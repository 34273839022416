import React from "react";
import { LuSettings2 } from "react-icons/lu";

export function TableHeaders({ headers, active = false }) {
  return (
    <ul
      className={`min-w-[1000px] grid grid-cols-7 justify-items-center bg-[var(--indigo-purple-light)] max-h-[400px] py-5 rounded-t-xl text-white text-lg text-center font-semibold capitalize  ${
        active ? "px-5" : ""
      }`}
    >
      {headers.map((header) => (
        <li>{header}</li>
      ))}
      <li className="mr-2">
        <LuSettings2 size={25} />
      </li>
    </ul>
  );
}
