import { useState } from "react";
import ModalLayout from "../../../components/AdminShared/ModalLayout";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import Loading from "../../../components/shared/Loading";
import { useRoles } from "../../../hooks/Admin/PermissionManage/useRoles";
import AttachPermissionSelect from "./AttachPermissionSelect";
const headers = ["permission", "actions"];
export default function RolesLayout() {
  const {
    error,
    loading,
    rolePermissionsLoading,
    addRoleLoading,
    detachLoading,
    roles,
    newRole,
    setNewRole,
    selectedRoleID,
    permissions,
    permissionTitle,
    handleUserSelect,
    handleShowDetachModal,
    handleDetachPermission,
    handleAddNewRole,
    searchQuery,
    setSearchQuery,
    isOpen,
    handleAtachPermission,
    submitAttachPermission,
    attachPermissionLoading,
    allPermissions,
  } = useRoles();

  return (
    <div className="w-full flex items-start gap-5">
      <ModalLayout id="detach-permission" closeBtn={false}>
        <div>
          <p className="my-3 text-white">
            Are you sure you want to delete the{" "}
            <span className="text-error">{permissionTitle}</span>?
          </p>
          <button
            disabled={detachLoading}
            onClick={handleDetachPermission}
            className="btn btn-error btn-outline disabled:bg-gray-500 light:disabled:bg-gray-300 join-item mr-3"
          >
            Yes
          </button>
          <button
            onClick={() => document.getElementById("detach-permission").close()}
            className="btn btn-success disabled:bg-gray-500 light:disabled:bg-gray-300 join-item"
          >
            Cancel
          </button>
        </div>
      </ModalLayout>
      <div className="w-full grid gap-3 max-w-xs">
        {loading ? (
          Array.from({ length: 5 }).map((item) => (
            <div className="w-full h-12 skeleton bg-transparent border border-gray-700 rounded-md"></div>
          ))
        ) : error ? (
          <span></span>
        ) : (
          roles.map((item) => (
            <button
              onClick={() => handleUserSelect(item.id)}
              className={`w-full btn ${
                selectedRoleID !== item.id &&
                "bg-transparent text-white border-gray-700"
              } `}
            >
              {item.title}
            </button>
          ))
        )}
        {newRole.showInput && (
          <input
            onChange={(e) =>
              setNewRole((prev) => ({ ...prev, value: e.target.value }))
            }
            disabled={addRoleLoading}
            className="input bg-transparent input-bordered !border-gray-700 text-white disabled:!bg-gray-700 disabled:!text-gray-400"
            placeholder="Role Name"
            autoFocus
            type="text"
          />
        )}
        <button
          disabled={addRoleLoading}
          onClick={handleAddNewRole}
          className="w-full btn btn-success btn-outline disabled:!bg-success"
        >
          {addRoleLoading ? (
            <span className="loading loading-dots loading-sm" />
          ) : newRole.showInput ? (
            "Submit"
          ) : (
            "Add new role"
          )}
        </button>
      </div>
      <div className="grow relative">
        <AttachPermissionSelect
          isOpen={isOpen}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          submitAttachPermission={submitAttachPermission}
          attachPermissionLoading={attachPermissionLoading}
          allPermissions={allPermissions}
        />
        <div
          className={`w-full overflow-auto scrollWidth  cursor-pointer rounded-xl relative z-20 border border-gray-700 `}
        >
          {!rolePermissionsLoading &&
            (permissions === null ? (
              <div className="w-full h-[500px] flex items-center justify-center">
                <ErrorMessage
                  text=" Please Select a Role"
                  optcClass="justify-center !text-second text-xl font-semibold"
                />
              </div>
            ) : (
              <table className="w-full  rounded-xl overflow-auto">
                <thead
                  className={`text-left text-gray-500 [&_th]:px-4  [&_th]:py-5 capitalize`}
                >
                  <tr className="sticky bg-gray-700 text-gray-300 top-0 z-30 shadow-[0_1px_0_0px] shadow-gray-500/40">
                    {/* <th>ID</th> */}
                    {headers.map((th) => (
                      <th>{th}</th>
                    ))}
                    <th className="w-[250px]">
                      <button
                        onClick={handleAtachPermission}
                        className={`btn btn-sm ${
                          isOpen ? "btn-error" : "btn-success"
                        }`}
                      >
                        {isOpen ? "Cancel" : "Attach permission"}
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody
                  className={`[&_td]:p-4 text-white [&_tr:not(:last-child)]:border-b [&_tr]:border-[--chart-grid] relative`}
                >
                  {permissions.map((row) => (
                    <tr className={`relative z-0 `}>
                      <td>{row.title}</td>
                      <td>
                        <div className="grid grid-cols-2 max-w-[200px]">
                          <button
                            onClick={() =>
                              handleShowDetachModal(row.id, row.title)
                            }
                            className="btn btn-error btn-sm btn-outline"
                          >
                            Detach
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ))}

          {rolePermissionsLoading && (
            <div className="w-full h-[500px] flex items-center justify-center">
              <Loading />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
