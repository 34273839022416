import React from "react";
import { RankingTable } from "./RankingTable";
import { RankingHeader } from "./RankingHeader";
import { RankingTopUserLayout } from "./RankingTopUserLayout";

export function RankingTopUsers({ data, headerText, type }) {
  const tops = data.filter((item) => item.rank >= 1 && item.rank <= 3);
  const other = data.filter((item) => item.rank > 3);

  return (
    <div className="rounded-[50px] bg-[#11092E] pb-10 shadow-lg">
      <RankingHeader headerText={headerText} />
      <RankingTopUserLayout data={tops} type={type} />
      <RankingTable data={other} type={type} />
    </div>
  );
}
