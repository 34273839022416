import { useState } from "react";
import { deleteData } from "../../utils/fetchData";
import { toast } from "react-toastify";

export function useDelete() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleDeleteData = async (url, successToast = null) => {
    let data = null;
    setLoading((prev) => true);
    setError((prev) => false);
    const response = await deleteData(url);
    if (response?.data) {
      data = response.data;
      if (successToast) {
        toast.success(response.message ?? successToast);
      }
    } else {
      setError(true);
      toast.error(
        response?.message || "Error fetching data please try again later"
      );
    }
    setLoading((prev) => false);
    return data;
  };

  return { loading, error, handleDeleteData };
}
