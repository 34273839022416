import { deleteData, getData } from "../../utils/fetchData";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export function useBanner() {
  const [type, setType] = useState("index");
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getBanners = async () => {
    setLoading((prev) => true);
    setError((prev) => false);
    const res = await getData("dashboard/banners");

    if (res?.data) {
      setBanners(res.data?.banners);
    } else {
      setError((prev) => "Error fetching data!");
      toast.error(res?.data?.message || "Unexpected error!");
    }

    setLoading((prev) => false);
  };

  const deleteBanners = async (id) => {
    setLoading((prev) => true);
    setError((prev) => false);
    const res = await deleteData("dashboard/banners/" + id);
    if (res?.data) {
      getBanners();
      toast.success(res.data);
    } else {
      toast.error("Unexpected error!");
      setLoading((prev) => false);
    }
  };

  useEffect(() => {
    getBanners();
  }, []);

  return { loading, error, setType, deleteBanners, getBanners, type, banners };
}
