import { useState } from "react";
import PermissionsLayout from "./PermissionsLayout";
import RolesLayout from "./RolesLayout";

export default function PermissionManagement() {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <>
      <div className="dashboard-box">
        <div role="tablist" className="tabs tabs-lifted tabs-lg">
          <div
            role="tab"
            onClick={() => setActiveTab(1)}
            className={`tab ${
              activeTab === 1
                ? "tab-active light:[--tab-border-color:#4b5563]"
                : "text-white light:border-b-[#4b5563]"
            }`}
          >
            Permissions
          </div>
          <div
            role="tab"
            onClick={() => setActiveTab(2)}
            className={`tab ${
              activeTab === 2
                ? "tab-active light:[--tab-border-color:#4b5563]"
                : "text-white light:border-b-[#4b5563]"
            }`}
          >
            Roles
          </div>
        </div>
        <div className="mt-5">
          {activeTab === 1 && <PermissionsLayout />}

          {activeTab === 2 && <RolesLayout />}
        </div>
      </div>
    </>
  );
}
