import React from "react";

export default function CurrentPage({
  pageStarColor = "white",
  category,
  currentPage,
  disableStar = false,
}) {
  return (
    <div className="w-full mb-5">
      <p className="uppercase [-webkit-text-stroke-color:white] [-webkit-text-stroke-width:1px] text-transparent opacity-20 text-xl md:text-4xl font-bold text-left tracking-[.3em] lg:tracking-[1em] xl:tracking-[1.5em] 2xl:tracking-[2.2em] mt-3 mb-3">
        {currentPage}
      </p>

      <div className="flex items-center justify-between ">
        <div className="flex items-center gap-2 capitalize">
          <span className="badge badge-xs bg-white border-transparent mb-1.5" />
          <p className="text-white opacity-60 text-2xl md:text-3xl  ">
            {category}
          </p>
          <p className="text-white text-xl md:text-3xl font-semibold border-white border-l-2 pl-2 leading-none">
            {currentPage}
          </p>
        </div>
        {!disableStar && (
          <svg
            className="w-10 md:w-[50px]"
            width="50"
            height="50"
            viewBox="0 0 95 90"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M47.3857 0C53.3372 22.0672 71.6502 39.3748 95 44.9999C71.6502 50.6249 53.3372 67.9323 47.3857 90C41.6628 67.9323 23.3498 50.6249 0 44.9999C23.3498 39.3748 41.6628 22.2835 47.3857 0Z"
              fill="white"
            />
            <path
              d="M47.3857 0C53.3372 22.0672 71.6502 39.3748 95 44.9999C71.6502 50.6249 53.3372 67.9323 47.3857 90C41.6628 67.9323 23.3498 50.6249 0 44.9999C23.3498 39.3748 41.6628 22.2835 47.3857 0Z"
              stroke={pageStarColor}
              fill={pageStarColor}
            />
          </svg>
        )}
      </div>
    </div>
  );
}
