import Loading from "../../../components/shared/Loading";
import PlanIndex from "./PlanIndex";
import PlanUpdate from "./PlanUpdate";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import TableBackground from "../../../components/AdminShared/TableBackground";
import { usePlans } from "../../../hooks/Admin/usePlans";

const PlanLayout = () => {
  const {
    loading,
    error,
    type,
    setType,
    formData,
    setFormData,
    plans,
    handleUpdate,
  } = usePlans();
  const renderSwitch = (param, plans) => {
    switch (param) {
      case "index":
        return (
          <PlanIndex
            plans={plans}
            setType={setType}
            setFormData={setFormData}
          />
        );
      case "update":
        return (
          <PlanUpdate
            setType={setType}
            formData={formData}
            setFormData={setFormData}
            handleUpdate={handleUpdate}
          />
        );

      default:
        return (
          <PlanIndex
            plans={plans}
            setType={setType}
            setFormData={setFormData}
          />
        );
    }
  };

  return (
    <>
      <TableBackground>
        {loading ? (
          <div className="w-full text-center">
            <Loading />
          </div>
        ) : error ? (
          <ErrorMessage text={error} />
        ) : (
          <div className="content">{renderSwitch(type, plans)}</div>
        )}
      </TableBackground>
    </>
  );
};

export default PlanLayout;
