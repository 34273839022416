import CompactSide from "../CompactInfo/CompactSide";
import Overview from "./Overview";
import TableLayout from "../Tables/TableLayout";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import Donut from "../Donut";
import BarChart from "../../Dashboard/BarChart";
import { useReferralDetail } from "../../../hooks/Referral/useReferralDetail";
import { useParams } from "react-router-dom";
const sidePartMapData = [
  {
    title: "Challenge Profit",
    objectName: "total_challenge_profit",
  },
  {
    title: "Investment Profit",
    objectName: "total_investment_profit",
  },
  {
    title: "Win-Win Profit",
    objectName: "total_win_win_profit",
  },
  {
    title: "Profit Share",
    objectName: "profit_share_profit",
  },
];

const TableLoadings = (
  <tr className="w-full ">
    <td className="w-[20%]">
      <div className=" py-1 ">
        <div className="rounded-lg w-28 h-5 bg-gray-700/60 light:bg-gray-300 animate-pulse mb-2" />
        <div className="rounded-lg w-16 h-4 bg-gray-700/60 light:bg-gray-300 animate-pulse" />
      </div>
    </td>
    <td className="w-[20%]">
      <div className="rounded-lg w-[70%] h-5 bg-gray-700/60 light:bg-gray-300 animate-pulse" />
    </td>
    <td className="w-[20%]">
      <div className="rounded-lg w-[50%] h-5 bg-gray-700/60 light:bg-gray-300 animate-pulse" />
    </td>

    <td className="w-[30%]">
      <div className="rounded-lg w-[80%] h-5 bg-gray-700/60 light:bg-gray-300 animate-pulse" />
    </td>
  </tr>
);

export default function DetailTableLayout() {
  const { id } = useParams();
  const { loading, details, profitByMonth, donutChart } = useReferralDetail(id);
  return (
    <div className=" mt-10">
      <Overview data={details?.top_part} loading={loading} />

      <div className="grid grid-cols-1 min-[1100px]:grid-cols-3 gap-y-5 min-[1100px]:gap-x-5 mt-5">
        <CompactSide
          data={details?.side_part}
          mapData={sidePartMapData}
          loading={loading}
        />

        <TableLayout
          title="Challenge Profits"
          headers={["Challenge", "Price", "Your Profit", "Date"]}
        >
          {loading
            ? Array.from({ length: 4 }).map((item) => TableLoadings)
            : details?.challenges_bought.length > 0 &&
              details?.challenges_bought.map((row) => (
                <tr className={`relative z-0 `}>
                  <td>
                    <div>
                      <div>{row.name}</div>
                      <div className="text-sm text-gray-500">{row.balance}</div>
                    </div>
                  </td>
                  <td>
                    <div>{row.price}</div>
                  </td>
                  <td>{row.profit}</td>
                  <td>{row.date}</td>
                </tr>
              ))}
        </TableLayout>

        <TableLayout
          title="Investment Profits"
          headers={["Type", "Price", "Profit", "Date"]}
        >
          {loading
            ? Array.from({ length: 5 }).map((item) => (
                <tr className="w-full [&_td]:p-2">
                  <td className="w-[20%] !py-5">
                    <div className="rounded-lg w-28 h-5 bg-gray-700/60 light:bg-gray-300 animate-pulse" />
                  </td>
                  <td className="w-[20%]">
                    <div className="rounded-lg w-[70%] h-5 bg-gray-700/60 light:bg-gray-300 animate-pulse" />
                  </td>
                  <td className="w-[20%]">
                    <div className="rounded-lg w-[50%] h-5 bg-gray-700/60 light:bg-gray-300 animate-pulse" />
                  </td>

                  <td className="w-[30%]">
                    <div className="rounded-lg w-[80%] h-5 bg-gray-700/60 light:bg-gray-300 animate-pulse" />
                  </td>
                </tr>
              ))
            : details?.investments.length > 0 &&
              details?.investments.map((row) => (
                <tr className={`relative z-0 `}>
                  <td>
                    <div>{row.name}</div>
                  </td>
                  <td>{row.price}</td>
                  <td>{row.profit}</td>
                  <td>{row.date}</td>
                </tr>
              ))}
        </TableLayout>

        <Donut
          series={donutChart?.data}
          labels={donutChart?.legends}
          loading={loading}
        />
      </div>
      <div className="grid grid-cols-1 min-[1100px]:grid-cols-4 gap-5 mt-5">
        <BarChart
          loading={loading}
          series={[
            {
              name: "Profit",
              type: "line",
              color: "#5d35b0",
              data: profitByMonth?.y,
            },
          ]}
          xAxis={profitByMonth?.x}
          title="Profit Per Month"
        />
        <TableLayout
          title="Profit Share"
          headers={["Challenge", "Price", "Profit", "Date"]}
          optClass="max-[1100px]:!col-span-1"
        >
          {loading
            ? Array.from({ length: 4 }).map((item) => TableLoadings)
            : details?.profit_shares.length > 0 &&
              details?.profit_shares.map((row) => (
                <tr className={`relative z-0 `}>
                  <td>
                    <div>
                      <div>{row.name}</div>
                      <div className="text-sm text-gray-500">{row.balance}</div>
                    </div>
                  </td>
                  <td>{row.price}</td>
                  <td>{row.profit}</td>
                  <td>{row.date}</td>
                </tr>
              ))}
        </TableLayout>
      </div>
    </div>
  );
}
