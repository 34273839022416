import React, { useEffect, useState } from "react";
export default function NotFound() {
  let [bgPosition, setBgPosition] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    window.addEventListener("mousemove", (e) => {
      setBgPosition((prev) => ({ x: e.clientX / 50, y: e.clientY / 50 }));
    });
  }, []);
  return (
    <div
      style={{
        backgroundColor: "#11092e",
        backgroundImage: "url(bg404.png)",
        backgroundPosition: `${bgPosition.x}px ${bgPosition.y}px`,
      }}
      className="flex flex-col items-center justify-center w-full h-screen text-white text-6xl font-semibold"
    >
      <p>404</p>
      <p> Page Not Founds</p>
    </div>
  );
}
