import { useState } from "react";

export function WithdrawInitialInput({ children, func, value, text, icon }) {
  const [error, setError] = useState(false);

  function handleError(e) {
    e.preventDefault();
    setError(true);
  }
  function handleOnChange(e) {
    func(e.target.value);
    setError(false);
  }
  return (
    <>
    <label htmlFor="withdrawForm2">Withdraw From:</label>
      <div className="w-full flex items-center justify-start">
        {icon}
        <select
          onInvalid={handleError}
          required={true}
          value={value}
          onChange={handleOnChange}
          id="withdrawForm2"
          className="w-full max-w-xs bg-transparent border-2 text-white border-white rounded-lg p-2  focus:outline-0 [&>*]:bg-gray-700 text-lg font-normal"
        >
          <option disabled value="">
            {text}
          </option>
          {children}
        </select>
      </div>
      <span
        className={`text-sm text-rose-600 mb-1 ${error ? "block" : "hidden"}`}
      >
        Please select one of these options
      </span>
    </>
  );
}
