export function IdentityDocument({ children }) {
  return (
    <div className="w-full grid grid-cols-1 gap-y-3 text-white font-semibold capitalize">
      <p className="text-white text-center text-xl uppercase tracking-[5px] font-light">
        Identity document
      </p>
      {children}
    </div>
  );
}
