import { Link, useLocation } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { FaChevronRight } from "react-icons/fa";
const hiddenUrls = ["admin"];
// const withoutLink = [];
// const mapUrlName = {
//   "new-challenge": "new challenge",
// };;

const Breadcrumbes = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => isValidUrl(x));

  function isValidUrl(val) {
    if (val === "") return false;
    if (hiddenUrls.includes(val)) return false;
    if (!!+val) return false;

    return true;
  }

  function createPathUrl(pathName) {
    // if (withoutLink.includes(pathName)) return location.pathname;

    const path = location.pathname.split(`/${pathName}`);

    if (!path[0] || path[0] === "/") return `/${pathName}`;

    return `${path[0]}/${pathName}`;
  }

  return (
    <div className="h-16 flex items-center text-white text-xl bg-gray-800 light:bg-gray-200 rounded-xl px-5 py-3 mb-5 mt-10 border border-light-border">
      <ul className="flex items-baseline gap-5">
        <li>
          <Link to="/">
            <AiFillHome className="fill-[--indigo-purple] light:fill-[--indigo-purple-light]" />
          </Link>
        </li>
        {pathnames.map((pathName, i) => {
          const link = createPathUrl(pathName);
          return (
            <li>
              <div className="flex items-center gap-4 justify-center">
                <FaChevronRight />
                {i + 1 === pathnames.length ? (
                  <div className="btn btn-sm bg-[--indigo-purple] light:bg-[--indigo-purple-light] hover:bg-[--indigo-purple-hover] text-white border-none capitalize">
                    {pathName.replaceAll("-", " ")}
                  </div>
                ) : (
                  <Link to={link}>
                    <div className="mt-1 relative group capitalize">
                      {pathName.replace("-", " ")}
                      <span className="block w-full h-[2px] rounded-full bg-transparent group-hover:bg-[--indigo-purple] transition-colors absolute bottom-1" />
                    </div>
                  </Link>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Breadcrumbes;
