import React, { useState } from "react";
import Loading from "../../components/shared/Loading";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { useSelector } from "react-redux";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

const allowedTypes = ["image/jpeg", "image/png", "image/gif"];

export function IdentityUpload({
  data,
  loading,
  handleSetData,
  uploadBtnDisabled,
  handleUploadIdentity,
}) {
  return (
    <>
      {loading ? (
        <div className="w-full text-center my-20">
          <Loading />
        </div>
      ) : (
        <>
          <p className="mt-2 font-light">
            Take a photo from your passport the photo should be:
          </p>
          <ul className="list-disc list-inside ml-3">
            <li>Bright and clear (good quality)</li>
            <li>Uncut (all corners of the document should be visible)</li>
            <li>The uploaded file size should not exceed 1 MB</li>
          </ul>
          <div className="grid grid-cols-3 items-center justify-items-end my-2">
            <img src="../i_passport_correct.png" alt="" />
            <img
              src="../i_passport_incorrect_1.png"
              className="w-10/12"
              alt=""
            />
            <img
              src="../i_passport_incorrect_2.png"
              className="w-10/12 "
              alt=""
            />
          </div>
          <DragDropFile data={data} handleSetData={handleSetData} />

          <button
            disabled={uploadBtnDisabled}
            onClick={handleUploadIdentity}
            className="btn text-white bg-[--indigo-purple] hover:bg-[--indigo-purple-hover] border border-light-border disabled:bg-gray-400 disabled:border-gray-400 disabled:light:border-gray-300"
          >
            Submit
          </button>
        </>
      )}
    </>
  );
}

function DragDropFile({ data, handleSetData }) {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(false);

  const token = "Bearer " + useSelector((state) => state.auth.token);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const server = {
    url: `${baseUrl}/api/chunk-upload`,
    headers: {
      Authorization: token,
    },
  };

  function handleFile(isCancel = false) {
    if (isCancel) {
      handleSetData("fileName", null);
      setFiles([]);
    } else {
      handleSetData(
        "fileName",
        document.querySelector('input[name="chunkfile"]').getAttribute("value")
      );
    }
  }

  return (
    <div>
      <FilePond
        className="[&_div.filepond--drop-label]:light:!bg-gray-200 [&_div.filepond--drop-label]:!rounded-md"
        files={files}
        onupdatefiles={setFiles}
        chunkUploads={true}
        onprocessfiles={handleFile}
        onremovefile={() => handleFile(true)}
        allowMultiple={false}
        chunkSize={50 * 1024}
        maxFileSize={1.2 * 1024 * 1024}
        acceptedFileTypes={["image/jpeg", "image/png"]}
        server={server}
        name="chunkfile"
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
    </div>
  );
}
