import DarkTablePagination from "../DarkTablePagination";
const TableLayout = ({
  children,
  pagination = true,
  paginationData,
  paginationClick,
}) => {
  return (
    <div className="dashboard-box ">
      <div>{children}</div>

      {pagination && paginationData && paginationData?.last_page > 1 && (
        <div className="w-full flex items-center justify-end">
          <DarkTablePagination
            data={paginationData}
            handleOnClick={paginationClick}
          />
        </div>
      )}
    </div>
  );
};

export default TableLayout;
