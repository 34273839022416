import React from "react";
import Chart from "react-apexcharts";
import Loading from "../../components/shared/Loading";

const barChartOptions = {
  chart: {
    parentHeightOffset: 0,

    type: "line",
    stacked: false,
    background: "var(--box-items-background)",

    foreColor: "var(--primary-text)",
    toolbar: {
      show: false,
      tools: {
        download: false,
        selection: false,
        zoom: false,
        zoomin: false,
        zoomout: false,
        pan: false,
      },
    },
  },
  grid: {
    show: true,
    position: "back",
    borderColor: "var(--chart-grid)",
    padding: {
      bottom: 40,
      right: 30,
      left: 40,
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 3,
    curve: "smooth",
  },
  yaxis: {
    labels: {
      padding: 30,
      align: "left",
    },
    tickAmount: 5,
  },
  tooltip: {
    followCursor: true,
  },
  legend: {
    showForSingleSeries: true,
    horizontalAlign: "left",
    position: "top",
    style: {
      fontFamily: "Khula",
    },
    offsetY: -40,
    itemMargin: {
      horizontal: 20,
    },
  },
};

export default function BarChart({
  loading,
  title = "",
  series,
  xAxis = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
}) {
  return (
    <>
      <div className="max-[400px]:hidden relative z-10 min-[1100px]:col-span-2 ">
        {loading ? (
          <div className="h-[500px] bg-[--box-items-background] rounded-[19px] flex items-center justify-center">
            <Loading optClass="opacity-30" />
          </div>
        ) : (
          <Chart
            options={{
              ...barChartOptions,
              title: {
                text: title,
                margin: 20,
                style: {
                  fontSize: "30px",
                  fontWeight: "bold",
                  fontFamily: "Khula",
                  color: "var(--primary-text)",
                },
                offsetX: 20,
                offsetY: 10,
              },
              xaxis: {
                categories: xAxis,
                axisBorder: { show: false },
                axisTicks: { show: false },
                labels: {
                  padding: 30,
                },
                offsetY: 20,
              },
            }}
            series={series}
            type="line"
            height={500}
          />
        )}
      </div>
    </>
  );
}
