import { useState } from "react";
import {usePost} from "./API/usePost"
export function usePaymentRequests() {
  const [error, setError] = useState(false);
  const [paymentId, setPaymentId] = useState(null);
  const {loading, handlePostData} = usePost(false)

  async function handleSubmit() {
    if (!paymentId) return;

    const data = {
      payment_id: paymentId,
    };

    setError((prev) => false);
    const res = await handlePostData("finance/deposit/now-payments/confirm", data, true);
    if (!res) {
      setError(`Payment doesnt exists! (id: ${paymentId})`);
    }
    setPaymentId(null);
  }

  return { loading, error, paymentId, setPaymentId, handleSubmit };
}
