import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PageStatus from "../../../../components/PageStatus/PageStatus";
import useUser from "../../../../hooks/Admin/User/useUser";
import TableBackground from "../../../../components/AdminShared/TableBackground";
import RequestDetailsForm from "../../../../components/AdminShared/RequestDetailsForm";
import RequestDetailsInput from "../../../../components/AdminShared/RequestsDetailsInput";
import RequestDetailsButton, {
  RequestDetailsButtonLayout,
} from "../../../../components/AdminShared/RequestsDetailsButton";
import ErrorMessage from "../../../../components/shared/ErrorMessage";
import Loading from "../../../../components/shared/Loading";
const inputStyle = "!input-md !text-xl text-black";

const UserUpdate = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    verification_type: "",
    role: "",
  });

  const { id } = useParams();

  const { putLoading: loading, putError: error, updateUser, user, verificationTypes, roles } =
    useUser(null, null, id);

  useEffect(() => {
    if (user) {
      setFormData({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone: user.phone,
        verification_type: user.verification_type,
        role: user.roles[0].id,
      });
    }
  }, [user]);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateUser(formData, id);
  };

  return (
    <>
      <PageStatus
        category={"Users"}
        currentPage={"Update User"}
        logoStarColor="#F8CC15"
      />
      <TableBackground>
        {loading || user === null ? (
          <div className="w-full text-center">
            <Loading />
          </div>
        ) : (
          <>
            <div className=" flex items-center justify-between text-center text-white mb-5">
              <h3 className="text-2xl text-center w-full ">User Index</h3>
              <Link
                to="/dashboard/admin/users"
                className="btn btn-secondary btn-outline ml-auto"
              >
                Back
              </Link>
            </div>

            <RequestDetailsForm>
              <RequestDetailsInput
                title="First Name"
                name="first_name"
                titleClass="text-xl"
                value={formData.first_name}
                optClass={inputStyle}
                onChange={handleChange}
                required
              />
              <RequestDetailsInput
                title="Last Name"
                name="last_name"
                titleClass="text-xl"
                value={formData.last_name}
                optClass={inputStyle}
                onChange={handleChange}
                required
              />
              <RequestDetailsInput
                title="Email"
                name="email"
                type="email"
                titleClass="text-xl"
                value={formData.email}
                optClass={inputStyle}
                onChange={handleChange}
                required
              />
              <RequestDetailsInput
                title="Phone"
                name="phone"
                type="phone"
                titleClass="text-xl"
                value={formData.phone}
                optClass={inputStyle}
                onChange={handleChange}
              />

              <label className="form-control">
                <div className="label">
                  <span className={`label-text text-xl text-slate-100`}>
                    Verification Type:
                  </span>
                </div>
                <select
                  name="verification_type"
                  value={formData.verification_type}
                  onChange={handleChange}
                  className="select select-md text-lg text-black select-bordered bg-slate-300"
                >
                  {Object.entries(verificationTypes).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
              </label>
              <label className="form-control">
                <div className="label">
                  <span className={`label-text text-xl text-slate-100`}>
                    Role:
                  </span>
                </div>
                <select
                  name="role"
                  value={formData.role}
                  onChange={handleChange}
                  className="select select-md text-lg text-black select-bordered bg-slate-300"
                >
                  {roles.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.title}
                    </option>
                  ))}
                </select>
              </label>
            </RequestDetailsForm>
            {error && <ErrorMessage optcClass="mt-3" text={error} />}
            <RequestDetailsButtonLayout>
              <RequestDetailsButton
                title="submit"
                onclick={handleSubmit}
                optClass="btn-success"
              />
            </RequestDetailsButtonLayout>

            {/* <form onSubmit={handleSubmit}>
              <button
                type="submit"
                className="btn btn-outline btn-success text-white px-4 py-2 rounded"
                disabled={loading}
              >
                {loading ? "Updating..." : "Update"}
              </button>
              {error && (
                <p className="text-red-500 mt-2">Error: {error.message}</p>
              )}
            </form> */}
          </>
        )}
      </TableBackground>
    </>
  );
};

export default UserUpdate;
