import TableBackground from "../../../components/AdminShared/TableBackground";
import { useBanner } from "../../../hooks/Admin/useBanner";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/Table";

const BannerLayout = () => {
  const { loading, deleteBanners, banners } = useBanner();
  const navigate = useNavigate();

  return (
    <>
      <TableBackground>
        <button onClick={() => navigate("create")} className="btn btn-success ">
          Create
        </button>
        <Table
          headers={["Title", "Photo", "Actions"]}
          optClass="!max-h-[700px]"
        >
          {loading
            ? Array.from({ length: 3 }).map((_, i) => (
                <tr key={i}>
                  <td>
                    <div className="w-10 h-8 skeleton bg-transparent"></div>
                  </td>
                  <td>
                    <div className="w-40 h-8 skeleton bg-transparent"></div>
                  </td>
                  <td>
                    <div className="w-[300px] h-[130px] skeleton bg-transparent rounded-none"></div>
                  </td>
                  <td className="grid grid-cols-1 gap-3">
                    <div className=" h-8 skeleton bg-transparent"></div>
                    <div className="h-8 skeleton bg-transparent"></div>
                  </td>
                </tr>
              ))
            : banners.map((banner) => (
                <tr>
                  <td>{banner.id}</td>
                  <td>
                    <p>{banner.title}</p>
                  </td>
                  <td>
                    <div>
                      <img
                        src={banner.images[0]?.src}
                        className="w-[300px]"
                        alt={banner.title}
                      />
                    </div>
                  </td>
                  <td className="grid grid-cols-1 gap-3">
                    <button
                      onClick={() => navigate(`update/${banner.id}`)}
                      className="btn btn-sm btn-outline btn-success"
                    >
                      Update
                    </button>
                    <button
                      onClick={() => deleteBanners(banner.id)}
                      className="btn btn-sm btn-outline btn-error"
                    >
                      delete
                    </button>
                  </td>
                </tr>
              ))}
        </Table>
      </TableBackground>
    </>
  );
};

export default BannerLayout;
