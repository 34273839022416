import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePost } from "./API/usePost";
export function useForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  // const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { loading, handlePostData } = usePost(false);

  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const getEmail = async () => {
    const res = await handlePostData(
      "users/verification/forgot-password/email-exists",
      { email: email }
    );
    if (!res) return;
    navigate(`/reset-password/${encodeURIComponent(email)}`);
  };
  const handleSubmit = async (e) => {
    if (emailPattern.test(email)) {
      getEmail();
    } else {
      setError(true);
    }
  };
  function handleSetEmail(val) {
    setEmail((prev) => val);
  }

  return { email, loading, error, handleSubmit, handleSetEmail };
}
