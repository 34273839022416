import { SubmitButton } from "./SubmitButton";
export function TransactionResult({
  headerText,
  firstBtnText,
  secBtnText,
  firstBtnClick,
  secBtnClick,
  imgUrl,
  imgAlt,
  linkFirst,
  linkSecond,
}) {
  return (
    <div className="w-full grid grid-cols-1 justify-items-center">
      <p className="max-w-md text-3xl text-white text-center font-bold">
        {headerText}
      </p>
      <img src={imgUrl} className="w-full max-w-xs my-6" alt={imgAlt} />
      <div className="w-full grid justify-items-center ">
        <SubmitButton text={firstBtnText} onClick={firstBtnClick} />

        <SubmitButton
          text={secBtnText}
          bgColor="bg-white"
          textColor="primaryPurple"
          onClick={secBtnClick}
        />
      </div>
    </div>
  );
}
