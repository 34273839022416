import React from "react";
import { SubmitButton } from "../../components/shared/SubmitButton";
import { useNavigate } from "react-router-dom";

export function VerificationUnsuccess({ uploadAgain, declineReason }) {
  const navigate = useNavigate();
  return (
    <div className="max-w-lg flex flex-col items-center capitalize">
      <p className="text-2xl text-red-400 text-center font-bold">
        Your verification was unsuccessful!
      </p>
      <img src="../transactionUnsuccess.png" className="w-10/12 md:w-6/12 my-10" alt="" />
      <div className="w-full flex flex-col bg-primaryPurple rounded-lg border-t-2 sm:border-t-2 border-white mb-7 overflow-hidden">
        <div className="w-full text-center inline-block text-white font-semibold p-2.5 bg-[var(--indigo-purple-hover)]">
          Reason
        </div>
        <div className="w-full inline-block text-primaryPurple font-bold bg-white p-2.5">
          {declineReason}
        </div>
      </div>
      <SubmitButton text="Upload again" optClass="mb-2" onClick={uploadAgain} />
      <SubmitButton
        onClick={() => navigate("/dashboard/tickets")}
        optClass="text-xl !px-3"
        text="Have a problem? ticket now"
      />
    </div>
  );
}
