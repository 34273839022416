import Filter from "../../../components/shared/DarkTable/Filter";
import FilterOptions from "../../../components/shared/DarkTable/FilterOptions";
import TableFilters from "../../../components/shared/DarkTable/TableFilters";

export default function Filters({
  filter,
  handleFilterChange,
  handleSubmitFilter,
  filterOptionData,
}) {
  return (
    <TableFilters
      handleSubmitFilter={handleSubmitFilter}
      handleReset={() => handleFilterChange("reset")}
      title="Tickets"
    >
      <Filter
        title="Title"
        value={filter.title}
        name="title"
        onChange={handleFilterChange}
        placeholder="Title"
      />
      <FilterOptions
        title="State"
        value={filter.state}
        name="state"
        onChange={handleFilterChange}
        options={filterOptionData}
        dontUseKey
      />
      <Filter
        title="From Date"
        value={filter.from_date}
        name="from_date"
        onChange={handleFilterChange}
        type="date"
      />
      <Filter
        title="To Date"
        value={filter.to_date}
        name="to_date"
        onChange={handleFilterChange}
        type="date"
      />
    </TableFilters>
  );
}
