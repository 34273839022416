import ChallengeRequestDetails from "./ChallengeRequestDetail";
import { WithdrawRequestRow } from "./WithdrawRequestRow";
import Table from "../../../components/AdminShared/Table";
import { useWithdrawRequest } from "../../../hooks/Admin/useWithdrawRequest";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import Loading from "../../../components/shared/Loading";
import TableBackground from "../../../components/AdminShared/TableBackground";
import PageStatus from "../../../components/PageStatus/PageStatus";
const theadData = ["Name", "Email", "Amount", "Actions"];

export default function WithdrawRequest() {
  const {
    loading,
    error,
    userSelected,
    setUserSelected,
    withdrawRequests,
    getRequestWithdraws,
  } = useWithdrawRequest();

  return (
    <>
      <PageStatus
        category={"Finance"}
        currentPage={"Withdraws"}
        logoStarColor="#F8CC15"
      />

      {userSelected ? (
        <ChallengeRequestDetails
          userSelected={userSelected}
          setUserSelected={setUserSelected}
          getRequestWithdraws={getRequestWithdraws}
        />
      ) : (
        <TableBackground>
          {loading ? (
            <div className="w-full text-center">
              <Loading />
            </div>
          ) : error ? (
            <ErrorMessage text={error} />
          ) : (
            <Table theads={theadData}>
              {withdrawRequests?.map((data) => (
                <WithdrawRequestRow
                  data={data}
                  setUserSelected={setUserSelected}
                />
              ))}
            </Table>
          )}
        </TableBackground>
      )}
    </>
  );
}
