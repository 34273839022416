import React from "react";

export function IdentityUploadResult() {
  return (
    <>
      <p className="text-white text-center text-xl uppercase tracking-[5px] font-light">
        Upload Completed!
      </p>
      <div className="w-9/12 mt-4">
        <img src="../Group 115.png" className="w-full" alt="" />
        <p className="text-lg text-white text-center font-semibold my-10">
          Your document will checked and the result will be notified to you via
          email
        </p>
      </div>
    </>
  );
}
