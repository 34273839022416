import React, { useRef, useState } from "react";
import Pagination from "../../components/AdminShared/Pagination";
import usePost from "../../hooks/Blog/Post/usePost";
import Loading from "../../components/shared/Loading";
import { Link } from "react-router-dom";
import TableBackground from "../../components/AdminShared/TableBackground";
import Table from "../../components/AdminShared/Table";
import ErrorMessage from "../../components/shared/ErrorMessage";
import { usePagination } from "../../hooks/usePagination";

export default function Post() {
  const { pagination, handleChangePagination } = usePagination();
  const {
    error,
    loading,
    deleteError,
    deleteLoading,
    posts,
    postLinks,
    deletePost,
  } = usePost(pagination);
  const [postId, setPostId] = useState(null);
  const modalRef = useRef(null);

  function handleClose() {
    modalRef?.current.close();
  }

  function showModal(id) {
    setPostId(id);
    modalRef.current.showModal();
  }

  return (
    <>
      <dialog
        ref={modalRef}
        id="my_modal_1"
        className="modal w-full max-w-md mx-auto"
      >
        <div className="modal-box bg-black border border-error py-5 px-2">
          <div>
            <div className="w-full text-error font-bold my-5 max-w-xs mx-auto">
              Are you sure you want to delete the post?
              <div className="w-full text-start">ID: {postId}</div>
            </div>
            <div className="w-full max-w-[250px] grid grid-cols-2 gap-2 mx-auto">
              <button
                onClick={() => {
                  deletePost(postId);
                  handleClose();
                }}
                className="btn btn-error btn-outline btn-sm"
              >
                Delete
              </button>
              <button
                onClick={handleClose}
                className="btn btn-accent btn-outline btn-sm"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </dialog>
      <TableBackground>
        {error || deleteError ? (
          <ErrorMessage />
        ) : loading || deleteLoading ? (
          <div className="w-full h-[700px] flex items-center justify-center">
            <Loading />
          </div>
        ) : (
          <>
            <div className=" flex items-center justify-end text-center text-white mb-5">
              <Link to="/post/create" className="btn btn-outline btn-success ">
                create
              </Link>
            </div>

            <Table theads={["Id", "Title", "Description", "Slug", "Actions"]}>
              {posts.map((post) => (
                <tr>
                  <td>{post.id}</td>
                  <td>
                    <p>{post.title}</p>
                  </td>
                  <td>
                    <p>{post.description}</p>
                  </td>
                  <td>
                    <p>{post.slug}</p>
                  </td>
                  {/* <td><p>{trader.referral_count}</p></td> */}
                  <td className="min-w-[150px] grid grid-cols-1 xl:grid-cols-2 gap-2 px-0">
                    <Link
                      to={`/post/update/${post.id}`}
                      className="btn btn-primary btn-outline btn-sm"
                    >
                      Update
                    </Link>
                    <button
                      onClick={() => showModal(post.id)}
                      className="btn btn-secondary btn-outline btn-sm"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </Table>
          </>
        )}
        {postLinks && (
          <Pagination
            handleOnClick={handleChangePagination}
            data={postLinks}
            optClass="mt-5"
          />
        )}
      </TableBackground>
    </>
  );
}
