export function TransferDetail({ title, value }) {
  return (
    <div className="h-12 w-full grid grid-cols-2 items-center bg-[var(--indigo-purple-dark)] text-lg font-bold leading-none border-l-2 border-white rounded-md capitalize">
      <span className=" text-white ml-1 pt-1">{title}:</span>
      <div className="h-full flex items-center justify-start text-[var(--indigo-purple-dark)] bg-white rounded-md pt-2 pb-1 px-2 text-wrap">
        {value}
      </div>
    </div>
  );
}
