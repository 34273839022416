import { useEffect, useState } from "react";
import { useFetch } from "../../API/useFetch";
import { usePut } from "../../API/usePut";
import { usePost } from "../../API/usePost";

export function useRoles() {
  const [roles, setRoles] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [selectedRoleID, setSelectedRoleID] = useState(null);
  const [permissionID, setPermissionID] = useState(null);
  const [permissionTitle, setPermissionTitle] = useState(null);
  const [newRole, setNewRole] = useState({
    value: null,
    showInput: false,
  });
  const [searchQuery, setSearchQuery] = useState({ name: "", value: "" });
  const [isOpen, setIsOpen] = useState(false);
  const [allPermissions, setAllPermissions] = useState({});

  const { error, loading, fetchData } = useFetch();
  const { loading: rolePermissionsLoading, fetchData: getRolePermissions } =
    useFetch(false);
  const { fetchData: getAllPermissions } = useFetch();
  const { loading: detachLoading, handlePutData } = usePut();
  const { loading: attachPermissionLoading, handlePutData: attachPermission } =
    usePut();
  const { loading: addRoleLoading, handlePostData } = usePost(false);

  async function handleGetRoles() {
    const res = await fetchData("role");
    if (res) {
      setRoles(res.roles);
    }
    const AllPermissionsRes = await getAllPermissions("permission-list");
    if (AllPermissionsRes) {
      setAllPermissions(AllPermissionsRes.permissions);
    }
  }

  async function handleGetRolePermissions(id) {
    setIsOpen(false);
    setSearchQuery({ name: "", value: "" });
    const res = await getRolePermissions(`role-permissions/${id}`);
    if (!res) return;
    setPermissions(res.role.permissions);
  }

  async function handleDetachPermission() {
    const data = {
      permission: permissionID,
    };
    const res = await handlePutData(
      `role/detach/${selectedRoleID}`,
      data,
      true
    );

    if (!res) return;

    document.getElementById("detach-permission").close();
    handleGetRolePermissions(selectedRoleID);
  }

  async function handleAddNewRole() {
    if (newRole.showInput) {
      if (newRole.value) {
        const data = {
          title: newRole.value,
        };
        const res = await handlePostData("role", data, "created Successfully");

        if (!res) return;

        handleGetRoles();
        setNewRole((prev) => ({ ...prev, showInput: false }));
      }
    } else {
      setNewRole((prev) => ({ ...prev, showInput: true }));
    }
  }

  async function submitAttachPermission() {
    const data = {
      permission: searchQuery.value,
    };
    const res = await attachPermission(
      `role/attach/${selectedRoleID}`,
      data,
      true
    );

    if (!res) return;

    handleGetRolePermissions(selectedRoleID);
  }

  // on role select
  function handleUserSelect(id) {
    setSelectedRoleID(id);
    handleGetRolePermissions(id);
    setIsOpen(false);
    setSearchQuery({ name: "", value: "" });
  }

  function handleShowDetachModal(id, title) {
    document.getElementById("detach-permission").showModal();
    setPermissionID(id);
    setPermissionTitle(title);
  }

  function handleAtachPermission() {
    if (isOpen) {
      setIsOpen(false);
      setSearchQuery({ name: "", value: "" });
    } else {
      setIsOpen(true);
    }
  }

  useEffect(() => {
    handleGetRoles();
  }, []);

  return {
    error,
    loading,
    rolePermissionsLoading,
    addRoleLoading,
    detachLoading,
    roles,
    permissions,
    setNewRole,
    newRole,
    selectedRoleID,
    permissionTitle,
    handleUserSelect,
    handleShowDetachModal,
    handleDetachPermission,
    handleAddNewRole,
    searchQuery,
    setSearchQuery,
    isOpen,
    handleAtachPermission,
    submitAttachPermission,
    attachPermissionLoading,
    allPermissions,
  };
}
