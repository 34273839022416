import { useState } from "react";
import { usePost } from "./API/usePost";

const initialWithdrawData = {
  amount: null,
  withdrawFrom: null,
  type: "trc20",
  trc20_wallet_address: "",
  card_number: "",
  card_holder_name: "",
  step: 1,
};

export function useWithdraw() {
  const [withdrawData, setWithdrawData] = useState(initialWithdrawData);
  const {loading, handlePostData} = usePost(false)
  const handleSubmitWithdraw = async () => {
      const res = await handlePostData("finance/withdraws", withdrawData);
      if (!res) return;
      setWithdrawData((prevData) => ({
        ...prevData,
        step: 4,
      }));
  };

  function handleSetWithdraw(values) {
    setWithdrawData((prev) => ({ ...prev, ...values }));
  }

  function handleResetSetWithdraw() {
    setWithdrawData(initialWithdrawData);
  }

  return {
    loading,
    withdrawData,
    handleSubmitWithdraw,
    handleSetWithdraw,
    handleResetSetWithdraw,
  };
}
