import React from "react";
import Loading from "../../../components/shared/Loading";
import TopTraderIndex from "./TopTraderIndex";
import TopTraderUpdate from "./TopTraderUpdate";
import PageStatus from "../../../components/PageStatus/PageStatus";
import TableBackground from "../../../components/AdminShared/TableBackground";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import { useTopTraders } from "../../../hooks/Admin/useTopTraders";

const TopTraderLayout = () => {
  const {
    loading,
    error,
    traders,
    type,
    setType,
    setFormData,
    formData,
    handleUpdate,
  } = useTopTraders();
  const renderSwitch = (param, traders) => {
    switch (param) {
      case "index":
        return (
          <TopTraderIndex
            traders={traders}
            setType={setType}
            setFormData={setFormData}
          />
        );
      case "update":
        return (
          <TopTraderUpdate
            setType={setType}
            formData={formData}
            setFormData={setFormData}
            handleUpdate={handleUpdate}
          />
        );

      default:
        return <TopTraderIndex traders={traders} />;
    }
  };

  return (
    <>
      <PageStatus
        category={"Top Traders"}
        currentPage={"Top traders"}
        logoStarColor="#F8CC15"
      />
      <TableBackground>
        {loading ? (
          <div className="w-full text-center">
            <Loading />
          </div>
        ) : error ? (
          <ErrorMessage text={error} />
        ) : (
          <div className="content">{renderSwitch(type, traders)}</div>
        )}
      </TableBackground>
    </>
  );
};

export default TopTraderLayout;
