import React from "react";
import Loading from "../../../components/shared/Loading";
import WithdrawTopTraderIndex from "./WithdrawTopTraderIndex";
import WithdrawTopTraderUpdate from "./WithdrawTopTraderUpdate";
import TableBackground from "../../../components/AdminShared/TableBackground";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import { useWithdrawTopTrader } from "../../../hooks/Admin/useWithdrawTopTrader";

const WithdrawTopTraderLayout = () => {
  const {
    loading,
    error,
    type,
    setType,
    formData,
    setFormData,
    traders,
    handleUpdate,
  } = useWithdrawTopTrader();

  const renderSwitch = (param, traders) => {
    switch (param) {
      case "index":
        return (
          <WithdrawTopTraderIndex
            traders={traders}
            setType={setType}
            setFormData={setFormData}
          />
        );
      case "update":
        return (
          <WithdrawTopTraderUpdate
            setType={setType}
            formData={formData}
            setFormData={setFormData}
            handleUpdate={handleUpdate}
          />
        );

      default:
        return <WithdrawTopTraderIndex traders={traders} />;
    }
  };

  return (
    <>
      <TableBackground>
        {loading ? (
          <div className="w-full text-center">
            <Loading />
          </div>
        ) : error ? (
          <ErrorMessage text={error} />
        ) : (
          <div className="content">{renderSwitch(type, traders)}</div>
        )}
      </TableBackground>
    </>
  );
};

export default WithdrawTopTraderLayout;
