import CompactBox from "./CompactBox";
import { IoPeopleSharp } from "react-icons/io5";
import { TbReportMoney } from "react-icons/tb";
import { FaRegChartBar } from "react-icons/fa";
import { GrGrow } from "react-icons/gr";
import { MdOutlineAttachMoney } from "react-icons/md";
export default function CompactLayout({ data, loading }) {
  return (
    <div className="w-full grid grid-cols-2 lg:grid-cols-4 auto-cols-fr gap-2 lg:gap-5">
      <CompactBox
        title="Total users"
        data={data?.total_referrals}
        icon={<IoPeopleSharp size={25} color="var(--primary-text)" />}
        loading={loading}
      />
      <CompactBox
        title="Total challenge sold"
        data={data?.challenge_count}
        icon={<FaRegChartBar size={25} color="var(--primary-text)" />}
        loading={loading}
      />
      <CompactBox
        title="Total challenge profit"
        data={data?.challenge_profit}
        icon={<TbReportMoney size={25} color="var(--primary-text)" />}
        loading={loading}
      />
      <CompactBox
        title="This month profit"
        data={data?.profit_this_month}
        icon={
          <MdOutlineAttachMoney
            size={35}
            className="text-indigo-500 light:text-yellow-500"
          />
        }
        loading={loading}
        optClass="border border-indigo-500 light:border-yellow-500"
        titleClass="!text-indigo-500 light:!text-yellow-500"
      />
    </div>
  );
}
