import Chart from "react-apexcharts";
import Loading from "../../components/shared/Loading";
import DonutDataNotFound from "../../components/DataNotFound/DonutChart";

const options = {
  chart: {
    parentHeightOffset: 0,
    type: "donut",
    foreColor: "var(--primary-text)",
  },
  dataLabels: {
    enabled: false,
    dropShadow: {
      enabled: false,
    },
    style: {
      fontSize: "15px",
      fontWeight: "light",
      fontFamily: "Khula",
      colors: ["#111", "#333", "#333", "#333"],
    },
  },
  legend: {
    position: "bottom",
    fontSize: "14px",
    offsetY: 10,

    style: {
      fontFamily: "Khula",
    },
    itemMargin: {
      horizontal: 20,
      vertical: 5,
    },
    markers: {
      offsetX: -3,
      radius: 3,
    },
  },

  stroke: {
    width: 0,
    // colors: ["#a68cdd", "#e88491", "#1AACA4", "#fff1b4"],
  },
  title: {
    align: "left",
    style: {
      fontSize: "25px",
      fontWeight: "bold",
      fontFamily: "Khula",
      color: "var(--primary-text)",
    },
  },
  responsive: [
    {
      breakpoint: 1100,
      options: {
        grid: {
          padding: {
            top: 0,
            bottom: 20,
          },
        },
        legend: {
          horizontalAlign: "center",
        },
      },
    },
  ],
};

export default function Donut({
  series,
  labels,
  loading,
  text = "Profit From",
  height = 450,
  colors = ["#5d35b0", "#cf364a", "#13807a", "#f8cc15"],
  horizontalAlign = "start",
  className = "dashboard-box",
  optPadding = { top: 30 }, // Add space between title and chart
  tooltipFormatter,
}) {
  return (
    <div className={className}>
      {loading ? (
        <div className="h-full flex items-center justify-center opacity-30">
          <Loading />
        </div>
      ) : series ? (
        <Chart
          options={{
            ...options,
            labels,
            colors,
            title: { ...options.title, text },
            legend: {
              ...options.legend,
              horizontalAlign,
            },
            grid: {
              padding: {
                ...optPadding,
              },
            },
            tooltip: {
              y: {
                formatter: function (value) {
                  if (tooltipFormatter) {
                    return tooltipFormatter(value);
                  } else {
                    return value;
                  }
                },
              },
            },
          }}
          series={series}
          height={height}
          type="donut"
        />
      ) : (
        <div className="w-full h-full flex items-center justify-center">
          <div className="w-[300px]">
            <DonutDataNotFound />
            <p className="text-second text-2xl font-bold text-center mt-5">
              Data Not Found!
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
