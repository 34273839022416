import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Notify from "../components/shared/Notify";
import Menu from "../components/SidebarMenu/SidebarMenu";
import Container from "../components/Container";
import AuthGuard from "../pages/Auth/layouts/AuthGuard";
import { getChallenges } from "../store/slices/challenge";
import { useDispatch } from "react-redux";
import { getCountries } from "../store/slices/country";
import DashboardHeader from "../components/DashboardHeader";

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [sideBar, setSidebar] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getChallenges());
    dispatch(getCountries());
    setSidebar(!(window.innerWidth <= 768));
  }, []);

  const handleSidebar = () => {
    setSidebar(!sideBar);
  };

  return (
    <>
      <AuthGuard>
        <Notify />
        <DashboardHeader sideBar={sideBar} handleSidebar={handleSidebar} />

        <div className="flex relative">
          <Menu sideBar={sideBar} handleSidebar={handleSidebar} />
          <Container>
            <Outlet />
          </Container>
        </div>
      </AuthGuard>
    </>
  );
}
