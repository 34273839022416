import React from "react";

const Middle = () => {
  return (
    <svg
      className="circle-top-middle"
      width="722"
      height="722"
      viewBox="0 0 722 722"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M355.33 0.711456L356.367 65.789"
        stroke="#65B4F0"
        stroke-width="2.24"
      />
      <path
        d="M366.761 0.711456L365.731 65.789"
        stroke="#6AB6F1"
        stroke-width="4.39"
      />
      <path
        d="M378.184 1.07242L375.088 66.0898"
        stroke="#71BAF1"
        stroke-width="1.34"
      />
      <path
        d="M343.914 1.07251L347.01 66.0899"
        stroke="#5BAFEF"
        stroke-width="2.17"
      />
      <path
        d="M389.585 1.802L384.43 66.684"
        stroke="#75BCF2"
        stroke-width="3.82"
      />
      <path
        d="M332.506 1.802L337.661 66.684"
        stroke="#57ADEF"
        stroke-width="4.61"
      />
      <path
        d="M400.963 2.88501L393.748 67.5714"
        stroke="#78BDF2"
        stroke-width="0.94"
      />
      <path
        d="M321.136 2.88492L328.343 67.5713"
        stroke="#52AAEE"
        stroke-width="2.04"
      />
      <path
        d="M309.795 4.33643L319.054 68.7596"
        stroke="#46A5ED"
        stroke-width="1.28"
      />
      <path
        d="M412.296 4.33649L403.037 68.7597"
        stroke="#7CBFF2"
        stroke-width="2.28"
      />
      <path
        d="M423.576 6.14136L412.288 70.2412"
        stroke="#84C3F3"
        stroke-width="5.86"
      />
      <path
        d="M298.515 6.14145L309.81 70.2413"
        stroke="#3FA1EC"
        stroke-width="2.35"
      />
      <path
        d="M287.295 8.30737L300.62 72.0161"
        stroke="#389EEC"
        stroke-width="4.63"
      />
      <path
        d="M434.797 8.30743L421.48 72.0162"
        stroke="#84C3F3"
        stroke-width="2.9"
      />
      <path
        d="M276.149 10.8269L291.48 74.0769"
        stroke="#339BEB"
        stroke-width="5.62"
      />
      <path
        d="M445.949 10.8269L430.61 74.0768"
        stroke="#84C3F3"
        stroke-width="1.33"
      />
      <path
        d="M265.087 13.6998L282.417 76.4309"
        stroke="#2E99EB"
        stroke-width="1.22"
      />
      <path
        d="M457.005 13.6997L439.667 76.4308"
        stroke="#86C4F3"
        stroke-width="1.87"
      />
      <path
        d="M254.122 16.9188L273.436 79.0707"
        stroke="#2997EA"
        stroke-width="1.92"
      />
      <path
        d="M467.969 16.9187L448.662 79.0706"
        stroke="#84C3F3"
        stroke-width="1.76"
      />
      <path
        d="M243.27 20.4836L264.538 81.9963"
        stroke="#2594EA"
        stroke-width="4.36"
      />
      <path
        d="M478.821 20.4836L457.545 81.9963"
        stroke="#81C2F3"
        stroke-width="2.95"
      />
      <path
        d="M489.56 24.3945L466.353 85.2002"
        stroke="#7CBFF2"
        stroke-width="3.02"
      />
      <path
        d="M232.531 24.3945L255.746 85.2002"
        stroke="#2594EA"
        stroke-width="4.4"
      />
      <path
        d="M221.919 28.6511L247.05 88.6822"
        stroke="#2293EA"
        stroke-width="1.89"
      />
      <path
        d="M500.172 28.6512L475.041 88.6822"
        stroke="#7ABEF2"
        stroke-width="1.07"
      />
      <path
        d="M211.45 33.2314L238.475 92.4351"
        stroke="#2293EA"
        stroke-width="1.53"
      />
      <path
        d="M510.64 33.2314L483.623 92.4352"
        stroke="#75BCF2"
        stroke-width="2.85"
      />
      <path
        d="M520.952 38.1425L492.078 96.4664"
        stroke="#67B5F0"
        stroke-width="6.66"
      />
      <path
        d="M201.14 38.1426L230.021 96.4664"
        stroke="#2594EA"
        stroke-width="3.47"
      />
      <path
        d="M190.979 43.3845L221.693 100.761"
        stroke="#2796EA"
        stroke-width="3.27"
      />
      <path
        d="M531.12 43.3845L500.39 100.761"
        stroke="#59AEEF"
        stroke-width="1.37"
      />
      <path
        d="M180.991 48.9424L213.517 105.318"
        stroke="#2C98EB"
        stroke-width="6.7"
      />
      <path
        d="M541.101 48.9424L508.582 105.318"
        stroke="#4DA8EE"
        stroke-width="0.96"
      />
      <path
        d="M171.191 54.8237L205.484 110.132"
        stroke="#2C98EB"
        stroke-width="6.06"
      />
      <path
        d="M550.9 54.8237L516.607 110.132"
        stroke="#3FA1EC"
        stroke-width="0.58"
      />
      <path
        d="M161.579 61.0056L197.607 115.193"
        stroke="#339BEB"
        stroke-width="4.35"
      />
      <path
        d="M560.519 61.0056L524.491 115.193"
        stroke="#339BEB"
        stroke-width="2.6"
      />
      <path
        d="M569.921 67.4886L532.202 120.51"
        stroke="#2997EA"
        stroke-width="2.15"
      />
      <path
        d="M152.162 67.4885L189.889 120.51"
        stroke="#359DEB"
        stroke-width="2.77"
      />
      <path
        d="M579.127 74.2648L539.732 126.061"
        stroke="#2594EA"
        stroke-width="2.1"
      />
      <path
        d="M142.964 74.2648L182.351 126.061"
        stroke="#3FA1EC"
        stroke-width="3.09"
      />
      <path
        d="M588.123 81.3345L547.098 131.852"
        stroke="#1B90E9"
        stroke-width="0.73"
      />
      <path
        d="M133.982 81.3345L174.993 131.852"
        stroke="#44A3ED"
        stroke-width="3.95"
      />
      <path
        d="M596.863 88.6897L554.267 137.876"
        stroke="#168DE9"
        stroke-width="1.44"
      />
      <path
        d="M125.228 88.6898L167.824 137.876"
        stroke="#4DA8EE"
        stroke-width="1.94"
      />
      <path
        d="M116.713 96.3084L160.843 144.126"
        stroke="#57ADEF"
        stroke-width="4.84"
      />
      <path
        d="M605.379 96.3083L561.249 144.126"
        stroke="#1B90E9"
        stroke-width="2.26"
      />
      <path
        d="M108.439 104.205L154.064 150.593"
        stroke="#5BAFEF"
        stroke-width="6.35"
      />
      <path
        d="M613.645 104.205L568.02 150.593"
        stroke="#2293EA"
        stroke-width="3.62"
      />
      <path
        d="M100.428 112.35L147.496 157.272"
        stroke="#5BAFEF"
        stroke-width="3.48"
      />
      <path
        d="M621.671 112.35L574.588 157.272"
        stroke="#2796EA"
        stroke-width="3.44"
      />
      <path
        d="M92.6719 120.751L141.145 164.154"
        stroke="#62B3F0"
        stroke-width="3.03"
      />
      <path
        d="M629.427 120.751L580.946 164.154"
        stroke="#2C98EB"
        stroke-width="7.83"
      />
      <path
        d="M636.897 129.392L587.071 171.231"
        stroke="#F05429"
        stroke-width="3.26"
      />
      <path
        d="M85.1934 129.392L135.012 171.231"
        stroke="#65B4F0"
        stroke-width="4.37"
      />
      <path
        d="M644.104 138.267L592.993 178.503"
        stroke="#F1582E"
        stroke-width="0.73"
      />
      <path
        d="M77.9863 138.267L129.113 178.503"
        stroke="#65B4F0"
        stroke-width="5.96"
      />
      <path
        d="M71.0654 147.367L123.439 185.964"
        stroke="#62B3F0"
        stroke-width="4.32"
      />
      <path
        d="M651.034 147.367L598.652 185.964"
        stroke="#F15F37"
        stroke-width="2.02"
      />
      <path
        d="M64.4366 156.685L118.013 193.597"
        stroke="#65B4F0"
        stroke-width="2.79"
      />
      <path
        d="M657.647 156.685L604.078 193.597"
        stroke="#EAA23D"
        stroke-width="2.47"
      />
      <path
        d="M663.982 166.207L609.264 201.396"
        stroke="#4735EE"
        stroke-width="1.85"
      />
      <path
        d="M58.1084 166.207L112.82 201.396"
        stroke="#5EB0EF"
        stroke-width="4.57"
      />
      <path
        d="M670.01 175.924L614.201 209.354"
        stroke="#E93148"
        stroke-width="6.67"
      />
      <path
        d="M52.0811 175.924L107.889 209.354"
        stroke="#57ADEF"
        stroke-width="1.88"
      />
      <path
        d="M675.721 185.829L618.898 217.469"
        stroke="#EA354C"
        stroke-width="2.49"
      />
      <path
        d="M46.3699 185.828L103.208 217.468"
        stroke="#48A6ED"
        stroke-width="2.68"
      />
      <path
        d="M681.124 195.906L623.302 225.734"
        stroke="#EA3C52"
        stroke-width="2.88"
      />
      <path
        d="M40.9736 195.906L98.7811 225.734"
        stroke="#389EEC"
        stroke-width="4.28"
      />
      <path
        d="M686.19 206.15L627.458 234.127"
        stroke="#EB3E54"
        stroke-width="4.86"
      />
      <path
        d="M35.9012 206.15L94.6254 234.127"
        stroke="#2C98EB"
        stroke-width="4.1"
      />
      <path
        d="M690.947 216.551L631.366 242.648"
        stroke="#EB3E54"
        stroke-width="3.57"
      />
      <path
        d="M31.1515 216.551L90.7401 242.648"
        stroke="#1B90E9"
        stroke-width="4.34"
      />
      <path
        d="M695.358 227.103L634.98 251.289"
        stroke="#EA3C52"
        stroke-width="2.52"
      />
      <path
        d="M26.7325 227.103L87.1177 251.29"
        stroke="#128BE8"
        stroke-width="4.92"
      />
      <path
        d="M699.432 237.782L638.311 260.044"
        stroke="#EA3A50"
        stroke-width="3.01"
      />
      <path
        d="M22.6593 237.782L83.7734 260.044"
        stroke="#1B90E9"
        stroke-width="2.01"
      />
      <path
        d="M703.183 248.59L641.377 268.896"
        stroke="#EA3A50"
        stroke-width="2.62"
      />
      <path
        d="M18.9238 248.59L80.7144 268.896"
        stroke="#2293EA"
        stroke-width="3.6"
      />
      <path
        d="M706.571 259.51L644.164 277.845"
        stroke="#EA3A50"
        stroke-width="0.8"
      />
      <path
        d="M15.5273 259.51L77.9341 277.845"
        stroke="#F04D21"
        stroke-width="4.06"
      />
      <path
        d="M12.4836 270.528L75.439 286.878"
        stroke="#F05327"
        stroke-width="2.38"
      />
      <path
        d="M709.6 270.528L646.66 286.878"
        stroke="#EA354C"
        stroke-width="2.97"
      />
      <path
        d="M9.78516 281.644L73.2291 295.986"
        stroke="#E89B2F"
        stroke-width="2.57"
      />
      <path
        d="M712.298 281.644L648.862 295.986"
        stroke="#EA384E"
        stroke-width="0.98"
      />
      <path
        d="M7.4483 292.834L71.3131 305.154"
        stroke="#3B28ED"
        stroke-width="3.39"
      />
      <path
        d="M714.65 292.834L650.778 305.154"
        stroke="#EA354C"
        stroke-width="2.63"
      />
      <path
        d="M5.46387 304.101L69.6893 314.382"
        stroke="#E7213A"
        stroke-width="2.63"
      />
      <path
        d="M716.634 304.101L652.401 314.382"
        stroke="#EA354C"
        stroke-width="1.83"
      />
      <path
        d="M3.84103 315.412L68.3521 323.655"
        stroke="#E82A42"
        stroke-width="0.91"
      />
      <path
        d="M718.25 315.42L653.731 323.655"
        stroke="#E9334A"
        stroke-width="2.33"
      />
      <path
        d="M719.52 326.783L654.776 332.965"
        stroke="#E92F46"
        stroke-width="6.7"
      />
      <path
        d="M2.57129 326.783L67.3154 332.966"
        stroke="#E92F46"
        stroke-width="2.68"
      />
      <path
        d="M720.422 338.177L655.528 342.306"
        stroke="#E82840"
        stroke-width="1.75"
      />
      <path
        d="M1.6691 338.177L66.5785 342.306"
        stroke="#E92F46"
        stroke-width="2.62"
      />
      <path
        d="M720.978 349.601L655.971 351.67"
        stroke="#E82840"
        stroke-width="5.84"
      />
      <path
        d="M1.12012 349.602L66.1347 351.67"
        stroke="#E93148"
        stroke-width="2.78"
      />
      <path
        d="M378.184 721.001L375.088 655.984"
        stroke="#71BAF1"
        stroke-width="5.22"
      />
      <path
        d="M366.761 721.362L365.731 656.285"
        stroke="#6AB6F1"
        stroke-width="0.42"
      />
      <path
        d="M500.172 693.423L475.041 633.392"
        stroke="#7ABEF2"
        stroke-width="2.07"
      />
      <path
        d="M355.33 721.362L356.367 656.285"
        stroke="#65B4F0"
        stroke-width="2.37"
      />
      <path
        d="M343.914 721.001L347.01 655.984"
        stroke="#5BAFEF"
        stroke-width="3.46"
      />
      <path d="M721.15 361.033H656.113" stroke="#E7213A" stroke-width="4.07" />
      <path
        d="M332.506 720.272L337.661 655.39"
        stroke="#57ADEF"
        stroke-width="1.81"
      />
      <path
        d="M720.978 372.472L655.971 370.404"
        stroke="#E82840"
        stroke-width="2.78"
      />
      <path
        d="M321.136 719.189L328.343 654.495"
        stroke="#52AAEE"
        stroke-width="1.31"
      />
      <path
        d="M720.422 383.889L655.528 379.768"
        stroke="#E82840"
        stroke-width="3.49"
      />
      <path
        d="M309.795 717.737L319.054 653.314"
        stroke="#46A5ED"
        stroke-width="0.98"
      />
      <path
        d="M719.52 395.29L654.776 389.101"
        stroke="#E92F46"
        stroke-width="2.53"
      />
      <path
        d="M298.515 715.932L309.81 651.833"
        stroke="#3FA1EC"
        stroke-width="2.28"
      />
      <path
        d="M718.25 406.654L653.731 398.419"
        stroke="#E9334A"
        stroke-width="5.32"
      />
      <path
        d="M287.295 713.766L300.62 650.058"
        stroke="#389EEC"
        stroke-width="1.34"
      />
      <path
        d="M716.634 417.973L652.401 407.692"
        stroke="#EA354C"
        stroke-width="1.81"
      />
      <path
        d="M276.149 711.247L291.48 647.997"
        stroke="#339BEB"
        stroke-width="4.69"
      />
      <path
        d="M714.65 429.239L650.778 416.92"
        stroke="#EA354C"
        stroke-width="1.1"
      />
      <path
        d="M265.087 708.374L282.417 645.643"
        stroke="#2E99EB"
        stroke-width="3.13"
      />
      <path
        d="M712.298 440.43L648.862 426.088"
        stroke="#EA384E"
        stroke-width="0.75"
      />
      <path
        d="M254.122 705.155L273.436 643.003"
        stroke="#2997EA"
        stroke-width="4.28"
      />
      <path
        d="M709.6 451.546L646.66 435.196"
        stroke="#EA354C"
        stroke-width="1.94"
      />
      <path
        d="M243.27 701.59L264.538 640.078"
        stroke="#2594EA"
        stroke-width="1.79"
      />
      <path
        d="M706.571 462.564L644.164 444.228"
        stroke="#EA3A50"
        stroke-width="2.93"
      />
      <path
        d="M232.531 697.679L255.746 636.874"
        stroke="#2594EA"
        stroke-width="1.24"
      />
      <path
        d="M703.183 473.484L641.377 453.171"
        stroke="#EA3A50"
        stroke-width="5.18"
      />
      <path
        d="M221.919 693.423L247.05 633.392"
        stroke="#2293EA"
        stroke-width="1.01"
      />
      <path
        d="M699.432 484.292L638.311 462.03"
        stroke="#EA3A50"
        stroke-width="4.52"
      />
      <path
        d="M211.45 688.842L238.475 629.631"
        stroke="#2293EA"
        stroke-width="2.39"
      />
      <path
        d="M695.358 494.971L634.98 470.784"
        stroke="#EA3C52"
        stroke-width="2.29"
      />
      <path
        d="M201.14 683.924L230.021 625.607"
        stroke="#2594EA"
        stroke-width="3.22"
      />
      <path
        d="M690.947 505.523L631.366 479.426"
        stroke="#EB3E54"
        stroke-width="0.72"
      />
      <path
        d="M190.979 678.689L221.693 621.313"
        stroke="#2796EA"
        stroke-width="4.79"
      />
      <path
        d="M686.19 515.924L627.458 487.947"
        stroke="#EB3E54"
        stroke-width="6.56"
      />
      <path
        d="M180.991 673.124L213.517 616.756"
        stroke="#2C98EB"
        stroke-width="3.67"
      />
      <path
        d="M681.124 526.167L623.302 496.34"
        stroke="#EA3C52"
        stroke-width="0.69"
      />
      <path
        d="M171.191 667.25L205.484 611.942"
        stroke="#2C98EB"
        stroke-width="5.15"
      />
      <path
        d="M675.721 536.245L618.898 504.605"
        stroke="#EA354C"
        stroke-width="3.36"
      />
      <path
        d="M161.579 661.068L197.607 606.881"
        stroke="#339BEB"
        stroke-width="2.89"
      />
      <path
        d="M670.01 546.15L614.201 512.713"
        stroke="#E93148"
        stroke-width="4"
      />
      <path
        d="M152.162 654.585L189.889 601.564"
        stroke="#359DEB"
        stroke-width="0.6"
      />
      <path
        d="M663.982 555.867L609.264 520.677"
        stroke="#4735EE"
        stroke-width="1.54"
      />
      <path
        d="M142.964 647.809L182.351 596.013"
        stroke="#3FA1EC"
        stroke-width="3.19"
      />
      <path
        d="M657.647 565.389L604.078 528.476"
        stroke="#EAA23D"
        stroke-width="2.94"
      />
      <path
        d="M133.982 640.74L174.993 590.215"
        stroke="#44A3ED"
        stroke-width="1.7"
      />
      <path
        d="M651.034 574.699L598.652 536.11"
        stroke="#F15F37"
        stroke-width="6.99"
      />
      <path
        d="M125.228 633.384L167.824 584.198"
        stroke="#4DA8EE"
        stroke-width="2.46"
      />
      <path
        d="M644.104 583.799L592.993 543.571"
        stroke="#F1582E"
        stroke-width="2.08"
      />
      <path
        d="M116.713 625.758L160.843 577.948"
        stroke="#57ADEF"
        stroke-width="2.47"
      />
      <path
        d="M636.897 592.681L587.071 550.836"
        stroke="#F05429"
        stroke-width="7.25"
      />
      <path
        d="M629.427 601.323L580.946 557.92"
        stroke="#2C98EB"
        stroke-width="1.13"
      />
      <path
        d="M621.671 609.724L574.588 564.802"
        stroke="#2796EA"
        stroke-width="4.41"
      />
      <path
        d="M100.428 609.724L147.496 564.802"
        stroke="#5BAFEF"
        stroke-width="3.99"
      />
      <path
        d="M613.645 617.869L568.02 571.48"
        stroke="#2293EA"
        stroke-width="3.73"
      />
      <path
        d="M92.6719 601.323L141.145 557.92"
        stroke="#62B3F0"
        stroke-width="2.05"
      />
      <path
        d="M605.379 625.758L561.249 577.948"
        stroke="#1B90E9"
        stroke-width="2.41"
      />
      <path
        d="M85.1934 592.681L135.012 550.836"
        stroke="#65B4F0"
        stroke-width="4.78"
      />
      <path
        d="M596.863 633.384L554.267 584.198"
        stroke="#168DE9"
        stroke-width="2.88"
      />
      <path
        d="M77.9863 583.799L129.113 543.571"
        stroke="#65B4F0"
        stroke-width="1.32"
      />
      <path
        d="M588.123 640.74L547.098 590.215"
        stroke="#1B90E9"
        stroke-width="2.07"
      />
      <path
        d="M71.0654 574.699L123.439 536.11"
        stroke="#62B3F0"
        stroke-width="4.05"
      />
      <path
        d="M579.127 647.809L539.732 596.013"
        stroke="#2594EA"
        stroke-width="1.7"
      />
      <path
        d="M64.4366 565.389L118.013 528.476"
        stroke="#65B4F0"
        stroke-width="2.73"
      />
      <path
        d="M569.921 654.585L532.202 601.564"
        stroke="#2997EA"
        stroke-width="1.89"
      />
      <path
        d="M58.1084 555.867L112.82 520.677"
        stroke="#5EB0EF"
        stroke-width="1.57"
      />
      <path
        d="M560.519 661.068L524.491 606.881"
        stroke="#339BEB"
        stroke-width="2.18"
      />
      <path
        d="M52.0811 546.15L107.889 512.713"
        stroke="#57ADEF"
        stroke-width="1.38"
      />
      <path
        d="M550.9 667.25L516.607 611.942"
        stroke="#3FA1EC"
        stroke-width="1.9"
      />
      <path
        d="M46.3699 536.245L103.208 504.605"
        stroke="#48A6ED"
        stroke-width="0.73"
      />
      <path
        d="M541.101 673.124L508.582 616.756"
        stroke="#4DA8EE"
        stroke-width="4.97"
      />
      <path
        d="M40.9736 526.167L98.7811 496.34"
        stroke="#389EEC"
        stroke-width="1.9"
      />
      <path
        d="M531.12 678.689L500.39 621.313"
        stroke="#59AEEF"
        stroke-width="2.63"
      />
      <path
        d="M35.9012 515.924L94.6254 487.947"
        stroke="#2C98EB"
        stroke-width="1.26"
      />
      <path
        d="M520.952 683.924L492.078 625.607"
        stroke="#67B5F0"
        stroke-width="7.69"
      />
      <path
        d="M31.1515 505.523L90.7401 479.426"
        stroke="#1B90E9"
        stroke-width="2.84"
      />
      <path
        d="M108.439 617.869L154.064 571.48"
        stroke="#5BAFEF"
        stroke-width="1.19"
      />
      <path
        d="M26.7325 494.971L87.1177 470.784"
        stroke="#128BE8"
        stroke-width="3.89"
      />
      <path
        d="M510.64 688.842L483.623 629.631"
        stroke="#75BCF2"
        stroke-width="6.83"
      />
      <path
        d="M22.6593 484.292L83.7734 462.03"
        stroke="#1B90E9"
        stroke-width="4.88"
      />
      <path
        d="M489.56 697.679L466.353 636.874"
        stroke="#7CBFF2"
        stroke-width="5.02"
      />
      <path
        d="M18.9238 473.484L80.7144 453.171"
        stroke="#2293EA"
        stroke-width="2.63"
      />
      <path
        d="M478.821 701.59L457.545 640.078"
        stroke="#81C2F3"
        stroke-width="1.77"
      />
      <path
        d="M15.5273 462.564L77.9341 444.228"
        stroke="#F04D21"
        stroke-width="2.86"
      />
      <path
        d="M467.969 705.155L448.662 643.003"
        stroke="#84C3F3"
        stroke-width="6.19"
      />
      <path
        d="M12.4836 451.546L75.439 435.196"
        stroke="#F05327"
        stroke-width="1.77"
      />
      <path
        d="M457.005 708.374L439.667 645.643"
        stroke="#86C4F3"
        stroke-width="4.17"
      />
      <path
        d="M9.78516 440.43L73.2291 426.088"
        stroke="#E89B2F"
        stroke-width="5.01"
      />
      <path
        d="M445.949 711.247L430.61 647.997"
        stroke="#84C3F3"
        stroke-width="2.02"
      />
      <path
        d="M7.4483 429.239L71.3131 416.92"
        stroke="#3B28ED"
        stroke-width="1.72"
      />
      <path
        d="M434.797 713.766L421.48 650.058"
        stroke="#84C3F3"
        stroke-width="2.79"
      />
      <path
        d="M5.46387 417.973L69.6893 407.692"
        stroke="#E7213A"
        stroke-width="1.3"
      />
      <path
        d="M423.577 715.932L412.281 651.833"
        stroke="#84C3F3"
        stroke-width="3.28"
      />
      <path
        d="M3.84103 406.654L68.3521 398.419"
        stroke="#E82A42"
        stroke-width="2.31"
      />
      <path
        d="M412.296 717.737L403.037 653.314"
        stroke="#7CBFF2"
        stroke-width="4.8"
      />
      <path
        d="M2.57129 395.29L67.3154 389.101"
        stroke="#E92F46"
        stroke-width="6.23"
      />
      <path
        d="M400.963 719.189L393.748 654.495"
        stroke="#78BDF2"
        stroke-width="1.43"
      />
      <path
        d="M1.6691 383.889L66.5785 379.768"
        stroke="#E92F46"
        stroke-width="3.18"
      />
      <path
        d="M389.585 720.272L384.43 655.39"
        stroke="#75BCF2"
        stroke-width="0.98"
      />
      <path
        d="M1.12012 372.472L66.1347 370.404"
        stroke="#E93148"
        stroke-width="2.9"
      />
      <path d="M0.94043 361.033H65.9851" stroke="#E93148" stroke-width="3.57" />
      <path d="M0.94043 361.033H65.9851" stroke="#E93148" stroke-width="1.28" />
      <path d="M721.15 361.033H656.113" stroke="#E7213A" stroke-width="4" />
    </svg>
  );
};

export default Middle;
