import React from "react";
import { InactivePlans } from "./InactivePlans";
import { PendingPlans } from "./PendingPlans";
import { ActivePlans } from "./ActivePlans";
import PageStatus from "../../components/PageStatus/PageStatus";
import { useMyChallenge } from "../../hooks/useMyChallenge";
import Loading from "../../components/shared/Loading";
import ErrorMessage from "../../components/shared/ErrorMessage";

export default function MyChallenge() {
  const { error, loading, userChallenges } = useMyChallenge();
  return (
    <div>
      <PageStatus category={"challenges"} currentPage={"My Challenges"} />
      {error ? (
        <ErrorMessage text="Error fetching data!" />
      ) : loading ? (
        <div className="w-full text-center">
          <Loading />
        </div>
      ) : (
        <>
          {userChallenges.STATE_PENDING && (
            <PendingPlans data={userChallenges.STATE_PENDING} />
          )}
          <ActivePlans plans={userChallenges.STATE_ACTIVE} />
          <InactivePlans data={userChallenges.STATE_INACTIVE} />
        </>
      )}
      {/* <img
        src="../dashboardBgWave.png"
        className="absolute mix-blend-color-burn -top-10 -z-10"
        alt=""
      /> */}
    </div>
  );
}
