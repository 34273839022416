import { useState } from "react";
import RequestDetailsInput from "../../../../components/AdminShared/RequestsDetailsInput";
import { usePost } from "../../../../hooks/API/usePost";
import { useNavigate } from "react-router-dom";

export default function CreateTitle() {
  const [form, setForm] = useState({
    title: "",
    description: "",
  });
  const navigate = useNavigate();
  const { loading, handlePostData } = usePost(false);

  function handleInputChange(event) {
    const { name, value } = event.target;
    setForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }

  async function handleCreateBanner() {
    const res = await handlePostData("dashboard/banners", form, true);
    if (!res) return;

    navigate(`${res.banner.id}`);
  }

  return (
    <div>
      <RequestDetailsInput
        title="Title"
        name="title"
        optClass="!input-md !text-lg"
        titleClass="text-lg"
        value={form.title}
        onChange={handleInputChange}
      />
      <RequestDetailsInput
        title="Description"
        name="description"
        optClass="!input-md"
        titleClass="text-lg"
        formClass="mt-7"
        value={form.description}
        onChange={handleInputChange}
      />

      <button
        disabled={loading}
        onClick={handleCreateBanner}
        className="btn btn-success disabled:!text-success-content disabled:!bg-success disabled:opacity-60 mt-7"
      >
        {loading ? (
          <span className="loading loading-dots loading-sm"></span>
        ) : (
          "Create"
        )}
      </button>
    </div>
  );
}
