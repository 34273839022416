import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/auth';
import notifyReducer from './slices/notify';
import topTraderReducer from './slices/topTrader';
import challengeReducer from './slices/challenge';
import userChallengeReducer from './slices/userChallenge';
import countryReducer from './slices/country';

// ----------------------------------------------------------------------

const store = configureStore({
  reducer:{
    auth : authReducer.reducer,
    notify: notifyReducer.reducer,
    topTrader: topTraderReducer.reducer,
    challenge: challengeReducer.reducer,
    userChallenge: userChallengeReducer.reducer,
    country: countryReducer.reducer
  }
});



export default store;
