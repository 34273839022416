import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getData, putData } from "../utils/fetchData";
import { useNavigate } from "react-router-dom";
import { useFetch } from "./API/useFetch";
import { usePostImage } from "./API/usePostImage";

function formatTextSpaces(value) {
  const formattedText = value.replace(/\n/g, "<?br>");
  return formattedText;
}

export function useTicketChat(id, role = "user") {
  const [title, setTitle] = useState("");
  const [error, setError] = useState(false);
  const [user, setUser] = useState(null);
  const [status, setStatus] = useState("");
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState(false);
  const navigate = useNavigate();
  const { loading, fetchData } = useFetch();
  const { handlePostImage } = usePostImage();

  async function getTicket() {
    let API;
    if (role === "admin") {
      API = "admin/tickets/";
    } else {
      API = "tickets/";
    }
    const res = await fetchData(API + id);

    if (res?.ticket) {
      setUser(res.ticket.user_id);
      setStatus(res.ticket.status);
      setMessages(res.ticket.messages);

      setTitle(res.ticket.title);
    } else {
      toast.error("Ticket Not Found!");
      setError(true);
    }
  }

  const pushMess = async (message) => {
    let API;
    if (role === "admin") {
      API = "admin/tickets/send-message/";
    } else {
      API = "tickets/send-message/";
    }

    if (message.message == null) {
      toast.error("Please fill input field!");
      return;
    }
    setNewMessage((prev) => !prev);

    const formData = {
      message: formatTextSpaces(message.message),
    };
    const res = await putData(API + id, formData);
    if (res?.data) {
      setMessages([...messages, res.data.message]);
    } else {
      toast.error("Error  sending message! Please Try Again Later.");
    }
    setNewMessage((prev) => !prev);
  };

  const pushFile = async (file) => {
    let API;
    if (role === "admin") {
      API = "admin/tickets/img";
    } else {
      API = "tickets/img";
    }

    if(file.size > 1048576){
      toast.error("The uploaded file exceeds the maximum size of 1 MB.");
      return;
    }

    setNewMessage((prev) => !prev);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("ticket_id", id);
    const res = await handlePostImage(API, formData);

    if (res?.message) {
      toast.success("file uploaded Successfully!");
      setMessages([...messages, res.message]);
    }
    setNewMessage((prev) => !prev);
  };

  async function handleClose() {
    const res = await getData("admin/tickets/close/" + id);
    if (res?.data) {
      toast.success(res.data.message);
      navigate(-1);
    } else {
      toast.error("Ticket Not Found!");
      setError(true);
    }
  }

  useEffect(() => {
    if (!error && title === "") {
      getTicket();
    }
  }, [title]);

  return {
    loading,
    error,
    title,
    user,
    status,
    messages,
    newMessage,
    pushMess,
    pushFile,
    setNewMessage,
    handleClose,
  };
}
