import TableLayout from "../../components/shared/DarkTable/TableLayout";
import TableFilters from "../../components/shared/DarkTable/TableFilters";
import Filter from "../../components/shared/DarkTable/Filter";
import Loading from "../../components/shared/Loading";
import { Player } from "@lottiefiles/react-lottie-player";
import { useState } from "react";
const initFilter = {
  "from-date": "",
  "to-date": "",
};
export default function TradesTable({ data, loading, setQuery }) {
  const [filters, setFilters] = useState(initFilter);

  function handleFilterChange(e) {
    setFilters((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  function handleSubmitFilter(e) {
    e.preventDefault();
    let query = "";

    if (filters["from-date"]) {
      query += `from-date=${filters["from-date"]}&`;
    }
    if (filters["to-date"]) {
      query += `to-date=${filters["to-date"]}`;
    }

    setQuery(query);
  }

  function handleReset() {
    setFilters(initFilter);
    handleSubmitFilter();
  }

  return (
    <div>
      <TableLayout pagination={false}>
        <TableFilters
          title="Trades"
          handleSubmitFilter={handleSubmitFilter}
          handleReset={handleReset}
        >
          <Filter
            title="From Date"
            name="from-date"
            value={filters["from-date"]}
            onChange={handleFilterChange}
            type="date"
          />
          <Filter
            title="To Date"
            name="to-date"
            value={filters["to-date"]}
            onChange={handleFilterChange}
            type="date"
          />
        </TableFilters>
        <TableContent
          headers={["Symbol", "Type", "Profit/Loss", "Duration", "Close Time"]}
          isDataAvailable={!!data?.[0]}
          data={data}
          loading={loading}
          noDataTitle="Data Not Found!"
        />
      </TableLayout>
    </div>
  );
}

function TableContent({
  headers,
  data,
  loading,
  isDataAvailable,
  noDataTitle = "",
}) {
  return (
    <div className={`w-full overflow-scroll scrollWidth cursor-pointer py-5`}>
      <div className="w-full min-w-[500px] relative ">
        <div className="w-full h-full absolute shadow-[inset_0_-100px_100px_-100px] shadow-gray-800 light:shadow-gray-200 z-10 pointer-events-none" />
        <div className="w-full  grid grid-cols-5 border-b border-b-gray-700 text-second font-semibold pb-2 px-2">
          {headers.map((item) => (
            <div>{item}</div>
          ))}
        </div>
        {loading ? (
          <div className="w-full h-[300px] flex items-center justify-center">
            <Loading optClass="opacity-30" />
          </div>
        ) : (
          <div className="grid grid-cols-1 max-h-[500px] overflow-scroll scrollWidth [&>div:last-child]:!rounded-b-xl ">
            {isDataAvailable &&
              data.map((item, i) => (
                <>
                  <div
                    key={i}
                    className="collapse hover:bg-gray-900/10 !rounded-none"
                  >
                    <input
                      type="checkbox"
                      name="my-accordion-1"
                      className="!min-h-[40px] peer"
                    />
                    <div className="collapse-title text-white peer-checked:text-indigo-400 !px-2 !py-4 !min-h-[40px] grid grid-cols-5 peer-checked:bg-gray-900/30  border-x border-x-transparent peer-checked:border-[--chart-grid] light:peer-checked:bg-gray-400/30">
                      <div>{item.symbol}</div>
                      <div>{item.type}</div>
                      <div
                        className={
                          item.success === "Lost"
                            ? "text-error"
                            : "text-success"
                        }
                      >
                        {item.profit}
                      </div>
                      <div>{item.duration_in_minutes}m</div>
                      <div>{item.close_time}</div>
                    </div>
                    <div className="collapse-content !p-0 border border-[--chart-grid] rounded-b-xl">
                      <div className="w-full max-sm:max-w-[80vw] grid lg:grid-cols-2 xl:grid-cols-3 text-white xl:divide-x divide-gray-700 light:divide-gray-300 py-5">
                        <div className="px-3 sm:px-10">
                          <div className="w-full flex items-center justify-between xl:mb-2">
                            <span className="text-second">Pips</span>
                            <span className="">{item.pips}</span>
                          </div>
                          <div className="w-full flex items-center justify-between">
                            <span className="text-second">Lot Size</span>
                            <span>{item.volume}</span>
                          </div>
                        </div>
                        <div className="px-3 sm:px-10">
                          <div>
                            <div className="w-full flex items-center justify-between xl:mb-2">
                              <span className="text-second">Open Price</span>
                              <span>{item.open_price}</span>
                            </div>
                            <div className="w-full flex items-center justify-between">
                              <span className="text-second">Close Price</span>
                              <span>{item.close_price}</span>
                            </div>
                          </div>
                        </div>
                        <div className="px-3 sm:px-10">
                          <div className="w-full flex items-center justify-between">
                            <span className="text-second">Open Time</span>
                            <span>{item.open_time}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </div>
        )}
      </div>

      {!loading && !isDataAvailable && (
        <div className="w-full h-[335px] ">
          <Player
            src="https://lottie.host/0a086584-47fd-4247-ad9e-c3e39d6ec20d/yB2qgX25mt.json"
            className="player h-[300px]"
            autoplay
            loop
          />
          {noDataTitle !== "" && (
            <p className="-mt-8 text-second text-center font-semibold text-lg">
              {noDataTitle}
            </p>
          )}
        </div>
      )}
    </div>
  );
}
