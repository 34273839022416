export function WithdrawRequestRow({ data, setUserSelected }) {
  const { first_name, last_name, amount, email } = data;
  return (
    <tr>
      <th>{first_name + " " + last_name} </th>
      <td>{email}</td>
      <td>{amount}</td>
      <td>
        <button
          onClick={() => setUserSelected(data)}
          className="btn btn-sm btn-outline btn-info"
        >
          Details
        </button>
      </td>
    </tr>
  );
}
