import React from "react";
import { useNavigate } from "react-router-dom";
import { goldenStartTime } from "../../utils/goldenStartTime";
import { formatComma } from "../../utils/formatNumber";
export function InactiveTableRow({ data }) {
  const navigate = useNavigate();
  const content = goldenStartTime();

  return (
    <tr className="text-neutral-100 ">
      <td>{data.id}</td>
      <td>
        <span
          className={`${
            data.inactive_reason === "fail"
              ? "text-error"
              : data.inactive_reason === "success"
              ? "text-success"
              : ""
          } capitalize`}
        >
          {data.state_type_name}
        </span>
      </td>
      <td>${formatComma(data.balance)}</td>
      <td className="capitalize">{data.challenge_type_name}</td>
      <td className="text-amber-400 text-lg">{data.phase_type_name}</td>
      <td>
        {data.challenge === "golden" && data.start_time === null ? content : ""}
        {data.start_time !== 0 ? data.start_time : "not started"}
        {!data.start_time && "-"}
      </td>
      <td>{data.end_time ?? "-"}</td>
      <td className="text-white font-bold text-center">
        {data.inactive_reason !== "pending start" ? (
          <button
            className="bg-amber-500 text-black w-full rounded-xl py-2 hover:bg-amber-600"
            onClick={() => navigate("analyze/" + data.id)}
          >
            Analyze
          </button>
        ) : content === null ? (
          <p className="text-info">
            <span>Wait for 2 hours</span>
          </p>
        ) : (
          "-"
        )}
      </td>
    </tr>
  );
}
