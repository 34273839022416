import { useNavigate } from "react-router-dom";

export default function ChallengeRequestRow({ data }) {
  const { email, challenge, date, challenge_id } = data;
  const navigate = useNavigate();
  return (
    <tr>
      <th>{email}</th>
      <td>{challenge}</td>
      <td>{date.split("T")[0]}</td>
      <td>
        <div className="flex items-center gap-x-2">
          <button
            onClick={() => navigate(`user/${challenge_id}`)}
            className="btn btn-sm btn-info btn-outline"
          >
            Details
          </button>
        </div>
      </td>
    </tr>
  );
}
