import { useState, useEffect } from "react";
import { authActions } from "../store/slices/auth";
import { useDispatch } from "react-redux";
import {useFetch} from "../hooks/API/useFetch"
import {usePost} from "../hooks/API/usePost"
import {usePostImage} from "../hooks/API/usePostImage"
export function useVerification() {
  const [data, setData] = useState({
    policyLive: null,
    phoneNumber: null,
    phoneVerify: null,
    country: null,
    docType: null,
    step: 0,
  });
  const [verifyState, setVerifyState] = useState(null);
  const [currentPage, setCurrentPage] = useState("policy");
  const [declineReason, setDeclineReason] = useState("");
  const dispatch = useDispatch();
  const {loading, error, fetchData} = useFetch();
  const {handlePostData} = usePost()
  const {loading:identityLoading, handlePostImage} = usePostImage()

  const getVerificationState = async () => {
    const res = await fetchData("users/verification/identity/state");
    if (!res) return;
    setVerifyState(res.verification_type);
    if (res.verification_type === 5) {
      setDeclineReason(res.decline_reason);
    }
  };

  useEffect(() => {
    if (error === false && verifyState === null) {
      getVerificationState();
    }
  }, [error, verifyState]);

  const sendSms = async (number) => {
    const formData = {
      phone: number,
    };

    const res = await handlePostData("users/verification/sms/send", formData, "Code Sent Successfully.");
    if (!res) return;
    setData((prevData) => ({ ...prevData, step: 1 }));
  };

  const verifySms = async (result) => {
    const formData = {
      phone: data.phoneNumber,
      code: result,
    };

    const res = await handlePostData("users/verification/sms/verify", formData, true);
    if (!res) return 0;
    dispatch(
      authActions.setVerification({
        type: 2,
      })
    );
    setCurrentPage("document");
    return 1;
  };

  const handleUploadIdentity = async (file) => {
    if (!data.docType || !data.country) {
      return;
    }
    const formData = new FormData();
    formData.append("identity", file);
    formData.append("identity_type", data.docType);
    formData.append("country", data.country);
    const res = await handlePostImage("users/verification/identity/send", formData, true);
    if (!res) return;
    dispatch(
      authActions.setVerification({
        type: 3,
      })
    );
    handleCurrentPage("uploaded");

  };

  useEffect(() => {
    if (verifyState === 2) {
      setCurrentPage("document");
    }
    if (verifyState === 3) {
      setCurrentPage("uploaded");
    }
    if (verifyState === 4) {
      setCurrentPage("success");
    }
    if (verifyState === 5) {
      setCurrentPage("declined");
    }
  }, [verifyState]);

  function handleSetData(property, value) {
    setData((prev) => ({ ...prev, [property]: value }));
  }
  function handleCurrentPage(property) {
    setCurrentPage(property);
  }
  function uploadAgain() {
    setCurrentPage("document");
  }

  return {
    error,
    loading,
    data,
    currentPage,
    sendSms,
    verifySms,
    handleSetData,
    handleCurrentPage,
    declineReason,
    uploadAgain,
    handleUploadIdentity,
    identityLoading,
  };
}
