import { Outlet, useLocation, useNavigate } from "react-router-dom";

export default function Layout() {
  const navigate = useNavigate();
  const location = useLocation();
  const isAssignedPage = location.pathname.includes("assigned");

  return (
    <>
      <div className="dashboard-box">
        <div role="tablist" className="tabs tabs-lifted tabs-lg">
          <div
            role="tab"
            onClick={() => navigate("/admin/tickets")}
            className={`tab ${
              !isAssignedPage
                ? "tab-active light:[--tab-border-color:#4b5563]"
                : "text-white light:border-b-[#4b5563]"
            }`}
          >
            Unassigned
          </div>
          <div
            role="tab"
            onClick={() => navigate("/admin/tickets/assigned")}
            className={`tab ${
              isAssignedPage
                ? "tab-active light:[--tab-border-color:#4b5563]"
                : "text-white light:border-b-[#4b5563]"
            }`}
          >
            Assigned
          </div>
        </div>
        <div className="mt-5">
          <Outlet />
        </div>
      </div>
    </>
  );
}
