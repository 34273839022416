import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';


export default function RequestsDetailsInputMultiple({
  title,
  value,
  setValue,
  disabled = false,
  titleClass,
  formClass,
  optClass,
  ...props
}) {
  return (
    <label className={`form-control ${formClass}`}>
      <div className="label">
        <span className={`label-text text-slate-100 ${titleClass}`}>
          {title}:
        </span>
      </div>
        <TagsInput         className={`input input-sm text-base  ${optClass} ${
            disabled
                ? "!bg-[#00000055] !text-slate-300 input-disabled border-0"
                : "input-bordered bg-slate-300 focus:bg-slate-100"
        }`}  value={value} onChange={(tags) => setValue(tags)}  />
    </label>
  );
}
