import React, { useEffect, useRef, useState } from "react";
import { IoIosAttach } from "react-icons/io";
const defaultInputs = {
  message: "",
  isPrivate: false,
  files: [],
};

export function ChatForm({ addNewMessage, addNewFile, handleClose, loading }) {
  const fileInputRef = useRef(null);
  const [inputs, setInputs] = useState(defaultInputs);
  const [fileUpload, setFileUpload] = useState(null);
  const send = useRef();

  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.ctrlKey && event.key === "Enter") {
        send.current.click();
      }
    });
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileUpload(file.name);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleCancelImage = () => {
    fileInputRef.current.value = "";
    setFileUpload((prev) => null);
  };

  async function handleSubmit() {
    if (inputs.message) {
      await addNewMessage(inputs);
    }

    if (fileInputRef.current.files[0]) {
      addNewFile(fileInputRef.current.files[0]);
    }
    fileInputRef.current.value = "";
    setFileUpload((prev) => null);
    setInputs(defaultInputs);
  }
  return (
    <div className="w-full ">
      <form onSubmit={(e) => e.preventDefault()}>
        {fileUpload ? (
          <div className="size-28 border-4 border-light-border rounded-2xl relative">
            <button
              onClick={handleCancelImage}
              className="size-6 flex items-center justify-center bg-error absolute -left-2 -top-2 p-2 rounded-full border border-transparent hover:border-red-200 transition-colors"
            >
              x
            </button>
            <img
              loading="lazy"
              src={URL.createObjectURL(fileInputRef?.current?.files[0])}
              className="w-full h-full object-cover rounded-xl"
              alt=""
            />
          </div>
        ) : !loading ? (
          <textarea
            disabled={loading}
            value={inputs.message}
            onChange={(e) =>
              setInputs((prev) => ({ ...prev, message: e.target.value }))
            }
            autoFocus
            onInvalid={(e) => e.preventDefault()}
            required={true}
            className="w-full bg-gray-900/40 text-white outline-0 border-0 p-3 rounded-box"
            placeholder="write your message here"
            rows="6"
          />
        ) : (
          <div className="w-full rounded-box flex items-center justify-center h-[168px] bg-gray-900/40">
            <div className="loading loading-dots text-gray-500" />
          </div>
        )}
        <div className="flex items-center justify-end mt-5">
          <div className="flex flex-wrap-reverse items-center gap-2">
            <button
              disabled={loading}
              className="btn btn-success disabled:!text-success-content disabled:!bg-success"
              onClick={handleClose}
            >
              close ticket
            </button>
            <input
              disabled={loading}
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept="*"
              hidden
              onChange={handleImageChange}
            />
            <button
              disabled={loading}
              className="btn disabled:!bg-base-100 disabled:!text-base-content light:border-neutral-400"
              onClick={handleButtonClick}
            >
              <IoIosAttach size={20} />
              Attach
            </button>
            <button
              disabled={loading}
              ref={send}
              className="btn !bg-[var(--indigo-purple)] hover:!bg-[var(--indigo-purple-hover)] !text-white !border-[--border-light] hover:!border-indigo-500 light:!hover:border-orange-500"
              onClick={handleSubmit}
            >
              send
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
