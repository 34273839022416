import React from "react";
import Loading from "../../../../components/shared/Loading";
import useUserPayment from "../../../../hooks/Admin/User/useUserPayment";
import TableBackground from "../../../../components/AdminShared/TableBackground";
import { Link } from "react-router-dom";
import { usePagination } from "../../../../hooks/usePagination";
import MyWalletFilter from "../../../../components/shared/Finance/MyWalletFilter";
import Pagination from "../../../../components/AdminShared/Pagination";
import { formatComma } from "../../../../utils/formatNumber";

const initialFilter = {
  status: "",
  wallettypeid: "",
  transitiontypeid: "",
  fromDate: "",
  toDate: "",
};

const filterOptions = [
  "status",
  "wallettypeid",
  "transitiontypeid",
  "fromDate",
  "toDate",
];

export default function UserPayment() {
  const {
    pagination,
    handleFilterChange,
    query,
    handleSubmitFilter,
    handleChangePagination,
    filter,
  } = usePagination(initialFilter, filterOptions);
  const { error, loading, transactions } = useUserPayment(pagination, query);
  return (
    <>
      <TableBackground>
        {error ? (
          <p>Error loading data</p>
        ) : loading ? (
          <div className="w-full text-center">
            <Loading />
          </div>
        ) : (
          <div className="content mx-5 py-5 text-center text-white ">
            <div className=" flex items-center justify-between text-center text-white mb-5">
              <h3 className="text-2xl text-center w-full ">User Payment</h3>
              <Link
                to="/admin/users"
                className="btn btn-secondary btn-outline ml-auto"
              >
                Back
              </Link>
            </div>
            {transactions && (
              <MyWalletFilter
                data={transactions}
                handleFilterChange={handleFilterChange}
                handleSubmitFilter={handleSubmitFilter}
                filter={filter}
              />
            )}
            <div className="overflow-x-auto mb-5">
              <table className="table">
                <thead>
                  <tr className="text-white">
                    <th>Id</th>
                    <th>Type</th>
                    <th>Wallet</th>
                    <th>Direction</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Hash</th>
                    <th>Decline Reason</th>
                  </tr>
                </thead>
                <tbody>
                  {error ? (
                    "Error fetching data"
                  ) : loading || transactions == null ? (
                    <div className="w-full flex items-center justify-center h-[440px]">
                      <Loading />
                    </div>
                  ) : (
                    <>
                      {transactions.transactions.data.map((row) => (
                        <tr>
                          <td>{row.id}</td>
                          <td>{row.type_name ?? "Inner"}</td>
                          <td>{row.wallet_type_name}</td>
                          <td
                            className={`${
                              row.direction === "in"
                                ? "text-green-400"
                                : "text-red-400"
                            }`}
                          >
                            ${formatComma(row.amount)}
                          </td>
                          <td className="capitalize">{row.status}</td>
                          <td>{row.created_at.split("T")[0]}</td>
                          <td>{row.transaction_number}</td>
                          <td>{row.decline_reason ?? "-"}</td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            {transactions?.transactions && (
              <Pagination
                handleOnClick={handleChangePagination}
                data={transactions.transactions}
                optClass="mt-5"
              />
            )}
          </div>
        )}
      </TableBackground>
    </>
  );
}
