import { useEffect, useState } from "react";
import { useFetch } from "../API/useFetch";

export function useReferralDetail(id) {
  const { loading, fetchData } = useFetch();
  const [details, setDetails] = useState();
  const [profitByMonth, setProfitByMonth] = useState({ x: [], y: [] });
  const [donutChart, setDonutChart] = useState();

  async function getData() {
    const res = await fetchData(`users/referrals/user/${id}`);

    if (!res) return;

    setDetails(res);

    setProfitByMonth({
      x: res.profit_per_month.map((item) => item.month_name),
      y: res.profit_per_month.map((item) => item.count),
    });

    setDonutChart({
      data: res.donut_chart_profits.map((item) => item.earning ?? 0),
      legends: res.donut_chart_profits.map((item) => item.name),
    });
  }

  useEffect(() => {
    getData();
  }, []);

  return { loading, details, profitByMonth, donutChart };
}
