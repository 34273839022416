import React from "react";
import { TradeHistoryRow } from "./RowData";
import StatusLogo from "../../../components/PageStatus/StatusLogo";
import { AnalysisStatus } from "./AnalysisStatus";
import { GeneralInfo } from "./GeneralInfo";
import { ChallengeStatistics } from "./ChallengeStatistics";
import { HistoryChart } from "./HistoryChart";
import Loading from "../../../components/shared/Loading";
import DownloadCSV from "../DownloadCSV";
import { useAnalyze } from "../../../hooks/useAnalyzePanel";
import Table from "../../../components/Table";
import { useNavigate } from "react-router-dom";
// import { logDOM } from "@testing-library/react";
// import DownloadButton from "../shared/DownloadButton";
import { IoArrowBack } from "react-icons/io5";
const headers = [
  "symbol",
  "type",
  "status",
  "Profit/Loss",
  "Pips",
  "open time",
  "close time",
  "lot",
  "duration",
  "open price",
  "close price",
];
export default function AnalyzePanel({ match }) {
  const {
    loading,
    totals,
    trades,
    totalsArchive,
    totalArchives,
    thisChallenge,
    tadeLoading,
  } = useAnalyze();
  const navigate = useNavigate();
  return (
    <>
      <div className="relative flex items-center justify-end mt-8 ">
        <button
          onClick={() => navigate(-1)}
          className="btn gap-1 absolute top-3 left-0 pb-1 justify-start bg-[var(--indigo-purple)] hover:bg-[var(--indigo-purple-hover)] text-white border-indigo-600 hover:border-indigo-500"
        >
          <IoArrowBack size={19} />
          <span className="-mb-1.5"> Back</span>
        </button>

        <div className="flex items-center text-end gap-5">
          <div className="max-w-[70px] max-h-[70px]">
            <svg
              className="hidden md:block"
              width="70"
              height="70"
              viewBox="0 0 104 104"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M51.8447 0L0 51.8447L51.8447 103.689L103.689 51.8447L51.8447 0ZM51.7928 9.65276C53.1421 14.9462 57.2938 19.0979 62.5873 20.4473C57.2938 21.7966 53.1421 25.9483 51.7928 31.2418C50.4954 25.9483 46.3437 21.7966 41.0502 20.4473C46.3437 19.0979 50.4954 14.9981 51.7928 9.65276ZM51.8447 93.31L20.7586 62.224L20.6029 62.0682C14.8424 56.1521 14.8943 46.7069 20.7586 40.8944L36.0163 25.637L46.7069 36.3276L31.1379 51.8965L62.224 82.9826L51.8447 93.31ZM67.3618 77.7411L56.9825 67.3618L72.5514 51.7928L67.3618 46.6032L51.7928 62.1721L41.4135 51.7928L67.3099 25.8964L77.1701 35.7568L77.6892 36.2757L82.8789 41.4654C88.5874 47.1739 88.5874 56.4634 82.8789 62.224L67.3618 77.7411Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="text-white">
            <p className="capitalize font-bold text-3xl">GoldenFund</p>
            <p className="font-medium text-xl uppercase">Analyze panel</p>
          </div>
        </div>
      </div>

      {loading ? (
        <Loading optClass={"block mx-auto mt-20"} />
      ) : (
        <>
          <GeneralInfo
            data={totals}
            dataArchive={totalsArchive}
            dataChallenge={thisChallenge}
          />
          <AnalysisStatus
            data={totals}
            dataArchive={totalsArchive}
            dataChallenge={thisChallenge}
          />
          <HistoryChart dataArchives={totalArchives} total={totals} />
        </>
      )}
      {tadeLoading ? (
        <Loading optClass={"block mx-auto mt-20"} />
      ) : (
        <>
          {trades != null && totalArchives != null && totals != null && (
            <ChallengeStatistics
              data={trades}
              archives={totalArchives}
              total={totals}
              primaryBalance={thisChallenge.balance}
            />
          )}
          {trades && (
            <div className="pt-12">
              <Table headers={headers}>
                {trades.map((row, index) => (
                  <TradeHistoryRow row={row} index={index} />
                ))}
              </Table>
              <DownloadCSV data={trades} />
            </div>
          )}
        </>
      )}
    </>
  );
}
