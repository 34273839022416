import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../API/useFetch";
import { usePost } from "../../API/usePost";
export function useChallengeDetails(id) {
  const [error, setError] = useState(false);
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const { loading, fetchData } = useFetch();
  const { loading: submitLoading, handlePostData } = usePost(false);

  async function getUserData() {
    setError(false);
    const res = await fetchData(`challenges/user-challenges/requests/${id}`);
    if (res?.user_challenge) {
      setUser(res.user_challenge);
    } else {
      setError(`The user with ID ${id} was not found.`);
    }
  }

  const handleSubmit = async (data) => {
    const _ = await handlePostData(
      "challenges/user-challenges/activate",
      data,
      "User challenge activated successfully!"
    );
    handleBack();
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getUserData();
  }, []);

  return {
    user,
    handleSubmit,
    loading,
    submitLoading,
    error,
    handleBack,
  };
}
