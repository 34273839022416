import { useEffect, useRef, useState } from "react";
import { useFetch } from "../../../../hooks/API/useFetch";
import Loading from "../../../../components/shared/Loading";
import RequestDetailsInput from "../../../../components/AdminShared/RequestsDetailsInput";
import { usePostImage } from "../../../../hooks/API/usePostImage";
import { useNavigate, useParams } from "react-router-dom";
import TableBackground from "../../../../components/AdminShared/TableBackground";
import { usePut } from "../../../../hooks/API/usePut";

const imageSizes = {
  1: "w-[901px] h-[396px]",
  2: "w-[350px] h-[265px]",
};

export default function Uploadimages() {
  const { id: bannerID } = useParams();
  const navigate = useNavigate();
  const [banner, setBanner] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [inputs, setInputs] = useState({});
  const [num, setNum] = useState(1);
  const [deviceType, setDeviceType] = useState(1);
  const [imgPreview, setImgPreview] = useState({
    deviceType: null,
    url: "",
  });

  const { loading, fetchData } = useFetch();
  const { loading: uploadLoading, handlePostImage } = usePostImage();
  const { loading: inputsLoading, handlePutData } = usePut();
  const { loading: statusLoading, handlePutData: stausPutData } = usePut();

  const fileInputRef = useRef(null);

  function handleButtonClick(i) {
    fileInputRef.current.click();
    setDeviceType(i);
  }

  async function getBannerData() {
    const res = await fetchData(`dashboard/banners/${bannerID}`);
    if (!res) return;

    setBanner(res);

    setSelectedImages(res.banner.images.map((item) => item.src));
    setNum(res.banner.images.length);
  }

  async function handleUploadImg() {
    if (!fileInputRef.current.files[0]) return;
    const formData = new FormData();
    formData.append("photo", fileInputRef.current.files[0]);
    formData.append("id", bannerID);
    formData.append("device_type_id", deviceType);
    const res = await handlePostImage(
      "dashboard/banners/img",
      formData,
      "banner created successfully"
    );
    if (!res) return;

    setNum((prev) => prev + 1);
    const imagesUrl = selectedImages;
    imagesUrl[deviceType - 1] = res.src;
    setSelectedImages(imagesUrl);
    setImgPreview({});
  }

  function showImgPreview(event) {
    const file = event.target.files[0];
    setImgPreview({ deviceType: deviceType, url: URL.createObjectURL(file) });
  }

  function handleInputsChange(e) {
    const { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
  }

  async function handleUpdateInputs() {
    if (Object.keys(inputs).length === 0) return;

    const res = await handlePutData(`dashboard/banners/${bannerID}`, inputs);
  }

  async function handleToggleStatus() {
    const data = {
      status: banner.banner?.status_name === "Active" ? 3 : 2,
    };

    const res = await stausPutData(`dashboard/banners/${bannerID}`, data, true);
    getBannerData();
  }

  useEffect(() => {
    getBannerData();
  }, []);

  return (
    <>
      <TableBackground>
        <div className="flex items-center justify-between gap-3">
          <button
            onClick={() => navigate("/admin/banner")}
            className="btn btn-success"
          >
            Back
          </button>
          <button
            onClick={handleToggleStatus}
            className={`btn ${
              banner.banner?.status_name === "Active"
                ? "btn btn-error"
                : "btn-success"
            }`}
          >
            {statusLoading ? (
              <span className="loading loading-dots loading-sm" />
            ) : banner.banner?.status_name === "Active" ? (
              "Deactivate"
            ) : (
              "Activate"
            )}
          </button>
        </div>
        <div className="text-white mt-5">
          {loading ? (
            <div className="flex justify-center items-center h-[400px]">
              <Loading />
            </div>
          ) : (
            <>
              <div className="grid grid-cols-2 gap-3">
                <RequestDetailsInput
                  title="Title"
                  name="title"
                  optClass="!input-md !text-lg"
                  titleClass="text-lg"
                  defaultValue={banner.banner.title}
                  onChange={handleInputsChange}
                />
                <RequestDetailsInput
                  title="Description"
                  name="description"
                  optClass="!input-md !text-lg"
                  titleClass="text-lg"
                  defaultValue={banner.banner.description}
                  onChange={handleInputsChange}
                />
              </div>

              <button
                onClick={handleUpdateInputs}
                disabled={inputsLoading}
                className="btn btn-warning border-yellow-500/70 !text-yellow-500/70 hover:!text-warning-content btn-outline mt-3"
              >
                {inputsLoading ? (
                  <span className="loading loading-dots loading-sm" />
                ) : (
                  "Edit"
                )}
              </button>

              {Object.keys(banner.device_types).map((item, i) => {
                return (
                  <div className="flex items-center justify-center flex-col my-10 text-lg">
                    {i <= num && <div>{banner.device_types[item]}</div>}

                    {imgPreview.deviceType - 1 === i ? (
                      <div className="relative">
                        <img
                          src={imgPreview.url}
                          className={`${uploadLoading && "opacity-50"} ${
                            imageSizes[item]
                          }`}
                          alt=""
                        />
                        {uploadLoading && (
                          <span className="loading loading-bars absolute top-1/2 left-1/2 -translate-y-5 -translate-x-5 " />
                        )}
                        <div className="flex items-center gap-3 mt-3">
                          <button
                            onClick={handleUploadImg}
                            disabled={uploadLoading}
                            className="btn btn-success btn-wide "
                          >
                            {uploadLoading ? (
                              <span className="loading loading-dots loading-sm !text-neutral-400" />
                            ) : (
                              "Confirm"
                            )}
                          </button>
                          <button
                            disabled={uploadLoading}
                            onClick={() => {
                              setImgPreview({});
                              fileInputRef.current.value = "";
                            }}
                            className="btn btn-error btn-wide disabled:!text-neutral-600"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    ) : num === i ? (
                      <button
                        onClick={() => handleButtonClick(i + 1)}
                        disabled={uploadLoading}
                        className="w-[200px] h-[200px] border border-dashed font-bold"
                      >
                        Upload Image
                      </button>
                    ) : (
                      i < num && (
                        <div
                          onClick={() => handleButtonClick(i + 1)}
                          className="w-fit mx-auto mb-10 relative group"
                        >
                          <img
                            src={selectedImages[i]}
                            className={imageSizes[item]}
                            alt=""
                          />
                          <span className="opacity-0 group-hover:opacity-100 cursor-pointer transition-all flex items-center justify-center text-warning underline top-0 left-0 w-full h-full bg-neutral-800/60 absolute">
                            Edit
                          </span>
                        </div>
                      )
                    )}
                  </div>
                );
              })}

              <div className="">
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={showImgPreview}
                />
              </div>
            </>
          )}
        </div>
      </TableBackground>
    </>
  );
}
