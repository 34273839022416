import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getData } from "../utils/fetchData";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { usePost } from "./API/usePost";
const mt4Brokers = ["Swissquote", "Uniglobe", "HYCM", "Axi"];
const mt5Brokers = ["Uniglobe", "HYCM", "Swissquote", "OANDA"];

export function useNewChallenge(data) {
  const challenges = useSelector((state) => state.challenge);
  const [challenge, setChallenge] = useState(null);
  const [balance, setBalance] = useState(null);
  const [broker, setBroker] = useState(null);
  const [brokers, setBrokers] = useState(mt4Brokers);
  const [platform, setPlatform] = useState("MT4");
  const [modalVisible, setModalVisible] = useState(false);
  const [wallet, setWallet] = useState(null);
  const { loading, handlePostData } = usePost(false);
  let balancePrice = 0;

  const navigate = useNavigate();

  const handlebuy = async (token) => {
    if (broker === null) {
      toast.error("please select you broker");
      return;
    }

    const formData = {
      balance_id: balance.id,
      broker: broker,
      platform: platform,
      token: token,
    };

    const res = await handlePostData(
      "challenges/user-challenges/pay",
      formData,
      true
    );
    if (!res) return;
    navigate("/dashboard/my-challenges");
  };

  function handleModalVisible() {
    if (!broker) return toast.error("Please select a broker!");
    if (challenge && balance && platform && wallet) {
      setModalVisible(true);
    }
  }
  const getBalance = async () => {
    const response = await getData("finance/wallets/balance");
    if (response?.data) {
      setWallet(response.data.balance);
    }
  };

  useEffect(() => {
    if (wallet === null) {
      getBalance();
    }
  }, [wallet]);

  useEffect(() => {
    const selectedChallenge = challenges?.challenges.find(
      (item) => item.name === data.accountType
    );
    setChallenge(selectedChallenge);

    const nonInactiveBalance = selectedChallenge?.challenge_balances.find(
      (balance) => balance.status !== "inactive"
    );
    setBalance(nonInactiveBalance);
  }, [data.accountType]);

  const handlePlatformChange = async (data) => {
    setPlatform(data);
    if (data === "MT4") {
      await setBrokers(mt4Brokers);
      await setBroker(null);
    } else if (data === "MT5") {
      await setBrokers(mt5Brokers);
      await setBroker(null);
    }
    setBroker(null);
  };

  if (balance != null) {
    balancePrice = balance.balance_phases.filter(
      (item) => (item.registration_fee !== 0 || item.pre_investment !== null)
    )[0];
  }

  return {
    loading,
    modalVisible,
    setModalVisible,
    handleModalVisible,
    challenge,
    balance,
    platform,
    broker,
    wallet,
    balancePrice,
    handlebuy,
    setBalance,
    handlePlatformChange,
    setBroker,
    brokers,
  };
}
