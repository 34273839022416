import { useEffect, useState } from "react";
import { useFetch } from "./API/useFetch";
export function useAnalyzePanelN(id) {
  const [data, setData] = useState(null);
  const [tradesData, setTradesData] = useState(null);
  const [query, setQuery] = useState("");
  const { loading, fetchData, error } = useFetch();
  const {
    loading: tradesLoading,
    fetchData: tradesFetch,
    error: tradesError,
  } = useFetch(true, false);

  async function getData() {
    const res = await fetchData(`challenges/user-challenges/analyze/${id}`);
    if (!res) return;

    setData(res);
  }

  async function getTradesData() {
    console.log(`challenges/user-challenges/analyze/trades/${id}?${query}`);
    const res = await tradesFetch(
      `challenges/user-challenges/analyze/trades/${id}?${query}`
    );
    if (!res) return;

    setTradesData(res);
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getTradesData();
  }, [query]);

  return {
    loading,
    tradesLoading,
    data,
    tradesData,
    setQuery,
    error,
    tradesError,
  };
}
