import React from "react";
// import DownloadButton from "../shared/DownloadButton";
import { format } from 'date-fns';
import TradeStatistics from "../../../utils/TradeStatistics";

function formatDollar(num) {
  return `$${Intl.NumberFormat().format(num)}`;
}
function formatPercent(num) {
  return `${Intl.NumberFormat().format(num)}%`;
}
export function ChallengeStatistics({data,archives, total, primaryBalance}) {
  const tradeStats   = new TradeStatistics(data, archives, total);
  const statistics   = tradeStats.getAllStatistics();
  const goldenFactor = tradeStats.getGoldenFactor();
  return (
    <>
      <p className="font-bold text-2xl text-white  leading-none mt-10 mb-2">
        Challenge statistics
      </p>
      <div className="w-full flex flex-col md:flex-row items-stretch [&>div]:grow gap-5">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 bg-[var(--indigo-purple-dark)] shadow-[0_0_7px_0] shadow-[rgba(0,0,0,0.5)] p-2 join">
          <ul className="join-item border-r-0 sm:border-r md:border-r-0 lg:border-r pl-7 pr-3 capitalize">
            <ChallengeStatisticsLi text="trade counts" value={statistics.tradeCount} optClass="text-green-500" />
            <ChallengeStatisticsLi text="failed trades count" value={statistics.failedTrades} optClass="text-red-600"/>
            <ChallengeStatisticsLi text="succeed trades count" value={statistics.successTrades} optClass="text-green-500"/>
            <ChallengeStatisticsLi
              text="overall profit"
              value={total.balance - +primaryBalance}
              format={formatDollar}
              optClass={total.balance - +primaryBalance > 0 ? "text-green-500" : "text-red-500"}
            />
            <ChallengeStatisticsLi
              text="traded volume"
              value={`${statistics.totalLot} Lots`}
              optClass="text-white"
            />
            <ChallengeStatisticsLi
              text="average profit percent"
              value={0.3}
              format={formatPercent}
              optClass="text-green-500"
            />
          </ul>
          <ul className="join-item pl-7 pr-3 capitalize">

            <ChallengeStatisticsLi text={"Average Loss Percent"} format={formatPercent} value={-6} optClass="text-red-600"/>
            <ChallengeStatisticsLi
              text={"Best Trade"}
              value={statistics.bestProfit !== undefined? statistics.bestProfit.profit: 0}
              format={formatDollar}
              optClass="text-green-500"
            />
            <ChallengeStatisticsLi
              text={"Worst Trade"}
              value={statistics.worstProfit !== undefined? statistics.worstProfit.profit : 0}
              format={formatDollar}
              optClass="text-red-600"
            />
            <ChallengeStatisticsLi
              text={"Highest Daily Balance"}
              value={statistics.bestBalance.balance}
              format={formatDollar}
              optClass="text-green-500"
            />
            <ChallengeStatisticsLi
              text={"Highest Balance Date"}
              value={ format(new Date(statistics.bestBalance.created_at), 'MM/dd/yyyy')}
              valueColor={statistics.bestBalance.created_at}
              optClass="text-green-500"
            />
          </ul>
        </div>
        <div className="  bg-[var(--indigo-purple-dark)] shadow-[0_0_7px_0] shadow-[rgba(0,0,0,0.5)] p-2 rounded-lg">
          <ul className="w-full px-5 capitalize">
            <ChallengeStatisticsLi text="Profit Factor" value={statistics.profitFactor.toFixed(2)} optClass="text-green-500"/>
            <ChallengeStatisticsLi text="Golden Factor" value={goldenFactor.toFixed(2)} optClass="text-green-500"/>
          </ul>
        </div>
      </div>
      
    </>
  );
}
function ChallengeStatisticsLi({ text, value, format, optClass }) {
  return (
    <li className="flex items-center justify-between relative before:absolute before:top-3 before:-left-4 before:content-[''] before:w-2.5 before:h-2.5 before:bg-transparent before:rounded-full before:border before:border-slate-300  font-medium text-lg py-1">
      <span className="text-white">{text}</span>
      <span
        className={`font-semibold ${optClass}`}
      >
        {format ? format(value) : value}
      </span>
    </li>
  );
}
