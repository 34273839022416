import { useState, useEffect } from "react";
import { authActions } from "../store/slices/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkRequirement } from "../pages/Auth/shared/InputRequirment";
import { usePost } from "./API/usePost";
import Cookies from "js-cookie";

const intialInfo = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  confirmPass: "",
  referral_token: null,
  role: "trader",
};

const intialErrors = {
  nameErr: false,
  lastNameErr: false,
  emailErr: false,
  passwordErr: false,
  confirmPassErr: false,
};
const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export function useSignUp() {
  const [signupInfo, setSignupInfo] = useState(intialInfo);
  const [errors, setErrors] = useState(intialErrors);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const { loading, handlePostData } = usePost(false);

  const dispatch = useDispatch();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const refParam = urlParams.get("ref");
    if (refParam) {
      setSignupInfo({
        ...signupInfo,
        referral_token: refParam,
      });
    }
  }, []);
  async function register() {
    const res = await handlePostData(
      "auth/register",
      signupInfo,
      "successfully registered"
    );
    if (!res) return;

    await dispatch(
      authActions.loginSuccess({
        token: res.token,
        user: res.user,
        role: res.role,
        permission: res.permissions,
      })
    );

    Cookies.set('authToken', res.token, {
      expires: 7,
      secure: true,
      sameSite: 'strict'
    });

    navigate("/email-verification");
  }

  function handleSubmit() {
    const isInputsUnCorrect = Object.values(errors).every(
      (item) => item !== false
    );
    if (isInputsUnCorrect) {
      return;
    }
    if (!emailPattern.test(signupInfo.email)) {
      handleSetErrors({ emailErr: true });
      return;
    }
    const requirements = checkRequirement(signupInfo.password);
    if (!requirements.every((x) => x === true)) return;
    if (signupInfo.password !== signupInfo.confirmPass) {
      setErrors({ ...errors, confirmPassErr: true });
      return;
    }
    register();
  }

  function handleSetErrors(values) {
    setErrors((prev) => ({ ...prev, ...values }));
  }
  function handleSetSignUpInfo(values) {
    setSignupInfo((prev) => ({ ...prev, ...values }));
  }
  function handleCurrentPage(page) {
    setCurrentPage((prev) => page);
  }
  return {
    signupInfo,
    errors,
    loading,
    handleSubmit,
    handleSetErrors,
    handleSetSignUpInfo,
    currentPage,
    handleCurrentPage,
    modalVisible,
    setModalVisible,
  };
}
