import React from "react";

export function Copyright() {
  const date = new Date().getFullYear();
  return (
    <div className="text-center opacity-50 text-white mx-auto mb-3">
      <p>All Rights Reserved.</p>
      <p>&copy; {date} Golden Fund</p>
    </div>
  );
}
