import { IoArrowBack } from "react-icons/io5";

export default function ModalLayout({ id = "", children, closeBtn = true }) {
  return (
    <dialog id={id} className="modal">
      <div className="modal-box bg-indigo-950 light:bg-[--indigo-purple-dark] p-5 border border-[var(--indigo-purple)] text-left">
        {closeBtn && (
          <form method="dialog" className="text-left">
            <button className="btn btn-sm btn-square bg-gradient-to-br from-indigo-700 to-indigo-800 light:from-[--indigo-purple] light:to-[--indigo-purple-light] border-0 text-white ">
              <IoArrowBack size={20} />
            </button>
          </form>
        )}
        {children}
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
}
