import {useState} from 'react';
import { toast } from "react-toastify";
import {postDataImge} from "../../utils/fetchData";


const useUpdateImage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

    const postImage = async (data, url) => {
        setLoading((prev) => true);

            const res = await postDataImge(url, data);

            if (res?.status === "success") {
                toast.success("Image Updated Successfully!", { toastId: 1 });
            } else {
                setError(true);
                toast.error("Error  Updating Image! Please Try Again Later.");
            }
        setLoading((prev) => false);
    };

    return {
       loading, postImage, error
  };
};

export default useUpdateImage;
