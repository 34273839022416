import Cookies from "js-cookie";

let cachedToken = null;

export const getToken = () => {
    // If token is cached, return it
    // if (cachedToken) {
    // return cachedToken;
    // }

    const authToken = Cookies.get('authToken');
    if (authToken){
        cachedToken = authToken;
        return cachedToken;
    }

    return null;
};

export const clearToken = () => {
    cachedToken = null;
};