import React from "react";
import ErrorMessage from "../../components/shared/ErrorMessage";
import { formatComma } from "../../utils/formatNumber";
import "flatpickr/dist/themes/dark.css";
import Table from "../../components/Table";

export function WalletTable({ transactions, headers }) {
  return (
    <div className="flex flex-col mx-auto ">
      {transactions?.data.length > 0 ? (
        <>
          <Table headers={headers}>
            {transactions.data.map((row) => (
              <tr>
                <td>{row.id}</td>
                <td>{row.type_name ?? "Inner"}</td>
                <td>{row.wallet_type_name}</td>
                <td
                  className={`${
                    row.direction === "in" ? "text-green-400" : "text-red-400"
                  }`}
                >
                  ${formatComma(row.amount)}
                </td>
                <td className="capitalize">{row.status}</td>
                <td>{row.created_at.split("T")[0]}</td>
                <td>{row.transaction_number}</td>
                <td>{row.decline_reason ?? "-"}</td>
              </tr>
            ))}
          </Table>

          {/* <DownloadButton /> */}
        </>
      ) : (
        <ErrorMessage text="There is no data!" />
      )}
    </div>
  );
}
