import React from "react";
import { Input } from "./Input";
import "react-toastify/dist/ReactToastify.css";
import UseProfile from "../../hooks/useProfile";

export function ProfileInputs() {
  const {
    loading,
    user,
    formData,
    handleInputChange,
    handleReset,
    handleSubmit,
  } = UseProfile();

  return (
    <>
      <div className="dashboard-box grow !p-10">
        <div className="text-second text-3xl font-semibold text-start mb-10">
          Setting
        </div>
        <div className=" grid sm:grid-cols-2 gap-5 ">
          <Input
            name="first_name"
            title="First Name"
            disabled={formData.isVerified}
            value={formData.first_name}
            onChange={handleInputChange}
            loading={loading}
          />
          <Input
            name="last_name"
            title="Last Name"
            disabled={formData.isVerified}
            value={formData.last_name}
            onChange={handleInputChange}
            loading={loading}
          />
          <Input
            name="birth"
            title="Birth"
            type="date"
            optClass="referral-filter"
            disabled={formData.isVerified}
            value={formData.birth}
            loading={loading}
            onChange={handleInputChange}
          />
          <Input title="Phone" value={user.phone} disabled />
          <Input title="Email" value={user.email} disabled />
          {user.country && (
            <Input title="Country" value={user.country.name} disabled />
          )}
        </div>
        <div>
          <button
            disabled={loading || formData.isVerified}
            onClick={handleSubmit}
            className="w-full max-w-[200px] bg-[--indigo-purple] text-white p-2 rounded-lg border border-transparent hover:border-light-border  disabled:hover:cursor-not-allowed disabled:bg-gray-700 disabled:!border-transparent disabled:text-second light:disabled:bg-gray-300 light:disabled:text-gray-400 my-8"
          >
            {loading ? (
              <span className="loading loading-dots loading-sm -mb-1.5" />
            ) : (
              "Submit"
            )}
          </button>
          {/* <button
              disabled={false}
              // onClick={handleUploadImage}
              className="hover:bg-error text-white p-2 rounded-lg border border-error  disabled:hover:cursor-not-allowed disabled:bg-gray-700 disabled:!border-transparent disabled:text-second light:disabled:bg-gray-300 light:disabled:text-gray-400 transition-colors"
            >
              {false ? (
                <span className="loading loading-dots loading-sm" />
              ) : (
                "Reset"
              )}
            </button> */}
        </div>
      </div>
    </>
  );
}
