import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getData } from "../../utils/fetchData";
import useDidMount from "../useDidMount";
import { useFetch } from "../API/useFetch";

const usePost = (pagination) => {
  const [challenges, setChallenges] = useState([]);
  const [challengeLinks, setChallengeLinks] = useState([]);
  const didMount = useDidMount(true);
  const [refresh, setRefresh] = useState(false);
  const {loading, error, fetchData} = useFetch()

  const getChallenges = async () => {
    const res = await fetchData("challenges/user-challenges?page=" + pagination);
    setRefresh(false);
    if (res?.user_challenges) {
      setChallenges(res.user_challenges.data);
      setChallengeLinks(res.user_challenges);
    }
  };

  useEffect(() => {
    if (
      pagination &&
      ((challenges.length === 0 && didMount) || (refresh && didMount))
    ) {
      getChallenges();
    }
  }, [didMount, challenges.length, refresh]);

  useEffect(()=>{
    getChallenges()
  }, [pagination])


  return {
    error,
    loading,
    challenges,
    challengeLinks,
    setRefresh,
  };
};

export default usePost;
