import React from "react";
import Table from "../../../components/AdminShared/Table";
const WithdrawTopTraderIndex = ({ traders, setType, setFormData }) => {
  console.log(traders);
  const handleUpdate = (trader) => {
    setFormData({
      id: trader.id,
      name: trader.name,
      rank: trader.rank,
      gf_factor: trader.gf_factor,
      withdraw: trader.withdraw,
      nation: trader.nation,
    });
    setType("update");
  };

  return (
    <>
      <div className="title text-center">
        <h3 className="text-lg text-white">Withdraw Top Trader Index</h3>
      </div>
      <Table
        theads={[
          "Id",
          "Name",
          "Rank",
          "Gf factor",
          "Withdraw",
          "Nation",
          "Actions",
        ]}
      >
        {traders
          .sort((a, b) => a.rank - b.rank)
          .map((trader) => (
            <tr>
              <td>{trader.id}</td>
              <td>
                <p>{trader.name}</p>
              </td>
              <td>
                <p>{trader.rank}</p>
              </td>
              <td>
                <p>{trader.gf_factor}</p>
              </td>
              <td>
                <p>{trader.withdraw}</p>
              </td>
              <td>
                <p>{trader.nation}</p>
              </td>
              <th>
                <button
                  onClick={() => handleUpdate(trader)}
                  className="btn btn-outline btn-sm btn-primary"
                >
                  update
                </button>
              </th>
            </tr>
          ))}
      </Table>
    </>
  );
};

export default WithdrawTopTraderIndex;
