import { useEffect, useState } from "react";
import { useFetch } from "../../API/useFetch";

export function useChallengeRequests() {
  const [challengeRequests, setChallengeRequests] = useState(null);
  const [error, setError] = useState(false);
  const {loading, fetchData} = useFetch()

  async function getRequestChallenges() {
    setError((prev) => false);
    const res = await fetchData("challenges/user-challenges/pending");
    if (!res) return;
    if (res.user_challenges.length === 0) {
      setError((prev) => "Data not found!");
    } else {
      setChallengeRequests(res.user_challenges);
    }
  }

  useEffect(() => {
    if (challengeRequests == null) {
      getRequestChallenges();
    }
  }, [challengeRequests]);
  
  return {
    challengeRequests,
    error,
    loading,
  };
}
