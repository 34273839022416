import { useEffect, useState } from "react";
import { getData } from "../utils/fetchData";

const useTopTraders = () => {
  const [loading, setLoading] = useState(false);
  const [userRank, setUserRank] = useState("");

  async function getTopTrader() {
    setLoading((prev) => true);
    const res = await getData("challenge/golden-top-trader");
    setLoading((prev) => false);

    if (res?.status === "success" || res.status === "failed") {
      setUserRank(res);
      return;
    }
    // toast.error("Error fetching data!");
  }

  useEffect(() => {
    getTopTrader();
  }, []);

  return { loading, userRank };
};

export default useTopTraders;
