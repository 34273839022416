import React from "react";
import { TicketRow } from "./TicketRow";
import ErrorMessage from "../../components/shared/ErrorMessage";

export function TicketRowsLayout({
  tickets,
  id,
  admin,
  ticketType,
  getUnAssignedTickets,
  setTicketType = null,
}) {
  const transformData = (data) => {
    const transformedData = data.map((ticket) => {
      const {
        id,
        title,
        message,
        updated_at,
        state,
        status,
        last_page,
        user,
        categories,
        seen,
        seen_admin,
      } = ticket;

      const date = updated_at;

      return {
        id,
        title,
        date,
        state,
        bodyText: message,
        status,
        last_page,
        user,
        categories,
        seen,
        seen_admin,
      };
    });

    return transformedData;
  };

  const data = transformData(tickets);

  return (
    <>
      <div className="flex flex-col gap-y-3">
        {data[0] ? (
          data.map((ticket) => {
            return (
              <TicketRow
                data={ticket}
                key={ticket.id}
                admin={admin}
                ticketType={ticketType}
                setTicketType={setTicketType}
                getUnAssignedTickets={getUnAssignedTickets}
              />
            );
          })
        ) : (
          <ErrorMessage text="Ticket Not Found!" />
        )}
      </div>
    </>
  );
}
