import React from "react";
import PageStatus from "../../../../components/PageStatus/PageStatus";
import Loading from "../../../../components/shared/Loading";
import { FaFilter } from "react-icons/fa";
import useUserPayment from "../../../../hooks/Admin/User/useUserPayment";
import TableBackground from "../../../../components/AdminShared/TableBackground";
import { Link } from "react-router-dom";
export default function UserPayment() {
  const { error, loading, filter, setFilterOption } =
    useUserPayment();
  return (
    <>
      <PageStatus
        category={"Users"}
        currentPage={"Payments"}
        logoStarColor="#F8CC15"
      />

      <TableBackground>
        {error ? (
          <p>Error loading data</p>
        ) : loading ? (
          <div className="w-full text-center">
            <Loading />
          </div>
        ) : (
          <div className="content mx-5 py-5 text-center text-white ">
            <div className=" flex items-center justify-between text-center text-white mb-5">
              <h3 className="text-2xl text-center w-full ">
                User Payment Index
              </h3>
              <Link
                to="/dashboard/admin/users"
                className="btn btn-secondary btn-outline ml-auto"
              >
                Back
              </Link>
            </div>
            <div className=" w-full flex justify-end text-center text-white mb-5">
              <label htmlFor="filter " className="relative">
                <FaFilter
                  size={15}
                  className="absolute right-3 top-1/2 -translate-y-1/2"
                />
                <select
                  onChange={(e) => setFilterOption(e.target.value)}
                  // value={filterOption}
                  id="filter"
                  className="select bg-primary w-full max-w-xs capitalize text-center [&>option]:text-white [&>option]:bg-primaryPurple  "
                >
                  <option value="">filter</option>
                  <option value="date">date</option>
                  <option value="success">success</option>
                  <option value="pending">pending</option>
                  <option value="failed">failed</option>
                  <option value="amount">amount</option>
                  <option value="type">type</option>
                  <option value="id">id</option>
                </select>
              </label>
            </div>
            <div className="overflow-x-auto mb-5">
              <table className="table">
                <thead>
                  <tr className="text-white">
                    <th>id</th>
                    <th>date</th>
                    <th>status</th>
                    <th>number</th>
                    <th>amount</th>
                    <th>type</th>
                  </tr>
                </thead>
                <tbody>
                  {filter.map((payment) => (
                    <tr>
                      <td>{payment.id}</td>
                      <td>
                        <p>{payment.created_at}</p>
                      </td>
                      <td>
                        <p>{payment.status}</p>
                      </td>
                      <td>
                        <p>{payment.number}</p>
                      </td>
                      <td>
                        <p>{payment.amount}</p>
                      </td>
                      <td>
                        <p>{payment.payment_type_name}</p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {
              // payments.current ??
              // (
              //     <Pagination
              //         setRefresh={setRefresh}
              //         setPagination={setPagination}
              //         current={paymentLinks.current_page}
              //         lastPage={paymentLinks.last_page}
              //     />
              // )
            }
          </div>
        )}
      </TableBackground>
    </>
  );
}
