import React from "react";
import UseProfile from "../../hooks/useProfile";
import { MdCancel } from "react-icons/md";
export function ProfilePhoto() {
  const {
    imgLoading: loading,
    selectedImage,
    currentImage,
    showFile,
    handleImageChange,
    handleUploadImage,
    fileInputRef,
    handleCancelPreview,
  } = UseProfile();

  return (
    <>
      <div className="w-full flex flex-col items-center gap-8 dashboard-box ">
        <div className="relative">
          {showFile && !loading && (
            <div className="absolute text-white left-1/2 -translate-x-1/2 -top-3 z-10">
              <button
                onClick={handleCancelPreview}
                className=" bg-gray-800 text-red-500 px-2 rounded-xl pt-0.5 border border-indigo-500/50 transition-colors hover:border-red-500"
              >
                <div className="flex items-top gap-1">
                  <MdCancel className="mt-px" />
                  cancel
                </div>
              </button>
            </div>
          )}
          <div className="size-60 shadow-[0_0_15px_-2px] shadow-gray-900 light:shadow-gray-500 light:shadow-[0_0_10px_-3px] border border-indigo-500/50 light:border-yellow-500/80 p-2 rounded-3xl">
            {loading ? (
              <div className="w-full h-full rounded-3xl skeleton bg-transparent" />
            ) : (
              <div className="relative w-full h-full rounded-2xl overflow-hidden group">
                <img
                  src={selectedImage ?? currentImage}
                  className="w-full h-full rounded-3xl object-cover"
                  alt=""
                />
                <button
                  disabled={loading}
                  onClick={handleUploadImage}
                  className={`absolute w-full h-[50px] flex items-center justify-center transition-all max-w-xs bg-[--indigo-purple] text-white p-2  border border-[--indigo-purple] hover:border-light-border rounded-b-3xl disabled:hover:cursor-not-allowed disabled:bg-gray-700 disabled:!border-transparent disabled:text-second light:disabled:bg-gray-300 light:disabled:text-gray-400 group-hover:bottom-0 -bottom-[105px]  ${
                    showFile && "!bottom-0"
                  }`}
                >
                  {loading ? (
                    <span className="loading loading-dots loading-sm" />
                  ) : showFile ? (
                    "Confirm"
                  ) : (
                    "Upload new image"
                  )}
                </button>
              </div>
            )}
          </div>
        </div>

        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept="image/*"
          onChange={handleImageChange}
        />
      </div>
    </>
  );
}
