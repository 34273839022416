import { useEffect, useState } from "react";
import { useFetch } from "../API/useFetch";

const initialData = {
  challenge_type_names: {},
  state_type_names: {},
  phase_type_names: {},
  user_challenges: {},
};

const usePost = (query, pagination) => {
  const { loading, error, fetchData } = useFetch();
  const [challengesData, setChallengesData] = useState(initialData);

  const getChallenges = async () => {
    const res = await fetchData(
      `challenges/user-challenges?page=${pagination}${query}`
    );
    if (!res) return;

    setChallengesData(res);
  };

  useEffect(() => {
    getChallenges();
  }, [query, pagination]);

  return {
    error,
    loading,
    challengesData,
  };
};

export default usePost;
