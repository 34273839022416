import { useState, useEffect } from "react";
import { getData } from "../utils/fetchData";
import { toast } from "react-toastify";
import {usePost} from "./API/usePost"
const transferInitialState = {
  from: 1,
  to: 3,
  amount: null,
};

export function useMyWallets(pagination, query) {
  const [wallet, setWallet] = useState(null);
  const [secondWallet, setSecondWallet] = useState(null);
  const [secondWalletType, setSecondWalletType] = useState(null);
  const [typeName, setTypeName] = useState("all");
  const [transactions, setTransactions] = useState(null);
  const [error, setError] = useState(false);
  const [loadingTransitions, setLoadingTransitions] = useState(false);
  const [loadingTransfer, setLoadingTransfer] = useState(false)
  const [errorTransitions, setErrorTransitions] = useState(false);
  const [transferData, setTransferData] = useState(transferInitialState);
  const [modalVisible, setModalVisible] = useState(false);
  const {handlePostData} = usePost(false)
  useEffect(() => {
    if (wallet === null) fetchWallet();
  }, [wallet]);

  useEffect(() => {
    fetchTransitions();
  }, [pagination, query]);

  const fetchWallet = async () => {
    setLoadingTransfer(prev => true)
    const res = await getData("finance/wallets/amount-role");
    setLoadingTransfer(prev => false)
    if (res?.data) {
      setWallet(res.data.wallet);
      setSecondWallet(res.data.balance);
      setSecondWalletType(res.data.type);
      return;
    }
    setError(true);
    toast.error(res?.data?.message || "Error fetching data!");
  };

  const fetchTransitions = async () => {
    setLoadingTransitions((x) => true);
    const res = await getData(`finance/wallets/transactions?page=${pagination}${query}`);
    setLoadingTransitions((x) => false);
    if (res?.data) {
      setTransactions(res.data);
      return;
    }
    setErrorTransitions(true);
    toast.error(res?.data?.message || "Error fetching data!");
  };

  const handlePostTransfer = async () => {
    setModalVisible((prev) => false);
    setLoadingTransfer(true)
    const res = await handlePostData("finance/wallets/transfers/fund", transferData);
    if (!res) {
      setLoadingTransfer(false)
      return
    }
    toast.success("Successfully transferred!");
    fetchTransitions();
    fetchWallet();
  };

  function handleSetTypeName(value) {
    setTypeName((prev) => value);
  }

  return {
    loadingTransitions,
    loadingTransfer,
    error,
    errorTransitions,
    wallet,
    secondWallet,
    secondWalletType,
    typeName,
    transactions,
    transferData,
    setTransferData,
    handleSetTypeName,
    handlePostTransfer,
    modalVisible,
    setModalVisible,
  };
}
