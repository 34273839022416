export function PlanSetupIcon({ data, active, setData }) {
  return (
    <button>
      <div
        className={`w-fit h-fit relative  my-4 mb-10 transition-all hover:scale-[1.2] ${
          active ? "opacity-100 scale-[1.2]" : "opacity-50"
        } `}
      >
        <div
          className={`w-24 h-24 md:w-32 md:h-32 flex items-center justify-center rounded-3xl border  p-3 relative z-10 ${
            data.state !== 1
              ? "bg-gray-600 border-gray-400"
              : "bg-[var(--indigo-purple-dark)] border-white"
          }`}
        >
          <div className="w-3/4 h-3/4">
            <img
              className={data.state !== 1 && "opacity-70"}
              src={
                data.name !== "win-win"
                  ? `../${data.name}Plan.svg`
                  : "../winwinPlan.svg"
              }
              alt=""
            />
          </div>
        </div>
        <div
          className={`${
            !active ? "hidden" : "flex"
          } w-full h-full top-8 items-end justify-center  text-md md:text-xl font-bold  rounded-3xl p-1 absolute z-0 ${
            data.state !== 1
              ? "bg-gray-300 text-gray-600"
              : "bg-white text-[var(--indigo-purple-dark)]"
          }`}
        >
          <p className="capitalize">{data.name}</p>
        </div>
      </div>
    </button>
  );
}
