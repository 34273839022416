import React from "react";

import DashboardBanner from "./DashboardBanner";
import CompactInfo from "./CompactInfo";
import PlanTable from "./PlanTable/PlanTable";
import BarChart from "./BarChart";
import DonutChart from "./DonutChart";
import Bonus from "./Bonus";
import LineChart from "./LineChart";
import usePermissions from "../../hooks/usePermission";
import { useDashboardCharts } from "../../hooks/useDashboardCharts";
import ErrorMessage from "../../components/shared/ErrorMessage";
import LayoutGrid from "./LayoutGrid";
import TopTraderContainer from "./topTraders/TopTraderContainer";
import UserBalance from "./UserBalance";
import GffBanner from "./GffBanner";

export default function Dashboard() {
  const { can } = usePermissions();
  const { error, loading, totalChart, avgProfit } = useDashboardCharts();

  return (
    <>
      <CompactInfo />
      <LayoutGrid>
        <DashboardBanner />
        <Bonus />

        <UserBalance />
        {can("show_challenge") && <PlanTable />}
        {can("show_transfer") && <LineChart />}

        {can("show_challenge") && <DonutChart />}
        {error ? (
          <ErrorMessage
            optcClass="mt-5"
            text="Error fetching chart data. Please try again later!"
          />
        ) : (
          <BarChart loading={loading} series={totalChart} title="Total Fund" />
        )}

        {can("show_challenge") &&
          (error ? (
            <ErrorMessage
              optcClass="mt-5"
              text="Error fetching chart data. Please try again later!"
            />
          ) : (
            <div className="min-[1100px]:col-span-2 ">
              <BarChart
                loading={loading}
                series={avgProfit}
                title="Avg Total Profit"
              />
              <GffBanner />
            </div>
          ))}
        {can("show_challenge") && <TopTraderContainer />}
      </LayoutGrid>
    </>
  );
}
