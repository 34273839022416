import { Player } from "@lottiefiles/react-lottie-player";

const TableContent = ({
  children,
  headers,
  loading,
  loadingPlaceHolder,
  isDataAvailable,
  layoutClass = "",
  noDataTitle = "",
}) => {
  return (
    <div
      className={`w-full  overflow-auto scrollWidth  cursor-pointer rounded-xl relative z-20 ${layoutClass}`}
    >
      {loading ? (
        loadingPlaceHolder
      ) : (
        <table className="w-full  rounded-xl overflow-auto ">
          <thead
            className={`text-left text-gray-500 [&_th]:px-4  [&_th]:py-5 capitalize`}
          >
            <tr className="sticky bg-[--box-items-background] top-0 z-30 shadow-[0_1px_0_0px] shadow-gray-500/40 ">
              {headers.map((th) => (
                <th>{th}</th>
              ))}
            </tr>
          </thead>
          <tbody
            className={`[&_td]:p-4 text-white [&_tr:not(:last-child)]:border-b [&_tr]:border-[--chart-grid] [&_tr:nth-child(even)]:- light:[&_tr:nth-child(even)]:- relative`}
          >
            {isDataAvailable && children}
          </tbody>
        </table>
      )}

      {!loading && !isDataAvailable && (
        <div className="w-full h-[335px] ">
          <Player
            src="https://lottie.host/0a086584-47fd-4247-ad9e-c3e39d6ec20d/yB2qgX25mt.json"
            className="player h-[300px]"
            autoplay
            loop
          />
          {noDataTitle !== "" && (
            <p className="-mt-8 text-second text-center font-semibold text-lg">
              {noDataTitle}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default TableContent;
