import React from "react";
import Loading from "../../../components/shared/Loading";
import { ChatBubble } from "../../Tickets/Chat/ChatBubble";

export function Chat({ messages, newMessage, user }) {
  return (
    <div className=" w-full h-full max-h-[500px] flex flex-col-reverse overflow-scroll scrollWidth bg-[#4743e040]/20 shadow-[0_0_8px_-3px] shadow-neutral-900 light:bg-yellow-600/5 light:shadow-neutral-400 rounded-xl mb-5 px-2 md:px-10 py-5">
      {newMessage ? (
        <div className="w-full h-full flex items-center justify-center">
          <Loading />
        </div>
      ) : (
        <div className="w-full">
          {messages.map((item) => (
            <ChatBubble
              data={item}
              isAdmin={user !== item.user_id && item.type !== "user"}
            />
          ))}
        </div>
      )}
    </div>
  );
}
