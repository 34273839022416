import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { usePost } from "../../hooks/API/usePost";
import Loading from "../../components/shared/Loading";
import ModalLayout from "../../components/AdminShared/ModalLayout";

export default function ChangeUserState({ status }) {
  const [reason, setReason] = useState("");
  const [refund, setRefund] = useState(false);
  const { loading, handlePostData } = usePost(false);
  const navigate = useNavigate();

  const { id } = useParams();
  const handleFail = async () => {
    if (!reason) return toast.error("Enter reason!");
    const data = {
      user_challenge_id: id,
      reason: reason,
    };

    const res = await handlePostData(
      "challenges/user-challenges/fail",
      data,
      "The Challenge failed successfully"
    );
    if (!res) return;
    handleClose();
  };

  const handleRefund = async () => {
    if (!reason) return toast.error("Enter reason!");
    const data = {
      user_challenge_id: id,
      reason: reason,
    };

    const res = await handlePostData(
      "challenges/user-challenges/refund",
      data,
      "The Challenge refunded successfully"
    );
    if (!res) return;
    handleClose();
  };

  const handleClose = () => {
    closeModal();
    setRefund(false);
    navigate("/admin/challenges");
  };

  function showModal() {
    document.getElementById("user-state").showModal();
  }

  function closeModal() {
    document.getElementById("user-state").close();
  }

  return (
    <>
      <ModalLayout id="user-state">
        {loading ? (
          <div className="w-full flex items-center justify-center h-[300px] text-center">
            <Loading />
          </div>
        ) : (
          <>
            <label htmlFor="declineReason" className="form-control">
              <span className="text-neutral-100 mb-2">
                Please enter a {refund ? "Refund" : "Decline"} reason:
              </span>
              <textarea
                onChange={(e) => setReason(e.target.value)}
                className="textarea "
                name="declineReason"
                id="declineReason"
                rows={5}
              />
            </label>
            {refund ? (
              <button
                onClick={handleRefund}
                className="btn btn-outline btn-warning mt-5"
              >
                Submit
              </button>
            ) : (
              <button
                onClick={handleFail}
                className="btn btn-outline btn-warning mt-5"
              >
                Submit
              </button>
            )}
          </>
        )}
      </ModalLayout>
      {status === "active" && (
        <div className="grid grid-cols-2 max-w-sm gap-2 mt-5">
          <button
            onClick={showModal}
            className="btn btn-outline btn-sm grow btn-error"
          >
            Fail
          </button>
          <button
            onClick={() => {
              showModal();
              setRefund(true);
            }}
            className="btn btn-outline btn-sm grow btn-warning"
          >
            Refund
          </button>
        </div>
      )}
    </>
  );
}
