export default function GffBanner() {
  return (
    <a
      href="https://golden.fund/en/freedom-fund"
      target="_blank"
      rel="noreferrer"
      className="w-full max-md:hidden text-white relative rounded-xl bg-indigo-700/20 light:bg-yellow-500/10 light:border-orange-400/20 border border-indigo-400/20 transition-colors hover:!border-light-border z-[1] backdrop-blur-md grid md:grid-cols-3 overflow-hidden p-5 mt-5"
    >
      <div className="col-span-2 relative z-[99]">
        <p className="text-2xl md:leading-[3rem] font-bold ">
          Unlock Financial Freedom with Freedom Fund
        </p>
        <div className="mt-5">
          <ul className="[&>li]:flex [&>li]:align-center [&>li]:gap-2 [&>li]:mb-4">
            <li>
              <svg
                className="w-5 fill-[#6231E5]"
                viewBox="0 0 236 228"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M117.716 0C132.501 55.9036 177.994 99.7496 236 114C177.994 128.25 132.501 172.095 117.716 228C103.499 172.095 58.0058 128.25 0 114C58.0058 99.7496 103.499 56.4516 117.716 0Z" />
              </svg>
              <span className="text-base lg:text-xl -mb-2">
                30% Annual Returns
              </span>
            </li>

            <li>
              <svg
                className="w-5 fill-[#6231E5]"
                viewBox="0 0 236 228"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M117.716 0C132.501 55.9036 177.994 99.7496 236 114C177.994 128.25 132.501 172.095 117.716 228C103.499 172.095 58.0058 128.25 0 114C58.0058 99.7496 103.499 56.4516 117.716 0Z" />
              </svg>
              <span className="text-base lg:text-xl -mb-2">
                Withdraw Anytime
              </span>
            </li>
            <li>
              <svg
                className="w-5 fill-[#6231E5]"
                viewBox="0 0 236 228"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M117.716 0C132.501 55.9036 177.994 99.7496 236 114C177.994 128.25 132.501 172.095 117.716 228C103.499 172.095 58.0058 128.25 0 114C58.0058 99.7496 103.499 56.4516 117.716 0Z" />
              </svg>
              <span className="text-base lg:text-xl -mb-2">
                24H Guaranteed Payout
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="max-md:hidden z-50 flex items-center justify-center relative">
        <img
          src="./freedom.webp"
          className="w-[200px] relative z-50"
          alt="freedom fund"
        />
        <span className="opacity-80 light:opacity-70 lg:opacity-100 absolute w-full h-full lg:max-w-[150px] lg:max-h-[150px] shadow-[0_0_50px_20px_#7102ff,_0_0_100px_60px_rgb(103_76_227),_0_0_140px_90px_rgb(69_9_187_/_58%)] light:shadow-[0_0_60px_30px_#ffe602,_0_0_100px_60px_rgb(241_255_9_/_87%),_0_0_140px_90px_rgb(255_3_3_/_58%)] left-0 top-0 lg:!top-auto lg:left-28 blur-[50px]" />
      </div>
    </a>
  );
}
