import React, { useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";

export function PhoneNumberEnter({ data, sendSms, handleSetData }) {
  const [value, setValue] = useState();
  console.log(value);
  const [error, SetError] = useState(false);

  function handleSetNumber() {
    if (isValidPhoneNumber(value || "")) {
      handleSetData("phoneNumber", value);
      sendSms(value);
    } else {
      SetError(true);
    }
  }
  return (
    <div className="max-w-xs">
      {data.policyLive === "USA" ? (
        <PhoneInput
          placeholder="Enter phone number"
          value={value}
          onChange={setValue}
          // countryCallingCodeEditable={false}
          international={true}
          defaultCountry="US"
          className="[&_select]:!bg-slate-900 [&_select]:!text-slate-200 "
        />
      ) : (
        <PhoneInput
          placeholder="Enter phone number"
          value={value}
          onChange={setValue}
          // countryCallingCodeEditable={false}
          international={true}
          className="[&_select]:!bg-slate-900 [&_select]:!text-slate-200 "
          // defaultCountry="IR"
        />
      )}

      <span
        className={`text-sm text-rose-600 font-semibold mt-2 ${
          error ? "block" : "hidden"
        } `}
      >
        Please enter a valid phone number
      </span>
      <p className="text-lg text-white text-center font-light mt-3">
        Enter your phone number to receive a verification code
      </p>
      <button
        onClick={handleSetNumber}
        className="w-full max-w-xs font-semibold rounded-3xl bg-[var(--indigo-purple)] hover:bg-[var(--indigo-purple-hover)] text-white py-2 mt-10"
      >
        Next
      </button>
    </div>
  );
}
