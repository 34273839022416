// import { getToken } from "./getToken";
import store from "../store/store";
import { authActions } from "../store/slices/auth";
export const handle401Error = () => {
  localStorage.removeItem("userData");
  store.dispatch(authActions.logout());
};


const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const getData = async (url) => {
  let response;
  try {
    let token = "Bearer " + store.getState().auth.token;
    response = await fetch(`${baseUrl}/api/${url}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (!response.ok) {
      // Throw an error if the response is not successful
      // throw new Error("Failed to fetch data");
      console.log("Failed to fetch data");
      if (response && response.status === 401) {
        console.log("l2");
        handle401Error();
      }
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    if (response && response.status === 401) {
      console.log("l2");
      handle401Error();
    }
    if (response) {
      const error = await response.json();
      return error;
    }

    return null;
  }
};

export const postData = async (url, post) => {
  let response;
  try {
    let token = "Bearer " + store.getState().auth.token;
    response = await fetch(`${baseUrl}/api/${url}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(post),
    });

    if (!response.ok) {
      // Throw an error if the response is not successful
      if (response && response.status === 401) {
        console.log("l2");
        handle401Error();
      }
      console.log("Failed to fetch data");
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    if (response && response.status === 401) {
      console.log("l2");
      handle401Error();
    }
    if (response) {
      const error = await response.json();
      return error;
    }

    return null;
  }
};

export const putData = async (url, post) => {
  let response;
  try {
    let token = "Bearer " + store.getState().auth.token;
    const response = await fetch(`${baseUrl}/api/${url}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(post),
    });

    if (!response.ok) {
      // Throw an error if the response is not successful
      console.log("Failed to fetch data");
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    if (response && response.status === 401) {
      console.log("l2");
      handle401Error();
    }
    if (response) {
      const error = await response.json();
      return error;
    }

    return null;
  }
};

export const patchData = async (url, post) => {
  let token = "Bearer " + store.getState().auth.token;
  const res = await fetch(`${baseUrl}/api/${url}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(post),
  });

  const data = await res.json();
  return data;
};

export const deleteData = async (url) => {
  let token = "Bearer " + store.getState().auth.token;
  const res = await fetch(`${baseUrl}/api/${url}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  const data = await res.json();
  return data;
};

export const postDataImge = async (url, post) => {
  try {
    let token = "Bearer " + store.getState().auth.token;
    const response = await fetch(`${baseUrl}/api/${url}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
      },
      body: post,
    });

    if (!response.ok) {
      // Throw an error if the response is not successful
      // throw new Error('Failed to fetch data');
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.log(error);
    // Handle errors by setting the error state
    // throw new Error('Failed to fetch data');
    console.log("Failed to fetch data");
  }
};
