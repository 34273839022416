import React from "react";

const rankImages = ["../topTraderFirst.png", "../rank-2.png", "../rank-3.png"];

const nationImg = {
  UAE: "../flags/UAE.png",
  IR: "../flags/IR.png",
  TR: "../flags/TR.png",
  CH: "../flags/CH.png",
  KR: "../flags/KR.png",
  GE: "../flags/GE.png",
  EN: "../flags/EN.png",
  BE: "../flags/BE.png",
  IT: "../flags/IT.png",
  CA: "../flags/CA.png",
};

export function RankingTopUser({ data, type }) {
  const { name, rank, nation } = data;
  return (
    <div
      className={`flex flex-col items-center justify-center ${
        String(rank) === "1"
          ? "col-start-2 scale-[1.20]"
          : String(rank) === "2"
          ? "col-start-1 row-start-2"
          : "col-start-3 row-start-2"
      }`}
    >
      <div className="relative w-fit">
        <img
          src={rankImages[rank - 1] ?? "../UserProfilePhoto.png"}
          className="rounded-full w-full h-auto max-w-[75px]"
          alt=""
        />
        <img
          src={nationImg[nation] ?? nationImg.UAE}
          className="absolute bottom-[-10px] right-1/2 translate-x-1/2 rounded-full w-full max-w-5  max-h-6"
          alt=""
        />
      </div>
      <div className="text-center mt-4 ">
        {/* <p className="text-yellow-500 font-semibold text-lg leading-none relative my-1">
          {rank}
          {rank === 1 && (
            <span className="text-sm absolute -top-3 left-[65%] translate-x-[-65%] !normal-case">
              st
            </span>
          )}
          {rank === 2 && (
            <span className="text-sm absolute -top-3 left-[70%] translate-x-[-70%] !normal-case">
              nd
            </span>
          )}
          {rank === 3 && (
            <span className="text-sm absolute -top-3 left-[67%] translate-x-[-67%] !normal-case">
              rd
            </span>
          )}
        </p> */}
        <p className="text-white font-semibold capitalize leading-none">
          {name}
        </p>
        <p className="text-xs text-neutral-500 font-semibold ">
          {type === "withdraw" ? (
            <span className="-mb-1">${data.withdraw}</span>
          ) : (
            <>
              GF factor : {data.gf_factor}{" "}
              <span className="text-transparent">%</span>
            </>
          )}
        </p>
        {/* <p className="text-neutral-500 font-semibold text-xs">
          {type === "top" && (
            <>
              GF factor : {data.gf_factor}{" "}
              <span className="text-yellow-500">%</span>
            </>
          )}
          {type === "golden" && (
            <>
              GF factor : {data.gf_factor}{" "}
              <span className="text-yellow-500">%</span>
            </>
          )}
          {type === "withdraw" && (
            <>
              GF factor : ${data.withdraw} {" "}
              <span className="text-yellow-500">%</span>
            </>
          )}
        </p> */}
      </div>
    </div>
  );
}
