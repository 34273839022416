import React from "react";
import StatusLogo from "./StatusLogo";
import CurrentPage from "./CurrentPage";

export default function PageStatus({
  category,
  currentPage,
  logoStarColor,
  pageStarColor,
}) {
  return (
    <>
      <StatusLogo
        category={category}
        currentPage={currentPage}
        logoStarColor={logoStarColor}
      />
      <CurrentPage
        category={category}
        currentPage={currentPage}
        pageStarColor={pageStarColor}
      />
    </>
  );
}
