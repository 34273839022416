import React from "react";
import { ProfileInputs } from "./ProfileInputs";
import { ProfilePhoto } from "./ProfilePhoto";
import VerificationStatus from "./VerificationStatus";
export default function Profile() {
  return (
    <>

      <div className="mt-10">
        <div className="flex flex-col-reverse xl:flex-row gap-5">
          <ProfileInputs />
          <div className="w-full xl:max-w-md flex flex-col gap-5">
            <ProfilePhoto />
            <VerificationStatus />
          </div>
        </div>
      </div>
    </>
  );
}
