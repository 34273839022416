import { useState } from "react"
import { postData } from "../utils/fetchData";
import { toast } from "react-toastify";
const intialData = {
    paymentMethod: "Crypto",
    depositAmount: null,
    creditAmount: null,
    confirmed: false,
    step: 1,
};
export function useDeposit() {
    const [depositData, setDepositData] = useState(intialData);
    const [cryptoRelocateloading, setCryptoRelocateloading] = useState(false)
    const submitPayment = () => {
        setDepositData((prevData) => ({
            ...prevData,
            confirmed: true,
        }));

        if (depositData.paymentMethod === "Crypto") {
            getPaymentNowUrl();
        } else if (depositData.paymentMethod === "Fiat") {
            setDepositData((prevData) => ({
                ...prevData,
                step: 4,
            }));
        }
    }

    const getPaymentNowUrl = async () => {
        if (depositData.confirmed === 'false') {
            return;
        }
        setCryptoRelocateloading(prev => true)
        const data = { "amount": depositData.depositAmount }
        const res = await postData("finance/deposit/now-payments", data);
        if (res) {
            window.location.href = res.data.link;
        } else {
            toast.error("Error fetching Data! Please Try Again Later.");
        }
        setCryptoRelocateloading(prev => false)
    };

    function handleSetDepositData(values) {
        setDepositData(prev => ({ ...prev, ...values }))
    }


    return { depositData, submitPayment, handleSetDepositData, cryptoRelocateloading }
}