import { useEffect, useRef, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { usePut } from "../../../../hooks/API/usePut";
import { useFetch } from "../../../../hooks/API/useFetch";

export default function BanModal({ user, setUser }) {
  const banReason = user.ban_reason[user.ban_reason.length - 1];
  const [reason, setReason] = useState("");
  const { handlePutData } = usePut();
  const { fetchData } = useFetch();
  const [loading, setLoading] = useState(false);
  const modalRef = useRef(null);

  const handleClose = () => {
    setUser(null);
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleClose();
  };

  const handleBlock = async () => {
    if (!reason) return;
    const data = {
      reason: reason,
    };
    setLoading((x) => true);
    const _ = await handlePutData(
      `users/ban/${user.id}`,
      data,
      `User ${user.name} banned Successfully`
    );
    setLoading((x) => false);

    handleClose();
  };

  const handleUnBlock = async () => {
    setLoading((x) => true);
    const _ = await fetchData(
      `users/unban/${user.id}`,
      `User ${user.name} unbanned Successfully`
    );
    setLoading((x) => false);

    handleClose();
  };

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    user.id ? modalRef.current.showModal() : modalRef.current.close();
  }, [user.id]);

  return (
    <dialog
      ref={modalRef}
      id="my_modal_1"
      className="modal"
      onCancel={handleESC}
    >
      <div className="modal-box w-11/12 md:w-full max-w-xl bg-indigo-950 p-5 border border-[var(--indigo-purple)] relative z-50">
        <button
          onClick={handleClose}
          className="btn btn-sm btn-square bg-gradient-to-br from-indigo-700 to-indigo-800 border-0 text-white "
        >
          <IoArrowBack size={20} />
        </button>

        <div className="max-w-[350px] mx-auto">
          {user.status === 2 ? (
            <>
              <div className="text-white">
                <p className="opacity-50">
                  created at {banReason.created_at.split("T")[0]}
                </p>
                <p>
                  <span className="opacity-50">user:</span> {user.email}
                </p>
                <p className="mt-3 mb-7">
                  <span className="text-indigo-400 font-semibold">
                    Blocked Reason:
                  </span>{" "}
                  <span>{banReason.reason}</span>
                </p>
              </div>
              <button
                onClick={handleUnBlock}
                disabled={loading}
                className="btn btn-outline btn-warning disabled:!text-warning disabled:!border-warning "
              >
                {loading ? (
                  <span className="loading loading-dots loading-sm" />
                ) : (
                  "UnBlock"
                )}
              </button>
            </>
          ) : (
            <>
              <label htmlFor="declineReason" className="form-control">
                <div className="text-neutral-100 mb-2">
                  Please enter a reason to block the{" "}
                  <span className="font-semibold">{user.email}</span>:
                </div>
                <textarea
                  onChange={(e) => setReason(e.target.value)}
                  className="textarea bg-neutral-200"
                  name="declineReason"
                  id="declineReason"
                  rows={3}
                />
              </label>

              <button
                onClick={handleBlock}
                disabled={loading}
                className="btn btn-outline btn-error disabled:!border-error disabled:!text-error mt-5"
              >
                {loading ? (
                  <span className="loading loading-dots loading-sm" />
                ) : (
                  "Ban"
                )}
              </button>
            </>
          )}
        </div>
      </div>
    </dialog>
  );
}
