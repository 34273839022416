import React from "react";
import { AmountActionButton } from "./AmountActionButton";
import { ShowAmount } from "./ShowAmount";
import { WalletTable } from "./WalletTable";
import PageStatus from "../../components/PageStatus/PageStatus";
import Loading from "../../components/shared/Loading";
import { useMyWallets } from "../../hooks/useMyWallets";
import usePermissions from "../../hooks/usePermission";
import Modal from "./Modal";
import { PiWalletBold } from "react-icons/pi";
import { FaArrowsRotate } from "react-icons/fa6";
import MyWalletFilter from "../../components/shared/Finance/MyWalletFilter";
import Pagination from "../../components/AdminShared/Pagination";
import { usePagination } from "../../hooks/usePagination";
const headers = [
  "type",
  "wallet Name",
  "amount",
  "status",
  "date",
  "transaction hash.",
  "decline reason",
];

const initialFilter = {
  status: "",
  wallettypeid: "",
  transitiontypeid: "",
  fromDate: "",
  toDate: "",
};

const filterOptions = ["status", "wallettypeid", "transitiontypeid", "fromDate", "toDate" ]

export default function Wallet() {
  const { pagination, handleFilterChange, query, handleSubmitFilter, handleChangePagination, filter } = usePagination(initialFilter, filterOptions)
  const {
    loadingTransitions,
    loadingTransfer,
    error,
    errorTransitions,
    wallet,
    secondWallet,
    secondWalletType,
    typeName,
    transactions,
    modalVisible,
    setModalVisible,
    transferData,
    setTransferData,
    handlePostTransfer,
  } = useMyWallets(pagination, query);
  const { can } = usePermissions();
  return (
    <div className="relative z-10 min-h-screen">
      {modalVisible && (
        <Modal
          visible={modalVisible}
          setModalVisible={setModalVisible}
          transferData={transferData}
          setTransferData={setTransferData}
          handlePostTransfer={handlePostTransfer}
        />
      )}

      <PageStatus
        category={"finance"}
        currentPage={"my wallets"}
        logoStarColor="#F8CC15"
      />
      {error ? (
        "Error fetching data"
      ) : wallet == null ? (
        <Loading optClass={"block mx-auto mt-20"} />
      ) : (
        <ShowAmount>
          <AmountActionButton
            loading={loadingTransfer}
            text="Account Balance"
            value={wallet}
            icon={<PiWalletBold color="white" size={40} />}
          />
          <AmountActionButton
            loading={loadingTransfer}
            text={secondWalletType}
            value={secondWallet}
            icon={<PiWalletBold color="white" size={40} />}
          />
          {can("show_transfer") && (
            <button
              onClick={() => setModalVisible(true)}
              className="col-start-1 sm:col-span-2 flex justify-center gap-3"
            >
              <div className="grow rounded-lg max-w-[340px] bg-gradient-to-r from-indigo-900 via-indigo-800 to-indigo-900 h-14 flex items-center justify-center text-white font-semibold text-lg gap-2">
                <span className="pt-1">Transfer</span>
                <FaArrowsRotate color="white" size={20} />
              </div>
            </button>
          )}
        </ShowAmount>
      )}

      {transactions && (
        <MyWalletFilter
          data={transactions}
          handleFilterChange={handleFilterChange}
          handleSubmitFilter={handleSubmitFilter}
          filter={filter}
        />
      )}
      {errorTransitions ? (
        "Error fetching data"
      ) : loadingTransitions || transactions == null ? (
        <div className="w-full flex items-center justify-center h-[440px]">
          <Loading />
        </div>
      ) : (
        <WalletTable
          transactions={transactions.transactions}
          typeName={typeName}
          headers={headers}
        />
      )}
      {transactions?.transactions && (
        <Pagination
          handleOnClick={handleChangePagination}
          data={transactions.transactions}
          optClass="mt-5"
        />
      )}
      <img
        src="../dashboardBgWave.png"
        className="absolute w-full max-h-[700px] -bottom-[40%] z-[-1]"
        alt=""
      />
    </div>
  );
}
