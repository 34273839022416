import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";
import Loading from "../../../components/shared/Loading";
import { useSucccessChallenges } from "../../../hooks/Admin/useSuccessChallenges";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import Table from "../../../components/AdminShared/Table";
import TableBackground from "../../../components/AdminShared/TableBackground";
import PageStatus from "../../../components/PageStatus/PageStatus";

const theadData = [
  "Id",
  "User Name",
  "Phase",
  "Amount",
  "Start Date",
  "Details",
  "Confirmation",
];
export default function Success() {
  const { loading, error, challenges, handleSubmit } = useSucccessChallenges();

  return (
    <>
      <PageStatus
        category={"Challenge"}
        currentPage={"Success challenges"}
        logoStarColor="#F8CC15"
      />
      <TableBackground>
        {loading ? (
          <div className="w-full text-center">
            <Loading />
          </div>
        ) : error ? (
          <ErrorMessage />
        ) : (
          <Table theads={theadData}>
            {challenges.map((challenge) => (
              <ChallengesRow
                key={challenge.id}
                data={challenge}
                handleSubmit={handleSubmit}
              />
            ))}
          </Table>
        )}
      </TableBackground>
    </>
  );
}

function ChallengesRow({ data, handleSubmit }) {
  const { id, Phase, balance, start_time } = data;
  const { first_name, last_name } = data.user;
  return (
    <tr>
      <th>{id}</th>
      <td>{first_name + " " + last_name}</td>
      <td>{Phase}</td>
      <td>{balance}</td>
      <td>{start_time}</td>
      <td>
        <Link
          to={`/dashboard/my-challenges/analyze/${id}`}
          className="btn btn-sm btn-info btn-outline"
        >
          Details
        </Link>
      </td>
      <td>
        <div className="flex items-center gap-x-2">
          <button
            onClick={() => handleSubmit(id)}
            className="btn btn-sm btn-success"
          >
            <FaCheck />
          </button>
        </div>
      </td>
    </tr>
  );
}
