import React from "react";

export function TradeHistoryRow({ row, index }) {
  return (
    <tr >
    <td>
      {index}
    </td>
    <td>{row.symbol}
    </td>
    <td className="!px-1">
      <span className="w-full badge text-primaryPurple  bg-orange-500 font-semibold border-0 pt-0.5">
      {row.type === 'DEAL_TYPE_BUY' ? 'buy' : 'sell'}

      </span>
    </td>
    <td>
      <span className={`w-full badge text-white border-0  pt-0.5 ${
        row.success === "lost" ? "bg-red-600" : "bg-green-500"
      } `}>

      {row.success}
      </span>
    </td>
    <td
      className={`${
        row.profit >= 0 ? "text-green-500" : "text-[#f00]"
      }`}
    >
      ${row.profit}
    </td>
    <td className={`${row.pips >= 0 ? "text-green-500" : "text-[#f00]"}`}>
      {row.pips}
    </td>
    <td className="  ">{row.open_time}</td>
    <td className="  ">{row.close_time}</td>
    <td>{row.volume}</td>
    <td >{row.duration_in_minutes} <span className="font-light">minutes</span></td>
    <td>{row.open_price}</td>
    <td>{row.close_price}</td>
  </tr>
  );
}
