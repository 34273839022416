import React from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import { InputsContainer } from "../shared/InputsContainer";
import { ImageContainer } from "../shared/ImageContainer";
import { InputRequirment } from "../shared/InputRequirment";
import { ResetPasswordCodeVerify } from "./ResetPasswordCodeVerify";
import { Player } from "@lottiefiles/react-lottie-player";
import { useResetPassword } from "../../../hooks/useResetPassword";
import Loading from "../../../components/shared/Loading";
import AuthInput from "../../../components/Auth/AuthInput";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { AuthSubmitBtn } from "../../../components/Auth/AuthSubmitBtn";
// import { Loginfooter } from "./Loginfooter";

export default function ResetPassword() {
  const {
    loading,
    error,
    showPass,
    handleSetShowPass,
    verificationCode,
    email,
    newPass,
    confirmPass,
    handleSubmit,
    handleSetVerification,
    handleSetNewPass,
    handleSetConfirmPass,
  } = useResetPassword();
  return (
    <>
      <InputsContainer>
        <div className="relative">
          <p className="relative text-4xl text-neutral-100 font-bold">
            Reset Your Password
            <img
              src="../yellowStar.svg"
              className="absolute -right-10 top-0 w-10"
              alt=""
            />
          </p>
        </div>
        <p className="text-neutral-200">
          Input your new password. We strongly advise you to store it safely.
        </p>
        <hr className="border-dashed border-white/50 my-10 border-t-2" />

        <div
          className={`mt-14 flex items-center justify-center ${
            loading ? "block" : "hidden"
          } `}
        >
          <Loading />
        </div>

        <div className={`${!loading ? "block" : "hidden"}`}>
          <ResetPasswordCodeVerify
            verificationCode={verificationCode}
            setVerificationCode={handleSetVerification}
            email={email}
          />
          <AuthInput
            type={showPass ? "text" : "password"}
            label="password"
            onChange={(e) => handleSetNewPass(e.target.value)}
            value={newPass}
            dataTip="Enter new password"
          >
            <label className="swap ">
              <input type="checkbox" />
              <FaEye
                size={20}
                className="swap-on fill-indigo-700"
                onClick={handleSetShowPass}
              />
              <FaEyeSlash
                size={20}
                className="swap-off fill-indigo-700"
                onClick={handleSetShowPass}
              />
            </label>
          </AuthInput>

          <PasswordStrengthBar
            password={newPass}
            minLength={4}
            scoreWordStyle={{ display: "none" }}
            className="passwordStrengthBar mb-4 mt-2"
          />

          <AuthInput
            type="password"
            label="confirm new password"
            dataTip="re-Enter your new password"
            onChange={(e) => handleSetConfirmPass(e.target.value)}
            value={confirmPass}
          />

          <span
            className={`text-sm text-rose-600 font-semibold mt-2 ${
              error ? "block" : "hidden"
            } `}
          >
            Password Don't Match
          </span>
          <InputRequirment data={newPass} />

          <hr className="border-dashed border-white/50 border-t-2 my-10" />

          <AuthSubmitBtn onClick={handleSubmit} title="Change password" />
        </div>

        {/* <Loginfooter title={"need help?"} linkText={"customer support"} /> */}
      </InputsContainer>

      <ImageContainer>
        <div
          className="flex items-center justify-center w-full h-full"
          style={{
            backgroundImage:
              "radial-gradient(circle, rgba(60,52,196,0.4) 0%, rgba(0,0,0,0) 70%)",
          }}
        >
          <Player
            src="https://lottie.host/060b02f4-97f0-4506-bdae-fb6c5ad4a2e0/LehPPKGwol.json"
            className="player md:w-[400px] md:h-400px lg:w-[700px] lg:h-[700px]"
            autoplay
            loop
          />
        </div>
      </ImageContainer>
    </>
  );
}
