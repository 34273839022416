import React from "react";
import { RankingTopUser } from "./RankingTopUser";

export function RankingTopUserLayout({ data, type }) {
  return (
    <div className="w-full grid grid-cols-3 items-start justify-start p-2 mt-3">
      {data.map((data) =>
        data.rank <= 3 ? <RankingTopUser data={data} type={type} /> : null
      )}
    </div>
  );
}
