import { useEffect, useState } from "react";
import useDidMount from "../../useDidMount";
import { useParams } from "react-router-dom";
import { useFetch } from "../../API/useFetch";

const useUserCertificate = (pagination) => {
  const [certificates, setCertificates] = useState([]);
  const [certificateLinks, setCertificateLinks] = useState([]);
  const didMount = useDidMount(true);
  const [refresh, setRefresh] = useState(false);
  const {loading, error, fetchData} = useFetch()
  const { id } = useParams();

  const getCertificates = async () => {
    const res = await fetchData("users/certificates/" + id);
    setRefresh(false);
    if (!res) return;
    setCertificates(res.certificates.data);
    setCertificateLinks(res.certificates);
  };

  useEffect(() => {
    if (
      pagination &&
      ((certificates.length === 0 && didMount) || (refresh && didMount))
    ) {
      getCertificates();
    }
  }, [didMount, pagination, certificates.length, refresh]);

  // useEffect(()=>{
  //   getCertificates
  // },[pagination])
  return {
    error,
    loading,
    certificates,
    certificateLinks,
    setRefresh,
  };
};

export default useUserCertificate;
