const Filter = ({
  optClass,
  formClass,
  titleClass,
  title,
  type = "text",
  disabled,
  value,
  ...props
}) => {
  return (
    <label className={`form-control ${formClass}`}>
      <div className="label">
        <span className={`label-text text-second ${titleClass}`}>{title}:</span>
      </div>
      <input
        className={`referral-filter input input-sm bg-gray-700 light:bg-gray-400/50 text-gray-200 light:text-gray-800 ${optClass}`}
        type={type}
        disabled={disabled}
        value={value}
        {...props}
      />
    </label>
  );
};

export default Filter;
