export default function CompactSide({ mapData, data, loading }) {
  return (
    <div
      style={{
        background:
          "conic-gradient(from -60deg at 50% calc(100%/3),var(--_g)), conic-gradient(from 120deg at 50% calc(200%/3),var(--_g)),conic-gradient(from  60deg at calc(200%/3),var(--c3) 60deg,var(--c2) 0 120deg,#0000 0),conic-gradient(from 180deg at calc(100%/3),var(--c1) 60deg,var(--_g)),linear-gradient(90deg,var(--c1)   calc(100%/6),var(--c2) 0 50%,var(--c1) 0 calc(500%/6),var(--c2) 0)",
        backgroundSize: " calc(1.732*var(--s)) var(--s)",
      }}
      className="dashboard-box flex flex-col justify-evenly gap-5 bg-[#242c3a] light:bg-gray-300"
    >
      {mapData.map((item) => (
        <Box
          title={item.title}
          value={data?.[item.objectName]}
          loading={loading}
        />
      ))}
    </div>
  );
}

function Box({ title = "", value, loading }) {
  return (
    <div className="bg-[#4443] light:bg-transparent backdrop-blur-md shadow-[0_0_15px_-5px] shadow-gray-800 light:shadow-gray-400 p-4 px-7 border border-gray-100/5 rounded-xl max-[1100px]:text-center">
      <p className="text-second text-lg font-semibold">{title}</p>
      <p className="text-white text-3xl font-semibold">
        {loading ? (
          <div className="w-20 h-7 mb-1 skeleton bg-gray-50/10 rounded-md mt-1" />
        ) : (
          value
        )}
      </p>
    </div>
  );
}
