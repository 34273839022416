import React, { useEffect, useState } from "react";
import Loading from "../../components/shared/Loading";
import Table from "../../components/Table";
import { useFetch } from "../../hooks/API/useFetch";

const headers = [
  "Challenge",
  "State",
  "Start Date",
  "End Date",
  "Permittable Loss",
];

export default function PlanTable() {
  const [plans, setPlans] = useState(null);
  const {loading, fetchData: handleGetData} = useFetch()
  const fetchData = async () => {
    const res = await handleGetData("dashboard-plans");
    if (!res) return;
    setPlans(res.dashboard_plans);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading || plans === null ? (
        <Loading optClass={"block mx-auto mt-20"} />
      ) : (
        plans.length > 0 && (
          <Table headers={headers}>
            {plans.map((row) => (
              <tr>
                <td>{row.id}</td>
                <td>{row.plan_summary}</td>
                <td>{row.account_type}</td>
                <td>{row.start_date ?? "-"}</td>
                <td>{row.end_date ?? "-"}</td>
                <td>{row.permittable_loss}</td>
              </tr>
            ))}
          </Table>
        )
      )}
    </>
  );
}
