import React, { useEffect, useRef, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { usePost } from "../../../hooks/API/usePost";
import Loading from "../../../components/shared/Loading";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import usePermissions from "../../../hooks/usePermission";
export function GeneralInfo({ data, dataArchive, dataChallenge }) {
  const [visible, setModalVisible] = useState(false);
  const [reason, setReason] = useState("");
  const [refund, setRefund] = useState(false);
  const { loading, handlePostData } = usePost(false);
  const navigate = useNavigate();
  const modalRef = useRef(null);
  const { can } = usePermissions();

  const handleFail = async () => {
    if (!reason) return toast.error("Enter reason!");
    const data = {
      user_challenge_id: dataChallenge?.id,
      reason: reason,
    };

    const res = await handlePostData(
      "challenges/user-challenges/fail",
      data,
      "The Challenge failed successfully"
    );
    if (!res) return;
    handleClose();
    navigate(-1);
  };

  const handleRefund = async () => {
    if (!reason) return toast.error("Enter reason!");
    const data = {
      user_challenge_id: dataChallenge?.id,
      reason: reason,
    };

    const res = await handlePostData(
      "challenges/user-challenges/refund",
      data,
      "The Challenge refunded successfully"
    );
    if (!res) return;
    handleClose();
    navigate(-1);
  };

  const handleClose = () => {
    setModalVisible(false);
    setRefund(false);
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleClose();
  };

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : modalRef.current.close();
  }, [visible]);

  return (
    <>
      {can("update_challenge") && visible && (
        <dialog
          ref={modalRef}
          id="my_modal_1"
          className="modal"
          onCancel={handleESC}
        >
          <div className="modal-box w-11/12 md:w-full max-w-xl bg-indigo-950 p-5 border border-[var(--indigo-purple)]">
            <button
              onClick={handleClose}
              className="btn btn-sm btn-square bg-gradient-to-br from-indigo-700 to-indigo-800 border-0 text-white "
            >
              <IoArrowBack size={20} />
            </button>

            <div className="max-w-[350px] mx-auto">
              {loading ? (
                <div className="w-full flex items-center justify-center h-[300px] text-center">
                  <Loading />
                </div>
              ) : (
                <>
                  <label htmlFor="declineReason" className="form-control">
                    <span className="text-neutral-100 mb-2">
                      Please enter a {refund ? "Refund" : "Decline"} reason:
                    </span>
                    <textarea
                      onChange={(e) => setReason(e.target.value)}
                      className="textarea "
                      name="declineReason"
                      id="declineReason"
                      rows={5}
                    />
                  </label>
                  {refund ? (
                    <button
                      onClick={handleRefund}
                      className="btn btn-outline btn-warning mt-5"
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      onClick={handleFail}
                      className="btn btn-outline btn-warning mt-5"
                    >
                      Submit
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </dialog>
      )}
      <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-2 rounded-md px-5 py-7 my-10 indigo-gradient">
        <div>
          <span className="text-neutral-300 text-base">Name:</span>
          <span className="ml-1.5 text-neutral-100 text-xl break-words">
            {dataChallenge?.user?.first_name +
                " " +
                dataChallenge?.user?.last_name}
          </span>
        </div>
        <div>
          <span className="text-neutral-300 text-base">Email:</span>
          <span className="ml-1.5 text-neutral-100 text-xl break-words">
            {dataChallenge?.user?.email}
          </span>
        </div>
        <div>
          <span className="text-neutral-300 text-base">Balance:</span>
          <span className="ml-1.5 text-neutral-100 text-xl">
            {dataChallenge?.balance}
          </span>
        </div>
        <div>
          <span className="text-neutral-300 text-base">Challenge:</span>
          <span className="ml-1.5 text-neutral-100 text-xl">
            {dataChallenge?.challenge_type_name}
          </span>
        </div>
        <div>
          <span className="text-neutral-300 text-base">Phase:</span>
          <span className="ml-1.5 text-neutral-100 text-xl">
            {dataChallenge?.phase_type_name}
          </span>
        </div>
        <div>
          <span className="leading-none text-neutral-300 text-base">
            State:
          </span>

          <span className="ml-1.5 text-neutral-100 text-xl capitalize">
            {dataChallenge?.state_type_name}
          </span>
        </div>
        {can("update_challenge") && (
          <>
            <div>
              <span className="leading-none text-neutral-300 text-base">
                Platform:
              </span>
              <span className="ml-1.5 text-neutral-100 text-xl capitalize">
                {dataChallenge?.platform}
              </span>
            </div>
            <div>
              <span className="leading-none text-neutral-300 text-base">
                Server:
              </span>
              <span className="ml-1.5 text-neutral-100 text-xl capitalize">
                {dataChallenge?.server}
              </span>
            </div>
            </>
        )}
        {can("update_challenge") && dataChallenge?.status === "active" && (
            <div className="grid grid-cols-2 max-w-sm gap-2 mt-5">
              <button
                  onClick={() => setModalVisible(true)}
                  className="btn btn-outline btn-sm grow btn-error"
              >
                Fail
              </button>
              <button
                  onClick={() => {
                    setModalVisible(true);
                    setRefund(true);
                  }}
                  className="btn btn-outline btn-sm grow btn-warning"
              >
                Refund
              </button>
            </div>
        )}
        {dataChallenge?.reason?.reason && (
            <div className="md:col-span-2 text-neutral-100 p-4 bg-[var(--indigo-purple-dark)] border border-[var(--indigo-purple)] rounded-xl">
              <p>
                <span className="text-error font-semibold">Inactive Reason:</span>{" "}
                {dataChallenge.reason.reason}
              </p>
            </div>
        )}
      </div>

      <div className="flex items-center gap-x-5 font-medium [&>*]:border-none mb-5 mt-3">
        <p className="badge badge-lg bg-indigo-600 text-indigo-100 font-semibold pt-1">
          All times are in GMT
        </p>
        {dataChallenge.status === "inactive" && (
          <p className="badge badge-lg bg-rose-700 text-red-100 font-semibold pt-1">
            Inactive
          </p>
        )}
      </div>

      <div className="w-full grid grid-cols-2 sm:grid-cols-4  gap-3 [&>div]:shadow [&>div]:rounded-lg [&>div]:bg-gradient-to-br [&>div]:from-indigo-800 [&>div]:to-[var(--indigo-purple-dark)]  mb-7 relative z-50">
        {data !== null && dataArchive !== null && (
          <>
            <GeneralInfoStat title={"Balance"} value={data.balance} />
            <GeneralInfoStat title={"Equity"} value={data.equity} />
            <GeneralInfoStat
              title={"Today Profit"}
              value={data.equity - dataArchive.equity}
            />
            {data.profit_target && (
              <GeneralInfoStat
                title={"Target Profit"}
                value={data.profit_target}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}
function GeneralInfoStat({ title, value }) {
  return (
    <div className="text-center py-3">
      <p className="text-white text-xl font-normal mb-2"> {title} </p>
      <p className="text-white font-semibold text-3xl">
        ${Intl.NumberFormat().format(Number(value))}
      </p>
    </div>
  );
}
