import React from "react";

export function PlanSetupTable({ data }) {
  let balancePhases = [...data.balance_phases].sort(
    (a, b) => a.phase_type_id - b.phase_type_id
  );
  let keys = Object.keys(balancePhases[0]);
  const elementsToDelete = [
    "id",
    "name",
    "status",
    "type",
    "order",
    "challenge_balance_id",
    "created_at",
    "updated_at",
    "phase_type_id",
    "phase_type_name",
    "account_type_id",
    "account_type_name",
  ];
  keys = keys.filter((item) => !elementsToDelete.includes(item));
  return (
    <div className="w-full rounded-2xl overflow-scroll md:overflow-hidden shadow-[0_0_2px_0] shadow-black scrollWidth mx-auto my-4">
      <table className="w-full min-w-[700px] h-[700px] bg-[var(--indigo-purple-dark)] text-center capitalize">
        <thead>
          <tr className="text-[#C2C2C2] ">
            <th className="px-6 py-3 "></th>
            {balancePhases.map((title, index) => (
              <th
                key={index}
                className="px-6 py-3 font-medium text-md md:text-xl"
              >
                {title.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="relative text-white text-md md:text-xl font-medium py-10">
          {/* <div className="w-1 top-0 bottom-8 rounded-full absolute left-[25%] bg-[#b9b9b9]"></div> */}
          {keys.map((tableTitle, index) => (
            <tr key={index}>
              <td className="md:pr-3 pl-9  text-left">
                <span className="relative before:absolute before:w-4 before:h-1.5 before:bg-[#696969] before:top-2 before:left-[-25px] before:rounded-xl">
                  {tableTitle.toString().replace(/_/g, " ")}
                </span>
              </td>
              {balancePhases.map((name, index) => (
                <td key={index}>
                  {tableTitle !== "registration_fee"
                    ? name[tableTitle] ?? "-"
                    : `$${name.registration_fee}`}
                </td>
              ))}
            </tr>
          ))}
          <tr>
            <td className="py-4"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
