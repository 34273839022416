import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useState } from "react";
import { getBanners } from "../../utils/requests/dashboardRequest";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";

const url = process.env.REACT_APP_API_BASE_URL + "/storage/";
export default function DashboardBanner() {
  const [loading, setLoading] = useState(true);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const fetchBanners = async () => {
      setLoading((x) => true);
      const banners = await getBanners();

      if (banners) {
        setBanners(banners);
      }
      setLoading((x) => false);
    };

    fetchBanners();
  }, []);

  function handleFilterSize(item, size) {
    const url = item.images.filter((item) => item.device_name === size)[0].src;
    return url;
  }

  return (
    <div className="w-full min-[1100px]:col-span-2">
      {loading ? (
        <div className="w-full h-[400px] skeleton bg-gray-800/50 light:bg-gray-200 rounded-xl"></div>
      ) : (
        <div className="w-full md:h-[400px] relative">
          <Swiper
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination]}
            pagination={{
              el: ".swiper-c-pagination",
            }}
            className="mySwiper rounded-2xl w-full h-full"
          >
            {banners.length > 0 ? (
              banners.map((image) =>
                image.photo ? (
                  <SwiperSlide>
                    <img
                      src={url + image.photo}
                      alt={image.slug}
                      className="w-full h-full "
                    />
                  </SwiperSlide>
                ) : (
                  <SwiperSlide>
                    <picture className=" w-full h-full flex">
                      <source
                        media="(max-width: 799px)"
                        srcset={handleFilterSize(image, "Mobile")}
                      />
                      <source
                        media="(min-width: 800px)"
                        srcset={handleFilterSize(image, "Desktop")}
                      />
                      <img
                        src={handleFilterSize(image, "Desktop")}
                        className="w-full h-full max-h-[300px] sm:max-md:max-h-[350px] md:max-h-[400px]"
                        alt={image.title}
                      />
                    </picture>
                  </SwiperSlide>
                )
              )
            ) : (
              <SwiperSlide>
                <img
                  src="./defaultBanner.png"
                  alt="Golden Fund Banner"
                  className="w-full h-full max-h-[400px] "
                />
              </SwiperSlide>
            )}
          </Swiper>
          <div className="swiper-c-pagination absolute !left-1/2 !-translate-x-1/2 !bottom-4 !z-10 flex items-center justify-center gap-2 mt-2 md:mt-5 [&_.swiper-pagination-bullet]:!bg-[--primary-text] [&_.swiper-pagination-bullet-active]:!bg-[--primary-text] [&_.swiper-pagination-bullet-active]:!scale-125 " />
        </div>
      )}
    </div>
  );
}
