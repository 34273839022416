import React from "react";

export function ShowAmount({ children}) {
  return (
    <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-5 max-w-[1020px] mx-auto">
      {
        children
      }
    </div>
  );
}
