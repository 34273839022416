import Loading from "../../../components/shared/Loading";
import PlanInfoUpdate from "./PlanInfoUpdate";
import TableBackground from "../../../components/AdminShared/TableBackground";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import PageStatus from "../../../components/PageStatus/PageStatus";
import { usePlanInfo } from "../../../hooks/Admin/usePlanInfo";

const PlanInfoLayout = () => {
  const { loading, error, formData, setFormData, handleUpdate } = usePlanInfo();

  return (
    <>
      <PageStatus
        category={"Dashboard"}
        currentPage={"Plan info"}
        logoStarColor="#F8CC15"
      />
      <TableBackground>
        <div>
          <h1 className="text-center text-2xl text-white">
            Plan Info Management
          </h1>
        </div>
        <div className="content">
          {loading ? (
            <div className="w-full text-center">
              <Loading />
            </div>
          ) : error ? (
            <ErrorMessage text={error} />
          ) : (
            <PlanInfoUpdate
              formData={formData}
              setFormData={setFormData}
              handleUpdate={handleUpdate}
            />
          )}
        </div>
      </TableBackground>
    </>
  );
};

export default PlanInfoLayout;
