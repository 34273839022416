import React from "react";

export default function ErrorText({ error, text, optClass }) {
  return (
    <span
      className={`text-sm text-rose-600 ${
        error ? "block" : "hidden"
      } ${optClass}`}
    >
      {text}
    </span>
  );
}
