import { IoPersonCircleOutline } from "react-icons/io5";
import { formatComma } from "../../../utils/formatNumber";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
const headers = [
  "User Name",
  "Total Profit",
  "Purchase Count",
  "Challenge Profit ",
  "Register Date",
];

const style =
  "join-item text-gray-500 bg-transparent hover:underline underline-offset-4 border !border-gray-700 py-3 text-center !border-0 !rounded-md";

export default function MainTable({
  data,
  handleChangePagination,
  loading,
  filter,
  handleFilterChange,
  handleSubmitFilter,
}) {
  const navigate = useNavigate();

  return (
    <div className="dashboard-box ">
      <div>
        <div className="flex flex-col lg:flex-row lg:items-end justify-between mb-5">
          <p className="text-second text-2xl text-center p-3">Your Referrals</p>
          <div className="grid grid-cols-2 lg:flex lg:items-end gap-5">
            <label className={`form-control grow `}>
              <div className="label">
                <span className={`label-text text-second `}>From Date:</span>
              </div>
              <input
                className={`referral-filter input input-sm bg-gray-700 light:bg-gray-400/50 text-gray-200 light:text-gray-800`}
                type="date"
                name="from_date"
                value={filter.from_date}
                onChange={handleFilterChange}
              />
            </label>
            <label className={`form-control grow `}>
              <div className="label">
                <span className={`label-text text-second `}>To Date:</span>
              </div>
              <input
                className={`referral-filter input input-sm bg-gray-700 light:bg-gray-400/50 text-gray-200 light:text-gray-800`}
                type="date"
                name="to_date"
                value={filter.to_date}
                onChange={handleFilterChange}
              />
            </label>

            <button
              className=" text-white bg-[--indigo-purple-light] py-1 px-8 border border-transparent rounded-md hover:bg-[--indigo-purple] hover:border-light-border transition-colors "
              onClick={handleSubmitFilter}
            >
              Filter
            </button>
          </div>
        </div>
        <div
          className={`w-full max-h-[375px] overflow-auto scrollWidth  cursor-pointer rounded-xl relative z-20 `}
        >
          <table className="w-full  rounded-xl overflow-auto ">
            <thead
              className={`text-left text-gray-500 [&_th]:px-4  [&_th]:py-5 capitalize`}
            >
              <tr className="sticky bg-[--box-items-background] top-0 z-30 shadow-[0_1px_0_0px] shadow-gray-500/40 ">
                {/* <th>ID</th> */}
                {headers.map((th) => (
                  <th>{th}</th>
                ))}
              </tr>
            </thead>
            <tbody
              className={`[&_td]:p-4 text-white [&_tr:not(:last-child)]:border-b [&_tr]:border-[--chart-grid] [&_tr:nth-child(even)]:- light:[&_tr:nth-child(even)]:- relative`}
            >
              {loading
                ? Array.from({ length: 5 }).map((item) => (
                    <tr className="w-full ">
                      <td>
                        <div className="flex items-center gap-2 py-1 ">
                          <div className="rounded-lg w-6 h-6 bg-gray-700/60 light:bg-gray-300 animate-pulse" />
                          <div className="rounded-lg w-20 h-5 bg-gray-700/60 light:bg-gray-300 animate-pulse" />
                        </div>
                      </td>
                      <td>
                        <div className="rounded-lg w-[50%] h-5 bg-gray-700/60 light:bg-gray-300 animate-pulse" />
                      </td>
                      <td>
                        <div className="rounded-lg w-[80%] h-5 bg-gray-700/60 light:bg-gray-300 animate-pulse" />
                      </td>
                      <td>
                        <div className="rounded-lg w-[40%] h-5 bg-gray-700/60 light:bg-gray-300 animate-pulse" />
                      </td>
                      <td>
                        <div className="rounded-lg w-[80%] h-5 bg-gray-700/60 light:bg-gray-300 animate-pulse" />
                      </td>
                    </tr>
                  ))
                : data.data.length > 0 &&
                  data.data.map((row) => (
                    <tr
                      onClick={() => navigate(`${row.referral_id}`)}
                      className={`relative z-0 hover:bg-[#1b232e] light:hover:bg-gray-300`}
                    >
                      {/* <td>{row.id}</td> */}
                      <td>
                        <div className="flex items-center gap-2">
                          <IoPersonCircleOutline
                            className="mb-2.5 fill-[--secondary-text] "
                            size={22}
                          />

                          <div>{row.first_name + row.last_name}</div>
                        </div>
                      </td>
                      <td>
                        <div>${formatComma(row.total_profit)}</div>
                      </td>
                      <td>{formatComma(row.product_count)}</td>
                      <td>${formatComma(row.product_profit)}</td>
                      <td>{row.register_date}</td>
                    </tr>
                  ))}
            </tbody>
          </table>

          {!loading && !data.data.length > 0 && (
            <div className="w-full h-[335px] ">
              <Player
                src="https://lottie.host/0a086584-47fd-4247-ad9e-c3e39d6ec20d/yB2qgX25mt.json"
                className="player h-[300px]"
                autoplay
                loop
              />
              <p className="-mt-8 text-second text-center font-semibold text-lg">
                You don't have a referral!
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="mt-5">
        {data && (
          <div className="w-full flex items-center justify-end">
            <ReactPaginate
              className={`w-full ${
                data.last_page > 3 ? "!max-w-[400px]" : "max-w-[300px]"
              } items-center join [&_*]:text-xs sm:[&_*]:text-sm !rounded-none`}
              activeLinkClassName="!text-white !text-lg"
              pageClassName="grow inline-flex !border-y-0"
              previousClassName="grow inline-flex"
              nextClassName="grow inline-flex"
              pageLinkClassName={`${style} w-full`}
              previousLinkClassName={`${style} w-full !bg-transparent hover:!bg-gray-700/50 hover:!no-underline !border !border-gray-700/50 `}
              nextLinkClassName={`${style} w-full !bg-transparent hover:!bg-gray-700/50 hover:!no-underline !border !border-gray-700/50 `}
              breakClassName="grow inline-flex"
              breakLinkClassName={`${style} grow`}
              forcePage={data.current_page - 1}
              breakLabel="..."
              nextLabel="Next"
              onPageChange={(event) => handleChangePagination(event)}
              pageRangeDisplayed={2}
              marginPagesDisplayed={1}
              pageCount={data.last_page}
              previousLabel="Previous"
              renderOnZeroPageCount={null}
            />
          </div>
        )}
      </div>
    </div>
  );
}
