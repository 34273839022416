import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter } from "react-router-dom";

import Router from "./routes";
import { authActions } from './store/slices/auth';
import Loading from './components/shared/Loading';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

export default function App() {

  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  console.log('tt');

  useEffect(() => {
    console.log('ttt');
    // Check for stored authentication state
    if(token === null){
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const userDataObject = JSON.parse(storedUserData);
      console.log('auth');
      dispatch(
        authActions.loginSuccess({
          token: userDataObject.token,
          user: userDataObject.user,
          role: userDataObject.role,
          permission: userDataObject.permissions,
        })
      );
    }
  }
  setLoading(false);
  }, [dispatch]);
console.log('gere');

  return (
    <>
    <BrowserRouter>
    <TawkMessengerReact
                propertyId="66260eb31ec1082f04e56175"
                widgetId="1hs2ait4b"/>
    {loading ? (
      <div className='bg-primaryPurple w-full h-screen flex items-center justify-center'>
        <Loading optClass={" block mx-auto mt-20 text-white"} />
      </div>
      )
    :
    (

        <Router />
    )
    }
    </BrowserRouter>
    </>
  );
}
