import React from "react";
import { ChatAdminBubble } from "./ChatAdminBubble";
import { ChatUserBubble } from "./ChatUserBubble";
import Loading from "../../../components/shared/Loading";

export function Chat({ messages, newMessage, user }) {
  return (
    <div className=" w-full h-[600px] max-h-[600px] flex flex-col-reverse overflow-scroll scrollWidth mb-5 px-10 py-5 rounded-xl bg-[#4743e040]/20 shadow-[0_0_2px_0] shadow-neutral-900">
      {newMessage ? (
        <div className="w-full h-full flex items-center justify-center">
          <Loading />
        </div>
      ) : (
        <div className="w-full">
          {messages.map((item) =>
            user !== item.user_id && item.type !== "user" ? (
              <ChatAdminBubble item={item} />
            ) : (
              <ChatUserBubble item={item} />
            )
          )}
        </div>
      )}
    </div>
  );
}
