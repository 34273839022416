import React, { useState } from "react";
import PageStatus from "../../../components/PageStatus/PageStatus";
import Pagination from "../../../components/AdminShared/Pagination";
import Loading from "../../../components/shared/Loading";
import { Link } from "react-router-dom";
import useUser from "../../../hooks/Admin/User/useUser";
import TableBackground from "../../../components/AdminShared/TableBackground";
import Table from "../../../components/AdminShared/Table";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import RequestDetailsButton from "../../../components/AdminShared/RequestsDetailsButton";
import { usePagination } from "../../../hooks/usePagination";
import RequestDetailsInput from "../../../components/AdminShared/RequestsDetailsInput";
import BanModal from "./Ban/BanModal";
const initialFilter = {
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
};
const filterOptions = ["firstname", "lastname", "email", "phone"];
export default function Users() {
  const {
    pagination,
    handleFilterChange,
    query,
    handleSubmitFilter,
    handleChangePagination,
    filter,
  } = usePagination(initialFilter, filterOptions);
  const { error, loading, users, userLinks } = useUser(pagination, query);
  const [selectedUser, setSelectedUser] = useState(null);

  console.log(users);

  return (
    <>
      {selectedUser && (
        <BanModal user={selectedUser} setUser={setSelectedUser} />
      )}
      <PageStatus
        category={"Users"}
        currentPage={"Users"}
        logoStarColor="#F8CC15"
      />
      <TableBackground>
        {error ? (
          <ErrorMessage />
        ) : loading ? (
          <div className="w-full flex items-center justify-center h-[700px]">
            <Loading />
          </div>
        ) : (
          <div className="content mx-5 py-5 text-center text-white ">
            <form onSubmit={handleSubmitFilter} className="mb-10 text-end">
              <div className="w-full grid grid-cols-1 sm:grid-cols-4 gap-3">
                <RequestDetailsInput
                  title="First Name"
                  value={filter.firstname}
                  name="firstname"
                  onChange={handleFilterChange}
                  placeholder="First Name"
                />
                <RequestDetailsInput
                  title="Last Name"
                  value={filter.lastname}
                  name="lastname"
                  onChange={handleFilterChange}
                  placeholder="Last Name"
                />
                <RequestDetailsInput
                  title="Email"
                  value={filter.email}
                  name="email"
                  onChange={handleFilterChange}
                  placeholder="Email"
                />

                <RequestDetailsInput
                  title="Phone"
                  value={filter.phone}
                  name="phone"
                  onChange={handleFilterChange}
                  placeholder="Phone"
                  type="number"
                />
              </div>
              <RequestDetailsButton
                title="Filter"
                optClass="btn-success mt-3 "
              />
            </form>
            <Table theads={["Id", "Name", "Email", "Role", "Phone", "Actions"]}>
              {users.map((user) => (
                <tr>
                  <td>{user.id}</td>
                  <td>
                    <p>{user.first_name + " " + user.last_name}</p>
                  </td>
                  <td>
                    <p>{user.email}</p>
                  </td>
                  <td>
                    <p>{user.roles[0]?.title}</p>
                  </td>
                  <td>
                    <p>{user.phone}</p>
                  </td>
                  <td className="grid grid-cols-1 2xl:grid-cols-4 gap-2 min-w-[200px]">
                    <Link
                      to={`update/${user.id}`}
                      className="btn btn-primary btn-outline  btn-sm "
                    >
                      Update
                    </Link>
                    <Link
                      to={`add-challenge/${user.id}`}
                      className="btn btn-secondary btn-outline  btn-sm "
                    >
                      Add Challenge
                    </Link>
                    <Link
                      to={`certificates/${user.id}`}
                      className="btn btn-primary btn-outline  btn-sm "
                    >
                      Certificates
                    </Link>
                    <Link
                      to={`payments/${user.id}`}
                      className="btn btn-secondary btn-outline  btn-sm "
                    >
                      Payments
                    </Link>
                    <Link
                      to={`add-payment/${user.id}`}
                      className="btn btn-secondary btn-outline  btn-sm "
                    >
                      Add Payment
                    </Link>
                    <button
                      onClick={() => setSelectedUser(user)}
                      className="btn btn-primary btn-outline btn-sm"
                    >
                      {user.status === 2 ? "Unblock" : "Ban"}
                    </button>
                  </td>
                </tr>
              ))}
            </Table>
          </div>
        )}
        {userLinks && (
          <Pagination handleOnClick={handleChangePagination} data={userLinks} />
        )}
      </TableBackground>
    </>
  );
}
