import React from "react";
import { Link } from "react-router-dom";

export function ListItem({ icon, text, url, active, handleSidebar }) {
  function onClicked() {
    if (window.innerWidth <= 768) {
      handleSidebar();
    }
  }

  return (
    <Link to={url}>
      <li
        onClick={onClicked}
        className={`w-full h-12 flex items-center gap-2 pl-4  py-1.5 mb-4 hover:bg-[var(--indigo-purple)] ${
          url === active ? `bg-[var(--indigo-purple)]` : ""
        }  hover:cursor-pointer transition-all`}
      >
        {icon}
        <span className="text-lg capitalize mb-[-10px]">{text} </span>
      </li>
    </Link>
  );
}
export function ListDivider({ text }) {
  return (
    <li className="divide-y divide-neutral-500 inline-grid mb-3">
      <span className="capitalize text-sm opacity-80 ml-8  ">{text}</span>
      <span></span>
    </li>
  );
}
