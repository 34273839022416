import { useEffect, useState } from "react";
  import { useFetch } from "../API/useFetch";
export function useVerificationRequests() {
  const [userSelected, setUserSelected] = useState(null);
  const [verificationRequests, setVerificationRequests] = useState(null);
  const [error, setError] = useState(false);
  const {loading, fetchData} = useFetch()
  async function getRequestVerifications() {
      setError((prev) => false);
      const res = await fetchData("users/verification/identity");
      if (!res) return;
      if (res.verification_requests.length === 0) {
        setError((prev) => true);
        return;
      }
      setVerificationRequests(res.verification_requests); 
  }

  useEffect(() => {
    if (verificationRequests == null) {
      getRequestVerifications();
    }
  }, [verificationRequests]);

  return {
    loading,
    error,
    userSelected,
    setUserSelected,
    verificationRequests,
    getRequestVerifications,
  };
}
