import { TransferDetailsLayout } from "../../components/shared/TransferDetailsLayout";
import { TransferDetail } from "../../components/shared/TransferDetail";
import { SubmitButton } from "../../components/shared/SubmitButton";
export default function DepositConfirmation({
  depositData,
  handleSetDepositData,
  submitPayment,
}) {
  function handleBack() {
    handleSetDepositData({
      paymentMethod: null,
      depositAmount: null,
      creditAmount: null,
      confirmed: false,
      step: 1,
    });
  }
  return (
    <>
      <div className="w-full md:max-w-md flex items-center justify-center md:justify-start md:pl-7 my-5">
        {depositData.paymentMethod === "Crypto" && (
          <div
            role="alert"
            className="alert bg-transparent border border-[#F8CC15] rounded-xl mt-5 max-w-md"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 shrink-0 stroke-current"
              fill="#F8CC15"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
            <span className="text-[#F8CC15]">
              Warning: Dear user, once you complete the transaction, it may take
              about 3 to 5 minutes at the how payment stage. Please wait for
              confirmation
            </span>
          </div>
        )}
      </div>
      <div className="flex flex-col md:flex-row items-center gap-10 ">
        <TransferDetailsLayout maxWidth="max-w-md">
          <TransferDetail
            title={"deposit with"}
            value={depositData.paymentMethod}
          />
          <TransferDetail title={"deposit to"} value={"GF Wallet"} />
          <TransferDetail
            title={"deposit amount"}
            value={`$${Intl.NumberFormat().format(depositData.depositAmount)}`}
          />
        </TransferDetailsLayout>
        <div>
          <SubmitButton onClick={submitPayment} />
          <SubmitButton
            onClick={handleBack}
            text="back"
            optClass="border border-[var(--indigo-purple)] bg-[var(--indigo-purple-dark)] hover:border-indigo-500 hover:bg-[var(--indigo-purple-dark)]"
          />
        </div>
      </div>
    </>
  );
}
