import React, { useEffect, useState } from "react";

export function ProfilePhoto({ user }) {
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (user.photo == null) {
      setSelectedImage("../UserProfilePhoto.png");
    } else {
      setSelectedImage(user.photo);
    }
  }, []);
  return (
    <div className="relative w-[180px] h-[180px] flex items-center justify-center">
      <img src={selectedImage} className="w-full h-full rounded-full" alt="" />
      <img
        src="/profileBorder.png"
        className="w-full h-full absolute scale-125 left-1"
        alt=""
      />
    </div>
  );
}
