import ReactPaginate from "react-paginate";
import { usePermissions } from "../../../hooks/Admin/PermissionManage/usePermissions";
import { usePagination } from "../../../hooks/usePagination";
import Loading from "../../../components/shared/Loading";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import ModalLayout from "../../../components/AdminShared/ModalLayout";
import { IoAdd } from "react-icons/io5";

const headers = ["permission", "actions"];

const style =
  "join-item text-gray-500 bg-transparent hover:underline underline-offset-4 border !border-gray-700 py-3 text-center !border-0 !rounded-md";

export default function PermissionsLayout() {
  const { pagination, handleChangePagination } = usePagination();
  const {
    error,
    loading,
    addPermissionLoading,
    permissions,
    handleOpenModal,
    AddNewPermission,
    setNewPermission,
  } = usePermissions(pagination);

  return (
    <div className="w-full">
      <ModalLayout id="add-permission">
        <div className="my-3">
          <div className="join w-full">
            <input
              disabled={addPermissionLoading}
              onChange={(e) => setNewPermission(e.target.value)}
              className="input grow bg-transparent !border border-light-border focus-within:border-indigo-500 light:focus-within:border-[--indigo-purple-light] !outline-0 text-white join-item"
              placeholder="New Permission"
            />
            <button
              disabled={addPermissionLoading}
              onClick={AddNewPermission}
              className="btn btn-success disabled:bg-gray-500 light:disabled:bg-gray-300 join-item"
            >
              {addPermissionLoading ? (
                <span className="loading loading-dots loading-sm text-gray-400"></span>
              ) : (
                <IoAdd size={20} className="text-white" />
              )}
            </button>
          </div>
        </div>
      </ModalLayout>

      <div className="w-full flex items-center justify-end">
        <button
          className=" text-white bg-[--indigo-purple-light] py-1 px-8 border border-transparent rounded-md hover:bg-[--indigo-purple] hover:border-light-border transition-colors "
          onClick={handleOpenModal}
        >
          Add
        </button>
      </div>
      <div
        className={`w-full max-h-[700px] overflow-auto scrollWidth  cursor-pointer rounded-xl relative z-20 border border-gray-700 mt-3`}
      >
        {!loading &&
          (error ? (
            <ErrorMessage text="Please try again!" optcClass="p-5" />
          ) : (
            <table className="w-full  rounded-xl overflow-auto ">
              <thead
                className={`text-left text-gray-500 [&_th]:px-4  [&_th]:py-5 capitalize`}
              >
                <tr className="sticky bg-gray-700 text-gray-300 top-0 z-30 shadow-[0_1px_0_0px] shadow-gray-500/40 text-center">
                  {/* <th>ID</th> */}
                  {headers.map((th) => (
                    <th>{th}</th>
                  ))}
                </tr>
              </thead>
              <tbody
                className={`[&_td]:p-4 text-white [&_tr:not(:last-child)]:border-b [&_tr]:border-[--chart-grid]  relative`}
              >
                {permissions.permissions.data.map((row) => (
                  <tr className={`relative z-0 `}>
                    <td>{row.title}</td>
                    <td>-</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ))}

        {loading && (
          <div className="w-full h-[633px] flex items-center justify-center">
            <Loading />
          </div>
        )}
      </div>

      <div className="mt-5">
        {permissions && (
          <div className="w-full flex items-center justify-end">
            <ReactPaginate
              className={`w-full ${
                permissions.permissions.last_page > 3
                  ? "!max-w-[400px]"
                  : "max-w-[300px]"
              } items-center join [&_*]:text-xs sm:[&_*]:text-sm !rounded-none`}
              activeLinkClassName="!text-white !text-lg"
              pageClassName="grow inline-flex !border-y-0"
              previousClassName="grow inline-flex"
              nextClassName="grow inline-flex"
              pageLinkClassName={`${style} w-full`}
              previousLinkClassName={`${style} w-full !bg-transparent hover:!bg-gray-700/50 hover:!no-underline !border !border-gray-700/50 `}
              nextLinkClassName={`${style} w-full !bg-transparent hover:!bg-gray-700/50 hover:!no-underline !border !border-gray-700/50 `}
              breakClassName="grow inline-flex"
              breakLinkClassName={`${style} grow`}
              forcePage={permissions.permissions.current_page - 1}
              breakLabel="..."
              nextLabel="Next"
              onPageChange={(event) => handleChangePagination(event)}
              pageRangeDisplayed={2}
              marginPagesDisplayed={1}
              pageCount={permissions.permissions.last_page}
              previousLabel="Previous"
              renderOnZeroPageCount={null}
            />
          </div>
        )}
      </div>
    </div>
  );
}
