import React from "react";
import { PiSquaresFourBold } from "react-icons/pi";
import { CiPower } from "react-icons/ci";
import { ListItem, ListDivider } from "./ListItem";
import { Copyright } from "./Copyright";
import { UserStatus } from "./UserStatus";
import { ListUl } from "./ListUl";
import { ListLogOutItem } from "./ListLogOutItem";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { MdOutlineNewspaper } from "react-icons/md";
import { IoStatsChart } from "react-icons/io5";
import { IoNewspaperOutline } from "react-icons/io5";
import { LuPackagePlus } from "react-icons/lu";
import { LuMailPlus } from "react-icons/lu";
import { FaUser } from "react-icons/fa";
import icons from "./icons";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import usePermissions from "../../hooks/usePermission";
import { useLocation } from "react-router-dom";

export default function SidebarMenu({
  sideBar,
  handleSidebar,
  logoutLoading,
  handleLogOut,
}) {
  const activeLink = useLocation().pathname;
  const { can } = usePermissions();

  return (
    <>
      <div
        className={`min-w-[300px] w-[300px] md:max-w-[300px] min-h-screen  h-full md:!left-0 md:block ${
          !sideBar ? "left-[-300px]" : "left-0"
        } absolute md:static`}
      >
        <div
          className={`w-[300px] max-w-[300px] min-w-fit h-screen md:!left-0 duration-300 bg-[var(--indigo-purple-dark)] rounded-br-3xl ${
            !sideBar ? "left-[-300px]" : "left-0"
          }  flex flex-col justify-between fixed top-0 
          } left-0 overflow-y-auto overflow-x-hidden scrollWidth transition-all z-[999]`}
        >
          <div className="text-white">
            <UserStatus />
            <ListUl>
              <ListItem
                active={activeLink}
                handleSidebar={handleSidebar}
                icon={<PiSquaresFourBold size={40} />}
                text={"Dashboard"}
                url={"/"}
              />
              {can("show_challenge") && (
                <>
                  <ListDivider text={"challenges"} />
                  <ListItem
                    active={activeLink}
                    handleSidebar={handleSidebar}
                    icon={icons.newChallenge}
                    text={"new challenge"}
                    url={"/new-challenge"}
                  />
                  <ListItem
                    active={activeLink}
                    handleSidebar={handleSidebar}
                    icon={icons.myChallenge}
                    text={"my challenges"}
                    url={"/my-challenges"}
                  />
                </>
              )}
              {can("show_wallet") && (
                <>
                  <ListDivider text={"Finance"} />
                  <ListItem
                    active={activeLink}
                    handleSidebar={handleSidebar}
                    icon={icons.myWallet}
                    text={"my wallets"}
                    url={"/wallets"}
                  />
                </>
              )}
              {can("show_deposit") && (
                <ListItem
                  active={activeLink}
                  handleSidebar={handleSidebar}
                  icon={icons.deposit}
                  text={"deposits"}
                  url={"/deposits"}
                />
              )}

              {can("show_withdraw") && (
                <ListItem
                  active={activeLink}
                  handleSidebar={handleSidebar}
                  icon={icons.withdraw}
                  text={"withdraws"}
                  url={"/withdraws"}
                />
              )}
              {can("show_referral") && (
                <>
                  <ListDivider text={"Account"} />
                  <ListItem
                    active={activeLink}
                    handleSidebar={handleSidebar}
                    icon={icons.referral}
                    text={"referrals"}
                    url={"/referrals"}
                  />
                </>
              )}
              {can("show_top_trader") && (
                <ListItem
                  active={activeLink}
                  handleSidebar={handleSidebar}
                  icon={icons.topTrader}
                  text={"top traders"}
                  url={"/top-traders"}
                />
              )}
              {can("show_verification") && (
                <ListItem
                  active={activeLink}
                  handleSidebar={handleSidebar}
                  icon={icons.verification}
                  text={"verification"}
                  url={"/verification"}
                />
              )}
              {can("show_certificate") && (
                <ListItem
                  active={activeLink}
                  handleSidebar={handleSidebar}
                  icon={icons.certificate}
                  text={"certificates"}
                  url={"/certificates"}
                />
              )}
              {can("show_ticket") && (
                <>
                  <ListDivider text={"Support"} />
                  <ListItem
                    active={activeLink}
                    handleSidebar={handleSidebar}
                    icon={icons.ticket}
                    text={"tickets"}
                    url={"/tickets"}
                  />
                </>
              )}

              {can("read_ticket") && (
                <>
                  <ListDivider text={"support"} />
                  <ListItem
                    active={activeLink}
                    handleSidebar={handleSidebar}
                    icon={<LuMailPlus size={20} />}
                    text={"tickets"}
                    url={"/admin/tickets"}
                  />
                </>
              )}
              {can("read_user_challenge_requests") && (
                <>
                  <ListDivider text={"challenge"} />
                  <ListItem
                    active={activeLink}
                    handleSidebar={handleSidebar}
                    icon={<LuPackagePlus size={20} />}
                    text={"challenge requests"}
                    url={"/admin/challenge-requests"}
                  />
                  <ListItem
                    active={activeLink}
                    handleSidebar={handleSidebar}
                    icon={<LuPackagePlus size={20} />}
                    text={"user challenges"}
                    url={"/admin/user-challenges"}
                  />
                </>
              )}

              {can("read_challenge") && (
                <>
                  <ListItem
                    active={activeLink}
                    handleSidebar={handleSidebar}
                    icon={<LuPackagePlus size={20} />}
                    text={"success challenges"}
                    url={"/admin/success"}
                  />

                  <ListItem
                    active={activeLink}
                    handleSidebar={handleSidebar}
                    icon={<LuPackagePlus size={20} />}
                    text={"inactive challenges"}
                    url={"/admin/inactive-challenges"}
                  />
                </>
              )}

              {can("read_withdraw") && (
                <>
                  <ListDivider text={"finance"} />

                  {/* <ListItem
                  active={active}
                  handleSidebar={handleSidebar}
                  icon={<GrUserAdmin size={20} />}
                  text={"failed challenges"}
                  url={"failed"}
                 
                /> */}
                  {can("read_ticket") && (
                    <ListItem
                      active={activeLink}
                      handleSidebar={handleSidebar}
                      icon={<FaMoneyBillTransfer size={20} />}
                      text={"payment requests"}
                      url={"/admin/payment-requests"}
                    />
                  )}
                  <ListItem
                    active={activeLink}
                    handleSidebar={handleSidebar}
                    icon={<FaMoneyBillTransfer size={20} />}
                    text={"withdraw requests"}
                    url={"/admin/withdraw-requests"}
                  />
                </>
              )}
              {can("read_user") && (
                <>
                  <ListDivider text={"users"} />
                  <ListItem
                    active={activeLink}
                    handleSidebar={handleSidebar}
                    icon={<FaUser size={20} />}
                    text={"Users"}
                    url={"/admin/users"}
                  />
                </>
              )}
              {can("read_verification") && (
                <ListItem
                  active={activeLink}
                  handleSidebar={handleSidebar}
                  icon={<FaUser size={20} />}
                  text={"verification requests"}
                  url={"/admin/verification-requests"}
                />
              )}
              {can("read_banner") && (
                <>
                  <ListDivider text={"dashboard"} />
                  <ListItem
                    active={activeLink}
                    handleSidebar={handleSidebar}
                    icon={<MdOutlineNewspaper size={20} />}
                    text={"banner"}
                    url={"/admin/banner"}
                  />
                </>
              )}
              {can("read_stat") && (
                <ListItem
                  active={activeLink}
                  handleSidebar={handleSidebar}
                  icon={<MdOutlineNewspaper size={20} />}
                  text={"stats"}
                  url={"/admin/stats"}
                />
              )}
              {can("read_plans_info") && (
                <ListItem
                  active={activeLink}
                  handleSidebar={handleSidebar}
                  icon={<MdOutlineNewspaper size={20} />}
                  text={"Plans info"}
                  url={"/admin/plans-info"}
                />
              )}
              {can("read_plan") && (
                <ListItem
                  active={activeLink}
                  handleSidebar={handleSidebar}
                  icon={<MdOutlineNewspaper size={20} />}
                  text={"Plans"}
                  url={"/admin/plans"}
                />
              )}
              {can("read_top_trader") && (
                <>
                  <ListDivider text={"top trader"} />
                  <ListItem
                    active={activeLink}
                    handleSidebar={handleSidebar}
                    icon={<IoStatsChart size={20} />}
                    text={"Top traders"}
                    url={"/admin/top-traders"}
                  />
                  <ListItem
                    active={activeLink}
                    handleSidebar={handleSidebar}
                    icon={<IoStatsChart size={20} />}
                    text={"Golden Top traders"}
                    url={"/admin/golden-top-traders"}
                  />
                  <ListItem
                    active={activeLink}
                    handleSidebar={handleSidebar}
                    icon={<IoStatsChart size={20} />}
                    text={"Withdraw Top traders"}
                    url={"/admin/withdraw-top-traders"}
                  />
                </>
              )}
              {can("read_blog") && (
                <>
                  <ListDivider text={"blog"} />
                  <ListItem
                    active={activeLink}
                    handleSidebar={handleSidebar}
                    icon={<IoNewspaperOutline size={20} />}
                    text={"posts"}
                    url={"/post"}
                  />
                </>
              )}

              {can("update_challenge") && (
                <>
                  <ListDivider text={"Authorization"} />
                  <ListItem
                    active={activeLink}
                    handleSidebar={handleSidebar}
                    icon={<MdOutlineAdminPanelSettings size={20} />}
                    text={"permissions"}
                    url={"/admin/permissions"}
                  />
                </>
              )}
              <ListLogOutItem
                icon={icons.logout}
                text={"Log out"}
                loading={logoutLoading}
                handleLogOut={handleLogOut}
              />
            </ListUl>
          </div>
          <Copyright />
        </div>
      </div>
      <div
        onClick={handleSidebar}
        className={`md:hidden absolute top-0 bottom-0 w-full h-full min-h-screen bg-[#030303a1] z-[990] cursor-pointer ${
          sideBar ? "" : "hidden"
        }`}
      ></div>
    </>
  );
}
