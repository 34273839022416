import React from "react";

export default function PlanStepIndicator({ children, headerText }) {
  console.log(headerText);
  return (
    <div className="mx-5 mt-5 mb-3">
      <p className="text-3xl text-white font-bold mb-3 first-letter:uppercase">
        {headerText}{" "}
      </p>
      <ol className=" flex items-start">{children}</ol>
    </div>
  );
}

export function IndicatorItem({
  num,
  text,
  last = false,
  currentStep,
  setData,
}) {
  function handleClick() {
    if (currentStep > num) {
      setData((prev) => ({ ...prev, step: num }));
    }
  }
  return (
    <li
      onClick={handleClick}
      className="max-w-36 relative w-full mb-6 cursor-pointer"
    >
      <div className="flex items-center">
        <div
          className={`min-w-6 min-h-6 max-w-6 max-h-6 z-10 font-bold flex items-center justify-center  rounded-full pt-1 ${
            currentStep >= num
              ? " bg-[var(--indigo-purple)] text-white "
              : "bg-[#C0C0C0] text-white"
          }`}
        >
          <span>{num}</span>
        </div>
        {!last && <div className="flex w-full h-0.5 bg-white"></div>}
      </div>
      <div className="mt-3">
        <h3 className={`text-xs  text-white capitalize `}>{text}</h3>
      </div>
    </li>
  );
}
