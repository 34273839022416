let cachedToken = null;

export const getToken = () => {
    // If token is cached, return it
    // if (cachedToken) {
    // return cachedToken;
    // }

    // If not cached, retrieve from localStorage and cache it
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
    const userDataObject = JSON.parse(storedUserData);
    cachedToken = userDataObject?.token; // Cache the token
    return cachedToken;
    }

    return null; // Return null if no token is found
};

export const clearToken = () => {
    // Clear token from memory and localStorage
    cachedToken = null;
    localStorage.removeItem('userData');
};