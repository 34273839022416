import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export function ChatTitle({ title, id }) {
  const navigate = useNavigate();
  return (
    <div className="w-full flex items-center justify-between mb-5">
      <div className="flex items-center gap-2">
        <button onClick={() => navigate(-1)}>
          <FaArrowLeft className="fill-neutral-100" />
        </button>
        <p className="text-neutral-100 text-2xl font-semibold -mb-2">{title}</p>
        <span className="badge badge-sm bg-neutral-500 text-neutral-100 font-semibold border-0 pt-0.5">
          {id}
        </span>
      </div>
      <div className="flex items-center gap-2">
        {/* <span className="badge badge-sm badge-error badge-outline pt-0.5">
          false
        </span>
        <span className="badge badge-sm badge-success badge-outline pt-0.5">
          true
        </span> */}
      </div>
    </div>
  );
}
