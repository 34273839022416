import React from "react";

const TableFilters = ({
  children,
  title = "",
  handleSubmitFilter,
  handleReset,
  alignLeft,
}) => {
  const length = children?.length || 0;
  return (
    <div
      className={`flex flex-col lg:flex-row lg:items-center ${
        title !== "" ? "justify-between " : "justify-end"
      } ${alignLeft && "!justify-start"} mb-5 gap-3 lg:gap-10 xl:gap-20 `}
    >
      {title !== "" && (
        <p className="max-lg:w-full text-second text-2xl text-start pt-2">
          {title}
        </p>
      )}
      <form
        className={`grid justify-start ${
          length < 5 ? `grid-cols-2 lg:flex lg:items-end` : `grid-cols-5`
        }  flex-wrap gap-5`}
        onSubmit={handleSubmitFilter}
      >
        {children}

        {handleReset && (
          <button
            className="max-h-[36px] self-end text-white bg-gray-700 light:bg-gray-300 py-1 px-8 border border-transparent rounded-md hover:bg-gray-700/70 hover:border-light-border transition-colors "
            onClick={handleReset}
          >
            Reset
          </button>
        )}
        <button
          className="max-h-[36px] self-end text-white bg-[--indigo-purple-light] py-1 px-8 border border-transparent rounded-md hover:bg-[--indigo-purple] hover:border-light-border transition-colors "
          type="submit"
        >
          Filter
        </button>
      </form>
    </div>
  );
};

export default TableFilters;
