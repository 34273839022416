import React from "react";

export function PlanBalanceBtnLayout({ children }) {
  return (
    <div className="flex flex-col md:flex-row items-center gap-3">
      <span className="text-lg text-white font-semibold">Balance:</span>
      <div
        className={`w-full grid grid-cols-2 lg:grid-cols-4 gap-5 items-center md:my-4 relative z-10`}
      >
        {children}
      </div>
    </div>
  );
}
