import { useSelector } from "react-redux";

const usePermissions = () => {
  const permissions = useSelector((state) => state.auth.permission);

  const can = (permission) => {
    return permissions?.includes(permission);
  };

  return { can };
};

export default usePermissions;
