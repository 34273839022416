import { useSelector } from "react-redux";
import { useFetch } from "../API/useFetch";
import { useEffect, useState } from "react";

export function useReferrals(pagination, query) {
  const user = useSelector((state) => state.auth.user);
  const [chartsData, setChartsData] = useState();
  const [referralByMonth, setReferralByMonth] = useState({ x: [], y: [] });
  const [profitByMonth, setProfitByMonth] = useState({ x: [], y: [] });
  const [donutChart, setDonutChart] = useState();
  const [referralUsers, setReferralUsers] = useState();

  const [wallet, setWallet] = useState();

  const { loading, fetchData } = useFetch();
  const { loading: walletLoading, fetchData: handleFetch } = useFetch();
  const { loading: referralsTableLoading, fetchData: fetchReferralsTable } =
    useFetch();
  const { loading: withdrawLoading, fetchData: handleFetchWithdraw } =
    useFetch(false);

  async function getChartsData() {
    const res = await fetchData(`users/referrals/${user.id}`);
    if (!res) return;

    setChartsData(res);

    setReferralByMonth({
      x: res.referral_by_month.map((item) => item.month_name),
      y: res.referral_by_month.map((item) => item.count),
    });

    setProfitByMonth({
      x: res.profit_per_month.map((item) => item.month_name),
      y: res.profit_per_month.map((item) => item.count),
    });

    setDonutChart({
      data: res.donut_chart_profits.map((item) => item.earning ?? 0),
      legends: res.donut_chart_profits.map((item) => item.name),
    });
  }

  async function getWalletData() {
    const res = await handleFetch(`users/referrals/wallet/${user.id}`);
    if (!res) return;

    setWallet(res);
  }

  async function getReferralUsers() {
    const res = await fetchReferralsTable(
      `users/referrals/users/${user.id}?page=${pagination}${query}`
    );
    if (!res) return;

    setReferralUsers(res);
  }

  async function handleWithdraw() {
    const res = await handleFetchWithdraw(`users/referrals/deposit`, true);
    if (!res) return;

    getWalletData();
  }

  useEffect(() => {
    getChartsData();
    getWalletData();
  }, []);

  useEffect(() => {
    getReferralUsers();
  }, [pagination, query]);

  return {
    referralsTableLoading,
    withdrawLoading,
    walletLoading,
    loading,
    wallet,
    handleWithdraw,
    chartsData,
    referralByMonth,
    profitByMonth,
    donutChart,
    referralUsers,
  };
}
