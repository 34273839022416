import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import Loading from "../../components/shared/Loading";
import ErrorMessage from "../../components/shared/ErrorMessage";
import {useFetch} from "../../hooks/API/useFetch"
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const appearance = {
  theme: "night",
  labels: "floating",
  variables: {
    colorPrimary: "#4743e0",
    tabIconColor: "#ff0000",
  },
};
export function DepositPay({ depositData }) {
  const [clientSecret, setClinetSecret] = useState("");
  const {loading, error, fetchData} = useFetch()

  async function fetchSecretKey() {
      const res = await fetchData("finance/deposit/stripe?amount=" + depositData.depositAmount);
      if (res?.clientSecret) {
        setClinetSecret(res.clientSecret);
      }
  }

  useEffect(() => {
    fetchSecretKey();
  }, []);

  function handleFetchSecretKey() {
    fetchSecretKey();
  }

  return (
    <div className="w-full max-w-lg flex items-center justify-center">
      {loading ? (
        <Loading />
      ) : error ? (
        <ErrorMessage optcClass="justify-center" text={`Error fetching data!`}>
          <button
            onClick={handleFetchSecretKey}
            className="-mb-2 underline decoration-warning/60 hover:text-indigo-400 hover:decoration-indigo-500 underline-offset-2 transition-colors"
          >
            please try again.
          </button>
        </ErrorMessage>
      ) : (
        stripePromise &&
        clientSecret && (
          <Elements
            stripe={stripePromise}
            options={{ clientSecret, appearance }}
          >
            <CheckoutForm />
          </Elements>
        )
      )}
    </div>
  );
}
