import React from "react";

export function PlanBalanceBtn({ balance, id, setBalance, isInactive }) {
  function handleCheck() {
    if (!isInactive) {
      setBalance(id);
    }
  }

  return (
    <label
      onClick={handleCheck}
      htmlFor={id.name}
      data-tip={
        isInactive &&
        "Access to this balance requires admin permission. Please submit a ticket to request access."
      }
      className={`w-full flex items-center  rounded-xl p-2 cursor-pointer   transition-all ${
        isInactive
          ? "tooltip bg-gray-600 hover:cursor-not-allowed light:bg-gray-300"
          : "indigo-gradient shadow-[0_0_8px_-5px] hover:!shadow-[--indigo-purple-hover] light:shadow-neutral-400 "
      } `}
    >
      <div className="relative flex items-center">
        <input
          disabled={isInactive}
          type="radio"
          name="balance"
          id={id.name}
          value={id.id}
          checked={balance.id === id.id}
          className="peer appearance-none bg-white light:bg-neutral-300 p-3 rounded checked:bg-neutral-200 light:checked:bg-[--indigo-purple-hover] disabled:bg-gray-500 light:disabled:bg-gray-400/20"
        />
        <svg
          className="stroke-[var(--indigo-purple-dark)] absolute w-4 h-4 mt-1 top-0 left-1 hidden peer-checked:inline-block"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke-width="5"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <polyline points="20 6 9 17 4 12"></polyline>
        </svg>
      </div>
      <span className="grow text-xl pt-1  -translate-x-2 text-white font-semibold text-center">
        ${Intl.NumberFormat().format(id.amount)}
      </span>
    </label>
  );
}
