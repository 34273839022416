import React from 'react';
import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import Loading from './components/shared/Loading';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import useInitializer from "./hooks/useInitializer";

export default function App() {

  const { loading } = useInitializer();

  return (
    <>
    <BrowserRouter>
    <TawkMessengerReact
                propertyId="66260eb31ec1082f04e56175"
                widgetId="1hs2ait4b"/>
    {loading ? (
      <div className='bg-primaryPurple w-full h-screen flex items-center justify-center'>
        <Loading optClass={" block mx-auto mt-20 text-white"} />
      </div>
      )
    :
    (

        <Router />
    )
    }
    </BrowserRouter>
    </>
  );
}
