import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { checkRequirement } from "../pages/Auth/shared/InputRequirment";
import { useNavigate } from "react-router-dom";
import { usePost } from "./API/usePost";
export function useResetPassword() {
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [error, setError] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const { email } = useParams();
  const { loading, handlePostData } = usePost(false);

  const [verificationCode, setVerificationCode] = useState({
    value: "",
    error: false,
  });
  const navigate = useNavigate();

  async function verifyOtp(otpData) {
    const res = await handlePostData(
      "users/verification/forgot-password/verify-otp",
      otpData
    );
    if (!res) return;
    toast.success(res.message);
    navigate("/login");
  }

  function handleSubmit() {
    const requirements = checkRequirement(newPass);
    if (!requirements.every((x) => x === true)) return;
    if (newPass !== confirmPass) {
      setError(true);
      return;
    }
    const verifyData = {
      otp: verificationCode.value,
      password: newPass,
      email: email,
    };

    verifyOtp(verifyData);
  }
  function handleSetVerification(values) {
    setVerificationCode((prev) => ({ ...prev, ...values }));
  }
  function handleSetConfirmPass(val) {
    setConfirmPass(val);
  }
  function handleSetNewPass(val) {
    setNewPass((prev) => val);
  }
  function handleSetShowPass() {
    setShowPass((prev) => !prev);
  }

  return {
    error,
    loading,
    showPass,
    handleSetShowPass,
    verificationCode,
    email,
    newPass,
    confirmPass,
    handleSubmit,
    handleSetVerification,
    handleSetNewPass,
    handleSetConfirmPass,
  };
}
