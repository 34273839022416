import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import { CertificateLayout } from "./CertificateLayout";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const url = process.env.REACT_APP_API_BASE_URL + "/";

export function CertificateSwipe({ data }) {
  return (
    <>
      <div className="flex items-center gap-2 my-3 ">
        <span className="badge badge-xs bg-white border-transparent mb-1.5"></span>
        <p className="text-white text-2xl font-bold">
          Certificate of Achievements
        </p>
      </div>
      {data[0] ? (
        <Swiper
          slidesPerView={1}
          breakpoints={{
            500: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 3,
            },
          }}
          spaceBetween={30}
          pagination={{
            clickable: true,
            el: ".swiper-custom-pagination",
          }}
          navigation={true}
          allowTouchMove={false}
          modules={[Pagination, Navigation]}
          className="mySwiper [&_.swiper-button-prev]:text-white [&_.swiper-button-next]:text-white"
        >
          {data.map((item, i) => (
            <SwiperSlide key={i}>
              <CertificateLayout
                imgUrl={url + item.src}
                text={"Achievement Certificate"}
                type={0}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <p className="text-neutral-300">
          You don't have a certificate!{" "}
          <Link className="text-indigo-500" to="/dashboard/new-challenge">
            try to get one!
          </Link>
        </p>
      )}
      <div className="swiper-custom-pagination w-full h-auto flex items-center justify-center gap-1.5 mt-5 [&_.swiper-pagination-bullet]:bg-white " />
    </>
  );
}
