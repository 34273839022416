import { notifyActions } from '../../store/slices/notify';
import LoadingPage from './LoadingPage'
import Toast from './Toast'

import {useSelector, useDispatch} from 'react-redux';

const Notify = () => {

    const dispatch = useDispatch();
    const notify = useSelector((state) => state.notify);

    return(
        <>
            {notify.loading && <LoadingPage />}
            {notify.error && 
                <Toast 
                    msg={{ msg: notify.msg, title: "error" }}
                    handleShow= {() => dispatch(notifyActions.hide())}
                    bgColor="bg-danger"
                />
            }
            {notify.success && 
                <Toast
                    msg={{ msg: notify.msg, title: "Success" }}
                    handleShow= {() => dispatch(notifyActions.hide())}
                    bgColor="bg-success"
                />
            }
        </>
    );
}
export default Notify