import React from "react";
import { ProfileInputs } from "./ProfileInputs";
import { ProfilePhoto } from "./ProfilePhoto";
import StatusLogo from "../../components/PageStatus/StatusLogo";
import CurrentPage from "../../components/PageStatus/CurrentPage";
export default function Profile() {
  return (
    <>
      <StatusLogo category={"Account"} disableStar={true} />
      <CurrentPage
        category={"Profile"}
        currentPage={"Account"}
        disableStar={true}
      />
      <div className="relative w-full flex flex-col items-center justify-center mt-10 mx-auto z-10">
        <img
          src="../whiteStar.svg"
          className="absolute top-[5%] right-[10%] w-14 h-14"
          alt=""
        />
        <img
          src="../yellowStar.svg"
          className="w-9 h-9 absolute top-[20%] left-[10%]"
          alt=""
        />
        <ProfilePhoto />
        <ProfileInputs />
        <img
          src="../profileBgWave.png"
          className="w-full max-h-[400px] absolute -bottom-32 -left-5 z-[-1]"
          alt=""
        />
      </div>
    </>
  );
}
