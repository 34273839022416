import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../store/slices/auth";
import Cookies from 'js-cookie';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const useInitializer = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const updateUserData = async (authToken) => {
    try {
      let token = "Bearer " + authToken;
      const response = await fetch(`${baseUrl}/api/auth/user?timestamp=${Date.now()}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (!response.ok) {
          Cookies.remove('authToken');
          await dispatch(authActions.logout());
      }

      const jsonData = await response.json();
      if (jsonData?.data?.user) {
        await dispatch(
          authActions.loginSuccess({
            token: authToken,
            user: jsonData.data.user,
            role: jsonData.data.role,
            permission: jsonData.data.permissions,
          })
        );

      } else {
          Cookies.remove('authToken');
          await dispatch(authActions.logout());
      }
    } catch (error) {
        Cookies.remove('authToken');
        await dispatch(authActions.logout());
    }
    setLoading(false);
  };

  useEffect(() => {
    const authToken = Cookies.get('authToken');
    if (authToken) {
      updateUserData(authToken);
    } else {
      setLoading(false);
    }
  }, [dispatch]);

  return { loading };
};

export default useInitializer;
