import React from "react";

const nationImg = {
  UAE: "../flags/UAE.png",
  IR: "../flags/IR.png",
  TR: "../flags/TR.png",
  CH: "../flags/CH.png",
  GE: "../flags/GE.png",
  KR: "../flags/KR.png",
  EN: "../flags/EN.png",
  BE: "../flags/BE.png",
  IT: "../flags/IT.png",
  CA: "../flags/CA.png",
};

export function RankingTableItem({ data, type }) {
  const { rank, name, nation } = data;
  console.log(nation);
  return (
    <div className="w-full h-[45px] flex items-center overflow-hidden mb-2 [&_*]:text-[#eee]">
      <div className="h-full flex items-center justify-center bg-[var(--indigo-purple-dark)] px-3 gap-1">
        {/* rank */}
        <span className="font-bol opacity-90  pt-1.5">{rank}</span>
        <div className="relative">
          <div className="absolute top-0 right-0 left-0 bottom-0  mix-blend-plus-lighter"></div>
          {/* <img src="../topTraderCup.png" alt="" /> */}
        </div>
      </div>
      <div className="h-full flex items-center justify-between flex-grow bg-[var(--indigo-purple-dark)] px-2">
        <div className="flex flex-col items-start justify-center gap-0.5">
          {/* name */}
          <p className="text-md 2xl:text-lg text-rankTableRowText  leading-none">
            {name.substring(0, 8)}
            {name.length > 8 && ".."}
          </p>
          {/* location */}
          {/* <span className="text-xs  text-rankTableRowText opacity-90 font-bold leading-none ">
            from {location}
          </span> */}
        </div>
        <div className="flex items-center gap-2 text-rankTableRowText ">
          {/* gr factor */}
          {type === "top" && (
            <span className="-mb-2">GF factor: {data.gf_factor}</span>
          )}
          {type === "withdraw" && (
            <span className="-mb-1">${data.withdraw}</span>
          )}
          {type === "golden" && (
            <div className="flex flex-col items-start justify-center">
              <span className="text-sm text-[#43288B]">
                GF factor: {data.gf_factor}
              </span>{" "}
              <span className="text-xs text-[#2A6F00]">
                Participation: {data.participation}
              </span>
            </div>
          )}
          {/* image */}
          <img
            src={nationImg[nation ? nation : "UAE"]}
            className="w-full h-auto max-w-[30px] rounded-full shadow-sm"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
