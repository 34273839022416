import TableBackground from "../../../../components/AdminShared/TableBackground";
import PageStatus from "../../../../components/PageStatus/PageStatus";
import CreateTitle from "./CreateTitle";
import { useNavigate } from "react-router-dom";

export default function BannerCreate() {
  const navigate = useNavigate();

  return (
    <>
      <PageStatus
        category={"Banner"}
        currentPage={"Create"}
        logoStarColor="#F8CC15"
      />
      <TableBackground>
        <button onClick={() => navigate(-1)} className="btn btn-success">
          Back
        </button>
        <CreateTitle />
      </TableBackground>
      ;
    </>
  );
}
