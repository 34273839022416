import React from "react";

export function PlanBalanceBtn({ balance, id, setBalance }) {
  return (
    <label
      onClick={() => setBalance(id)}
      htmlFor={id.name}
      className="w-full flex items-center indigo-gradient rounded-xl p-2 cursor-pointer hover:shadow-[0_0_10px_0] hover:shadow-[#3830a3cc] transition-all"
    >
      <div className="relative flex items-center">
        <input
          type="radio"
          name="balance"
          id={id.name}
          value={id.id}
          checked={balance.id === id.id}
          className="peer appearance-none bg-white p-3 rounded checked:bg-neutral-200"
        />
        <svg
          className="stroke-[var(--indigo-purple-dark)] absolute w-4 h-4 mt-1 top-0 left-1 hidden peer-checked:inline-block"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke-width="5"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <polyline points="20 6 9 17 4 12"></polyline>
        </svg>
      
      </div>
      <span className="grow text-xl pt-1  -translate-x-2 text-white font-semibold text-center">
        ${Intl.NumberFormat().format(id.amount)}
      </span>
    </label>
  );
}
