import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export function UserStatus() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  return (
    <div
      onClick={() => {
        navigate("profile");
      }}
      className="w-full flex items-center justify-center flex-col hover:cursor-pointer mt-10 group"
    >
      <div className="relative w-[90px] h-[90px] flex items-center justify-center">
        <div
          className="w-[90px] h-[90px] group-hover:w-[280px]
      group-hover:h-[280px] absolute transition-all duration-[1000ms] !delay-0"
          style={{
            backgroundImage:
              "radial-gradient(circle, rgba(81,73,208,0.8) 0%, rgba(0,0,0,0) 70%)",
          }}
        ></div>
        {user.photo ? (
          <img
            src={process.env.REACT_APP_API_BASE_URL + "/storage/" + user.photo}
            className="object-cover w-full h-full z-50 rounded-full"
            alt=""
          />
        ) : (
          <img
            src="../UserProfilePhoto.png"
            className="object-cover w-2/3 z-50 rounded-full "
            alt=""
          />
        )}
        <img
          src="../profileBorder.png"
          className="w-full h-full absolute z-50 scale-125 left-0.5"
          alt=""
        />
      </div>

      <div>
        <p className="font-bold mt-3">
          {" "}
          {user.first_name + " " + user.last_name}{" "}
        </p>
        {/* <p className="text-xs">active now</p> */}
      </div>
      {/* <div className="w-3 h-3 rounded-full bg-green-500"></div> */}
      <hr className="w-full mt-8 " />
    </div>
  );
}
