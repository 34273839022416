import React, { useState } from "react";
import { FiUploadCloud } from "react-icons/fi";
import { IoCloudDoneOutline } from "react-icons/io5";
import Loading from "../../components/shared/Loading";

const allowedTypes = ["image/jpeg", "image/png", "image/gif"];

export function IdentityUpload({ data, loading, handleUploadIdentity }) {
  return (
    <>
      {loading ? (
        <div className="w-full text-center my-20">
          <Loading />
        </div>
      ) : (
        <>
          <p className="mt-2 font-light">
            Take a photo from your passport the photo should be:
          </p>
          <ul className="list-disc list-inside ml-3">
            <li>Bright and clear (good quality)</li>
            <li>Uncut (all corners of the document should be visible)</li>
          </ul>
          <div className="grid grid-cols-3 items-center justify-items-end my-2">
            <img src="../i_passport_correct.png" alt="" />
            <img
              src="../i_passport_incorrect_1.png"
              className="w-10/12"
              alt=""
            />
            <img
              src="../i_passport_incorrect_2.png"
              className="w-10/12 "
              alt=""
            />
          </div>
          <DragDropFile
            data={data}
            handleUploadIdentity={handleUploadIdentity}
          />
        </>
      )}
    </>
  );
}

function DragDropFile({ data, handleUploadIdentity }) {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(false);

  function handleSubmit() {
    const checkType = Object.entries(files).every((item) =>
      allowedTypes.includes(item[1]?.type)
    );

    if (checkType) {
      handleUploadIdentity(files[0]);
    } else {
      setError(true);
    }
  }

  function handleClear() {
    setFiles([]);
  }
  function handleDrag(e) {
    e.preventDefault();
  }
  function handleDrop(e) {
    e.preventDefault();
    setFiles((x) => e.dataTransfer.files);
    setError(false);
  }

  return (
    <div
      className="flex flex-col items-center justify-center border-2 border-dashed border-white text-start p-3 relative"
      onDrop={handleDrop}
      onDragOver={handleDrag}
    >
      <input
        type="file"
        id="uploadDocs"
        accept="image/*"
        hidden
        onChange={(e) => {
          setFiles(e.target.files);
          setError(false);
        }}
      />

      <label
        htmlFor="uploadDocs"
        className="w-full h-full bg-transparent absolute hover:cursor-pointer"
      ></label>

      {files.length === 0 ? (
        <>
          <FiUploadCloud
            size={50}
            stroke="white"
            className="mx-auto pointer-events-none"
          />
          <div className="text-white">Upload your documents</div>
        </>
      ) : (
        <>
          <IoCloudDoneOutline size={50} stroke="white" />
          <img
            className="max-w-[100px] mt-4 mb-2"
            src={URL.createObjectURL(files[0])}
            alt=""
          />
          <span> {files[0].name} </span>

          <div className="grid grid-cols-2 items-center gap-3 z-10 mt-3">
            <button
              onClick={handleSubmit}
              className="btn bg-[var(--indigo-purple)] hover:bg-[var(--indigo-purple-hover)] border-none text-white btn-sm"
            >
              Confirm
            </button>
            <button
              onClick={handleClear}
              className="btn btn-error btn-outline btn-sm"
            >
              Cancel
            </button>
          </div>
          <p
            className={`text-sm text-error font-semibold text-center mt-2 ${
              error ? "block" : "hidden"
            } `}
          >
            Invalid file type
          </p>
        </>
      )}
    </div>
  );
}
