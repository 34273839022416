import React from "react";

export function Input({
  title,
  type = "text",
  optClass = "",
  loading = false,
  disabled,
  ...props
}) {
  return (
    <label className={`form-control w-full`}>
      <div className="label">
        <span className="text-gray-400 light:text-gray-600">{title}</span>
      </div>
      <input
        disabled={disabled || loading}
        type={type}
        className={`w-full bg-gray-900/30 text-white placeholder:text-white p-3 rounded-lg outline-0 disabled:text-gray-500 disabled:cursor-not-allowed light:border-gray-300 light:bg-gray-300/50 light:disabled:text-gray-500 ${optClass}`}
        {...props}
      />
    </label>
  );
}
