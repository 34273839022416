import { Player } from "@lottiefiles/react-lottie-player";

export default function TableLayout({
  title = "",
  headers,
  children,
  optClass = "",
}) {
  return (
    <div className={`dashboard-box col-span-2 ${optClass}`}>
      <p className="text-second text-2xl text-center p-3">{title}</p>

      <div
        className={`w-full max-h-[375px] overflow-auto scrollWidth  cursor-pointer rounded-xl relative z-20 `}
      >
        <table className="w-full  rounded-xl overflow-auto ">
          <thead
            className={`text-left text-gray-500 [&_th]:px-4  [&_th]:py-5 capitalize`}
          >
            <tr className="sticky bg-[--box-items-background] top-0 z-30 shadow-[0_1px_0_0px] shadow-gray-500/40 ">
              {/* <th>ID</th> */}
              {headers.map((th) => (
                <th>{th}</th>
              ))}
            </tr>
          </thead>
          <tbody
            className={`[&_td]:p-4 text-white [&_tr:not(:last-child)]:border-b [&_tr]:border-[--chart-grid] [&_tr:nth-child(even)]:- light:[&_tr:nth-child(even)]:- relative`}
          >
            {children}
          </tbody>
        </table>
        {!children && (
          <div className="w-full h-[335px] ">
            <Player
              src="https://lottie.host/0a086584-47fd-4247-ad9e-c3e39d6ec20d/yB2qgX25mt.json"
              className="player h-[300px]"
              autoplay
              loop
            />
            <p className="-mt-8 text-second text-center font-semibold text-lg">
              Data Not Found!
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
