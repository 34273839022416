import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFetch } from "../../../hooks/API/useFetch";

export function ResetPasswordCodeVerify({
  verificationCode,
  setVerificationCode,
  email,
}) {
  const [countdown, setCountdown] = useState(300);
  const { fetchData } = useFetch();

  async function sendCode() {
    const res = await fetchData(
      `users/verification/forgot-password/send-otp?email=${email}`
    );
    if (!res) return;
    toast.success(res.message);
  }

  useEffect(() => {
    sendCode();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countdown > 0) {
        setCountdown((x) => x - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countdown]);

  function handleVerifyCode(e) {
    // clear or add
    if (verificationCode.value.length < e.target.value.length) {
      if (verificationCode.value.length < 6) {
        setVerificationCode({
          error: false,
          value: e.target.value,
        });
      }
    } else {
      setVerificationCode({ error: false, value: e.target.value });
    }
  }

  function handleResendCode() {
    // resend code
    sendCode();
    setCountdown(300);
  }
  return (
    <>
      <label className="form-control w-full mb-4">
        <div className="label py-0">
          <label
            htmlFor="resendCode"
            className="label  text-white font-semibold capitalize"
          >
            email verification
          </label>

          <span className="label-text-alt">
            <div
              className="tooltip before:bg-indigo-700 before:text-indigo-100 capitalize before:max-w-[110px]"
              data-tip="enter email verification code"
            >
              <span
                className={`aspect-square w-5  border-transparent flex items-center justify-center pt-1 rounded-full bg-white text-[#4743E0] font-extrabold`}
              >
                &#63;
              </span>
            </div>
          </span>
        </div>
        <div
          className={`flex items-center justify-between gap-x-2 w-full bg-white rounded-[18px] border-y-4 border-l-0 border-r-4  border-[#4743E0] focus:border-[#4743E0]
          `}
        >
          <input
            type="number"
            required={true}
            onChange={handleVerifyCode}
            value={verificationCode.value}
            className="input w-2/3 !border-0 !outline-0 bg-transparent"
            id="resendCode"
          />
          <button
            onClick={handleResendCode}
            className={`w-1/3 btn outline-none hover:bg-indigo-700 hover:border-indigo-700 bg-[#4743E0] border-[#4743E0] text-wrap !text-white capitalize rounded-[12px] ${
              countdown > 0 ? "btn-disabled" : "btn-active cursor-pointer"
            }`}
          >
            {countdown > 0 ? (
              <span className="countdown text-xl mt-1">{countdown}</span>
            ) : (
              <span>resend code</span>
            )}
          </button>
        </div>
      </label>
      <p
        className={`text-sm text-rose-600  font-semibold mt-2 ${
          verificationCode.error ? "block" : "hidden"
        } `}
      >
        incorrect code
      </p>
    </>
  );
}
