export default class TradeStatistics {
  constructor(trades, archives, total) {
    this.trades = trades;
    this.archives = archives;
    this.total = total;
  }

  getTradeCount() {
    return this.trades.length;
  }

  getFailedTradesCount() {
    return this.trades.filter((trade) => trade.success === "lost").length;
  }

  getSuccessTradesCount() {
    return this.trades.filter((trade) => trade.success === "won").length;
  }

  getTotalProfit() {
    return this.trades.reduce((sum, trade) => {
      const profit = parseFloat(trade.profit);
      return sum + profit;
    }, 0);
  }

  getTotalLot() {
    const totalLot = this.trades.reduce((sum, trade) => {
      const volume = parseFloat(trade.volume);
      return sum + volume;
    }, 0);

    return parseFloat(totalLot.toFixed(2));
  }

  getBestProfit() {
    return this.trades.reduce((maxProfitTrade, trade) => {
      const profit = parseFloat(trade.profit);
      if (profit > parseFloat(maxProfitTrade.profit)) {
        return trade;
      }
      return maxProfitTrade;
    }, this.trades[0]);
  }

  getTradesAfterBest() {
    const bestProfit = this.getBestProfit();
    return this.trades.filter(
      (trade) => new Date(trade.created_at) > new Date(bestProfit.created_at)
    );
  }

  getWorstProfitAfterBest() {
    const tradesAfterBest = this.getTradesAfterBest();
    return tradesAfterBest.reduce((worstProfitTrade, trade) => {
      const profit = parseFloat(trade.profit);
      if (profit < parseFloat(worstProfitTrade.profit)) {
        return trade;
      }
      return worstProfitTrade;
    }, tradesAfterBest[0]);
  }

  getWorstProfit() {
    return this.trades.reduce((worstProfitTrade, trade) => {
      const profit = parseFloat(trade.profit);
      if (profit < parseFloat(worstProfitTrade.profit)) {
        return trade;
      }
      return worstProfitTrade;
    }, this.trades[0]);
  }

  getBestBalance() {
    return this.archives.reduce((maxArchiveBalance, archive) => {
      const balance = parseFloat(archive.balance);
      if (balance > parseFloat(maxArchiveBalance.balance)) {
        return archive;
      }
      return maxArchiveBalance;
    }, this.archives[0]);
  }

  getWorstBalance() {
    return this.archives.reduce((minArchiveBalance, archive) => {
      const balance = parseFloat(archive.balance);
      if (balance < parseFloat(minArchiveBalance.balance)) {
        return archive;
      }
      return minArchiveBalance;
    }, this.archives[0]);
  }

  getLastAnalysis() {
    return this.archives[this.archives.length - 1];
  }

  getFirstBalance() {
    return this.archives[0].balance;
  }

  getSumPositive() {
    return this.trades.reduce((sum, trade) => {
      const profit = parseFloat(trade.profit);
      if (profit > 0) {
        return sum + profit;
      }
      return sum;
    }, 0);
  }

  getSumNegative() {
    let sumNegative = this.trades.reduce((sum, trade) => {
      const profit = parseFloat(trade.profit);
      if (profit < 0) {
        return sum + profit;
      }
      return sum;
    }, 0);

    sumNegative = sumNegative * -1;
    if (sumNegative < 1) {
      sumNegative = 1;
    }
    return sumNegative;
  }

  getProfitFactor() {
    const sumPositive = this.getSumPositive();
    const sumNegative = this.getSumNegative();
    return sumPositive / sumNegative;
  }

  getGoldenFactor() {
    const balance = this.getFirstBalance();
    const totalProfit = this.total?.equity - balance;
    let maxDrawDown = this.getLastAnalysis().max_draw_down * 100;
    const tradeCount = this.getTradeCount();

    if(maxDrawDown < 1) maxDrawDown = 1;
    if (totalProfit <= 0) return 0;
    if (maxDrawDown && balance) {
      return (((totalProfit / balance) * 100) / maxDrawDown) * (tradeCount/30);
    }
    return 0;
  }

  getAllStatistics() {
    return {
      tradeCount: this.getTradeCount(),
      failedTrades: this.getFailedTradesCount(),
      successTrades: this.getSuccessTradesCount(),
      totalProfit: this.getTotalProfit(),
      totalLot: this.getTotalLot(),
      bestProfit: this.getBestProfit(),
      worstProfit: this.getWorstProfit(),
      bestBalance: this.getBestBalance(),
      worstBalance: this.getWorstBalance(),
      sumPositive: this.getSumPositive(),
      sumNegative: this.getSumNegative(),
      profitFactor: this.getProfitFactor(),
      goldenFactor: this.getGoldenFactor(),
    };
  }
}
