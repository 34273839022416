import React, { useState } from "react";


const identityType = [
  {
    id: 1,
    name: "Passport",
  },
  {
    id: 2,
    name: "Driving License",
  },
  {
    id: 3,
    name: "ID card",
  },
  {
    id: 4,
    name: "Residence",
  },
];

export function IdentityDocType({ handleSetData }) {
  const [error, setError] = useState(false);

  function handleDocType(e) {
    handleSetData("docType", e.target.value);
    setError(false);
  }
  function handleErr(e) {
    e.preventDefault();
    setError(true);
  }
  return (
    <>
      <p className="mt-2">choose your document type</p>
      <div className="flex flex-wrap items-center gap-5 font-light [&>div]:flex [&>div]:items-center [&>div]:gap-1 [&_label]:pt-1 [&_input]:border-black ">
        {identityType.map((doc) => (
          <DocTypeInput
            key={doc.id}
            handleDocType={handleDocType}
            doc={doc.id}
            name={doc.name}
            handleErr={handleErr}
          />
        ))}
      </div>
      <span className={`text-sm text-error  ${error ? "block" : "hidden"}`}>
        Please select one of these options
      </span>
    </>
  );
}

function DocTypeInput({ handleDocType, doc, handleErr, name }) {
  return (
    <div>
      <input
        onChange={handleDocType}
        required={true}
        type="radio"
        id={name}
        name="docType"
        value={doc}
        className="radio radio-xs !border-white"
        onInvalid={handleErr}
      />
      <label className="cursor-pointer" htmlFor={name}>
        {name}
      </label>
    </div>
  );
}
