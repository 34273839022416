import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { authActions } from '../store/slices/auth';
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const useInitializer = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const updateUserData = async(userToken) => {
      try {
      let token = "Bearer " + userToken;
      const response = await fetch(`${baseUrl}/api/auth/user`, {
          method: "GET",
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: token,
          },
      });

      if (!response.ok) await dispatch(authActions.logout())

      const jsonData = await response.json();
      if (jsonData?.data?.user) {
          await dispatch(
              authActions.loginSuccess({
                  token: jsonData.data.token,
                  user: jsonData.data.user,
                  role: jsonData.data.role,
                  permission: jsonData.data.permissions,
              })
          );
          localStorage.setItem("userData", JSON.stringify(jsonData.data));
      }else{
          await dispatch(authActions.logout())
      }
      } catch (error)
      {
          await dispatch(authActions.logout())
      }
      setLoading(false);
    }

  useEffect(() => {
      const storedUserData = localStorage.getItem('userData');
      if (storedUserData) {
        const userDataObject = JSON.parse(storedUserData);
        updateUserData( userDataObject.token);
      }else{
          setLoading(false);
      }
  }, [dispatch]);

  return { loading };
};

export default useInitializer;