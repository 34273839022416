import React from "react";
import { Link } from "react-router-dom";
import PageStatus from "../../../../components/PageStatus/PageStatus";
import useAddChallenge from "../../../../hooks/Admin/User/useAddChallenge";
import TableBackground from "../../../../components/AdminShared/TableBackground";
import RequestDetailsForm from "../../../../components/AdminShared/RequestDetailsForm";
import RequestDetailsInput from "../../../../components/AdminShared/RequestsDetailsInput";
import RequestDetailsButton, {
  RequestDetailsButtonLayout,
} from "../../../../components/AdminShared/RequestsDetailsButton";
import Loading from "../../../../components/shared/Loading";
const AddChallenge = () => {
  const {
    loading,
    formData,
    handleChange,
    balance,
    setBalance,
    challenge,
    setChallenge,
    challenges,
    phases,
    balances,
    mt4Brokers,
    mt5Brokers,
    handleSubmit,
  } = useAddChallenge();

  return (
    <>
      <PageStatus
        category={"Users"}
        currentPage={"Add Challenge"}
        logoStarColor="#F8CC15"
      />

      <TableBackground>
        {loading ? (
          <div className="w-full text-center">
            <Loading />
          </div>
        ) : (
          <>
            <div className=" flex items-center justify-between text-center text-white mb-5">
              <h3 className="text-2xl text-center w-full ">Add Challenge</h3>
              <Link
                to="/dashboard/admin/users"
                className="btn btn-secondary btn-outline ml-auto"
              >
                Back
              </Link>
            </div>

            <RequestDetailsForm>
              <label className="form-control">
                <div className="label">
                  <span className={`label-text text-xl text-slate-100`}>
                    Challenge:
                  </span>
                </div>
                <select
                  name="challenge"
                  value={challenge}
                  onChange={(e) => setChallenge(e.target.value)}
                  className="select select-md text-lg text-black select-bordered bg-slate-300"
                >
                  <option disabled selected>
                    Pick challenge
                  </option>
                  {challenges.map((challengeItem) => (
                    <option value={challengeItem.id}>
                      {challengeItem.name}
                    </option>
                  ))}
                </select>
              </label>

              <label className="form-control">
                <div className="label">
                  <span className={`label-text text-xl text-slate-100`}>
                    Balance:
                  </span>
                </div>
                <select
                  name="balance"
                  value={balance}
                  onChange={(e) => setBalance(e.target.value)}
                  className="select select-md text-lg text-black select-bordered bg-slate-300"
                >
                  <option disabled selected value="">
                    Pick Balance
                  </option>
                  {balances &&
                    balances.map((balanceItem) => (
                      <option value={balanceItem.id}>
                        {balanceItem.amount}
                      </option>
                    ))}
                </select>
              </label>
              <label className="form-control">
                <div className="label">
                  <span className={`label-text text-xl text-slate-100`}>
                    Phase:
                  </span>
                </div>
                <select
                  name="balance_phase"
                  value={formData.balance_phase}
                  onChange={handleChange}
                  className="select select-md text-lg text-black select-bordered bg-slate-300"
                >
                  <option disabled selected value="">
                    Pick Phase
                  </option>
                  {phases &&
                    phases.map((phaseItem) => {
                      return (
                        <option value={phaseItem.id}>{phaseItem.name}</option>
                      );
                    })}
                </select>
              </label>
              <label className="form-control">
                <div className="label">
                  <span className={`label-text text-xl text-slate-100`}>
                    Platform:
                  </span>
                </div>
                <select
                  name="platform"
                  value={formData.platform}
                  onChange={handleChange}
                  className="select select-md text-lg text-black select-bordered bg-slate-300"
                >
                  <option disabled selected>
                    Pick Platform
                  </option>
                  <option value="MT4">MT4</option>
                  <option value="MT5">MT5</option>
                </select>
              </label>
              <label className="form-control">
                <div className="label">
                  <span className={`label-text text-xl text-slate-100`}>
                    Broker:
                  </span>
                </div>
                <select
                  name="broker"
                  value={formData.broker}
                  onChange={handleChange}
                  className="select select-md text-lg text-black select-bordered bg-slate-300"
                >
                  <option disabled selected>
                    Pick Broker
                  </option>
                  {formData.platform && formData.platform === "MT4"
                    ? mt4Brokers.map((broker, index) => (
                        <option key={index} value={broker}>
                          {broker}
                        </option>
                      ))
                    : mt5Brokers.map((broker, index) => (
                        <option key={index} value={broker}>
                          {broker}
                        </option>
                      ))}
                </select>
              </label>
              <RequestDetailsInput
                title="Scale Up"
                name="scale_up"
                titleClass="text-xl"
                optClass="!input-md !text-xl text-black"
                value={formData.scale_up}
                onChange={handleChange}
              />
              <RequestDetailsInput
                  title="Real Count"
                  name="real_count"
                  titleClass="text-xl"
                  optClass="!input-md !text-xl text-black"
                  value={formData.real_count}
                  onChange={handleChange}
              />
            </RequestDetailsForm>
            <RequestDetailsButtonLayout>
              <RequestDetailsButton
                title="submit"
                optClass="btn-success"
                onclick={handleSubmit}
              />
            </RequestDetailsButtonLayout>
          </>
        )}
      </TableBackground>
    </>
  );
};

export default AddChallenge;
