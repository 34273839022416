import React from "react";
import Table from "../../components/Table";
import { PendingRow } from "./PendingRow";

const inactiveHeader = [
  "state",
  "balance",
  "challenge",
  "phase",
  "start time",
  "end time",
];
export function PendingPlans({ data }) {
  return (
    <>
      <div className="w-full rounded-full bg-[var(--indigo-purple-hover)] text-white text-center text-xl font-semibold py-2 mt-10 capitalize">
        Pending Plans
      </div>

      <Table headers={inactiveHeader}>
        {data.map((row) => (
          <PendingRow data={row} />
        ))}
      </Table>
    </>
  );
}
