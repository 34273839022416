import React from "react";

export function VerificationLayout({ children }) {
  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      className="w-full min-h-[500px] max-w-2xl flex flex-col justify-items-center items-center bg-[var(--indigo-purple-dark)] border border-[var(--indigo-purple)] px-6 py-10 mx-auto rounded-3xl shadow"
    >
      <p className="text-4xl text-white font-bold !uppercase">verification</p>

      {children}
    </form>
  );
}
