import { createSlice } from "@reduxjs/toolkit";
// import { getData, postData } from "../../utils/fetchData";
import {  getToken } from "../../utils/getToken";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoggedIn: false,
  user: null,
  token: null,
  role: null,
  permission: null,
  error: null,
};

const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess(state, action) {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.role = action.payload.role;
      state.permission = action.payload.permission;
      state.error = null;
    },
    loginFailure(state, action) {
      state.isLoggedIn = false;
      state.user = null;
      state.token = null;
      state.role = null;
      state.error = action.payload.error;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.user = null;
      state.token = null;
      state.role = null;
      state.error = null;
    },

    setVerification(state, action) {
      state.user.verification_type = action.payload.type;
    },

    setRefferal(state, action) {
      state.user.referral_id = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
  },
});

export const authActions = authReducer.actions;
export default authReducer;

export function postRefferal($data) {
  return async (dispatch) => {
    let response;
    const userToken = getToken()
  try {
    let token = "Bearer " + userToken;
    response = await fetch(`${baseUrl}/api/referral`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify($data),
    });

    if (!response.ok) {
      if (response && response.status === 401) {
        await dispatch(authActions.logout())
      }
      console.log("Failed to fetch data");
    }

    const jsonData = await response.json();
    if (jsonData.referral_id) {
      await dispatch(authActions.setRefferal(jsonData.referral_id));
      return "true";
    }
  } catch (error) {
      return null;
    }
  };
}

export function logout($data) {
  return async (dispatch) => {
    let response;
    const userToken = getToken()
  try {
    let token = "Bearer " + userToken;
    response = await fetch(`${baseUrl}/api/auth/logout`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (!response.ok) {
      console.log("Failed to fetch data");
      if (response && response.status === 401) {
        await dispatch(authActions.logout())
      }
    }

    const jsonData = await response.json();
    if (jsonData?.data) {
      await dispatch(authActions.logout());
      return "true";
    }
  } catch (error) {
    if (response && response.status === 401) {
      await dispatch(authActions.logout())

    }

    return null;
  }
    
  };
}
