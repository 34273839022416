import ModalLayout from "../../../components/AdminShared/ModalLayout";
import Pagination from "../../../components/AdminShared/Pagination";
import Table from "../../../components/AdminShared/Table";
import TableBackground from "../../../components/AdminShared/TableBackground";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import Loading from "../../../components/shared/Loading";
import { useInactiveUsers } from "../../../hooks/Admin/useInactiveUsers";
import { usePagination } from "../../../hooks/usePagination";

export default function InactiveChallenges() {
  const { pagination, handleChangePagination } = usePagination();
  const {
    error,
    loading,
    activateLoading,
    deactivateLoading,
    inactiveUsers,
    activateReason,
    handleActivate,
    handleDeactivate,
    setActivateReason,
    handleUserSelected,
    userState,
  } = useInactiveUsers(pagination);

  return (
    <>
      <ModalLayout id="inactive-challenges">
        {userState.state === 1 ? (
          <>
            <label htmlFor="declineReason" className="form-control">
              <div className="text-white mb-2 text-center">
                Please enter a reason
              </div>
              <textarea
                onChange={(e) => setActivateReason(e.target.value)}
                value={activateReason}
                className="textarea bg-neutral-200 text-black"
                rows={3}
              />
            </label>

            <button
              disabled={activateLoading}
              onClick={handleActivate}
              className="btn btn-error btn-outline mt-3"
            >
              Activate
            </button>
          </>
        ) : (
          <>
            <div className="text-white my-5">
              <span className="text-success">Reason:</span> {userState.reason}
            </div>
            <button
              disabled={deactivateLoading}
              onClick={handleDeactivate}
              className="btn btn-outline btn-success"
            >
              Deactivate
            </button>
          </>
        )}
      </ModalLayout>
      <TableBackground>
        {loading ? (
          <div className="w-full flex items-center justify-center h-[600px]">
            <Loading />
          </div>
        ) : error ? (
          <ErrorMessage />
        ) : (
          <div className="content mx-5 py-5 text-center text-white ">
            <Table
              theads={[
                "Id",
                "Email",
                "Challenge",
                "Balance",
                "Server",
                "Phase",
                "Actions",
              ]}
            >
              {inactiveUsers?.inactive_user_challenges.data.map((challenge) => (
                <tr>
                  <td>{challenge.id}</td>
                  <td>
                    <p>{challenge.user.email}</p>
                  </td>
                  <td>
                    <p>{challenge.user_challenge.challenge}</p>
                  </td>
                  <td>
                    <p>{challenge.user_challenge.balance}</p>
                  </td>
                  <td>
                    <p>{challenge.user_challenge.server}</p>
                  </td>
                  <td>
                    <p>{challenge.user_challenge.phase_type_name}</p>
                  </td>

                  <td>
                    <button
                      className="link hover:text-[--indigo-purple] transition-colors"
                      onClick={() =>
                        handleUserSelected(
                          challenge.id,
                          challenge.state,
                          challenge.reason
                        )
                      }
                    >
                      {challenge.state === 1 ? "Activate" : "Deactivate"}
                    </button>
                  </td>
                </tr>
              ))}
            </Table>
          </div>
        )}

        {inactiveUsers?.inactive_user_challenges && (
          <Pagination
            handleOnClick={handleChangePagination}
            data={inactiveUsers.inactive_user_challenges}
          />
        )}
      </TableBackground>
    </>
  );
}
