import Loading from "../../../components/shared/Loading";
import { useAdminTickets } from "../../../hooks/Admin/Tickets/useAdminTickets";
import { usePagination } from "../../../hooks/usePagination";
import Filters from "./Filters";
import TableLayout from "../../../components/shared/DarkTable/TableLayout";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import Row from "./Row";
const initialFilter = {
  title: "",
  state: "",
  from_date: "",
  to_date: "",
};

const filterOptions = ["title", "state", "from_date", "to_date"];
export default function Unassigned() {
  const {
    pagination,
    handleFilterChange,
    query,
    handleSubmitFilter,
    handleChangePagination,
    filter,
  } = usePagination(initialFilter, filterOptions);
  const { loading, tickets, filterOptionData, assignToSelf } = useAdminTickets(
    1,
    pagination,
    query
  );

  return (
    <TableLayout
      paginationData={tickets}
      paginationClick={handleChangePagination}
      pagination={!loading}
    >
      <Filters
        filter={filter}
        handleFilterChange={handleFilterChange}
        handleSubmitFilter={handleSubmitFilter}
        filterOptionData={filterOptionData}
      />
      <div className="w-full flex flex-col gap-5 mt-10">
        {loading ? (
          <div className="flex items-center justify-center self-center h-[600px]">
            <Loading />
          </div>
        ) : tickets.data.length > 0 ? (
          tickets.data.map((ticket, i) => (
            <Row
              key={i}
              data={ticket}
              handleClick={assignToSelf}
              handleClickTitle="Assign To Self"
            />
          ))
        ) : (
          <div className="flex items-center justify-center self-center h-[600px]">
            <ErrorMessage />
          </div>
        )}
      </div>
    </TableLayout>
  );
}
