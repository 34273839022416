import { useEffect, useState } from "react";
import Loading from "../../components/shared/Loading";
import Chart from "react-apexcharts";
import { useFetch } from "../../hooks/API/useFetch";
export default function LineChart() {
  const [data, setData] = useState(null);
  const [dateArray, setDateArray] = useState(null);
  const { loading, error, fetchData } = useFetch();

  const fetchChartData = async () => {
    const res = await fetchData("finance/wallets/transitions/fund");
    if (!res) return;
    const transactions = res.transactions;
    const datesArray = transactions.map((transaction) => transaction.date);
    const balancesArray = transactions.map(
      (transaction) => transaction.balance
    );

    setDateArray(datesArray);
    setData(balancesArray);
    return;
  };

  useEffect(() => {
    if (error !== true && data === null) {
      fetchChartData();
    }
  }, []);

  const chartData = {
    options: {
      colors: ["#f8cc15"],
      xaxis: {
        type: "datetime",
        categories: dateArray,
        axisBorder: { show: false },
        axisTicks: { show: false },
        offsetY: 20,
        labels: {
          padding: 30,
          formatter: function (value, timestamp, opts) {
            return opts.dateFormatter(new Date(timestamp), "dd MMM HH:mm");
          },
        },
      },
      title: {
        text: "Monthly Invest ($)",
        margin: 20,
        style: {
          fontSize: "30px",
          fontWeight: "bold",
          fontFamily: "Khula",
          color: "#f8cc15",
        },
        offsetX: 20,
        offsetY: 10,
      },
      chart: {
        parentHeightOffset: 0,

        type: "line",
        stacked: false,
        background: "var(--box-items-background)",

        foreColor: "var(--primary-text)",
        toolbar: {
          show: false,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
          },
        },
      },
      stroke: {
        curve: "stepline",
      },
      grid: {
        show: true,
        position: "back",
        borderColor: "var(--chart-grid)",
        padding: {
          bottom: 40,
          right: 30,
          left: 40,
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        showForSingleSeries: true,
        horizontalAlign: "left",
        position: "top",
        style: {
          fontFamily: "Khula",
        },
        offsetY: -40,
        itemMargin: {
          horizontal: 20,
        },
      },
    },

    series: [
      {
        name: "Total invest",
        data: data,
      },
    ],
  };

  return (
    <div className="w-full min-[1100px]:col-span-2">
      {loading ? (
        <div className="h-[500px] bg-[--box-items-background] rounded-[19px] flex items-center justify-center">
          <Loading />
        </div>
      ) : (
        <div className="hidden md:block">
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="line"
            height={500}
          />
        </div>
      )}
    </div>
  );
}
