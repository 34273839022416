export default function Overview({ loading, data }) {
  return (
    <div className="dashboard-box border border-[--indigo-purple] grid grid-cols-1 min-[1100px]:grid-cols-4  max-[1100px]:gap-5">
      <div className="min-[1100px]:col-span-3 flex items-center justify-between min-[1100px]:border-r min-[1100px]:border-b-0 border-b border-gray-700 light:border-gray-300 pr-3 xl:px-8 max-[1100px]:pb-3 max-[800px]:flex-col">
        <div className="flex items-center gap-3">
          <div className="max-w-[50px] light:brightness-[0.2]  max-[900px]:hidden">
            <img src="/UserProfilePhoto.png" alt="" />
          </div>
          <div>
            {loading ? (
              <div className="w-40 h-6 skeleton bg-gray-700/60 light:bg-gray-300 rounded-md mb-2" />
            ) : (
              <div className="text-white text-2xl font-semibold">
                {data.user_name}
              </div>
            )}
            <div className="text-second font-semibold max-[900px]:hidden">
              Referral User
            </div>
          </div>
        </div>
        <div className="max-[800px]:text-sm xl:text-lg font-semibold flex items-center gap-2">
          <span className="text-second">Registered in</span>
          {loading ? (
            <div className="w-36 h-6 skeleton bg-gray-700/60 light:bg-gray-300 rounded-md mb-2" />
          ) : (
            <span className="text-white">{data.registered_at}</span>
          )}
        </div>
      </div>
      <div className="flex items-center justify-center">
        <div className="text-xl font-semibold text-center">
          <p className="text-second">Total Profit</p>
          {loading ? (
            <div className="w-32 h-9 bg-gray-700/60 light:bg-gray-300 skeleton rounded-md mt-2" />
          ) : (
            <p className="text-[--indigo-purple] text-4xl mt-1">
              {data.total_profit}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
