export default function RequestDetailsForm({ children, optClass }) {
  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      className={`w-full grid grid-cols-1 sm:grid-cols-3 gap-x-5 gap-y-8 ${optClass} `}
    >
      {children}
    </form>
  );
}
