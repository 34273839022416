import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useState } from "react";
import { getBanners, getStats } from "../../utils/requests/dashboardRequest";
import Loading from "../../components/shared/Loading";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

// const bannerImages = [
//   { imageUrl: "../dashboardBanner.png", imageAlt: "banner" },
//   { imageUrl: "../dashboardBanner.png", imageAlt: "banner" },
// ];
const url = process.env.REACT_APP_API_BASE_URL + "/storage/";
export default function DashboardBanner() {
  const [loading, setLoading] = useState(false);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    // dispatch(
    //   notifyActions.loading()
    // );
    const fetchBanners = async () => {
      setLoading((x) => true);
      const banners = await getBanners();

      if (banners) {
        setBanners(banners);
      }
    };

    fetchBanners();
    setLoading((x) => false);
  }, []);

  return (
    <>
      {loading ? (
        <Loading optClass={"block mx-auto mt-10 mb-14"} />
      ) : (
        <div className="mb-5 mt-16 md:mt-0">
          <Swiper
            modules={[Pagination]}
            pagination={{
              el: ".swiper-c-pagination",
            }}
            className="mySwiper mt-5 rounded-2xl "
          >
            {banners.map((image) => (
              <SwiperSlide>
                <img
                  src={url + image.photo}
                  alt={image.slug}
                  className="w-full h-full"
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-c-pagination flex items-center justify-center gap-2 mt-2 md:mt-5 [&_.swiper-pagination-bullet]:!bg-white [&_.swiper-pagination-bullet-active]:!bg-white [&_.swiper-pagination-bullet-active]:!scale-125 " />
        </div>
      )}
    </>
  );
}
