import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { logout } from "../../store/slices/auth";
import { useNavigate } from "react-router-dom";

export function ListLogOutItem({ icon, text, hover, handleSidebar }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleLogOut = async () => {
    setLoading((prev) => true);
    const dispatchData = await dispatch(logout());
    setLoading((prev) => false);
    if (!dispatchData) {
      toast.error("Error! Please Try Again Later.");
    } else {
      toast.success("logout Successfully");
      localStorage.removeItem("userData");
      navigate("/");
    }
  };
  return (
    <>
      <ToastContainer theme="dark" />
      {loading ? (
        <li className="w-full text-center py-1.5 pl-4 mb-4">
          <span className="loading loading-dots loading-sm"></span>
        </li>
      ) : (
        <li
          onClick={handleLogOut}
          className={`w-full flex items-center gap-2 hover:bg-indigo-600 py-1.5 pl-4 mb-4 hover:cursor-pointer transition-all `}
        >
          {icon}
          <span className="text-lg capitalize pt-1">{text} </span>
        </li>
      )}
    </>
  );
}
