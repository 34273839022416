function calculateAvailableTime(activeTime) {
  if (!activeTime) return false;
  const currentDate = new Date();
  const targetDate = new Date(activeTime.replace(" ", "T"));
  const diffMilliseconds = targetDate - currentDate;
  const diffDays = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor(
    (diffMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const diffMinutes = Math.floor(
    (diffMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
  );
  const diffSeconds = Math.floor((diffMilliseconds % (1000 * 60)) / 1000);

  const availableTime = {
    day: diffDays,
    hour: diffHours,
    min: diffMinutes,
    seconds: diffSeconds,
  };

  return availableTime;
}

export default calculateAvailableTime;
