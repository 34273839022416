import React from "react";
// import { TicketFilters } from "./TicketFilters";
import { NewTicket } from "./NewTicket";
import { TicketRowsLayout } from "./TicketRowsLayout";
import { StatesContextProvider } from "./StatesContextProvider";
import Loading from "../../components/shared/Loading";
import { useTicket } from "../../hooks/useTicket";
import Pagination from "../../components/AdminShared/Pagination";
import RequestDetailsInput from "../../components/AdminShared/RequestsDetailsInput";
import RequestDetailsButton, {
  RequestDetailsButtonLayout,
} from "../../components/AdminShared/RequestsDetailsButton";
import { usePagination } from "../../hooks/usePagination";

const initialFilter = {
  title: "",
  state: "",
  from: "",
  to: "",
};

const filterOptions = ["title", "state", "from", "to"]

export default function Tickets() {
  const { pagination, handleFilterChange, query, handleSubmitFilter, handleChangePagination, filter } = usePagination(initialFilter, filterOptions)
  const { loading, error, tickets, newTicketSuccess} =
    useTicket(pagination, query);

  return (
    <StatesContextProvider>
      <NewTicket newTicketSuccess={newTicketSuccess} />
      {/* <TicketFilters /> */}
      {error ? (
        "error fetching data"
      ) : loading ? (
        <div className="min-h-[700px] flex items-center justify-center mt-10">
          <Loading optClass="text-white" />
        </div>
      ) : (
        <>
          <form onSubmit={handleSubmitFilter} className="mb-10 text-end">
            <div className="w-full grid grid-cols-2 lg:grid-cols-4 gap-x-5">
              <RequestDetailsInput
                title="Title"
                value={filter.title}
                name="title"
                onChange={handleFilterChange}
                titleClass="font-semibold"
                placeholder="Search Title"
              />
              <label htmlFor="state" className="form-control">
                <div className="label">
                  <span className={`label-text text-slate-100`}>State:</span>
                </div>
                <select
                  id="state"
                  name="state"
                  value={filter.state}
                  onChange={handleFilterChange}
                  className="select select-sm  text-black select-bordered bg-slate-300"
                >
                  <option value="">All</option>

                  <option value={tickets.state[1]}>{tickets.state[1]}</option>
                  <option value={tickets.state[2]}>{tickets.state[2]}</option>
                  <option value={tickets.state[3]}>{tickets.state[3]}</option>
                </select>
              </label>
              <RequestDetailsInput
                title="Date From"
                type="date"
                value={filter.from}
                name="from"
                onChange={handleFilterChange}
                titleClass="font-semibold"
              />
              <RequestDetailsInput
                title="Date To"
                type="date"
                value={filter.to}
                name="to"
                onChange={handleFilterChange}
                titleClass="font-semibold"
              />
            </div>
            <RequestDetailsButtonLayout optClass="mt-5 justify-end">
              <RequestDetailsButton
                onclick={() => handleFilterChange("reset")}
                optClass="btn-error"
                title="Reset"
              />

              <RequestDetailsButton
                onclick={handleSubmitFilter}
                optClass="btn-success "
                title="Filter"
                type="submit"
              />
            </RequestDetailsButtonLayout>
          </form>

          <TicketRowsLayout
            tickets={tickets.tickets.data}
            ticketType={0}
            admin={false}
          />
        </>
      )}

      {tickets?.tickets && (
        <Pagination
          handleOnClick={handleChangePagination}
          data={tickets.tickets}
          optClass="mt-5"
        />
      )}
    </StatesContextProvider>
  );
}
