import React from "react";
import { format } from "date-fns";

export function ChatAdminBubble({ item }) {
  function formatTextSpaces(value) {
    const formattedText = String(value).split("<?br>");
    return formattedText;
  }
  return (
    <div className="chat chat-start ">
      <div className="chat-bubble break-words bg-zinc-900 shadow-md text-white">
        {item.message.startsWith(process.env.REACT_APP_API_BASE_URL) ? (
          <a href={item.message} download>
            Download File
          </a>
        ) : (
          formatTextSpaces(item.message).map((text) => <p>{text}</p>)
        )}
      </div>
      <div className="chat-footer text-neutral-200 text-xs font-semibold mt-1 capitalize">
        {item.user?.first_name}
        <time className="opacity-50 ml-1">
          {format(new Date(item.created_at), "yyyy-MM-dd HH:mm")}
        </time>
      </div>
    </div>
  );
}
