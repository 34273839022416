import React from "react";
import { TiTick } from "react-icons/ti";
import { FaExclamation } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const VerificationStatus = () => {
  const user = useSelector((state) => state.auth.user);
  const isVerified = user.verification_type === 4;
  const navigate = useNavigate();
  return (
    <div className="dashboard-box h-full">
      <div className="flex flex-col max-xl:gap-5 items-center justify-between h-full">
        <div>
          <p className="text-center text-second font-semibold text-xl">
            Verification Status
          </p>
          {isVerified ? (
            <>
              <div className="flex items-start md:items-end border border-green-800 light:border-green-600 light:bg-green-600/30 bg-green-600/10 p-5 rounded-xl gap-3 mb-8 mt-3">
                <div className="bg-green-600/20 rounded-xl pb-1 px-0.5">
                  <TiTick className="fill-success" size={30} />
                </div>
                <p className="text-white pb-1 max-md:mt-1">
                  You’ve completed the verification process.
                </p>
              </div>
            </>
          ) : (
            <div className="flex flex-col items-center border border-red-800 light:border-red-600 bg-red-600/10 p-5 rounded-xl gap-3 mt-3">
              <div className="flex items-start xl:items-end gap-3">
                <div className="bg-red-600/20 rounded-xl px-1.5 pt-1 pb-2">
                  <FaExclamation className="fill-error opacity-70" size={20} />
                </div>
                <p className="text-white xl:text-center ">
                  Your profile is currently unverified.
                </p>
              </div>
              <button
                onClick={() => navigate("/verification")}
                className="btn btn-error btn-outline btn-sm"
              >
                Verify Now
              </button>
            </div>
          )}
        </div>
        <div className="w-full text-center">
          <Link
            to={"/tickets"}
            className="hover:text-[--indigo-purple] transition-colors group text-second"
          >
            Have A Problem?{" "}
            <span className="underline underline-offset-4 !decoration-second group-hover:no-underline">
              Ticket Now
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default VerificationStatus;
