import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getData } from "../utils/fetchData";
const mt4Brokers = ["Swissquote", "Uniglobe", "HYCM", "FXTM"];
const mt5Brokers = ["Uniglobe", "HYCM", "Swissquote", "OANDA"];

export function useNewChallenge(data) {
  const challenges = useSelector((state) => state.challenge);
  const [challenge, setChallenge] = useState(null);
  const [balance, setBalance] = useState(null);
  const [broker, setBroker] = useState(null);
  const [brokers, setBrokers] = useState(mt4Brokers);
  const [platform, setPlatform] = useState("MT4");
  const [wallet, setWallet] = useState(null);
  const [walletLoading, setWalletLoading] = useState(false);
  let balancePrice = 0;

  const getBalance = async () => {
    setWalletLoading((prev) => true);
    const response = await getData("finance/wallets/balance");
    setWalletLoading((prev) => false);
    if (response?.data) {
      setWallet(response.data.balance);
    }
  };

  useEffect(() => {
    if (wallet === null) {
      getBalance();
    }
  }, [wallet]);

  useEffect(() => {
    const selectedChallenge = challenges?.challenges.find(
      (item) => item.name === data.accountType
    );
    setChallenge(selectedChallenge);

    const nonInactiveBalance = selectedChallenge?.challenge_balances.find(
      (balance) => balance.status !== "inactive" && balance.state === 1
    );
    setBalance(nonInactiveBalance);
  }, [data.accountType]);

  const handlePlatformChange = async (data) => {
    setPlatform(data);
    if (data === "MT4") {
      await setBrokers(mt4Brokers);
      await setBroker(null);
    } else if (data === "MT5") {
      await setBrokers(mt5Brokers);
      await setBroker(null);
    }
    setBroker(null);
  };

  if (balance != null) {
    balancePrice = balance.balance_phases.filter(
      (item) => item.registration_fee !== 0 || item.pre_investment !== null
    )[0];
  }

  return {
    challenge,
    balance,
    platform,
    broker,
    wallet,
    balancePrice,
    setBalance,
    handlePlatformChange,
    setBroker,
    brokers,
    walletLoading,
  };
}
