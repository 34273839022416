import { useEffect, useState } from "react";
import useDidMount from "../../useDidMount";
import { useParams } from "react-router-dom";
import { useFetch } from "../../API/useFetch";

const useUserPayment = () => {
  const [payments, setPayments] = useState([]);
  const [filter, setFilter] = useState([]);
  const didMount = useDidMount(true);
  const [refresh, setRefresh] = useState(false);
  const {loading, error, fetchData} = useFetch()

  const { id } = useParams();

  function setFilterOption(val) {
    switch (val) {
      case "date":
        setFilter(() =>
          [...payments].sort((a, b) => {
            return (
              new Date(b.created_at.split("T")[0]) -
              new Date(a.created_at.split("T")[0])
            );
          })
        );
        break;
      case "success":
        setFilter(() =>
          payments.filter((payment) => payment.status === "success")
        );
        break;
      case "pending":
        setFilter(() =>
          payments.filter((payment) => payment.status === "pending")
        );
        break;
      case "failed":
        setFilter(() =>
          payments.filter((payment) => payment.status === "failed")
        );
        break;
      case "id":
        setFilter(() =>
          [...payments].sort((a, b) => {
            return a.id - b.id;
          })
        );
        break;
      case "amount":
        setFilter(() =>
          [...payments].sort((a, b) => {
            return b.amount - a.amount;
          })
        );
        break;
      default:
        setFilter(payments);
        break;
    }
  }

  const getPayments = async () => {   
    const res = await fetchData("finance/wallets/transactions/admin/" + id);
    if(!res) return;
    setRefresh(false);      
    setPayments(res.transactions);
    // setPaymentLinks(res.payments);
  };

  useEffect(() => {
    if ((payments.length === 0 && didMount) || (refresh && didMount)) {
      getPayments();
    }
  }, [didMount, payments.length, refresh]);

  useEffect(() => {
    setFilter(payments);
  }, [payments]);

  return {
    error,
    loading,
    payments,
    setRefresh,
    filter,
    setFilterOption,
  };
};

export default useUserPayment;
