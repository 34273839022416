import { IoArrowBack } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { useChat } from "../../../../hooks/Admin/Tickets/useChat";
import { ChatBubble } from "../../../Tickets/Chat/ChatBubble";
import { useSelector } from "react-redux";
import Loading from "../../../../components/shared/Loading";
import { ChatForm } from "../ChatForm";
import { useEffect, useRef } from "react";

export default function Chat() {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    loading,
    fileUploadLoading,
    closeTicketLoading,
    chat,
    pushMess,
    pushFile,
    newMessageLoading,
    handleClose,
  } = useChat(id);
  const user = useSelector((state) => state.auth.user);
  const messageEndRef = useRef(null);
  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chat]);
  return (
    <div className="dashboard-box">
      <div className="w-full flex items-center justify-between">
        <div className="text-white text-2xl">
          {loading ? (
            <div className="w-32 h-8 rounded-md bg-gray-900/10 skeleton" />
          ) : (
            chat.title
          )}
        </div>
        <button
          onClick={() => navigate(-1)}
          className="flex items-center bg-[--indigo-purple] rounded-md p-2 pb-2.5 text-white border border-transparent hover:border-light-border transition-colors"
        >
          <IoArrowBack size={20} />
          <span className="-mb-1.5"> Back</span>
        </button>
      </div>
      <div className="w-full  p-2 bg-gray-900/40 h-[500px] overflow-auto scrollWidth rounded-box mt-5">
        {loading ? (
          <div className="w-full h-[500px] flex items-center justify-center">
            <Loading />
          </div>
        ) : (
          <>
            {chat.messages.map((item) => (
              <ChatBubble data={item} isAdmin={user.id !== item.user_id} />
            ))}{" "}
            <div ref={messageEndRef} />
          </>
        )}
      </div>
      <div className="mt-5">
        <ChatForm
          addNewMessage={pushMess}
          addNewFile={pushFile}
          handleClose={handleClose}
          loading={newMessageLoading || fileUploadLoading || closeTicketLoading}
        />
      </div>
    </div>
  );
}
