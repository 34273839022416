import { CertificateSwipe } from "./CertificateSwipe";
import { CertificateGrid } from "./CertificateGrid";
import PageStatus from "../../components/PageStatus/PageStatus";
import Loading from "../../components/shared/Loading";
import useCertificate from "../../hooks/useCertificate";

export default function Certificate() {
  const { achievementCertificates, profitCertificates, loading, error } =
    useCertificate();

  return (
    <>
      <PageStatus
        category={"account"}
        currentPage={"certificates"}
        logoStarColor="#F8CC15"
      />
      {error ? (
        <p>error fetching data</p>
      ) : loading || achievementCertificates === null ? (
        <Loading optClass={"block mx-auto mt-20"} />
      ) : (
        <>
          <CertificateSwipe data={achievementCertificates} />
          <CertificateGrid data={profitCertificates} />
        </>
      )}
    </>
  );
}
