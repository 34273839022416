import { useState, useEffect } from "react";
import {useFetch} from "./API/useFetch"
export function useTicket(pagination,query, initialApi = "tickets") {
  const [tickets, setTickets] = useState(null);
  const [type, setType] = useState(null);
  const {loading, error, fetchData} = useFetch()

  function newTicketSuccess() {
    getTickets();
  }

  async function getTickets() {
    let api;
    if (type) {
      switch (type) {
        case "admin-unAssigned":
          api = "admin/tickets/answer-list";
          break;
        case "admin-assigned":
          api = "admin/tickets";
          break;
        default:
          api = "tickets";
          break;
      }
    } else {
      api = initialApi;
    }

    const res = await fetchData(`${api}?page=${pagination}${query}`);
    if (!res) return;
    setTickets(res);
    
  }

  useEffect(() => {
    getTickets();
  }, [pagination, query, type]);

  return {
    loading,
    tickets,
    error,
    newTicketSuccess,
    getTickets,
    setType,
  };
}
