import React from "react";

import DashboardBanner from "./DashboardBanner";
import CompactInfo from "./CompactInfo";
import PlanTable from "./PlanTable";
import BarChart from "./BarChart";
import DonutChart from "./DonutChart";
import RankingTables from "../TopTraders/RankingTables";
import Bonus from "./Bonus";
import LineChart from "./LineChart";
import usePermissions from "../../hooks/usePermission";



export default function Dashboard() {
  const { can }             = usePermissions();

  return (
    <>
      <Bonus />
      <DashboardBanner />
      <CompactInfo />
      <PlanTable />
      {
      can("show_top_trader") &&
      (
        <RankingTables />
      )
      }
      {
      can("show_transfer") &&
      (
          <LineChart />
      )
      }
      <BarChart />
      {
      can("show_challenge") &&
      (
          <DonutChart />
      )
      }
    </>
  );
}
