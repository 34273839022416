import React from 'react'
import Numbers from './CircleAnimation/Numbers'
import Layout from './CircleAnimation/Layout'
import Inner from './CircleAnimation/Inner'
import Middle from './CircleAnimation/Middle'
import Lines from './CircleAnimation/Lines'

const CircleAnime = ({userRole}) => {
  return (
    <div className={`anime-circle ${userRole === "trader" && "before:bg-[#2907ff8f]"}  ${userRole === "investor" && "before:bg-[#f8cb1550]"} before:transition-all !h-screen !top-0 lg:!relative`}>
      <div className={`container-circle ${userRole === "investor" && "sepia"} `} >
        <div style={{right:0}} className="circle-iner lg:left-[50%] lg:!top-[50%] lg:translate-x-[-50%] lg:translate-y-[-50%]">
          <div className="cicle-rel">
            <Numbers/>
            <Layout/>
            <Inner/>
            <Middle/>
            <Lines/>
          </div>

</div>
  </div>
  </div>

  )

}
export default CircleAnime