import { Navigate, useRoutes } from "react-router-dom";

/* 

  ------- User Dashboard -------

*/

// dashborad
import DashboardLayout from "../layouts/DashboardLayout";
import Dashboard from "../pages/Dashboard";

// profile
import Profile from "../pages/Profile";

// new challenge
import NewChallenge from "../pages/NewChallenge";

// my Challenge
import MyChallenge from "../pages/MyChallenge";
import AnalyzePanel from "../pages/MyChallenge/Analyze"; //analyze\

// deposits
import Deposit from "../pages/Deposit";
import DepositTransactionSuccess from "../pages/Deposit/DepositTransactionSuccess";
import DepositTransactionUnsuccess from "../pages/Deposit/DepositTransactionUnsuccess";

// withdraws
import Withdraw from "../pages/Withdraw";

// top traders
import TopTraders from "../pages/TopTraders";

// verification
import Verification from "../pages/Verification";

// referrals
import Referrals from "../pages/Referrals";

// certificates
import Certificate from "../pages/Certificate";

// wallets
import Wallet from "../pages/MyWallets";

// tickets
import Tickets from "../pages/Tickets";
import { ChatLayout } from "../pages/Tickets/Chat";
import TicketContainer from "../pages/Tickets/TicketContainer";

/* 

------- Authentication -------

*/

// layout
import AuthLayout from "../pages/Auth/layouts/AuthLayout";

// login
import Login from "../pages/Auth/Login";

// sign up
import SignUp from "../pages/Auth/SignUp";

// forgot password
import ForgotPassword from "../pages/Auth/ForgotPassword";

// reset password
import ResetPassword from "../pages/Auth/ResetPassword";

// email verification
import EmailVerification from "../pages/Auth/EmailVerification";

/* 

  ------- Admin -------

*/
// succes challenges
import Success from "../pages/Admin/SuccessChallenges";

// withdraw requests
import AdminWithdraw from "../pages/Admin/WithdrawRequests";

// challenge requests
import ChallengeRequests from "../pages/Admin/ChallengeRequests";
import ChallengeRequestDetails from "../pages/Admin/ChallengeRequests/ChallengeRequestDetails";

// verification request
import AdminVerification from "../pages/Admin/VerificationRequests";

// payment requests
import PaymentRequests from "../pages/Admin/PaymentRequests";

// stats
import StatLayout from "../pages/Admin/Stats";

// plans info
import PlanInfoLayout from "../pages/Admin/PlanInfo";

// banner
import BannerLayout from "../pages/Admin/Banner";

// Plans
import PlanLayout from "../pages/Admin/Plan";

// top traders
import TopTraderLayout from "../pages/Admin/TopTrader";

// golden top traders
import GoldenTopTraderLayout from "../pages/Admin/GoldenTopTrader";

// withdraw top traders
import WithdrawTopTraderLayout from "../pages/Admin/WithdrawTopTrader";

// tickets
import AdminTickets from "../pages/Admin/Ticket";
import AdminChatLayout from "../pages/Admin/Ticket/AdminChatLayout";

/* 

------- deactive -------

*/
import NotFound from "../components/NotFound";
import Post from "../pages/Post";
import PostCreate from "../pages/Post/create";
import PostUpdate from "../pages/Post/update";
import UserChallenges from "../pages/Admin/UserChallenges";
import Users from "../pages/Admin/Users";
import UserUpdate from "../pages/Admin/Users/update";
import AddChallenge from "../pages/Admin/Users/AddChallenge";
import UserCertificate from "../pages/Admin/Users/Certificate";
import UserPayment from "../pages/Admin/Users/Payment";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Admin
    // {
    //   path: "admin",
    //   element: <AdminLayout />,
    //   children: [
    //     { element: <Navigate to={"banner"} />, index: true },
    //     { path: "banner", element: <Banner /> },
    //     { path: "Stats", element: <Stats /> },
    //     { path: "Charts", element: <Charts /> },
    //     { path: "TopTraders", element: <AdminTopTraders /> },
    //     { path: "MaxWithdraw", element: <MaxWithdraw /> },
    //     { path: "GoldenPlan", element: <GoldenPlan /> },
    //     { path: "Search", element: <Search /> },
    //     { path: "success", element: <Success /> },
    //     { path: "failed", element: <FailedChallenges /> },
    //     { path: "tickets", element: <AdminTickets /> },
    //   ],
    // },

    // Dashboard
    {
      path: "dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Dashboard />, index: true },
        { path: "profile", element: <Profile /> },
        { path: "dashboard", element: <Navigate to="/dashboard" replace /> },
        { path: "new-challenge", element: <NewChallenge /> },
        { path: "my-challenges", element: <MyChallenge /> },
        // TEST TEST
        { path: "my-challenges/analyze/:id", element: <AnalyzePanel /> },

        { path: "deposit", element: <Deposit /> },
        { path: "deposit/success", element: <DepositTransactionSuccess /> },
        { path: "deposit/failed", element: <DepositTransactionUnsuccess /> },
        { path: "withdraw", element: <Withdraw /> },
        { path: "verification", element: <Verification /> },
        { path: "wallet", element: <Wallet /> },
        { path: "top-traders", element: <TopTraders /> },
        { path: "certificates", element: <Certificate /> },
        { path: "referrals", element: <Referrals /> },
        {
          path: "tickets",
          element: <TicketContainer />,
          children: [
            {
              element: <Tickets />,
              index: true,
            },
            {
              path: "chat/:id",
              element: <ChatLayout />,
            },
          ],
        },
        // Admin
        // { path: "banner", element: <Banner /> },
        // { path: "Charts", element: <Charts /> },
        // { path: "TopTraders", element: <AdminTopTraders /> },
        // { path: "MaxWithdraw", element: <MaxWithdraw /> },
        // { path: "GoldenPlan", element: <GoldenPlan /> },
        // { path: "Search", element: <Search /> },
        // { path: "failed", element: <FailedChallenges /> },
        { path: "success", element: <Success /> },
        { path: "admin-tickets", element: <AdminTickets /> },
        { path: "admin-tickets/admin-chats/:id", element: <AdminChatLayout /> },
        {
          path: "admin-challenge-requests",
          element: <ChallengeRequests />,
        },
        {
          path: "admin-challenge-requests/user/:id",
          element: <ChallengeRequestDetails />,
        },
        { path: "admin-verification-requests", element: <AdminVerification /> },
        { path: "admin/withdraw-requests", element: <AdminWithdraw /> },
        { path: "admin/banner", element: <BannerLayout /> },
        { path: "admin/challenges", element: <UserChallenges /> },
        { path: "admin/users", element: <Users /> },
        { path: "admin/users/update/:id", element: <UserUpdate /> },
        { path: "admin/users/add-challenge/:id", element: <AddChallenge /> },
        { path: "admin/users/certificates/:id", element: <UserCertificate /> },
        { path: "admin/users/payments/:id", element: <UserPayment /> },
        { path: "admin/stats", element: <StatLayout /> },
        { path: "admin/plan-info", element: <PlanInfoLayout /> },
        { path: "admin/plans", element: <PlanLayout /> },
        { path: "admin/top-traders", element: <TopTraderLayout /> },
        { path: "admin/payment-requests", element: <PaymentRequests /> },
        {
          path: "admin/golden-top-traders",
          element: <GoldenTopTraderLayout />,
        },
        {
          path: "admin/withdraw-top-traders",
          element: <WithdrawTopTraderLayout />,
        },
        { path: "post", element: <Post /> },
        { path: "post/create", element: <PostCreate /> },
        { path: "post/update/:id", element: <PostUpdate /> },
      ],
    },
    // Login
    {
      element: <AuthLayout />,
      children: [
        { element: <Login />, index: true },
        { path: "login", element: <Navigate to={"/"} replace /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        { path: "reset-password/:email", element: <ResetPassword /> },

        { path: "signUp", element: <SignUp /> },
        { path: "email-verification", element: <EmailVerification /> },
      ],
    },
    // Main Routes
    {
      element: <Navigate to="/" replace />,
    },

    { path: "/404", element: <NotFound /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
