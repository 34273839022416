import Chart from "react-apexcharts";

const options = {
  chart: {
    // parentHeightOffset: 0,
    type: "radialBar",
    sparkline: {
      enabled: true,
    },
  },
  plotOptions: {
    radialBar: {
      offsetY: -20,
      track: {
        background: "#11182733", // Remove solid track background
      },
      startAngle: -90,
      endAngle: 90,
      dataLabels: {
        name: {
          show: false,
          fontSize: "16px",
          color: "var(--secondary-text)",
          //   offsetY: 120,
        },
        value: {
          show: false,
          //   offsetY: 76,
          // fontSize: "22px",
          // fontWeight: "bold",
          // color: balance >= max ? "#22c55e" : "var(--primary-text)",
        },
      },
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      type: "linear",
      shadeIntensity: 0.5,
      gradientToColors: ["#22c55e"], // final gradient color
      stops: [0, 100],
    },
  },
  stroke: {
    dashArray: 3,
  },
  labels: ["To Withdraw"],
  colors: ["var(--indigo-purple)"], // start gradient color
  grid: {
    padding: {
      // top: 0, // bottom padding to prevent cutting the title
    },
  },
};

function valueToPercent(value, maxValue) {
  return (value / maxValue) * 100; //scale up the value
}

export default function RadialChart({ balance, max = 100, loading }) {
  return (
    <div className="w-full flex flex-col justify-center items-center">
      <Chart
        options={options}
        series={[valueToPercent(balance ?? 0, max)]}
        type="radialBar"
        height={380}
      />
      <div className="w-full h-[1px] bg-gradient-to-r from-[--indigo-purple] to-[#22c55e]" />
      <div
        className={`mt-3 ${
          balance > 0
            ? "text-[#22c55e] text-lg font-semibold "
            : "text-[--primary-text] text-xl text-center"
        } `}
      >
        {loading ? (
          <div className="w-44 h-7 skeleton bg-gray-900/20 rounded-md " />
        ) : balance - max >= 0 ? (
          "Ready To Withdraw"
        ) : (
          <p className="text-gray-500">
            <span className="text-white"> ${(max - balance).toFixed(2)}</span>{" "}
            to Withdraw
          </p>
        )}
      </div>
    </div>
  );
}
