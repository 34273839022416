import React from "react";
import { UserInfo } from "./UserInfo";
import RankingTables from "./RankingTables";
import PageStatus from "../../components/PageStatus/PageStatus";

import { useSelector } from "react-redux";
import useTopTraders from "../../hooks/useTopTraders";
import Loading from "../../components/shared/Loading";
export default function TopTraders() {
  const user = useSelector((state) => state.auth.user);
  const { loading, userRank } = useTopTraders();

  return (
    <>
      <PageStatus
        category={"account"}
        currentPage={"top traders"}
        logoStarColor="#F8CC15"
      />
      {loading ? (
        <div className="w-full text-center">
          <Loading />
        </div>
      ) : (
        <UserInfo user={user} userRank={userRank} />
      )}
      <RankingTables titleBubble revenueIcon />
    </>
  );
}
