import { useState } from "react";
import { formatComma } from "../../../utils/formatNumber";
import RadialChart from "./RadialChart";
import { FaLink } from "react-icons/fa6";

export default function Withdraw({
  data,
  loading,
  withdrawLoading,
  handleWithdraw,
}) {
  const [copyState, setCopyState] = useState("Copy");

  function handleCopyText(text) {
    navigator.clipboard.writeText(text);
    setCopyState("Copied!");
  }
  const referralLink =
    process.env.REACT_APP_FRONT_URL + "/signup?ref=" + data?.referral_token;
  return (
    <div className="w-full grid grid-cols-1 min-[860px]:grid-cols-2 xl:grid-cols-3 dashboard-box gap-10 min-[860px]:gap-5">
      <div className="flex items-center justify-center flex-col gap-2 max-[860px]:row-start-2  ">
        <div className="w-[150px] h-[150px] border border-[--indigo-purple]">
          {loading ? (
            <div className="w-full h-full skeleton bg-gray-900/20 rounded-none" />
          ) : (
            <img
              className="w-full h-full"
              src={data?.referral_qr_code}
              alt=""
            />
          )}
        </div>
        <div className="join border border-[--indigo-purple] mt-2">
          <div className="max-w-[200px] overflow-scroll scrollWidth text-nowrap text-white  join-item bg-transparent select-all relative after:absolute after:w-1/4 after:h-full after:right-0 after:top-0 after:bg-gradient-to-l after:from-gray-800 light:after:from-gray-300 after:to-transparent">
            {loading ? (
              <div className="w-[200px] h-full skeleton bg-transparent rounded-none" />
            ) : (
              <div className="w-full h-full py-1 px-2">{referralLink}</div>
            )}
          </div>
          <button
            disabled={loading}
            onClick={() => handleCopyText(referralLink)}
            data-tip={copyState}
            onMouseLeave={() => setCopyState("Copy")}
            className="tooltip btn btn-sm disabled:bg-gray-600 disabled:text-gray-400 join-item bg-[--indigo-purple] text-white font-normal border-[--indigo-purple] border-none before:bg-[--indigo-purple]"
          >
            <FaLink size={17} />
          </button>
        </div>
      </div>

      <div className="max-xl:hidden">
        <RadialChart
          balance={data?.wallet_balance}
          max={data?.maximum_send}
          loading={loading}
        />
      </div>

      <div className="flex flex-col items-center justify-center gap-1 ">
        <p className="text-second font-semibold">Referral Wallet</p>
        {loading ? (
          <div className="w-32 h-8 skeleton bg-gray-900/20 rounded-md mb-1" />
        ) : (
          <p className="text-white text-3xl font-semibold">
            ${formatComma(data?.wallet_balance)}
          </p>
        )}
        <button
          onClick={handleWithdraw}
          disabled={!data?.button_active || loading || withdrawLoading}
          className="w-full max-w-[200px] bg-[--indigo-purple] text-white p-2 mt-3 rounded-xl border border-transparent hover:border-light-border  disabled:hover:cursor-not-allowed disabled:bg-gray-700 disabled:!border-transparent disabled:text-second light:disabled:bg-gray-300 light:disabled:text-gray-400"
        >
          Send To Wallet
        </button>
      </div>
    </div>
  );
}
