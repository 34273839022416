export default function DonutDataNotFound() {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 427.08 396.02"
    >
      <defs>
        <clipPath id="clippath">
          <path
            d="M30.86,312.71v-15.51h12.33c-.92-3.58-1.4-7.29-1.4-11.06,0-5.47,1.01-10.77,2.92-15.83-1.68.41-3.46.63-5.28.63h-9.11s.29-15.64.29-15.64l-27.9,28.97,28.16,28.43Z"
            style={{
              fill: "none",
              strokeWidth: 0,
            }}
          />
        </clipPath>
        <clipPath id="clippath-1">
          <rect
            x={80.64}
            y={30.23}
            width={9.59}
            height={52.78}
            style={{
              fill: "none",
              strokeWidth: 0,
            }}
          />
        </clipPath>
        <clipPath id="clippath-2">
          <path
            d="M331.2,139.96v-15.51h-12.33c.92-3.58,1.4-7.29,1.4-11.06,0-5.47-1.01-10.77-2.92-15.83,1.68.41,3.46.63,5.28.63h9.11l-.29-15.64,27.9,28.97-28.16,28.43Z"
            style={{
              fill: "none",
              strokeWidth: 0,
            }}
          />
        </clipPath>
        <clipPath id="clippath-3">
          <path
            d="M380.87,196.99l.65-.67c5.83-.81,10.92-4.99,12.62-11.02.22-.77.36-1.54.45-2.3l.27-.27,26.66,7.49s-7.06,30.52-40.3,35.16l-.35-28.39Z"
            style={{
              fill: "none",
              strokeWidth: 0,
            }}
          />
        </clipPath>
      </defs>
      <path
        d="M97.81,226.33v115.35c8.3,2.28,17.22,3.7,26.52,4.1v-123.55c-9.3.4-18.23,1.82-26.52,4.1Z"
        style={{
          fill: "#4138be",
          opacity: 0.7,
          strokeWidth: 0,
        }}
      />
      <path
        d="M61.89,211.07v33.59c7.32-6.23,16.32-11.44,26.53-15.27v-18.19c0-1.29.53-2.46,1.37-3.32.85-.84,2.02-1.37,3.32-1.37,2.59,0,4.7,2.1,4.7,4.69v15.13c8.3-2.28,17.22-3.7,26.52-4.1v-11.16c0-8.62-3.49-16.43-9.14-22.07s-13.46-9.15-22.08-9.15c-17.25,0-31.22,13.98-31.22,31.22Z"
        style={{
          fill: "#4138be",
          opacity: 0.54,
          strokeWidth: 0,
        }}
      />
      <path
        id="blackLine"
        className="stroke-gray-400 light:stroke-black"
        d="M64.02,204.46v33.59c7.32-6.23,16.32-11.44,26.53-15.27v-18.19c0-1.29.53-2.46,1.37-3.32.85-.84,2.02-1.37,3.32-1.37,2.59,0,4.7,2.1,4.7,4.69v15.13c8.3-2.28,17.22-3.7,26.52-4.1v-11.16c0-8.62-3.49-16.43-9.14-22.07-5.66-5.66-13.46-9.15-22.08-9.15-17.25,0-31.22,13.98-31.22,31.22Z"
        style={{
          fill: "none",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M41.8,284.01c0,3.77.48,7.48,1.4,11.06,12.47-.03,23.78-5.09,31.95-13.26,8.2-8.2,13.27-19.53,13.27-32.04v-20.39c-10.22,3.83-19.22,9.04-26.53,15.27v1.71c0,10.58-7.32,19.45-17.17,21.82-1.91,5.05-2.92,10.36-2.92,15.83Z"
        style={{
          fill: "#4138be",
          opacity: 0.8,
          strokeWidth: 0,
        }}
      />
      <path
        d="M30.86,310.58v-15.51h12.33c-.92-3.58-1.4-7.29-1.4-11.06,0-5.47,1.01-10.77,2.92-15.83-1.68.41-3.46.63-5.28.63h-9.11s.29-15.64.29-15.64l-27.9,28.97,28.16,28.43Z"
        style={{
          fill: "#4138be",
          opacity: 0.54,
          strokeWidth: 0,
        }}
      />
      <path
        id="blackLine-2"
        className="stroke-gray-400 light:stroke-black"
        d="M44.98,288.13c0,3.77.48,7.48,1.4,11.06,12.47-.03,23.78-5.09,31.95-13.26,8.2-8.2,13.27-19.53,13.27-32.04v-20.39c-10.22,3.83-19.22,9.04-26.53,15.27v1.71c0,10.58-7.32,19.45-17.17,21.82-1.91,5.05-2.92,10.36-2.92,15.83Z"
        style={{
          fill: "none",
          strokeMiterlimit: 10,
        }}
      />
      <g
        style={{
          clipPath: "url(#clippath)",
        }}
      >
        <line
          x1={43.72}
          y1={287.72}
          x2={20.93}
          y2={310.51}
          style={{
            fill: "none",
            stroke: "#4138be",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={45.53}
          y1={279.99}
          x2={19.01}
          y2={306.52}
          style={{
            fill: "none",
            stroke: "#4138be",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={51.37}
          y1={268.24}
          x2={15.36}
          y2={304.25}
          style={{
            fill: "none",
            stroke: "#4138be",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={49.34}
          y1={264.35}
          x2={12.38}
          y2={301.31}
          style={{
            fill: "none",
            stroke: "#4138be",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={43.43}
          y1={264.35}
          x2={9.25}
          y2={298.53}
          style={{
            fill: "none",
            stroke: "#4138be",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={34.12}
          y1={267.75}
          x2={4.67}
          y2={297.2}
          style={{
            fill: "none",
            stroke: "#4138be",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={32.2}
          y1={263.75}
          x2={2.9}
          y2={293.05}
          style={{
            fill: "none",
            stroke: "#4138be",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={30.28}
          y1={259.76}
          x2={0.35}
          y2={289.68}
          style={{
            fill: "none",
            stroke: "#4138be",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={47.82}
          y1={289.92}
          x2={25.03}
          y2={312.71}
          style={{
            fill: "none",
            stroke: "#4138be",
            strokeMiterlimit: 10,
          }}
        />
      </g>
      <g>
        <path
          d="M52.1,28.56c.62,25.66.66,52.77,0,78.45-.66-25.7-.62-52.79,0-78.45h0Z"
          className="charts-gray fill-gray-400 light:fill-gray-700"
          style={{
            strokeWidth: 0,
          }}
        />
        <path
          d="M38.01,88.46c36.72,3.67,73.75,8.33,110.2,14.08-36.73-3.59-73.74-8.4-110.2-14.08h0Z"
          className="charts-gray fill-gray-400 light:fill-gray-700"
          style={{
            strokeWidth: 0,
          }}
        />
        <rect
          x={79.43}
          y={30.43}
          className="fill-gray-400 light:fill-[#50565e]"
          width={9.59}
          height={52.36}
          style={{
            strokeWidth: 0,
          }}
        />
        <g
          style={{
            clipPath: "url(#clippath-1)",
          }}
        >
          <line
            x1={71.71}
            y1={42.08}
            x2={95.47}
            y2={26.6}
            style={{
              fill: "none",
              stroke: "#29313d",
              strokeMiterlimit: 10,
            }}
          />
          <line
            x1={72.93}
            y1={45.32}
            x2={94.28}
            y2={31.32}
            style={{
              fill: "none",
              stroke: "#29313d",
              strokeMiterlimit: 10,
            }}
          />
          <line
            x1={74.15}
            y1={48.57}
            x2={90.51}
            y2={37.61}
            style={{
              fill: "none",
              stroke: "#29313d",
              strokeMiterlimit: 10,
            }}
          />
          <line
            x1={75.36}
            y1={51.81}
            x2={91.73}
            y2={40.86}
            style={{
              fill: "none",
              stroke: "#29313d",
              strokeMiterlimit: 10,
            }}
          />
          <line
            x1={76.58}
            y1={55.05}
            x2={92.94}
            y2={44.1}
            style={{
              fill: "none",
              stroke: "#29313d",
              strokeMiterlimit: 10,
            }}
          />
          <line
            x1={77.8}
            y1={58.29}
            x2={94.16}
            y2={47.34}
            style={{
              fill: "none",
              stroke: "#29313d",
              strokeMiterlimit: 10,
            }}
          />
          <line
            x1={79.02}
            y1={61.53}
            x2={95.38}
            y2={50.58}
            style={{
              fill: "none",
              stroke: "#29313d",
              strokeMiterlimit: 10,
            }}
          />
          <line
            x1={74.5}
            y1={69.03}
            x2={90.86}
            y2={58.07}
            style={{
              fill: "none",
              stroke: "#29313d",
              strokeMiterlimit: 10,
            }}
          />
          <line
            x1={75.72}
            y1={72.27}
            x2={92.08}
            y2={61.32}
            style={{
              fill: "none",
              stroke: "#29313d",
              strokeMiterlimit: 10,
            }}
          />
          <line
            x1={76.94}
            y1={75.51}
            x2={93.3}
            y2={64.56}
            style={{
              fill: "none",
              stroke: "#29313d",
              strokeMiterlimit: 10,
            }}
          />
          <line
            x1={78.16}
            y1={78.75}
            x2={94.52}
            y2={67.8}
            style={{
              fill: "none",
              stroke: "#29313d",
              strokeMiterlimit: 10,
            }}
          />
          <line
            x1={79.38}
            y1={81.99}
            x2={95.74}
            y2={71.04}
            style={{
              fill: "none",
              stroke: "#29313d",
              strokeMiterlimit: 10,
            }}
          />
          <line
            x1={80.6}
            y1={85.24}
            x2={96.96}
            y2={74.28}
            style={{
              fill: "none",
              stroke: "#29313d",
              strokeMiterlimit: 10,
            }}
          />
        </g>
        <g>
          <path
            id="question"
            d="M121.06,47.22c-1.2-2.2-3.25-3.82-6.12-4.9-.41-.16-.86-.3-1.31-.43-3.25-.94-6.13-.72-8.64.66-3.55,1.96-5.94,5.04-7.16,9.25,0,0,0,.01,0,.02l1.27.37h0s4.32,1.25,4.32,1.25c.94-3.24,2.45-5.45,4.55-6.6.89-.49,1.84-.7,2.83-.63.42.03.82.11,1.25.23,1.78.52,3.02,1.41,3.71,2.66.8,1.47.88,3.34.22,5.61-.51,1.78-1.61,3.14-3.28,4.06-.62.34-1.2.67-1.74.99-2.03,1.19-3.5,2.19-4.44,3.03-1.51,1.32-2.75,3.5-3.74,6.55l-.27.97,4.35,1.29c.84-2.93,2.21-4.91,4.09-5.95,1.53-.84,2.87-1.58,4.01-2.2.73-.41,1.39-.76,1.96-1.08,2.51-1.39,4.17-3.45,4.97-6.22.94-3.24.66-6.23-.84-8.95ZM102.17,72.5l-.09-.03-.24-.09-1.39,5.86,4.1,1.47.87-3.7.52-2.16-3.77-1.35Z"
            className="charts-gray fill-gray-400 light:fill-gray-700"
            style={{
              opacity: 0.2,
              strokeWidth: 0,
            }}
          />
          <path
            id="question-2"
            data-name="question"
            d="M124.83,48.34c-1.2-2.2-3.25-3.82-6.12-4.9-.41-.16-.86-.3-1.31-.43-3.25-.94-6.13-.72-8.64.66-3.55,1.96-5.94,5.04-7.16,9.25,0,0,0,.01,0,.02l1.27.37h0s4.32,1.25,4.32,1.25c.94-3.24,2.45-5.45,4.55-6.6.89-.49,1.84-.7,2.83-.63.42.03.82.11,1.25.23,1.78.52,3.02,1.41,3.71,2.66.8,1.47.88,3.34.22,5.61-.51,1.78-1.61,3.14-3.28,4.06-.62.34-1.2.67-1.74.99-2.03,1.19-3.5,2.19-4.44,3.03-1.51,1.32-2.75,3.5-3.74,6.55l-.27.97,4.35,1.29c.84-2.93,2.21-4.91,4.09-5.95,1.53-.84,2.87-1.58,4.01-2.2.73-.41,1.39-.76,1.96-1.08,2.51-1.39,4.17-3.45,4.97-6.22.94-3.24.66-6.23-.84-8.95ZM106.12,73.95l-.09-.03-.24-.09-1.39,5.86,4.1,1.47.87-3.7.52-2.16-3.77-1.35Z"
            className="charts-gray fill-gray-400 light:fill-gray-700"
            style={{
              opacity: 0.5,
              strokeWidth: 0,
            }}
          />
        </g>
        <path
          id="question-3"
          data-name="question"
          d="M125.38,72.84c-1.36-.06-2.69.44-4,1.47-.19.15-.38.32-.57.49-1.35,1.25-2.06,2.65-2.13,4.21-.09,2.2.67,4.19,2.29,5.94,0,0,0,0,0,.01l.53-.49h0s1.8-1.66,1.8-1.66c-1.24-1.35-1.84-2.68-1.79-3.98.03-.55.19-1.05.5-1.49.13-.18.28-.35.46-.52.74-.68,1.51-1.01,2.29-.98.91.04,1.8.53,2.67,1.48.69.74,1.01,1.63.96,2.67-.02.39-.03.75-.03,1.09-.02,1.28.03,2.25.16,2.92.19,1.07.86,2.27,2,3.59l.38.4,1.83-1.66c-1.12-1.22-1.66-2.41-1.61-3.58.04-.95.07-1.78.11-2.49.02-.46.03-.86.05-1.22.06-1.56-.43-2.92-1.5-4.07-1.24-1.35-2.72-2.06-4.4-2.13ZM132.03,88.76l-.04.03-.11.09,2.34,2.3,1.84-1.5-1.48-1.45-.86-.85-1.69,1.37Z"
          className="charts-gray fill-gray-400 light:fill-gray-700"
          style={{
            opacity: 0.2,
            strokeWidth: 0,
          }}
        />
        <path
          id="question-4"
          data-name="question"
          d="M127.07,71.14c-1.36-.06-2.69.44-4,1.47-.19.15-.38.32-.57.49-1.35,1.25-2.06,2.65-2.13,4.21-.09,2.2.67,4.19,2.29,5.94,0,0,0,0,0,.01l.53-.49h0s1.8-1.66,1.8-1.66c-1.24-1.35-1.84-2.68-1.79-3.98.03-.55.19-1.05.5-1.49.13-.18.28-.35.46-.52.74-.68,1.51-1.01,2.29-.98.91.04,1.8.53,2.67,1.48.69.74,1.01,1.63.96,2.67-.02.39-.03.75-.03,1.09-.02,1.28.03,2.25.16,2.92.19,1.07.86,2.27,2,3.59l.38.4,1.83-1.66c-1.12-1.22-1.66-2.41-1.61-3.58.04-.95.07-1.78.11-2.49.02-.46.03-.86.05-1.22.06-1.56-.43-2.92-1.5-4.07-1.24-1.35-2.72-2.06-4.4-2.13ZM133.72,87.07l-.04.03-.11.09,2.34,2.3,1.84-1.5-1.48-1.45-.86-.85-1.69,1.37Z"
          className="charts-gray fill-gray-400 light:fill-gray-700"
          style={{
            opacity: 0.5,
            strokeWidth: 0,
          }}
        />
        <rect
          x={63.32}
          y={14.51}
          className="fill-gray-400 light:fill-[#464b51]"
          width={9.59}
          height={65.1}
          style={{
            strokeWidth: 0,
          }}
        />
        <rect
          x={61.69}
          y={15.36}
          className="fill-gray-400 light:fill-[#50565e]"
          width={9.59}
          height={65.1}
          style={{
            strokeWidth: 0,
          }}
        />
      </g>
      <path
        d="M264.26,53.59v115.35c-8.3,2.28-17.22,3.7-26.52,4.1V49.49c9.3.4,18.23,1.82,26.52,4.1Z"
        style={{
          fill: "#4138be",
          opacity: 0.7,
          strokeWidth: 0,
        }}
      />
      <path
        d="M300.18,38.33v33.59c-7.32-6.23-16.32-11.44-26.53-15.27v-18.19c0-1.29-.53-2.46-1.37-3.32-.85-.84-2.02-1.37-3.32-1.37-2.59,0-4.7,2.1-4.7,4.69v15.13c-8.3-2.28-17.22-3.7-26.52-4.1v-11.16c0-8.62,3.49-16.43,9.14-22.07,5.66-5.66,13.46-9.15,22.08-9.15,17.25,0,31.22,13.98,31.22,31.22Z"
        style={{
          fill: "#4138be",
          opacity: 0.54,
          strokeWidth: 0,
        }}
      />
      <path
        id="blackLine-3"
        className="stroke-gray-400 light:stroke-black"
        d="M298.04,31.72v33.59c-7.32-6.23-16.32-11.44-26.53-15.27v-18.19c0-1.29-.53-2.46-1.37-3.32-.85-.84-2.02-1.37-3.32-1.37-2.59,0-4.7,2.1-4.7,4.69v15.13c-8.3-2.28-17.22-3.7-26.52-4.1v-11.16c0-8.62,3.49-16.43,9.14-22.07,5.66-5.66,13.46-9.15,22.08-9.15,17.25,0,31.22,13.98,31.22,31.22Z"
        style={{
          fill: "none",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M320.27,111.27c0,3.77-.48,7.48-1.4,11.06-12.47-.03-23.78-5.09-31.95-13.26-8.2-8.2-13.27-19.53-13.27-32.04v-20.39c10.22,3.83,19.22,9.04,26.53,15.27v1.71c0,10.58,7.32,19.45,17.17,21.82,1.91,5.05,2.92,10.36,2.92,15.83Z"
        style={{
          fill: "#4138be",
          opacity: 0.8,
          strokeWidth: 0,
        }}
      />
      <path
        d="M331.2,137.84v-15.51h-12.33c.92-3.58,1.4-7.29,1.4-11.06,0-5.47-1.01-10.77-2.92-15.83,1.68.41,3.46.63,5.28.63h9.11l-.29-15.64,27.9,28.97-28.16,28.43Z"
        style={{
          fill: "#4138be",
          opacity: 0.54,
          strokeWidth: 0,
        }}
      />
      <path
        id="blackLine-4"
        className="stroke-gray-400 light:stroke-black"
        d="M317.09,115.39c0,3.77-.48,7.48-1.4,11.06-12.47-.03-23.78-5.09-31.95-13.26-8.2-8.2-13.27-19.53-13.27-32.04v-20.39c10.22,3.83,19.22,9.04,26.53,15.27v1.71c0,10.58,7.32,19.45,17.17,21.82,1.91,5.05,2.92,10.36,2.92,15.83Z"
        style={{
          fill: "none",
          strokeMiterlimit: 10,
        }}
      />
      <g
        style={{
          clipPath: "url(#clippath-2)",
        }}
      >
        <line
          x1={318.35}
          y1={114.98}
          x2={341.14}
          y2={137.77}
          style={{
            fill: "none",
            stroke: "#4138be",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={316.53}
          y1={107.25}
          x2={343.06}
          y2={133.78}
          style={{
            fill: "none",
            stroke: "#4138be",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={310.7}
          y1={95.5}
          x2={346.7}
          y2={131.51}
          style={{
            fill: "none",
            stroke: "#4138be",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={312.72}
          y1={91.61}
          x2={349.68}
          y2={128.57}
          style={{
            fill: "none",
            stroke: "#4138be",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={318.64}
          y1={91.61}
          x2={352.81}
          y2={125.79}
          style={{
            fill: "none",
            stroke: "#4138be",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={327.95}
          y1={95}
          x2={357.4}
          y2={124.45}
          style={{
            fill: "none",
            stroke: "#4138be",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={329.87}
          y1={91.01}
          x2={359.16}
          y2={120.3}
          style={{
            fill: "none",
            stroke: "#4138be",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={331.79}
          y1={87.01}
          x2={361.71}
          y2={116.94}
          style={{
            fill: "none",
            stroke: "#4138be",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1={314.24}
          y1={117.18}
          x2={337.03}
          y2={139.97}
          style={{
            fill: "none",
            stroke: "#4138be",
            strokeMiterlimit: 10,
          }}
        />
      </g>
      <path
        d="M245.92,115.21c7.4-.32,14.5-1.45,21.1-3.26v67.15h-21.1v-63.89Z"
        style={{
          fill: "#4138be",
          opacity: 0.54,
          strokeWidth: 0,
        }}
      />
      <g>
        <path
          d="M223.25,241.1s-7.42-7.87-29.33-13.82c-21.91-5.95-31.53-2.1-31.53-2.1l-38.3,136.06,27.26-1.07,8.24,35.84h206.71v-42.29l9.65.42c1.27.06,2.3-1.04,2.17-2.3l-4.15-39.47c-.33-3.09-.51-6.2-.56-9.3l-.16-10.3c-.19-1.56-.12-3.14.22-4.67l.84-3.86c.97-4.48,1.17-9.08.6-13.63l-.62-4.93c-.64-5.05-2.05-9.98-4.19-14.6l-.46-1.01c-1.85-3.99-4.21-7.72-7.04-11.08l-1.85-2.2c-2.68-3.18-5.74-6.02-9.13-8.44h0c-3.12-2.23-6.49-4.09-10.04-5.54l-2.91-1.19c-2.19-.9-4.45-1.64-6.64-2.34l-4.34-1.08c-2.83-.55-5.62-1-8.42-1.21l-2.19-.16c-4.28-.32-8.59-.13-12.83.56h0c-8.96,1.46-17.67,4.17-25.87,8.06l-37.79,17.92-17.31-2.27Z"
          style={{
            strokeWidth: 0,
          }}
        />
        <g>
          <polygon
            points="234.78 214.26 238.8 192.58 203.3 200.45 211.24 220.98 234.78 214.26"
            style={{
              strokeWidth: 0,
            }}
          />
          <g>
            <path
              d="M291,225.18l-16.26,13.99c-6.69,5.76-13.97,10.81-21.7,15.06l-2.97,1.63-12.99,6.74c-3.1,1.38-5.31,2.28-8.69,2.58l-2.71.34c-1.72.15-3.52,0-5.29,0l-2.12-.17c-2.74-.22-5.46-.82-7.92-2.05-.37-.19-.74-.38-1.09-.6h0c-2.35-1.43-4.19-3.56-5.27-6.08h0c-1.32-3.09-2.02-6.41-2.05-9.77l-.13-12.57h9.43v-13.3l21.47-21.92-19.1,7.5c-3.09,1.21-6.39,1.84-9.71,1.84h-2.01c-4.27,0-8.47-1.03-12.26-3h0c-3.94-2.05-7.31-5.06-9.79-8.75l-1.49-2.22c-2.28-3.4-3.75-7.28-4.29-11.33l-.98-7.41c-.2-1.52-.3-3.04-.3-4.57v-25.45l-3.65-12.55,44-19.62,76.11,2.08.02,2.93-10.84,27.15,12.59,79.52Z"
              style={{
                fill: "#ffccde",
                strokeWidth: 0,
              }}
            />
            <line
              x1={194.2}
              y1={169.31}
              x2={208.71}
              y2={162.84}
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
              }}
            />
            <path
              d="M191.66,158.03l-2.33-1.76c-1.05-.79-1.84-1.88-2.27-3.12h0c-.42-1.21-.49-2.52-.18-3.77l.05-.19c.24-.98.7-1.89,1.34-2.67l7.42-8.96"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
              }}
            />
          </g>
        </g>
        <g>
          <path
            d="M46.53,396.02v-2.36c0-8.67,1.77-17.25,5.22-25.21l70.25-162.5c.9-2.07,1.17-4.36.8-6.58l-4.91-29.35c-1.32-7.88-1.98-15.85-1.98-23.83v-19.56c0-1.42,1.86-7.99,2.15-9.37l4.02-21.52c.96-4.46,3.99-11.6,7.28-14.77l.3-.29c.57-.55,1.34-.86,2.13-.86h0c1.7,0,3.07,1.38,3.07,3.07l-.77,13.22c0,.84,1.01,1.26,1.61.67l17.82-17.82c1.99-1.99,4.41-3.48,7.08-4.37h0c.93-.31,1.89.38,1.89,1.36v17.83s7.93,63.42,7.93,63.42l3.18,27.03c.52,4.42.45,8.89-.21,13.29l-4.33,28.86c-.41,2.77-1,5.5-1.74,8.2l-34.02,122.85c-.27.97.46,1.93,1.47,1.93h15.82c.86,0,1.38.95.91,1.67l-22.76,35H46.53Z"
            style={{
              fill: "#ffccde",
              strokeWidth: 0,
            }}
          />
          <path
            d="M150.39,202c-1.7-15.1-7.38-29.64-15.73-42.27-2.89-4.2-5.25-8.85-6.58-13.83,0,0,.26.32.26.32l-2.56-1.27.44-.9s2.56,1.27,2.56,1.27l.2.1.06.22c1.25,4.86,3.49,9.43,6.31,13.62,8.27,12.81,13.83,27.51,15.38,42.7,0,0-.35.04-.35.04h0Z"
            style={{
              strokeWidth: 0,
            }}
          />
        </g>
        <path
          d="M160.03,84.04c0-.91-.07-1.72-.07-2.81h0c0-.41.04-.81.12-1.21l.27-1.33c.32-1.58,1.15-3,2.36-4.06h0c1.27-1.1,2.89-1.73,4.57-1.77l2.15-.05c.74-.02,1.47-.12,2.18-.31h0c1.87-.5,3.54-1.56,4.77-3.05l1.11-1.34,2.87-3.45,2.02-2.66,1.36-2.03c.35-.52.77-.98,1.25-1.37l.64-.52c.6-.49,1.28-.87,2.01-1.11h0c1.19-.4,2.46-.43,3.67-.1l.23.06c1.15.32,2.2.95,3,1.84l.81.89c.72.79,1.65,1.36,2.69,1.64l.68.19c1.03.28,2.12.27,3.15-.05h0c1.01-.31,1.91-.89,2.61-1.69l3.34-3.81c1.2-1.37,2.54-2.62,3.98-3.73l.9-.69c1.2-.92,2.56-1.6,4.02-2h0c1.04-.29,2.11-.44,3.19-.44h2.34c1.03,0,2.05.13,3.04.4l.95.25c1.83.49,3.52,1.41,4.93,2.68l.16.15c1.03.94,1.9,2.05,2.54,3.29l.96,1.83c.74,1.43,1.63,2.77,2.66,4.01l.6.73c1.47,1.79,3.68,2.81,5.99,2.77h0c1.65-.03,3.24-.59,4.54-1.6l.32-.25c1.84-1.43,4.11-2.21,6.45-2.21h1.92c2.21,0,4.36.7,6.15,1.99h0c1.06.77,1.97,1.73,2.68,2.83l1.67,2.6c.78,1.22,1.21,2.62,1.24,4.07l.26,13.08c.02,1.03.74,1.92,1.75,2.15h.02c1.93.44,3.91.66,5.89.66h0c2.01,0,3.98.52,5.73,1.51h0c2.18,1.23,3.9,3.14,4.92,5.43l.52,1.17c.65,1.46.98,3.04.99,4.64l.07,14.32-76.11-2.08-44,19.62-11.08-38.17,1.3-4.78c.34-1.71.84-3.4.78-4.92l-.05-1.19Z"
          style={{
            strokeWidth: 0,
          }}
        />
        <path
          d="M173.26,154.02c-.51,5.69-.48,11.34-.3,17.07.02,5.77.86,11.32,2.34,16.91,0,0-.28.06-.28.06-3.03-8.11-3.87-16.84-4.49-25.48-.19-2.89-.27-5.79-.25-8.7l2.99.13h0Z"
          style={{
            strokeWidth: 0,
          }}
        />
        <circle
          cx={156.22}
          cy={127.64}
          r={34.42}
          className="fill-gray-300 light:fill-white"
          style={{
            strokeWidth: 0,
          }}
        />
        <g>
          <path
            d="M150.46,162.79c-18.71,0-33.92-15.22-33.92-33.92s15.22-33.92,33.92-33.92,33.92,15.22,33.92,33.92-15.22,33.92-33.92,33.92Z"
            className="fill-gray-300 light:fill-white"
            style={{
              strokeWidth: 0,
            }}
          />
          <path
            d="M150.46,95.44c18.43,0,33.42,14.99,33.42,33.42s-14.99,33.42-33.42,33.42-33.42-14.99-33.42-33.42,14.99-33.42,33.42-33.42M150.46,94.44c-19.01,0-34.42,15.41-34.42,34.42s15.41,34.42,34.42,34.42,34.42-15.41,34.42-34.42-15.41-34.42-34.42-34.42h0Z"
            style={{
              strokeWidth: 0,
            }}
          />
        </g>
        <circle
          cx={150.46}
          cy={128.87}
          r={29.73}
          style={{
            fill: "#241f21",
            strokeWidth: 0,
          }}
        />
        <circle
          cx={224.99}
          cy={127.64}
          r={33.29}
          className="fill-gray-300 light:fill-white"
          style={{
            strokeWidth: 0,
          }}
        />
        <g>
          <path
            d="M218.94,160.43c-17.76,0-32.21-14.45-32.21-32.21s14.45-32.21,32.21-32.21,32.21,14.45,32.21,32.21-14.45,32.21-32.21,32.21Z"
            className="fill-gray-300 light:fill-white"
            style={{
              strokeWidth: 0,
            }}
          />
          <path
            d="M218.95,96.52c17.48,0,31.71,14.22,31.71,31.71s-14.22,31.71-31.71,31.71-31.71-14.22-31.71-31.71,14.22-31.71,31.71-31.71M218.95,95.52c-18.06,0-32.71,14.64-32.71,32.71s14.64,32.71,32.71,32.71,32.71-14.64,32.71-32.71-14.64-32.71-32.71-32.71h0Z"
            style={{
              strokeWidth: 0,
            }}
          />
        </g>
        <circle
          cx={218.95}
          cy={128.22}
          r={28.25}
          style={{
            fill: "#241f21",
            strokeWidth: 0,
          }}
        />
        <polyline
          points="138.32 100.26 149.93 116.45 144.13 142.04 157.99 158.6"
          className="stroke-gray-300 light:stroke-white"
          style={{
            fill: "none",

            strokeMiterlimit: 10,
          }}
        />
        <polyline
          points="132.77 101 144.38 117.19 138.58 142.78 152.44 159.34"
          className="stroke-gray-300 light:stroke-white"
          style={{
            fill: "none",

            strokeMiterlimit: 10,
          }}
        />
        <path
          d="M128.88,129.98s18.95-21.92,39.29,0c0,0-24.89,22.79-39.29,0Z"
          className="fill-gray-300 light:fill-white"
          style={{
            strokeWidth: 0,
          }}
        />
        <circle
          cx={144.59}
          cy={128.78}
          r={7.85}
          style={{
            strokeWidth: 0,
          }}
        />
        <polyline
          points="211.88 99.24 223.49 115.43 217.69 141.02 229.66 156.16"
          className="stroke-gray-300 light:stroke-white"
          style={{
            fill: "none",

            strokeMiterlimit: 10,
          }}
        />
        <polyline
          points="206.34 99.98 217.94 116.18 212.14 141.76 226.01 158.32"
          className="stroke-gray-300 light:stroke-white"
          style={{
            fill: "none",

            strokeMiterlimit: 10,
          }}
        />
        <path
          d="M198.43,129.28s18-20.83,37.33,0c0,0-23.65,21.66-37.33,0Z"
          className="fill-gray-300 light:fill-white"
          style={{
            strokeWidth: 0,
          }}
        />
        <circle
          cx={213.37}
          cy={128.14}
          r={7.46}
          style={{
            strokeWidth: 0,
          }}
        />
        <g
          style={{
            opacity: 0.6,
          }}
        >
          <path
            d="M218.95,294.55l-30.73,30.73,30.73,30.73,30.73-30.73-30.73-30.73ZM218.91,300.27c.8,3.14,3.26,5.6,6.4,6.4-3.14.8-5.6,3.26-6.4,6.4-.77-3.14-3.23-5.6-6.37-6.4,3.14-.8,5.6-3.23,6.37-6.4ZM218.95,349.86l-18.43-18.43-.09-.09c-3.41-3.51-3.38-9.11.09-12.55l9.04-9.04,6.34,6.34-9.23,9.23,18.43,18.43-6.15,6.12ZM228.14,340.63l-6.15-6.15,9.23-9.23-3.08-3.08-9.23,9.23-6.15-6.15,15.35-15.35,5.84,5.84.31.31,3.08,3.08c3.38,3.38,3.38,8.89,0,12.3l-9.2,9.2Z"
            style={{
              fill: "#4138be",
              strokeWidth: 0,
            }}
          />
        </g>
        <g>
          <g>
            <path
              d="M285.9,101.47l3.21,2.81c.75.84,1.66,1.87,2.52,2.74l1.39,1.5c1.42,1.79,1.9,2.29,3.12,4.48l3.44,6.18c1.64,2.95,2.93,6.08,3.84,9.34l1.77,6.32c1.98,7.09,3.29,14.35,3.9,21.68l5.79,61.55,16.47,129.6c.35,2.64,2.6,4.62,5.25,4.62h19.55l-14.28,43.74h-86.27l10.66-193.5c.19-3.47-.71-6.91-2.57-9.85h0c-1.69-2.67-2.59-5.77-2.59-8.93v-28.15c0-7.22-.83-14.42-2.49-21.45l-3.2-13.61c-1.18-5-3.03-9.82-5.51-14.32l-9.67-17.53c-.94-1.88-1.42-3.95-1.42-6.04h0c0-.71.18-1.41.51-2.03l.27-.49c.45-.84,1.74-1.49,2.68-1.87.65-.26,1.34-.34,2.04-.26h0c.94.11,1.81.54,2.48,1.22l3.93,3.98c.41.42.97.66,1.55.67l1.91.04c1.41.03,2.79.47,3.97,1.26l5.59,3.76c.71.48,1.54.74,2.4.77l1.38.04c3.39.09,6.62,1.43,9.08,3.77l9.32,7.97Z"
              style={{
                fill: "#ffccde",
                strokeWidth: 0,
              }}
            />
            <path
              d="M247.25,77.67c9.73,14.5,18.65,29.88,23.53,46.74,0,0-.54.15-.54.15-3.66-12.57-9.88-24.23-16.65-35.38-2.28-3.71-4.66-7.38-7.16-10.93,0,0,.81-.58.81-.58h0Z"
              style={{
                strokeWidth: 0,
              }}
            />
            <path
              d="M262.89,86.43c9.05,10.66,16.07,22.96,21,36.03,0,0-.53.2-.53.2-3.75-9.71-8.75-18.93-14.79-27.4-2.01-2.82-4.14-5.58-6.42-8.15l.74-.67h0Z"
              style={{
                strokeWidth: 0,
              }}
            />
            <path
              d="M279.89,94.56c.02-.29-.04-.2.01-.18.03.04.07.09.13.15,7.88,7.44,12.24,17.76,15.29,27.96,0,0-.34.1-.34.1-3.19-9.89-7.53-19.87-15.29-27.02-.34-.38-.82-.62-.78-1.24,0,0,.98.21.98.21h0Z"
              style={{
                strokeWidth: 0,
              }}
            />
          </g>
          <path
            d="M262.15,277.05c.71-23.6,2.66-49.12,4.01-72.76.35-3.5-.3-7.03-2.08-10.06-2.05-3.04-3.17-6.83-3.01-10.51,0,0,0-2.7,0-2.7-.12-10,.39-22.41-.24-32.34-.47-7.08-2.24-14.24-3.82-21.15-1.57-6.87-2.85-14.29-6.62-20.38,2.11,2.93,3.59,6.28,4.72,9.72,1.14,3.44,1.86,6.98,2.75,10.46,1.75,6.98,3.47,14.05,4,21.27.68,9.97.22,22.38.38,32.42.03,1.54-.07,3.83.13,5.32.24,3.67,2.57,6.52,3.94,9.81,1.42,3.43,1.14,7.3.84,10.9-1.56,22.71-2.8,47.41-5,70h0Z"
            style={{
              strokeWidth: 0,
            }}
          />
        </g>
      </g>
      <g>
        <path
          d="M360.75,190.01c-2.94,2.1-5.07,3.86-6.4,5.29-2.15,2.26-3.81,5.88-5.03,10.87l-.36,1.61-.75,5.12-.9-1c-8.75-8.25-13.72-20.46-13.72-33.88,0-.4,0-.8.01-1.18l6.64,1.71s-.01.02-.01.04l1.95.47h.01l6.62,1.58c1.12-5.29,3.2-8.97,6.25-11.02,1.3-.87,2.7-1.3,4.2-1.29-1.53,2.86-2.41,6.17-2.41,9.68,0,4.52,1.46,8.7,3.9,12.01Z"
          className="charts-gray fill-gray-400 light:fill-gray-700"
          style={{
            opacity: 0.2,
            strokeWidth: 0,
          }}
        />
        <path
          d="M348.71,205.81c1.23-4.75,3.53-9.2,5.71-11.36,1.35-1.36,3.51-3.04,6.5-5.05.79-.54,1.66-1.1,2.57-1.68,2.47-1.57,4.03-3.74,4.64-6.51.79-3.54.5-6.39-.86-8.56-1.17-1.86-3.15-3.1-5.93-3.73-.67-.15-1.3-.22-1.94-.23-1.52-.01-2.94.4-4.26,1.23-3.1,1.96-5.2,5.47-6.34,10.52l-6.72-1.51h-.01l-1.98-.45s0-.02.01-.03l-6.73-1.63c.57-21.9,17.49-39.73,39-41.72,1.32-.12,2.66-.19,4.02-.19,18.93,0,34.99,12.22,40.77,29.19,1.48,4.34,2.27,9.01,2.27,13.85,0,23.76-19.26,43.03-43.04,43.03-7.82,0-15.15-2.09-21.47-5.74-2.47-1.42-4.79-3.09-6.91-4.97"
          className="charts-gray stroke-gray-400 light:stroke-gray-700"
          style={{
            fill: "none",
            strokeMiterlimit: 10,
          }}
        />
        <path
          d="M368.37,180.83c-.62,2.77-2.17,4.94-4.64,6.51-.92.58-1.78,1.14-2.57,1.68-2.48-3.17-3.96-7.15-3.96-11.47,0-3.36.9-6.51,2.45-9.24.64.01,1.26.09,1.94.23,2.77.63,4.75,1.87,5.93,3.73,1.36,2.17,1.66,5.02.86,8.56Z"
          className="charts-gray stroke-gray-400 light:stroke-gray-700"
          style={{
            fill: "none",
            strokeMiterlimit: 10,
          }}
        />
        <path
          d="M394.49,177.56c0,10.3-8.35,18.65-18.65,18.65-2.94,0-5.73-.68-8.2-1.9-2.55-1.24-4.78-3.07-6.49-5.28.79-.54,1.66-1.1,2.57-1.68,2.47-1.57,4.03-3.74,4.64-6.51.79-3.54.5-6.39-.86-8.56-1.17-1.86-3.15-3.1-5.93-3.73-.67-.15-1.3-.22-1.94-.23,1.47-2.56,3.52-4.74,5.99-6.34,2.18-1.44,4.68-2.43,7.37-2.83.92-.15,1.87-.23,2.84-.23,7.63,0,14.21,4.6,17.07,11.16,1.01,2.29,1.58,4.82,1.58,7.48Z"
          className="charts-gray stroke-gray-400 light:stroke-gray-700"
          style={{
            fill: "none",
            strokeMiterlimit: 10,
          }}
        />
        <path
          d="M393.91,170.62l-1-.55c-2.87-6.57-9.44-11.16-17.07-11.16-.98,0-1.92.09-2.84.23l-.4-.23v-33.71s38.1-1.78,54.48,35.66l-33.16,9.75Z"
          className="charts-gray fill-gray-400 light:fill-gray-700"
          style={{
            strokeWidth: 0,
          }}
        />
        <path
          id="question-5"
          data-name="question"
          d="M375.44,168.89c-2.05-3.24-5.33-5.54-9.81-6.91-.65-.2-1.34-.38-2.05-.54-5.06-1.13-9.44-.53-13.16,1.82-5.25,3.32-8.61,8.26-10.09,14.81-.01.01-.01.02-.01.03l1.98.45h.01l6.72,1.51c1.14-5.05,3.24-8.56,6.34-10.52,1.32-.83,2.74-1.24,4.26-1.23.64.01,1.26.09,1.94.23,2.77.63,4.75,1.87,5.93,3.73,1.36,2.17,1.66,5.02.86,8.56-.62,2.77-2.17,4.94-4.64,6.51-.92.58-1.78,1.14-2.57,1.68-2.99,2-5.15,3.68-6.5,5.05-2.18,2.16-3.87,5.62-5.1,10.37l-.32,1.51,6.78,1.57c1.02-4.55,2.92-7.71,5.71-9.48,2.26-1.43,4.24-2.68,5.93-3.74,1.09-.69,2.05-1.3,2.9-1.83,3.71-2.36,6.05-5.67,7.01-9.98,1.14-5.05.43-9.59-2.11-13.6ZM348.25,209.79l-.19-.04-.49-.11-2.05,9.1,8.33,1.87,1.29-5.74.76-3.36-7.65-1.71Z"
          className="charts-gray fill-gray-400 light:fill-gray-700"
          style={{
            strokeWidth: 0,
          }}
        />
        <g>
          <path
            d="M380.87,196.99l.65-.67c5.83-.81,10.92-4.99,12.62-11.02.22-.77.36-1.54.45-2.3l.27-.27,26.66,7.49s-7.06,30.52-40.3,35.16l-.35-28.39Z"
            className="charts-gray fill-gray-400 light:fill-gray-700"
            style={{
              opacity: 0.4,
              strokeWidth: 0,
            }}
          />
          <g
            style={{
              clipPath: "url(#clippath-3)",
            }}
          >
            <line
              x1={387.37}
              y1={233.72}
              x2={378.85}
              y2={185.41}
              style={{
                fill: "none",
                stroke: "#29313d",
                strokeMiterlimit: 10,
              }}
            />
            <line
              x1={392.72}
              y1={233.72}
              x2={384.2}
              y2={185.41}
              style={{
                fill: "none",
                stroke: "#29313d",
                strokeMiterlimit: 10,
              }}
            />
            <line
              x1={398.08}
              y1={233.72}
              x2={389.56}
              y2={185.41}
              style={{
                fill: "none",
                stroke: "#29313d",
                strokeMiterlimit: 10,
              }}
            />
            <line
              x1={402.25}
              y1={226.54}
              x2={393.73}
              y2={178.23}
              style={{
                fill: "none",
                stroke: "#29313d",
                strokeMiterlimit: 10,
              }}
            />
            <line
              x1={406.82}
              y1={226.54}
              x2={398.3}
              y2={178.23}
              style={{
                fill: "none",
                stroke: "#29313d",
                strokeMiterlimit: 10,
              }}
            />
            <line
              x1={412.17}
              y1={226.54}
              x2={403.66}
              y2={178.23}
              style={{
                fill: "none",
                stroke: "#29313d",
                strokeMiterlimit: 10,
              }}
            />
            <line
              x1={417.53}
              y1={226.54}
              x2={409.01}
              y2={178.23}
              style={{
                fill: "none",
                stroke: "#29313d",
                strokeMiterlimit: 10,
              }}
            />
            <line
              x1={422.89}
              y1={226.54}
              x2={414.37}
              y2={178.23}
              style={{
                fill: "none",
                stroke: "#29313d",
                strokeMiterlimit: 10,
              }}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
