import {useEffect, useState} from "react"
import Loading from "../../components/shared/Loading";
import Chart from "react-apexcharts";
import {useFetch} from "../../hooks/API/useFetch"
export default function LineChart(){
    const [data, setData]          = useState(null);
    const [dateArray, setDateArray]          = useState(null);
    const {loading, error, fetchData} = useFetch()

    const fetchChartData = async () => {
        const res = await fetchData('finance/wallets/transitions/fund');
        if (!res) return;
            const transactions  = res.transactions;
            const datesArray    = transactions.map(transaction => transaction.date);
            const balancesArray = transactions.map(transaction => transaction.balance);

            setDateArray(datesArray);
            setData(balancesArray);
            return;

    }

    useEffect(() => {
        if(error !== true && data === null){
            fetchChartData();
        }
    }, []);

    const chartData = {
        options: {
            colors:['#f8cc15'],
            xaxis: {
                type: "datetime",
                categories: dateArray,

                labels: {
                    formatter: function (value, timestamp, opts) {
                        return opts.dateFormatter(new Date(timestamp), "dd MMM HH:mm");
                    },
                },
            },
            title: {
                text: 'Monthly Invest ($)',
                align: 'left',
                offsetX: 20,
                offsetY: 10,
                style: {
                    fontSize: "16px",
                    color: "#f8cc15",
                },
            },
            chart: {
                type: "line",
                toolbar: {
                    show: false
                },
                background: "#232323",
                foreColor: "#fff",
            },
            stroke: {
                curve: "stepline"
            },
            grid: {
                xaxis: {
                    lines: {
                        show: false,
                    },
                },
                yaxis: {
                    lines: {
                        show: false,
                    },
                },
            },
        },

        series: [{
            name: "Total invest",
            data: data
        }],


    };

    return <>
    {loading ? (
      <Loading optClass={"block mx-auto mt-20"} />
    ) : (
      <div className="hidden md:block mt-14">
        <div className="flex items-center gap-x-3 ">
          <img src="../yellowStar.svg" className="w-10" alt="" />

          <span className="text-white text-5xl font-bold capitalize">
            Total Investment Chart
          </span>
        </div>
        <div className="w-full mx-auto mb-10 mt-3">
          <Chart
                options={chartData.options}
                series={chartData.series}
                type="line"
                width="100%"
                height={500}
          />
        </div>
      </div>
    )}
  </>
}