import React from "react";
import { RankingTableItem } from "./RankingTableItem";

export function RankingTable({ data, type }) {
  return (
    <div className="w-full max-h-[200px] overflow-auto">
      {data.map((item, id) => (
        <RankingTableItem data={item} key={id} type={type}/>
      ))}
    </div>
  );
}
