export default function Table({ theads, children }) {
  return (
    <table className="table">
      <thead>
        <tr className="text-white">
          {theads.map((item) => (
            <th>{item}</th>
          ))}
        </tr>
      </thead>
      <tbody className="text-white font-semibold">{children}</tbody>
    </table>
  );
}
