export default function UserRankTable({ userRank }) {
  return (
    <div className="w-full grid grid-cols-1 items-end sm:grid-cols-3 gap-x-10 gap-y-2 rounded-lg ">
      <div className="grid grid-cols-1 group relative z-20">
        <p className="bg-amber-500 rounded-t-lg text-lg text-black text-center font-bold p-1 px-2 capitalize">
          Rank
        </p>
        <p className="h-fit z-10 relative bg-[var(--indigo-purple-dark)] text-white font-bold text-xl text-center p-2.5 after:content-[''] after:absolute after:h-full after:w-full after:top-0  after:-z-10 after:bg-[var(--indigo-purple-dark)]  after:left-6  rounded-b-lg text-wrap  ">
          <span className="relative">
            {userRank.rank}
            <span className="text-sm absolute top-0 -right-5 !normal-case">
              th
            </span>
          </span>
        </p>
      </div>
      <div className="grid grid-cols-1 group relative z-20">
        <p className="bg-amber-500 rounded-t-lg text-lg text-black text-center font-bold p-1 px-2 capitalize">
          Active Date Account
        </p>
        <p className="h-fit z-10 relative bg-[var(--indigo-purple-dark)] text-white font-bold text-xl text-center p-2.5 after:content-[''] before:content-[''] after:absolute before:absolute after:h-full before:h-full after:w-full before:w-full after:top-0 before:top-0 before:-z-10 after:-z-10 after:bg-[var(--indigo-purple-dark)] before:bg-[var(--indigo-purple-dark)] after:left-6 before:right-6  rounded-b-lg text-wrap  ">
          <span className="relative">
            {userRank.year}/{userRank.month}
          </span>
        </p>
      </div>
      {/* <div className="grid grid-cols-1 group relative z-20">
      <p className="bg-amber-500 rounded-t-lg text-lg text-black text-center font-bold p-1 px-2 capitalize">
        Participation
      </p>
      <p className="h-fit z-10 relative bg-[var(--indigo-purple-dark)] text-white font-bold text-xl text-center p-2.5 after:content-[''] before:content-[''] after:absolute before:absolute after:h-full before:h-full after:w-full before:w-full after:top-0 before:top-0 before:-z-10 after:-z-10 after:bg-[var(--indigo-purple-dark)] before:bg-[var(--indigo-purple-dark)] after:left-6 before:right-6  rounded-b-lg text-wrap  ">
        <span className="relative">
          {userRank.year}/{userRank.month}
        </span>
      </p>
    </div> */}
      <div className="grid grid-cols-1 group relative z-20">
        <p className="bg-amber-500 rounded-t-lg text-lg text-black text-center font-bold p-1 px-2 capitalize">
          GF Factor
        </p>
        <p className="h-fit z-10 relative bg-[var(--indigo-purple-dark)] text-white font-bold text-xl text-center p-2.5 before:content-['']  before:absolute before:h-full before:w-full before:top-0 before:-z-10 before:bg-[var(--indigo-purple-dark)]  before:right-6  rounded-b-lg text-wrap  ">
          <span className="relative">
            {userRank.user_challenge.golden_factor}
          </span>
        </p>
      </div>
    </div>
  );
}
