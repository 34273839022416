export function PaymentMethod({ paymentMethod }) {
  // function handleSetMethod(value) {
  //   setWithdrawData((prevData) => ({ ...prevData, paymentMethod: value }));
  // }

  return (
    <div className="flex items-center gap-x-4 ml-7 my-2">
      <div
        // onClick={() => handleSetMethod("tether")}
        className={`w-24 h-24 bg-white rounded-xl p-2 ${
          paymentMethod === "trc20" ? "opacity-100" : "opacity-50"
        }`}
      >
        <img src="../trxLogo.svg" alt="trx logo" />
      </div>
      {/* <div
        onClick={() => handleSetMethod("Bank")}
        className={`h-24 rounded-xl bg-white p-3 ${
          paymentMethod === "Bank" ? "opacity-100" : "opacity-50"
        }`}
      >
        <img src="../Bank.png" className="w-full h-full" alt="" />
      </div> */}
    </div>
  );
}
