export default function RequestDetailsInput({
  title,
  value,
  disabled = false,
  type = "text",
  titleClass,
  formClass,
  optClass,
  ...props
}) {
  return (
    <label className={`form-control ${formClass}`}>
      <div className="label">
        <span className={`label-text text-slate-100 ${titleClass}`}>
          {title}:
        </span>
      </div>
      <input
        className={`input input-sm text-base  ${optClass} ${
          disabled
            ? "!bg-[#00000055] !text-slate-300 input-disabled border-0"
            : "input-bordered bg-slate-300 focus:bg-slate-100"
        }`}
        type={type}
        disabled={disabled}
        value={value}
        {...props}
      />
    </label>
  );
}
