import { useEffect, useState } from "react";
import useDidMount from "../../useDidMount";
import { useFetch } from "../../API/useFetch";
import {usePut} from "../../API/usePut"
import { useNavigate } from "react-router-dom";
const useUser = (pagination, query, userId) => {
  const [user, setUser] = useState(null);
  const [verificationTypes, setVerificationTypes] = useState([]);
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [userLinks, setUserLinks] = useState([]);
  const didMount = useDidMount(true);
  const [refresh, setRefresh] = useState(false);
  const {loading, error, fetchData} = useFetch()
  const { loading: putLoading, error: putError, handlePutData } = usePut()
  const navigate = useNavigate()
  const getUser = async (id) => {
    const res = await fetchData("users/" + id);
    if(!res) return;
    setUser(res.user);
    setVerificationTypes(res.verification_types);
    setRoles(res.roles);
    };

  const getUsers = async () => {
    const res = await fetchData(`users?page=${pagination}${query}`);
    setRefresh(false);
    if(!res) return;

    setUsers(res.users.data);
    setUserLinks(res.users);
  };

  useEffect(() => {
    if (
      pagination &&
      ((users.length === 0 && didMount) || (refresh && didMount))
    ) {
      getUsers();
    }
  }, [didMount, users.length, refresh]);

  useEffect(()=>{
    getUsers();
  },[pagination, query])

  useEffect(() => {
    if (userId && user === null) {
      getUser(userId);
    }
  }, [didMount, userId]);

  const updateUser = async (data, id) => {
    const _ = await handlePutData("users/" + id, data, "User successfully updated");
    navigate("/dashboard/admin/users")
  };

  return {
    error,
    loading,
    users,
    userLinks,
    verificationTypes,
    roles,
    updateUser,
    user,
    setRefresh,
    putLoading,
    putError
  };
};

export default useUser;
