import React from "react";
import { Outlet } from "react-router-dom";
import PageStatus from "../../components/PageStatus/PageStatus";

export default function TicketContainer() {
  return (
    <>
      <PageStatus
        category={"supports"}
        currentPage={"tickets"}
        logoStarColor="#F8CC15"
        pageStarColor="var(--indigo-purple)"
      />
      <div className="w-full bg-[var(--indigo-purple-dark)]  p-5 scrollWidth overflow-y-auto mt-5 border border-[var(--indigo-purple)] rounded-xl">
        <Outlet />
      </div>
    </>
  );
}
