import { useReferrals } from "../../hooks/Referral/useReferrals";
import BarChart from "../Dashboard/BarChart";
import CompactLayout from "./CompactInfo/CompactLayout";
import CompactSide from "./CompactInfo/CompactSide";
import Donut from "./Donut";
import MainTable from "./Tables/MainTable";
import WithdrawLayout from "./Withdraw/WithdrawLayout";
import { usePagination } from "../../hooks/usePagination";
const sidePartMapData = [
  {
    title: "Total Profit",
    objectName: "total_profit",
  },
  {
    title: "Profit Share",
    objectName: "challenge_profit_share_profit",
  },
  {
    title: "Investment Profit",
    objectName: "investment_profit",
  },
  {
    title: "Win-Win Profit",
    objectName: "win_win_profit",
  },
];

const initialFilter = {
  from_date: "",
  to_date: "",
};

const filterOptions = ["from_date", "to_date"];

export default function Referrals() {
  const {
    pagination,
    handleChangePagination,
    handleFilterChange,
    handleSubmitFilter,
    filter,
    query,
  } = usePagination(initialFilter, filterOptions);

  const {
    referralsTableLoading,
    walletLoading,
    loading,
    wallet,
    chartsData,
    referralByMonth,
    profitByMonth,
    donutChart,
    handleWithdraw,
    referralUsers,
    withdrawLoading,
  } = useReferrals(pagination, query);

  return (
    <>
      <div className="flex flex-col gap-5 mt-10">
        <CompactLayout data={chartsData?.header_part} loading={loading} />
        <WithdrawLayout
          data={wallet}
          loading={walletLoading}
          withdrawLoading={withdrawLoading}
          handleWithdraw={handleWithdraw}
        />

        <div className="w-full grid grid-cols-1 min-[1100px]:grid-cols-3  gap-5">
          <BarChart
            loading={loading}
            series={[
              {
                name: "User",
                type: "line",
                color: "#13807a",
                data: referralByMonth.y,
              },
            ]}
            xAxis={referralByMonth.x}
            title="Referral Per Month"
          />
          <Donut
            series={donutChart?.data}
            labels={donutChart?.legends}
            loading={loading}
          />
          <CompactSide
            data={chartsData?.side_part}
            mapData={sidePartMapData}
            loading={loading}
          />
          <BarChart
            loading={loading}
            series={[
              {
                name: "Profit",
                type: "line",
                color: "#5d35b0",
                data: profitByMonth.y,
              },
            ]}
            xAxis={profitByMonth.x}
            title="Profit Per Month"
          />
        </div>

        <MainTable
          data={referralUsers?.referral_users}
          handleChangePagination={handleChangePagination}
          loading={referralsTableLoading}
          filter={filter}
          handleFilterChange={handleFilterChange}
          handleSubmitFilter={handleSubmitFilter}
        />
      </div>
    </>
  );
}
