import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFetch } from "../../API/useFetch";

const useUserPayment = (pagination, query) => {
  const [transactions, setTransactions] = useState([]);
  const {loading, error, fetchData} = useFetch()
  const { id } = useParams();

  useEffect(() => {
    fetchTransitions();
  }, [pagination, query]);

  const fetchTransitions = async () => {
    const res = await  fetchData("finance/wallets/transactions/admin/" + id +`?page=${pagination}${query}`);
    if(!res) return;
    setTransactions(res);
  };

  return {
    error,
    loading,
    transactions,
  };
};

export default useUserPayment;
