import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Notify from "../components/shared/Notify";
import Menu from "../components/SidebarMenu/SidebarMenu";
import Container from "../components/Container";
import AuthGuard from "../pages/Auth/layouts/AuthGuard";
import { getChallenges } from "../store/slices/challenge";
import { useDispatch } from "react-redux";
import { getCountries } from "../store/slices/country";
import DashboardHeader from "../components/DashboardHeader";
import { useLogOut } from "../hooks/useLogOut";
import Breadcrumbes from "../components/Breadcrumbes";
import usePermissions from "../hooks/usePermission";

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [sideBar, setSidebar] = useState(false);
  const { loading, handleLogOut } = useLogOut();
  const { can } = usePermissions();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getChallenges());
    dispatch(getCountries());
    setSidebar(!(window.innerWidth <= 768));
  }, []);

  const handleSidebar = () => {
    setSidebar(!sideBar);
  };

  return (
    <>
      <AuthGuard>
        <Notify />
        <DashboardHeader
          sideBar={sideBar}
          handleSidebar={handleSidebar}
          logoutLoading={loading}
          handleLogOut={handleLogOut}
        />

        <div className="flex relative">
          <Menu
            sideBar={sideBar}
            handleSidebar={handleSidebar}
            logoutLoading={loading}
            handleLogOut={handleLogOut}
          />
          <Container>
            {can("read_ticket") && location.pathname !== "/" && (
              <Breadcrumbes />
            )}
            <Outlet />
          </Container>
        </div>
      </AuthGuard>
    </>
  );
}
