import { useState, useEffect } from "react";
import { getData } from "../utils/fetchData";
import { useParams } from "react-router-dom";
import {toast} from "react-toastify";
import { useFetch } from "./API/useFetch";
export function useAnalyze(){
    const [loading, setLoading] = useState(true);
    const [totals, setTotals] = useState(null);
    const [totalsArchive, setTotalsArchive] = useState(null);
    const [totalArchives, setTotalArchives] = useState(null);
    const [trades, setTrades] = useState(null);
    const [thisChallenge, setThisChallenge] = useState(null);
    const { id } = useParams();
    const {loading: tadeLoading, fetchData} = useFetch()
  
    const getUserChallenge = async(id) => {
        const res = await getData("challenges/user-challenges/"+id);
        if (res?.data){
          setThisChallenge(res.data.user_challenge);
          return;
        }

        toast.error(res?.data?.message || 'error fetching data');
    }
  
    async function getUserInformation() {
      try {
        const res = await getData(
          "challenges/user-challenges/account-information/update/" + id
        );
        if (res?.data.totals) {
          setTotals(res.data.totals[0]);
        }
      } catch (error) {
        return null;
      }
    }
  
    async function getUserInformationArchive() {
      try {
        const res = await getData(
          "challenges/user-challenges/account-information-archive/" + id
        );
        if (res?.data.total_archives) {
          setTotalsArchive(res.data.total_archives[res.data.total_archives.length - 1]);
          setTotalArchives(res.data.total_archives);
        }
      } catch (error) {
        return null;
      }
    }
  
    async function getUserTrades() {
        const res = await fetchData("challenges/user-challenges/trades/update/" + id);
        if (!res?.trades) return ;

        setTrades(res.trades);
    }

    useEffect(() => {
        if (thisChallenge === null) {
            getUserChallenge(id);
        }
    }, [thisChallenge, id]);

    useEffect(() => {
      if (totals == null) {
        getUserInformation();
      }
    }, [totals]);
  
    useEffect(() => {
      if (totalsArchive == null) {
        getUserInformationArchive();
      }
    }, [totalsArchive]);
  
    useEffect(() => {
      if (trades == null) {
        getUserTrades();
      } 
    }, [trades]);
  
    useEffect(() => {
      if (totals != null && totalsArchive != null && thisChallenge != null) {
        setLoading((x) => false);
      }
    }, [totals, totalsArchive, thisChallenge]);
    
    return {loading, totals, trades, totalsArchive, totalArchives, thisChallenge, tadeLoading} 
}