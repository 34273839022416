import React, { useState } from "react";
import { FaArrowRight, FaCheck } from "react-icons/fa6";
import InactiveTimeCountDown from "./InactiveTimeCountDown";

export function ChallengeType({ setData, challenges }) {
  function handleChange(type, order) {
    setData((prev) => ({
      ...prev,
      accountType: type,
      order: order - 1,
      step: 2,
    }));
  }
  return (
    <div className="-mb-32">
      {challenges &&
        challenges.map(
          (challenge) =>
            challenge.state !== 4 && (
              <ChallengeTypeOption
                img={
                  challenge.name !== "win-win"
                    ? `../${challenge.name}Plan.svg`
                    : "../winwinPlan.svg"
                }
                challengeData={challenge}
                func={handleChange}
              />
            )
        )}
    </div>
  );
}
function ChallengeTypeOption({ img, challengeData, func }) {
  const { name, order, state, activate_time } = challengeData;
  const [ended, setEnded] = useState(false);

  return (
    <div
      onClick={() => func(name, order)}
      className="w-full max-w-sm pr-10 my-4 group z-[50] relative cursor-pointer "
    >
      <div className="w-full relative group-hover:ml-10 transition-all duration-300">
        <div
          className={`w-full flex items-center gap-2 transition-all rounded-2xl px-5 py-3 md:py-6 z-10 relative ${
            state === 1
              ? "bg-[var(--indigo-purple-dark)] group-hover:bg-[var(--indigo-purple-hover)] "
              : "bg-gray-600"
          }`}
        >
          <img
            src={img}
            className={`max-w-[50px] ${state !== 1 && " opacity-70"}`}
            alt=""
          />
          <div
            className={`capitalize ${
              state === 1 ? "text-white" : "text-gray-300"
            }`}
          >
            <h4 className="text-xl md:text-3xl font-bold">{name}</h4>
            {state === 1 ? (
              <p className="font-light">Available now</p>
            ) : !activate_time ? (
              <span>Not Available</span>
            ) : (
              !ended && (
                <InactiveTimeCountDown
                  setEnded={setEnded}
                  activeTime={activate_time}
                />
              )
            )}
          </div>
        </div>
        <div
          className={`w-20 h-[calc(100%-2px)] ${
            state === 1 ? "bg-white" : "bg-gray-400"
          } rounded-r-2xl absolute top-[1px] -right-10 overflow-hidden`}
        >
          <div className="group-hover:left-10 left-0 duration-300 transition-all relative flex flex-row-reverse items-center justify-between p-1 w-full h-full">
            <FaArrowRight
              size={30}
              className={
                state === 1
                  ? "fill-[var(--indigo-purple-dark)] "
                  : "fill-gray-500"
              }
            />
            <FaCheck
              size={30}
              className={
                state === 1
                  ? "fill-[var(--indigo-purple-hover)]"
                  : "fill-gray-500"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
