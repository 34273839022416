import { useEffect, useState } from "react";
import { useFetch } from "../../API/useFetch";
import { useNavigate } from "react-router-dom";

// 1 = unassigned, 2 = assigned
export function useAdminTickets(getFrom = 1, pagination, query) {
  const [tickets, setTickets] = useState(null);
  const [filterOptionData, setFilterOptionData] = useState({});
  const { loading, error, fetchData } = useFetch();
  const navigate = useNavigate();

  async function getTickets() {
    const api = getFrom === 1 ? "admin/tickets/answer-list" : "admin/tickets";
    console.log(`${api}?page=${pagination}${query}`);

    const res = await fetchData(`${api}?page=${pagination}${query}`);

    if (!res) return;
    setFilterOptionData(res.state);
    setTickets(res.tickets);
  }

  async function assignToSelf(id) {
    const _ = await fetchData(
      "admin/tickets/answer/" + id,
      "Ticket assign to you successfully!"
    );
    getTickets();
  }

  function goToChat(id) {
    navigate("/admin/tickets/assigned/chats/" + id);
  }

  useEffect(() => {
    getTickets();
  }, [pagination, query]);

  return { error, loading, tickets, filterOptionData, assignToSelf, goToChat };
}
