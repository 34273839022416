import { useEffect, useState } from "react";
import { useFetch } from "../../API/useFetch";
import { usePut } from "../../API/usePut";
import { toast } from "react-toastify";
import { usePostImage } from "../../API/usePostImage";
import { useNavigate } from "react-router-dom";
function formatTextSpaces(value) {
  const formattedText = value.replace(/\n/g, "<?br>");
  return formattedText;
}
export function useChat(id) {
  const [chat, setChat] = useState(null);
  const { loading, fetchData } = useFetch();
  const { loading: closeTicketLoading, fetchData: closeTicket } =
    useFetch(false);
  const { loading: newMessageLoading, handlePutData } = usePut();
  const { loading: fileUploadLoading, handlePostImage } = usePostImage();

  const navigate = useNavigate();

  async function getChatData() {
    const res = await fetchData(`admin/tickets/` + id);

    if (!res) return;

    setChat(res.ticket);
  }

  async function pushMess(message) {
    if (message.message == null) {
      toast.error("Please fill input field!");
      return;
    }

    const formData = {
      message: formatTextSpaces(message.message),
    };
    const res = await handlePutData(
      "admin/tickets/send-message/" + id,
      formData
    );
    if (!res) return;
    setChat((prev) => ({ ...prev, messages: [...prev.messages, res.message] }));
  }

  const pushFile = async (file) => {
    if (file.size > 1048576) {
      toast.error("The uploaded file exceeds the maximum size of 1 MB.");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("ticket_id", id);
    const res = await handlePostImage("admin/tickets/img", formData);

    if (res?.message) {
      toast.success("file uploaded Successfully!");
      setChat((prev) => ({
        ...prev,
        messages: [...prev.messages, res.message],
      }));
    }
  };

  async function handleClose() {
    const res = await closeTicket("admin/tickets/close/" + id, true);
    if (!res) return;
    navigate(-1);
  }

  useEffect(() => {
    getChatData();
  }, []);

  return {
    loading,
    fileUploadLoading,
    closeTicketLoading,
    newMessageLoading,
    chat,
    pushMess,
    pushFile,
    handleClose,
  };
}
