import { useState, useEffect } from "react";
import { useFetch } from "./API/useFetch";
export function useMyChallenge() {
  const [userChallenges, setUserChallenges] = useState([]);
  const {loading, error, fetchData} = useFetch()

  async function getChallengesData() {
    const res = await fetchData("challenges/user-challenges");
    if (!res) return;
    setUserChallenges(res.user_challenges);
  }

  useEffect(() => {
    getChallengesData();
  }, []);

  return { error, loading, userChallenges };
}
