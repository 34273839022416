import BannerIndex from "./BannerIndex";
import BannerCreate from "./BannerCreate";
import Loading from "../../../components/shared/Loading";
import PageStatus from "../../../components/PageStatus/PageStatus";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import TableBackground from "../../../components/AdminShared/TableBackground";
import { useBanner } from "../../../hooks/Admin/useBanner";

const BannerLayout = () => {
  const { loading, error, setType, deleteBanners, getBanners, type, banners } =
    useBanner();
  const renderSwitch = (param, banners) => {
    switch (param) {
      case "index":
        return (
          <>
            {error ? (
              <ErrorMessage text={error} />
            ) : loading ? (
              <div className="w-full text-center">
                <Loading />
              </div>
            ) : (
              <BannerIndex
                banners={banners}
                setType={setType}
                deleteBanners={deleteBanners}
              />
            )}
          </>
        );
      case "create":
        return <BannerCreate setType={setType} getBanners={getBanners} />;
      default:
        return <BannerIndex banners={banners} />;
    }
  };

  return (
    <>
      <PageStatus
        category={"Dashboard"}
        currentPage={"Banner"}
        logoStarColor="#F8CC15"
      />
      <TableBackground>
        <div className="content">{renderSwitch(type, banners)}</div>
      </TableBackground>
    </>
  );
};

export default BannerLayout;
