import RequestDetailsInput from "../../../components/AdminShared/RequestsDetailsInput";
const PlanUpdate = ({ setType, handleUpdate, formData, setFormData }) => {
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <>
      <div className=" flex  items-center justify-between title text-center text-white mb-5">
        <h3 className="text-2xl text-center w-full ">Trader update</h3>
        <button
          onClick={() => setType("index")}
          className="btn btn-secondary btn-outline"
        >
          back
        </button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
        <RequestDetailsInput
          title="Month"
          name="month"
          optClass="!input-md"
          titleClass="text-lg"
          value={formData.month}
          onChange={handleInputChange}
          disabled
        />
        <RequestDetailsInput
          title="Fund"
          name="fund"
          optClass="!input-md"
          titleClass="text-lg"
          value={formData.fund}
          onChange={handleInputChange}
        />
        <RequestDetailsInput
          title="Total fund under managment"
          name="total_fund_under_managment"
          optClass="!input-md"
          titleClass="text-lg"
          value={formData.total_fund_under_managment}
          onChange={handleInputChange}
        />
        <RequestDetailsInput
          title="Avg total profit "
          name="avg_total_profit"
          optClass="!input-md"
          titleClass="text-lg"
          value={formData.avg_total_profit}
          onChange={handleInputChange}
        />
      </div>
      <button
        className="btn btn-outline btn-success mt-10 mb-5 "
        onClick={handleUpdate}
      >
        submit
      </button>
    </>
  );
};

export default PlanUpdate;
