import React, { useState } from "react";
import PlanStepIndicator, { IndicatorItem } from "./PlanStepIndicator";
import PageStatus from "../../components/PageStatus/PageStatus";
import { ChallengeType } from "./ChallengeType";
import { PlanInfo } from "./PlanInfo";
import { useSelector } from "react-redux";
import Loading from "../../components/shared/Loading";

const tempData = {
  step: 1,
  order: 0,
};

export default function NewChallenge() {
  const [data, setData] = useState(tempData);
  const { challenges } = useSelector((state) => state.challenge);

  return (
    <div className="relative">
      <div className="block pb-5">
        <PageStatus
          category={"challenges"}
          currentPage={"New Challenge"}
          pageStarColor={"#11092E"}
          logoStarColor="#ebc216"
        />
        <PlanStepIndicator headerText={"select your plan"}>
          <IndicatorItem
            num={1}
            text={"select challenge type"}
            currentStep={data.step}
            setData={setData}
          />
          <IndicatorItem
            num={2}
            text={"pay"}
            currentStep={data.step}
            setData={setData}
          />
          <IndicatorItem
            num={3}
            text={`show your \n best`}
            last={true}
            currentStep={data.step}
            setData={setData}
          />
        </PlanStepIndicator>
        {challenges[0] ? (
          <>
            {data.step === 1 && (
              <ChallengeType setData={setData} challenges={challenges} />
            )}
            {data.step === 2 && (
              <PlanInfo setData={setData} data={data} challenges={challenges} />
            )}
          </>
        ) : (
          <div className="w-full text-center">
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
}
