import React, { useState } from "react";
import Loading from "../../../components/shared/Loading";
import { Link } from "react-router-dom";
import useUser from "../../../hooks/Admin/User/useUser";
import { usePagination } from "../../../hooks/usePagination";
import BanModal from "./Ban/BanModal";
import TableLayout from "../../../components/shared/DarkTable/TableLayout";
import TableFilters from "../../../components/shared/DarkTable/TableFilters";
import TableContent from "../../../components/shared/DarkTable/TableContent";
import Filter from "../../../components/shared/DarkTable/Filter";
const initialFilter = {
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
};
const filterOptions = ["firstname", "lastname", "email", "phone"];
export default function Users() {
  const {
    pagination,
    handleFilterChange,
    query,
    handleSubmitFilter,
    handleChangePagination,
    filter,
  } = usePagination(initialFilter, filterOptions);
  const { error, loading, users, userLinks } = useUser(pagination, query);
  const [selectedUser, setSelectedUser] = useState(null);

  return (
    <>
      {selectedUser && (
        <BanModal user={selectedUser} setUser={setSelectedUser} />
      )}

      <TableLayout
        paginationData={userLinks}
        paginationClick={handleChangePagination}
        pagination={!loading}
      >
        <TableFilters title="Users" handleSubmitFilter={handleSubmitFilter}>
          <Filter
            title="First Name"
            value={filter.firstname}
            name="firstname"
            onChange={handleFilterChange}
            placeholder="First Name"
          />
          <Filter
            title="Last Name"
            value={filter.lastname}
            name="lastname"
            onChange={handleFilterChange}
            placeholder="Last Name"
          />
          <Filter
            title="Email"
            value={filter.email}
            s
            name="email"
            onChange={handleFilterChange}
            placeholder="Email"
          />

          <Filter
            title="Phone"
            value={filter.phone}
            name="phone"
            onChange={handleFilterChange}
            placeholder="Phone"
            type="number"
          />
        </TableFilters>
        <TableContent
          loading={loading}
          loadingPlaceHolder={
            <div className="w-full flex items-center justify-center h-[700px]">
              <Loading />
            </div>
          }
          isDataAvailable={!!users[0]}
          noDataTitle="Data Not Found!"
          headers={["Id", "Name", "Email", "Role", "Phone", "Actions"]}
        >
          {users?.map((user) => (
            <tr>
              <td>{user.id}</td>
              <td>
                <p>{user.first_name + " " + user.last_name}</p>
              </td>
              <td>
                <p>{user.email}</p>
              </td>
              <td>
                <p>{user.roles[0]?.title}</p>
              </td>
              <td>
                <p>{user.phone}</p>
              </td>
              <td className="grid grid-cols-1 2xl:grid-cols-4 gap-2 min-w-[200px] [&>*:hover]:!bg-[--indigo-purple] [&>*:hover]:border-light-border [&_:nth-child(odd)]:light:bg-gray-300 [&_:nth-child(odd)]:bg-gray-700 [&_:nth-child(even)]:bg-blue-500/20 ">
                <Link to={`update/${user.id}`} className="btn btn-sm btn-ghost">
                  Update
                </Link>
                <Link
                  to={`add-challenge/${user.id}`}
                  className="btn btn-sm btn-ghost"
                >
                  Add Challenge
                </Link>
                <Link
                  to={`certificates/${user.id}`}
                  className="btn btn-sm btn-ghost"
                >
                  Certificates
                </Link>
                <Link
                  to={`payments/${user.id}`}
                  className="btn btn-sm btn-ghost"
                >
                  Payments
                </Link>
                <Link
                  to={`add-payment/${user.id}`}
                  className="btn btn-sm btn-ghost"
                >
                  Add Payment
                </Link>
                <button
                  onClick={() => setSelectedUser(user)}
                  className="btn btn-sm btn-ghost"
                >
                  {user.status === 2 ? "Unblock" : "Ban"}
                </button>
              </td>
            </tr>
          ))}
        </TableContent>
      </TableLayout>
    </>
  );
}
