import { useNavigate } from "react-router-dom";

export default function Bonus() {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate("referrals")}
      className="w-full h-full min-[1100px]:max-[1400px]:row-start-2 min-[1100px]:max-[1400px]:col-start-2 flex items-center gap-3 bg-gray-800 border border-indigo-400 light:bg-gray-200 light:shadow-[0_0_10px_-5px] light:shadow-gray-900/40 rounded-xl p-5 light:border-none light:hover:bg-gray-300 hover:bg-primaryPurple hover:cursor-pointer transition-colors md:flex-col max-md:justify-between"
    >
      <img
        src="/gift3d.webp"
        className="w-full max-[370px]:hidden max-w-[100px] min-[600px]:max-w-[150px] min-[800px]:max-w-[220px]"
        alt=""
      />
      <div className="md:text-center">
        <p className="text-lg sm:text-2xl text-indigo-400 font-semibold min-[1100px]:mt-2">
          Invite friends, get rewards!
        </p>
        <p className="sm:text-lg text-gray-400 light:text-gray-600">
          Join our referral program and unlock rewards
        </p>
      </div>
    </div>
  );
}
