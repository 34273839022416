import PageStatus from "../../components/PageStatus/PageStatus";
import { TransactionResult } from "../../components/shared/TransactionResult";

export default function DepositTransactionUnSuccess() {
  return (
    <>
      <PageStatus
        category={"account"}
        currentPage={"deposits"}
        logoStarColor="#F8CC15"
      />
      <div className="w-full flex justify-center">
        <TransactionResult
          headerText="Your payment has been 
            Failed"
          firstBtnText="GO TO NEW CHALLENGE"
          linkFirst="/dashboard/newChallenge"
          secBtnText="back"
          linkSecond="/deposits"
          imgUrl="../../transactionUnsuccess.png"
        />
      </div>
    </>
  );
}
