import TableBackground from "../../../components/AdminShared/TableBackground";
import PageStatus from "../../../components/PageStatus/PageStatus";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import Loading from "../../../components/shared/Loading";
import RequestDetailsForm from "../../../components/AdminShared/RequestDetailsForm";
import RequestDetailsInput from "../../../components/AdminShared/RequestsDetailsInput";
import RequestDetailsButton, {
  RequestDetailsButtonLayout,
} from "../../../components/AdminShared/RequestsDetailsButton";
import { usePaymentRequests } from "../../../hooks/usePaymentRequests";

export default function PaymentRequests() {
  const { loading, error, paymentId, setPaymentId, handleSubmit } =
    usePaymentRequests();
  return (
    <>
      <PageStatus
        category={"Finance"}
        currentPage={"Payment requests"}
        logoStarColor="#F8CC15"
      />
      <TableBackground>
        {loading ? (
          <div className="w-full text-center">
            <Loading />
          </div>
        ) : (
          <>
            <p className="text-neutral-300 my-2">
              This input field is used to verify the status of a payment. Enter
              the payment ID to check if the payment was successful. the system
              will return a result indicating whether the payment has been
              processed successfully or if there was an issue.
            </p>
            <RequestDetailsForm>
              <RequestDetailsInput
                title="Payment ID"
                type="number"
                value={paymentId}
                onChange={(e) => setPaymentId(e.target.value)}
                optClass="font-semibold"
              />
            </RequestDetailsForm>
            {error && <ErrorMessage text={error} optcClass="mt-2" />}
            <RequestDetailsButtonLayout>
              <RequestDetailsButton
                title="submit"
                onclick={handleSubmit}
                optClass="btn-success"
              />
            </RequestDetailsButtonLayout>
          </>
        )}
      </TableBackground>
    </>
  );
}
