import { format } from "date-fns";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { getData } from "../../utils/fetchData";
import ChallengeStatistics from "../../utils/ChallengeStatistics";
import { useAnalyze } from "../../hooks/useAnalyzePanel";
import { formatComma } from "../../utils/formatNumber";
import { FaInfinity } from "react-icons/fa6";
import Loading from "../../components/shared/Loading";
const defaultOptions = {
  // border radius
  stroke: {
    lineCap: "round",
  },

  plotOptions: {
    radialBar: {
      // unProgressed area color
      track: {
        background: "#383838",
      },
      // bar
      hollow: {
        size: "70%",
      },
      startAngle: -90,
      endAngle: 90,
      dataLabels: {
        show: true,

        //  'series' style
        value: {
          color: "transparent",
        },
      },
    },
  },
  labels: [""],
  chart: {
    type: "radialBar",
  },
};

export default function RadialChart({ data, loading }) {
  if (loading)
    return (
      <div className=" grid min-[940px]:grid-cols-2 lg:grid-cols-3 gap-5">
        {Array.from({ length: 3 }).map((_, i) => (
          <LoadingBox />
        ))}
      </div>
    );
  const { draw_down, draw_down_daily, trading_period } = data;

  const StatusItems = [
    {
      options: {
        ...defaultOptions,

        // progressed area color
        fill: {
          colors:
            maxPermitted(
              draw_down.max_allowed,
              Math.abs(draw_down.draw_down)
            )[0] < 100
              ? ["#44ff00"]
              : ["#f00"],
        },
      },
      series: maxPermitted(
        draw_down.max_allowed,
        Math.abs(draw_down.draw_down)
      ),
      title: "Overall Drawdown",
      centerValue: `$${formatComma(draw_down.draw_down)}`,
      mainText: "Max Value Reached",
      footer: `Max Allowed: `,
      allowed: `$${formatComma(draw_down.max_allowed)}`,
    },
    {
      options: {
        ...defaultOptions,

        // progressed area color
        fill: {
          colors: !draw_down_daily
            ? ["#00f"]
            : maxPermitted(
                draw_down_daily.max_allowed,
                Math.abs(draw_down_daily.draw_down)
              )[0] < 100
            ? ["#00f"]
            : ["#f00"],
        },
      },
      series: !draw_down_daily
        ? [NaN]
        : maxPermitted(
            draw_down_daily.max_allowed,
            Math.abs(draw_down_daily.draw_down)
          ),
      title: "Daily Drawdown",
      centerValue: `${
        draw_down_daily ? `$${formatComma(draw_down_daily.draw_down)}` : "---"
      }`,
      mainText: "Max Value Reached",
      footer: `Max Allowed: `,
      allowed: !draw_down_daily
        ? "No limit"
        : `$${draw_down_daily.max_allowed}`,
    },
    {
      options: {
        ...defaultOptions,

        // progressed area color
        fill: {
          colors: ["#FF7A00"],
        },
      },
      series: trading_period
        ? maxPermitted(
            trading_period.max_allowed,
            trading_period.trading_period
          )
        : [NaN],
      title: "Trading Period",
      centerValue: `${
        trading_period ? `${trading_period.trading_period} Days` : "---"
      }`,
      mainText: "Max value reached",
      footer: "Max Allowed: ",
      allowed: `${
        trading_period?.max_allowed
          ? `${trading_period?.max_allowed} Days`
          : "No Limit"
      }`,
    },
  ];

  function maxPermitted(max, num) {
    const res = (parseInt(num) / parseInt(max)) * 100;
    if (res === Infinity) {
      return [100];
    }
    return [res];
  }

  return (
    <div className=" grid min-[940px]:grid-cols-2 lg:grid-cols-3 gap-5">
      {data && StatusItems.map((item) => <AnalysisStatusItem data={item} />)}
    </div>
  );
}

function AnalysisStatusItem({ data }) {
  if (!data) return null;
  return (
    <div className="dashboard-box flex flex-col text-center relative !py-14">
      <span className="text-lg text-nowrap text-second absolute top-8  translate-x-1/2 right-1/2 font-semibold">
        {data?.title}
      </span>
      <div className="-mb-8">
        <ReactApexChart
          options={data.options}
          series={data.series}
          height={300}
          type="radialBar"
        />
      </div>
      <span className="text-white capitalize absolute translate-x-1/2 right-1/2 bottom-[calc(50%+20px)] translate-y-1/2 font-bold text-2xl">
        {data.centerValue}
      </span>
      <div className="h-full flex flex-col justify-end">
        {data.title === "trading periods" ? (
          <span className="capitalize  text-md lg:text-lg font-bold w-full text-center text-orange-500">
            {data.series.at(0) === 0 && "Your time has been finished."}
          </span>
        ) : (
          <span className="capitalize text-md lg:text-lg font-bold w-full text-center text-red-500">
            {data.series.at(0) >= 100 && "Reached to max allowed limit"}
          </span>
        )}
        <p>
          <span className="capitalize text-green-500 font-bold">
            {data.footer}
          </span>
          <br />
          <span className="capitalize text-white">{data.allowed}</span>
        </p>
      </div>
    </div>
  );
}

function LoadingBox() {
  return (
    <div className="dashboard-box h-[400px] flex items-center justify-center">
      <Loading optClass="opacity-30" />
    </div>
  );
}
