import React from "react";
import PageStatus from "../../../components/PageStatus/PageStatus";
import Pagination from "../../../components/AdminShared/Pagination";
import Loading from "../../../components/shared/Loading";
import { Link } from "react-router-dom";
import useUserChallenge from "../../../hooks/Admin/useUserChallenge";
import TableBackground from "../../../components/AdminShared/TableBackground";
import Table from "../../../components/AdminShared/Table";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import { usePagination } from "../../../hooks/usePagination";

export default function UserChallenges() {
  const { pagination, handleChangePagination} = usePagination()
  const { error, loading, challenges, challengeLinks } =
    useUserChallenge(pagination);

  return (
    <>
      <PageStatus
        category={"Challenge"}
        currentPage={"User Challenges"}
        logoStarColor="#F8CC15"
      />
      <TableBackground>
        {error ? (
          <ErrorMessage />
        ) : (
          <div className="content mx-5 py-5 text-center text-white ">
            <div className=" flex  items-center justify-between title text-center text-white mb-5">
              <h3 className="text-2xl text-center w-full ">
                User Challenges Index
              </h3>
            </div>
           { loading ? (
          <div className="w-full flex items-center justify-center h-[600px]">
            <Loading />
          </div> ) : (
              <Table
                theads={[
                  "Id",
                  "User",
                  "State",
                  "Phase",
                  "Challenges",
                  "Balance",
                  "Start time",
                  "End time",
                  "Actions",
                ]}
              >
                {challenges.map((challenge) => (
                  <tr>
                    <td>{challenge.id}</td>
                    <td>
                      <p>
                        {challenge.user.first_name +
                          " " +
                          challenge.user.last_name}
                      </p>
                    </td>
                    <td>
                      <p>{challenge.state_type_name}</p>
                    </td>
                    <td>
                      <p>{challenge.phase_type_name}</p>
                    </td>
                    <td>
                      <p>{challenge.challenge_type_name}</p>
                    </td>
                    <td>
                      <p>{challenge.balance}$</p>
                    </td>
                    <td>
                      <p>{challenge.start_time}</p>
                    </td>
                    <td>
                      <p>{challenge.end_time}</p>
                    </td>
                    <th>
                      <Link
                        to={`/dashboard/my-challenges/analyze/${challenge.id}`}
                        className="btn btn-primary btn-outline  btn-sm mr-2"
                      >
                        Stats
                      </Link>
                      {/* */}
                    </th>
                  </tr>
                ))}
              </Table> 
            )}
          </div>
        )}
        
        {challengeLinks && (
          <Pagination
            handleOnClick={handleChangePagination}
            data={challengeLinks}
          />
        )}
      </TableBackground>
    </>
  );
}
