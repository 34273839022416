export default function FilterOptions({
  title = "",
  name = "",
  value,
  onChange,
  options = null,
  defaultOption = "All",
  defaultDisable = false,
  titleClass = "",
  optClass = "",
  dontUseKey = false,
  children,
}) {
  return (
    <label htmlFor={name} className="form-control">
      <div className="label">
        <span className={`label-text text-second ${titleClass}`}>{title}:</span>
      </div>
      <select
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        defaultValue=""
        className={`referral-filter select select-sm bg-gray-700 light:bg-gray-400/50 text-gray-400 !cursor-pointer  ${optClass}`}
      >
        <option disabled={defaultDisable} value="">
          {defaultOption}
        </option>

        {options
          ? Object.entries(options).map(([key, value]) => (
              <option key={key} value={dontUseKey ? value : key}>
                {value}
              </option>
            ))
          : children}
      </select>
    </label>
  );
}
