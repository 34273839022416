import React, { useEffect, useState } from "react";
import calculateAvailableTime from "../../utils/calculateAvailableTime";

const InactiveTimeCountDown = ({
  activeTime,
  textSize = "text-base",
  typeSkeleton = true,
}) => {
  const [availableTime, setAvailableTime] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      const calcTime = calculateAvailableTime(activeTime);
      setAvailableTime(calcTime);
    }, 1000);

    return () => clearInterval(interval);
  }, [availableTime]);

  return availableTime ? (
    <div className="flex items-center justify-center gap-1 -mb-1">
      <div className="flex items-center">
        <span className={`countdown font-mono ${textSize}`}>
          <span style={{ "--value": availableTime.day }}></span>
        </span>
        days
      </div>
      <span className={`countdown ${textSize}`}>
        <span style={{ "--value": availableTime.hour }}></span>:
        <span style={{ "--value": availableTime.min }}></span>:
        <span style={{ "--value": availableTime.seconds }}></span>
      </span>
    </div>
  ) : typeSkeleton ? (
    <span className="w-28 h-6 block skeleton bg-gray-300/10 rounded-md" />
  ) : (
    <span className="w-full h-8 block" />
  );
};

export default InactiveTimeCountDown;
