import { createSlice } from '@reduxjs/toolkit';
import { authActions } from './auth';
import { getToken } from '../../utils/getToken';
const baseUrl = process.env.REACT_APP_API_BASE_URL;
// import { getData } from '../../utils/fetchData';
// utils

// ----------------------------------------------------------------------

const initialState = {
    activeChallenges: null,
    inactiveChallenges: null,
  };
  
  const userChallengeReducer = createSlice({
    name: 'userChallenge',
    initialState,
    reducers: {
      activeChallenge(state, action) {
        state.activeChallenges = action.payload;
      },
      inactiveChallenges(state, action){
        state.inactiveChallenges = action.payload;
      }

    },
  });
  
  export const userChallengeActions = userChallengeReducer.actions;
  export default userChallengeReducer;

  export  function  getUserChallenges() {
    return async (dispatch) => {
      let response;
    const userToken = getToken()
  try {
    let token = "Bearer " + userToken;
    response = await fetch(`${baseUrl}/api/user-challenges`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (!response.ok) {
      console.log("Failed to fetch data");
      if (response && response.status === 401) {
        await dispatch(authActions.logout())
      }
    }

    const jsonData = await response.json();
    if (jsonData.userChallenges) {
      const userChallenges = jsonData.userChallenges;

      const activeData = [];
      const inactiveData = [];

      userChallenges.forEach(item => {
        if (item.status === 'active') {
          activeData.push(item);
        } else if (item.status === 'inactive') {
          inactiveData.push(item);
        }
      });
          dispatch(userChallengeActions.activeChallenge(activeData));

          dispatch(userChallengeActions.inactiveChallenges(inactiveData));
  }
  } catch (error) {
    if (response && response.status === 401) {
      await dispatch(authActions.logout())

    }

    return null;
  }
   
}
  }




