import { useEffect, useState } from "react";
import {useFetch} from "../API/useFetch"
export function useWithdrawRequest() {
  const [userSelected, setUserSelected] = useState(null);
  const [withdrawRequests, setWithdrawRequests] = useState(null);
  const [error, setError] = useState(false);
  const {loading, fetchData} = useFetch(false)

  async function getRequestWithdraws() {
    const res = await fetchData("finance/withdraws");
    if (!res) return;
    if (res.withdraw_requests.length === 0) {
      setError((prev) => "Data not found!");
    } else {
      setWithdrawRequests(res.withdraw_requests);
    }
  }

  useEffect(() => {
    if (withdrawRequests == null) {
      getRequestWithdraws();
    }
  }, [withdrawRequests]);

  return {
    loading,
    error,
    userSelected,
    setUserSelected,
    withdrawRequests,
    getRequestWithdraws,
  };
}
