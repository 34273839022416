import { useEffect, useState } from "react";
import useDidMount from "./useDidMount";
import { useFetch } from "../hooks/API/useFetch";
const useCertificate = () => {
  const [achievementCertificates, setAchievementCertificates] = useState(null);
  const [profitCertificates, setProfitCertificates] = useState(null);
  const didMount = useDidMount(true);
  const { loading, error, fetchData } = useFetch();

  const getCertificates = async () => {
    const res = await fetchData("users/certificates");
    if (!res) return;

    setAchievementCertificates(res.achievement_certificates);
    setProfitCertificates(res.profit_certificates);
  };

  useEffect(() => {
    if (achievementCertificates === null && didMount) {
      getCertificates();
    }
  }, [achievementCertificates, profitCertificates]);
  return {
    achievementCertificates,
    profitCertificates,
    loading,
    error,
  };
};

export default useCertificate;
