import { useState } from "react";

export function usePagination(initialFilter, filterOptions){
    const [query, setQuery] = useState("")
    const [pagination, setPagination] = useState(1)
    const [filter, setFilter] = useState(initialFilter)

    function handleSubmitFilter(e) {
        e.preventDefault();
        let query = "";
    
        filterOptions.forEach(element => {
           if (filter[element]) {
            query += `&${element}=${filter[element]}`;  
           } 
        });
        
        setPagination(1);
        setQuery(query);
      }

      function handleFilterChange(e) {
        if (e === "reset") {
            setFilter(initialFilter)
        }else{
            setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
        }
      }

      function handleChangePagination(e) {
        setPagination(e.selected + 1)
      }

      return { pagination, handleFilterChange, query, handleSubmitFilter, handleChangePagination, filter }
}