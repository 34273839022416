import { useEffect, useState } from "react";
import { getData, putData } from "../../utils/fetchData";
import { toast } from "react-toastify";
const initialFormData = {
  id: null,
  name: null,
  rank: null,
  gf_factor: null,
  nation: null,
};
export function useTopTraders() {
  const [type, setType] = useState("index");
  const [traders, setTraders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const getTraders = async () => {
    setLoading((prev) => true);

    const res = await getData("top-trader");
    if (res?.data) {
      setTraders(res.data.top_traders);
    } else {
      toast.error(res?.data.message || "Unexpected error!");
      setError((prev) => "Error fetching data!");
    }

    setLoading((prev) => false);
  };
  const handleUpdate = async () => {
    setLoading((prev) => true);

    const res = await putData("top-traders/" + formData.id, formData);
    if (res?.data) {
      toast.success("successfully updated!");
      setType("index");
      getTraders();
    } else {
      toast.error(res?.data.message || "Unexpected error!");
      setLoading((prev) => false);
    }
  };

  useEffect(() => {
    getTraders();
  }, []);
  return {
    loading,
    error,
    traders,
    type,
    setType,
    setFormData,
    formData,
    handleUpdate,
  };
}
