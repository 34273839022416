import React from "react";
import Loading from "../../../components/shared/Loading";
import GoldenTopTraderIndex from "./GoldenTopTraderIndex";
import GoldenTopTraderUpdate from "./GoldenTopTraderUpdate";
import PageStatus from "../../../components/PageStatus/PageStatus";
import TableBackground from "../../../components/AdminShared/TableBackground";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import { useGoldenTopTrader } from "../../../hooks/Admin/useGoldenTopTrader";

const GoldenTopTraderLayout = () => {
  const {
    loading,
    error,
    type,
    setType,
    formData,
    setFormData,
    traders,
    handleUpdate,
  } = useGoldenTopTrader();
  const renderSwitch = (param, traders) => {
    switch (param) {
      case "index":
        return (
          <GoldenTopTraderIndex
            traders={traders}
            setType={setType}
            setFormData={setFormData}
          />
        );
      case "update":
        return (
          <GoldenTopTraderUpdate
            setType={setType}
            formData={formData}
            setFormData={setFormData}
            handleUpdate={handleUpdate}
          />
        );

      default:
        return <GoldenTopTraderIndex traders={traders} />;
    }
  };

  return (
    <>
      <PageStatus
        category={"Top Traders"}
        currentPage={"Golden top traders"}
        logoStarColor="#F8CC15"
      />

      <TableBackground>
        {loading ? (
          <div className="w-full text-center">
            <Loading />
          </div>
        ) : error ? (
          <ErrorMessage text={error} />
        ) : (
          <div className="content">{renderSwitch(type, traders)}</div>
        )}
      </TableBackground>
    </>
  );
};

export default GoldenTopTraderLayout;
