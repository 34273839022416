import React from 'react'

function Numbers() {
  return (
    <svg className='circle-top-numbers' width="1490" height="1493" viewBox="0 0 1490 1493" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.97">
<path d="M693.742 107.232L697.342 105.096C697.5 104.99 697.703 105.021 697.808 105.201C697.883 105.359 697.83 105.532 697.65 105.637L694.05 107.796C693.998 107.818 693.945 107.818 693.892 107.818C693.787 107.818 693.659 107.796 693.607 107.66C693.532 107.532 693.554 107.329 693.735 107.224L693.742 107.232ZM697.192 105.351C697.192 105.193 697.349 105.013 697.5 105.013C697.703 105.013 697.83 105.193 697.83 105.351V122.672C697.83 122.852 697.703 123.003 697.5 123.003C697.342 123.003 697.192 122.845 697.192 122.672V105.351Z" fill="#5EB0EF"/>
<path d="M711.824 112.744C712.981 113.903 713.575 115.444 713.575 116.964C713.575 118.483 712.981 120.055 711.824 121.235C710.636 122.394 709.096 122.988 707.578 122.988H707.555C706.037 122.988 704.496 122.424 703.339 121.235C703.211 121.107 703.211 120.897 703.339 120.769C703.467 120.641 703.677 120.641 703.805 120.769C704.857 121.799 706.195 122.311 707.563 122.311C708.93 122.311 710.313 121.799 711.343 120.739C712.395 119.709 712.914 118.347 712.914 116.956C712.914 115.565 712.403 114.256 711.373 113.196C710.321 112.165 708.983 111.654 707.615 111.654C707.488 111.654 707.382 111.601 707.307 111.496C707.307 111.473 707.285 111.473 707.285 111.473C707.157 111.345 707.157 111.112 707.285 110.984L712.478 105.63H704.534C704.354 105.63 704.203 105.472 704.203 105.291C704.203 105.111 704.361 104.96 704.534 104.96H713.252C713.432 104.96 713.582 105.111 713.582 105.291C713.582 105.344 713.582 105.397 713.56 105.449C713.537 105.502 713.507 105.554 713.485 105.577L708.186 111.007C709.524 111.135 710.839 111.699 711.839 112.729L711.824 112.744Z" fill="#5EB0EF"/>
</g>
<g opacity="0.81">
<path d="M741.223 49.3514C741.223 47.945 741.524 46.3807 742.238 45.1398C742.952 43.8838 744.147 42.9588 745.86 42.8835C745.965 42.8835 746.056 42.9588 746.056 43.0791C746.056 43.1693 745.98 43.2596 745.89 43.2596C744.297 43.3348 743.267 44.1546 742.561 45.3203C742.027 46.2303 741.742 47.3208 741.637 48.3963C742.185 47.3058 743.32 46.5763 744.635 46.5763C746.499 46.5763 748.025 48.0804 748.032 49.9456C748.017 51.8258 746.499 53.3375 744.635 53.3375C742.892 53.3375 741.456 52.0364 741.276 50.3367C741.261 50.3216 741.261 50.2915 741.261 50.2765C741.231 49.9757 741.216 49.6673 741.216 49.3514H741.223ZM744.643 52.9614C746.311 52.9614 747.641 51.6152 747.671 49.9456C747.641 48.291 746.311 46.9448 744.643 46.9448C742.974 46.9448 741.644 48.291 741.644 49.9456C741.644 51.6002 742.989 52.9614 744.643 52.9614Z" fill="#E71D36"/>
<path d="M752.023 53.0667L756.126 43.2896H750.625C750.52 43.2896 750.429 43.1994 750.429 43.0941C750.429 43.0038 750.52 42.8986 750.625 42.8986H756.442C756.532 42.8986 756.637 43.0038 756.637 43.0941C756.637 43.1392 756.607 43.1843 756.577 43.2295L752.368 53.2171C752.353 53.2773 752.278 53.3374 752.203 53.3374C752.188 53.3374 752.158 53.3224 752.128 53.3224C752.023 53.2773 751.993 53.172 752.023 53.0667Z" fill="#E71D36"/>
</g>
<g opacity="0.79">
<path d="M777.612 122.476L780.108 120.22C780.423 119.927 780.596 119.528 780.596 119.13C780.596 118.784 780.461 118.43 780.213 118.144C779.927 117.828 779.514 117.655 779.123 117.655C778.77 117.655 778.409 117.791 778.131 118.039L777.876 118.272C777.831 118.31 777.778 118.31 777.74 118.272C777.703 118.235 777.71 118.167 777.748 118.137L778.003 117.896C778.326 117.61 778.717 117.467 779.116 117.467C779.566 117.467 780.017 117.655 780.341 118.009C780.619 118.325 780.769 118.731 780.769 119.122C780.769 119.573 780.589 120.025 780.213 120.348L777.891 122.454H780.679C780.724 122.454 780.769 122.491 780.769 122.544C780.769 122.597 780.724 122.642 780.679 122.642H777.665C777.665 122.642 777.605 122.634 777.59 122.604C777.56 122.567 777.56 122.506 777.597 122.469L777.612 122.476Z" fill="#E71D36"/>
<path d="M783.67 117.475C784.234 117.475 784.692 117.934 784.692 118.505C784.692 118.912 784.452 119.265 784.106 119.43C784.798 119.626 785.316 120.258 785.316 121.017C785.316 121.927 784.58 122.657 783.67 122.657C782.761 122.657 782.024 121.927 782.024 121.017C782.024 120.265 782.543 119.626 783.234 119.43C782.881 119.265 782.641 118.912 782.641 118.505C782.641 117.934 783.099 117.475 783.67 117.475ZM783.67 122.469C784.482 122.469 785.136 121.822 785.136 121.01C785.136 120.198 784.482 119.543 783.67 119.543C782.859 119.543 782.205 120.205 782.205 121.01C782.205 121.815 782.866 122.469 783.67 122.469ZM783.67 119.34C784.129 119.34 784.504 118.964 784.504 118.505C784.504 118.047 784.129 117.671 783.67 117.663C783.204 117.671 782.829 118.039 782.829 118.505C782.829 118.972 783.204 119.34 783.67 119.34Z" fill="#E71D36"/>
</g>
<g opacity="0.94">
<path d="M874.574 47.8021C874.462 47.7119 874.462 47.5013 874.574 47.3885C874.687 47.2757 874.875 47.2757 874.995 47.3885C875.942 48.321 877.175 48.8024 878.377 48.8024C879.579 48.8024 880.857 48.3135 881.781 47.3885C882.736 46.4408 883.194 45.2074 883.194 43.974C883.194 42.7406 882.728 41.5147 881.781 40.5897C880.857 39.6646 879.647 39.1983 878.392 39.1983C877.137 39.1983 875.957 39.6646 874.988 40.5897C874.92 40.7025 874.71 40.7025 874.589 40.6122C874.567 40.6122 874.544 40.5897 874.544 40.5671C874.522 40.5671 874.522 40.5445 874.522 40.5445C874.477 40.4768 874.454 40.4016 874.477 40.3114L875.288 33.4675C875.311 33.3246 875.401 33.2343 875.499 33.2118C875.544 33.1892 875.589 33.1666 875.664 33.1666H883.502C883.668 33.1666 883.803 33.3246 883.803 33.4675C883.803 33.6555 883.668 33.7683 883.502 33.7683H875.874L875.175 39.6646C876.122 38.9426 877.265 38.5966 878.399 38.5966C879.79 38.5966 881.18 39.1081 882.247 40.176C883.269 41.2214 883.803 42.5902 883.803 43.9815C883.803 45.3729 883.269 46.7642 882.202 47.8096C881.158 48.8776 879.767 49.4116 878.377 49.4116C876.987 49.4116 875.641 48.8776 874.574 47.8096V47.8021Z" fill="#E71D36"/>
<path d="M896.384 40.146C897.428 41.1914 897.962 42.5827 897.962 43.9515C897.962 45.3203 897.428 46.7342 896.384 47.8021C895.316 48.8475 893.926 49.3815 892.558 49.3815H892.536C891.168 49.3815 889.778 48.8701 888.733 47.8021C888.613 47.6893 888.613 47.5013 888.733 47.3885C888.846 47.2757 889.034 47.2757 889.154 47.3885C890.101 48.3211 891.311 48.7798 892.543 48.7798C893.776 48.7798 895.023 48.3136 895.955 47.3659C896.91 46.4409 897.368 45.2075 897.368 43.959C897.368 42.7106 896.91 41.5223 895.978 40.5746C895.031 39.6496 893.821 39.1833 892.596 39.1833C892.476 39.1833 892.385 39.1382 892.318 39.0479C892.318 39.0254 892.295 39.0254 892.295 39.0254C892.183 38.905 892.183 38.702 892.295 38.5816L896.977 33.7533H889.815C889.65 33.7533 889.515 33.6104 889.515 33.4524C889.515 33.2945 889.657 33.1516 889.815 33.1516H897.676C897.841 33.1516 897.977 33.287 897.977 33.4524C897.977 33.4976 897.977 33.5427 897.954 33.5953C897.932 33.6405 897.909 33.6856 897.887 33.7081L893.107 38.6042C894.317 38.7245 895.497 39.2284 896.399 40.161L896.384 40.146Z" fill="#E71D36"/>
</g>
<g opacity="0.76">
<path d="M768.917 7.55087C768.857 7.50575 768.857 7.39294 768.917 7.34029C768.977 7.28013 769.075 7.28013 769.135 7.34029C769.624 7.82162 770.263 8.06981 770.879 8.06981C771.495 8.06981 772.156 7.82162 772.637 7.34029C773.126 6.85144 773.366 6.21217 773.366 5.58042C773.366 4.94868 773.126 4.30941 772.637 3.8356C772.156 3.35426 771.54 3.1136 770.894 3.1136C770.248 3.1136 769.639 3.35426 769.135 3.8356C769.098 3.89576 768.992 3.89576 768.932 3.85064C768.917 3.85064 768.91 3.8356 768.91 3.82808C768.895 3.82808 768.895 3.81303 768.895 3.81303C768.872 3.77543 768.857 3.73783 768.872 3.6927L769.293 0.157923C769.308 0.0827154 769.353 0.0375905 769.398 0.0300697C769.421 0.0150281 769.443 0.00750732 769.481 0.00750732H773.524C773.607 0.00750732 773.682 0.0902362 773.682 0.165444C773.682 0.263215 773.607 0.323381 773.524 0.323381H769.586L769.225 3.36179C769.714 2.99327 770.3 2.81277 770.886 2.81277C771.6 2.81277 772.322 3.076 772.87 3.62501C773.396 4.16651 773.674 4.87347 773.674 5.58794C773.674 6.30242 773.396 7.02442 772.848 7.56592C772.307 8.11493 771.593 8.3932 770.871 8.3932C770.15 8.3932 769.458 8.11493 768.91 7.56592L768.917 7.55087Z" fill="#5EB0EF"/>
<path d="M778.702 0C779.612 0 780.356 0.74456 780.356 1.6621C780.356 2.31641 779.957 2.89551 779.409 3.15874C780.536 3.46709 781.37 4.49744 781.37 5.72333C781.37 7.19741 780.175 8.37818 778.702 8.37818C777.229 8.37818 776.034 7.18989 776.034 5.72333C776.034 4.50496 776.868 3.47461 777.996 3.15874C777.425 2.89551 777.041 2.32393 777.041 1.6621C777.041 0.737039 777.785 0 778.702 0ZM778.702 8.07734C780.017 8.07734 781.07 7.02443 781.07 5.71581C781.07 4.40719 780.017 3.34676 778.702 3.34676C777.387 3.34676 776.335 4.41471 776.335 5.71581C776.335 7.01691 777.402 8.07734 778.702 8.07734ZM778.702 3.01584C779.446 3.01584 780.055 2.40666 780.055 1.6621C780.055 0.917538 779.446 0.308353 778.702 0.300832C777.951 0.315874 777.342 0.910017 777.342 1.6621C777.342 2.41418 777.951 3.01584 778.702 3.01584Z" fill="#5EB0EF"/>
</g>
<g opacity="0.96">
<path d="M967.042 19.524L970.049 12.3567H966.013C965.938 12.3567 965.87 12.289 965.87 12.2138C965.87 12.1461 965.938 12.0709 966.013 12.0709H970.274C970.342 12.0709 970.417 12.1461 970.417 12.2138C970.417 12.2438 970.394 12.2814 970.372 12.3115L967.29 19.6293C967.275 19.6744 967.223 19.7195 967.17 19.7195C967.155 19.7195 967.14 19.712 967.118 19.712C967.042 19.6819 967.02 19.6067 967.042 19.524Z" fill="#5EB0EF"/>
<path d="M972.032 18.9599C971.98 18.9148 971.98 18.817 972.032 18.7644C972.085 18.7118 972.175 18.7118 972.228 18.7644C972.679 19.2006 973.257 19.4338 973.821 19.4338C974.384 19.4338 974.993 19.2006 975.429 18.7644C975.88 18.3132 976.098 17.7341 976.098 17.155C976.098 16.5758 975.88 15.9967 975.429 15.5605C974.993 15.1243 974.422 14.9062 973.836 14.9062C973.25 14.9062 972.686 15.1243 972.228 15.5605C972.198 15.6132 972.1 15.6132 972.04 15.5681C972.032 15.5681 972.017 15.5605 972.017 15.5455C972.01 15.5455 972.01 15.538 972.01 15.538C971.987 15.5079 971.98 15.4703 971.987 15.4252L972.37 12.1987C972.378 12.1311 972.423 12.0859 972.468 12.0784C972.491 12.0709 972.513 12.0558 972.543 12.0558H976.241C976.316 12.0558 976.383 12.1311 976.383 12.1987C976.383 12.289 976.316 12.3416 976.241 12.3416H972.641L972.31 15.1168C972.761 14.7784 973.295 14.6129 973.828 14.6129C974.482 14.6129 975.143 14.8536 975.647 15.3575C976.128 15.8463 976.376 16.4931 976.376 17.155C976.376 17.8168 976.12 18.4711 975.624 18.9599C975.128 19.4638 974.475 19.712 973.821 19.712C973.167 19.712 972.528 19.4638 972.032 18.9599Z" fill="#5EB0EF"/>
</g>
<g opacity="0.81">
<path d="M873.297 80.4275L877.761 76.3964C878.332 75.8699 878.633 75.1555 878.633 74.441C878.633 73.8243 878.393 73.185 877.949 72.6886C877.438 72.1246 876.709 71.8162 876.01 71.8162C875.379 71.8162 874.74 72.0569 874.244 72.5006L873.786 72.9218C873.703 72.9895 873.613 72.9895 873.545 72.9218C873.478 72.8541 873.493 72.7338 873.56 72.6811L874.019 72.2449C874.597 71.7335 875.296 71.4778 876.01 71.4778C876.814 71.4778 877.619 71.8087 878.205 72.4404C878.708 73.012 878.971 73.734 878.971 74.4335C878.971 75.2382 878.641 76.0429 877.979 76.6295L873.838 80.3975H878.813C878.896 80.3975 878.971 80.4651 878.971 80.5554C878.971 80.6456 878.889 80.7284 878.813 80.7284H873.425C873.372 80.7284 873.32 80.7133 873.29 80.6607C873.237 80.593 873.237 80.4877 873.305 80.42L873.297 80.4275Z" fill="#5EB0EF"/>
<path d="M884.435 71.4854C886.246 71.5004 887.592 73.6137 887.592 76.0956C887.592 78.5775 886.246 80.7209 884.435 80.7284C882.654 80.7134 881.294 78.6 881.294 76.0956C881.294 73.5912 882.654 71.5004 884.435 71.4854ZM881.625 76.0956C881.625 77.314 881.97 78.397 882.481 79.1641C883 79.9538 883.699 80.405 884.435 80.405C885.172 80.405 885.886 79.9538 886.404 79.1641C886.93 78.397 887.261 77.314 887.261 76.0956C887.261 74.8772 886.93 73.8243 886.404 73.0572C885.893 72.2675 885.187 71.8163 884.435 71.8163C883.684 71.8163 883 72.2675 882.481 73.0572C881.97 73.8243 881.625 74.9073 881.625 76.0956Z" fill="#5EB0EF"/>
</g>
<g opacity="0.54">
<path d="M943.061 46.6666C943.444 46.6666 943.76 46.9824 943.76 47.3735C943.76 47.6518 943.595 47.9 943.354 48.0128C943.835 48.1482 944.188 48.5844 944.188 49.1033C944.188 49.7275 943.677 50.2314 943.054 50.2314C942.43 50.2314 941.919 49.7275 941.919 49.1033C941.919 48.5844 942.272 48.1482 942.753 48.0128C942.505 47.9 942.347 47.6593 942.347 47.3735C942.347 46.9824 942.663 46.6666 943.054 46.6666H943.061ZM943.061 50.1036C943.625 50.1036 944.068 49.6523 944.068 49.1033C944.068 48.5543 943.625 48.0955 943.061 48.0955C942.498 48.0955 942.054 48.5468 942.054 49.1033C942.054 49.6598 942.505 50.1036 943.061 50.1036ZM943.061 47.9451C943.377 47.9451 943.64 47.6819 943.64 47.366C943.64 47.0501 943.377 46.7869 943.061 46.7869C942.738 46.7869 942.482 47.0501 942.482 47.366C942.482 47.6819 942.746 47.9451 943.061 47.9451Z" fill="#E71D36"/>
<path d="M945.052 47.1103L945.766 46.6892C945.766 46.6892 945.834 46.6741 945.857 46.7117C945.872 46.7418 945.857 46.7794 945.827 46.7945L945.113 47.2232C945.113 47.2232 945.09 47.2232 945.083 47.2232C945.06 47.2232 945.037 47.2232 945.03 47.1931C945.015 47.1705 945.022 47.1254 945.052 47.1028V47.1103ZM945.736 46.7418C945.736 46.7418 945.766 46.6741 945.796 46.6741C945.834 46.6741 945.864 46.7117 945.864 46.7418V50.1713C945.864 50.1713 945.842 50.239 945.796 50.239C945.766 50.239 945.736 50.2089 945.736 50.1713V46.7418Z" fill="#E71D36"/>
</g>
<g opacity="0.64">
<path d="M902.517 206.303C902.517 205.641 902.659 204.904 902.998 204.325C903.336 203.738 903.892 203.302 904.703 203.265C904.756 203.265 904.794 203.302 904.794 203.355C904.794 203.4 904.756 203.438 904.719 203.438C903.967 203.475 903.486 203.859 903.155 204.408C902.9 204.837 902.772 205.348 902.72 205.852C902.983 205.34 903.516 204.994 904.132 204.994C905.012 204.994 905.726 205.701 905.733 206.581C905.726 207.469 905.012 208.176 904.132 208.176C903.313 208.176 902.637 207.567 902.554 206.762C902.547 206.762 902.547 206.739 902.547 206.739C902.532 206.589 902.524 206.446 902.524 206.296L902.517 206.303ZM904.125 208.003C904.914 208.003 905.538 207.371 905.553 206.581C905.538 205.799 904.914 205.167 904.125 205.167C903.336 205.167 902.712 205.799 902.712 206.581C902.712 207.363 903.343 208.003 904.125 208.003Z" fill="#5EB0EF"/>
<path d="M907.019 206.303C907.019 205.641 907.161 204.904 907.5 204.325C907.838 203.738 908.394 203.302 909.206 203.265C909.258 203.265 909.296 203.302 909.296 203.355C909.296 203.4 909.258 203.438 909.22 203.438C908.469 203.475 907.988 203.859 907.657 204.408C907.402 204.837 907.267 205.348 907.221 205.852C907.485 205.34 908.018 204.994 908.634 204.994C909.514 204.994 910.228 205.701 910.235 206.581C910.228 207.469 909.514 208.176 908.634 208.176C907.815 208.176 907.139 207.567 907.056 206.762V206.739C907.041 206.589 907.034 206.446 907.034 206.296L907.019 206.303ZM908.627 208.003C909.408 208.003 910.04 207.371 910.055 206.581C910.04 205.799 909.416 205.167 908.627 205.167C907.838 205.167 907.214 205.799 907.214 206.581C907.214 207.363 907.845 208.003 908.627 208.003Z" fill="#5EB0EF"/>
</g>
<g opacity="0.88">
<path d="M986.815 126.793L987.965 126.109C988.018 126.079 988.078 126.086 988.115 126.139C988.138 126.192 988.123 126.244 988.063 126.282L986.913 126.974C986.913 126.974 986.875 126.981 986.86 126.981C986.83 126.981 986.785 126.974 986.77 126.929C986.747 126.883 986.755 126.823 986.808 126.786L986.815 126.793ZM987.92 126.192C987.92 126.139 987.972 126.086 988.018 126.086C988.085 126.086 988.123 126.146 988.123 126.192V131.734C988.123 131.795 988.078 131.84 988.018 131.84C987.965 131.84 987.92 131.787 987.92 131.734V126.192Z" fill="#E71D36"/>
<path d="M991.76 126.071C992.888 126.079 993.729 127.395 993.729 128.944C993.729 130.493 992.888 131.825 991.76 131.832C990.648 131.825 989.799 130.508 989.799 128.944C989.799 127.38 990.648 126.079 991.76 126.071ZM990.009 128.944C990.009 129.704 990.227 130.381 990.543 130.854C990.866 131.351 991.302 131.629 991.76 131.629C992.219 131.629 992.662 131.351 992.985 130.854C993.316 130.381 993.519 129.704 993.519 128.944C993.519 128.185 993.316 127.53 992.985 127.049C992.662 126.553 992.226 126.274 991.76 126.274C991.294 126.274 990.866 126.553 990.543 127.049C990.22 127.523 990.009 128.2 990.009 128.944Z" fill="#E71D36"/>
</g>
<g opacity="0.94">
<path d="M909.716 209.913C909.716 209.236 909.858 208.484 910.204 207.883C910.55 207.281 911.121 206.83 911.948 206.8C912 206.8 912.038 206.837 912.038 206.89C912.038 206.935 912 206.98 911.955 206.98C911.189 207.018 910.692 207.409 910.354 207.973C910.099 208.409 909.956 208.935 909.911 209.454C910.174 208.928 910.723 208.574 911.354 208.574C912.256 208.574 912.985 209.304 912.992 210.199C912.985 211.101 912.256 211.831 911.354 211.831C910.512 211.831 909.821 211.207 909.738 210.387C909.731 210.387 909.731 210.364 909.731 210.364C909.716 210.214 909.708 210.064 909.708 209.913H909.716ZM911.361 211.65C912.166 211.65 912.804 211.004 912.819 210.199C912.804 209.402 912.166 208.755 911.361 208.755C910.557 208.755 909.918 209.402 909.918 210.199C909.918 210.996 910.565 211.65 911.361 211.65Z" fill="#E71D36"/>
<path d="M916.051 206.8C917.036 206.8 917.765 207.958 917.765 209.312C917.765 210.665 917.036 211.831 916.051 211.839C915.082 211.839 914.338 210.68 914.338 209.312C914.338 207.943 915.082 206.807 916.051 206.8ZM914.518 209.312C914.518 209.973 914.706 210.56 914.984 210.981C915.262 211.41 915.646 211.658 916.044 211.658C916.442 211.658 916.833 211.417 917.119 210.981C917.404 210.567 917.584 209.973 917.584 209.312C917.584 208.65 917.404 208.078 917.119 207.657C916.84 207.228 916.457 206.98 916.044 206.98C915.631 206.98 915.262 207.221 914.984 207.657C914.706 208.071 914.518 208.665 914.518 209.312Z" fill="#E71D36"/>
</g>
<g opacity="0.51">
<path d="M924.649 82.4279L925.731 81.7811C925.776 81.751 925.844 81.7585 925.874 81.8112C925.897 81.8563 925.882 81.909 925.829 81.939L924.739 82.5934C924.739 82.5934 924.709 82.6009 924.694 82.6009C924.664 82.6009 924.627 82.5934 924.612 82.5557C924.589 82.5181 924.597 82.458 924.649 82.4279ZM925.686 81.8638C925.686 81.8187 925.731 81.7585 925.776 81.7585C925.837 81.7585 925.874 81.8112 925.874 81.8638V87.0908C925.874 87.1434 925.837 87.1886 925.776 87.1886C925.731 87.1886 925.686 87.1434 925.686 87.0908V81.8638Z" fill="#E71D36"/>
<path d="M927.242 82.4279L928.332 81.7811C928.377 81.751 928.437 81.7585 928.467 81.8112C928.49 81.8563 928.475 81.909 928.422 81.939L927.34 82.5934C927.34 82.5934 927.31 82.6009 927.295 82.6009C927.265 82.6009 927.227 82.5934 927.212 82.5557C927.19 82.5181 927.197 82.458 927.25 82.4279H927.242ZM928.279 81.8638C928.279 81.8187 928.324 81.7585 928.37 81.7585C928.43 81.7585 928.467 81.8112 928.467 81.8638V87.0908C928.467 87.1434 928.43 87.1886 928.37 87.1886C928.324 87.1886 928.279 87.1434 928.279 87.0908V81.8638Z" fill="#E71D36"/>
</g>
<g opacity="0.88">
<path d="M920.606 167.458L924.656 157.801H919.223C919.118 157.801 919.027 157.711 919.027 157.613C919.027 157.523 919.118 157.418 919.223 157.418H924.972C925.062 157.418 925.16 157.523 925.16 157.613C925.16 157.659 925.13 157.704 925.1 157.749L920.944 167.616C920.929 167.676 920.854 167.736 920.778 167.736C920.763 167.736 920.733 167.721 920.703 167.721C920.606 167.676 920.575 167.571 920.606 167.473V167.458Z" fill="#5EB0EF"/>
<path d="M930.691 157.403C931.811 157.403 932.72 158.313 932.72 159.456C932.72 160.268 932.232 160.975 931.556 161.299C932.938 161.682 933.976 162.953 933.976 164.457C933.976 166.27 932.503 167.729 930.691 167.729C928.88 167.729 927.407 166.27 927.407 164.457C927.407 162.953 928.437 161.682 929.82 161.299C929.113 160.975 928.64 160.268 928.64 159.456C928.64 158.32 929.549 157.403 930.691 157.403ZM930.691 167.353C932.315 167.353 933.607 166.059 933.607 164.45C933.607 162.84 932.315 161.532 930.691 161.532C929.068 161.532 927.775 162.848 927.775 164.45C927.775 166.052 929.091 167.353 930.691 167.353ZM930.691 161.118C931.608 161.118 932.36 160.366 932.36 159.449C932.36 158.531 931.608 157.779 930.691 157.771C929.76 157.786 929.016 158.523 929.016 159.449C929.016 160.374 929.767 161.118 930.691 161.118Z" fill="#5EB0EF"/>
</g>
<g opacity="0.66">
<path d="M1062.85 143.377L1066.35 140.225C1066.79 139.812 1067.03 139.255 1067.03 138.699C1067.03 138.21 1066.85 137.721 1066.5 137.322C1066.09 136.879 1065.53 136.638 1064.98 136.638C1064.49 136.638 1063.99 136.826 1063.6 137.172L1063.24 137.503C1063.18 137.556 1063.1 137.556 1063.05 137.503C1063 137.45 1063.01 137.36 1063.07 137.315L1063.43 136.976C1063.88 136.57 1064.43 136.375 1064.98 136.375C1065.61 136.375 1066.24 136.63 1066.69 137.127C1067.09 137.571 1067.3 138.142 1067.3 138.691C1067.3 139.323 1067.04 139.955 1066.52 140.406L1063.28 143.347H1067.18C1067.24 143.347 1067.3 143.399 1067.3 143.474C1067.3 143.55 1067.24 143.61 1067.18 143.61H1062.96C1062.92 143.61 1062.88 143.602 1062.85 143.557C1062.81 143.504 1062.81 143.422 1062.86 143.369L1062.85 143.377Z" fill="#E71D36"/>
<path d="M1068.84 137.285L1070.28 136.427C1070.34 136.382 1070.42 136.397 1070.47 136.472C1070.5 136.533 1070.47 136.608 1070.41 136.645L1068.96 137.51C1068.96 137.51 1068.92 137.518 1068.9 137.518C1068.87 137.518 1068.81 137.51 1068.79 137.458C1068.76 137.405 1068.77 137.322 1068.84 137.285H1068.84ZM1070.22 136.533C1070.22 136.472 1070.28 136.397 1070.35 136.397C1070.43 136.397 1070.48 136.472 1070.48 136.533V143.489C1070.48 143.565 1070.43 143.625 1070.35 143.625C1070.29 143.625 1070.22 143.565 1070.22 143.489V136.533Z" fill="#E71D36"/>
</g>
<g opacity="0.58">
<path d="M931.308 124.748L934.321 122.025C934.705 121.664 934.908 121.19 934.908 120.702C934.908 120.28 934.75 119.852 934.442 119.513C934.096 119.13 933.607 118.927 933.126 118.927C932.698 118.927 932.27 119.085 931.932 119.393L931.623 119.679C931.571 119.724 931.511 119.724 931.466 119.679C931.42 119.634 931.428 119.551 931.473 119.521L931.781 119.227C932.172 118.881 932.645 118.708 933.126 118.708C933.668 118.708 934.216 118.934 934.607 119.363C934.945 119.746 935.125 120.235 935.125 120.709C935.125 121.251 934.9 121.8 934.457 122.191L931.653 124.733H935.013C935.065 124.733 935.118 124.778 935.118 124.838C935.118 124.898 935.065 124.951 935.013 124.951H931.375C931.375 124.951 931.308 124.943 931.285 124.906C931.248 124.861 931.248 124.793 931.293 124.748H931.308Z" fill="#E71D36"/>
<path d="M936.568 124.748L939.582 122.025C939.965 121.664 940.168 121.19 940.168 120.702C940.168 120.28 940.01 119.852 939.702 119.513C939.357 119.13 938.868 118.927 938.387 118.927C937.959 118.927 937.53 119.085 937.192 119.393L936.884 119.679C936.831 119.724 936.771 119.724 936.726 119.679C936.681 119.634 936.689 119.551 936.734 119.521L937.042 119.227C937.433 118.881 937.906 118.708 938.387 118.708C938.928 118.708 939.477 118.934 939.868 119.363C940.206 119.746 940.386 120.235 940.386 120.709C940.386 121.251 940.161 121.8 939.717 122.191L936.914 124.733H940.273C940.326 124.733 940.379 124.778 940.379 124.838C940.379 124.898 940.326 124.951 940.273 124.951H936.636C936.636 124.951 936.568 124.943 936.546 124.906C936.508 124.861 936.508 124.793 936.553 124.748H936.568Z" fill="#E71D36"/>
</g>
<g opacity="0.9">
<path d="M1032.92 139.045C1033.65 139.045 1034.26 139.609 1034.32 140.338C1034.33 140.459 1034.34 140.579 1034.34 140.707C1034.34 141.293 1034.21 141.933 1033.92 142.459C1033.61 142.978 1033.12 143.369 1032.41 143.399C1032.36 143.399 1032.33 143.369 1032.33 143.316C1032.33 143.279 1032.36 143.241 1032.4 143.241C1033.06 143.211 1033.48 142.865 1033.77 142.384C1033.98 142.008 1034.11 141.549 1034.15 141.113C1033.93 141.557 1033.45 141.865 1032.91 141.865C1032.14 141.865 1031.5 141.241 1031.5 140.459C1031.5 139.676 1032.14 139.045 1032.91 139.045H1032.92ZM1032.92 141.707C1033.61 141.707 1034.17 141.15 1034.17 140.459C1034.17 140.428 1034.17 140.398 1034.17 140.368V140.346C1034.11 139.699 1033.57 139.21 1032.93 139.21C1032.24 139.21 1031.67 139.767 1031.67 140.466C1031.67 141.165 1032.23 141.714 1032.93 141.714L1032.92 141.707Z" fill="#5EB0EF"/>
</g>
<g opacity="0.57">
<path d="M1055.55 325.44L1058.68 317.98H1054.48C1054.4 317.98 1054.33 317.912 1054.33 317.829C1054.33 317.762 1054.4 317.679 1054.48 317.679H1058.92C1058.98 317.679 1059.07 317.762 1059.07 317.829C1059.07 317.867 1059.04 317.897 1059.02 317.934L1055.81 325.553C1055.8 325.598 1055.75 325.643 1055.68 325.643C1055.67 325.643 1055.65 325.636 1055.62 325.636C1055.54 325.598 1055.53 325.523 1055.54 325.44H1055.55Z" fill="#E71D36"/>
<path d="M1064.67 321.108C1065.18 321.62 1065.45 322.304 1065.45 322.973C1065.45 323.643 1065.18 324.342 1064.67 324.861C1064.15 325.373 1063.46 325.636 1062.79 325.636H1062.78C1062.11 325.636 1061.43 325.388 1060.92 324.861C1060.86 324.801 1060.86 324.711 1060.92 324.658C1060.98 324.598 1061.07 324.598 1061.12 324.658C1061.58 325.117 1062.18 325.342 1062.78 325.342C1063.38 325.342 1064 325.117 1064.46 324.651C1064.92 324.199 1065.15 323.59 1065.15 322.981C1065.15 322.372 1064.92 321.785 1064.46 321.319C1064 320.868 1063.4 320.634 1062.8 320.634C1062.75 320.634 1062.7 320.612 1062.67 320.567C1062.67 320.552 1062.65 320.552 1062.65 320.552C1062.6 320.492 1062.6 320.394 1062.65 320.334L1064.95 317.965H1061.43C1061.35 317.965 1061.28 317.897 1061.28 317.814C1061.28 317.731 1061.35 317.664 1061.43 317.664H1065.29C1065.37 317.664 1065.44 317.731 1065.44 317.814C1065.44 317.837 1065.44 317.859 1065.42 317.882C1065.41 317.904 1065.4 317.927 1065.39 317.942L1063.04 320.349C1063.64 320.401 1064.21 320.657 1064.66 321.108H1064.67Z" fill="#E71D36"/>
</g>
<g opacity="0.82">
<path d="M909.52 253.278C910.384 253.278 911.091 253.985 911.091 254.865C911.091 255.489 910.715 256.038 910.189 256.294C911.264 256.587 912.06 257.572 912.06 258.738C912.06 260.145 910.918 261.273 909.513 261.273C908.107 261.273 906.965 260.145 906.965 258.738C906.965 257.572 907.761 256.595 908.836 256.294C908.288 256.046 907.927 255.497 907.927 254.865C907.927 253.985 908.633 253.278 909.513 253.278H909.52ZM909.52 260.987C910.775 260.987 911.782 259.979 911.782 258.738C911.782 257.497 910.775 256.474 909.52 256.474C908.265 256.474 907.258 257.49 907.258 258.738C907.258 259.987 908.273 260.987 909.52 260.987ZM909.52 256.159C910.226 256.159 910.813 255.572 910.813 254.865C910.813 254.158 910.234 253.571 909.52 253.564C908.799 253.579 908.22 254.143 908.22 254.865C908.22 255.587 908.799 256.159 909.52 256.159Z" fill="#E71D36"/>
<path d="M914.277 260.483C914.225 260.438 914.225 260.333 914.277 260.28C914.337 260.22 914.427 260.22 914.48 260.28C914.946 260.739 915.555 260.979 916.149 260.979C916.742 260.979 917.366 260.739 917.824 260.28C918.29 259.814 918.523 259.204 918.523 258.603C918.523 258.001 918.298 257.392 917.824 256.933C917.366 256.474 916.772 256.249 916.156 256.249C915.54 256.249 914.961 256.474 914.48 256.933C914.442 256.993 914.345 256.993 914.285 256.948C914.27 256.948 914.262 256.933 914.262 256.926C914.247 256.926 914.247 256.911 914.247 256.911C914.225 256.881 914.217 256.843 914.225 256.798L914.623 253.429C914.638 253.361 914.683 253.316 914.728 253.301C914.751 253.286 914.773 253.278 914.811 253.278H918.666C918.749 253.278 918.816 253.361 918.816 253.429C918.816 253.519 918.749 253.579 918.666 253.579H914.908L914.563 256.482C915.029 256.128 915.592 255.955 916.149 255.955C916.832 255.955 917.516 256.211 918.042 256.73C918.546 257.242 918.809 257.918 918.809 258.603C918.809 259.287 918.546 259.972 918.02 260.491C917.509 261.017 916.825 261.28 916.141 261.28C915.457 261.28 914.796 261.017 914.27 260.491L914.277 260.483Z" fill="#E71D36"/>
</g>
<g opacity="0.67">
<path d="M1051.07 235.228C1051.07 234.724 1051.18 234.168 1051.43 233.724C1051.69 233.28 1052.12 232.949 1052.72 232.919C1052.76 232.919 1052.79 232.942 1052.79 232.987C1052.79 233.017 1052.77 233.047 1052.73 233.047C1052.16 233.077 1051.79 233.371 1051.54 233.784C1051.35 234.108 1051.25 234.499 1051.21 234.882C1051.41 234.491 1051.81 234.228 1052.29 234.228C1052.96 234.228 1053.5 234.769 1053.51 235.431C1053.51 236.101 1052.96 236.642 1052.29 236.642C1051.66 236.642 1051.15 236.176 1051.09 235.567V235.552C1051.08 235.439 1051.08 235.326 1051.08 235.213L1051.07 235.228ZM1052.29 236.522C1052.89 236.522 1053.36 236.04 1053.37 235.446C1053.36 234.852 1052.89 234.371 1052.29 234.371C1051.69 234.371 1051.21 234.852 1051.21 235.446C1051.21 236.04 1051.69 236.522 1052.29 236.522Z" fill="#5EB0EF"/>
<path d="M1054.93 236.559L1056.39 233.062H1054.42C1054.42 233.062 1054.35 233.032 1054.35 232.995C1054.35 232.964 1054.38 232.927 1054.42 232.927H1056.5C1056.5 232.927 1056.57 232.964 1056.57 232.995C1056.57 233.01 1056.56 233.025 1056.55 233.04L1055.05 236.605C1055.05 236.605 1055.02 236.65 1054.99 236.65C1054.99 236.65 1054.97 236.65 1054.96 236.65C1054.92 236.635 1054.91 236.597 1054.92 236.559H1054.93Z" fill="#5EB0EF"/>
</g>
<g opacity="0.92">
<path d="M1005.54 250.841L1006.32 250.383C1006.32 250.383 1006.39 250.368 1006.42 250.405C1006.43 250.443 1006.42 250.473 1006.39 250.495L1005.61 250.962C1005.61 250.962 1005.59 250.962 1005.58 250.962C1005.56 250.962 1005.53 250.962 1005.52 250.932C1005.51 250.902 1005.51 250.856 1005.55 250.834L1005.54 250.841ZM1006.29 250.435C1006.29 250.435 1006.32 250.36 1006.36 250.36C1006.4 250.36 1006.43 250.398 1006.43 250.435V254.158C1006.43 254.196 1006.4 254.233 1006.36 254.233C1006.33 254.233 1006.29 254.203 1006.29 254.158V250.435Z" fill="#E71D36"/>
<path d="M1009.43 252.022C1009.68 252.27 1009.8 252.601 1009.8 252.925C1009.8 253.248 1009.68 253.586 1009.43 253.842C1009.17 254.09 1008.84 254.218 1008.52 254.218C1008.2 254.218 1007.87 254.098 1007.62 253.842C1007.59 253.812 1007.59 253.767 1007.62 253.744C1007.65 253.714 1007.68 253.714 1007.71 253.744C1007.94 253.963 1008.23 254.075 1008.52 254.075C1008.81 254.075 1009.11 253.963 1009.33 253.737C1009.56 253.511 1009.67 253.225 1009.67 252.925C1009.67 252.624 1009.56 252.346 1009.34 252.12C1009.11 251.902 1008.83 251.789 1008.53 251.789C1008.5 251.789 1008.48 251.774 1008.47 251.751C1008.44 251.721 1008.44 251.676 1008.47 251.646L1009.58 250.495H1007.87C1007.84 250.495 1007.81 250.465 1007.81 250.428C1007.81 250.39 1007.84 250.353 1007.87 250.353H1009.74C1009.78 250.353 1009.82 250.383 1009.82 250.428C1009.82 250.443 1009.82 250.45 1009.82 250.465C1009.82 250.473 1009.8 250.488 1009.8 250.495L1008.67 251.661C1008.96 251.691 1009.24 251.812 1009.45 252.03L1009.43 252.022Z" fill="#E71D36"/>
</g>
<g opacity="0.85">
<path d="M1151.05 226.451C1150.97 226.391 1150.97 226.263 1151.05 226.188C1151.11 226.113 1151.23 226.113 1151.31 226.188C1151.9 226.767 1152.67 227.076 1153.42 227.076C1154.17 227.076 1154.97 226.775 1155.55 226.188C1156.14 225.594 1156.43 224.827 1156.43 224.06C1156.43 223.293 1156.14 222.526 1155.55 221.946C1154.97 221.367 1154.22 221.074 1153.44 221.074C1152.65 221.074 1151.92 221.367 1151.31 221.946C1151.26 222.022 1151.14 222.022 1151.06 221.962C1151.05 221.962 1151.03 221.946 1151.03 221.931C1151.02 221.931 1151.02 221.916 1151.02 221.916C1150.99 221.871 1150.97 221.826 1150.99 221.773L1151.49 217.502C1151.5 217.411 1151.56 217.359 1151.62 217.344C1151.65 217.329 1151.68 217.314 1151.72 217.314H1156.62C1156.71 217.314 1156.8 217.411 1156.8 217.502C1156.8 217.614 1156.71 217.69 1156.62 217.69H1151.85L1151.41 221.367C1152.01 220.916 1152.71 220.698 1153.43 220.698C1154.3 220.698 1155.16 221.014 1155.83 221.683C1156.47 222.338 1156.8 223.187 1156.8 224.06C1156.8 224.932 1156.47 225.797 1155.8 226.451C1155.15 227.121 1154.29 227.452 1153.41 227.452C1152.54 227.452 1151.71 227.121 1151.04 226.451H1151.05Z" fill="#E71D36"/>
<path d="M1160.91 227.188L1164.9 217.697H1159.55C1159.46 217.697 1159.37 217.607 1159.37 217.509C1159.37 217.419 1159.46 217.321 1159.55 217.321H1165.2C1165.28 217.321 1165.39 217.419 1165.39 217.509C1165.39 217.554 1165.36 217.592 1165.33 217.637L1161.24 227.331C1161.23 227.392 1161.15 227.444 1161.09 227.444C1161.07 227.444 1161.04 227.429 1161.02 227.429C1160.92 227.384 1160.89 227.286 1160.92 227.181L1160.91 227.188Z" fill="#E71D36"/>
</g>
<g opacity="0.99">
<path d="M1098.76 159.027C1099.54 159.802 1099.93 160.832 1099.93 161.84C1099.93 162.848 1099.53 163.901 1098.76 164.691C1097.97 165.465 1096.94 165.856 1095.93 165.856H1095.91C1094.9 165.856 1093.87 165.48 1093.1 164.691C1093.01 164.608 1093.01 164.465 1093.1 164.382C1093.19 164.292 1093.33 164.292 1093.41 164.382C1094.12 165.067 1095 165.413 1095.91 165.413C1096.82 165.413 1097.75 165.067 1098.44 164.367C1099.14 163.683 1099.48 162.773 1099.48 161.84C1099.48 160.908 1099.14 160.035 1098.45 159.336C1097.75 158.651 1096.86 158.305 1095.95 158.305C1095.87 158.305 1095.79 158.275 1095.75 158.2C1095.75 158.185 1095.73 158.185 1095.73 158.185C1095.65 158.102 1095.65 157.944 1095.73 157.862L1099.2 154.289H1093.9C1093.78 154.289 1093.67 154.184 1093.67 154.064C1093.67 153.943 1093.78 153.838 1093.9 153.838H1099.72C1099.84 153.838 1099.94 153.943 1099.94 154.064C1099.94 154.101 1099.94 154.131 1099.93 154.169C1099.91 154.207 1099.9 154.237 1099.87 154.259L1096.34 157.884C1097.24 157.967 1098.11 158.35 1098.78 159.035L1098.76 159.027Z" fill="#E71D36"/>
<path d="M1107.47 153.861C1109.82 153.876 1111.57 156.621 1111.57 159.855C1111.57 163.089 1109.82 165.864 1107.47 165.879C1105.15 165.864 1103.38 163.111 1103.38 159.855C1103.38 156.598 1105.15 153.883 1107.47 153.861ZM1103.81 159.855C1103.81 161.434 1104.26 162.84 1104.93 163.841C1105.59 164.871 1106.5 165.45 1107.47 165.45C1108.43 165.45 1109.35 164.864 1110.02 163.841C1110.7 162.84 1111.13 161.434 1111.13 159.855C1111.13 158.275 1110.7 156.899 1110.02 155.906C1109.35 154.876 1108.44 154.289 1107.47 154.289C1106.49 154.289 1105.59 154.876 1104.93 155.906C1104.26 156.899 1103.81 158.313 1103.81 159.855Z" fill="#E71D36"/>
</g>
<g opacity="0.82">
<path d="M1152.65 253.271C1152.78 253.398 1152.84 253.564 1152.84 253.722C1152.84 253.88 1152.77 254.053 1152.65 254.181C1152.53 254.308 1152.36 254.369 1152.2 254.369C1152.03 254.369 1151.87 254.308 1151.74 254.181C1151.72 254.166 1151.72 254.143 1151.74 254.128C1151.75 254.113 1151.78 254.113 1151.79 254.128C1151.9 254.241 1152.05 254.293 1152.2 254.293C1152.35 254.293 1152.49 254.241 1152.6 254.128C1152.71 254.015 1152.77 253.872 1152.77 253.722C1152.77 253.571 1152.71 253.429 1152.6 253.316C1152.49 253.203 1152.35 253.15 1152.2 253.15C1152.18 253.15 1152.17 253.15 1152.17 253.135C1152.17 253.135 1152.15 253.09 1152.17 253.083L1152.73 252.503H1151.87C1151.87 252.503 1151.84 252.488 1151.84 252.466C1151.84 252.443 1151.85 252.428 1151.87 252.428H1152.81C1152.81 252.428 1152.85 252.443 1152.85 252.466V252.481V252.496L1152.27 253.083C1152.41 253.098 1152.56 253.158 1152.66 253.271H1152.65Z" fill="#E71D36"/>
<path d="M1154.35 253.271C1154.47 253.398 1154.53 253.564 1154.53 253.722C1154.53 253.88 1154.47 254.053 1154.35 254.181C1154.22 254.309 1154.05 254.369 1153.89 254.369C1153.72 254.369 1153.56 254.309 1153.44 254.181C1153.42 254.166 1153.42 254.143 1153.44 254.128C1153.45 254.113 1153.47 254.113 1153.49 254.128C1153.6 254.241 1153.74 254.293 1153.89 254.293C1154.04 254.293 1154.19 254.241 1154.3 254.128C1154.41 254.015 1154.47 253.872 1154.47 253.722C1154.47 253.571 1154.41 253.429 1154.3 253.316C1154.19 253.203 1154.04 253.15 1153.89 253.15C1153.88 253.15 1153.87 253.15 1153.86 253.135C1153.86 253.135 1153.84 253.09 1153.86 253.083L1154.41 252.504H1153.56C1153.56 252.504 1153.52 252.488 1153.52 252.466C1153.52 252.443 1153.53 252.428 1153.56 252.428H1154.5C1154.5 252.428 1154.53 252.443 1154.53 252.466V252.481V252.496L1153.95 253.083C1154.1 253.098 1154.24 253.158 1154.35 253.271H1154.35Z" fill="#E71D36"/>
</g>
<g opacity="0.71">
<path d="M1200.29 220.788L1203.07 214.162H1199.35C1199.28 214.162 1199.21 214.102 1199.21 214.027C1199.21 213.967 1199.27 213.899 1199.35 213.899H1203.29C1203.35 213.899 1203.42 213.974 1203.42 214.027C1203.42 214.057 1203.4 214.087 1203.38 214.117L1200.53 220.886C1200.53 220.924 1200.47 220.969 1200.42 220.969C1200.41 220.969 1200.39 220.961 1200.37 220.961C1200.29 220.931 1200.28 220.863 1200.29 220.788Z" fill="#E71D36"/>
<path d="M1204.94 218.269C1204.94 217.321 1205.14 216.253 1205.62 215.418C1206.1 214.569 1206.91 213.937 1208.08 213.892C1208.15 213.892 1208.21 213.944 1208.21 214.02C1208.21 214.08 1208.15 214.14 1208.09 214.14C1207.01 214.193 1206.31 214.749 1205.84 215.539C1205.48 216.155 1205.28 216.892 1205.22 217.622C1205.59 216.885 1206.36 216.389 1207.25 216.389C1208.51 216.389 1209.54 217.411 1209.55 218.675C1209.54 219.953 1208.51 220.969 1207.25 220.969C1206.07 220.969 1205.1 220.089 1204.97 218.938C1204.96 218.931 1204.96 218.908 1204.96 218.908C1204.94 218.697 1204.93 218.479 1204.93 218.269H1204.94ZM1207.25 220.721C1208.39 220.721 1209.28 219.811 1209.3 218.675C1209.28 217.554 1208.39 216.644 1207.25 216.644C1206.12 216.644 1205.22 217.554 1205.22 218.675C1205.22 219.796 1206.13 220.721 1207.25 220.721Z" fill="#E71D36"/>
</g>
<g opacity="0.73">
<path d="M1132.33 396.399H1127.21C1127.21 396.399 1127.15 396.384 1127.11 396.361C1127.05 396.301 1127.04 396.211 1127.09 396.151L1132.38 389.57L1132.4 389.548C1132.4 389.548 1132.46 389.51 1132.5 389.51C1132.55 389.51 1132.6 389.548 1132.63 389.585C1132.64 389.6 1132.64 389.608 1132.65 389.623C1132.65 389.638 1132.66 389.645 1132.66 389.645V396.091H1133.43C1133.53 396.091 1133.6 396.151 1133.6 396.241C1133.6 396.339 1133.54 396.407 1133.43 396.407H1132.66V398.114C1132.66 398.204 1132.6 398.279 1132.5 398.279C1132.42 398.279 1132.33 398.204 1132.33 398.114V396.407V396.399ZM1132.33 396.083V390.142L1127.54 396.083H1132.33Z" fill="#E71D36"/>
<path d="M1134.99 390.578L1136.74 389.54C1136.81 389.487 1136.91 389.502 1136.96 389.593C1137 389.668 1136.98 389.758 1136.89 389.803L1135.14 390.856C1135.14 390.856 1135.08 390.871 1135.06 390.871C1135.01 390.871 1134.95 390.856 1134.93 390.796C1134.89 390.736 1134.9 390.631 1134.99 390.585V390.578ZM1136.66 389.66C1136.66 389.585 1136.74 389.495 1136.81 389.495C1136.91 389.495 1136.98 389.585 1136.98 389.66V398.099C1136.98 398.189 1136.92 398.264 1136.81 398.264C1136.74 398.264 1136.66 398.189 1136.66 398.099V389.66Z" fill="#E71D36"/>
</g>
<g opacity="0.51">
<path d="M1162.54 286.362C1163.06 286.896 1163.33 287.603 1163.33 288.295C1163.33 288.987 1163.06 289.709 1162.54 290.25C1162 290.777 1161.29 291.048 1160.6 291.048H1160.58C1159.89 291.048 1159.19 290.792 1158.65 290.25C1158.59 290.19 1158.59 290.1 1158.65 290.04C1158.71 289.98 1158.8 289.98 1158.86 290.04C1159.34 290.514 1159.96 290.747 1160.58 290.747C1161.21 290.747 1161.84 290.514 1162.31 290.032C1162.79 289.559 1163.03 288.934 1163.03 288.303C1163.03 287.671 1162.8 287.069 1162.33 286.58C1161.85 286.106 1161.24 285.873 1160.61 285.873C1160.55 285.873 1160.51 285.851 1160.47 285.806C1160.47 285.791 1160.46 285.791 1160.46 285.791C1160.4 285.73 1160.4 285.625 1160.46 285.565L1162.83 283.113H1159.19C1159.11 283.113 1159.04 283.045 1159.04 282.963C1159.04 282.88 1159.11 282.812 1159.19 282.812H1163.18C1163.27 282.812 1163.33 282.88 1163.33 282.963C1163.33 282.985 1163.33 283.008 1163.32 283.03C1163.3 283.053 1163.3 283.076 1163.28 283.091L1160.86 285.572C1161.47 285.633 1162.07 285.888 1162.53 286.362H1162.54Z" fill="#E71D36"/>
<path d="M1168.5 282.82C1170.1 282.835 1171.31 284.715 1171.31 286.926C1171.31 289.137 1170.1 291.048 1168.5 291.063C1166.91 291.048 1165.69 289.167 1165.69 286.926C1165.69 284.685 1166.9 282.827 1168.5 282.82ZM1165.99 286.926C1165.99 288.009 1166.3 288.972 1166.76 289.656C1167.22 290.363 1167.84 290.762 1168.5 290.762C1169.17 290.762 1169.8 290.363 1170.26 289.656C1170.73 288.972 1171.02 288.009 1171.02 286.926C1171.02 285.843 1170.73 284.903 1170.26 284.219C1169.8 283.512 1169.17 283.113 1168.5 283.113C1167.84 283.113 1167.22 283.512 1166.76 284.219C1166.3 284.903 1165.99 285.866 1165.99 286.926Z" fill="#E71D36"/>
</g>
<g opacity="0.54">
<path d="M1240.09 387.224L1243.3 385.321C1243.44 385.231 1243.63 385.253 1243.72 385.411C1243.78 385.547 1243.74 385.712 1243.58 385.802L1240.37 387.728C1240.33 387.75 1240.28 387.75 1240.23 387.75C1240.14 387.75 1240.03 387.728 1239.97 387.615C1239.91 387.502 1239.93 387.314 1240.09 387.224H1240.09ZM1243.17 385.547C1243.17 385.411 1243.3 385.246 1243.45 385.246C1243.63 385.246 1243.75 385.404 1243.75 385.547V400.994C1243.75 401.152 1243.63 401.295 1243.45 401.295C1243.31 401.295 1243.17 401.16 1243.17 400.994V385.547Z" fill="#E71D36"/>
<path d="M1253.64 385.231C1256.35 385.231 1258.57 387.321 1258.8 390.006C1258.87 390.443 1258.89 390.901 1258.89 391.36C1258.89 393.519 1258.43 395.903 1257.33 397.835C1256.18 399.761 1254.39 401.19 1251.76 401.303C1251.57 401.303 1251.46 401.19 1251.46 401.002C1251.43 400.866 1251.57 400.723 1251.71 400.723C1254.17 400.611 1255.75 399.325 1256.8 397.557C1257.58 396.181 1258.06 394.481 1258.22 392.849C1257.37 394.504 1255.63 395.624 1253.64 395.624C1250.77 395.624 1248.41 393.308 1248.41 390.435C1248.41 387.562 1250.77 385.223 1253.64 385.223L1253.64 385.231ZM1253.64 395.053C1256.19 395.053 1258.25 392.985 1258.25 390.443C1258.25 390.33 1258.25 390.209 1258.23 390.097V389.984C1258 387.622 1256.03 385.81 1253.64 385.81C1251.07 385.81 1248.98 387.878 1248.98 390.443C1248.98 393.007 1251.07 395.053 1253.64 395.053Z" fill="#E71D36"/>
</g>
<g opacity="0.9">
<path d="M1119.5 339.7C1120.45 339.7 1121.23 340.474 1121.23 341.437C1121.23 342.121 1120.82 342.723 1120.25 343.001C1121.42 343.325 1122.3 344.4 1122.3 345.679C1122.3 347.22 1121.05 348.454 1119.51 348.454C1117.97 348.454 1116.72 347.213 1116.72 345.679C1116.72 344.4 1117.6 343.325 1118.78 343.001C1118.17 342.723 1117.78 342.129 1117.78 341.437C1117.78 340.474 1118.55 339.7 1119.51 339.7H1119.5ZM1119.5 348.146C1120.88 348.146 1121.98 347.048 1121.98 345.679C1121.98 344.31 1120.88 343.197 1119.5 343.197C1118.13 343.197 1117.02 344.31 1117.02 345.679C1117.02 347.048 1118.14 348.146 1119.5 348.146ZM1119.5 342.851C1120.28 342.851 1120.92 342.212 1120.92 341.437C1120.92 340.662 1120.28 340.023 1119.5 340.008C1118.72 340.023 1118.08 340.647 1118.08 341.437C1118.08 342.227 1118.72 342.851 1119.5 342.851Z" fill="#E71D36"/>
<path d="M1125.92 348.228L1129.37 340.031H1124.75C1124.66 340.031 1124.59 339.955 1124.59 339.865C1124.59 339.79 1124.66 339.7 1124.75 339.7H1129.63C1129.7 339.7 1129.79 339.79 1129.79 339.865C1129.79 339.903 1129.77 339.94 1129.74 339.978L1126.22 348.349C1126.2 348.401 1126.14 348.446 1126.08 348.446C1126.07 348.446 1126.04 348.431 1126.02 348.431C1125.93 348.394 1125.91 348.303 1125.93 348.221L1125.92 348.228Z" fill="#E71D36"/>
</g>
<g opacity="0.97">
<path d="M1102.34 349.838C1103.12 350.62 1103.52 351.658 1103.52 352.681C1103.52 353.703 1103.12 354.764 1102.34 355.561C1101.54 356.343 1100.5 356.742 1099.48 356.742H1099.46C1098.44 356.742 1097.4 356.358 1096.62 355.561C1096.53 355.478 1096.53 355.335 1096.62 355.253C1096.71 355.162 1096.85 355.162 1096.93 355.253C1097.64 355.945 1098.54 356.291 1099.46 356.291C1100.39 356.291 1101.32 355.945 1102.01 355.23C1102.72 354.538 1103.07 353.621 1103.07 352.681C1103.07 351.74 1102.72 350.861 1102.03 350.146C1101.32 349.454 1100.42 349.108 1099.5 349.108C1099.42 349.108 1099.34 349.071 1099.29 349.003C1099.29 348.988 1099.27 348.988 1099.27 348.988C1099.18 348.898 1099.18 348.747 1099.27 348.657L1102.78 345.047H1097.42C1097.3 345.047 1097.2 344.942 1097.2 344.821C1097.2 344.701 1097.3 344.596 1097.42 344.596H1103.3C1103.42 344.596 1103.53 344.701 1103.53 344.821C1103.53 344.859 1103.53 344.889 1103.51 344.927C1103.5 344.964 1103.47 344.994 1103.46 345.017L1099.89 348.679C1100.79 348.77 1101.67 349.146 1102.35 349.845L1102.34 349.838Z" fill="#E71D36"/>
<path d="M1106.72 356.358L1112.58 351.071C1113.33 350.379 1113.73 349.439 1113.73 348.506C1113.73 347.687 1113.42 346.859 1112.82 346.198C1112.15 345.453 1111.19 345.054 1110.28 345.054C1109.44 345.054 1108.61 345.363 1107.95 345.957L1107.34 346.513C1107.24 346.604 1107.12 346.604 1107.04 346.513C1106.95 346.423 1106.97 346.273 1107.05 346.205L1107.66 345.634C1108.42 344.957 1109.34 344.626 1110.28 344.626C1111.34 344.626 1112.39 345.062 1113.15 345.889C1113.82 346.634 1114.16 347.589 1114.16 348.506C1114.16 349.567 1113.73 350.62 1112.86 351.387L1107.42 356.328H1113.96C1114.06 356.328 1114.17 356.411 1114.17 356.539C1114.17 356.667 1114.06 356.764 1113.96 356.764H1106.89C1106.82 356.764 1106.75 356.749 1106.71 356.674C1106.65 356.591 1106.65 356.448 1106.73 356.366L1106.72 356.358Z" fill="#E71D36"/>
</g>
<g opacity="0.55">
<path d="M1286.34 274.464H1274.56C1274.47 274.464 1274.42 274.434 1274.33 274.374C1274.18 274.231 1274.15 274.028 1274.27 273.885L1286.42 258.761L1286.48 258.701C1286.56 258.64 1286.62 258.618 1286.7 258.618C1286.82 258.618 1286.94 258.701 1286.99 258.791C1287.02 258.821 1287.02 258.851 1287.05 258.881C1287.05 258.911 1287.08 258.941 1287.08 258.971V273.75H1288.84C1289.07 273.75 1289.21 273.893 1289.21 274.096C1289.21 274.329 1289.07 274.472 1288.84 274.472H1287.08V278.39C1287.08 278.593 1286.94 278.766 1286.7 278.766C1286.53 278.766 1286.33 278.593 1286.33 278.39V274.472L1286.34 274.464ZM1286.34 273.742V260.084L1275.31 273.742H1286.34Z" fill="#5EB0EF"/>
<path d="M1293.76 271.065C1293.76 268.357 1294.34 265.334 1295.72 262.942C1297.1 260.521 1299.4 258.738 1302.71 258.595C1302.91 258.595 1303.09 258.738 1303.09 258.971C1303.09 259.144 1302.94 259.317 1302.77 259.317C1299.69 259.46 1297.71 261.047 1296.35 263.296C1295.32 265.056 1294.77 267.154 1294.57 269.23C1295.63 267.124 1297.82 265.718 1300.35 265.718C1303.95 265.718 1306.89 268.628 1306.91 272.231C1306.88 275.863 1303.94 278.774 1300.35 278.774C1296.99 278.774 1294.22 276.269 1293.87 272.983C1293.84 272.953 1293.84 272.9 1293.84 272.87C1293.78 272.291 1293.76 271.689 1293.76 271.08V271.065ZM1300.35 278.037C1303.58 278.037 1306.14 275.442 1306.2 272.216C1306.14 269.019 1303.58 266.424 1300.35 266.424C1297.13 266.424 1294.57 269.019 1294.57 272.216C1294.57 275.412 1297.16 278.037 1300.35 278.037Z" fill="#5EB0EF"/>
</g>
<g opacity="0.54">
<path d="M1311.83 313.956C1311.83 313.956 1311.81 313.911 1311.83 313.896C1311.84 313.881 1311.87 313.881 1311.89 313.896C1312.03 314.031 1312.21 314.106 1312.39 314.106C1312.57 314.106 1312.76 314.031 1312.9 313.896C1313.04 313.753 1313.11 313.572 1313.11 313.384C1313.11 313.196 1313.04 313.016 1312.9 312.88C1312.77 312.738 1312.58 312.67 1312.4 312.67C1312.22 312.67 1312.04 312.738 1311.89 312.88C1311.88 312.895 1311.84 312.895 1311.83 312.88H1311.82C1311.82 312.88 1311.81 312.858 1311.81 312.843L1311.93 311.82C1311.93 311.82 1311.95 311.782 1311.96 311.782C1311.96 311.782 1311.98 311.782 1311.99 311.782H1313.16C1313.16 311.782 1313.2 311.805 1313.2 311.828C1313.2 311.858 1313.18 311.873 1313.16 311.873H1312.02L1311.91 312.753C1312.05 312.647 1312.22 312.595 1312.39 312.595C1312.6 312.595 1312.8 312.67 1312.97 312.828C1313.12 312.986 1313.2 313.189 1313.2 313.399C1313.2 313.61 1313.12 313.813 1312.96 313.971C1312.8 314.129 1312.6 314.212 1312.39 314.212C1312.18 314.212 1311.99 314.129 1311.82 313.971L1311.83 313.956Z" fill="#E71D36"/>
<path d="M1313.92 313.271C1313.92 312.948 1313.98 312.58 1314.15 312.294C1314.32 312 1314.59 311.79 1314.99 311.767C1315.01 311.767 1315.04 311.782 1315.04 311.812C1315.04 311.835 1315.02 311.858 1315 311.858C1314.63 311.873 1314.39 312.068 1314.23 312.339C1314.1 312.549 1314.04 312.805 1314.01 313.053C1314.13 312.798 1314.4 312.632 1314.71 312.632C1315.13 312.632 1315.5 312.978 1315.5 313.414C1315.5 313.851 1315.13 314.204 1314.71 314.204C1314.3 314.204 1313.97 313.903 1313.92 313.505C1313.92 313.505 1313.92 313.497 1313.92 313.49C1313.92 313.422 1313.92 313.347 1313.92 313.279V313.271ZM1314.71 314.114C1315.1 314.114 1315.41 313.805 1315.42 313.414C1315.41 313.031 1315.1 312.715 1314.71 312.715C1314.32 312.715 1314.01 313.023 1314.01 313.414C1314.01 313.805 1314.32 314.114 1314.71 314.114Z" fill="#E71D36"/>
</g>
<g opacity="0.81">
<path d="M1347.46 366.12L1351.26 357.065H1346.16C1346.06 357.065 1345.98 356.982 1345.98 356.885C1345.98 356.802 1346.06 356.704 1346.16 356.704H1351.55C1351.63 356.704 1351.73 356.802 1351.73 356.885C1351.73 356.93 1351.7 356.967 1351.67 357.013L1347.77 366.263C1347.76 366.316 1347.69 366.376 1347.62 366.376C1347.61 366.376 1347.58 366.361 1347.56 366.361C1347.46 366.316 1347.44 366.226 1347.46 366.128V366.12Z" fill="#E71D36"/>
<path d="M1353.76 365.413C1353.7 365.361 1353.7 365.233 1353.76 365.165C1353.83 365.097 1353.94 365.097 1354.01 365.165C1354.58 365.722 1355.31 366.007 1356.03 366.007C1356.74 366.007 1357.51 365.714 1358.05 365.165C1358.63 364.601 1358.9 363.864 1358.9 363.135C1358.9 362.405 1358.62 361.668 1358.05 361.111C1357.51 360.555 1356.78 360.284 1356.03 360.284C1355.28 360.284 1354.58 360.562 1354 361.111C1353.96 361.179 1353.84 361.179 1353.77 361.126C1353.76 361.126 1353.74 361.111 1353.74 361.096C1353.73 361.096 1353.73 361.081 1353.73 361.081C1353.7 361.044 1353.69 360.999 1353.7 360.946L1354.18 356.87C1354.19 356.787 1354.24 356.734 1354.3 356.719C1354.33 356.704 1354.36 356.689 1354.4 356.689H1359.08C1359.17 356.689 1359.26 356.787 1359.26 356.87C1359.26 356.983 1359.17 357.05 1359.08 357.05H1354.53L1354.12 360.562C1354.68 360.134 1355.36 359.923 1356.04 359.923C1356.87 359.923 1357.7 360.231 1358.33 360.863C1358.94 361.487 1359.26 362.3 1359.26 363.135C1359.26 363.969 1358.94 364.797 1358.3 365.413C1357.68 366.053 1356.85 366.368 1356.03 366.368C1355.2 366.368 1354.39 366.053 1353.76 365.413H1353.76Z" fill="#E71D36"/>
</g>
<g opacity="0.69">
<path d="M1187.1 491.838C1187.06 491.808 1187.06 491.74 1187.1 491.695C1187.13 491.658 1187.19 491.658 1187.23 491.695C1187.55 492.003 1187.96 492.169 1188.36 492.169C1188.76 492.169 1189.19 492.003 1189.49 491.695C1189.81 491.379 1189.97 490.973 1189.97 490.559C1189.97 490.146 1189.82 489.74 1189.49 489.431C1189.19 489.123 1188.79 488.965 1188.37 488.965C1187.95 488.965 1187.56 489.123 1187.23 489.431C1187.21 489.469 1187.14 489.469 1187.1 489.439L1187.09 489.424L1187.08 489.416C1187.07 489.394 1187.06 489.371 1187.07 489.341L1187.34 487.07C1187.34 487.025 1187.38 486.995 1187.41 486.987C1187.42 486.98 1187.44 486.972 1187.46 486.972H1190.07C1190.12 486.972 1190.16 487.025 1190.16 487.07C1190.16 487.13 1190.12 487.168 1190.07 487.168H1187.53L1187.3 489.123C1187.62 488.882 1187.99 488.77 1188.37 488.77C1188.83 488.77 1189.3 488.943 1189.65 489.296C1189.99 489.642 1190.17 490.101 1190.17 490.559C1190.17 491.018 1190 491.485 1189.64 491.831C1189.29 492.184 1188.83 492.364 1188.37 492.364C1187.9 492.364 1187.46 492.184 1187.1 491.831L1187.1 491.838Z" fill="#5EB0EF"/>
<path d="M1191.55 487.649L1192.63 487.01C1192.67 486.98 1192.73 486.987 1192.76 487.04C1192.79 487.085 1192.77 487.138 1192.72 487.168L1191.64 487.814C1191.64 487.814 1191.61 487.822 1191.6 487.822C1191.57 487.822 1191.53 487.814 1191.52 487.777C1191.49 487.739 1191.5 487.679 1191.55 487.649ZM1192.58 487.085C1192.58 487.04 1192.63 486.987 1192.67 486.987C1192.73 486.987 1192.77 487.04 1192.77 487.085V492.274C1192.77 492.327 1192.73 492.372 1192.67 492.372C1192.63 492.372 1192.58 492.327 1192.58 492.274V487.085Z" fill="#5EB0EF"/>
</g>
<g opacity="0.8">
<path d="M1286.76 347.296H1278.49C1278.43 347.296 1278.39 347.273 1278.32 347.236C1278.22 347.138 1278.2 346.995 1278.29 346.89L1286.82 336.27L1286.85 336.233C1286.91 336.195 1286.95 336.172 1287.01 336.172C1287.1 336.172 1287.18 336.233 1287.22 336.293C1287.24 336.315 1287.24 336.33 1287.25 336.353C1287.25 336.376 1287.28 336.391 1287.28 336.413V346.792H1288.51C1288.67 346.792 1288.77 346.89 1288.77 347.032C1288.77 347.19 1288.67 347.296 1288.51 347.296H1287.28V350.048C1287.28 350.191 1287.17 350.312 1287.01 350.312C1286.89 350.312 1286.75 350.191 1286.75 350.048V347.296H1286.76ZM1286.76 346.792V337.203L1279.02 346.792H1286.76Z" fill="#E71D36"/>
<path d="M1293.33 349.943L1298.89 336.691H1291.43C1291.29 336.691 1291.17 336.571 1291.17 336.428C1291.17 336.308 1291.29 336.165 1291.43 336.165H1299.32C1299.44 336.165 1299.58 336.308 1299.58 336.428C1299.58 336.488 1299.53 336.548 1299.5 336.609L1293.8 350.139C1293.78 350.221 1293.68 350.304 1293.57 350.304C1293.55 350.304 1293.51 350.281 1293.48 350.281C1293.33 350.221 1293.3 350.078 1293.33 349.935L1293.33 349.943Z" fill="#E71D36"/>
</g>
<g opacity="0.66">
<path d="M1186.28 392.601C1186.28 392.601 1186.24 392.511 1186.28 392.473C1186.32 392.436 1186.37 392.436 1186.41 392.473C1186.69 392.751 1187.07 392.902 1187.44 392.902C1187.8 392.902 1188.19 392.751 1188.47 392.473C1188.76 392.195 1188.9 391.811 1188.9 391.435C1188.9 391.059 1188.76 390.691 1188.47 390.405C1188.19 390.127 1187.83 389.984 1187.44 389.984C1187.06 389.984 1186.71 390.127 1186.41 390.405C1186.38 390.443 1186.32 390.443 1186.29 390.405L1186.27 390.39H1186.26C1186.26 390.39 1186.24 390.337 1186.25 390.315L1186.5 388.239C1186.5 388.194 1186.53 388.171 1186.56 388.164C1186.57 388.164 1186.59 388.149 1186.61 388.149H1188.98C1189.04 388.149 1189.07 388.201 1189.07 388.239C1189.07 388.292 1189.04 388.329 1188.98 388.329H1186.67L1186.46 390.119C1186.74 389.901 1187.09 389.796 1187.44 389.796C1187.86 389.796 1188.28 389.954 1188.6 390.277C1188.91 390.593 1189.07 391.007 1189.07 391.428C1189.07 391.849 1188.92 392.27 1188.59 392.586C1188.27 392.909 1187.85 393.075 1187.42 393.075C1186.99 393.075 1186.59 392.909 1186.27 392.586L1186.28 392.601Z" fill="#E71D36"/>
<path d="M1190.53 391.202C1190.53 390.54 1190.67 389.803 1191.01 389.217C1191.35 388.623 1191.91 388.186 1192.73 388.149C1192.77 388.149 1192.82 388.186 1192.82 388.239C1192.82 388.284 1192.78 388.322 1192.74 388.322C1191.99 388.359 1191.5 388.743 1191.17 389.292C1190.91 389.721 1190.79 390.239 1190.73 390.743C1191 390.232 1191.53 389.886 1192.15 389.886C1193.03 389.886 1193.75 390.601 1193.75 391.48C1193.75 392.368 1193.03 393.082 1192.15 393.082C1191.32 393.082 1190.65 392.466 1190.56 391.668C1190.56 391.661 1190.56 391.646 1190.56 391.638C1190.55 391.495 1190.54 391.353 1190.54 391.202H1190.53ZM1192.14 392.909C1192.93 392.909 1193.55 392.278 1193.57 391.488C1193.55 390.706 1192.93 390.074 1192.14 390.074C1191.35 390.074 1190.73 390.706 1190.73 391.488C1190.73 392.27 1191.36 392.909 1192.14 392.909Z" fill="#E71D36"/>
</g>
<g opacity="0.82">
<path d="M1414.37 541.829C1414.31 541.791 1414.31 541.701 1414.37 541.648C1414.42 541.596 1414.49 541.596 1414.55 541.648C1414.96 542.054 1415.49 542.265 1416.02 542.265C1416.55 542.265 1417.09 542.054 1417.5 541.648C1417.91 541.235 1418.12 540.701 1418.12 540.167C1418.12 539.633 1417.91 539.099 1417.5 538.693C1417.09 538.287 1416.58 538.091 1416.03 538.091C1415.48 538.091 1414.97 538.294 1414.55 538.693C1414.52 538.745 1414.43 538.745 1414.37 538.7L1414.35 538.678L1414.34 538.67C1414.32 538.64 1414.31 538.61 1414.32 538.572L1414.67 535.594C1414.68 535.534 1414.73 535.496 1414.76 535.481C1414.79 535.474 1414.81 535.459 1414.83 535.459H1418.24C1418.3 535.459 1418.36 535.534 1418.36 535.587C1418.36 535.669 1418.3 535.714 1418.24 535.714H1414.92L1414.62 538.279C1415.04 537.963 1415.53 537.813 1416.02 537.813C1416.63 537.813 1417.23 538.038 1417.7 538.497C1418.14 538.948 1418.37 539.543 1418.37 540.152C1418.37 540.761 1418.14 541.363 1417.68 541.814C1417.23 542.28 1416.62 542.513 1416.02 542.513C1415.42 542.513 1414.83 542.28 1414.37 541.814V541.829Z" fill="#E71D36"/>
<path d="M1421.23 542.348L1424 535.744H1420.28C1420.21 535.744 1420.15 535.684 1420.15 535.617C1420.15 535.556 1420.21 535.489 1420.28 535.489H1424.21C1424.27 535.489 1424.34 535.556 1424.34 535.617C1424.34 535.647 1424.32 535.677 1424.3 535.707L1421.46 542.453C1421.45 542.491 1421.4 542.536 1421.35 542.536C1421.35 542.536 1421.32 542.528 1421.3 542.528C1421.23 542.498 1421.21 542.423 1421.23 542.355V542.348Z" fill="#E71D36"/>
</g>
<g opacity="0.91">
<path d="M1273.88 536.482C1275.31 537.918 1276.05 539.836 1276.05 541.716C1276.05 543.596 1275.31 545.544 1273.88 547.018C1272.41 548.455 1270.49 549.192 1268.61 549.192H1268.58C1266.69 549.192 1264.78 548.492 1263.35 547.018C1263.19 546.86 1263.19 546.605 1263.35 546.447C1263.5 546.289 1263.76 546.289 1263.92 546.447C1265.23 547.725 1266.89 548.364 1268.58 548.364C1270.27 548.364 1271.99 547.725 1273.27 546.416C1274.57 545.138 1275.21 543.446 1275.21 541.723C1275.21 540.001 1274.57 538.369 1273.3 537.061C1271.99 535.782 1270.33 535.143 1268.64 535.143C1268.48 535.143 1268.35 535.075 1268.25 534.947C1268.25 534.917 1268.22 534.917 1268.22 534.917C1268.07 534.759 1268.07 534.473 1268.22 534.308L1274.66 527.667H1264.8C1264.58 527.667 1264.39 527.472 1264.39 527.253C1264.39 527.035 1264.59 526.84 1264.8 526.84H1275.62C1275.84 526.84 1276.03 527.028 1276.03 527.253C1276.03 527.314 1276.03 527.381 1276 527.449C1275.97 527.509 1275.94 527.577 1275.9 527.607L1269.33 534.346C1270.99 534.504 1272.61 535.211 1273.86 536.482H1273.88Z" fill="#E71D36"/>
<path d="M1281.6 529.653L1286.07 527.005C1286.25 526.877 1286.51 526.908 1286.64 527.133C1286.73 527.321 1286.67 527.547 1286.45 527.675L1281.98 530.36C1281.92 530.39 1281.86 530.39 1281.8 530.39C1281.67 530.39 1281.51 530.36 1281.44 530.194C1281.35 530.036 1281.38 529.78 1281.6 529.653ZM1285.88 527.321C1285.88 527.133 1286.07 526.908 1286.26 526.908C1286.52 526.908 1286.67 527.133 1286.67 527.321V548.808C1286.67 549.034 1286.52 549.222 1286.26 549.222C1286.07 549.222 1285.88 549.034 1285.88 548.808V527.321Z" fill="#E71D36"/>
</g>
<g opacity="0.68">
<path d="M1272.72 539.738L1275.8 532.39H1271.67C1271.59 532.39 1271.52 532.323 1271.52 532.24C1271.52 532.172 1271.59 532.097 1271.67 532.097H1276.03C1276.1 532.097 1276.18 532.18 1276.18 532.24C1276.18 532.27 1276.16 532.307 1276.14 532.338L1272.98 539.836C1272.97 539.881 1272.91 539.926 1272.86 539.926C1272.85 539.926 1272.83 539.919 1272.8 539.919C1272.73 539.888 1272.7 539.806 1272.73 539.731L1272.72 539.738Z" fill="#E71D36"/>
<path d="M1280.5 532.089C1281.82 532.089 1282.91 533.112 1283.01 534.421C1283.05 534.631 1283.06 534.857 1283.06 535.083C1283.06 536.136 1282.83 537.301 1282.3 538.249C1281.74 539.189 1280.86 539.888 1279.58 539.941C1279.49 539.941 1279.43 539.888 1279.43 539.798C1279.42 539.731 1279.48 539.663 1279.55 539.663C1280.75 539.61 1281.52 538.978 1282.04 538.114C1282.42 537.437 1282.65 536.609 1282.73 535.812C1282.32 536.617 1281.46 537.166 1280.49 537.166C1279.09 537.166 1277.93 536.03 1277.93 534.631C1277.93 533.233 1279.08 532.089 1280.49 532.089H1280.5ZM1280.5 536.888C1281.74 536.888 1282.75 535.88 1282.75 534.631C1282.75 534.579 1282.75 534.519 1282.74 534.466V534.406C1282.62 533.255 1281.66 532.368 1280.5 532.368C1279.24 532.368 1278.22 533.375 1278.22 534.631C1278.22 535.887 1279.24 536.888 1280.5 536.888Z" fill="#E71D36"/>
</g>
<g opacity="0.75">
<path d="M1334.58 474.766C1335.67 475.849 1336.22 477.3 1336.22 478.729C1336.22 480.158 1335.67 481.632 1334.58 482.738C1333.47 483.828 1332.02 484.377 1330.6 484.377H1330.58C1329.15 484.377 1327.71 483.843 1326.62 482.738C1326.5 482.618 1326.5 482.422 1326.62 482.302C1326.74 482.181 1326.93 482.181 1327.05 482.302C1328.05 483.272 1329.3 483.753 1330.58 483.753C1331.86 483.753 1333.16 483.272 1334.13 482.279C1335.12 481.309 1335.6 480.03 1335.6 478.729C1335.6 477.428 1335.12 476.195 1334.15 475.202C1333.16 474.239 1331.9 473.751 1330.62 473.751C1330.5 473.751 1330.41 473.705 1330.33 473.608C1330.33 473.585 1330.31 473.585 1330.31 473.585C1330.19 473.465 1330.19 473.247 1330.31 473.126L1335.19 468.102H1327.72C1327.56 468.102 1327.41 467.96 1327.41 467.787C1327.41 467.614 1327.55 467.471 1327.72 467.471H1335.91C1336.07 467.471 1336.22 467.614 1336.22 467.787C1336.22 467.839 1336.22 467.884 1336.2 467.929C1336.18 467.975 1336.16 468.027 1336.12 468.05L1331.15 473.149C1332.4 473.269 1333.64 473.803 1334.58 474.766H1334.58Z" fill="#5EB0EF"/>
<path d="M1340.69 483.851L1348.86 476.481C1349.89 475.51 1350.45 474.209 1350.45 472.901C1350.45 471.765 1350.01 470.607 1349.19 469.689C1348.25 468.651 1346.92 468.095 1345.65 468.095C1344.49 468.095 1343.33 468.531 1342.41 469.351L1341.57 470.125C1341.42 470.246 1341.25 470.246 1341.13 470.125C1341.01 470.005 1341.03 469.787 1341.15 469.689L1341.99 468.892C1343.05 467.952 1344.34 467.493 1345.64 467.493C1347.11 467.493 1348.58 468.095 1349.64 469.261C1350.56 470.298 1351.04 471.63 1351.04 472.908C1351.04 474.382 1350.44 475.856 1349.23 476.917L1341.65 483.806H1350.75C1350.89 483.806 1351.04 483.926 1351.04 484.099C1351.04 484.272 1350.9 484.415 1350.75 484.415H1340.9C1340.8 484.415 1340.7 484.392 1340.66 484.295C1340.56 484.174 1340.56 483.979 1340.68 483.858L1340.69 483.851Z" fill="#5EB0EF"/>
</g>
<g opacity="0.67">
<path d="M1444.6 463.026C1444.6 461.815 1444.86 460.461 1445.48 459.393C1446.1 458.31 1447.13 457.513 1448.61 457.445C1448.7 457.445 1448.77 457.506 1448.77 457.611C1448.77 457.686 1448.71 457.769 1448.63 457.769C1447.25 457.829 1446.36 458.543 1445.76 459.544C1445.29 460.333 1445.05 461.273 1444.96 462.199C1445.43 461.258 1446.42 460.627 1447.55 460.627C1449.15 460.627 1450.47 461.928 1450.48 463.537C1450.47 465.162 1449.16 466.463 1447.55 466.463C1446.04 466.463 1444.8 465.342 1444.65 463.876C1444.63 463.861 1444.63 463.838 1444.63 463.823C1444.61 463.567 1444.59 463.297 1444.59 463.026H1444.6ZM1447.55 466.147C1448.99 466.147 1450.13 464.989 1450.16 463.545C1450.14 462.116 1448.99 460.958 1447.55 460.958C1446.1 460.958 1444.96 462.116 1444.96 463.545C1444.96 464.974 1446.12 466.147 1447.55 466.147Z" fill="#E71D36"/>
<path d="M1452.87 463.026C1452.87 461.815 1453.12 460.461 1453.74 459.393C1454.35 458.31 1455.38 457.513 1456.86 457.445C1456.95 457.445 1457.03 457.506 1457.03 457.611C1457.03 457.686 1456.97 457.769 1456.89 457.769C1455.51 457.829 1454.62 458.543 1454.02 459.544C1453.55 460.333 1453.31 461.273 1453.22 462.199C1453.69 461.258 1454.68 460.627 1455.81 460.627C1457.41 460.627 1458.73 461.928 1458.74 463.537C1458.73 465.162 1457.42 466.463 1455.81 466.463C1454.3 466.463 1453.06 465.342 1452.9 463.876C1452.89 463.861 1452.89 463.838 1452.89 463.823C1452.87 463.567 1452.85 463.297 1452.85 463.026H1452.87ZM1455.81 466.147C1457.26 466.147 1458.41 464.989 1458.43 463.545C1458.41 462.116 1457.26 460.958 1455.81 460.958C1454.37 460.958 1453.23 462.116 1453.23 463.545C1453.23 464.974 1454.38 466.147 1455.81 466.147Z" fill="#E71D36"/>
</g>
<g opacity="0.9">
<path d="M1214.87 194.593H1211.38C1211.38 194.593 1211.33 194.586 1211.31 194.571C1211.27 194.526 1211.26 194.465 1211.3 194.428L1214.9 189.938L1214.92 189.923C1214.92 189.923 1214.96 189.9 1214.99 189.9C1215.02 189.9 1215.05 189.923 1215.07 189.953C1215.08 189.961 1215.08 189.968 1215.08 189.976C1215.08 189.983 1215.09 189.991 1215.09 189.991V194.383H1215.61C1215.68 194.383 1215.72 194.428 1215.72 194.48C1215.72 194.548 1215.68 194.593 1215.61 194.593H1215.09V195.759C1215.09 195.819 1215.05 195.872 1214.98 195.872C1214.93 195.872 1214.87 195.819 1214.87 195.759V194.593H1214.87ZM1214.87 194.383V190.329L1211.6 194.383H1214.87Z" fill="#E71D36"/>
<path d="M1217.65 195.714L1219.99 190.118H1216.84C1216.78 190.118 1216.73 190.066 1216.73 190.006C1216.73 189.953 1216.78 189.893 1216.84 189.893H1220.17C1220.22 189.893 1220.28 189.953 1220.28 190.006C1220.28 190.028 1220.27 190.058 1220.25 190.081L1217.84 195.797C1217.84 195.797 1217.79 195.864 1217.75 195.864C1217.75 195.864 1217.73 195.857 1217.71 195.857C1217.65 195.834 1217.63 195.774 1217.65 195.714Z" fill="#E71D36"/>
</g>
<g opacity="0.8">
<path d="M1181.99 212.162C1181.93 212.117 1181.93 212.011 1181.99 211.951C1182.05 211.891 1182.14 211.891 1182.2 211.951C1182.68 212.425 1183.31 212.673 1183.92 212.673C1184.53 212.673 1185.18 212.425 1185.65 211.951C1186.14 211.47 1186.38 210.838 1186.38 210.214C1186.38 209.59 1186.14 208.966 1185.65 208.492C1185.18 208.018 1184.57 207.785 1183.93 207.785C1183.29 207.785 1182.69 208.018 1182.2 208.492C1182.16 208.552 1182.05 208.552 1181.99 208.507C1181.99 208.507 1181.97 208.492 1181.97 208.484C1181.96 208.484 1181.96 208.469 1181.96 208.469C1181.93 208.432 1181.92 208.394 1181.93 208.349L1182.35 204.867C1182.36 204.792 1182.41 204.746 1182.45 204.739C1182.48 204.724 1182.5 204.716 1182.54 204.716H1186.53C1186.61 204.716 1186.68 204.799 1186.68 204.867C1186.68 204.964 1186.61 205.017 1186.53 205.017H1182.65L1182.29 208.018C1182.78 207.649 1183.35 207.476 1183.93 207.476C1184.64 207.476 1185.35 207.74 1185.89 208.281C1186.41 208.815 1186.68 209.507 1186.68 210.214C1186.68 210.921 1186.41 211.628 1185.87 212.162C1185.34 212.703 1184.63 212.974 1183.93 212.974C1183.22 212.974 1182.54 212.703 1181.99 212.162H1181.99Z" fill="#E71D36"/>
<path d="M1193.9 211.222H1189.07C1189.07 211.222 1189.01 211.207 1188.98 211.184C1188.92 211.124 1188.91 211.041 1188.95 210.981L1193.93 204.784L1193.95 204.761C1193.95 204.761 1194.01 204.724 1194.05 204.724C1194.09 204.724 1194.14 204.761 1194.17 204.792C1194.18 204.807 1194.18 204.814 1194.19 204.829C1194.19 204.844 1194.21 204.852 1194.21 204.852V210.921H1194.93C1195.02 210.921 1195.08 210.981 1195.08 211.064C1195.08 211.162 1195.02 211.214 1194.93 211.214H1194.21V212.816C1194.21 212.899 1194.15 212.967 1194.06 212.967C1193.98 212.967 1193.9 212.899 1193.9 212.816V211.214V211.222ZM1193.9 210.929V205.333L1189.38 210.929H1193.9Z" fill="#E71D36"/>
</g>
<g opacity="0.71">
<path d="M1206.48 215.697C1207.61 216.832 1208.2 218.351 1208.2 219.848C1208.2 221.345 1207.62 222.886 1206.48 224.052C1205.31 225.195 1203.8 225.774 1202.3 225.774H1202.28C1200.78 225.774 1199.26 225.218 1198.13 224.052C1198 223.924 1198 223.721 1198.13 223.601C1198.26 223.473 1198.46 223.473 1198.59 223.601C1199.62 224.616 1200.94 225.12 1202.28 225.12C1203.62 225.12 1204.98 224.616 1206 223.578C1207.03 222.563 1207.54 221.224 1207.54 219.856C1207.54 218.487 1207.03 217.201 1206.02 216.163C1204.98 215.148 1203.67 214.644 1202.32 214.644C1202.19 214.644 1202.1 214.591 1202.02 214.493C1202.02 214.471 1202 214.471 1202 214.471C1201.87 214.343 1201.87 214.117 1202 213.989L1207.11 208.725H1199.29C1199.11 208.725 1198.96 208.574 1198.96 208.394C1198.96 208.213 1199.11 208.063 1199.29 208.063H1207.87C1208.05 208.063 1208.2 208.213 1208.2 208.394C1208.2 208.447 1208.2 208.492 1208.18 208.544C1208.15 208.597 1208.12 208.642 1208.1 208.672L1202.89 214.012C1204.21 214.14 1205.5 214.696 1206.49 215.712L1206.48 215.697Z" fill="#E71D36"/>
<path d="M1215.34 225.346L1222.3 208.762H1212.96C1212.79 208.762 1212.63 208.612 1212.63 208.432C1212.63 208.281 1212.78 208.101 1212.96 208.101H1222.83C1222.98 208.101 1223.16 208.274 1223.16 208.432C1223.16 208.507 1223.11 208.582 1223.06 208.657L1215.93 225.594C1215.91 225.692 1215.78 225.797 1215.65 225.797C1215.63 225.797 1215.58 225.774 1215.53 225.774C1215.35 225.699 1215.3 225.519 1215.35 225.346H1215.34Z" fill="#E71D36"/>
</g>
<g opacity="0.87">
<path d="M1100.92 253.948V253.91C1100.92 253.91 1100.94 253.902 1100.96 253.91C1101.04 253.993 1101.15 254.03 1101.25 254.03C1101.36 254.03 1101.47 253.985 1101.54 253.91C1101.62 253.835 1101.66 253.722 1101.66 253.617C1101.66 253.511 1101.63 253.406 1101.54 253.323C1101.46 253.241 1101.36 253.203 1101.25 253.203C1101.15 253.203 1101.04 253.241 1100.96 253.323C1100.96 253.331 1100.93 253.331 1100.93 253.323C1100.93 253.323 1100.93 253.308 1100.93 253.301L1101 252.707C1101 252.707 1101 252.684 1101.01 252.684C1101.01 252.684 1101.02 252.684 1101.03 252.684H1101.7C1101.7 252.684 1101.72 252.699 1101.72 252.707C1101.72 252.722 1101.71 252.729 1101.7 252.729H1101.04L1100.98 253.241C1101.06 253.18 1101.16 253.15 1101.26 253.15C1101.38 253.15 1101.5 253.195 1101.59 253.286C1101.68 253.376 1101.72 253.496 1101.72 253.617C1101.72 253.737 1101.68 253.857 1101.59 253.948C1101.5 254.038 1101.38 254.083 1101.26 254.083C1101.14 254.083 1101.02 254.038 1100.93 253.948H1100.92Z" fill="#E71D36"/>
<path d="M1102.57 252.677C1102.72 252.677 1102.85 252.804 1102.85 252.955C1102.85 253.068 1102.78 253.165 1102.69 253.21C1102.88 253.263 1103.02 253.436 1103.02 253.639C1103.02 253.887 1102.82 254.083 1102.57 254.083C1102.32 254.083 1102.12 253.887 1102.12 253.639C1102.12 253.436 1102.26 253.263 1102.45 253.21C1102.35 253.165 1102.29 253.068 1102.29 252.955C1102.29 252.797 1102.42 252.677 1102.57 252.677ZM1102.57 254.038C1102.79 254.038 1102.97 253.865 1102.97 253.639C1102.97 253.414 1102.79 253.241 1102.57 253.241C1102.36 253.241 1102.17 253.421 1102.17 253.639C1102.17 253.857 1102.36 254.038 1102.57 254.038ZM1102.57 253.188C1102.7 253.188 1102.8 253.083 1102.8 252.962C1102.8 252.842 1102.7 252.737 1102.57 252.737C1102.45 252.737 1102.34 252.842 1102.34 252.962C1102.34 253.083 1102.45 253.188 1102.57 253.188Z" fill="#E71D36"/>
</g>
<g opacity="0.69">
<path d="M1166.86 309.271H1157.2C1157.13 309.271 1157.08 309.248 1157.01 309.203C1156.89 309.083 1156.87 308.917 1156.97 308.804L1166.93 296.402L1166.97 296.357C1167.04 296.312 1167.09 296.282 1167.16 296.282C1167.25 296.282 1167.35 296.357 1167.39 296.425C1167.41 296.448 1167.41 296.47 1167.44 296.5C1167.44 296.523 1167.46 296.545 1167.46 296.568V308.684H1168.9C1169.09 308.684 1169.21 308.804 1169.21 308.97C1169.21 309.158 1169.09 309.278 1168.9 309.278H1167.46V312.489C1167.46 312.655 1167.34 312.798 1167.15 312.798C1167.01 312.798 1166.84 312.655 1166.84 312.489V309.278L1166.86 309.271ZM1166.86 308.684V297.485L1157.82 308.684H1166.86Z" fill="#5EB0EF"/>
</g>
<g opacity="0.98">
<path d="M1207.38 277.292H1203.9C1203.9 277.292 1203.86 277.284 1203.83 277.269C1203.79 277.224 1203.78 277.164 1203.82 277.126L1207.41 272.659L1207.43 272.644C1207.43 272.644 1207.47 272.621 1207.49 272.621C1207.53 272.621 1207.56 272.644 1207.58 272.674C1207.58 272.682 1207.58 272.689 1207.58 272.689C1207.59 272.704 1207.6 272.712 1207.6 272.712V277.089H1208.12C1208.19 277.089 1208.23 277.134 1208.23 277.194C1208.23 277.262 1208.19 277.307 1208.12 277.307H1207.6V278.465C1207.6 278.525 1207.55 278.578 1207.49 278.578C1207.43 278.578 1207.37 278.525 1207.37 278.465V277.307L1207.38 277.292ZM1207.38 277.081V273.05L1204.13 277.081H1207.38Z" fill="#E71D36"/>
<path d="M1209.58 276.292C1209.58 275.494 1209.75 274.599 1210.15 273.892C1210.56 273.178 1211.24 272.652 1212.22 272.606C1212.28 272.606 1212.33 272.652 1212.33 272.719C1212.33 272.772 1212.29 272.825 1212.24 272.825C1211.33 272.87 1210.74 273.336 1210.34 273.998C1210.03 274.517 1209.88 275.141 1209.82 275.75C1210.13 275.126 1210.78 274.712 1211.53 274.712C1212.59 274.712 1213.46 275.57 1213.47 276.638C1213.46 277.713 1212.6 278.57 1211.53 278.57C1210.53 278.57 1209.72 277.826 1209.61 276.863C1209.61 276.856 1209.61 276.841 1209.61 276.841C1209.59 276.66 1209.58 276.48 1209.58 276.307L1209.58 276.292ZM1211.52 278.352C1212.48 278.352 1213.23 277.585 1213.25 276.638C1213.24 275.69 1212.48 274.923 1211.52 274.923C1210.57 274.923 1209.81 275.69 1209.81 276.638C1209.81 277.585 1210.58 278.352 1211.52 278.352Z" fill="#E71D36"/>
</g>
<g opacity="0.87">
<path d="M1246.41 364.827C1249.04 364.827 1251.19 366.857 1251.42 369.46C1251.49 369.881 1251.51 370.332 1251.51 370.776C1251.51 372.874 1251.07 375.19 1250 377.056C1248.89 378.928 1247.15 380.312 1244.59 380.417C1244.42 380.417 1244.3 380.305 1244.3 380.124C1244.28 379.989 1244.42 379.861 1244.54 379.861C1246.93 379.748 1248.46 378.5 1249.49 376.785C1250.25 375.446 1250.71 373.799 1250.87 372.22C1250.04 373.822 1248.35 374.92 1246.42 374.92C1243.63 374.92 1241.33 372.671 1241.33 369.881C1241.33 367.09 1243.63 364.827 1246.42 364.827H1246.41ZM1246.41 374.363C1248.88 374.363 1250.88 372.355 1250.88 369.888C1250.88 369.775 1250.88 369.663 1250.86 369.557V369.444C1250.63 367.151 1248.71 365.391 1246.4 365.391C1243.9 365.391 1241.88 367.391 1241.88 369.888C1241.88 372.385 1243.91 374.363 1246.4 374.363H1246.41Z" fill="#E71D36"/>
</g>
<g opacity="0.71">
<path d="M1177.94 307.074L1180.55 300.855H1177.05C1176.98 300.855 1176.93 300.795 1176.93 300.727C1176.93 300.674 1176.99 300.607 1177.05 300.607H1180.75C1180.81 300.607 1180.87 300.674 1180.87 300.727C1180.87 300.757 1180.85 300.787 1180.84 300.81L1178.16 307.165C1178.16 307.165 1178.1 307.24 1178.06 307.24C1178.05 307.24 1178.03 307.232 1178.01 307.232C1177.94 307.202 1177.93 307.135 1177.94 307.074Z" fill="#5EB0EF"/>
<path d="M1185.56 303.457C1185.99 303.886 1186.2 304.457 1186.2 305.014C1186.2 305.57 1185.99 306.157 1185.56 306.593C1185.12 307.022 1184.55 307.24 1183.99 307.24H1183.99C1183.43 307.24 1182.86 307.029 1182.43 306.593C1182.39 306.548 1182.39 306.473 1182.43 306.42C1182.48 306.375 1182.56 306.375 1182.6 306.42C1182.99 306.804 1183.48 306.992 1183.99 306.992C1184.49 306.992 1185 306.804 1185.38 306.413C1185.77 306.037 1185.96 305.533 1185.96 305.014C1185.96 304.495 1185.77 304.013 1185.39 303.63C1185 303.254 1184.5 303.058 1184.01 303.058C1183.96 303.058 1183.93 303.036 1183.9 302.998H1183.89C1183.84 302.938 1183.84 302.855 1183.89 302.81L1185.8 300.832H1182.87C1182.81 300.832 1182.75 300.772 1182.75 300.712C1182.75 300.652 1182.81 300.591 1182.87 300.591H1186.09C1186.16 300.591 1186.21 300.652 1186.21 300.712C1186.21 300.727 1186.21 300.749 1186.2 300.772C1186.2 300.787 1186.18 300.81 1186.17 300.817L1184.22 302.818C1184.72 302.863 1185.2 303.073 1185.56 303.457H1185.56Z" fill="#5EB0EF"/>
</g>
<g opacity="0.75">
<path d="M1189.67 408.801C1189.67 408.034 1189.84 407.169 1190.23 406.492C1190.62 405.8 1191.28 405.296 1192.22 405.251C1192.28 405.251 1192.33 405.296 1192.33 405.356C1192.33 405.409 1192.29 405.454 1192.24 405.454C1191.36 405.499 1190.79 405.943 1190.41 406.582C1190.12 407.086 1189.96 407.68 1189.9 408.267C1190.2 407.665 1190.82 407.267 1191.55 407.267C1192.57 407.267 1193.4 408.094 1193.41 409.117C1193.4 410.147 1192.57 410.974 1191.55 410.974C1190.59 410.974 1189.8 410.26 1189.7 409.327C1189.7 409.32 1189.7 409.305 1189.7 409.29C1189.68 409.124 1189.67 408.951 1189.67 408.786V408.801ZM1191.55 410.786C1192.46 410.786 1193.19 410.049 1193.21 409.132C1193.19 408.222 1192.46 407.485 1191.55 407.485C1190.63 407.485 1189.9 408.222 1189.9 409.132C1189.9 410.042 1190.64 410.786 1191.55 410.786Z" fill="#5EB0EF"/>
<path d="M1196.73 405.259C1197.7 405.259 1198.49 406.003 1198.57 406.966C1198.6 407.124 1198.6 407.289 1198.6 407.447C1198.6 408.214 1198.44 409.072 1198.05 409.756C1197.63 410.44 1197 410.952 1196.06 410.997C1195.99 410.997 1195.95 410.959 1195.95 410.892C1195.94 410.839 1195.99 410.794 1196.04 410.794C1196.92 410.756 1197.48 410.297 1197.86 409.666C1198.14 409.177 1198.31 408.568 1198.36 407.989C1198.06 408.575 1197.44 408.981 1196.72 408.981C1195.7 408.981 1194.86 408.154 1194.86 407.131C1194.86 406.108 1195.7 405.274 1196.72 405.274L1196.73 405.259ZM1196.73 408.763C1197.64 408.763 1198.38 408.026 1198.38 407.116C1198.38 407.079 1198.38 407.033 1198.37 406.996V406.966C1198.29 406.116 1197.58 405.469 1196.73 405.469C1195.81 405.469 1195.07 406.206 1195.07 407.124C1195.07 408.041 1195.81 408.771 1196.73 408.771V408.763Z" fill="#5EB0EF"/>
</g>
<g opacity="0.63">
<path d="M1262.19 372.4C1262.59 372.799 1262.79 373.333 1262.79 373.852C1262.79 374.371 1262.59 374.912 1262.19 375.318C1261.78 375.717 1261.25 375.92 1260.73 375.92H1260.72C1260.21 375.92 1259.67 375.724 1259.27 375.318C1259.23 375.273 1259.23 375.205 1259.27 375.16C1259.32 375.115 1259.39 375.115 1259.43 375.16C1259.79 375.514 1260.25 375.694 1260.72 375.694C1261.2 375.694 1261.67 375.514 1262.02 375.153C1262.39 374.799 1262.57 374.333 1262.57 373.852C1262.57 373.37 1262.39 372.919 1262.04 372.558C1261.68 372.205 1261.22 372.024 1260.75 372.024C1260.7 372.024 1260.66 372.009 1260.64 371.971H1260.63C1260.59 371.919 1260.59 371.836 1260.63 371.791L1262.42 369.948H1259.69C1259.63 369.948 1259.57 369.896 1259.57 369.835C1259.57 369.775 1259.63 369.723 1259.69 369.723H1262.69C1262.75 369.723 1262.8 369.775 1262.8 369.835C1262.8 369.851 1262.8 369.873 1262.79 369.888C1262.78 369.903 1262.78 369.926 1262.76 369.933L1260.94 371.798C1261.4 371.844 1261.85 372.039 1262.2 372.393L1262.19 372.4Z" fill="#E71D36"/>
<path d="M1268.16 374.611H1264.54C1264.54 374.611 1264.5 374.604 1264.47 374.589C1264.43 374.543 1264.42 374.483 1264.46 374.438L1268.19 369.79L1268.2 369.775C1268.2 369.775 1268.25 369.753 1268.27 369.753C1268.31 369.753 1268.34 369.775 1268.36 369.805C1268.37 369.813 1268.37 369.82 1268.38 369.835C1268.38 369.835 1268.38 369.851 1268.38 369.858V374.401H1268.92C1268.99 374.401 1269.04 374.446 1269.04 374.506C1269.04 374.574 1268.99 374.619 1268.92 374.619H1268.38V375.822C1268.38 375.882 1268.34 375.935 1268.27 375.935C1268.22 375.935 1268.16 375.882 1268.16 375.822V374.619V374.611ZM1268.16 374.393V370.196L1264.77 374.393H1268.16Z" fill="#E71D36"/>
</g>
<g opacity="0.61">
<path d="M1272.3 372.475C1272.25 372.438 1272.25 372.363 1272.3 372.317C1272.34 372.272 1272.41 372.272 1272.46 372.317C1272.81 372.663 1273.26 372.836 1273.7 372.836C1274.15 372.836 1274.62 372.656 1274.96 372.317C1275.31 371.964 1275.48 371.513 1275.48 371.061C1275.48 370.61 1275.3 370.151 1274.96 369.813C1274.61 369.467 1274.17 369.302 1273.71 369.302C1273.25 369.302 1272.81 369.475 1272.46 369.813C1272.43 369.858 1272.35 369.858 1272.31 369.821C1272.31 369.821 1272.3 369.813 1272.3 369.805H1272.29C1272.29 369.805 1272.27 369.745 1272.28 369.708L1272.58 367.181C1272.58 367.128 1272.62 367.098 1272.65 367.09C1272.67 367.083 1272.69 367.075 1272.71 367.075H1275.6C1275.66 367.075 1275.72 367.136 1275.72 367.188C1275.72 367.256 1275.66 367.301 1275.6 367.301H1272.79L1272.53 369.475C1272.88 369.211 1273.3 369.083 1273.72 369.083C1274.23 369.083 1274.75 369.271 1275.14 369.663C1275.51 370.046 1275.71 370.55 1275.71 371.069C1275.71 371.588 1275.51 372.099 1275.12 372.483C1274.73 372.874 1274.21 373.069 1273.7 373.069C1273.19 373.069 1272.7 372.874 1272.3 372.483V372.475Z" fill="#E71D36"/>
<path d="M1279.4 367.068C1280.41 367.068 1281.24 367.85 1281.32 368.85C1281.35 369.016 1281.35 369.181 1281.35 369.354C1281.35 370.159 1281.18 371.054 1280.78 371.768C1280.35 372.49 1279.68 373.017 1278.69 373.062C1278.63 373.062 1278.58 373.017 1278.58 372.949C1278.57 372.896 1278.63 372.844 1278.67 372.844C1279.59 372.799 1280.18 372.325 1280.57 371.663C1280.87 371.152 1281.05 370.512 1281.11 369.911C1280.79 370.527 1280.14 370.949 1279.39 370.949C1278.33 370.949 1277.44 370.084 1277.44 369.016C1277.44 367.948 1278.32 367.068 1279.39 367.068H1279.4ZM1279.4 370.738C1280.35 370.738 1281.12 369.971 1281.12 369.016C1281.12 368.971 1281.12 368.933 1281.11 368.888V368.85C1281.03 367.963 1280.29 367.286 1279.4 367.286C1278.44 367.286 1277.66 368.053 1277.66 369.016C1277.66 369.978 1278.45 370.738 1279.4 370.738Z" fill="#E71D36"/>
</g>
<g opacity="0.88">
<path d="M1212.11 395.865C1212.06 395.827 1212.06 395.752 1212.11 395.707C1212.15 395.662 1212.22 395.662 1212.27 395.707C1212.62 396.053 1213.08 396.234 1213.53 396.234C1213.97 396.234 1214.46 396.053 1214.81 395.707C1215.16 395.354 1215.34 394.887 1215.34 394.429C1215.34 393.97 1215.17 393.511 1214.81 393.158C1214.46 392.812 1214.01 392.639 1213.54 392.639C1213.06 392.639 1212.63 392.812 1212.26 393.158C1212.23 393.203 1212.15 393.203 1212.11 393.165L1212.09 393.15L1212.09 393.143C1212.07 393.12 1212.06 393.09 1212.07 393.052L1212.37 390.488C1212.38 390.435 1212.42 390.405 1212.45 390.39C1212.46 390.382 1212.48 390.375 1212.51 390.375H1215.44C1215.5 390.375 1215.55 390.435 1215.55 390.488C1215.55 390.555 1215.5 390.601 1215.44 390.601H1212.58L1212.32 392.804C1212.67 392.533 1213.1 392.406 1213.53 392.406C1214.05 392.406 1214.57 392.594 1214.97 393C1215.35 393.391 1215.55 393.902 1215.55 394.421C1215.55 394.94 1215.35 395.466 1214.95 395.858C1214.56 396.256 1214.04 396.459 1213.51 396.459C1212.99 396.459 1212.49 396.256 1212.09 395.858L1212.11 395.865Z" fill="#E71D36"/>
<path d="M1220.88 395.173H1217.33C1217.33 395.173 1217.29 395.166 1217.26 395.151C1217.22 395.105 1217.21 395.045 1217.25 395L1220.91 390.443L1220.92 390.428C1220.92 390.428 1220.97 390.405 1220.99 390.405C1221.03 390.405 1221.06 390.428 1221.08 390.458C1221.09 390.465 1221.09 390.473 1221.1 390.48C1221.1 390.488 1221.1 390.495 1221.1 390.503V394.963H1221.64C1221.71 394.963 1221.75 395.008 1221.75 395.068C1221.75 395.136 1221.71 395.181 1221.64 395.181H1221.1V396.361C1221.1 396.422 1221.06 396.474 1220.99 396.474C1220.94 396.474 1220.88 396.422 1220.88 396.361V395.181V395.173ZM1220.88 394.955V390.841L1217.56 394.955H1220.88Z" fill="#E71D36"/>
</g>
<g opacity="0.89">
<path d="M1324.4 404.198C1324.4 402.393 1324.78 400.385 1325.7 398.791C1326.62 397.181 1328.15 395.993 1330.35 395.895C1330.49 395.895 1330.6 395.993 1330.6 396.143C1330.6 396.256 1330.5 396.376 1330.39 396.376C1328.34 396.474 1327.02 397.527 1326.12 399.024C1325.43 400.19 1325.07 401.588 1324.93 402.972C1325.64 401.573 1327.1 400.633 1328.78 400.633C1331.17 400.633 1333.13 402.566 1333.15 404.965C1333.13 407.379 1331.18 409.312 1328.78 409.312C1326.54 409.312 1324.7 407.643 1324.48 405.462C1324.45 405.439 1324.45 405.401 1324.45 405.386C1324.42 405.003 1324.39 404.604 1324.39 404.198H1324.4ZM1328.78 408.838C1330.92 408.838 1332.63 407.116 1332.67 404.965C1332.63 402.837 1330.92 401.115 1328.78 401.115C1326.64 401.115 1324.93 402.837 1324.93 404.965C1324.93 407.094 1326.66 408.838 1328.78 408.838Z" fill="#E71D36"/>
<path d="M1335.82 397.572L1338.5 395.985C1338.61 395.91 1338.77 395.925 1338.85 396.061C1338.91 396.173 1338.86 396.309 1338.73 396.384L1336.05 397.993C1336.01 398.016 1335.97 398.016 1335.94 398.016C1335.86 398.016 1335.76 397.993 1335.73 397.903C1335.67 397.805 1335.69 397.655 1335.82 397.58L1335.82 397.572ZM1338.38 396.173C1338.38 396.061 1338.49 395.925 1338.61 395.925C1338.76 395.925 1338.86 396.061 1338.86 396.173V409.072C1338.86 409.207 1338.76 409.32 1338.61 409.32C1338.5 409.32 1338.38 409.207 1338.38 409.072V396.173Z" fill="#E71D36"/>
</g>
<g opacity="0.84">
<path d="M1301.51 342.708C1302.79 342.716 1303.74 344.212 1303.74 345.972C1303.74 347.732 1302.79 349.244 1301.51 349.251C1300.25 349.244 1299.28 347.747 1299.28 345.972C1299.28 344.197 1300.25 342.716 1301.51 342.708ZM1299.52 345.972C1299.52 346.829 1299.76 347.597 1300.13 348.138C1300.49 348.702 1300.98 349.018 1301.51 349.018C1302.04 349.018 1302.54 348.702 1302.9 348.138C1303.28 347.597 1303.51 346.829 1303.51 345.972C1303.51 345.115 1303.28 344.363 1302.9 343.821C1302.53 343.265 1302.04 342.941 1301.51 342.941C1300.98 342.941 1300.49 343.257 1300.13 343.821C1299.77 344.363 1299.52 345.13 1299.52 345.972Z" fill="#5EB0EF"/>
</g>
<g opacity="0.76">
<path d="M1276.11 500.63L1278.25 499.359C1278.34 499.299 1278.47 499.314 1278.53 499.419C1278.57 499.509 1278.54 499.615 1278.44 499.682L1276.3 500.968C1276.3 500.968 1276.24 500.983 1276.21 500.983C1276.15 500.983 1276.07 500.968 1276.03 500.893C1275.99 500.818 1276 500.698 1276.11 500.63ZM1278.16 499.509C1278.16 499.419 1278.25 499.314 1278.35 499.314C1278.47 499.314 1278.54 499.419 1278.54 499.509V509.828C1278.54 509.933 1278.47 510.023 1278.35 510.023C1278.26 510.023 1278.16 509.933 1278.16 509.828V499.509Z" fill="#E71D36"/>
<path d="M1286.88 503.909C1287.57 504.601 1287.91 505.518 1287.91 506.421C1287.91 507.323 1287.56 508.263 1286.88 508.963C1286.17 509.655 1285.25 510.008 1284.35 510.008H1284.34C1283.44 510.008 1282.52 509.67 1281.83 508.963C1281.75 508.888 1281.75 508.767 1281.83 508.685C1281.9 508.609 1282.03 508.609 1282.11 508.685C1282.74 509.301 1283.53 509.602 1284.34 509.602C1285.16 509.602 1285.98 509.294 1286.6 508.67C1287.23 508.053 1287.53 507.241 1287.53 506.413C1287.53 505.586 1287.22 504.804 1286.61 504.172C1285.98 503.555 1285.19 503.255 1284.37 503.255C1284.3 503.255 1284.24 503.225 1284.19 503.164C1284.19 503.149 1284.18 503.149 1284.18 503.149C1284.1 503.074 1284.1 502.931 1284.18 502.856L1287.28 499.667H1282.54C1282.44 499.667 1282.34 499.577 1282.34 499.472C1282.34 499.366 1282.43 499.276 1282.54 499.276H1287.73C1287.84 499.276 1287.93 499.366 1287.93 499.472C1287.93 499.502 1287.93 499.532 1287.91 499.562C1287.9 499.592 1287.88 499.622 1287.87 499.637L1284.71 502.871C1285.51 502.946 1286.29 503.285 1286.89 503.901L1286.88 503.909Z" fill="#E71D36"/>
</g>
<g opacity="0.51">
<path d="M1303.44 459.265L1305.12 455.279H1302.87C1302.82 455.279 1302.79 455.242 1302.79 455.197C1302.79 455.159 1302.83 455.114 1302.87 455.114H1305.24C1305.24 455.114 1305.32 455.159 1305.32 455.197C1305.32 455.212 1305.3 455.234 1305.3 455.249L1303.58 459.318C1303.58 459.318 1303.55 459.363 1303.52 459.363C1303.52 459.363 1303.5 459.363 1303.49 459.363C1303.44 459.348 1303.43 459.303 1303.44 459.258V459.265Z" fill="#E71D36"/>
<path d="M1306.21 458.957C1306.21 458.957 1306.18 458.874 1306.21 458.844C1306.24 458.814 1306.3 458.814 1306.33 458.844C1306.57 459.085 1306.9 459.213 1307.21 459.213C1307.53 459.213 1307.87 459.085 1308.11 458.844C1308.36 458.596 1308.48 458.273 1308.48 457.949C1308.48 457.626 1308.36 457.303 1308.11 457.062C1307.87 456.821 1307.55 456.693 1307.22 456.693C1306.89 456.693 1306.58 456.814 1306.33 457.062C1306.3 457.092 1306.25 457.092 1306.22 457.062C1306.22 457.062 1306.21 457.062 1306.21 457.047C1306.21 457.047 1306.19 457.002 1306.19 456.979L1306.4 455.182C1306.4 455.182 1306.43 455.122 1306.45 455.114C1306.47 455.114 1306.48 455.099 1306.5 455.099H1308.55C1308.6 455.099 1308.63 455.144 1308.63 455.182C1308.63 455.234 1308.59 455.264 1308.55 455.264H1306.55L1306.37 456.814C1306.62 456.626 1306.92 456.535 1307.21 456.535C1307.58 456.535 1307.94 456.671 1308.22 456.949C1308.49 457.22 1308.63 457.581 1308.63 457.949C1308.63 458.318 1308.49 458.679 1308.21 458.957C1307.93 459.235 1307.57 459.378 1307.21 459.378C1306.85 459.378 1306.48 459.235 1306.21 458.957H1306.21Z" fill="#E71D36"/>
</g>
<g opacity="0.55">
<path d="M1326.14 538.068C1326.14 535.489 1326.69 532.608 1328 530.33C1329.32 528.021 1331.51 526.321 1334.67 526.186C1334.86 526.186 1335.03 526.321 1335.03 526.539C1335.03 526.704 1334.89 526.87 1334.73 526.87C1331.8 527.005 1329.9 528.517 1328.61 530.66C1327.63 532.338 1327.1 534.338 1326.91 536.316C1327.93 534.316 1330.01 532.969 1332.43 532.969C1335.85 532.969 1338.65 535.744 1338.68 539.174C1338.65 542.634 1335.85 545.401 1332.43 545.401C1329.22 545.401 1326.59 543.017 1326.26 539.888C1326.23 539.858 1326.23 539.806 1326.23 539.783C1326.17 539.234 1326.14 538.655 1326.14 538.083L1326.14 538.068ZM1332.42 544.709C1335.49 544.709 1337.93 542.235 1337.99 539.166C1337.94 536.121 1335.49 533.654 1332.42 533.654C1329.35 533.654 1326.9 536.128 1326.9 539.166C1326.9 542.205 1329.37 544.709 1332.42 544.709Z" fill="#E71D36"/>
<path d="M1345.99 544.905L1353.53 526.93H1343.41C1343.22 526.93 1343.05 526.765 1343.05 526.569C1343.05 526.404 1343.22 526.216 1343.41 526.216H1354.11C1354.27 526.216 1354.46 526.404 1354.46 526.569C1354.46 526.652 1354.41 526.735 1354.35 526.817L1346.62 545.176C1346.59 545.281 1346.45 545.394 1346.32 545.394C1346.29 545.394 1346.23 545.364 1346.18 545.364C1345.98 545.281 1345.93 545.093 1345.98 544.897L1345.99 544.905Z" fill="#E71D36"/>
</g>
<g opacity="0.81">
<path d="M1274.14 565.512C1274.75 566.121 1275.06 566.933 1275.06 567.73C1275.06 568.528 1274.75 569.355 1274.14 569.979C1273.52 570.588 1272.7 570.897 1271.91 570.897H1271.89C1271.1 570.897 1270.28 570.596 1269.67 569.979C1269.61 569.911 1269.61 569.799 1269.67 569.738C1269.74 569.671 1269.86 569.671 1269.92 569.738C1270.47 570.28 1271.17 570.551 1271.89 570.551C1272.61 570.551 1273.34 570.28 1273.88 569.723C1274.44 569.182 1274.71 568.467 1274.71 567.73C1274.71 566.993 1274.44 566.309 1273.9 565.752C1273.34 565.211 1272.64 564.94 1271.92 564.94C1271.85 564.94 1271.8 564.918 1271.76 564.857C1271.76 564.842 1271.74 564.842 1271.74 564.842C1271.67 564.775 1271.67 564.654 1271.74 564.587L1274.48 561.774H1270.3C1270.2 561.774 1270.13 561.691 1270.13 561.601C1270.13 561.511 1270.21 561.428 1270.3 561.428H1274.88C1274.98 561.428 1275.06 561.511 1275.06 561.601C1275.06 561.631 1275.06 561.654 1275.04 561.684C1275.03 561.714 1275.01 561.736 1275 561.751L1272.21 564.609C1272.92 564.677 1273.61 564.978 1274.14 565.519V565.512Z" fill="#E71D36"/>
<path d="M1277.56 570.603L1282.13 566.474C1282.71 565.933 1283.02 565.203 1283.02 564.474C1283.02 563.835 1282.78 563.188 1282.32 562.676C1281.79 562.097 1281.05 561.781 1280.33 561.781C1279.68 561.781 1279.03 562.03 1278.52 562.488L1278.05 562.925C1277.96 562.992 1277.87 562.992 1277.81 562.925C1277.74 562.857 1277.75 562.737 1277.82 562.684L1278.29 562.24C1278.89 561.714 1279.61 561.458 1280.34 561.458C1281.17 561.458 1281.98 561.796 1282.59 562.443C1283.1 563.022 1283.37 563.767 1283.37 564.489C1283.37 565.316 1283.03 566.143 1282.35 566.738L1278.11 570.596H1283.2C1283.28 570.596 1283.37 570.663 1283.37 570.761C1283.37 570.859 1283.28 570.934 1283.2 570.934H1277.69C1277.63 570.934 1277.58 570.919 1277.55 570.867C1277.5 570.799 1277.5 570.694 1277.57 570.626L1277.56 570.603Z" fill="#E71D36"/>
</g>
<g opacity="0.59">
<path d="M1335.64 550.177C1336.09 550.621 1336.31 551.215 1336.31 551.794C1336.31 552.373 1336.09 552.982 1335.64 553.433C1335.19 553.877 1334.6 554.103 1334.01 554.103H1334.01C1333.43 554.103 1332.83 553.885 1332.39 553.433C1332.34 553.381 1332.34 553.306 1332.39 553.253C1332.44 553.2 1332.52 553.2 1332.56 553.253C1332.97 553.652 1333.48 553.847 1334.01 553.847C1334.53 553.847 1335.06 553.652 1335.46 553.245C1335.86 552.847 1336.06 552.328 1336.06 551.794C1336.06 551.26 1335.86 550.756 1335.46 550.35C1335.06 549.959 1334.55 549.756 1334.02 549.756C1333.98 549.756 1333.93 549.733 1333.9 549.696C1333.9 549.688 1333.89 549.688 1333.89 549.688C1333.84 549.635 1333.84 549.545 1333.89 549.5L1335.88 547.447H1332.83C1332.77 547.447 1332.71 547.387 1332.71 547.319C1332.71 547.251 1332.77 547.191 1332.83 547.191H1336.18C1336.25 547.191 1336.31 547.251 1336.31 547.319C1336.31 547.342 1336.31 547.357 1336.3 547.379C1336.29 547.402 1336.28 547.417 1336.27 547.432L1334.24 549.515C1334.75 549.568 1335.25 549.778 1335.64 550.177H1335.64Z" fill="#E71D36"/>
<path d="M1340.51 547.206C1341.68 547.206 1342.63 548.101 1342.74 549.259C1342.77 549.447 1342.78 549.643 1342.78 549.839C1342.78 550.771 1342.58 551.794 1342.11 552.621C1341.61 553.448 1340.84 554.065 1339.71 554.11C1339.63 554.11 1339.58 554.058 1339.58 553.982C1339.57 553.922 1339.63 553.862 1339.69 553.862C1340.75 553.81 1341.42 553.26 1341.88 552.501C1342.22 551.907 1342.42 551.177 1342.49 550.478C1342.12 551.192 1341.38 551.674 1340.51 551.674C1339.28 551.674 1338.27 550.673 1338.27 549.44C1338.27 548.207 1339.28 547.199 1340.51 547.199V547.206ZM1340.51 551.433C1341.61 551.433 1342.5 550.545 1342.5 549.447C1342.5 549.395 1342.5 549.35 1342.49 549.297V549.259C1342.39 548.229 1341.54 547.454 1340.51 547.454C1339.41 547.454 1338.51 548.342 1338.51 549.447C1338.51 550.553 1339.41 551.433 1340.51 551.433Z" fill="#E71D36"/>
</g>
<g opacity="0.58">
<path d="M1234.69 553.561L1236.76 552.335C1236.85 552.275 1236.96 552.29 1237.02 552.396C1237.07 552.486 1237.04 552.591 1236.93 552.644L1234.86 553.885C1234.86 553.885 1234.8 553.9 1234.77 553.9C1234.71 553.9 1234.64 553.885 1234.61 553.809C1234.56 553.734 1234.58 553.614 1234.68 553.561H1234.69ZM1236.67 552.478C1236.67 552.388 1236.76 552.283 1236.84 552.283C1236.96 552.283 1237.04 552.388 1237.04 552.478V562.428C1237.04 562.534 1236.96 562.624 1236.84 562.624C1236.75 562.624 1236.67 562.534 1236.67 562.428V552.478Z" fill="#E71D36"/>
<path d="M1243.42 552.275C1245.17 552.275 1246.6 553.621 1246.75 555.351C1246.79 555.629 1246.81 555.93 1246.81 556.224C1246.81 557.615 1246.51 559.149 1245.8 560.39C1245.06 561.631 1243.9 562.549 1242.21 562.624C1242.09 562.624 1242.01 562.549 1242.01 562.428C1242 562.338 1242.09 562.248 1242.18 562.248C1243.75 562.173 1244.78 561.345 1245.46 560.21C1245.96 559.322 1246.27 558.224 1246.38 557.179C1245.83 558.247 1244.71 558.969 1243.42 558.969C1241.58 558.969 1240.06 557.472 1240.06 555.629C1240.06 553.787 1241.58 552.275 1243.42 552.275ZM1243.42 558.6C1245.06 558.6 1246.39 557.269 1246.39 555.629C1246.39 555.554 1246.39 555.479 1246.38 555.411V555.336C1246.23 553.809 1244.96 552.644 1243.42 552.644C1241.77 552.644 1240.43 553.975 1240.43 555.629C1240.43 557.284 1241.77 558.6 1243.42 558.6Z" fill="#E71D36"/>
</g>
<g opacity="0.7">
<path d="M1271.58 538.933C1271.58 538.114 1271.75 537.196 1272.17 536.474C1272.59 535.745 1273.28 535.203 1274.29 535.158C1274.35 535.158 1274.4 535.203 1274.4 535.271C1274.4 535.323 1274.36 535.376 1274.3 535.376C1273.37 535.421 1272.77 535.895 1272.36 536.579C1272.04 537.113 1271.88 537.745 1271.82 538.377C1272.14 537.738 1272.8 537.316 1273.57 537.316C1274.66 537.316 1275.54 538.196 1275.55 539.287C1275.54 540.385 1274.66 541.265 1273.57 541.265C1272.55 541.265 1271.71 540.505 1271.61 539.512V539.475C1271.58 539.302 1271.57 539.114 1271.57 538.933H1271.58ZM1273.58 541.047C1274.55 541.047 1275.33 540.265 1275.34 539.287C1275.33 538.317 1274.54 537.534 1273.58 537.534C1272.61 537.534 1271.82 538.317 1271.82 539.287C1271.82 540.257 1272.61 541.047 1273.58 541.047Z" fill="#5EB0EF"/>
<path d="M1279.26 535.158C1280.45 535.165 1281.34 536.564 1281.34 538.204C1281.34 539.843 1280.45 541.257 1279.26 541.265C1278.08 541.257 1277.18 539.858 1277.18 538.204C1277.18 536.549 1278.08 535.173 1279.26 535.158ZM1277.4 538.204C1277.4 539.009 1277.63 539.723 1277.96 540.227C1278.3 540.753 1278.77 541.047 1279.26 541.047C1279.75 541.047 1280.22 540.753 1280.56 540.227C1280.9 539.723 1281.13 539.009 1281.13 538.204C1281.13 537.399 1280.91 536.707 1280.56 536.196C1280.22 535.677 1279.75 535.376 1279.26 535.376C1278.76 535.376 1278.31 535.669 1277.96 536.196C1277.63 536.7 1277.4 537.414 1277.4 538.204Z" fill="#5EB0EF"/>
</g>
<g opacity="0.66">
<path d="M1280.28 578.387L1282.1 574.055H1279.66C1279.61 574.055 1279.57 574.018 1279.57 573.965C1279.57 573.928 1279.6 573.883 1279.66 573.883H1282.23C1282.27 573.883 1282.32 573.928 1282.32 573.965C1282.32 573.988 1282.31 574.003 1282.3 574.025L1280.44 578.448C1280.44 578.448 1280.4 578.5 1280.36 578.5C1280.36 578.5 1280.34 578.5 1280.33 578.5C1280.29 578.478 1280.27 578.433 1280.29 578.387H1280.28Z" fill="#E71D36"/>
<path d="M1284.16 578.387L1285.98 574.055H1283.54C1283.5 574.055 1283.46 574.018 1283.46 573.965C1283.46 573.928 1283.5 573.882 1283.54 573.882H1286.12C1286.16 573.882 1286.21 573.928 1286.21 573.965C1286.21 573.988 1286.19 574.003 1286.18 574.025L1284.31 578.448C1284.31 578.448 1284.28 578.5 1284.24 578.5C1284.24 578.5 1284.22 578.5 1284.21 578.5C1284.16 578.478 1284.15 578.433 1284.16 578.387Z" fill="#E71D36"/>
</g>
<g opacity="0.75">
<path d="M1328.73 643.555C1328.53 643.405 1328.53 643.051 1328.73 642.863C1328.93 642.668 1329.23 642.668 1329.42 642.863C1331 644.405 1333.04 645.21 1335.04 645.21C1337.03 645.21 1339.15 644.405 1340.69 642.863C1342.26 641.284 1343.03 639.246 1343.03 637.208C1343.03 635.17 1342.26 633.131 1340.69 631.59C1339.15 630.048 1337.15 629.281 1335.07 629.281C1333 629.281 1331.04 630.048 1329.42 631.59C1329.31 631.785 1328.96 631.785 1328.77 631.627C1328.73 631.627 1328.69 631.59 1328.69 631.552C1328.65 631.552 1328.65 631.514 1328.65 631.514C1328.58 631.402 1328.54 631.281 1328.58 631.131L1329.92 619.774C1329.96 619.541 1330.12 619.391 1330.27 619.353C1330.35 619.316 1330.42 619.278 1330.54 619.278H1343.54C1343.81 619.278 1344.05 619.549 1344.05 619.774C1344.05 620.083 1343.81 620.278 1343.54 620.278H1330.89L1329.74 630.055C1331.32 628.86 1333.2 628.28 1335.08 628.28C1337.39 628.28 1339.69 629.13 1341.47 630.898C1343.16 632.627 1344.05 634.899 1344.05 637.208C1344.05 639.517 1343.16 641.825 1341.39 643.555C1339.66 645.323 1337.36 646.21 1335.05 646.21C1332.74 646.21 1330.51 645.323 1328.74 643.555H1328.73Z" fill="#E71D36"/>
<path d="M1360.62 619.271C1365.16 619.271 1368.88 622.775 1369.27 627.273C1369.38 628.002 1369.42 628.777 1369.42 629.544C1369.42 633.161 1368.66 637.163 1366.81 640.397C1364.89 643.63 1361.89 646.015 1357.46 646.21C1357.15 646.21 1356.96 646.015 1356.96 645.706C1356.92 645.473 1357.15 645.247 1357.38 645.247C1361.5 645.052 1364.15 642.901 1365.91 639.938C1367.22 637.629 1368.03 634.778 1368.3 632.048C1366.88 634.816 1363.95 636.704 1360.6 636.704C1355.79 636.704 1351.83 632.816 1351.83 628.002C1351.83 623.189 1355.79 619.263 1360.6 619.263L1360.62 619.271ZM1360.62 635.741C1364.89 635.741 1368.35 632.274 1368.35 628.002C1368.35 627.807 1368.35 627.619 1368.31 627.423V627.228C1367.93 623.264 1364.62 620.226 1360.62 620.226C1356.31 620.226 1352.81 623.693 1352.81 628.002C1352.81 632.312 1356.31 635.741 1360.62 635.741Z" fill="#E71D36"/>
</g>
<g opacity="0.7">
<path d="M1285.01 617.638C1285.43 617.638 1285.76 617.977 1285.76 618.406C1285.76 618.706 1285.58 618.977 1285.33 619.098C1285.85 619.24 1286.23 619.714 1286.23 620.278C1286.23 620.955 1285.68 621.504 1285 621.504C1284.31 621.504 1283.77 620.955 1283.77 620.278C1283.77 619.714 1284.15 619.24 1284.67 619.098C1284.4 618.977 1284.22 618.714 1284.22 618.406C1284.22 617.977 1284.57 617.638 1284.99 617.638H1285.01ZM1285.01 621.361C1285.61 621.361 1286.1 620.872 1286.1 620.271C1286.1 619.669 1285.61 619.18 1285.01 619.18C1284.4 619.18 1283.92 619.669 1283.92 620.271C1283.92 620.872 1284.4 621.361 1285.01 621.361ZM1285.01 619.022C1285.34 619.022 1285.63 618.737 1285.63 618.398C1285.63 618.06 1285.35 617.774 1285.01 617.766C1284.66 617.766 1284.37 618.045 1284.37 618.398C1284.37 618.752 1284.66 619.022 1285.01 619.022Z" fill="#E71D36"/>
<path d="M1288.54 617.638C1289.19 617.638 1289.73 618.142 1289.78 618.789C1289.79 618.894 1289.8 619.007 1289.8 619.113C1289.8 619.632 1289.69 620.203 1289.43 620.669C1289.15 621.136 1288.72 621.474 1288.09 621.504C1288.04 621.504 1288.01 621.474 1288.01 621.436C1288.01 621.399 1288.04 621.369 1288.07 621.369C1288.67 621.339 1289.04 621.03 1289.3 620.609C1289.49 620.278 1289.6 619.872 1289.64 619.481C1289.44 619.88 1289.02 620.15 1288.54 620.15C1287.85 620.15 1287.28 619.594 1287.28 618.902C1287.28 618.21 1287.85 617.646 1288.54 617.646V617.638ZM1288.54 620C1289.15 620 1289.65 619.504 1289.65 618.887C1289.65 618.857 1289.65 618.834 1289.65 618.804V618.782C1289.6 618.21 1289.12 617.774 1288.55 617.774C1287.93 617.774 1287.43 618.27 1287.43 618.887C1287.43 619.504 1287.93 620 1288.55 620H1288.54Z" fill="#E71D36"/>
</g>
<g opacity="0.67">
<path d="M1362.7 681.866C1362.65 681.821 1362.65 681.716 1362.7 681.663C1362.76 681.603 1362.85 681.603 1362.9 681.663C1363.37 682.114 1363.97 682.355 1364.56 682.355C1365.14 682.355 1365.77 682.114 1366.22 681.663C1366.69 681.197 1366.91 680.595 1366.91 679.994C1366.91 679.392 1366.69 678.79 1366.22 678.339C1365.77 677.888 1365.18 677.662 1364.57 677.662C1363.96 677.662 1363.38 677.888 1362.9 678.339C1362.87 678.399 1362.77 678.399 1362.71 678.354C1362.69 678.354 1362.68 678.339 1362.68 678.331C1362.67 678.331 1362.67 678.324 1362.67 678.324C1362.65 678.286 1362.64 678.256 1362.65 678.211L1363.04 674.872C1363.05 674.804 1363.1 674.759 1363.15 674.752C1363.17 674.736 1363.19 674.729 1363.22 674.729H1367.05C1367.13 674.729 1367.2 674.812 1367.2 674.879C1367.2 674.97 1367.13 675.03 1367.05 675.03H1363.32L1362.98 677.91C1363.45 677.557 1364.01 677.391 1364.56 677.391C1365.23 677.391 1365.92 677.64 1366.43 678.158C1366.93 678.67 1367.19 679.339 1367.19 680.016C1367.19 680.693 1366.93 681.377 1366.41 681.889C1365.9 682.408 1365.22 682.671 1364.54 682.671C1363.86 682.671 1363.2 682.408 1362.68 681.889L1362.7 681.866Z" fill="#5EB0EF"/>
<path d="M1372.09 674.714C1373.43 674.714 1374.53 675.744 1374.64 677.068C1374.68 677.286 1374.69 677.512 1374.69 677.737C1374.69 678.805 1374.46 679.978 1373.92 680.934C1373.36 681.881 1372.47 682.588 1371.17 682.641C1371.08 682.641 1371.02 682.588 1371.02 682.49C1371 682.423 1371.07 682.355 1371.14 682.355C1372.35 682.302 1373.13 681.663 1373.66 680.791C1374.04 680.114 1374.28 679.272 1374.36 678.467C1373.94 679.279 1373.09 679.836 1372.1 679.836C1370.69 679.836 1369.52 678.692 1369.52 677.271C1369.52 675.85 1370.68 674.699 1372.1 674.699L1372.09 674.714ZM1372.09 679.565C1373.35 679.565 1374.37 678.542 1374.37 677.286C1374.37 677.233 1374.37 677.173 1374.36 677.113V677.06C1374.25 675.895 1373.27 675 1372.1 675C1370.83 675 1369.8 676.023 1369.8 677.286C1369.8 678.55 1370.83 679.565 1372.1 679.565H1372.09Z" fill="#5EB0EF"/>
</g>
<g opacity="0.68">
<path d="M1418.33 552.817L1419.45 550.169H1417.96C1417.96 550.169 1417.91 550.147 1417.91 550.117C1417.91 550.094 1417.93 550.064 1417.96 550.064H1419.54C1419.54 550.064 1419.59 550.094 1419.59 550.117C1419.59 550.132 1419.58 550.139 1419.57 550.154L1418.43 552.862C1418.43 552.862 1418.41 552.892 1418.39 552.892C1418.39 552.892 1418.37 552.892 1418.36 552.892C1418.33 552.877 1418.33 552.854 1418.33 552.824V552.817Z" fill="#E71D36"/>
<path d="M1421.57 552.283H1419.92C1419.92 552.283 1419.9 552.283 1419.89 552.268C1419.87 552.245 1419.87 552.223 1419.88 552.2L1421.59 550.079H1421.6C1421.6 550.079 1421.62 550.057 1421.63 550.057C1421.64 550.057 1421.66 550.072 1421.66 550.079C1421.66 550.079 1421.66 550.087 1421.67 550.094C1421.67 550.094 1421.67 550.102 1421.67 550.109V552.185H1421.92C1421.92 552.185 1421.97 552.208 1421.97 552.238C1421.97 552.268 1421.95 552.29 1421.92 552.29H1421.67V552.839C1421.67 552.839 1421.65 552.892 1421.62 552.892C1421.6 552.892 1421.57 552.869 1421.57 552.839V552.29L1421.57 552.283ZM1421.57 552.185V550.267L1420.03 552.185H1421.57Z" fill="#E71D36"/>
</g>
<g opacity="0.56">
<path d="M1372.8 577.523C1372.8 576.846 1372.94 576.094 1373.29 575.5C1373.63 574.898 1374.2 574.454 1375.03 574.417C1375.08 574.417 1375.12 574.454 1375.12 574.507C1375.12 574.552 1375.08 574.589 1375.05 574.589C1374.28 574.627 1373.78 575.018 1373.45 575.582C1373.19 576.018 1373.05 576.545 1373 577.064C1373.27 576.537 1373.81 576.191 1374.45 576.191C1375.34 576.191 1376.08 576.913 1376.08 577.816C1376.08 578.718 1375.35 579.448 1374.45 579.448C1373.6 579.448 1372.92 578.824 1372.83 578.004C1372.83 577.996 1372.83 577.981 1372.83 577.981C1372.81 577.831 1372.81 577.68 1372.81 577.53L1372.8 577.523ZM1374.45 579.26C1375.25 579.26 1375.89 578.613 1375.9 577.808C1375.89 577.011 1375.25 576.364 1374.45 576.364C1373.64 576.364 1373 577.011 1373 577.808C1373 578.606 1373.65 579.26 1374.45 579.26Z" fill="#E71D36"/>
<path d="M1377.41 577.523C1377.41 576.846 1377.55 576.094 1377.89 575.5C1378.24 574.898 1378.81 574.454 1379.64 574.417C1379.69 574.417 1379.73 574.454 1379.73 574.507C1379.73 574.552 1379.69 574.589 1379.65 574.589C1378.88 574.627 1378.38 575.018 1378.05 575.582C1377.79 576.018 1377.65 576.545 1377.6 577.064C1377.86 576.537 1378.41 576.191 1379.04 576.191C1379.94 576.191 1380.68 576.913 1380.68 577.816C1380.68 578.718 1379.95 579.448 1379.04 579.448C1378.2 579.448 1377.52 578.824 1377.43 578.004C1377.42 577.996 1377.42 577.981 1377.42 577.981C1377.41 577.831 1377.4 577.68 1377.4 577.53L1377.41 577.523ZM1379.05 579.26C1379.86 579.26 1380.5 578.613 1380.51 577.808C1380.5 577.011 1379.86 576.364 1379.05 576.364C1378.25 576.364 1377.61 577.011 1377.61 577.808C1377.61 578.606 1378.26 579.26 1379.05 579.26Z" fill="#E71D36"/>
</g>
<g opacity="0.5">
<path d="M1334.03 643.743L1334.78 643.3C1334.78 643.3 1334.85 643.284 1334.88 643.322C1334.89 643.352 1334.88 643.39 1334.85 643.412L1334.1 643.864C1334.1 643.864 1334.07 643.864 1334.07 643.864C1334.04 643.864 1334.02 643.864 1334.01 643.833C1333.99 643.803 1333.99 643.766 1334.03 643.743ZM1334.74 643.352C1334.74 643.352 1334.77 643.284 1334.81 643.284C1334.85 643.284 1334.88 643.322 1334.88 643.352V646.947C1334.88 646.985 1334.85 647.015 1334.81 647.015C1334.78 647.015 1334.74 646.985 1334.74 646.947V643.352Z" fill="#E71D36"/>
<path d="M1337.1 643.277C1337.51 643.277 1337.84 643.608 1337.84 644.022C1337.84 644.315 1337.67 644.571 1337.42 644.691C1337.92 644.826 1338.3 645.285 1338.3 645.834C1338.3 646.488 1337.76 647.022 1337.11 647.022C1336.46 647.022 1335.91 646.496 1335.91 645.834C1335.91 645.285 1336.29 644.826 1336.79 644.691C1336.53 644.571 1336.36 644.315 1336.36 644.022C1336.36 643.608 1336.69 643.277 1337.1 643.277ZM1337.1 646.887C1337.69 646.887 1338.16 646.413 1338.16 645.834C1338.16 645.255 1337.69 644.774 1337.1 644.774C1336.52 644.774 1336.04 645.247 1336.04 645.834C1336.04 646.421 1336.52 646.887 1337.1 646.887ZM1337.1 644.623C1337.43 644.623 1337.7 644.352 1337.7 644.022C1337.7 643.691 1337.43 643.42 1337.1 643.412C1336.76 643.412 1336.49 643.683 1336.49 644.022C1336.49 644.36 1336.76 644.623 1337.1 644.623Z" fill="#E71D36"/>
</g>
<g opacity="0.85">
<path d="M1388.66 651.64C1388.59 651.587 1388.59 651.467 1388.66 651.399C1388.72 651.332 1388.83 651.332 1388.9 651.399C1389.44 651.926 1390.14 652.204 1390.83 652.204C1391.52 652.204 1392.24 651.926 1392.77 651.399C1393.31 650.858 1393.57 650.158 1393.57 649.459C1393.57 648.76 1393.31 648.06 1392.77 647.534C1392.24 647.007 1391.55 646.744 1390.84 646.744C1390.12 646.744 1389.45 647.007 1388.9 647.534C1388.86 647.601 1388.74 647.601 1388.67 647.549C1388.66 647.549 1388.65 647.534 1388.65 647.519C1388.63 647.519 1388.63 647.504 1388.63 647.504C1388.6 647.466 1388.6 647.421 1388.6 647.368L1389.07 643.473C1389.08 643.39 1389.14 643.337 1389.19 643.33C1389.21 643.315 1389.24 643.307 1389.28 643.307H1393.74C1393.83 643.307 1393.92 643.397 1393.92 643.48C1393.92 643.585 1393.84 643.653 1393.74 643.653H1389.4L1389 647.007C1389.54 646.601 1390.19 646.398 1390.84 646.398C1391.63 646.398 1392.42 646.691 1393.03 647.301C1393.61 647.895 1393.92 648.677 1393.92 649.467C1393.92 650.256 1393.62 651.053 1393.01 651.648C1392.41 652.257 1391.62 652.558 1390.83 652.558C1390.04 652.558 1389.27 652.257 1388.66 651.648L1388.66 651.64Z" fill="#E71D36"/>
<path d="M1396.63 649.023C1396.63 647.782 1396.89 646.391 1397.53 645.293C1398.16 644.179 1399.22 643.367 1400.74 643.299C1400.83 643.299 1400.91 643.367 1400.91 643.473C1400.91 643.555 1400.85 643.63 1400.77 643.63C1399.36 643.698 1398.45 644.42 1397.83 645.45C1397.35 646.255 1397.1 647.225 1397.01 648.173C1397.49 647.21 1398.5 646.564 1399.66 646.564C1401.31 646.564 1402.66 647.895 1402.67 649.549C1402.66 651.211 1401.31 652.55 1399.66 652.55C1398.11 652.55 1396.85 651.399 1396.69 649.895C1396.68 649.88 1396.68 649.858 1396.68 649.843C1396.65 649.579 1396.64 649.301 1396.64 649.023H1396.63ZM1399.65 652.219C1401.13 652.219 1402.3 651.031 1402.33 649.549C1402.31 648.083 1401.13 646.894 1399.65 646.894C1398.17 646.894 1397 648.083 1397 649.549C1397 651.016 1398.19 652.219 1399.65 652.219Z" fill="#E71D36"/>
</g>
<g opacity="0.52">
<path d="M1426.81 743.838L1429.13 741.739C1429.43 741.461 1429.58 741.093 1429.58 740.724C1429.58 740.401 1429.46 740.07 1429.22 739.814C1428.95 739.521 1428.58 739.363 1428.22 739.363C1427.89 739.363 1427.56 739.483 1427.3 739.724L1427.06 739.942C1427.01 739.979 1426.97 739.979 1426.94 739.942C1426.9 739.904 1426.91 739.844 1426.94 739.822L1427.18 739.596C1427.48 739.325 1427.84 739.197 1428.22 739.197C1428.64 739.197 1429.05 739.37 1429.36 739.701C1429.62 739.995 1429.76 740.378 1429.76 740.739C1429.76 741.16 1429.58 741.574 1429.25 741.882L1427.09 743.838H1429.68C1429.72 743.838 1429.77 743.868 1429.77 743.92C1429.77 743.973 1429.72 744.011 1429.68 744.011H1426.88C1426.88 744.011 1426.83 744.003 1426.81 743.981C1426.78 743.943 1426.78 743.89 1426.82 743.853L1426.81 743.838Z" fill="#E71D36"/>
<path d="M1433.31 741.258C1433.62 741.566 1433.78 741.98 1433.78 742.386C1433.78 742.792 1433.62 743.213 1433.31 743.529C1433 743.838 1432.58 743.996 1432.18 743.996C1431.77 743.996 1431.36 743.845 1431.05 743.529C1431.01 743.492 1431.01 743.439 1431.05 743.409C1431.09 743.371 1431.14 743.371 1431.17 743.409C1431.45 743.68 1431.81 743.823 1432.17 743.823C1432.53 743.823 1432.91 743.687 1433.18 743.401C1433.46 743.123 1433.6 742.762 1433.6 742.394C1433.6 742.025 1433.46 741.672 1433.18 741.393C1432.9 741.115 1432.55 740.98 1432.19 740.98C1432.16 740.98 1432.13 740.965 1432.1 740.942C1432.1 740.935 1432.1 740.935 1432.1 740.935C1432.07 740.905 1432.07 740.837 1432.1 740.807L1433.49 739.378H1431.37C1431.31 739.378 1431.28 739.333 1431.28 739.288C1431.28 739.242 1431.31 739.197 1431.37 739.197H1433.7C1433.74 739.197 1433.79 739.235 1433.79 739.288C1433.79 739.303 1433.79 739.318 1433.79 739.333C1433.79 739.348 1433.77 739.363 1433.76 739.37L1432.35 740.822C1432.71 740.859 1433.06 741.01 1433.33 741.281L1433.31 741.258Z" fill="#E71D36"/>
</g>
<g opacity="0.88">
<path d="M1336.52 750.779C1337 751.261 1337.24 751.907 1337.24 752.539C1337.24 753.171 1337 753.825 1336.52 754.322C1336.02 754.803 1335.38 755.051 1334.75 755.051H1334.74C1334.11 755.051 1333.46 754.818 1332.98 754.322C1332.93 754.269 1332.93 754.179 1332.98 754.126C1333.04 754.073 1333.13 754.073 1333.18 754.126C1333.62 754.555 1334.18 754.773 1334.74 754.773C1335.31 754.773 1335.89 754.562 1336.32 754.119C1336.76 753.69 1336.97 753.126 1336.97 752.547C1336.97 751.968 1336.76 751.419 1336.33 750.982C1335.89 750.554 1335.33 750.336 1334.76 750.336C1334.71 750.336 1334.67 750.313 1334.64 750.268C1334.64 750.26 1334.62 750.26 1334.62 750.26C1334.57 750.208 1334.57 750.11 1334.62 750.057L1336.79 747.824H1333.47C1333.4 747.824 1333.33 747.756 1333.33 747.688C1333.33 747.621 1333.4 747.545 1333.47 747.545H1337.1C1337.18 747.545 1337.24 747.606 1337.24 747.688C1337.24 747.711 1337.24 747.733 1337.24 747.748C1337.23 747.771 1337.21 747.794 1337.21 747.801L1335 750.065C1335.55 750.118 1336.1 750.351 1336.52 750.779H1336.52Z" fill="#5EB0EF"/>
<path d="M1340.27 754.863L1343.21 747.839H1339.26C1339.18 747.839 1339.12 747.771 1339.12 747.696C1339.12 747.636 1339.19 747.56 1339.26 747.56H1343.44C1343.51 747.56 1343.58 747.636 1343.58 747.696C1343.58 747.726 1343.56 747.763 1343.54 747.793L1340.51 754.968C1340.51 755.013 1340.45 755.051 1340.39 755.051C1340.39 755.051 1340.36 755.044 1340.34 755.044C1340.27 755.013 1340.24 754.938 1340.27 754.863Z" fill="#5EB0EF"/>
</g>
<g opacity="0.85">
<path d="M1385.28 790.752C1386.4 790.752 1387.32 791.67 1387.32 792.813C1387.32 793.625 1386.83 794.34 1386.15 794.663C1387.54 795.047 1388.57 796.325 1388.57 797.837C1388.57 799.657 1387.09 801.131 1385.27 801.131C1383.44 801.131 1381.96 799.665 1381.96 797.837C1381.96 796.325 1383 795.047 1384.4 794.663C1383.68 794.34 1383.21 793.625 1383.21 792.813C1383.21 791.67 1384.13 790.752 1385.27 790.752H1385.28ZM1385.28 800.763C1386.91 800.763 1388.21 799.454 1388.21 797.845C1388.21 796.235 1386.9 794.911 1385.28 794.911C1383.65 794.911 1382.34 796.228 1382.34 797.845C1382.34 799.461 1383.66 800.763 1385.28 800.763ZM1385.28 794.49C1386.19 794.49 1386.95 793.731 1386.95 792.813C1386.95 791.896 1386.19 791.136 1385.28 791.121C1384.34 791.136 1383.58 791.881 1383.58 792.813C1383.58 793.746 1384.34 794.49 1385.28 794.49Z" fill="#E71D36"/>
<path d="M1391.05 792.039L1393.13 790.805C1393.22 790.745 1393.34 790.76 1393.4 790.865C1393.44 790.955 1393.41 791.061 1393.31 791.121L1391.23 792.369C1391.23 792.369 1391.17 792.384 1391.14 792.384C1391.08 792.384 1391.01 792.369 1390.98 792.294C1390.93 792.219 1390.95 792.099 1391.05 792.046V792.039ZM1393.04 790.955C1393.04 790.865 1393.13 790.76 1393.22 790.76C1393.34 790.76 1393.41 790.865 1393.41 790.955V800.936C1393.41 801.041 1393.34 801.131 1393.22 801.131C1393.13 801.131 1393.04 801.041 1393.04 800.936V790.955Z" fill="#E71D36"/>
</g>
<g opacity="0.61">
<path d="M1330.72 770.83C1330.72 770.236 1330.85 769.566 1331.15 769.04C1331.45 768.506 1331.96 768.115 1332.69 768.085C1332.74 768.085 1332.77 768.115 1332.77 768.167C1332.77 768.205 1332.74 768.243 1332.71 768.243C1332.03 768.273 1331.59 768.626 1331.29 769.115C1331.07 769.506 1330.95 769.965 1330.9 770.424C1331.13 769.957 1331.62 769.649 1332.18 769.649C1332.97 769.649 1333.62 770.288 1333.62 771.086C1333.62 771.883 1332.97 772.529 1332.18 772.529C1331.44 772.529 1330.83 771.98 1330.75 771.251V771.228C1330.74 771.101 1330.74 770.965 1330.74 770.837L1330.72 770.83ZM1332.17 772.364C1332.88 772.364 1333.45 771.792 1333.46 771.078C1333.44 770.371 1332.88 769.799 1332.17 769.799C1331.47 769.799 1330.89 770.371 1330.89 771.078C1330.89 771.785 1331.47 772.364 1332.17 772.364Z" fill="#E71D36"/>
<path d="M1336.19 768.085C1336.94 768.085 1337.55 768.664 1337.62 769.408C1337.64 769.529 1337.64 769.657 1337.64 769.784C1337.64 770.379 1337.52 771.04 1337.21 771.574C1336.9 772.108 1336.4 772.499 1335.67 772.529C1335.62 772.529 1335.59 772.499 1335.59 772.447C1335.59 772.409 1335.62 772.372 1335.67 772.372C1336.34 772.341 1336.78 771.988 1337.07 771.499C1337.29 771.116 1337.42 770.649 1337.46 770.198C1337.23 770.657 1336.75 770.965 1336.19 770.965C1335.4 770.965 1334.74 770.326 1334.74 769.529C1334.74 768.731 1335.4 768.085 1336.19 768.085ZM1336.19 770.8C1336.9 770.8 1337.47 770.228 1337.47 769.521C1337.47 769.491 1337.47 769.461 1337.47 769.423V769.401C1337.41 768.739 1336.86 768.235 1336.2 768.235C1335.49 768.235 1334.91 768.807 1334.91 769.521C1334.91 770.236 1335.49 770.8 1336.2 770.8H1336.19Z" fill="#E71D36"/>
</g>
<g opacity="0.91">
<path d="M1382.01 846.609L1382.59 845.226H1381.82C1381.82 845.226 1381.79 845.211 1381.79 845.196C1381.79 845.181 1381.8 845.166 1381.82 845.166H1382.64C1382.64 845.166 1382.67 845.181 1382.67 845.196C1382.67 845.196 1382.67 845.211 1382.66 845.218L1382.07 846.632C1382.07 846.632 1382.05 846.647 1382.04 846.647H1382.04C1382.04 846.647 1382.01 846.625 1382.02 846.609H1382.01Z" fill="#E71D36"/>
</g>
<g opacity="0.74">
<path d="M1317.61 825.559C1319.04 825.559 1320.21 826.657 1320.33 828.078C1320.37 828.311 1320.37 828.552 1320.37 828.793C1320.37 829.928 1320.13 831.192 1319.55 832.207C1318.94 833.222 1318.01 833.974 1316.62 834.035C1316.52 834.035 1316.46 833.974 1316.46 833.877C1316.44 833.801 1316.52 833.734 1316.59 833.734C1317.89 833.674 1318.72 832.997 1319.28 832.064C1319.7 831.335 1319.95 830.44 1320.03 829.582C1319.58 830.455 1318.67 831.049 1317.61 831.049C1316.1 831.049 1314.86 829.83 1314.86 828.311C1314.86 826.792 1316.1 825.566 1317.61 825.566L1317.61 825.559ZM1317.61 830.74C1318.95 830.74 1320.04 829.65 1320.04 828.304C1320.04 828.244 1320.04 828.183 1320.03 828.123V828.063C1319.91 826.815 1318.86 825.86 1317.61 825.86C1316.25 825.86 1315.15 826.95 1315.15 828.304C1315.15 829.658 1316.25 830.74 1317.61 830.74Z" fill="#5EB0EF"/>
</g>
<g opacity="0.92">
<path d="M1369.08 910.265H1365.55C1365.55 910.265 1365.5 910.258 1365.48 910.243C1365.43 910.198 1365.43 910.138 1365.46 910.1L1369.11 905.565L1369.12 905.55C1369.12 905.55 1369.17 905.527 1369.19 905.527C1369.22 905.527 1369.26 905.55 1369.28 905.58C1369.29 905.588 1369.29 905.595 1369.3 905.603C1369.3 905.603 1369.3 905.618 1369.3 905.625V910.062H1369.83C1369.9 910.062 1369.94 910.108 1369.94 910.168C1369.94 910.235 1369.9 910.281 1369.83 910.281H1369.3V911.454C1369.3 911.514 1369.26 911.567 1369.19 911.567C1369.14 911.567 1369.08 911.514 1369.08 911.454V910.281V910.265ZM1369.08 910.047V905.949L1365.77 910.047H1369.08Z" fill="#E71D36"/>
<path d="M1371.88 911.401L1374.26 905.738H1371.07C1371.01 905.738 1370.96 905.685 1370.96 905.625C1370.96 905.573 1371.01 905.512 1371.07 905.512H1374.44C1374.49 905.512 1374.55 905.573 1374.55 905.625C1374.55 905.648 1374.54 905.678 1374.51 905.7L1372.08 911.484C1372.08 911.484 1372.03 911.552 1371.98 911.552C1371.97 911.552 1371.96 911.544 1371.94 911.544C1371.88 911.521 1371.86 911.454 1371.87 911.401H1371.88Z" fill="#E71D36"/>
</g>
<g opacity="0.74">
<path d="M1356.01 858.725C1358.07 858.725 1359.77 860.312 1359.94 862.358C1359.99 862.689 1360.01 863.042 1360.01 863.388C1360.01 865.028 1359.66 866.848 1358.82 868.314C1357.95 869.781 1356.59 870.864 1354.57 870.954C1354.44 870.954 1354.35 870.864 1354.35 870.729C1354.33 870.623 1354.44 870.518 1354.54 870.518C1356.42 870.428 1357.62 869.45 1358.42 868.104C1359.02 867.058 1359.38 865.765 1359.5 864.524C1358.86 865.78 1357.54 866.637 1356.01 866.637C1353.83 866.637 1352.03 864.877 1352.03 862.689C1352.03 860.5 1353.82 858.725 1356.01 858.725ZM1356.01 866.201C1357.95 866.201 1359.52 864.629 1359.52 862.689C1359.52 862.599 1359.52 862.516 1359.5 862.426V862.335C1359.33 860.538 1357.83 859.154 1356.01 859.154C1354.06 859.154 1352.46 860.726 1352.46 862.681C1352.46 864.637 1354.06 866.194 1356.01 866.194V866.201Z" fill="#5EB0EF"/>
<path d="M1367.38 858.725C1369.77 858.741 1371.55 861.538 1371.55 864.825C1371.55 868.111 1369.77 870.939 1367.38 870.962C1365.02 870.947 1363.22 868.149 1363.22 864.825C1363.22 861.501 1365.02 858.741 1367.38 858.725ZM1363.66 864.825C1363.66 866.434 1364.11 867.863 1364.8 868.879C1365.48 869.924 1366.4 870.518 1367.38 870.518C1368.36 870.518 1369.3 869.924 1369.98 868.879C1370.68 867.863 1371.12 866.434 1371.12 864.825C1371.12 863.215 1370.68 861.816 1369.98 860.809C1369.3 859.763 1368.37 859.169 1367.38 859.169C1366.39 859.169 1365.48 859.763 1364.8 860.809C1364.11 861.824 1363.66 863.253 1363.66 864.825Z" fill="#5EB0EF"/>
</g>
<g opacity="0.95">
<path d="M1404.49 860.906L1408.45 851.46H1403.13C1403.03 851.46 1402.94 851.378 1402.94 851.272C1402.94 851.182 1403.03 851.084 1403.13 851.084H1408.75C1408.84 851.084 1408.94 851.182 1408.94 851.272C1408.94 851.317 1408.91 851.355 1408.88 851.4L1404.81 861.049C1404.8 861.102 1404.72 861.162 1404.66 861.162C1404.64 861.162 1404.61 861.147 1404.58 861.147C1404.48 861.102 1404.45 861.004 1404.48 860.899L1404.49 860.906Z" fill="#E71D36"/>
<path d="M1416.05 855.416C1416.7 856.063 1417.03 856.928 1417.03 857.778C1417.03 858.628 1416.7 859.508 1416.05 860.169C1415.39 860.816 1414.52 861.147 1413.67 861.147H1413.66C1412.81 861.147 1411.95 860.831 1411.3 860.169C1411.22 860.094 1411.22 859.981 1411.3 859.906C1411.38 859.839 1411.49 859.839 1411.56 859.906C1412.15 860.485 1412.9 860.771 1413.67 860.771C1414.43 860.771 1415.21 860.478 1415.79 859.891C1416.38 859.312 1416.67 858.552 1416.67 857.77C1416.67 856.988 1416.38 856.259 1415.8 855.664C1415.21 855.085 1414.46 854.8 1413.7 854.8C1413.62 854.8 1413.57 854.769 1413.52 854.709C1413.52 854.694 1413.51 854.694 1413.51 854.694C1413.43 854.627 1413.43 854.491 1413.51 854.423L1416.42 851.423H1411.96C1411.86 851.423 1411.77 851.34 1411.77 851.235C1411.77 851.129 1411.86 851.047 1411.96 851.047H1416.85C1416.94 851.047 1417.03 851.137 1417.03 851.235C1417.03 851.265 1417.03 851.287 1417.02 851.317C1417 851.347 1416.99 851.378 1416.97 851.393L1414.01 854.439C1414.76 854.514 1415.49 854.83 1416.05 855.409V855.416Z" fill="#E71D36"/>
</g>
<g opacity="0.97">
<path d="M1302.16 789.594L1303.92 788.007C1304.15 787.797 1304.27 787.518 1304.27 787.233C1304.27 786.992 1304.17 786.736 1304 786.541C1303.79 786.315 1303.51 786.195 1303.23 786.195C1302.98 786.195 1302.73 786.285 1302.53 786.466L1302.35 786.631C1302.35 786.631 1302.28 786.654 1302.25 786.631C1302.23 786.608 1302.23 786.556 1302.25 786.533L1302.43 786.36C1302.66 786.157 1302.94 786.059 1303.22 786.059C1303.54 786.059 1303.86 786.187 1304.09 786.443C1304.28 786.669 1304.39 786.954 1304.39 787.233C1304.39 787.549 1304.26 787.872 1304 788.098L1302.36 789.587H1304.32C1304.32 789.587 1304.38 789.609 1304.38 789.647C1304.38 789.684 1304.35 789.715 1304.32 789.715H1302.19C1302.19 789.715 1302.15 789.715 1302.14 789.684C1302.12 789.662 1302.12 789.617 1302.14 789.594H1302.16Z" fill="#5EB0EF"/>
<path d="M1306.51 786.067C1307.12 786.067 1307.63 786.541 1307.68 787.15C1307.7 787.248 1307.7 787.353 1307.7 787.458C1307.7 787.947 1307.6 788.489 1307.35 788.925C1307.09 789.361 1306.68 789.684 1306.08 789.715C1306.03 789.715 1306.01 789.684 1306.01 789.647C1306.01 789.617 1306.03 789.587 1306.07 789.587C1306.63 789.564 1306.99 789.271 1307.23 788.865C1307.41 788.549 1307.51 788.165 1307.55 787.797C1307.36 788.173 1306.97 788.428 1306.51 788.428C1305.85 788.428 1305.32 787.902 1305.32 787.248C1305.32 786.593 1305.85 786.059 1306.51 786.059V786.067ZM1306.51 788.301C1307.09 788.301 1307.55 787.834 1307.55 787.255C1307.55 787.233 1307.55 787.203 1307.55 787.18V787.157C1307.5 786.616 1307.05 786.202 1306.51 786.202C1305.92 786.202 1305.45 786.669 1305.45 787.255C1305.45 787.842 1305.92 788.301 1306.51 788.301Z" fill="#5EB0EF"/>
</g>
<g opacity="0.97">
<path d="M1372.62 872.421C1373.54 872.421 1374.29 873.173 1374.29 874.105C1374.29 874.775 1373.9 875.354 1373.33 875.625C1374.48 875.941 1375.32 876.986 1375.32 878.219C1375.32 879.716 1374.11 880.912 1372.62 880.912C1371.12 880.912 1369.91 879.708 1369.91 878.219C1369.91 876.978 1370.76 875.941 1371.91 875.625C1371.32 875.354 1370.94 874.775 1370.94 874.105C1370.94 873.173 1371.69 872.421 1372.62 872.421ZM1372.62 880.618C1373.96 880.618 1375.02 879.55 1375.02 878.227C1375.02 876.903 1373.96 875.82 1372.62 875.82C1371.28 875.82 1370.21 876.903 1370.21 878.227C1370.21 879.55 1371.3 880.618 1372.62 880.618ZM1372.62 875.482C1373.37 875.482 1373.99 874.865 1373.99 874.105C1373.99 873.346 1373.38 872.737 1372.62 872.722C1371.85 872.737 1371.24 873.338 1371.24 874.105C1371.24 874.873 1371.85 875.482 1372.62 875.482Z" fill="#E71D36"/>
<path d="M1377.68 880.084C1377.62 880.039 1377.62 879.927 1377.68 879.866C1377.74 879.806 1377.84 879.806 1377.9 879.866C1378.4 880.355 1379.04 880.611 1379.68 880.611C1380.31 880.611 1380.98 880.355 1381.46 879.866C1381.95 879.37 1382.19 878.723 1382.19 878.084C1382.19 877.445 1381.95 876.798 1381.46 876.309C1380.97 875.828 1380.34 875.58 1379.68 875.58C1379.02 875.58 1378.41 875.82 1377.9 876.309C1377.86 876.369 1377.76 876.369 1377.7 876.324C1377.68 876.324 1377.68 876.309 1377.68 876.302C1377.66 876.302 1377.66 876.287 1377.66 876.287C1377.64 876.249 1377.62 876.211 1377.64 876.166L1378.07 872.586C1378.08 872.511 1378.13 872.466 1378.17 872.451C1378.2 872.436 1378.23 872.428 1378.26 872.428H1382.36C1382.44 872.428 1382.52 872.511 1382.52 872.586C1382.52 872.684 1382.44 872.744 1382.36 872.744H1378.37L1378.01 875.828C1378.5 875.452 1379.1 875.271 1379.69 875.271C1380.42 875.271 1381.15 875.542 1381.7 876.099C1382.24 876.648 1382.52 877.362 1382.52 878.092C1382.52 878.821 1382.24 879.551 1381.68 880.092C1381.13 880.649 1380.4 880.927 1379.68 880.927C1378.96 880.927 1378.25 880.649 1377.69 880.092L1377.68 880.084Z" fill="#E71D36"/>
</g>
<g opacity="0.83">
<path d="M1314.38 529.081L1322.09 522.109C1323.08 521.199 1323.6 519.966 1323.6 518.725C1323.6 517.649 1323.18 516.551 1322.41 515.687C1321.52 514.701 1320.26 514.182 1319.05 514.182C1317.95 514.182 1316.86 514.596 1315.99 515.371L1315.19 516.1C1315.06 516.213 1314.89 516.213 1314.78 516.1C1314.67 515.987 1314.69 515.777 1314.8 515.687L1315.6 514.934C1316.61 514.039 1317.82 513.611 1319.05 513.611C1320.44 513.611 1321.84 514.182 1322.84 515.28C1323.7 516.266 1324.16 517.522 1324.16 518.732C1324.16 520.124 1323.59 521.523 1322.45 522.523L1315.28 529.036H1323.88C1324.02 529.036 1324.15 529.149 1324.15 529.307C1324.15 529.465 1324.02 529.608 1323.88 529.608H1314.57C1314.48 529.608 1314.39 529.585 1314.35 529.495C1314.26 529.382 1314.26 529.194 1314.37 529.081H1314.38Z" fill="#E71D36"/>
<path d="M1328.21 528.028C1328.1 527.938 1328.1 527.727 1328.21 527.614C1328.32 527.502 1328.51 527.502 1328.62 527.614C1329.56 528.524 1330.77 529.006 1331.95 529.006C1333.14 529.006 1334.4 528.524 1335.31 527.614C1336.25 526.674 1336.7 525.464 1336.7 524.253C1336.7 523.042 1336.25 521.831 1335.31 520.921C1334.4 520.011 1333.21 519.552 1331.98 519.552C1330.74 519.552 1329.58 520.011 1328.62 520.921C1328.55 521.034 1328.35 521.034 1328.23 520.944C1328.2 520.944 1328.18 520.921 1328.18 520.898C1328.16 520.898 1328.16 520.876 1328.16 520.876C1328.11 520.808 1328.09 520.741 1328.11 520.65L1328.91 513.912C1328.93 513.776 1329.02 513.686 1329.11 513.656C1329.16 513.633 1329.2 513.611 1329.27 513.611H1336.99C1337.15 513.611 1337.29 513.769 1337.29 513.912C1337.29 514.092 1337.15 514.212 1336.99 514.212H1329.48L1328.8 520.018C1329.73 519.312 1330.85 518.966 1331.97 518.966C1333.34 518.966 1334.71 519.469 1335.76 520.522C1336.76 521.553 1337.29 522.899 1337.29 524.268C1337.29 525.637 1336.76 527.013 1335.71 528.036C1334.68 529.089 1333.31 529.615 1331.95 529.615C1330.58 529.615 1329.26 529.089 1328.2 528.036L1328.21 528.028Z" fill="#E71D36"/>
</g>
<g opacity="0.52">
<path d="M1320.05 582.607L1325.15 570.438H1318.31C1318.17 570.438 1318.07 570.325 1318.07 570.197C1318.07 570.084 1318.18 569.957 1318.31 569.957H1325.54C1325.66 569.957 1325.78 570.084 1325.78 570.197C1325.78 570.25 1325.75 570.31 1325.71 570.363L1320.47 582.787C1320.46 582.862 1320.36 582.938 1320.27 582.938C1320.25 582.938 1320.22 582.923 1320.18 582.923C1320.05 582.87 1320.01 582.734 1320.05 582.607Z" fill="#E71D36"/>
<path d="M1328.59 577.981C1328.59 576.237 1328.96 574.281 1329.85 572.739C1330.74 571.175 1332.22 570.024 1334.36 569.934C1334.49 569.934 1334.6 570.024 1334.6 570.175C1334.6 570.288 1334.51 570.4 1334.4 570.4C1332.41 570.491 1331.13 571.513 1330.26 572.965C1329.59 574.101 1329.23 575.454 1329.11 576.793C1329.79 575.439 1331.2 574.529 1332.83 574.529C1335.16 574.529 1337.05 576.409 1337.06 578.726C1337.05 581.065 1335.16 582.945 1332.83 582.945C1330.66 582.945 1328.88 581.328 1328.65 579.215C1328.64 579.192 1328.64 579.162 1328.64 579.14C1328.6 578.771 1328.59 578.38 1328.59 577.989V577.981ZM1332.83 582.479C1334.91 582.479 1336.56 580.809 1336.61 578.726C1336.57 576.665 1334.91 574.996 1332.83 574.996C1330.75 574.996 1329.11 576.665 1329.11 578.726C1329.11 580.787 1330.77 582.479 1332.83 582.479Z" fill="#E71D36"/>
</g>
<g opacity="0.67">
<path d="M1340.15 504.165L1342 499.765H1339.52C1339.48 499.765 1339.43 499.727 1339.43 499.675C1339.43 499.637 1339.47 499.584 1339.52 499.584H1342.14C1342.17 499.584 1342.23 499.63 1342.23 499.675C1342.23 499.697 1342.21 499.712 1342.2 499.735L1340.3 504.232C1340.3 504.232 1340.27 504.285 1340.23 504.285C1340.23 504.285 1340.21 504.285 1340.19 504.285C1340.15 504.262 1340.13 504.217 1340.15 504.172L1340.15 504.165Z" fill="#5EB0EF"/>
<path d="M1343.22 503.826C1343.22 503.826 1343.19 503.736 1343.22 503.706C1343.25 503.676 1343.31 503.676 1343.34 503.706C1343.62 503.977 1343.97 504.119 1344.32 504.119C1344.66 504.119 1345.04 503.977 1345.3 503.706C1345.58 503.428 1345.71 503.074 1345.71 502.721C1345.71 502.367 1345.58 502.006 1345.3 501.743C1345.03 501.472 1344.69 501.337 1344.32 501.337C1343.96 501.337 1343.62 501.472 1343.34 501.743C1343.32 501.773 1343.26 501.773 1343.23 501.743H1343.21V501.728C1343.2 501.705 1343.19 501.69 1343.2 501.66L1343.43 499.675C1343.43 499.637 1343.46 499.607 1343.49 499.599C1343.51 499.592 1343.52 499.584 1343.54 499.584H1345.8C1345.85 499.584 1345.9 499.63 1345.9 499.675C1345.9 499.727 1345.86 499.765 1345.8 499.765H1343.6L1343.39 501.472C1343.67 501.262 1343.99 501.164 1344.32 501.164C1344.73 501.164 1345.13 501.314 1345.44 501.623C1345.73 501.923 1345.89 502.322 1345.89 502.728C1345.89 503.134 1345.73 503.533 1345.42 503.834C1345.12 504.142 1344.72 504.3 1344.32 504.3C1343.92 504.3 1343.53 504.142 1343.21 503.834L1343.22 503.826Z" fill="#5EB0EF"/>
</g>
<g opacity="0.86">
<path d="M1338.18 676.098C1338.05 675.992 1338.05 675.759 1338.18 675.624C1338.3 675.496 1338.51 675.496 1338.65 675.624C1339.72 676.677 1341.11 677.226 1342.48 677.226C1343.85 677.226 1345.29 676.677 1346.34 675.624C1347.38 674.571 1347.94 673.157 1347.94 671.766C1347.94 670.374 1347.41 668.983 1346.34 667.93C1345.29 666.877 1343.93 666.351 1342.51 666.351C1341.08 666.351 1339.75 666.877 1338.65 667.93C1338.57 668.058 1338.33 668.058 1338.2 667.953C1338.18 667.953 1338.15 667.93 1338.15 667.9C1338.12 667.9 1338.12 667.877 1338.12 667.877C1338.07 667.802 1338.04 667.72 1338.07 667.614L1338.99 659.868C1339.01 659.71 1339.12 659.605 1339.22 659.575C1339.27 659.552 1339.33 659.522 1339.4 659.522H1348.27C1348.46 659.522 1348.61 659.71 1348.61 659.86C1348.61 660.071 1348.45 660.199 1348.27 660.199H1339.63L1338.85 666.87C1339.92 666.057 1341.21 665.659 1342.49 665.659C1344.06 665.659 1345.64 666.238 1346.85 667.441C1348.01 668.622 1348.61 670.171 1348.61 671.751C1348.61 673.33 1348.01 674.902 1346.8 676.083C1345.62 677.294 1344.04 677.895 1342.47 677.895C1340.9 677.895 1339.37 677.294 1338.16 676.083L1338.18 676.098Z" fill="#E71D36"/>
<path d="M1360.29 659.529C1363.89 659.552 1366.56 663.756 1366.56 668.697C1366.56 673.638 1363.89 677.888 1360.29 677.918C1356.75 677.895 1354.05 673.691 1354.05 668.697C1354.05 663.703 1356.75 659.56 1360.29 659.529ZM1354.7 668.697C1354.7 671.111 1355.39 673.27 1356.41 674.789C1357.43 676.361 1358.82 677.256 1360.29 677.256C1361.77 677.256 1363.18 676.361 1364.2 674.789C1365.25 673.262 1365.91 671.111 1365.91 668.697C1365.91 666.283 1365.25 664.177 1364.2 662.658C1363.18 661.086 1361.79 660.191 1360.29 660.191C1358.8 660.191 1357.43 661.086 1356.41 662.658C1355.39 664.185 1354.7 666.336 1354.7 668.697Z" fill="#E71D36"/>
</g>
<g opacity="0.95">
<path d="M1339.17 565.775L1340.82 564.278C1341.03 564.083 1341.15 563.82 1341.15 563.556C1341.15 563.323 1341.05 563.09 1340.89 562.902C1340.7 562.692 1340.43 562.579 1340.17 562.579C1339.94 562.579 1339.69 562.669 1339.51 562.834L1339.34 562.992C1339.34 562.992 1339.28 563.015 1339.25 562.992C1339.23 562.97 1339.23 562.925 1339.25 562.902L1339.42 562.744C1339.64 562.556 1339.9 562.458 1340.16 562.458C1340.46 562.458 1340.76 562.579 1340.97 562.819C1341.16 563.03 1341.26 563.301 1341.26 563.556C1341.26 563.857 1341.14 564.158 1340.89 564.369L1339.36 565.76H1341.2C1341.2 565.76 1341.26 565.783 1341.26 565.82C1341.26 565.858 1341.23 565.88 1341.2 565.88H1339.2C1339.2 565.88 1339.16 565.88 1339.15 565.858C1339.12 565.835 1339.12 565.798 1339.15 565.768L1339.17 565.775Z" fill="#E71D36"/>
<path d="M1342.05 565.775L1343.71 564.278C1343.92 564.083 1344.03 563.82 1344.03 563.556C1344.03 563.323 1343.94 563.09 1343.78 562.902C1343.59 562.692 1343.32 562.579 1343.05 562.579C1342.82 562.579 1342.58 562.669 1342.4 562.834L1342.23 562.992C1342.23 562.992 1342.17 563.015 1342.14 562.992C1342.11 562.97 1342.11 562.925 1342.14 562.902L1342.31 562.744C1342.53 562.556 1342.78 562.458 1343.05 562.458C1343.35 562.458 1343.65 562.579 1343.86 562.819C1344.05 563.03 1344.14 563.301 1344.14 563.556C1344.14 563.857 1344.02 564.158 1343.78 564.369L1342.24 565.76H1344.09C1344.09 565.76 1344.15 565.783 1344.15 565.82C1344.15 565.858 1344.12 565.88 1344.09 565.88H1342.09C1342.09 565.88 1342.05 565.88 1342.04 565.858C1342.02 565.835 1342.02 565.798 1342.04 565.768L1342.05 565.775Z" fill="#E71D36"/>
</g>
<g opacity="0.78">
<path d="M1459.28 577.38H1450.23C1450.16 577.38 1450.12 577.357 1450.06 577.312C1449.94 577.199 1449.92 577.049 1450.01 576.936L1459.34 565.324L1459.38 565.279C1459.45 565.234 1459.49 565.211 1459.56 565.211C1459.65 565.211 1459.74 565.279 1459.79 565.346C1459.81 565.369 1459.81 565.392 1459.83 565.414C1459.83 565.437 1459.86 565.459 1459.86 565.482V576.831H1461.21C1461.38 576.831 1461.49 576.944 1461.49 577.094C1461.49 577.267 1461.38 577.38 1461.21 577.38H1459.86V580.388C1459.86 580.546 1459.74 580.674 1459.57 580.674C1459.43 580.674 1459.28 580.538 1459.28 580.388V577.38H1459.28ZM1459.28 576.823V566.339L1450.81 576.823H1459.28Z" fill="#5EB0EF"/>
<path d="M1472.55 571.844C1473.54 572.837 1474.05 574.168 1474.05 575.469C1474.05 576.771 1473.54 578.124 1472.55 579.14C1471.53 580.132 1470.2 580.644 1468.9 580.644H1468.88C1467.57 580.644 1466.25 580.155 1465.25 579.14C1465.14 579.027 1465.14 578.854 1465.25 578.741C1465.36 578.628 1465.54 578.628 1465.65 578.741C1466.56 579.628 1467.71 580.072 1468.88 580.072C1470.05 580.072 1471.25 579.628 1472.14 578.718C1473.04 577.831 1473.48 576.658 1473.48 575.469C1473.48 574.281 1473.04 573.146 1472.15 572.235C1471.25 571.348 1470.09 570.912 1468.92 570.912C1468.81 570.912 1468.72 570.867 1468.66 570.776C1468.66 570.754 1468.63 570.754 1468.63 570.754C1468.52 570.641 1468.52 570.446 1468.63 570.333L1473.1 565.73H1466.27C1466.11 565.73 1465.98 565.595 1465.98 565.444C1465.98 565.294 1466.12 565.158 1466.27 565.158H1473.76C1473.92 565.158 1474.04 565.294 1474.04 565.444C1474.04 565.489 1474.04 565.534 1474.02 565.58C1474 565.625 1473.98 565.67 1473.95 565.692L1469.4 570.363C1470.55 570.476 1471.68 570.957 1472.54 571.844H1472.55Z" fill="#5EB0EF"/>
</g>
<g opacity="0.52">
<path d="M1323.64 693.93C1323.91 694.193 1324.04 694.539 1324.04 694.885C1324.04 695.231 1323.91 695.584 1323.64 695.847C1323.37 696.111 1323.03 696.246 1322.68 696.246C1322.33 696.246 1321.99 696.118 1321.73 695.847C1321.7 695.817 1321.7 695.772 1321.73 695.742C1321.76 695.712 1321.8 695.712 1321.83 695.742C1322.07 695.975 1322.37 696.096 1322.68 696.096C1322.99 696.096 1323.3 695.975 1323.54 695.742C1323.78 695.509 1323.89 695.201 1323.89 694.885C1323.89 694.569 1323.78 694.276 1323.54 694.035C1323.3 693.802 1323 693.681 1322.7 693.681C1322.66 693.681 1322.64 693.666 1322.63 693.644C1322.6 693.614 1322.6 693.561 1322.63 693.531L1323.8 692.32H1322C1321.97 692.32 1321.93 692.283 1321.93 692.245C1321.93 692.207 1321.97 692.17 1322 692.17H1323.97C1324.02 692.17 1324.05 692.207 1324.05 692.245C1324.05 692.26 1324.05 692.267 1324.05 692.283C1324.05 692.283 1324.03 692.305 1324.03 692.313L1322.84 693.538C1323.14 693.569 1323.44 693.696 1323.66 693.93H1323.64Z" fill="#E71D36"/>
<path d="M1326.6 692.177C1327.39 692.177 1327.99 693.117 1327.99 694.208C1327.99 695.298 1327.39 696.246 1326.6 696.254C1325.81 696.254 1325.21 695.313 1325.21 694.208C1325.21 693.102 1325.81 692.185 1326.6 692.177ZM1325.36 694.208C1325.36 694.742 1325.51 695.223 1325.73 695.562C1325.96 695.908 1326.26 696.111 1326.6 696.111C1326.93 696.111 1327.23 695.915 1327.46 695.562C1327.69 695.223 1327.84 694.749 1327.84 694.208C1327.84 693.666 1327.69 693.208 1327.46 692.869C1327.23 692.523 1326.93 692.32 1326.6 692.32C1326.26 692.32 1325.96 692.516 1325.73 692.869C1325.51 693.208 1325.36 693.681 1325.36 694.208Z" fill="#E71D36"/>
</g>
<g opacity="0.53">
<path d="M1388.64 698.969H1382.67C1382.63 698.969 1382.6 698.953 1382.55 698.923C1382.48 698.848 1382.46 698.75 1382.52 698.675L1388.68 691.011L1388.71 690.981C1388.75 690.951 1388.78 690.936 1388.83 690.936C1388.89 690.936 1388.94 690.981 1388.97 691.027C1388.99 691.042 1388.99 691.057 1389 691.072C1389 691.087 1389.02 691.102 1389.02 691.117V698.6H1389.9C1390.02 698.6 1390.09 698.675 1390.09 698.773C1390.09 698.893 1390.02 698.961 1389.9 698.961H1389.02V700.946C1389.02 701.052 1388.94 701.135 1388.83 701.135C1388.74 701.135 1388.64 701.044 1388.64 700.946V698.961V698.969ZM1388.64 698.608V691.688L1383.06 698.608H1388.64Z" fill="#E71D36"/>
<path d="M1392.19 700.804L1397.12 696.351C1397.74 695.765 1398.08 694.982 1398.08 694.193C1398.08 693.508 1397.82 692.809 1397.32 692.252C1396.75 691.628 1395.95 691.29 1395.18 691.29C1394.48 691.29 1393.78 691.553 1393.23 692.049L1392.71 692.516C1392.62 692.591 1392.53 692.591 1392.45 692.516C1392.38 692.44 1392.39 692.313 1392.47 692.252L1392.98 691.771C1393.62 691.2 1394.39 690.921 1395.18 690.921C1396.07 690.921 1396.96 691.29 1397.6 691.989C1398.16 692.613 1398.45 693.418 1398.45 694.193C1398.45 695.08 1398.08 695.975 1397.35 696.614L1392.77 700.774H1398.27C1398.36 700.774 1398.44 700.849 1398.44 700.946C1398.44 701.044 1398.35 701.135 1398.27 701.135H1392.32C1392.26 701.135 1392.2 701.119 1392.17 701.059C1392.11 700.984 1392.11 700.871 1392.18 700.796L1392.19 700.804Z" fill="#E71D36"/>
</g>
<g opacity="0.92">
<path d="M1482.12 745.394C1482.81 745.394 1483.36 745.959 1483.36 746.658C1483.36 747.154 1483.06 747.591 1482.65 747.794C1483.5 748.027 1484.14 748.809 1484.14 749.734C1484.14 750.847 1483.23 751.75 1482.12 751.75C1481 751.75 1480.09 750.855 1480.09 749.734C1480.09 748.809 1480.73 748.027 1481.58 747.794C1481.15 747.598 1480.86 747.162 1480.86 746.658C1480.86 745.959 1481.43 745.394 1482.12 745.394H1482.12ZM1482.12 751.516C1483.12 751.516 1483.91 750.719 1483.91 749.726C1483.91 748.734 1483.12 747.929 1482.12 747.929C1481.12 747.929 1480.32 748.734 1480.32 749.726C1480.32 750.719 1481.12 751.516 1482.12 751.516ZM1482.12 747.681C1482.68 747.681 1483.14 747.214 1483.14 746.658C1483.14 746.101 1482.67 745.635 1482.12 745.628C1481.55 745.635 1481.08 746.086 1481.08 746.658C1481.08 747.23 1481.55 747.681 1482.12 747.681Z" fill="#5EB0EF"/>
<path d="M1487.93 745.394C1488.99 745.394 1489.87 746.222 1489.96 747.282C1489.99 747.455 1490 747.636 1490 747.816C1490 748.666 1489.82 749.614 1489.38 750.373C1488.93 751.133 1488.23 751.697 1487.18 751.742C1487.11 751.742 1487.06 751.697 1487.06 751.622C1487.05 751.569 1487.11 751.516 1487.16 751.516C1488.13 751.471 1488.75 750.96 1489.17 750.268C1489.48 749.726 1489.67 749.05 1489.74 748.41C1489.4 749.065 1488.72 749.508 1487.93 749.508C1486.79 749.508 1485.86 748.591 1485.86 747.455C1485.86 746.32 1486.79 745.394 1487.93 745.394ZM1487.93 749.275C1488.93 749.275 1489.74 748.455 1489.74 747.455C1489.74 747.41 1489.74 747.365 1489.74 747.32V747.282C1489.65 746.342 1488.87 745.628 1487.93 745.628C1486.91 745.628 1486.08 746.447 1486.08 747.455C1486.08 748.463 1486.91 749.275 1487.93 749.275Z" fill="#5EB0EF"/>
</g>
<g opacity="0.51">
<path d="M1398.57 752.419C1398.57 751.975 1398.67 751.479 1398.89 751.088C1399.12 750.689 1399.49 750.403 1400.03 750.373C1400.06 750.373 1400.09 750.396 1400.09 750.433C1400.09 750.463 1400.07 750.494 1400.04 750.494C1399.54 750.516 1399.22 750.779 1398.99 751.14C1398.82 751.426 1398.73 751.772 1398.7 752.111C1398.87 751.765 1399.23 751.531 1399.64 751.531C1400.23 751.531 1400.71 752.005 1400.72 752.599C1400.72 753.194 1400.24 753.667 1399.64 753.667C1399.1 753.667 1398.64 753.254 1398.58 752.72V752.705C1398.58 752.607 1398.57 752.509 1398.57 752.411V752.419ZM1399.64 753.562C1400.17 753.562 1400.59 753.141 1400.6 752.607C1400.59 752.088 1400.17 751.659 1399.64 751.659C1399.12 751.659 1398.7 752.08 1398.7 752.607C1398.7 753.133 1399.12 753.562 1399.64 753.562Z" fill="#5EB0EF"/>
<path d="M1402.72 750.381C1403.36 750.381 1403.84 751.14 1403.84 752.028C1403.84 752.915 1403.36 753.675 1402.72 753.682C1402.08 753.682 1401.6 752.923 1401.6 752.028C1401.6 751.133 1402.08 750.388 1402.72 750.381ZM1401.71 752.028C1401.71 752.464 1401.83 752.848 1402.02 753.118C1402.2 753.404 1402.45 753.562 1402.72 753.562C1402.99 753.562 1403.24 753.404 1403.42 753.118C1403.6 752.848 1403.72 752.457 1403.72 752.028C1403.72 751.599 1403.6 751.216 1403.42 750.945C1403.23 750.659 1402.98 750.501 1402.72 750.501C1402.45 750.501 1402.21 750.659 1402.02 750.945C1401.84 751.216 1401.71 751.607 1401.71 752.028Z" fill="#5EB0EF"/>
</g>
<path d="M1402.88 744.8L1407.38 734.053H1401.33C1401.22 734.053 1401.12 733.955 1401.12 733.835C1401.12 733.737 1401.22 733.624 1401.33 733.624H1407.73C1407.83 733.624 1407.94 733.737 1407.94 733.835C1407.94 733.888 1407.91 733.933 1407.87 733.985L1403.24 744.966C1403.23 745.033 1403.15 745.094 1403.06 745.094C1403.05 745.094 1403.01 745.079 1402.98 745.079C1402.87 745.033 1402.83 744.913 1402.87 744.8H1402.88Z" fill="#5EB0EF"/>
<path d="M1410.48 735.031L1412.78 733.67C1412.88 733.602 1413.01 733.617 1413.07 733.737C1413.13 733.835 1413.09 733.948 1412.98 734.015L1410.68 735.392C1410.68 735.392 1410.61 735.407 1410.58 735.407C1410.51 735.407 1410.43 735.392 1410.4 735.309C1410.35 735.226 1410.37 735.098 1410.48 735.031ZM1412.68 733.835C1412.68 733.737 1412.77 733.624 1412.87 733.624C1413 733.624 1413.09 733.737 1413.09 733.835V744.883C1413.09 744.996 1413.01 745.094 1412.87 745.094C1412.77 745.094 1412.68 744.996 1412.68 744.883V733.835Z" fill="#5EB0EF"/>
<g opacity="0.66">
<path d="M1418.8 736.054C1418.73 736.001 1418.73 735.873 1418.8 735.798C1418.88 735.73 1418.98 735.73 1419.06 735.798C1419.63 736.362 1420.38 736.655 1421.1 736.655C1421.82 736.655 1422.6 736.362 1423.17 735.798C1423.75 735.219 1424.02 734.474 1424.02 733.73C1424.02 732.985 1423.75 732.241 1423.17 731.677C1422.6 731.112 1421.87 730.834 1421.12 730.834C1420.36 730.834 1419.64 731.112 1419.06 731.677C1419.01 731.744 1418.89 731.744 1418.82 731.692C1418.8 731.692 1418.79 731.677 1418.79 731.661C1418.77 731.661 1418.77 731.646 1418.77 731.646C1418.74 731.601 1418.73 731.564 1418.74 731.504L1419.23 727.36C1419.24 727.277 1419.3 727.217 1419.36 727.202C1419.39 727.187 1419.41 727.172 1419.45 727.172H1424.2C1424.29 727.172 1424.38 727.269 1424.38 727.352C1424.38 727.465 1424.29 727.533 1424.2 727.533H1419.58L1419.16 731.097C1419.74 730.661 1420.42 730.451 1421.12 730.451C1421.96 730.451 1422.8 730.759 1423.44 731.406C1424.06 732.038 1424.38 732.865 1424.38 733.707C1424.38 734.549 1424.06 735.392 1423.41 736.024C1422.78 736.67 1421.94 736.994 1421.1 736.994C1420.26 736.994 1419.45 736.67 1418.8 736.024V736.054Z" fill="#5EB0EF"/>
<path d="M1430.63 727.194C1432.55 727.209 1433.98 729.458 1433.98 732.09C1433.98 734.722 1432.55 737.009 1430.63 737.016C1428.74 737.001 1427.29 734.752 1427.29 732.09C1427.29 729.428 1428.74 727.202 1430.63 727.194ZM1427.64 732.09C1427.64 733.384 1428 734.534 1428.55 735.347C1429.1 736.189 1429.84 736.67 1430.62 736.67C1431.4 736.67 1432.16 736.189 1432.71 735.347C1433.28 734.534 1433.62 733.384 1433.62 732.09C1433.62 730.797 1433.27 729.676 1432.71 728.864C1432.16 728.021 1431.42 727.548 1430.62 727.548C1429.83 727.548 1429.09 728.021 1428.55 728.864C1428 729.676 1427.64 730.827 1427.64 732.09Z" fill="#5EB0EF"/>
</g>
<g opacity="0.58">
<path d="M1414.54 774.244L1418.03 765.911H1413.34C1413.25 765.911 1413.18 765.836 1413.18 765.746C1413.18 765.67 1413.25 765.58 1413.34 765.58H1418.3C1418.38 765.58 1418.47 765.67 1418.47 765.746C1418.47 765.783 1418.45 765.821 1418.42 765.859L1414.83 774.372C1414.82 774.425 1414.76 774.47 1414.69 774.47C1414.67 774.47 1414.65 774.455 1414.63 774.455C1414.54 774.417 1414.52 774.327 1414.54 774.237V774.244Z" fill="#E71D36"/>
<path d="M1420.43 766.671L1422.21 765.618C1422.29 765.565 1422.39 765.58 1422.44 765.671C1422.48 765.746 1422.45 765.836 1422.36 765.889L1420.58 766.957C1420.58 766.957 1420.53 766.972 1420.51 766.972C1420.45 766.972 1420.39 766.957 1420.36 766.896C1420.33 766.836 1420.34 766.731 1420.42 766.678L1420.43 766.671ZM1422.14 765.746C1422.14 765.671 1422.21 765.58 1422.29 765.58C1422.39 765.58 1422.45 765.671 1422.45 765.746V774.304C1422.45 774.395 1422.39 774.47 1422.29 774.47C1422.21 774.47 1422.14 774.395 1422.14 774.304V765.746Z" fill="#E71D36"/>
</g>
<g opacity="0.52">
<path d="M1273.46 853.724C1273.41 853.679 1273.41 853.589 1273.46 853.536C1273.51 853.484 1273.6 853.484 1273.65 853.536C1274.09 853.957 1274.64 854.183 1275.19 854.183C1275.74 854.183 1276.32 853.965 1276.74 853.536C1277.17 853.1 1277.38 852.543 1277.38 851.987C1277.38 851.43 1277.17 850.866 1276.74 850.445C1276.32 850.024 1275.77 849.813 1275.2 849.813C1274.63 849.813 1274.09 850.024 1273.65 850.445C1273.62 850.498 1273.52 850.498 1273.47 850.453C1273.46 850.453 1273.45 850.445 1273.45 850.43C1273.44 850.43 1273.44 850.423 1273.44 850.423C1273.42 850.392 1273.41 850.362 1273.42 850.317L1273.79 847.204C1273.79 847.143 1273.84 847.098 1273.88 847.083C1273.91 847.076 1273.93 847.061 1273.96 847.061H1277.53C1277.6 847.061 1277.66 847.136 1277.66 847.196C1277.66 847.279 1277.6 847.331 1277.53 847.331H1274.06L1273.74 850.016C1274.18 849.685 1274.69 849.528 1275.21 849.528C1275.84 849.528 1276.48 849.761 1276.96 850.25C1277.42 850.723 1277.66 851.348 1277.66 851.979C1277.66 852.611 1277.42 853.243 1276.93 853.724C1276.46 854.206 1275.83 854.454 1275.19 854.454C1274.55 854.454 1273.94 854.213 1273.46 853.724Z" fill="#5EB0EF"/>
<path d="M1282.1 847.053C1282.9 847.053 1283.56 847.707 1283.56 848.52C1283.56 849.099 1283.21 849.61 1282.72 849.843C1283.71 850.114 1284.45 851.024 1284.45 852.107C1284.45 853.408 1283.39 854.454 1282.1 854.454C1280.8 854.454 1279.75 853.408 1279.75 852.107C1279.75 851.032 1280.48 850.122 1281.47 849.843C1280.97 849.61 1280.63 849.106 1280.63 848.52C1280.63 847.707 1281.29 847.053 1282.1 847.053ZM1282.1 854.183C1283.25 854.183 1284.19 853.25 1284.19 852.1C1284.19 850.949 1283.25 850.009 1282.1 850.009C1280.94 850.009 1280.01 850.949 1280.01 852.1C1280.01 853.25 1280.95 854.183 1282.1 854.183ZM1282.1 849.716C1282.75 849.716 1283.29 849.174 1283.29 848.52C1283.29 847.865 1282.75 847.324 1282.1 847.316C1281.44 847.324 1280.89 847.858 1280.89 848.52C1280.89 849.182 1281.44 849.716 1282.1 849.716Z" fill="#5EB0EF"/>
</g>
<g opacity="0.89">
<path d="M1235.86 914.161L1237.1 913.424C1237.16 913.387 1237.23 913.394 1237.26 913.462C1237.29 913.514 1237.27 913.575 1237.21 913.612L1235.97 914.357C1235.97 914.357 1235.93 914.364 1235.92 914.364C1235.88 914.364 1235.83 914.357 1235.82 914.312C1235.8 914.267 1235.8 914.199 1235.86 914.161ZM1237.05 913.515C1237.05 913.462 1237.1 913.394 1237.16 913.394C1237.22 913.394 1237.27 913.454 1237.27 913.515V919.494C1237.27 919.554 1237.22 919.606 1237.16 919.606C1237.1 919.606 1237.05 919.554 1237.05 919.494V913.515Z" fill="#5EB0EF"/>
<path d="M1239.07 917.23C1239.07 916.395 1239.25 915.462 1239.67 914.725C1240.1 913.981 1240.81 913.432 1241.83 913.387C1241.89 913.387 1241.94 913.432 1241.94 913.499C1241.94 913.552 1241.9 913.605 1241.85 913.605C1240.9 913.65 1240.28 914.139 1239.87 914.831C1239.55 915.372 1239.38 916.019 1239.32 916.658C1239.65 916.011 1240.32 915.575 1241.1 915.575C1242.21 915.575 1243.12 916.47 1243.12 917.583C1243.12 918.704 1242.21 919.599 1241.1 919.599C1240.06 919.599 1239.22 918.824 1239.1 917.816C1239.09 917.809 1239.09 917.786 1239.09 917.779C1239.08 917.598 1239.07 917.418 1239.07 917.23ZM1241.1 919.381C1242.09 919.381 1242.88 918.584 1242.91 917.583C1242.89 916.598 1242.09 915.801 1241.1 915.801C1240.11 915.801 1239.32 916.598 1239.32 917.583C1239.32 918.568 1240.12 919.381 1241.1 919.381Z" fill="#5EB0EF"/>
</g>
<g opacity="0.55">
<path d="M1306.97 912.01L1309.61 909.626C1309.95 909.31 1310.13 908.889 1310.13 908.468C1310.13 908.099 1309.99 907.724 1309.72 907.43C1309.42 907.092 1308.99 906.911 1308.57 906.911C1308.2 906.911 1307.82 907.054 1307.53 907.317L1307.26 907.566C1307.21 907.603 1307.16 907.603 1307.12 907.566C1307.08 907.528 1307.09 907.453 1307.12 907.423L1307.39 907.167C1307.73 906.859 1308.15 906.716 1308.57 906.716C1309.04 906.716 1309.52 906.911 1309.87 907.287C1310.17 907.626 1310.32 908.054 1310.32 908.468C1310.32 908.942 1310.12 909.423 1309.73 909.762L1307.28 911.988H1310.23C1310.27 911.988 1310.33 912.025 1310.33 912.078C1310.33 912.131 1310.28 912.183 1310.23 912.183H1307.04C1307.04 912.183 1306.98 912.176 1306.96 912.146C1306.93 912.108 1306.93 912.048 1306.97 912.003L1306.97 912.01Z" fill="#E71D36"/>
<path d="M1311.71 910.1C1311.71 909.363 1311.87 908.543 1312.24 907.897C1312.62 907.242 1313.24 906.753 1314.14 906.716C1314.2 906.716 1314.25 906.753 1314.25 906.813C1314.25 906.859 1314.21 906.904 1314.16 906.904C1313.32 906.941 1312.78 907.37 1312.41 907.979C1312.14 908.461 1311.99 909.025 1311.93 909.589C1312.22 909.017 1312.81 908.634 1313.5 908.634C1314.47 908.634 1315.27 909.423 1315.28 910.401C1315.27 911.386 1314.47 912.176 1313.5 912.176C1312.58 912.176 1311.84 911.491 1311.74 910.604C1311.73 910.596 1311.73 910.581 1311.73 910.581C1311.72 910.416 1311.71 910.25 1311.71 910.085V910.1ZM1313.5 911.995C1314.37 911.995 1315.07 911.296 1315.08 910.416C1315.07 909.551 1314.37 908.844 1313.5 908.844C1312.62 908.844 1311.93 909.551 1311.93 910.416C1311.93 911.281 1312.63 911.995 1313.5 911.995Z" fill="#E71D36"/>
</g>
<g opacity="0.52">
<path d="M1257.67 840.247C1258.39 840.961 1258.76 841.916 1258.76 842.864C1258.76 843.812 1258.39 844.774 1257.67 845.511C1256.94 846.226 1255.98 846.594 1255.04 846.594H1255.03C1254.09 846.594 1253.13 846.248 1252.41 845.511C1252.33 845.429 1252.33 845.308 1252.41 845.226C1252.49 845.143 1252.62 845.143 1252.7 845.226C1253.35 845.865 1254.18 846.181 1255.03 846.181C1255.88 846.181 1256.73 845.865 1257.36 845.21C1258.02 844.571 1258.33 843.729 1258.33 842.872C1258.33 842.014 1258.01 841.194 1257.38 840.54C1256.73 839.901 1255.9 839.585 1255.06 839.585C1254.97 839.585 1254.91 839.555 1254.87 839.487C1254.87 839.472 1254.85 839.472 1254.85 839.472C1254.77 839.389 1254.77 839.247 1254.85 839.171L1258.07 835.855H1253.15C1253.04 835.855 1252.95 835.757 1252.95 835.652C1252.95 835.546 1253.04 835.441 1253.15 835.441H1258.55C1258.66 835.441 1258.76 835.539 1258.76 835.652C1258.76 835.682 1258.76 835.712 1258.74 835.749C1258.73 835.779 1258.71 835.81 1258.69 835.832L1255.41 839.194C1256.24 839.277 1257.05 839.623 1257.67 840.262V840.247Z" fill="#E71D36"/>
<path d="M1261.7 846.241L1267.08 841.375C1267.77 840.736 1268.13 839.878 1268.13 839.013C1268.13 838.261 1267.85 837.502 1267.31 836.893C1266.68 836.208 1265.81 835.84 1264.96 835.84C1264.2 835.84 1263.44 836.125 1262.83 836.667L1262.27 837.178C1262.17 837.261 1262.06 837.261 1261.99 837.178C1261.9 837.096 1261.93 836.953 1262 836.893L1262.56 836.366C1263.26 835.742 1264.1 835.441 1264.96 835.441C1265.93 835.441 1266.91 835.84 1267.61 836.607C1268.22 837.291 1268.53 838.171 1268.53 839.013C1268.53 839.984 1268.13 840.961 1267.34 841.661L1262.34 846.203H1268.34C1268.44 846.203 1268.53 846.286 1268.53 846.391C1268.53 846.497 1268.43 846.594 1268.34 846.594H1261.84C1261.78 846.594 1261.72 846.579 1261.69 846.512C1261.63 846.429 1261.63 846.309 1261.7 846.226V846.241Z" fill="#E71D36"/>
</g>
<g opacity="0.55">
<path d="M1346.23 868.517C1346.19 868.48 1346.19 868.397 1346.23 868.352C1346.28 868.307 1346.35 868.307 1346.4 868.352C1346.77 868.721 1347.26 868.916 1347.74 868.916C1348.22 868.916 1348.73 868.721 1349.1 868.352C1349.47 867.976 1349.66 867.487 1349.66 866.998C1349.66 866.509 1349.47 866.021 1349.1 865.652C1348.73 865.284 1348.25 865.103 1347.75 865.103C1347.25 865.103 1346.78 865.291 1346.4 865.652C1346.37 865.697 1346.29 865.697 1346.24 865.66C1346.24 865.66 1346.23 865.652 1346.23 865.637C1346.22 865.637 1346.22 865.63 1346.22 865.63C1346.2 865.599 1346.19 865.577 1346.2 865.539L1346.53 862.817C1346.53 862.764 1346.57 862.726 1346.61 862.719C1346.62 862.711 1346.65 862.696 1346.68 862.696H1349.79C1349.86 862.696 1349.91 862.764 1349.91 862.817C1349.91 862.892 1349.86 862.937 1349.79 862.937H1346.76L1346.48 865.276C1346.86 864.99 1347.31 864.855 1347.76 864.855C1348.31 864.855 1348.86 865.058 1349.28 865.479C1349.69 865.893 1349.9 866.442 1349.9 866.991C1349.9 867.54 1349.69 868.096 1349.26 868.51C1348.85 868.931 1348.29 869.149 1347.74 869.149C1347.19 869.149 1346.65 868.939 1346.23 868.51V868.517Z" fill="#E71D36"/>
<path d="M1354.9 865.472C1355.31 865.885 1355.52 866.442 1355.52 866.983C1355.52 867.525 1355.31 868.089 1354.9 868.51C1354.48 868.924 1353.92 869.134 1353.38 869.134H1353.37C1352.83 869.134 1352.28 868.931 1351.86 868.51C1351.82 868.465 1351.82 868.39 1351.86 868.345C1351.91 868.299 1351.98 868.299 1352.03 868.345C1352.4 868.713 1352.88 868.894 1353.37 868.894C1353.86 868.894 1354.36 868.705 1354.73 868.329C1355.1 867.961 1355.29 867.472 1355.29 866.976C1355.29 866.479 1355.1 866.006 1354.73 865.63C1354.36 865.261 1353.88 865.08 1353.39 865.08C1353.34 865.08 1353.31 865.065 1353.28 865.028H1353.27C1353.22 864.975 1353.22 864.892 1353.27 864.847L1355.12 862.93H1352.28C1352.22 862.93 1352.16 862.877 1352.16 862.809C1352.16 862.742 1352.21 862.689 1352.28 862.689H1355.39C1355.45 862.689 1355.51 862.742 1355.51 862.809C1355.51 862.824 1355.51 862.847 1355.51 862.862C1355.5 862.877 1355.49 862.899 1355.48 862.907L1353.58 864.855C1354.06 864.9 1354.53 865.103 1354.89 865.472H1354.9Z" fill="#E71D36"/>
</g>
<g opacity="0.61">
<path d="M1390.08 925.811C1390.98 925.811 1391.71 926.548 1391.71 927.458C1391.71 928.105 1391.32 928.676 1390.78 928.932C1391.89 929.241 1392.71 930.256 1392.71 931.459C1392.71 932.918 1391.53 934.084 1390.08 934.084C1388.63 934.084 1387.45 932.911 1387.45 931.459C1387.45 930.256 1388.28 929.233 1389.39 928.932C1388.82 928.669 1388.45 928.105 1388.45 927.458C1388.45 926.548 1389.18 925.811 1390.08 925.811H1390.08ZM1390.08 933.791C1391.38 933.791 1392.41 932.753 1392.41 931.459C1392.41 930.166 1391.38 929.12 1390.08 929.12C1388.78 929.12 1387.74 930.173 1387.74 931.459C1387.74 932.745 1388.79 933.791 1390.08 933.791ZM1390.08 928.789C1390.81 928.789 1391.41 928.188 1391.41 927.458C1391.41 926.729 1390.81 926.119 1390.08 926.112C1389.33 926.127 1388.73 926.713 1388.73 927.458C1388.73 928.203 1389.33 928.789 1390.08 928.789Z" fill="#5EB0EF"/>
<path d="M1394.81 933.813L1398.8 930.211C1399.31 929.737 1399.58 929.098 1399.58 928.466C1399.58 927.909 1399.37 927.345 1398.97 926.894C1398.51 926.383 1397.85 926.112 1397.23 926.112C1396.67 926.112 1396.1 926.322 1395.65 926.729L1395.24 927.105C1395.16 927.165 1395.09 927.165 1395.03 927.105C1394.97 927.044 1394.98 926.939 1395.04 926.894L1395.46 926.503C1395.98 926.044 1396.6 925.819 1397.24 925.819C1397.96 925.819 1398.68 926.112 1399.2 926.684C1399.65 927.195 1399.88 927.842 1399.88 928.466C1399.88 929.188 1399.59 929.91 1399 930.429L1395.28 933.798H1399.74C1399.81 933.798 1399.88 933.858 1399.88 933.941C1399.88 934.024 1399.81 934.099 1399.74 934.099H1394.92C1394.88 934.099 1394.83 934.084 1394.8 934.039C1394.76 933.979 1394.76 933.888 1394.82 933.828L1394.81 933.813Z" fill="#5EB0EF"/>
</g>
<g opacity="0.6">
<path d="M1340.19 1005.79L1341.24 1005.17C1341.29 1005.14 1341.35 1005.15 1341.38 1005.2C1341.4 1005.25 1341.38 1005.3 1341.33 1005.33L1340.28 1005.96C1340.28 1005.96 1340.25 1005.97 1340.24 1005.97C1340.21 1005.97 1340.17 1005.96 1340.15 1005.92C1340.13 1005.89 1340.14 1005.82 1340.19 1005.79ZM1341.2 1005.25C1341.2 1005.2 1341.24 1005.15 1341.29 1005.15C1341.35 1005.15 1341.39 1005.2 1341.39 1005.25V1010.3C1341.39 1010.35 1341.35 1010.4 1341.29 1010.4C1341.24 1010.4 1341.2 1010.35 1341.2 1010.3V1005.25Z" fill="#E71D36"/>
<path d="M1344.68 1005.15C1345.71 1005.15 1346.47 1006.36 1346.47 1007.77C1346.47 1009.17 1345.71 1010.39 1344.68 1010.4C1343.67 1010.39 1342.9 1009.19 1342.9 1007.77C1342.9 1006.34 1343.68 1005.16 1344.68 1005.15ZM1343.09 1007.77C1343.09 1008.46 1343.29 1009.07 1343.58 1009.51C1343.87 1009.96 1344.27 1010.22 1344.69 1010.22C1345.11 1010.22 1345.52 1009.96 1345.81 1009.51C1346.11 1009.07 1346.3 1008.46 1346.3 1007.77C1346.3 1007.07 1346.11 1006.47 1345.81 1006.04C1345.52 1005.58 1345.12 1005.33 1344.69 1005.33C1344.26 1005.33 1343.87 1005.58 1343.58 1006.04C1343.29 1006.47 1343.09 1007.09 1343.09 1007.77Z" fill="#E71D36"/>
</g>
<g opacity="0.84">
<path d="M1334.17 953.021C1334.73 953.578 1335 954.315 1335 955.037C1335 955.759 1334.73 956.511 1334.17 957.075C1333.61 957.624 1332.87 957.91 1332.14 957.91H1332.13C1331.41 957.91 1330.67 957.639 1330.11 957.075C1330.05 957.015 1330.05 956.917 1330.11 956.857C1330.17 956.797 1330.27 956.797 1330.33 956.857C1330.83 957.346 1331.47 957.594 1332.13 957.594C1332.78 957.594 1333.44 957.346 1333.93 956.842C1334.43 956.353 1334.68 955.699 1334.68 955.037C1334.68 954.375 1334.43 953.743 1333.95 953.239C1333.44 952.751 1332.8 952.502 1332.15 952.502C1332.09 952.502 1332.04 952.48 1332 952.427C1332 952.412 1331.98 952.412 1331.98 952.412C1331.92 952.352 1331.92 952.239 1331.98 952.179L1334.46 949.622H1330.67C1330.59 949.622 1330.51 949.547 1330.51 949.464C1330.51 949.381 1330.59 949.306 1330.67 949.306H1334.83C1334.91 949.306 1334.99 949.381 1334.99 949.464C1334.99 949.487 1334.99 949.517 1334.97 949.539C1334.96 949.562 1334.95 949.592 1334.94 949.599L1332.4 952.194C1333.04 952.254 1333.67 952.525 1334.15 953.014L1334.17 953.021Z" fill="#E71D36"/>
<path d="M1340.22 949.321C1341.67 949.321 1342.86 950.442 1342.99 951.878C1343.02 952.111 1343.04 952.359 1343.04 952.6C1343.04 953.758 1342.79 955.037 1342.2 956.067C1341.59 957.098 1340.63 957.857 1339.22 957.925C1339.12 957.925 1339.06 957.865 1339.06 957.767C1339.05 957.692 1339.12 957.617 1339.2 957.617C1340.51 957.556 1341.36 956.864 1341.93 955.917C1342.34 955.18 1342.6 954.27 1342.69 953.397C1342.23 954.285 1341.3 954.886 1340.23 954.886C1338.7 954.886 1337.42 953.645 1337.42 952.111C1337.42 950.577 1338.69 949.321 1340.23 949.321H1340.22ZM1340.22 954.578C1341.58 954.578 1342.69 953.472 1342.69 952.104C1342.69 952.044 1342.69 951.983 1342.68 951.923V951.863C1342.56 950.6 1341.5 949.629 1340.22 949.629C1338.85 949.629 1337.73 950.735 1337.73 952.111C1337.73 953.488 1338.85 954.586 1340.22 954.586V954.578Z" fill="#E71D36"/>
</g>
<g opacity="0.83">
<path d="M1410.14 928.263L1411.16 927.345C1411.29 927.225 1411.35 927.067 1411.35 926.901C1411.35 926.759 1411.3 926.616 1411.19 926.503C1411.07 926.375 1410.92 926.307 1410.75 926.307C1410.61 926.307 1410.47 926.36 1410.35 926.465L1410.25 926.563C1410.25 926.563 1410.21 926.578 1410.2 926.563C1410.18 926.548 1410.18 926.518 1410.2 926.51L1410.3 926.413C1410.44 926.292 1410.59 926.24 1410.75 926.24C1410.93 926.24 1411.12 926.315 1411.25 926.458C1411.36 926.586 1411.42 926.751 1411.42 926.909C1411.42 927.089 1411.35 927.278 1411.19 927.405L1410.26 928.263H1411.38C1411.38 928.263 1411.42 928.278 1411.42 928.3C1411.42 928.323 1411.41 928.338 1411.38 928.338H1410.16C1410.16 928.338 1410.14 928.338 1410.13 928.323C1410.11 928.308 1410.11 928.285 1410.13 928.27L1410.14 928.263Z" fill="#5EB0EF"/>
<path d="M1411.87 926.495L1412.29 926.247C1412.29 926.247 1412.34 926.24 1412.34 926.262C1412.35 926.277 1412.34 926.3 1412.33 926.315L1411.91 926.571H1411.89C1411.89 926.571 1411.86 926.571 1411.86 926.556C1411.86 926.541 1411.86 926.518 1411.88 926.503L1411.87 926.495ZM1412.27 926.277C1412.27 926.277 1412.28 926.24 1412.31 926.24C1412.33 926.24 1412.34 926.262 1412.34 926.277V928.293C1412.34 928.293 1412.33 928.33 1412.31 928.33C1412.29 928.33 1412.27 928.315 1412.27 928.293V926.277Z" fill="#5EB0EF"/>
</g>
<g opacity="0.84">
<path d="M1309.86 914.282C1309.8 914.237 1309.8 914.131 1309.86 914.071C1309.92 914.011 1310.01 914.011 1310.07 914.071C1310.54 914.537 1311.16 914.778 1311.76 914.778C1312.36 914.778 1313 914.537 1313.47 914.071C1313.94 913.597 1314.17 912.98 1314.17 912.364C1314.17 911.747 1313.94 911.138 1313.47 910.672C1313 910.205 1312.4 909.972 1311.78 909.972C1311.15 909.972 1310.56 910.205 1310.07 910.672C1310.03 910.732 1309.93 910.732 1309.87 910.687C1309.86 910.687 1309.85 910.672 1309.85 910.664C1309.84 910.664 1309.84 910.649 1309.84 910.649C1309.81 910.611 1309.8 910.581 1309.81 910.536L1310.22 907.114C1310.23 907.047 1310.28 907.001 1310.32 906.986C1310.35 906.971 1310.37 906.964 1310.41 906.964H1314.32C1314.41 906.964 1314.47 907.047 1314.47 907.114C1314.47 907.205 1314.41 907.265 1314.32 907.265H1310.51L1310.17 910.213C1310.64 909.852 1311.21 909.679 1311.78 909.679C1312.47 909.679 1313.17 909.935 1313.7 910.469C1314.21 910.987 1314.47 911.672 1314.47 912.371C1314.47 913.071 1314.21 913.763 1313.67 914.289C1313.15 914.823 1312.45 915.086 1311.76 915.086C1311.07 915.086 1310.39 914.816 1309.86 914.289V914.282Z" fill="#E71D36"/>
<path d="M1316.56 907.972L1318.18 907.009C1318.25 906.964 1318.34 906.971 1318.39 907.054C1318.42 907.122 1318.4 907.204 1318.32 907.25L1316.7 908.227C1316.7 908.227 1316.65 908.242 1316.63 908.242C1316.59 908.242 1316.53 908.227 1316.5 908.175C1316.47 908.115 1316.48 908.024 1316.56 907.979L1316.56 907.972ZM1318.11 907.122C1318.11 907.054 1318.18 906.971 1318.25 906.971C1318.34 906.971 1318.4 907.054 1318.4 907.122V914.928C1318.4 915.011 1318.34 915.079 1318.25 915.079C1318.18 915.079 1318.11 915.011 1318.11 914.928V907.122Z" fill="#E71D36"/>
</g>
<g opacity="0.8">
<path d="M1359.99 898.247L1363.28 895.284C1363.69 894.893 1363.92 894.374 1363.92 893.848C1363.92 893.389 1363.74 892.923 1363.41 892.554C1363.04 892.14 1362.5 891.915 1361.98 891.915C1361.52 891.915 1361.05 892.088 1360.68 892.419L1360.35 892.727C1360.29 892.78 1360.22 892.78 1360.17 892.727C1360.13 892.682 1360.14 892.592 1360.18 892.554L1360.52 892.231C1360.95 891.855 1361.46 891.667 1361.98 891.667C1362.58 891.667 1363.17 891.907 1363.59 892.374C1363.96 892.787 1364.16 893.329 1364.16 893.84C1364.16 894.434 1363.92 895.028 1363.43 895.45L1360.38 898.217H1364.04C1364.1 898.217 1364.16 898.27 1364.16 898.338C1364.16 898.405 1364.1 898.465 1364.04 898.465H1360.08C1360.04 898.465 1359.99 898.458 1359.98 898.42C1359.94 898.375 1359.94 898.292 1359.99 898.247H1359.99Z" fill="#5EB0EF"/>
<path d="M1365.87 897.796C1365.83 897.758 1365.83 897.668 1365.87 897.623C1365.92 897.57 1366 897.57 1366.04 897.623C1366.44 898.014 1366.95 898.217 1367.46 898.217C1367.97 898.217 1368.5 898.014 1368.89 897.623C1369.29 897.224 1369.49 896.705 1369.49 896.194C1369.49 895.683 1369.29 895.164 1368.89 894.773C1368.5 894.382 1368 894.186 1367.47 894.186C1366.94 894.186 1366.45 894.382 1366.04 894.773C1366.01 894.818 1365.92 894.818 1365.88 894.78C1365.87 894.78 1365.86 894.773 1365.86 894.765C1365.85 894.765 1365.85 894.758 1365.85 894.758C1365.83 894.727 1365.82 894.697 1365.83 894.66L1366.16 891.794C1366.17 891.734 1366.22 891.697 1366.25 891.689C1366.28 891.682 1366.29 891.667 1366.32 891.667H1369.61C1369.67 891.667 1369.73 891.734 1369.73 891.794C1369.73 891.87 1369.67 891.922 1369.61 891.922H1366.41L1366.12 894.389C1366.52 894.088 1366.99 893.945 1367.47 893.945C1368.06 893.945 1368.64 894.156 1369.08 894.607C1369.51 895.043 1369.73 895.615 1369.73 896.202C1369.73 896.788 1369.51 897.367 1369.06 897.804C1368.63 898.247 1368.04 898.473 1367.46 898.473C1366.88 898.473 1366.32 898.247 1365.87 897.804V897.796Z" fill="#5EB0EF"/>
</g>
<g opacity="0.66">
<path d="M1269.66 1071.69C1270.24 1072.27 1270.53 1073.04 1270.53 1073.79C1270.53 1074.54 1270.24 1075.32 1269.66 1075.92C1269.07 1076.5 1268.3 1076.79 1267.55 1076.79H1267.53C1266.78 1076.79 1266.01 1076.51 1265.44 1075.92C1265.37 1075.86 1265.37 1075.75 1265.44 1075.69C1265.5 1075.62 1265.6 1075.62 1265.67 1075.69C1266.2 1076.2 1266.86 1076.45 1267.54 1076.45C1268.22 1076.45 1268.91 1076.2 1269.43 1075.67C1269.95 1075.16 1270.21 1074.48 1270.21 1073.78C1270.21 1073.08 1269.95 1072.44 1269.44 1071.91C1268.92 1071.4 1268.25 1071.14 1267.57 1071.14C1267.5 1071.14 1267.46 1071.12 1267.42 1071.07C1267.42 1071.05 1267.41 1071.05 1267.41 1071.05C1267.35 1070.99 1267.35 1070.87 1267.41 1070.81L1269.99 1068.15H1266.03C1265.94 1068.15 1265.86 1068.07 1265.86 1067.98C1265.86 1067.89 1265.94 1067.82 1266.03 1067.82H1270.37C1270.46 1067.82 1270.54 1067.89 1270.54 1067.98C1270.54 1068.01 1270.54 1068.04 1270.52 1068.06C1270.51 1068.08 1270.5 1068.11 1270.49 1068.12L1267.85 1070.82C1268.52 1070.89 1269.17 1071.17 1269.67 1071.68L1269.66 1071.69Z" fill="#5EB0EF"/>
<path d="M1274.15 1076.57L1277.66 1068.18H1272.94C1272.85 1068.18 1272.77 1068.1 1272.77 1068.01C1272.77 1067.94 1272.85 1067.85 1272.94 1067.85H1277.93C1278.01 1067.85 1278.1 1067.94 1278.1 1068.01C1278.1 1068.05 1278.08 1068.09 1278.05 1068.13L1274.43 1076.7C1274.42 1076.75 1274.36 1076.81 1274.29 1076.81C1274.27 1076.81 1274.25 1076.79 1274.22 1076.79C1274.13 1076.75 1274.11 1076.66 1274.13 1076.57H1274.15Z" fill="#5EB0EF"/>
</g>
<g opacity="0.71">
<path d="M1291.33 1042.27L1293.62 1036.81H1290.54C1290.48 1036.81 1290.44 1036.76 1290.44 1036.71C1290.44 1036.65 1290.49 1036.59 1290.54 1036.59H1293.79C1293.84 1036.59 1293.9 1036.65 1293.9 1036.71C1293.9 1036.73 1293.88 1036.76 1293.87 1036.78L1291.52 1042.35C1291.52 1042.35 1291.47 1042.42 1291.43 1042.42C1291.43 1042.42 1291.41 1042.41 1291.39 1042.41C1291.33 1042.39 1291.32 1042.33 1291.33 1042.27H1291.33Z" fill="#E71D36"/>
<path d="M1297.99 1041.18H1294.59C1294.59 1041.18 1294.55 1041.17 1294.52 1041.16C1294.48 1041.12 1294.47 1041.06 1294.51 1041.01L1298.02 1036.64L1298.04 1036.62C1298.04 1036.62 1298.08 1036.6 1298.11 1036.6C1298.14 1036.6 1298.17 1036.62 1298.19 1036.65C1298.19 1036.66 1298.19 1036.67 1298.2 1036.68V1040.98H1298.72C1298.79 1040.98 1298.83 1041.01 1298.83 1041.07C1298.83 1041.14 1298.78 1041.19 1298.72 1041.19H1298.21V1042.32C1298.21 1042.38 1298.17 1042.43 1298.1 1042.43C1298.05 1042.43 1297.99 1042.38 1297.99 1042.32V1041.19V1041.18ZM1297.99 1040.97V1037.02L1294.81 1040.97H1297.99Z" fill="#E71D36"/>
</g>
<path d="M1236.08 1102.39L1242.42 1096.66C1243.23 1095.91 1243.66 1094.89 1243.66 1093.88C1243.66 1093 1243.33 1092.1 1242.69 1091.38C1241.95 1090.58 1240.92 1090.14 1239.93 1090.14C1239.03 1090.14 1238.13 1090.48 1237.41 1091.12L1236.76 1091.72C1236.65 1091.82 1236.52 1091.82 1236.42 1091.72C1236.33 1091.63 1236.34 1091.46 1236.43 1091.38L1237.09 1090.76C1237.92 1090.04 1238.91 1089.67 1239.92 1089.67C1241.06 1089.67 1242.21 1090.14 1243.03 1091.04C1243.75 1091.85 1244.12 1092.89 1244.12 1093.88C1244.12 1095.02 1243.65 1096.17 1242.71 1097L1236.82 1102.35H1243.89C1244 1102.35 1244.12 1102.44 1244.12 1102.58C1244.12 1102.72 1244 1102.82 1243.89 1102.82H1236.23C1236.16 1102.82 1236.08 1102.81 1236.04 1102.73C1235.97 1102.64 1235.97 1102.49 1236.06 1102.39H1236.08Z" fill="#5EB0EF"/>
<path d="M1247.15 1102.39L1253.49 1096.66C1254.3 1095.91 1254.73 1094.89 1254.73 1093.88C1254.73 1093 1254.4 1092.1 1253.76 1091.38C1253.03 1090.58 1251.99 1090.14 1251 1090.14C1250.1 1090.14 1249.2 1090.48 1248.48 1091.12L1247.83 1091.72C1247.71 1091.82 1247.58 1091.82 1247.49 1091.72C1247.4 1091.63 1247.41 1091.46 1247.5 1091.38L1248.16 1090.76C1248.98 1090.04 1249.98 1089.67 1250.99 1089.67C1252.13 1089.67 1253.28 1090.14 1254.1 1091.04C1254.82 1091.85 1255.19 1092.89 1255.19 1093.88C1255.19 1095.02 1254.73 1096.17 1253.79 1097L1247.9 1102.35H1254.97C1255.08 1102.35 1255.19 1102.44 1255.19 1102.58C1255.19 1102.72 1255.08 1102.82 1254.97 1102.82H1247.31C1247.23 1102.82 1247.16 1102.81 1247.12 1102.73C1247.05 1102.64 1247.05 1102.49 1247.14 1102.39H1247.15Z" fill="#5EB0EF"/>
<g opacity="0.64">
<path d="M1282.84 1099.08L1287.37 1088.28H1281.29C1281.18 1088.28 1281.08 1088.19 1281.08 1088.06C1281.08 1087.97 1281.18 1087.85 1281.29 1087.85H1287.72C1287.82 1087.85 1287.93 1087.97 1287.93 1088.06C1287.93 1088.12 1287.9 1088.16 1287.86 1088.22L1283.22 1099.24C1283.2 1099.31 1283.12 1099.37 1283.04 1099.37C1283.02 1099.37 1282.99 1099.35 1282.95 1099.35C1282.84 1099.3 1282.8 1099.19 1282.84 1099.08V1099.08Z" fill="#E71D36"/>
<path d="M1292.51 1099.08L1297.04 1088.28H1290.96C1290.84 1088.28 1290.74 1088.19 1290.74 1088.06C1290.74 1087.97 1290.84 1087.85 1290.96 1087.85H1297.38C1297.48 1087.85 1297.59 1087.97 1297.59 1088.06C1297.59 1088.12 1297.56 1088.16 1297.53 1088.22L1292.88 1099.24C1292.87 1099.31 1292.78 1099.37 1292.7 1099.37C1292.69 1099.37 1292.66 1099.35 1292.62 1099.35C1292.51 1099.3 1292.47 1099.19 1292.51 1099.08V1099.08Z" fill="#E71D36"/>
</g>
<g opacity="0.98">
<path d="M1151.13 1081.77C1151.03 1081.69 1151.03 1081.52 1151.13 1081.43C1151.23 1081.33 1151.38 1081.33 1151.47 1081.43C1152.26 1082.19 1153.26 1082.59 1154.25 1082.59C1155.24 1082.59 1156.28 1082.19 1157.04 1081.43C1157.82 1080.66 1158.2 1079.65 1158.2 1078.64C1158.2 1077.63 1157.83 1076.63 1157.04 1075.87C1156.28 1075.11 1155.3 1074.72 1154.27 1074.72C1153.24 1074.72 1152.28 1075.11 1151.48 1075.87C1151.42 1075.96 1151.26 1075.96 1151.16 1075.89C1151.14 1075.89 1151.12 1075.87 1151.12 1075.85C1151.1 1075.85 1151.1 1075.84 1151.1 1075.84C1151.06 1075.78 1151.04 1075.72 1151.06 1075.65L1151.72 1070.04C1151.74 1069.93 1151.81 1069.86 1151.9 1069.83C1151.93 1069.82 1151.97 1069.8 1152.03 1069.8H1158.45C1158.58 1069.8 1158.7 1069.93 1158.7 1070.04C1158.7 1070.2 1158.58 1070.29 1158.45 1070.29H1152.2L1151.63 1075.12C1152.41 1074.53 1153.34 1074.25 1154.27 1074.25C1155.41 1074.25 1156.55 1074.67 1157.42 1075.54C1158.25 1076.4 1158.69 1077.52 1158.69 1078.66C1158.69 1079.81 1158.25 1080.94 1157.38 1081.8C1156.52 1082.67 1155.39 1083.11 1154.25 1083.11C1153.11 1083.11 1152.01 1082.67 1151.14 1081.8L1151.13 1081.77Z" fill="#5EB0EF"/>
<path d="M1169 1075.5C1169.86 1076.35 1170.29 1077.49 1170.29 1078.61C1170.29 1079.73 1169.86 1080.89 1169 1081.76C1168.13 1082.62 1166.99 1083.06 1165.87 1083.06H1165.84C1164.72 1083.06 1163.58 1082.63 1162.73 1081.76C1162.63 1081.66 1162.63 1081.51 1162.73 1081.42C1162.83 1081.33 1162.98 1081.33 1163.07 1081.42C1163.85 1082.18 1164.84 1082.57 1165.84 1082.57C1166.85 1082.57 1167.87 1082.19 1168.63 1081.41C1169.41 1080.65 1169.79 1079.64 1169.79 1078.62C1169.79 1077.6 1169.41 1076.63 1168.65 1075.84C1167.87 1075.08 1166.88 1074.7 1165.87 1074.7C1165.78 1074.7 1165.7 1074.66 1165.65 1074.59C1165.65 1074.57 1165.63 1074.57 1165.63 1074.57C1165.53 1074.47 1165.53 1074.31 1165.63 1074.21L1169.46 1070.26H1163.59C1163.45 1070.26 1163.34 1070.15 1163.34 1070.01C1163.34 1069.88 1163.45 1069.77 1163.59 1069.77H1170.02C1170.16 1069.77 1170.27 1069.88 1170.27 1070.01C1170.27 1070.05 1170.27 1070.09 1170.25 1070.13C1170.23 1070.17 1170.22 1070.2 1170.19 1070.23L1166.28 1074.23C1167.26 1074.33 1168.23 1074.75 1168.98 1075.51L1169 1075.5Z" fill="#5EB0EF"/>
</g>
<g opacity="0.55">
<path d="M1258.37 1112.3C1258.37 1112.3 1258.35 1112.24 1258.37 1112.21C1258.39 1112.19 1258.43 1112.19 1258.46 1112.21C1258.66 1112.41 1258.93 1112.51 1259.18 1112.51C1259.44 1112.51 1259.71 1112.41 1259.91 1112.21C1260.12 1112.01 1260.21 1111.75 1260.21 1111.48C1260.21 1111.22 1260.12 1110.97 1259.91 1110.76C1259.72 1110.57 1259.45 1110.47 1259.19 1110.47C1258.93 1110.47 1258.67 1110.57 1258.46 1110.76C1258.45 1110.79 1258.4 1110.79 1258.38 1110.76C1258.38 1110.76 1258.37 1110.76 1258.37 1110.76C1258.36 1110.74 1258.36 1110.73 1258.36 1110.7L1258.54 1109.24C1258.54 1109.24 1258.56 1109.19 1258.58 1109.19C1258.59 1109.19 1258.6 1109.18 1258.62 1109.18H1260.29C1260.29 1109.18 1260.35 1109.21 1260.35 1109.24C1260.35 1109.28 1260.32 1109.3 1260.29 1109.3H1258.66L1258.51 1110.56C1258.72 1110.41 1258.96 1110.33 1259.2 1110.33C1259.49 1110.33 1259.79 1110.44 1260.02 1110.67C1260.24 1110.9 1260.35 1111.18 1260.35 1111.48C1260.35 1111.79 1260.24 1112.08 1260.01 1112.3C1259.78 1112.52 1259.49 1112.64 1259.2 1112.64C1258.91 1112.64 1258.62 1112.52 1258.39 1112.3L1258.37 1112.3Z" fill="#E71D36"/>
</g>
<g opacity="0.85">
<path d="M1205.7 1163.2L1208.94 1161.28C1209.08 1161.19 1209.26 1161.21 1209.35 1161.37C1209.42 1161.51 1209.37 1161.67 1209.21 1161.76L1205.97 1163.7C1205.93 1163.72 1205.88 1163.72 1205.83 1163.72C1205.74 1163.72 1205.63 1163.7 1205.58 1163.59C1205.51 1163.48 1205.53 1163.29 1205.7 1163.2H1205.7ZM1208.8 1161.51C1208.8 1161.37 1208.94 1161.2 1209.08 1161.2C1209.27 1161.2 1209.38 1161.37 1209.38 1161.51V1177.07C1209.38 1177.23 1209.27 1177.37 1209.08 1177.37C1208.94 1177.37 1208.8 1177.24 1208.8 1177.07V1161.51Z" fill="#E71D36"/>
<path d="M1221.95 1168.15C1222.99 1169.19 1223.52 1170.58 1223.52 1171.94C1223.52 1173.3 1222.99 1174.71 1221.95 1175.77C1220.89 1176.81 1219.5 1177.34 1218.14 1177.34H1218.12C1216.76 1177.34 1215.37 1176.83 1214.33 1175.77C1214.22 1175.66 1214.22 1175.47 1214.33 1175.36C1214.44 1175.25 1214.63 1175.25 1214.75 1175.36C1215.69 1176.28 1216.89 1176.74 1218.12 1176.74C1219.34 1176.74 1220.59 1176.28 1221.52 1175.34C1222.46 1174.41 1222.93 1173.19 1222.93 1171.94C1222.93 1170.69 1222.46 1169.51 1221.55 1168.56C1220.6 1167.63 1219.4 1167.17 1218.17 1167.17C1218.06 1167.17 1217.97 1167.12 1217.89 1167.03C1217.89 1167 1217.87 1167 1217.87 1167C1217.76 1166.89 1217.76 1166.68 1217.87 1166.56L1222.54 1161.75H1215.4C1215.23 1161.75 1215.1 1161.61 1215.1 1161.45C1215.1 1161.28 1215.23 1161.14 1215.4 1161.14H1223.23C1223.4 1161.14 1223.53 1161.29 1223.53 1161.45C1223.53 1161.49 1223.53 1161.54 1223.51 1161.58C1223.49 1161.63 1223.46 1161.67 1223.44 1161.69L1218.68 1166.57C1219.88 1166.69 1221.06 1167.2 1221.96 1168.12L1221.95 1168.15Z" fill="#E71D36"/>
</g>
<g opacity="0.97">
<path d="M1220.33 1130.35C1221.72 1130.36 1222.76 1131.99 1222.76 1133.9C1222.76 1135.81 1221.72 1137.45 1220.33 1137.46C1218.96 1137.45 1217.92 1135.83 1217.92 1133.9C1217.92 1131.96 1218.96 1130.36 1220.33 1130.35ZM1218.17 1133.9C1218.17 1134.83 1218.44 1135.66 1218.83 1136.25C1219.23 1136.86 1219.77 1137.21 1220.34 1137.21C1220.91 1137.21 1221.45 1136.86 1221.85 1136.25C1222.25 1135.66 1222.51 1134.83 1222.51 1133.9C1222.51 1132.96 1222.25 1132.15 1221.85 1131.57C1221.45 1130.96 1220.92 1130.61 1220.34 1130.61C1219.76 1130.61 1219.23 1130.96 1218.83 1131.57C1218.44 1132.15 1218.17 1132.99 1218.17 1133.9Z" fill="#E71D36"/>
</g>
<g opacity="0.83">
<path d="M1117.05 1070.75L1118.7 1069.77C1118.77 1069.73 1118.87 1069.74 1118.91 1069.82C1118.95 1069.89 1118.93 1069.98 1118.84 1070.02L1117.2 1071.02C1117.2 1071.02 1117.15 1071.03 1117.13 1071.03C1117.08 1071.03 1117.02 1071.02 1117 1070.96C1116.96 1070.9 1116.98 1070.81 1117.06 1070.76L1117.05 1070.75ZM1118.63 1069.89C1118.63 1069.82 1118.71 1069.74 1118.78 1069.74C1118.87 1069.74 1118.93 1069.82 1118.93 1069.89V1077.82C1118.93 1077.9 1118.87 1077.98 1118.78 1077.98C1118.71 1077.98 1118.63 1077.9 1118.63 1077.82V1069.89Z" fill="#E71D36"/>
<path d="M1121 1070.75L1122.65 1069.77C1122.71 1069.73 1122.81 1069.74 1122.86 1069.82C1122.89 1069.89 1122.87 1069.98 1122.78 1070.02L1121.14 1071.02C1121.14 1071.02 1121.09 1071.03 1121.07 1071.03C1121.02 1071.03 1120.96 1071.02 1120.94 1070.96C1120.9 1070.9 1120.92 1070.81 1121 1070.76V1070.75ZM1122.58 1069.89C1122.58 1069.82 1122.65 1069.74 1122.72 1069.74C1122.82 1069.74 1122.87 1069.82 1122.87 1069.89V1077.82C1122.87 1077.9 1122.81 1077.98 1122.72 1077.98C1122.65 1077.98 1122.58 1077.9 1122.58 1077.82V1069.89Z" fill="#E71D36"/>
</g>
<g opacity="0.76">
<path d="M1159.25 1200.33C1159.25 1199.61 1159.4 1198.81 1159.77 1198.18C1160.14 1197.54 1160.75 1197.06 1161.62 1197.03C1161.67 1197.03 1161.72 1197.06 1161.72 1197.12C1161.72 1197.17 1161.68 1197.21 1161.64 1197.21C1160.82 1197.25 1160.3 1197.67 1159.94 1198.27C1159.67 1198.73 1159.52 1199.29 1159.46 1199.84C1159.74 1199.28 1160.32 1198.91 1161 1198.91C1161.95 1198.91 1162.72 1199.67 1162.73 1200.63C1162.72 1201.59 1161.95 1202.36 1161 1202.36C1160.1 1202.36 1159.37 1201.7 1159.28 1200.82C1159.28 1200.82 1159.28 1200.8 1159.28 1200.79C1159.26 1200.64 1159.25 1200.49 1159.25 1200.32V1200.33ZM1161 1202.17C1161.85 1202.17 1162.53 1201.49 1162.54 1200.63C1162.53 1199.79 1161.85 1199.09 1161 1199.09C1160.14 1199.09 1159.46 1199.78 1159.46 1200.63C1159.46 1201.48 1160.15 1202.17 1161 1202.17Z" fill="#E71D36"/>
<path d="M1165.96 1197.03C1167 1197.03 1167.78 1198.25 1167.78 1199.69C1167.78 1201.13 1167 1202.36 1165.96 1202.37C1164.93 1202.36 1164.15 1201.14 1164.15 1199.69C1164.15 1198.24 1164.93 1197.03 1165.96 1197.03ZM1164.33 1199.69C1164.33 1200.39 1164.53 1201.01 1164.83 1201.46C1165.13 1201.91 1165.53 1202.17 1165.96 1202.17C1166.38 1202.17 1166.79 1201.91 1167.09 1201.46C1167.4 1201.01 1167.59 1200.39 1167.59 1199.69C1167.59 1198.99 1167.4 1198.38 1167.09 1197.94C1166.8 1197.48 1166.39 1197.22 1165.96 1197.22C1165.52 1197.22 1165.13 1197.48 1164.83 1197.94C1164.53 1198.38 1164.33 1199 1164.33 1199.69Z" fill="#E71D36"/>
</g>
<g opacity="0.55">
<path d="M1126.12 1106C1126.12 1105 1126.33 1103.89 1126.84 1103C1127.35 1102.11 1128.2 1101.44 1129.42 1101.39C1129.49 1101.39 1129.55 1101.44 1129.55 1101.53C1129.55 1101.59 1129.5 1101.66 1129.43 1101.66C1128.3 1101.71 1127.56 1102.29 1127.06 1103.12C1126.68 1103.77 1126.48 1104.54 1126.4 1105.31C1126.79 1104.54 1127.61 1104.02 1128.54 1104.02C1129.87 1104.02 1130.95 1105.09 1130.96 1106.42C1130.95 1107.76 1129.86 1108.84 1128.54 1108.84C1127.3 1108.84 1126.28 1107.91 1126.15 1106.7C1126.14 1106.69 1126.14 1106.67 1126.14 1106.67C1126.12 1106.45 1126.11 1106.23 1126.11 1106H1126.12ZM1128.55 1108.57C1129.74 1108.57 1130.69 1107.62 1130.71 1106.43C1130.69 1105.25 1129.74 1104.29 1128.55 1104.29C1127.37 1104.29 1126.42 1105.25 1126.42 1106.43C1126.42 1107.61 1127.37 1108.57 1128.55 1108.57Z" fill="#E71D36"/>
<path d="M1132.93 1106C1132.93 1105 1133.15 1103.89 1133.66 1103C1134.17 1102.11 1135.02 1101.44 1136.23 1101.39C1136.31 1101.39 1136.38 1101.44 1136.38 1101.53C1136.38 1101.59 1136.32 1101.66 1136.26 1101.66C1135.12 1101.71 1134.39 1102.29 1133.89 1103.12C1133.51 1103.77 1133.3 1104.54 1133.23 1105.31C1133.62 1104.54 1134.43 1104.02 1135.36 1104.02C1136.69 1104.02 1137.77 1105.09 1137.78 1106.42C1137.77 1107.76 1136.68 1108.84 1135.36 1108.84C1134.12 1108.84 1133.1 1107.91 1132.97 1106.7C1132.96 1106.69 1132.96 1106.67 1132.96 1106.67C1132.94 1106.45 1132.93 1106.23 1132.93 1106ZM1135.37 1108.57C1136.56 1108.57 1137.5 1107.62 1137.53 1106.43C1137.5 1105.25 1136.56 1104.29 1135.37 1104.29C1134.18 1104.29 1133.24 1105.25 1133.24 1106.43C1133.24 1107.61 1134.19 1108.57 1135.37 1108.57Z" fill="#E71D36"/>
</g>
<g opacity="0.58">
<path d="M1156.46 1158.72L1159.51 1151.46H1155.42C1155.35 1151.46 1155.28 1151.39 1155.28 1151.31C1155.28 1151.25 1155.35 1151.17 1155.42 1151.17H1159.74C1159.81 1151.17 1159.88 1151.25 1159.88 1151.31C1159.88 1151.34 1159.86 1151.38 1159.84 1151.41L1156.71 1158.84C1156.7 1158.88 1156.65 1158.93 1156.59 1158.93C1156.58 1158.93 1156.56 1158.91 1156.54 1158.91C1156.46 1158.88 1156.44 1158.8 1156.46 1158.72H1156.46Z" fill="#E71D36"/>
</g>
<g opacity="0.6">
<path d="M1095.34 1157.5C1096.17 1157.5 1096.86 1158.18 1096.86 1159.03C1096.86 1159.64 1096.5 1160.17 1095.99 1160.41C1097.03 1160.69 1097.8 1161.64 1097.8 1162.77C1097.8 1164.12 1096.7 1165.22 1095.34 1165.22C1093.99 1165.22 1092.89 1164.13 1092.89 1162.77C1092.89 1161.64 1093.66 1160.69 1094.7 1160.41C1094.16 1160.17 1093.81 1159.63 1093.81 1159.03C1093.81 1158.18 1094.49 1157.5 1095.34 1157.5H1095.34ZM1095.34 1164.95C1096.55 1164.95 1097.51 1163.98 1097.51 1162.78C1097.51 1161.57 1096.55 1160.59 1095.34 1160.59C1094.13 1160.59 1093.16 1161.57 1093.16 1162.78C1093.16 1163.98 1094.13 1164.95 1095.34 1164.95ZM1095.34 1160.28C1096.02 1160.28 1096.58 1159.72 1096.58 1159.03C1096.58 1158.35 1096.02 1157.78 1095.34 1157.78C1094.64 1157.78 1094.08 1158.34 1094.08 1159.03C1094.08 1159.72 1094.64 1160.28 1095.34 1160.28Z" fill="#5EB0EF"/>
<path d="M1099.63 1158.45L1101.17 1157.53C1101.24 1157.49 1101.33 1157.5 1101.37 1157.58C1101.41 1157.65 1101.38 1157.72 1101.3 1157.77L1099.76 1158.69C1099.76 1158.69 1099.72 1158.7 1099.69 1158.7C1099.65 1158.7 1099.6 1158.69 1099.57 1158.63C1099.54 1158.58 1099.55 1158.49 1099.63 1158.44L1099.63 1158.45ZM1101.11 1157.65C1101.11 1157.58 1101.18 1157.5 1101.25 1157.5C1101.34 1157.5 1101.39 1157.58 1101.39 1157.65V1165.08C1101.39 1165.15 1101.34 1165.22 1101.25 1165.22C1101.18 1165.22 1101.11 1165.15 1101.11 1165.08V1157.65Z" fill="#5EB0EF"/>
</g>
<g opacity="0.75">
<path d="M1083.05 1317.16L1084.14 1316.52C1084.18 1316.49 1084.25 1316.49 1084.28 1316.55C1084.3 1316.59 1084.29 1316.65 1084.24 1316.68L1083.15 1317.34C1083.15 1317.34 1083.12 1317.34 1083.1 1317.34C1083.07 1317.34 1083.03 1317.34 1083.02 1317.3C1083 1317.26 1083 1317.2 1083.06 1317.16H1083.05ZM1084.09 1316.59C1084.09 1316.55 1084.14 1316.49 1084.18 1316.49C1084.24 1316.49 1084.29 1316.55 1084.29 1316.59V1321.83C1084.29 1321.89 1084.25 1321.93 1084.18 1321.93C1084.14 1321.93 1084.09 1321.89 1084.09 1321.83V1316.59Z" fill="#E71D36"/>
<path d="M1085.86 1321.41C1085.82 1321.38 1085.82 1321.31 1085.86 1321.26C1085.9 1321.22 1085.96 1321.22 1086 1321.26C1086.32 1321.57 1086.73 1321.74 1087.14 1321.74C1087.54 1321.74 1087.97 1321.57 1088.28 1321.26C1088.59 1320.95 1088.75 1320.53 1088.75 1320.12C1088.75 1319.71 1088.59 1319.29 1088.28 1318.98C1087.97 1318.68 1087.56 1318.52 1087.14 1318.52C1086.72 1318.52 1086.32 1318.68 1086 1318.98C1085.98 1319.02 1085.91 1319.02 1085.87 1318.99C1085.86 1318.99 1085.85 1318.98 1085.85 1318.98C1085.84 1318.98 1085.84 1318.97 1085.84 1318.97C1085.83 1318.95 1085.82 1318.92 1085.83 1318.89L1086.1 1316.59C1086.11 1316.55 1086.14 1316.51 1086.17 1316.5C1086.18 1316.5 1086.2 1316.49 1086.22 1316.49H1088.85C1088.9 1316.49 1088.95 1316.54 1088.95 1316.59C1088.95 1316.65 1088.9 1316.69 1088.85 1316.69H1086.29L1086.05 1318.67C1086.38 1318.43 1086.75 1318.31 1087.14 1318.31C1087.6 1318.31 1088.07 1318.48 1088.43 1318.83C1088.77 1319.19 1088.95 1319.65 1088.95 1320.11C1088.95 1320.58 1088.77 1321.04 1088.41 1321.4C1088.06 1321.76 1087.59 1321.94 1087.12 1321.94C1086.66 1321.94 1086.2 1321.76 1085.84 1321.4L1085.86 1321.41Z" fill="#E71D36"/>
</g>
<g opacity="0.75">
<path d="M1077.21 1289.04C1077.92 1289.74 1078.28 1290.69 1078.28 1291.62C1078.28 1292.54 1077.92 1293.5 1077.21 1294.23C1076.49 1294.93 1075.54 1295.29 1074.62 1295.29H1074.6C1073.67 1295.29 1072.73 1294.95 1072.02 1294.23C1071.94 1294.15 1071.94 1294.02 1072.02 1293.95C1072.1 1293.86 1072.23 1293.86 1072.31 1293.95C1072.96 1294.58 1073.77 1294.89 1074.61 1294.89C1075.44 1294.89 1076.29 1294.57 1076.92 1293.92C1077.57 1293.29 1077.89 1292.46 1077.89 1291.61C1077.89 1290.76 1077.57 1289.95 1076.95 1289.31C1076.3 1288.67 1075.48 1288.36 1074.65 1288.36C1074.57 1288.36 1074.5 1288.33 1074.46 1288.27C1074.46 1288.25 1074.44 1288.25 1074.44 1288.25C1074.36 1288.17 1074.36 1288.04 1074.44 1287.95L1077.62 1284.68H1072.76C1072.65 1284.68 1072.56 1284.58 1072.56 1284.48C1072.56 1284.37 1072.65 1284.28 1072.76 1284.28H1078.1C1078.21 1284.28 1078.3 1284.37 1078.3 1284.48C1078.3 1284.51 1078.3 1284.54 1078.28 1284.58C1078.27 1284.61 1078.25 1284.64 1078.24 1284.65L1075 1287.98C1075.82 1288.05 1076.62 1288.4 1077.23 1289.03L1077.21 1289.04Z" fill="#5EB0EF"/>
</g>
<g opacity="0.51">
<path d="M1102.3 1276.42C1102.23 1276.36 1102.23 1276.21 1102.3 1276.14C1102.38 1276.06 1102.51 1276.06 1102.59 1276.14C1103.23 1276.77 1104.06 1277.09 1104.88 1277.09C1105.7 1277.09 1106.56 1276.76 1107.19 1276.14C1107.83 1275.49 1108.14 1274.66 1108.14 1273.83C1108.14 1272.99 1107.83 1272.17 1107.19 1271.54C1106.56 1270.91 1105.74 1270.6 1104.9 1270.6C1104.05 1270.6 1103.25 1270.91 1102.59 1271.54C1102.54 1271.62 1102.4 1271.62 1102.32 1271.55C1102.3 1271.55 1102.29 1271.54 1102.29 1271.52C1102.27 1271.52 1102.27 1271.51 1102.27 1271.51C1102.24 1271.46 1102.23 1271.41 1102.24 1271.35L1102.79 1266.71C1102.81 1266.62 1102.87 1266.56 1102.93 1266.54C1102.96 1266.53 1102.99 1266.51 1103.05 1266.51H1108.35C1108.46 1266.51 1108.55 1266.62 1108.55 1266.71C1108.55 1266.84 1108.46 1266.92 1108.35 1266.92H1103.19L1102.72 1270.9C1103.35 1270.41 1104.13 1270.18 1104.9 1270.18C1105.83 1270.18 1106.78 1270.53 1107.5 1271.25C1108.19 1271.96 1108.55 1272.88 1108.55 1273.82C1108.55 1274.76 1108.19 1275.71 1107.47 1276.41C1106.76 1277.13 1105.82 1277.49 1104.88 1277.49C1103.94 1277.49 1103.03 1277.13 1102.31 1276.41L1102.3 1276.42Z" fill="#E71D36"/>
<path d="M1111.78 1273.31C1111.78 1271.84 1112.09 1270.19 1112.85 1268.88C1113.6 1267.56 1114.85 1266.59 1116.66 1266.51C1116.77 1266.51 1116.86 1266.59 1116.86 1266.71C1116.86 1266.81 1116.78 1266.9 1116.69 1266.9C1115.01 1266.98 1113.93 1267.84 1113.19 1269.07C1112.63 1270.02 1112.33 1271.17 1112.22 1272.3C1112.8 1271.16 1114 1270.38 1115.38 1270.38C1117.34 1270.38 1118.94 1271.97 1118.96 1273.93C1118.94 1275.91 1117.34 1277.5 1115.38 1277.5C1113.55 1277.5 1112.03 1276.13 1111.85 1274.35C1111.83 1274.33 1111.83 1274.3 1111.83 1274.29C1111.8 1273.97 1111.79 1273.64 1111.79 1273.32L1111.78 1273.31ZM1115.37 1277.11C1117.13 1277.11 1118.52 1275.69 1118.56 1273.93C1118.53 1272.19 1117.13 1270.78 1115.37 1270.78C1113.61 1270.78 1112.22 1272.2 1112.22 1273.93C1112.22 1275.67 1113.63 1277.11 1115.37 1277.11Z" fill="#E71D36"/>
</g>
<g opacity="0.86">
<path d="M1404.01 910.153H1394.32C1394.24 910.153 1394.2 910.13 1394.13 910.077C1394.01 909.957 1393.98 909.792 1394.08 909.671L1404.08 897.224L1404.12 897.179C1404.2 897.134 1404.24 897.112 1404.31 897.112C1404.4 897.112 1404.5 897.179 1404.54 897.255C1404.57 897.277 1404.57 897.3 1404.59 897.33C1404.59 897.352 1404.61 897.375 1404.61 897.397V909.566H1406.05C1406.24 909.566 1406.36 909.686 1406.36 909.852C1406.36 910.04 1406.24 910.16 1406.05 910.16H1404.61V913.387C1404.61 913.552 1404.49 913.695 1404.3 913.695C1404.16 913.695 1404 913.552 1404 913.387V910.16L1404.01 910.153ZM1404.01 909.559V898.322L1394.93 909.559H1404.01Z" fill="#5EB0EF"/>
<path d="M1410.15 912.048C1410.03 911.95 1410.03 911.74 1410.15 911.619C1410.27 911.499 1410.46 911.499 1410.58 911.619C1411.55 912.567 1412.8 913.063 1414.04 913.063C1415.27 913.063 1416.57 912.567 1417.52 911.619C1418.48 910.649 1418.96 909.393 1418.96 908.137C1418.96 906.881 1418.48 905.625 1417.52 904.678C1416.57 903.73 1415.34 903.256 1414.06 903.256C1412.78 903.256 1411.57 903.73 1410.58 904.678C1410.5 904.798 1410.29 904.798 1410.17 904.7C1410.15 904.7 1410.13 904.678 1410.13 904.655C1410.11 904.655 1410.11 904.632 1410.11 904.632C1410.06 904.565 1410.03 904.49 1410.06 904.399L1410.89 897.405C1410.91 897.262 1411.01 897.172 1411.1 897.142C1411.15 897.119 1411.19 897.097 1411.27 897.097H1419.28C1419.45 897.097 1419.59 897.262 1419.59 897.405C1419.59 897.593 1419.45 897.713 1419.28 897.713H1411.49L1410.77 903.737C1411.74 903 1412.91 902.647 1414.07 902.647C1415.49 902.647 1416.91 903.166 1418 904.256C1419.04 905.324 1419.58 906.723 1419.58 908.145C1419.58 909.566 1419.04 910.987 1417.94 912.055C1416.88 913.146 1415.46 913.695 1414.04 913.695C1412.62 913.695 1411.24 913.153 1410.15 912.055V912.048Z" fill="#5EB0EF"/>
</g>
<g opacity="0.54">
<path d="M1309.78 962.4C1312.12 964.746 1313.32 967.875 1313.32 970.958C1313.32 974.042 1312.12 977.216 1309.78 979.615C1307.38 981.961 1304.25 983.165 1301.18 983.165H1301.13C1298.05 983.165 1294.93 982.022 1292.58 979.615C1292.32 979.352 1292.32 978.938 1292.58 978.675C1292.84 978.412 1293.26 978.412 1293.52 978.675C1295.66 980.758 1298.37 981.803 1301.13 981.803C1303.9 981.803 1306.71 980.758 1308.8 978.622C1310.93 976.531 1311.98 973.771 1311.98 970.951C1311.98 968.131 1310.93 965.476 1308.85 963.332C1306.72 961.242 1304 960.204 1301.24 960.204C1300.98 960.204 1300.77 960.098 1300.62 959.895C1300.62 959.843 1300.56 959.843 1300.56 959.843C1300.3 959.579 1300.3 959.113 1300.56 958.85L1311.09 947.997H1294.99C1294.62 947.997 1294.31 947.682 1294.31 947.321C1294.31 946.96 1294.63 946.644 1294.99 946.644H1312.65C1313.02 946.644 1313.33 946.96 1313.33 947.321C1313.33 947.426 1313.33 947.531 1313.28 947.636C1313.23 947.742 1313.17 947.847 1313.12 947.9L1302.38 958.91C1305.09 959.173 1307.75 960.317 1309.78 962.407L1309.78 962.4Z" fill="#E71D36"/>
<path d="M1328.03 982.277L1342.36 948.11H1323.13C1322.76 948.11 1322.45 947.794 1322.45 947.433C1322.45 947.117 1322.76 946.756 1323.13 946.756H1343.46C1343.77 946.756 1344.14 947.125 1344.14 947.433C1344.14 947.591 1344.03 947.742 1343.93 947.9L1329.23 982.796C1329.17 983.007 1328.91 983.21 1328.65 983.21C1328.6 983.21 1328.5 983.157 1328.39 983.157C1328.02 982.999 1327.93 982.638 1328.02 982.27L1328.03 982.277Z" fill="#E71D36"/>
</g>
<g opacity="0.56">
<path d="M1421.11 1057.55H1418.95C1418.95 1057.55 1418.93 1057.55 1418.91 1057.54C1418.88 1057.51 1418.88 1057.48 1418.9 1057.45L1421.13 1054.66H1421.14C1421.14 1054.66 1421.16 1054.64 1421.18 1054.64C1421.21 1054.64 1421.23 1054.66 1421.24 1054.67V1054.69C1421.24 1054.7 1421.25 1054.7 1421.25 1054.71V1057.43H1421.57C1421.62 1057.43 1421.64 1057.45 1421.64 1057.49C1421.64 1057.53 1421.62 1057.55 1421.57 1057.55H1421.25V1058.27C1421.25 1058.27 1421.22 1058.34 1421.18 1058.34C1421.15 1058.34 1421.11 1058.31 1421.11 1058.27V1057.55ZM1421.11 1057.42V1054.91L1419.09 1057.42H1421.11Z" fill="#E71D36"/>
<path d="M1422.48 1057.97C1422.48 1057.97 1422.46 1057.91 1422.48 1057.88C1422.51 1057.85 1422.55 1057.85 1422.58 1057.88C1422.8 1058.09 1423.08 1058.2 1423.35 1058.2C1423.63 1058.2 1423.92 1058.09 1424.13 1057.88C1424.35 1057.66 1424.45 1057.38 1424.45 1057.1C1424.45 1056.82 1424.35 1056.54 1424.13 1056.33C1423.92 1056.12 1423.64 1056.01 1423.35 1056.01C1423.07 1056.01 1422.8 1056.12 1422.58 1056.33C1422.57 1056.35 1422.52 1056.35 1422.49 1056.33C1422.49 1056.33 1422.48 1056.33 1422.48 1056.32C1422.48 1056.3 1422.47 1056.29 1422.48 1056.27L1422.66 1054.7C1422.66 1054.7 1422.69 1054.65 1422.71 1054.64C1422.71 1054.64 1422.73 1054.63 1422.75 1054.63H1424.53C1424.53 1054.63 1424.6 1054.67 1424.6 1054.7C1424.6 1054.75 1424.57 1054.77 1424.53 1054.77H1422.79L1422.63 1056.12C1422.85 1055.95 1423.11 1055.88 1423.37 1055.88C1423.68 1055.88 1424.01 1056 1424.25 1056.24C1424.48 1056.48 1424.6 1056.79 1424.6 1057.1C1424.6 1057.42 1424.48 1057.73 1424.23 1057.97C1423.99 1058.21 1423.68 1058.34 1423.36 1058.34C1423.05 1058.34 1422.74 1058.22 1422.49 1057.97H1422.48Z" fill="#E71D36"/>
</g>
<g opacity="0.98">
<path d="M1313.74 971.974L1316.55 965.273H1312.78C1312.71 965.273 1312.65 965.213 1312.65 965.137C1312.65 965.077 1312.71 965.002 1312.78 965.002H1316.77C1316.83 965.002 1316.91 965.077 1316.91 965.137C1316.91 965.167 1316.89 965.197 1316.87 965.228L1313.98 972.071C1313.98 972.117 1313.92 972.154 1313.87 972.154C1313.86 972.154 1313.84 972.147 1313.82 972.147C1313.74 972.117 1313.73 972.041 1313.74 971.974Z" fill="#E71D36"/>
<path d="M1318.45 969.424C1318.45 968.461 1318.65 967.386 1319.14 966.544C1319.63 965.686 1320.45 965.047 1321.63 965.002C1321.7 965.002 1321.76 965.055 1321.76 965.137C1321.76 965.197 1321.71 965.258 1321.65 965.258C1320.55 965.31 1319.85 965.874 1319.37 966.672C1319.01 967.296 1318.81 968.04 1318.74 968.777C1319.12 968.033 1319.9 967.529 1320.79 967.529C1322.07 967.529 1323.12 968.559 1323.12 969.838C1323.12 971.124 1322.07 972.162 1320.79 972.162C1319.6 972.162 1318.61 971.274 1318.49 970.109C1318.49 970.101 1318.49 970.078 1318.49 970.063C1318.46 969.86 1318.46 969.642 1318.46 969.432L1318.45 969.424ZM1320.79 971.899C1321.93 971.899 1322.84 970.981 1322.86 969.83C1322.84 968.695 1321.93 967.777 1320.79 967.777C1319.64 967.777 1318.73 968.695 1318.73 969.83C1318.73 970.966 1319.66 971.899 1320.79 971.899Z" fill="#E71D36"/>
</g>
<g opacity="0.61">
<path d="M1322 1111.51C1322 1109.05 1322.53 1106.29 1323.79 1104.11C1325.05 1101.9 1327.15 1100.27 1330.17 1100.14C1330.35 1100.14 1330.51 1100.26 1330.51 1100.47C1330.51 1100.63 1330.38 1100.79 1330.23 1100.79C1327.41 1100.92 1325.6 1102.36 1324.37 1104.42C1323.42 1106.02 1322.93 1107.93 1322.74 1109.83C1323.71 1107.91 1325.71 1106.63 1328.02 1106.63C1331.3 1106.63 1333.98 1109.28 1334.01 1112.57C1333.98 1115.88 1331.3 1118.53 1328.02 1118.53C1324.94 1118.53 1322.42 1116.25 1322.11 1113.25C1322.09 1113.23 1322.09 1113.17 1322.09 1113.15C1322.03 1112.62 1322 1112.07 1322 1111.51ZM1328.02 1117.88C1330.95 1117.88 1333.29 1115.52 1333.34 1112.57C1333.29 1109.65 1330.95 1107.29 1328.02 1107.29C1325.08 1107.29 1322.74 1109.66 1322.74 1112.57C1322.74 1115.48 1325.11 1117.88 1328.02 1117.88Z" fill="#E71D36"/>
<path d="M1337.65 1102.42L1341.33 1100.24C1341.48 1100.14 1341.69 1100.16 1341.8 1100.35C1341.88 1100.5 1341.83 1100.68 1341.64 1100.8L1337.97 1103C1337.91 1103.02 1337.86 1103.02 1337.81 1103.02C1337.7 1103.02 1337.58 1103 1337.52 1102.87C1337.44 1102.74 1337.47 1102.53 1337.65 1102.42ZM1341.17 1100.5C1341.17 1100.35 1341.33 1100.17 1341.48 1100.17C1341.69 1100.17 1341.82 1100.35 1341.82 1100.5V1118.19C1341.82 1118.37 1341.69 1118.53 1341.48 1118.53C1341.33 1118.53 1341.17 1118.37 1341.17 1118.19V1100.5Z" fill="#E71D36"/>
</g>
<g opacity="0.92">
<path d="M1370.22 954.932H1362.5C1362.44 954.932 1362.41 954.909 1362.35 954.871C1362.26 954.781 1362.24 954.646 1362.32 954.548L1370.28 944.636L1370.32 944.598C1370.38 944.56 1370.42 944.538 1370.47 944.538C1370.54 944.538 1370.62 944.598 1370.66 944.651C1370.67 944.673 1370.67 944.688 1370.7 944.711C1370.7 944.733 1370.71 944.748 1370.71 944.763V954.45H1371.86C1372.01 954.45 1372.1 954.548 1372.1 954.676C1372.1 954.826 1372.01 954.924 1371.86 954.924H1370.71V957.489C1370.71 957.624 1370.61 957.737 1370.46 957.737C1370.35 957.737 1370.22 957.624 1370.22 957.489V954.924V954.932ZM1370.22 954.458V945.508L1362.99 954.458H1370.22Z" fill="#5EB0EF"/>
<path d="M1381.55 950.216C1382.4 951.066 1382.83 952.201 1382.83 953.315C1382.83 954.428 1382.4 955.578 1381.55 956.451C1380.68 957.301 1379.55 957.737 1378.44 957.737H1378.41C1377.3 957.737 1376.17 957.323 1375.32 956.451C1375.22 956.361 1375.22 956.21 1375.32 956.112C1375.41 956.022 1375.56 956.022 1375.66 956.112C1376.43 956.864 1377.41 957.248 1378.41 957.248C1379.42 957.248 1380.43 956.872 1381.19 956.097C1381.96 955.345 1382.34 954.337 1382.34 953.322C1382.34 952.307 1381.96 951.337 1381.2 950.562C1380.43 949.81 1379.45 949.426 1378.44 949.426C1378.35 949.426 1378.28 949.389 1378.22 949.314C1378.22 949.298 1378.2 949.298 1378.2 949.298C1378.11 949.201 1378.11 949.035 1378.2 948.937L1382.01 945.012H1376.18C1376.05 945.012 1375.93 944.899 1375.93 944.763C1375.93 944.628 1376.05 944.515 1376.18 944.515H1382.58C1382.7 944.515 1382.82 944.628 1382.82 944.763C1382.82 944.801 1382.82 944.839 1382.81 944.876C1382.79 944.914 1382.77 944.951 1382.75 944.974L1378.86 948.96C1379.85 949.05 1380.81 949.471 1381.55 950.224V950.216Z" fill="#5EB0EF"/>
</g>
<g opacity="0.65">
<path d="M1320.07 1087.55C1320.91 1087.55 1321.61 1088.24 1321.61 1089.1C1321.61 1089.7 1321.24 1090.24 1320.73 1090.48C1321.77 1090.76 1322.54 1091.72 1322.54 1092.86C1322.54 1094.22 1321.43 1095.31 1320.07 1095.31C1318.71 1095.31 1317.6 1094.22 1317.6 1092.86C1317.6 1091.72 1318.37 1090.77 1319.42 1090.48C1318.88 1090.24 1318.53 1089.7 1318.53 1089.1C1318.53 1088.24 1319.22 1087.55 1320.07 1087.55ZM1320.07 1095.04C1321.29 1095.04 1322.27 1094.07 1322.27 1092.86C1322.27 1091.66 1321.29 1090.67 1320.07 1090.67C1318.85 1090.67 1317.88 1091.65 1317.88 1092.86C1317.88 1094.07 1318.86 1095.04 1320.07 1095.04ZM1320.07 1090.35C1320.76 1090.35 1321.33 1089.79 1321.33 1089.1C1321.33 1088.4 1320.76 1087.84 1320.07 1087.83C1319.37 1087.85 1318.81 1088.4 1318.81 1089.1C1318.81 1089.79 1319.37 1090.35 1320.07 1090.35Z" fill="#E71D36"/>
<path d="M1328.47 1090.89C1328.98 1091.39 1329.23 1092.06 1329.23 1092.71C1329.23 1093.37 1328.97 1094.04 1328.47 1094.56C1327.96 1095.05 1327.3 1095.31 1326.65 1095.31H1326.64C1325.99 1095.31 1325.32 1095.07 1324.82 1094.56C1324.77 1094.5 1324.77 1094.41 1324.82 1094.36C1324.87 1094.31 1324.96 1094.31 1325.02 1094.36C1325.47 1094.8 1326.05 1095.02 1326.63 1095.02C1327.22 1095.02 1327.82 1094.8 1328.26 1094.34C1328.71 1093.9 1328.94 1093.31 1328.94 1092.71C1328.94 1092.11 1328.71 1091.55 1328.28 1091.1C1327.82 1090.65 1327.25 1090.43 1326.66 1090.43C1326.61 1090.43 1326.57 1090.4 1326.53 1090.36C1326.53 1090.35 1326.52 1090.35 1326.52 1090.35C1326.47 1090.3 1326.47 1090.19 1326.52 1090.14L1328.76 1087.83H1325.33C1325.25 1087.83 1325.19 1087.76 1325.19 1087.69C1325.19 1087.61 1325.26 1087.55 1325.33 1087.55H1329.09C1329.17 1087.55 1329.23 1087.61 1329.23 1087.69C1329.23 1087.71 1329.23 1087.73 1329.22 1087.76C1329.21 1087.78 1329.2 1087.8 1329.2 1087.8L1326.9 1090.16C1327.48 1090.21 1328.05 1090.46 1328.47 1090.9V1090.89Z" fill="#E71D36"/>
</g>
<g opacity="0.93">
<path d="M1241.67 1097.27L1247.39 1083.64H1239.71C1239.57 1083.64 1239.44 1083.51 1239.44 1083.36C1239.44 1083.24 1239.57 1083.09 1239.71 1083.09H1247.82C1247.95 1083.09 1248.09 1083.24 1248.09 1083.36C1248.09 1083.42 1248.05 1083.49 1248.01 1083.55L1242.15 1097.47C1242.12 1097.56 1242.02 1097.64 1241.92 1097.64C1241.9 1097.64 1241.86 1097.62 1241.82 1097.62C1241.67 1097.56 1241.63 1097.41 1241.67 1097.26L1241.67 1097.27Z" fill="#E71D36"/>
<path d="M1251.02 1097.17L1258.05 1090.82C1258.94 1089.98 1259.42 1088.86 1259.42 1087.74C1259.42 1086.76 1259.05 1085.76 1258.34 1084.97C1257.53 1084.08 1256.39 1083.6 1255.28 1083.6C1254.28 1083.6 1253.28 1083.97 1252.5 1084.68L1251.77 1085.34C1251.64 1085.45 1251.5 1085.45 1251.39 1085.34C1251.29 1085.24 1251.31 1085.05 1251.41 1084.97L1252.14 1084.28C1253.06 1083.47 1254.16 1083.07 1255.28 1083.07C1256.55 1083.07 1257.82 1083.59 1258.74 1084.59C1259.53 1085.49 1259.94 1086.63 1259.94 1087.73C1259.94 1089.01 1259.42 1090.27 1258.38 1091.19L1251.85 1097.12H1259.69C1259.82 1097.12 1259.94 1097.23 1259.94 1097.37C1259.94 1097.51 1259.81 1097.64 1259.69 1097.64H1251.2C1251.12 1097.64 1251.04 1097.62 1250.99 1097.53C1250.91 1097.43 1250.91 1097.26 1251.01 1097.16L1251.02 1097.17Z" fill="#E71D36"/>
</g>
<g opacity="0.66">
<path d="M1187.56 1163.8L1191.98 1159.81C1192.55 1159.28 1192.85 1158.57 1192.85 1157.87C1192.85 1157.25 1192.61 1156.62 1192.17 1156.13C1191.66 1155.56 1190.94 1155.26 1190.25 1155.26C1189.62 1155.26 1188.99 1155.5 1188.49 1155.95L1188.04 1156.37C1187.95 1156.44 1187.86 1156.44 1187.8 1156.37C1187.74 1156.3 1187.75 1156.19 1187.82 1156.14L1188.28 1155.7C1188.86 1155.19 1189.55 1154.94 1190.25 1154.94C1191.05 1154.94 1191.85 1155.27 1192.43 1155.9C1192.92 1156.46 1193.18 1157.18 1193.18 1157.87C1193.18 1158.67 1192.85 1159.47 1192.21 1160.05L1188.1 1163.78H1193.03C1193.11 1163.78 1193.18 1163.84 1193.18 1163.93C1193.18 1164.03 1193.11 1164.11 1193.03 1164.11H1187.69C1187.64 1164.11 1187.59 1164.09 1187.56 1164.04C1187.51 1163.97 1187.51 1163.87 1187.58 1163.81L1187.56 1163.8Z" fill="#E71D36"/>
<path d="M1195.28 1163.8L1199.7 1159.81C1200.26 1159.28 1200.56 1158.57 1200.56 1157.87C1200.56 1157.25 1200.33 1156.62 1199.88 1156.13C1199.37 1155.56 1198.65 1155.26 1197.96 1155.26C1197.33 1155.26 1196.7 1155.5 1196.21 1155.95L1195.75 1156.37C1195.67 1156.44 1195.57 1156.44 1195.51 1156.37C1195.45 1156.3 1195.46 1156.19 1195.53 1156.14L1195.99 1155.7C1196.57 1155.19 1197.26 1154.94 1197.96 1154.94C1198.76 1154.94 1199.56 1155.27 1200.14 1155.9C1200.63 1156.46 1200.9 1157.18 1200.9 1157.87C1200.9 1158.67 1200.56 1159.47 1199.91 1160.05L1195.81 1163.78H1200.74C1200.82 1163.78 1200.9 1163.84 1200.9 1163.93C1200.9 1164.03 1200.82 1164.11 1200.74 1164.11H1195.4C1195.35 1164.11 1195.3 1164.09 1195.27 1164.04C1195.22 1163.97 1195.22 1163.87 1195.29 1163.81L1195.28 1163.8Z" fill="#E71D36"/>
</g>
<g opacity="0.88">
<path d="M1275.28 1144.82H1273.01C1273.01 1144.82 1272.98 1144.82 1272.97 1144.81C1272.94 1144.78 1272.93 1144.74 1272.96 1144.71L1275.3 1141.8V1141.79C1275.3 1141.79 1275.33 1141.77 1275.35 1141.77C1275.37 1141.77 1275.39 1141.79 1275.4 1141.81C1275.4 1141.81 1275.4 1141.82 1275.41 1141.82C1275.41 1141.82 1275.41 1141.83 1275.41 1141.84V1144.68H1275.75C1275.79 1144.68 1275.82 1144.71 1275.82 1144.75C1275.82 1144.79 1275.79 1144.82 1275.75 1144.82H1275.41V1145.57C1275.41 1145.61 1275.38 1145.64 1275.33 1145.64C1275.3 1145.64 1275.26 1145.61 1275.26 1145.57V1144.82L1275.28 1144.82ZM1275.28 1144.69V1142.06L1273.16 1144.69H1275.28Z" fill="#E71D36"/>
<path d="M1277.93 1141.77C1278.58 1141.77 1279.11 1142.27 1279.17 1142.92C1279.19 1143.03 1279.2 1143.14 1279.2 1143.25C1279.2 1143.77 1279.08 1144.35 1278.82 1144.82C1278.54 1145.28 1278.11 1145.63 1277.48 1145.65C1277.43 1145.65 1277.4 1145.62 1277.4 1145.58C1277.4 1145.55 1277.43 1145.52 1277.46 1145.52C1278.05 1145.49 1278.44 1145.18 1278.69 1144.75C1278.88 1144.42 1279 1144 1279.04 1143.61C1278.84 1144.01 1278.41 1144.28 1277.93 1144.28C1277.24 1144.28 1276.67 1143.73 1276.67 1143.03C1276.67 1142.33 1277.24 1141.77 1277.93 1141.77H1277.93ZM1277.93 1144.15C1278.54 1144.15 1279.04 1143.65 1279.04 1143.03C1279.04 1143 1279.04 1142.98 1279.04 1142.95V1142.93C1278.99 1142.35 1278.5 1141.91 1277.93 1141.91C1277.31 1141.91 1276.81 1142.41 1276.81 1143.03C1276.81 1143.66 1277.31 1144.15 1277.93 1144.15H1277.93Z" fill="#E71D36"/>
</g>
<g opacity="0.59">
<path d="M1302.43 1222.8L1304.67 1217.46H1301.66C1301.6 1217.46 1301.55 1217.42 1301.55 1217.35C1301.55 1217.31 1301.6 1217.25 1301.66 1217.25H1304.84C1304.89 1217.25 1304.94 1217.31 1304.94 1217.35C1304.94 1217.38 1304.93 1217.4 1304.91 1217.43L1302.61 1222.88C1302.61 1222.88 1302.56 1222.95 1302.52 1222.95C1302.52 1222.95 1302.5 1222.94 1302.48 1222.94C1302.43 1222.92 1302.4 1222.86 1302.43 1222.8Z" fill="#E71D36"/>
<path d="M1308.95 1221.73H1305.62C1305.62 1221.73 1305.58 1221.72 1305.55 1221.71C1305.52 1221.67 1305.51 1221.61 1305.54 1221.57L1308.97 1217.29L1308.99 1217.28C1308.99 1217.28 1309.03 1217.26 1309.05 1217.26C1309.08 1217.26 1309.12 1217.28 1309.13 1217.3C1309.14 1217.31 1309.14 1217.32 1309.15 1217.32C1309.15 1217.32 1309.15 1217.34 1309.15 1217.35V1221.53H1309.65C1309.72 1221.53 1309.75 1221.57 1309.75 1221.63C1309.75 1221.69 1309.72 1221.73 1309.65 1221.73H1309.15V1222.84C1309.15 1222.9 1309.12 1222.94 1309.05 1222.94C1309 1222.94 1308.94 1222.9 1308.94 1222.84V1221.73H1308.95ZM1308.95 1221.53V1217.66L1305.83 1221.53H1308.95Z" fill="#E71D36"/>
</g>
<g opacity="0.97">
<path d="M1274.02 1072.79C1274.02 1071.4 1274.31 1069.83 1275.03 1068.61C1275.74 1067.36 1276.93 1066.44 1278.63 1066.37C1278.74 1066.37 1278.83 1066.44 1278.83 1066.56C1278.83 1066.65 1278.75 1066.74 1278.66 1066.74C1277.08 1066.82 1276.06 1067.63 1275.36 1068.79C1274.82 1069.69 1274.54 1070.77 1274.44 1071.84C1274.99 1070.76 1276.12 1070.03 1277.42 1070.03C1279.28 1070.03 1280.79 1071.53 1280.8 1073.38C1280.79 1075.26 1279.28 1076.75 1277.42 1076.75C1275.69 1076.75 1274.27 1075.46 1274.09 1073.77C1274.07 1073.75 1274.07 1073.72 1274.07 1073.71C1274.04 1073.41 1274.03 1073.1 1274.03 1072.79H1274.02ZM1277.42 1076.38C1279.08 1076.38 1280.4 1075.05 1280.43 1073.38C1280.4 1071.74 1279.08 1070.4 1277.42 1070.4C1275.75 1070.4 1274.43 1071.74 1274.43 1073.38C1274.43 1075.03 1275.77 1076.38 1277.42 1076.38Z" fill="#5EB0EF"/>
<path d="M1284.75 1076.48L1288.83 1066.76H1283.36C1283.26 1066.76 1283.17 1066.67 1283.17 1066.56C1283.17 1066.47 1283.26 1066.37 1283.36 1066.37H1289.15C1289.24 1066.37 1289.34 1066.47 1289.34 1066.56C1289.34 1066.61 1289.31 1066.65 1289.28 1066.7L1285.1 1076.63C1285.09 1076.69 1285.01 1076.75 1284.94 1076.75C1284.92 1076.75 1284.89 1076.73 1284.86 1076.73C1284.76 1076.69 1284.73 1076.58 1284.76 1076.48L1284.75 1076.48Z" fill="#5EB0EF"/>
</g>
<g opacity="0.7">
<path d="M1225.45 1115.76C1225.84 1115.76 1226.15 1116.07 1226.15 1116.46C1226.15 1116.74 1225.98 1116.99 1225.75 1117.1C1226.22 1117.24 1226.58 1117.67 1226.58 1118.19C1226.58 1118.82 1226.07 1119.32 1225.45 1119.32C1224.82 1119.32 1224.31 1118.82 1224.31 1118.19C1224.31 1117.67 1224.67 1117.24 1225.15 1117.1C1224.91 1116.99 1224.74 1116.75 1224.74 1116.46C1224.74 1116.07 1225.06 1115.76 1225.45 1115.76ZM1225.45 1119.19C1226 1119.19 1226.45 1118.74 1226.45 1118.19C1226.45 1117.63 1226 1117.18 1225.45 1117.18C1224.89 1117.18 1224.44 1117.63 1224.44 1118.19C1224.44 1118.74 1224.89 1119.19 1225.45 1119.19ZM1225.45 1117.03C1225.76 1117.03 1226.03 1116.77 1226.03 1116.46C1226.03 1116.15 1225.76 1115.89 1225.45 1115.88C1225.12 1115.88 1224.87 1116.14 1224.87 1116.46C1224.87 1116.79 1225.13 1117.03 1225.45 1117.03Z" fill="#5EB0EF"/>
<path d="M1229.3 1117.29C1229.53 1117.52 1229.65 1117.82 1229.65 1118.13C1229.65 1118.43 1229.53 1118.73 1229.3 1118.97C1229.07 1119.2 1228.76 1119.31 1228.46 1119.31C1228.16 1119.31 1227.85 1119.2 1227.63 1118.97C1227.6 1118.95 1227.6 1118.9 1227.63 1118.88C1227.65 1118.86 1227.7 1118.86 1227.72 1118.88C1227.93 1119.08 1228.19 1119.19 1228.46 1119.19C1228.73 1119.19 1229 1119.09 1229.21 1118.88C1229.42 1118.67 1229.51 1118.4 1229.51 1118.13C1229.51 1117.86 1229.42 1117.6 1229.21 1117.39C1229 1117.19 1228.74 1117.08 1228.47 1117.08C1228.45 1117.08 1228.42 1117.07 1228.41 1117.05C1228.39 1117.03 1228.39 1116.97 1228.41 1116.95L1229.44 1115.89H1227.87C1227.87 1115.89 1227.8 1115.86 1227.8 1115.82C1227.8 1115.79 1227.83 1115.76 1227.87 1115.76H1229.59C1229.59 1115.76 1229.66 1115.79 1229.66 1115.82C1229.66 1115.83 1229.66 1115.85 1229.66 1115.85C1229.66 1115.86 1229.64 1115.88 1229.64 1115.88L1228.6 1116.95C1228.86 1116.97 1229.12 1117.09 1229.32 1117.29H1229.3Z" fill="#5EB0EF"/>
</g>
<g opacity="0.64">
<path d="M1209.76 1168.96C1209.86 1169.06 1209.91 1169.18 1209.91 1169.31C1209.91 1169.44 1209.86 1169.57 1209.76 1169.67C1209.67 1169.76 1209.54 1169.81 1209.41 1169.81C1209.28 1169.81 1209.15 1169.76 1209.06 1169.67C1209.05 1169.65 1209.05 1169.64 1209.06 1169.63C1209.07 1169.62 1209.09 1169.62 1209.09 1169.63C1209.18 1169.71 1209.3 1169.76 1209.41 1169.76C1209.52 1169.76 1209.64 1169.71 1209.73 1169.63C1209.82 1169.55 1209.85 1169.43 1209.85 1169.31C1209.85 1169.19 1209.81 1169.09 1209.73 1169C1209.64 1168.91 1209.52 1168.87 1209.41 1168.87C1209.41 1168.87 1209.39 1168.87 1209.39 1168.85C1209.39 1168.85 1209.38 1168.82 1209.39 1168.81L1209.82 1168.36H1209.16C1209.16 1168.36 1209.13 1168.35 1209.13 1168.33C1209.13 1168.32 1209.15 1168.3 1209.16 1168.3H1209.89C1209.89 1168.3 1209.92 1168.32 1209.92 1168.33V1168.36L1209.47 1168.81C1209.58 1168.82 1209.69 1168.87 1209.77 1168.95L1209.76 1168.96Z" fill="#E71D36"/>
<path d="M1211.07 1168.96C1211.17 1169.06 1211.21 1169.18 1211.21 1169.31C1211.21 1169.44 1211.16 1169.57 1211.07 1169.67C1210.97 1169.76 1210.85 1169.81 1210.72 1169.81C1210.59 1169.81 1210.46 1169.76 1210.36 1169.67C1210.35 1169.65 1210.35 1169.64 1210.36 1169.63C1210.37 1169.62 1210.39 1169.62 1210.4 1169.63C1210.49 1169.71 1210.6 1169.76 1210.72 1169.76C1210.83 1169.76 1210.94 1169.71 1211.03 1169.63C1211.12 1169.55 1211.16 1169.43 1211.16 1169.31C1211.16 1169.19 1211.12 1169.09 1211.03 1169C1210.94 1168.91 1210.83 1168.87 1210.72 1168.87C1210.7 1168.87 1210.69 1168.87 1210.69 1168.85C1210.69 1168.85 1210.69 1168.82 1210.69 1168.81L1211.13 1168.36H1210.47C1210.47 1168.36 1210.44 1168.35 1210.44 1168.33C1210.44 1168.32 1210.45 1168.3 1210.47 1168.3H1211.2C1211.2 1168.3 1211.23 1168.32 1211.23 1168.33C1211.23 1168.33 1211.23 1168.34 1211.23 1168.35L1210.79 1168.82C1210.9 1168.82 1211 1168.88 1211.09 1168.96H1211.07Z" fill="#E71D36"/>
</g>
<g opacity="0.77">
<path d="M1154.72 1226.93C1156.07 1226.93 1157.18 1228.03 1157.18 1229.4C1157.18 1230.38 1156.59 1231.24 1155.77 1231.63C1157.44 1232.1 1158.69 1233.62 1158.69 1235.44C1158.69 1237.63 1156.91 1239.39 1154.72 1239.39C1152.53 1239.39 1150.75 1237.63 1150.75 1235.44C1150.75 1233.63 1152 1232.1 1153.67 1231.63C1152.81 1231.24 1152.25 1230.38 1152.25 1229.4C1152.25 1228.03 1153.35 1226.93 1154.72 1226.93ZM1154.72 1238.95C1156.68 1238.95 1158.25 1237.38 1158.25 1235.44C1158.25 1233.5 1156.68 1231.92 1154.72 1231.92C1152.76 1231.92 1151.2 1233.5 1151.2 1235.44C1151.2 1237.38 1152.78 1238.95 1154.72 1238.95ZM1154.72 1231.42C1155.83 1231.42 1156.74 1230.51 1156.74 1229.4C1156.74 1228.3 1155.83 1227.39 1154.72 1227.37C1153.6 1227.39 1152.69 1228.28 1152.69 1229.4C1152.69 1230.52 1153.6 1231.42 1154.72 1231.42Z" fill="#5EB0EF"/>
<path d="M1166.37 1226.93C1168.8 1226.94 1170.62 1229.79 1170.62 1233.14C1170.62 1236.49 1168.81 1239.37 1166.37 1239.39C1163.97 1239.38 1162.14 1236.53 1162.14 1233.14C1162.14 1229.76 1163.97 1226.94 1166.37 1226.93ZM1162.58 1233.14C1162.58 1234.78 1163.05 1236.24 1163.74 1237.27C1164.43 1238.34 1165.38 1238.95 1166.37 1238.95C1167.36 1238.95 1168.32 1238.34 1169.02 1237.27C1169.74 1236.24 1170.18 1234.78 1170.18 1233.14C1170.18 1231.5 1169.74 1230.08 1169.02 1229.04C1168.33 1227.97 1167.38 1227.37 1166.37 1227.37C1165.36 1227.37 1164.43 1227.97 1163.74 1229.04C1163.05 1230.07 1162.58 1231.53 1162.58 1233.14Z" fill="#5EB0EF"/>
</g>
<g opacity="0.77">
<path d="M1096.82 1079.27C1096.82 1078.3 1097.03 1077.23 1097.52 1076.37C1098.02 1075.51 1098.84 1074.87 1100.02 1074.82C1100.09 1074.82 1100.15 1074.87 1100.15 1074.96C1100.15 1075.02 1100.1 1075.08 1100.04 1075.08C1098.94 1075.13 1098.23 1075.69 1097.75 1076.5C1097.38 1077.12 1097.18 1077.87 1097.11 1078.62C1097.49 1077.87 1098.27 1077.36 1099.18 1077.36C1100.46 1077.36 1101.51 1078.4 1101.52 1079.69C1101.51 1080.98 1100.46 1082.02 1099.18 1082.02C1097.97 1082.02 1096.99 1081.12 1096.86 1079.95C1096.85 1079.94 1096.85 1079.92 1096.85 1079.92C1096.83 1079.7 1096.82 1079.49 1096.82 1079.27ZM1099.18 1081.76C1100.33 1081.76 1101.24 1080.84 1101.27 1079.69C1101.24 1078.54 1100.33 1077.62 1099.18 1077.62C1098.02 1077.62 1097.11 1078.54 1097.11 1079.69C1097.11 1080.83 1098.03 1081.76 1099.18 1081.76Z" fill="#E71D36"/>
<path d="M1105.62 1074.82C1106.41 1074.82 1107.04 1075.46 1107.04 1076.25C1107.04 1076.81 1106.71 1077.31 1106.23 1077.54C1107.2 1077.81 1107.92 1078.69 1107.92 1079.74C1107.92 1081 1106.89 1082.02 1105.62 1082.02C1104.36 1082.02 1103.33 1081 1103.33 1079.74C1103.33 1078.69 1104.05 1077.81 1105.02 1077.54C1104.52 1077.31 1104.2 1076.81 1104.2 1076.25C1104.2 1075.46 1104.84 1074.82 1105.62 1074.82ZM1105.62 1081.76C1106.76 1081.76 1107.66 1080.86 1107.66 1079.74C1107.66 1078.62 1106.76 1077.7 1105.62 1077.7C1104.49 1077.7 1103.59 1078.62 1103.59 1079.74C1103.59 1080.86 1104.5 1081.76 1105.62 1081.76ZM1105.62 1077.42C1106.26 1077.42 1106.79 1076.89 1106.79 1076.25C1106.79 1075.61 1106.26 1075.08 1105.62 1075.08C1104.98 1075.08 1104.45 1075.6 1104.45 1076.25C1104.45 1076.9 1104.98 1077.42 1105.62 1077.42Z" fill="#E71D36"/>
</g>
<g opacity="0.76">
<path d="M1094.33 1104.28L1099.14 1092.82H1092.68C1092.56 1092.82 1092.46 1092.71 1092.46 1092.59C1092.46 1092.49 1092.56 1092.37 1092.68 1092.37H1099.51C1099.61 1092.37 1099.73 1092.49 1099.73 1092.59C1099.73 1092.65 1099.69 1092.7 1099.66 1092.75L1094.73 1104.46C1094.72 1104.53 1094.63 1104.6 1094.54 1104.6C1094.52 1104.6 1094.49 1104.58 1094.45 1104.58C1094.33 1104.53 1094.29 1104.41 1094.33 1104.28Z" fill="#E71D36"/>
</g>
<g opacity="0.59">
<path d="M1104.26 1182.99L1105.42 1180.24H1103.87C1103.87 1180.24 1103.82 1180.22 1103.82 1180.19C1103.82 1180.16 1103.84 1180.13 1103.87 1180.13H1105.51C1105.51 1180.13 1105.56 1180.16 1105.56 1180.19C1105.56 1180.2 1105.56 1180.21 1105.55 1180.22L1104.36 1183.04C1104.36 1183.04 1104.34 1183.07 1104.32 1183.07H1104.29C1104.29 1183.07 1104.26 1183.02 1104.26 1182.99Z" fill="#5EB0EF"/>
<path d="M1107.14 1180.13C1107.46 1180.13 1107.72 1180.39 1107.72 1180.71C1107.72 1180.95 1107.58 1181.15 1107.39 1181.24C1107.79 1181.35 1108.08 1181.71 1108.08 1182.14C1108.08 1182.66 1107.66 1183.08 1107.14 1183.08C1106.62 1183.08 1106.2 1182.66 1106.2 1182.14C1106.2 1181.71 1106.5 1181.35 1106.89 1181.24C1106.69 1181.15 1106.56 1180.95 1106.56 1180.71C1106.56 1180.39 1106.82 1180.13 1107.14 1180.13ZM1107.14 1182.96C1107.61 1182.96 1107.98 1182.59 1107.98 1182.14C1107.98 1181.68 1107.61 1181.3 1107.14 1181.3C1106.68 1181.3 1106.31 1181.68 1106.31 1182.14C1106.31 1182.59 1106.68 1182.96 1107.14 1182.96ZM1107.14 1181.18C1107.4 1181.18 1107.62 1180.96 1107.62 1180.71C1107.62 1180.45 1107.4 1180.23 1107.14 1180.22C1106.88 1180.22 1106.66 1180.44 1106.66 1180.71C1106.66 1180.97 1106.88 1181.18 1107.14 1181.18Z" fill="#5EB0EF"/>
</g>
<g opacity="0.64">
<path d="M1076.46 1172.61C1076.46 1171.13 1076.77 1169.48 1077.52 1168.18C1078.28 1166.87 1079.53 1165.89 1081.33 1165.81C1081.45 1165.81 1081.54 1165.9 1081.54 1166.02C1081.54 1166.12 1081.46 1166.21 1081.36 1166.21C1079.69 1166.29 1078.61 1167.15 1077.87 1168.37C1077.31 1169.33 1077.01 1170.48 1076.9 1171.61C1077.48 1170.46 1078.67 1169.7 1080.05 1169.7C1082.01 1169.7 1083.61 1171.28 1083.63 1173.24C1083.61 1175.22 1082.01 1176.8 1080.05 1176.8C1078.22 1176.8 1076.71 1175.43 1076.52 1173.65C1076.5 1173.64 1076.5 1173.61 1076.5 1173.59C1076.47 1173.28 1076.46 1172.94 1076.46 1172.62V1172.61ZM1080.05 1176.4C1081.81 1176.4 1083.2 1174.99 1083.24 1173.24C1083.21 1171.49 1081.81 1170.09 1080.05 1170.09C1078.29 1170.09 1076.9 1171.5 1076.9 1173.24C1076.9 1174.98 1078.31 1176.4 1080.05 1176.4Z" fill="#E71D36"/>
<path d="M1087.81 1176.52L1092.12 1166.24H1086.33C1086.22 1166.24 1086.13 1166.15 1086.13 1166.03C1086.13 1165.94 1086.23 1165.83 1086.33 1165.83H1092.45C1092.55 1165.83 1092.65 1165.94 1092.65 1166.03C1092.65 1166.08 1092.62 1166.13 1092.59 1166.18L1088.17 1176.67C1088.16 1176.74 1088.08 1176.8 1088 1176.8C1087.99 1176.8 1087.96 1176.79 1087.93 1176.79C1087.81 1176.74 1087.78 1176.63 1087.81 1176.52L1087.81 1176.52Z" fill="#E71D36"/>
</g>
<g opacity="0.75">
<path d="M1126.73 1215.29L1129.06 1213.18C1129.36 1212.9 1129.52 1212.53 1129.52 1212.16C1129.52 1211.83 1129.39 1211.5 1129.16 1211.23C1128.89 1210.93 1128.51 1210.77 1128.15 1210.77C1127.82 1210.77 1127.49 1210.89 1127.22 1211.14L1126.97 1211.35C1126.93 1211.39 1126.88 1211.39 1126.85 1211.35C1126.81 1211.32 1126.82 1211.26 1126.85 1211.23L1127.09 1211C1127.39 1210.73 1127.76 1210.6 1128.13 1210.6C1128.55 1210.6 1128.97 1210.77 1129.28 1211.11C1129.54 1211.4 1129.68 1211.78 1129.68 1212.15C1129.68 1212.57 1129.51 1212.99 1129.16 1213.3L1126.99 1215.27H1129.6C1129.64 1215.27 1129.68 1215.3 1129.68 1215.35C1129.68 1215.41 1129.64 1215.44 1129.6 1215.44H1126.77C1126.77 1215.44 1126.72 1215.44 1126.7 1215.41C1126.67 1215.37 1126.67 1215.32 1126.7 1215.28L1126.73 1215.29Z" fill="#E71D36"/>
<path d="M1130.81 1215.29L1133.14 1213.18C1133.45 1212.9 1133.6 1212.53 1133.6 1212.16C1133.6 1211.83 1133.48 1211.5 1133.24 1211.23C1132.97 1210.93 1132.59 1210.77 1132.23 1210.77C1131.9 1210.77 1131.57 1210.89 1131.3 1211.14L1131.06 1211.35C1131.01 1211.39 1130.97 1211.39 1130.93 1211.35C1130.89 1211.32 1130.9 1211.26 1130.93 1211.23L1131.17 1211C1131.47 1210.73 1131.84 1210.6 1132.21 1210.6C1132.63 1210.6 1133.05 1210.77 1133.36 1211.11C1133.63 1211.4 1133.76 1211.78 1133.76 1212.15C1133.76 1212.57 1133.59 1212.99 1133.24 1213.3L1131.07 1215.27H1133.68C1133.72 1215.27 1133.76 1215.3 1133.76 1215.35C1133.76 1215.41 1133.72 1215.44 1133.68 1215.44H1130.85C1130.85 1215.44 1130.8 1215.44 1130.79 1215.41C1130.76 1215.37 1130.76 1215.32 1130.79 1215.28L1130.81 1215.29Z" fill="#E71D36"/>
</g>
<g opacity="0.97">
<path d="M1136.15 1163.3C1136.73 1163.3 1137.21 1163.77 1137.21 1164.36C1137.21 1164.78 1136.96 1165.15 1136.6 1165.33C1137.32 1165.52 1137.86 1166.18 1137.86 1166.97C1137.86 1167.91 1137.09 1168.67 1136.15 1168.67C1135.21 1168.67 1134.44 1167.91 1134.44 1166.97C1134.44 1166.18 1134.98 1165.52 1135.69 1165.33C1135.32 1165.16 1135.08 1164.79 1135.08 1164.36C1135.08 1163.77 1135.55 1163.3 1136.14 1163.3H1136.15ZM1136.15 1168.48C1136.99 1168.48 1137.67 1167.8 1137.67 1166.97C1137.67 1166.13 1136.99 1165.45 1136.15 1165.45C1135.31 1165.45 1134.63 1166.13 1134.63 1166.97C1134.63 1167.8 1135.32 1168.48 1136.15 1168.48ZM1136.15 1165.23C1136.62 1165.23 1137.02 1164.84 1137.02 1164.36C1137.02 1163.89 1136.62 1163.5 1136.15 1163.49C1135.67 1163.5 1135.28 1163.88 1135.28 1164.36C1135.28 1164.84 1135.67 1165.23 1136.15 1165.23Z" fill="#E71D36"/>
<path d="M1141.17 1163.3C1142.22 1163.3 1143 1164.53 1143 1165.97C1143 1167.42 1142.22 1168.66 1141.17 1168.67C1140.13 1168.66 1139.35 1167.43 1139.35 1165.97C1139.35 1164.51 1140.13 1163.3 1141.17 1163.3ZM1139.54 1165.97C1139.54 1166.68 1139.74 1167.31 1140.04 1167.76C1140.34 1168.21 1140.74 1168.48 1141.17 1168.48C1141.6 1168.48 1142.01 1168.21 1142.31 1167.76C1142.62 1167.31 1142.81 1166.68 1142.81 1165.97C1142.81 1165.27 1142.61 1164.65 1142.31 1164.21C1142.01 1163.75 1141.61 1163.48 1141.17 1163.48C1140.74 1163.48 1140.34 1163.75 1140.04 1164.21C1139.74 1164.65 1139.54 1165.28 1139.54 1165.97Z" fill="#E71D36"/>
</g>
<g opacity="0.89">
<path d="M1078.13 1185.41H1071.98C1071.93 1185.41 1071.9 1185.39 1071.86 1185.36C1071.78 1185.29 1071.77 1185.18 1071.83 1185.11L1078.17 1177.21L1078.2 1177.18C1078.25 1177.15 1078.28 1177.13 1078.32 1177.13C1078.38 1177.13 1078.44 1177.18 1078.47 1177.22C1078.49 1177.24 1078.49 1177.25 1078.5 1177.27C1078.5 1177.28 1078.52 1177.3 1078.52 1177.31V1185.03H1079.43C1079.55 1185.03 1079.63 1185.11 1079.63 1185.21C1079.63 1185.33 1079.55 1185.41 1079.43 1185.41H1078.52V1187.45C1078.52 1187.56 1078.44 1187.65 1078.32 1187.65C1078.23 1187.65 1078.13 1187.56 1078.13 1187.45V1185.41ZM1078.13 1185.03V1177.9L1072.37 1185.03H1078.13Z" fill="#5EB0EF"/>
<path d="M1082.02 1186.61C1081.94 1186.55 1081.94 1186.41 1082.02 1186.34C1082.09 1186.26 1082.21 1186.26 1082.29 1186.34C1082.91 1186.94 1083.7 1187.26 1084.48 1187.26C1085.27 1187.26 1086.09 1186.94 1086.69 1186.34C1087.31 1185.72 1087.61 1184.93 1087.61 1184.13C1087.61 1183.33 1087.31 1182.53 1086.69 1181.93C1086.09 1181.33 1085.31 1181.03 1084.5 1181.03C1083.69 1181.03 1082.92 1181.33 1082.29 1181.93C1082.24 1182.01 1082.11 1182.01 1082.03 1181.95C1082.02 1181.95 1082 1181.93 1082 1181.92C1081.99 1181.92 1081.99 1181.9 1081.99 1181.9C1081.96 1181.86 1081.94 1181.81 1081.96 1181.75L1082.48 1177.31C1082.5 1177.22 1082.56 1177.16 1082.62 1177.15C1082.65 1177.13 1082.68 1177.12 1082.73 1177.12H1087.81C1087.91 1177.12 1088 1177.22 1088 1177.31C1088 1177.43 1087.91 1177.51 1087.81 1177.51H1082.86L1082.41 1181.33C1083.03 1180.86 1083.76 1180.64 1084.5 1180.64C1085.4 1180.64 1086.3 1180.97 1086.99 1181.66C1087.66 1182.34 1088 1183.23 1088 1184.13C1088 1185.03 1087.66 1185.93 1086.96 1186.61C1086.29 1187.3 1085.39 1187.65 1084.48 1187.65C1083.58 1187.65 1082.71 1187.3 1082.02 1186.61Z" fill="#5EB0EF"/>
</g>
<g opacity="0.94">
<path d="M1086.54 1293.82L1088.49 1289.16H1085.87C1085.81 1289.16 1085.78 1289.11 1085.78 1289.07C1085.78 1289.02 1085.82 1288.98 1085.87 1288.98H1088.64C1088.68 1288.98 1088.73 1289.03 1088.73 1289.07C1088.73 1289.09 1088.71 1289.11 1088.7 1289.13L1086.69 1293.89C1086.69 1293.89 1086.65 1293.95 1086.62 1293.95C1086.62 1293.95 1086.6 1293.95 1086.58 1293.95C1086.53 1293.92 1086.52 1293.87 1086.53 1293.83L1086.54 1293.82Z" fill="#E71D36"/>
<path d="M1091.5 1288.97C1092.48 1288.97 1093.2 1290.11 1093.2 1291.45C1093.2 1292.79 1092.47 1293.94 1091.5 1293.95C1090.54 1293.95 1089.81 1292.8 1089.81 1291.45C1089.81 1290.1 1090.54 1288.98 1091.5 1288.97ZM1089.99 1291.45C1089.99 1292.1 1090.18 1292.68 1090.45 1293.1C1090.73 1293.53 1091.1 1293.77 1091.5 1293.77C1091.9 1293.77 1092.28 1293.53 1092.56 1293.1C1092.85 1292.68 1093.02 1292.1 1093.02 1291.45C1093.02 1290.8 1092.84 1290.22 1092.56 1289.81C1092.28 1289.38 1091.91 1289.14 1091.5 1289.14C1091.1 1289.14 1090.73 1289.38 1090.45 1289.81C1090.17 1290.22 1089.99 1290.8 1089.99 1291.45Z" fill="#E71D36"/>
</g>
<g opacity="0.53">
<path d="M1003.76 1295.32H995.66C995.6 1295.32 995.563 1295.3 995.502 1295.26C995.405 1295.17 995.382 1295.02 995.465 1294.92L1003.82 1284.52L1003.86 1284.49C1003.92 1284.45 1003.96 1284.43 1004.02 1284.43C1004.1 1284.43 1004.17 1284.49 1004.21 1284.55C1004.24 1284.57 1004.24 1284.58 1004.25 1284.61C1004.25 1284.63 1004.27 1284.64 1004.27 1284.67V1294.83H1005.48C1005.64 1294.83 1005.74 1294.93 1005.74 1295.07C1005.74 1295.23 1005.64 1295.33 1005.48 1295.33H1004.27V1298.02C1004.27 1298.16 1004.17 1298.28 1004.02 1298.28C1003.9 1298.28 1003.76 1298.16 1003.76 1298.02V1295.33V1295.32ZM1003.76 1294.83V1285.43L996.179 1294.83H1003.76Z" fill="#E71D36"/>
<path d="M1008.86 1292.98C1008.86 1291.12 1009.26 1289.04 1010.21 1287.4C1011.16 1285.73 1012.74 1284.5 1015.02 1284.4C1015.16 1284.4 1015.28 1284.5 1015.28 1284.66C1015.28 1284.78 1015.18 1284.9 1015.06 1284.9C1012.94 1285 1011.57 1286.09 1010.64 1287.63C1009.92 1288.84 1009.55 1290.28 1009.41 1291.71C1010.14 1290.27 1011.65 1289.3 1013.39 1289.3C1015.86 1289.3 1017.88 1291.3 1017.91 1293.77C1017.88 1296.27 1015.87 1298.27 1013.39 1298.27C1011.07 1298.27 1009.17 1296.55 1008.93 1294.29C1008.91 1294.26 1008.91 1294.23 1008.91 1294.2C1008.87 1293.8 1008.85 1293.39 1008.85 1292.98L1008.86 1292.98ZM1013.4 1297.78C1015.61 1297.78 1017.38 1296 1017.42 1293.78C1017.38 1291.59 1015.61 1289.8 1013.4 1289.8C1011.18 1289.8 1009.42 1291.58 1009.42 1293.78C1009.42 1295.99 1011.2 1297.78 1013.4 1297.78Z" fill="#E71D36"/>
</g>
<g opacity="0.6">
<path d="M976.353 1222.4C976.353 1221.45 976.556 1220.37 977.045 1219.53C977.533 1218.67 978.353 1218.04 979.517 1217.99C979.585 1217.99 979.645 1218.04 979.645 1218.12C979.645 1218.18 979.593 1218.24 979.532 1218.24C978.443 1218.3 977.744 1218.85 977.263 1219.65C976.895 1220.27 976.699 1221.01 976.632 1221.75C977.007 1221 977.781 1220.51 978.683 1220.51C979.953 1220.51 980.998 1221.54 981.005 1222.81C980.998 1224.09 979.961 1225.12 978.683 1225.12C977.488 1225.12 976.511 1224.24 976.391 1223.08C976.383 1223.07 976.383 1223.05 976.383 1223.03C976.361 1222.83 976.353 1222.61 976.353 1222.4ZM978.683 1224.87C979.826 1224.87 980.727 1223.95 980.75 1222.81C980.727 1221.67 979.826 1220.75 978.683 1220.75C977.541 1220.75 976.632 1221.67 976.632 1222.81C976.632 1223.94 977.548 1224.87 978.683 1224.87Z" fill="#E71D36"/>
<path d="M982.741 1224.88L986.183 1221.78C986.619 1221.37 986.852 1220.82 986.852 1220.27C986.852 1219.79 986.672 1219.3 986.326 1218.92C985.928 1218.48 985.372 1218.24 984.831 1218.24C984.342 1218.24 983.854 1218.43 983.463 1218.77L983.102 1219.09C983.042 1219.14 982.967 1219.14 982.922 1219.09C982.877 1219.04 982.884 1218.95 982.929 1218.91L983.283 1218.57C983.733 1218.17 984.275 1217.98 984.823 1217.98C985.447 1217.98 986.063 1218.23 986.514 1218.72C986.897 1219.16 987.108 1219.72 987.108 1220.27C987.108 1220.89 986.852 1221.51 986.341 1221.96L983.14 1224.86H986.98C987.04 1224.86 987.1 1224.91 987.1 1224.98C987.1 1225.05 987.04 1225.12 986.98 1225.12H982.824C982.787 1225.12 982.741 1225.11 982.726 1225.06C982.689 1225.01 982.689 1224.93 982.734 1224.88H982.741Z" fill="#E71D36"/>
</g>
<g opacity="0.54">
<path d="M1054.64 1310.52C1055.32 1310.52 1055.87 1311.06 1055.87 1311.75C1055.87 1312.24 1055.57 1312.67 1055.17 1312.85C1056 1313.09 1056.62 1313.85 1056.62 1314.75C1056.62 1315.84 1055.73 1316.72 1054.64 1316.72C1053.55 1316.72 1052.66 1315.84 1052.66 1314.75C1052.66 1313.85 1053.29 1313.08 1054.11 1312.85C1053.69 1312.66 1053.41 1312.23 1053.41 1311.75C1053.41 1311.06 1053.96 1310.52 1054.64 1310.52ZM1054.64 1316.5C1055.62 1316.5 1056.39 1315.72 1056.39 1314.76C1056.39 1313.79 1055.61 1313 1054.64 1313C1053.67 1313 1052.89 1313.79 1052.89 1314.76C1052.89 1315.72 1053.68 1316.5 1054.64 1316.5ZM1054.64 1312.75C1055.19 1312.75 1055.64 1312.3 1055.64 1311.75C1055.64 1311.2 1055.19 1310.75 1054.64 1310.74C1054.08 1310.75 1053.63 1311.19 1053.63 1311.75C1053.63 1312.31 1054.08 1312.75 1054.64 1312.75Z" fill="#E71D36"/>
<path d="M1058.34 1316.1C1058.29 1316.07 1058.29 1315.99 1058.34 1315.95C1058.38 1315.9 1058.45 1315.9 1058.5 1315.95C1058.86 1316.3 1059.33 1316.49 1059.79 1316.49C1060.25 1316.49 1060.74 1316.3 1061.09 1315.95C1061.45 1315.58 1061.63 1315.11 1061.63 1314.64C1061.63 1314.18 1061.45 1313.7 1061.09 1313.35C1060.74 1313 1060.28 1312.82 1059.8 1312.82C1059.31 1312.82 1058.86 1312.99 1058.5 1313.35C1058.47 1313.4 1058.39 1313.4 1058.35 1313.36C1058.34 1313.36 1058.33 1313.35 1058.33 1313.35C1058.32 1313.34 1058.32 1313.34 1058.32 1313.34C1058.3 1313.31 1058.29 1313.28 1058.3 1313.25L1058.61 1310.63C1058.62 1310.58 1058.65 1310.54 1058.69 1310.53C1058.71 1310.52 1058.73 1310.52 1058.75 1310.52H1061.74C1061.8 1310.52 1061.86 1310.58 1061.86 1310.63C1061.86 1310.7 1061.8 1310.74 1061.74 1310.74H1058.83L1058.56 1312.99C1058.92 1312.72 1059.36 1312.58 1059.8 1312.58C1060.33 1312.58 1060.86 1312.78 1061.27 1313.19C1061.66 1313.58 1061.86 1314.11 1061.86 1314.64C1061.86 1315.16 1061.66 1315.7 1061.25 1316.1C1060.86 1316.5 1060.32 1316.7 1059.79 1316.7C1059.25 1316.7 1058.74 1316.5 1058.34 1316.1V1316.1Z" fill="#E71D36"/>
</g>
<g opacity="0.66">
<path d="M1008.44 1318.55L1014.63 1312.97C1015.42 1312.24 1015.83 1311.24 1015.83 1310.26C1015.83 1309.4 1015.5 1308.52 1014.88 1307.82C1014.17 1307.03 1013.16 1306.61 1012.19 1306.61C1011.32 1306.61 1010.44 1306.94 1009.74 1307.57L1009.11 1308.15C1008.99 1308.24 1008.87 1308.24 1008.78 1308.15C1008.68 1308.06 1008.7 1307.9 1008.79 1307.82L1009.43 1307.21C1010.23 1306.5 1011.2 1306.15 1012.19 1306.15C1013.31 1306.15 1014.43 1306.61 1015.23 1307.49C1015.93 1308.28 1016.29 1309.28 1016.29 1310.26C1016.29 1311.37 1015.83 1312.49 1014.91 1313.3L1009.17 1318.52H1016.06C1016.18 1318.52 1016.28 1318.61 1016.28 1318.74C1016.28 1318.86 1016.17 1318.98 1016.06 1318.98H1008.6C1008.53 1318.98 1008.45 1318.95 1008.42 1318.89C1008.35 1318.8 1008.35 1318.65 1008.44 1318.55H1008.44Z" fill="#E71D36"/>
<path d="M1019.24 1318.55L1025.43 1312.97C1026.22 1312.24 1026.64 1311.24 1026.64 1310.26C1026.64 1309.4 1026.31 1308.52 1025.68 1307.82C1024.97 1307.03 1023.96 1306.61 1022.99 1306.61C1022.11 1306.61 1021.24 1306.94 1020.54 1307.57L1019.91 1308.15C1019.79 1308.24 1019.66 1308.24 1019.57 1308.15C1019.48 1308.06 1019.5 1307.9 1019.59 1307.82L1020.23 1307.21C1021.03 1306.5 1022 1306.15 1022.99 1306.15C1024.11 1306.15 1025.23 1306.61 1026.03 1307.49C1026.73 1308.28 1027.09 1309.28 1027.09 1310.26C1027.09 1311.37 1026.63 1312.49 1025.71 1313.3L1019.97 1318.52H1026.86C1026.98 1318.52 1027.08 1318.61 1027.08 1318.74C1027.08 1318.86 1026.97 1318.98 1026.86 1318.98H1019.4C1019.33 1318.98 1019.25 1318.95 1019.21 1318.89C1019.14 1318.8 1019.14 1318.65 1019.24 1318.55H1019.24Z" fill="#E71D36"/>
</g>
<g opacity="0.53">
<path d="M993.113 1239.24L994.09 1236.9H992.775C992.775 1236.9 992.73 1236.88 992.73 1236.86C992.73 1236.83 992.752 1236.81 992.775 1236.81H994.165C994.165 1236.81 994.21 1236.83 994.21 1236.86C994.21 1236.87 994.203 1236.88 994.203 1236.88L993.188 1239.28C993.188 1239.28 993.165 1239.31 993.15 1239.31H993.135C993.135 1239.31 993.105 1239.27 993.113 1239.25V1239.24Z" fill="#E71D36"/>
<path d="M994.743 1239.06C994.743 1239.06 994.728 1239.01 994.743 1238.99C994.758 1238.98 994.788 1238.98 994.803 1238.99C994.954 1239.14 995.142 1239.21 995.322 1239.21C995.502 1239.21 995.705 1239.14 995.848 1238.99C995.991 1238.85 996.066 1238.65 996.066 1238.47C996.066 1238.28 995.991 1238.09 995.848 1237.95C995.705 1237.8 995.517 1237.73 995.33 1237.73C995.142 1237.73 994.954 1237.8 994.803 1237.95C994.796 1237.96 994.758 1237.96 994.743 1237.95H994.736V1237.93C994.736 1237.93 994.728 1237.91 994.736 1237.89L994.864 1236.84C994.864 1236.84 994.879 1236.8 994.894 1236.8C994.894 1236.8 994.909 1236.8 994.916 1236.8H996.119C996.119 1236.8 996.164 1236.82 996.164 1236.84C996.164 1236.87 996.141 1236.89 996.119 1236.89H994.946L994.841 1237.79C994.984 1237.68 995.164 1237.62 995.337 1237.62C995.555 1237.62 995.765 1237.7 995.931 1237.86C996.089 1238.02 996.171 1238.23 996.171 1238.45C996.171 1238.67 996.089 1238.88 995.923 1239.04C995.765 1239.2 995.548 1239.29 995.337 1239.29C995.127 1239.29 994.916 1239.2 994.751 1239.04L994.743 1239.06Z" fill="#E71D36"/>
</g>
<g opacity="0.95">
<path d="M990.377 1387.33C990.588 1387.54 990.693 1387.82 990.693 1388.1C990.693 1388.38 990.588 1388.66 990.377 1388.87C990.159 1389.08 989.881 1389.19 989.611 1389.19C989.333 1389.19 989.054 1389.08 988.844 1388.87C988.822 1388.85 988.822 1388.81 988.844 1388.79C988.867 1388.77 988.904 1388.77 988.927 1388.79C989.115 1388.98 989.363 1389.07 989.611 1389.07C989.859 1389.07 990.107 1388.98 990.294 1388.78C990.482 1388.6 990.58 1388.35 990.58 1388.1C990.58 1387.85 990.49 1387.61 990.302 1387.42C990.107 1387.23 989.866 1387.14 989.618 1387.14C989.596 1387.14 989.573 1387.13 989.565 1387.11C989.565 1387.11 989.543 1387.04 989.565 1387.02L990.505 1386.05H989.062C989.062 1386.05 989.002 1386.02 989.002 1385.99C989.002 1385.96 989.032 1385.93 989.062 1385.93H990.64C990.64 1385.93 990.7 1385.96 990.7 1385.99C990.7 1385.99 990.7 1386.01 990.7 1386.02V1386.03L989.723 1387.02C989.964 1387.05 990.204 1387.15 990.385 1387.34L990.377 1387.33Z" fill="#5EB0EF"/>
<path d="M992.744 1385.93C993.383 1385.93 993.857 1386.68 993.857 1387.56C993.857 1388.44 993.383 1389.19 992.744 1389.2C992.113 1389.2 991.632 1388.45 991.632 1387.56C991.632 1386.67 992.113 1385.93 992.744 1385.93ZM991.752 1387.56C991.752 1387.99 991.873 1388.37 992.053 1388.64C992.233 1388.92 992.481 1389.08 992.744 1389.08C993.007 1389.08 993.255 1388.92 993.443 1388.64C993.631 1388.37 993.744 1387.99 993.744 1387.56C993.744 1387.13 993.624 1386.75 993.443 1386.48C993.263 1386.2 993.015 1386.05 992.744 1386.05C992.474 1386.05 992.233 1386.2 992.053 1386.48C991.873 1386.75 991.752 1387.14 991.752 1387.56Z" fill="#5EB0EF"/>
</g>
<g opacity="0.84">
<path d="M835.097 1304.92C835.097 1303.99 835.292 1302.96 835.766 1302.15C836.239 1301.32 837.021 1300.72 838.148 1300.67C838.216 1300.67 838.276 1300.72 838.276 1300.8C838.276 1300.86 838.223 1300.92 838.171 1300.92C837.126 1300.96 836.442 1301.51 835.984 1302.27C835.63 1302.87 835.443 1303.59 835.375 1304.3C835.736 1303.58 836.48 1303.1 837.344 1303.1C838.569 1303.1 839.568 1304.09 839.576 1305.32C839.568 1306.55 838.569 1307.54 837.344 1307.54C836.194 1307.54 835.255 1306.69 835.142 1305.57C835.134 1305.57 835.134 1305.54 835.134 1305.54C835.112 1305.34 835.104 1305.13 835.104 1304.93L835.097 1304.92ZM837.344 1307.3C838.441 1307.3 839.313 1306.42 839.335 1305.31C839.313 1304.22 838.441 1303.34 837.344 1303.34C836.247 1303.34 835.375 1304.22 835.375 1305.31C835.375 1306.4 836.254 1307.3 837.344 1307.3Z" fill="#5EB0EF"/>
<path d="M843.492 1300.67C844.236 1300.67 844.845 1301.28 844.845 1302.03C844.845 1302.57 844.521 1303.04 844.07 1303.26C844.995 1303.51 845.679 1304.36 845.679 1305.36C845.679 1306.56 844.702 1307.53 843.492 1307.53C842.282 1307.53 841.305 1306.56 841.305 1305.36C841.305 1304.36 841.989 1303.51 842.913 1303.26C842.44 1303.04 842.124 1302.57 842.124 1302.03C842.124 1301.28 842.733 1300.67 843.484 1300.67H843.492ZM843.492 1307.29C844.566 1307.29 845.431 1306.42 845.431 1305.36C845.431 1304.29 844.566 1303.42 843.492 1303.42C842.417 1303.42 841.553 1304.29 841.553 1305.36C841.553 1306.42 842.425 1307.29 843.492 1307.29ZM843.492 1303.14C844.101 1303.14 844.597 1302.63 844.597 1302.03C844.597 1301.43 844.093 1300.93 843.492 1300.91C842.876 1300.92 842.372 1301.41 842.372 1302.03C842.372 1302.66 842.876 1303.14 843.492 1303.14Z" fill="#5EB0EF"/>
</g>
<g opacity="0.54">
<path d="M893.025 1272.37L896.482 1269.25C896.925 1268.83 897.158 1268.29 897.158 1267.73C897.158 1267.25 896.978 1266.76 896.624 1266.37C896.226 1265.92 895.662 1265.69 895.121 1265.69C894.633 1265.69 894.137 1265.88 893.753 1266.23L893.393 1266.56C893.333 1266.61 893.258 1266.61 893.205 1266.56C893.152 1266.5 893.167 1266.41 893.212 1266.37L893.573 1266.03C894.024 1265.63 894.565 1265.44 895.121 1265.44C895.745 1265.44 896.369 1265.69 896.82 1266.19C897.21 1266.63 897.413 1267.19 897.413 1267.74C897.413 1268.36 897.158 1268.99 896.647 1269.44L893.43 1272.35H897.293C897.353 1272.35 897.413 1272.41 897.413 1272.47C897.413 1272.54 897.353 1272.61 897.293 1272.61H893.115C893.07 1272.61 893.032 1272.6 893.009 1272.56C892.964 1272.51 892.964 1272.42 893.017 1272.37H893.025Z" fill="#E71D36"/>
<path d="M899.225 1271.9C899.172 1271.86 899.172 1271.76 899.225 1271.71C899.278 1271.66 899.36 1271.66 899.413 1271.71C899.834 1272.12 900.375 1272.33 900.908 1272.33C901.442 1272.33 902.006 1272.11 902.411 1271.71C902.832 1271.29 903.035 1270.75 903.035 1270.2C903.035 1269.66 902.832 1269.12 902.411 1268.71C901.998 1268.3 901.472 1268.09 900.916 1268.09C900.36 1268.09 899.841 1268.29 899.413 1268.71C899.383 1268.76 899.293 1268.76 899.24 1268.71C899.232 1268.71 899.217 1268.71 899.217 1268.69C899.21 1268.69 899.21 1268.68 899.21 1268.68C899.187 1268.65 899.18 1268.62 899.187 1268.58L899.548 1265.56C899.556 1265.5 899.601 1265.45 899.638 1265.44C899.661 1265.44 899.683 1265.42 899.713 1265.42H903.17C903.246 1265.42 903.306 1265.5 903.306 1265.56C903.306 1265.64 903.246 1265.69 903.17 1265.69H899.804L899.495 1268.29C899.916 1267.98 900.42 1267.82 900.916 1267.82C901.532 1267.82 902.141 1268.05 902.614 1268.52C903.065 1268.98 903.298 1269.59 903.298 1270.2C903.298 1270.81 903.065 1271.43 902.592 1271.89C902.133 1272.36 901.517 1272.6 900.901 1272.6C900.285 1272.6 899.691 1272.36 899.225 1271.89V1271.9Z" fill="#E71D36"/>
</g>
<g opacity="0.7">
<path d="M819.089 1391.91L821.877 1390.26C821.997 1390.18 822.155 1390.2 822.238 1390.34C822.298 1390.46 822.261 1390.6 822.118 1390.68L819.33 1392.36C819.292 1392.38 819.247 1392.38 819.209 1392.38C819.127 1392.38 819.029 1392.36 818.991 1392.26C818.931 1392.16 818.954 1392 819.089 1391.92V1391.91ZM821.757 1390.46C821.757 1390.34 821.877 1390.21 821.997 1390.21C822.155 1390.21 822.253 1390.35 822.253 1390.46V1403.87C822.253 1404.01 822.155 1404.13 821.997 1404.13C821.877 1404.13 821.757 1404.01 821.757 1403.87V1390.46Z" fill="#E71D36"/>
<path d="M830.851 1390.18C833.196 1390.18 835.127 1392 835.33 1394.33C835.39 1394.7 835.413 1395.1 835.413 1395.5C835.413 1397.37 835.014 1399.44 834.06 1401.12C833.068 1402.8 831.512 1404.03 829.22 1404.13C829.062 1404.13 828.964 1404.03 828.964 1403.87C828.942 1403.75 829.062 1403.63 829.182 1403.63C831.309 1403.53 832.684 1402.41 833.601 1400.89C834.278 1399.69 834.699 1398.22 834.834 1396.8C834.097 1398.24 832.587 1399.22 830.851 1399.22C828.363 1399.22 826.312 1397.2 826.312 1394.71C826.312 1392.22 828.363 1390.19 830.851 1390.19V1390.18ZM830.851 1398.7C833.06 1398.7 834.849 1396.91 834.849 1394.7C834.849 1394.61 834.849 1394.5 834.826 1394.4V1394.3C834.623 1392.25 832.917 1390.68 830.843 1390.68C828.611 1390.68 826.8 1392.47 826.8 1394.7C826.8 1396.94 828.611 1398.7 830.843 1398.7H830.851Z" fill="#E71D36"/>
</g>
<g opacity="0.77">
<path d="M867.119 1384.41L870.434 1382.45C870.576 1382.35 870.764 1382.38 870.862 1382.54C870.937 1382.68 870.885 1382.85 870.719 1382.95L867.405 1384.94C867.36 1384.96 867.307 1384.96 867.262 1384.96C867.164 1384.96 867.052 1384.94 866.999 1384.82C866.924 1384.7 866.954 1384.51 867.119 1384.41ZM870.291 1382.68C870.291 1382.54 870.434 1382.38 870.576 1382.38C870.764 1382.38 870.884 1382.54 870.884 1382.68V1398.64C870.884 1398.8 870.764 1398.94 870.576 1398.94C870.434 1398.94 870.291 1398.8 870.291 1398.64V1382.68Z" fill="#5EB0EF"/>
<path d="M881.113 1382.35C883.909 1382.35 886.201 1384.5 886.441 1387.27C886.509 1387.72 886.531 1388.2 886.531 1388.67C886.531 1390.9 886.058 1393.36 884.923 1395.36C883.736 1397.35 881.894 1398.82 879.166 1398.94C878.978 1398.94 878.858 1398.82 878.858 1398.63C878.836 1398.49 878.978 1398.34 879.121 1398.34C881.654 1398.22 883.292 1396.9 884.382 1395.07C885.186 1393.65 885.682 1391.9 885.847 1390.21C884.968 1391.92 883.172 1393.08 881.113 1393.08C878.152 1393.08 875.709 1390.69 875.709 1387.72C875.709 1384.76 878.152 1382.35 881.113 1382.35ZM881.113 1392.49C883.743 1392.49 885.87 1390.36 885.87 1387.73C885.87 1387.61 885.87 1387.49 885.847 1387.38V1387.26C885.607 1384.81 883.57 1382.94 881.113 1382.94C878.46 1382.94 876.303 1385.08 876.303 1387.73C876.303 1390.39 878.46 1392.49 881.113 1392.49Z" fill="#5EB0EF"/>
</g>
<g opacity="0.74">
<path d="M809.613 1355.2H801.519C801.458 1355.2 801.421 1355.18 801.361 1355.14C801.263 1355.05 801.24 1354.9 801.323 1354.81L809.673 1344.4L809.71 1344.37C809.77 1344.33 809.808 1344.31 809.868 1344.31C809.943 1344.31 810.026 1344.37 810.063 1344.43C810.086 1344.45 810.086 1344.46 810.101 1344.49C810.101 1344.51 810.124 1344.52 810.124 1344.55V1354.71H811.333C811.491 1354.71 811.589 1354.81 811.589 1354.95C811.589 1355.11 811.491 1355.2 811.333 1355.2H810.124V1357.9C810.124 1358.03 810.026 1358.15 809.868 1358.15C809.748 1358.15 809.613 1358.03 809.613 1357.9V1355.2ZM809.613 1354.71V1345.32L802.03 1354.71H809.613Z" fill="#E71D36"/>
<path d="M818.946 1344.29C820.45 1344.29 821.675 1345.52 821.675 1347.04C821.675 1348.13 821.021 1349.08 820.111 1349.52C821.968 1350.03 823.358 1351.74 823.358 1353.76C823.358 1356.2 821.381 1358.16 818.946 1358.16C816.512 1358.16 814.535 1356.2 814.535 1353.76C814.535 1351.74 815.918 1350.04 817.782 1349.52C816.835 1349.08 816.203 1348.13 816.203 1347.04C816.203 1345.52 817.428 1344.29 818.954 1344.29H818.946ZM818.946 1357.66C821.126 1357.66 822.87 1355.92 822.87 1353.76C822.87 1351.6 821.126 1349.84 818.946 1349.84C816.767 1349.84 815.031 1351.6 815.031 1353.76C815.031 1355.92 816.79 1357.66 818.946 1357.66ZM818.946 1349.28C820.171 1349.28 821.186 1348.28 821.186 1347.04C821.186 1345.81 820.179 1344.8 818.946 1344.79C817.699 1344.81 816.692 1345.8 816.692 1347.04C816.692 1348.29 817.699 1349.28 818.946 1349.28Z" fill="#E71D36"/>
</g>
<g opacity="0.79">
<path d="M731.146 1407.97L732.01 1407.46C732.048 1407.44 732.1 1407.44 732.123 1407.48C732.138 1407.52 732.123 1407.56 732.085 1407.59L731.221 1408.11C731.221 1408.11 731.198 1408.11 731.183 1408.11C731.161 1408.11 731.131 1408.11 731.116 1408.07C731.093 1408.04 731.101 1407.99 731.146 1407.96V1407.97ZM731.973 1407.52C731.973 1407.48 732.01 1407.44 732.048 1407.44C732.1 1407.44 732.13 1407.48 732.13 1407.52V1411.69C732.13 1411.73 732.1 1411.77 732.048 1411.77C732.01 1411.77 731.973 1411.73 731.973 1411.69V1407.52Z" fill="#E71D36"/>
<path d="M735.888 1410.84H733.355C733.355 1410.84 733.325 1410.84 733.303 1410.83C733.273 1410.8 733.265 1410.75 733.288 1410.72L735.896 1407.47L735.911 1407.46C735.911 1407.46 735.941 1407.44 735.963 1407.44C735.986 1407.44 736.016 1407.46 736.023 1407.48V1407.5C736.023 1407.51 736.038 1407.51 736.038 1407.52V1410.69H736.414C736.467 1410.69 736.497 1410.72 736.497 1410.77C736.497 1410.82 736.467 1410.85 736.414 1410.85H736.038V1411.69C736.038 1411.74 736.008 1411.78 735.956 1411.78C735.918 1411.78 735.873 1411.74 735.873 1411.69V1410.85L735.888 1410.84ZM735.888 1410.68V1407.75L733.521 1410.68H735.888Z" fill="#E71D36"/>
</g>
<g opacity="0.67">
<path d="M864.571 1427.49H860.506C860.506 1427.49 860.453 1427.49 860.423 1427.46C860.37 1427.41 860.363 1427.34 860.4 1427.29L864.601 1422.06L864.624 1422.04C864.624 1422.04 864.677 1422.01 864.707 1422.01C864.744 1422.01 864.789 1422.04 864.804 1422.07C864.812 1422.08 864.812 1422.09 864.827 1422.1C864.827 1422.1 864.834 1422.12 864.834 1422.13V1427.24H865.443C865.526 1427.24 865.571 1427.29 865.571 1427.36C865.571 1427.44 865.518 1427.49 865.443 1427.49H864.834V1428.84C864.834 1428.91 864.782 1428.97 864.707 1428.97C864.647 1428.97 864.579 1428.91 864.579 1428.84V1427.49L864.571 1427.49ZM864.571 1427.25V1422.53L860.761 1427.25H864.571Z" fill="#E71D36"/>
<path d="M869.517 1422.01C870.877 1422.02 871.892 1423.61 871.892 1425.49C871.892 1427.36 870.877 1428.97 869.517 1428.98C868.172 1428.98 867.149 1427.38 867.149 1425.49C867.149 1423.59 868.172 1422.02 869.517 1422.01ZM867.397 1425.49C867.397 1426.4 867.653 1427.22 868.044 1427.79C868.427 1428.39 868.961 1428.73 869.517 1428.73C870.073 1428.73 870.614 1428.39 870.997 1427.79C871.396 1427.22 871.644 1426.4 871.644 1425.49C871.644 1424.57 871.396 1423.77 870.997 1423.19C870.606 1422.59 870.08 1422.26 869.517 1422.26C868.953 1422.26 868.435 1422.6 868.044 1423.19C867.653 1423.77 867.397 1424.58 867.397 1425.49Z" fill="#E71D36"/>
</g>
<g opacity="0.87">
<path d="M825.252 1441.77H811.807C811.71 1441.77 811.642 1441.74 811.544 1441.67C811.379 1441.51 811.349 1441.27 811.477 1441.11L825.35 1423.84L825.417 1423.77C825.515 1423.7 825.583 1423.67 825.68 1423.67C825.808 1423.67 825.943 1423.77 826.011 1423.87C826.049 1423.9 826.049 1423.94 826.079 1423.97C826.079 1424 826.116 1424.03 826.116 1424.06V1440.95H828.123C828.386 1440.95 828.551 1441.11 828.551 1441.35C828.551 1441.61 828.386 1441.78 828.123 1441.78H826.116V1446.25C826.116 1446.48 825.951 1446.68 825.688 1446.68C825.493 1446.68 825.26 1446.48 825.26 1446.25V1441.78L825.252 1441.77ZM825.252 1440.95V1425.35L812.656 1440.95H825.252Z" fill="#E71D36"/>
<path d="M844.972 1433.54C846.452 1435.02 847.211 1436.99 847.211 1438.94C847.211 1440.89 846.452 1442.89 844.972 1444.41C843.461 1445.89 841.485 1446.64 839.546 1446.64H839.508C837.569 1446.64 835.593 1445.92 834.112 1444.41C833.947 1444.24 833.947 1443.98 834.112 1443.81C834.278 1443.65 834.541 1443.65 834.706 1443.81C836.051 1445.13 837.765 1445.78 839.508 1445.78C841.252 1445.78 843.025 1445.13 844.341 1443.78C845.686 1442.46 846.347 1440.72 846.347 1438.94C846.347 1437.17 845.686 1435.49 844.378 1434.13C843.033 1432.82 841.319 1432.16 839.576 1432.16C839.411 1432.16 839.283 1432.1 839.185 1431.97C839.185 1431.94 839.147 1431.94 839.147 1431.94C838.982 1431.77 838.982 1431.48 839.147 1431.31L845.791 1424.47H835.63C835.397 1424.47 835.202 1424.27 835.202 1424.04C835.202 1423.81 835.397 1423.61 835.63 1423.61H846.775C847.008 1423.61 847.204 1423.81 847.204 1424.04C847.204 1424.11 847.204 1424.17 847.166 1424.24C847.136 1424.3 847.099 1424.37 847.069 1424.4L840.297 1431.34C842.003 1431.51 843.687 1432.23 844.964 1433.55L844.972 1433.54Z" fill="#E71D36"/>
</g>
<g opacity="0.79">
<path d="M799.076 1416.41L801.812 1413.93C802.157 1413.61 802.345 1413.17 802.345 1412.74C802.345 1412.35 802.195 1411.97 801.925 1411.66C801.609 1411.32 801.165 1411.13 800.737 1411.13C800.346 1411.13 799.963 1411.28 799.655 1411.55L799.369 1411.81C799.317 1411.84 799.264 1411.84 799.226 1411.81C799.189 1411.77 799.196 1411.69 799.234 1411.66L799.52 1411.39C799.873 1411.08 800.301 1410.93 800.745 1410.93C801.241 1410.93 801.729 1411.13 802.09 1411.52C802.398 1411.87 802.556 1412.32 802.556 1412.75C802.556 1413.24 802.353 1413.73 801.947 1414.09L799.407 1416.4H802.458C802.503 1416.4 802.556 1416.44 802.556 1416.5C802.556 1416.56 802.511 1416.6 802.458 1416.6H799.159C799.159 1416.6 799.091 1416.6 799.076 1416.56C799.046 1416.52 799.046 1416.45 799.084 1416.42L799.076 1416.41Z" fill="#5EB0EF"/>
<path d="M803.766 1411.62L804.901 1410.95C804.946 1410.92 805.013 1410.93 805.043 1410.98C805.066 1411.03 805.051 1411.08 804.998 1411.11L803.864 1411.8C803.864 1411.8 803.834 1411.81 803.811 1411.81C803.781 1411.81 803.736 1411.8 803.721 1411.75C803.698 1411.72 803.706 1411.65 803.758 1411.62L803.766 1411.62ZM804.848 1411.03C804.848 1410.98 804.901 1410.93 804.946 1410.93C805.013 1410.93 805.051 1410.98 805.051 1411.03V1416.48C805.051 1416.54 805.013 1416.59 804.946 1416.59C804.901 1416.59 804.848 1416.54 804.848 1416.48V1411.03Z" fill="#5EB0EF"/>
</g>
<g opacity="0.69">
<path d="M813.445 1391.76H810.342C810.342 1391.76 810.304 1391.76 810.281 1391.74C810.244 1391.7 810.236 1391.65 810.266 1391.61L813.468 1387.62L813.483 1387.6C813.483 1387.6 813.521 1387.58 813.543 1387.58C813.573 1387.58 813.603 1387.6 813.618 1387.63C813.626 1387.63 813.626 1387.64 813.633 1387.65V1391.57H814.107C814.167 1391.57 814.204 1391.61 814.204 1391.66C814.204 1391.72 814.167 1391.76 814.107 1391.76H813.641V1392.8C813.641 1392.85 813.603 1392.9 813.543 1392.9C813.498 1392.9 813.445 1392.85 813.445 1392.8V1391.76ZM813.445 1391.57V1387.97L810.537 1391.57H813.445Z" fill="#E71D36"/>
<path d="M815.399 1390.86C815.399 1390.15 815.55 1389.35 815.918 1388.72C816.286 1388.08 816.887 1387.6 817.767 1387.57C817.819 1387.57 817.864 1387.6 817.864 1387.66C817.864 1387.71 817.827 1387.75 817.782 1387.75C816.97 1387.79 816.444 1388.21 816.091 1388.8C815.82 1389.27 815.67 1389.82 815.617 1390.36C815.895 1389.81 816.474 1389.44 817.143 1389.44C818.09 1389.44 818.864 1390.21 818.871 1391.15C818.864 1392.11 818.09 1392.88 817.143 1392.88C816.256 1392.88 815.527 1392.21 815.437 1391.35C815.429 1391.34 815.429 1391.33 815.429 1391.32C815.414 1391.17 815.407 1391.01 815.407 1390.84L815.399 1390.86ZM817.135 1392.7C817.985 1392.7 818.661 1392.02 818.676 1391.17C818.661 1390.33 817.985 1389.64 817.135 1389.64C816.286 1389.64 815.61 1390.33 815.61 1391.17C815.61 1392.01 816.294 1392.7 817.135 1392.7Z" fill="#E71D36"/>
</g>
<g opacity="0.91">
<path d="M699.003 1386.57H692.134C692.081 1386.57 692.051 1386.55 691.998 1386.51C691.916 1386.43 691.901 1386.31 691.968 1386.23L699.055 1377.41L699.093 1377.38C699.146 1377.34 699.176 1377.32 699.228 1377.32C699.296 1377.32 699.363 1377.38 699.394 1377.42C699.409 1377.44 699.409 1377.45 699.431 1377.47C699.431 1377.49 699.446 1377.51 699.446 1377.53V1386.14H700.468C700.603 1386.14 700.686 1386.23 700.686 1386.35C700.686 1386.48 700.603 1386.57 700.468 1386.57H699.446V1388.85C699.446 1388.97 699.363 1389.07 699.228 1389.07C699.13 1389.07 699.01 1388.97 699.01 1388.85V1386.57H699.003ZM699.003 1386.14V1378.18L692.57 1386.14H699.003Z" fill="#E71D36"/>
<path d="M704.466 1388.77L709.081 1377.76H702.888C702.768 1377.76 702.67 1377.66 702.67 1377.54C702.67 1377.44 702.768 1377.32 702.888 1377.32H709.434C709.531 1377.32 709.652 1377.44 709.652 1377.54C709.652 1377.59 709.622 1377.64 709.584 1377.69L704.849 1388.93C704.834 1389 704.752 1389.07 704.662 1389.07C704.647 1389.07 704.609 1389.05 704.579 1389.05C704.459 1389 704.429 1388.89 704.459 1388.77H704.466Z" fill="#E71D36"/>
</g>
<g opacity="0.88">
<path d="M1075.56 1325.8C1075.43 1325.7 1075.43 1325.47 1075.56 1325.33C1075.68 1325.2 1075.89 1325.2 1076.03 1325.33C1077.1 1326.38 1078.49 1326.93 1079.85 1326.93C1081.21 1326.93 1082.64 1326.38 1083.69 1325.33C1084.77 1324.26 1085.29 1322.87 1085.29 1321.49C1085.29 1320.1 1084.76 1318.71 1083.69 1317.67C1082.65 1316.62 1081.29 1316.1 1079.88 1316.1C1078.46 1316.1 1077.13 1316.61 1076.04 1317.67C1075.95 1317.8 1075.72 1317.8 1075.59 1317.69C1075.56 1317.69 1075.54 1317.67 1075.54 1317.64C1075.52 1317.64 1075.52 1317.61 1075.52 1317.61C1075.47 1317.53 1075.44 1317.46 1075.47 1317.35L1076.38 1309.64C1076.4 1309.48 1076.51 1309.37 1076.61 1309.35C1076.67 1309.33 1076.72 1309.3 1076.8 1309.3H1085.64C1085.82 1309.3 1085.98 1309.48 1085.98 1309.64C1085.98 1309.85 1085.82 1309.97 1085.64 1309.97H1077.04L1076.25 1316.62C1077.33 1315.81 1078.61 1315.42 1079.88 1315.42C1081.45 1315.42 1083.03 1316 1084.23 1317.2C1085.38 1318.38 1085.98 1319.92 1085.98 1321.5C1085.98 1323.07 1085.38 1324.64 1084.18 1325.81C1083 1327.02 1081.43 1327.62 1079.86 1327.62C1078.29 1327.62 1076.77 1327.02 1075.57 1325.81L1075.56 1325.8Z" fill="#5EB0EF"/>
<path d="M1097.6 1309.29C1101.18 1309.31 1103.85 1313.5 1103.85 1318.42C1103.85 1323.34 1101.18 1327.58 1097.6 1327.6C1094.07 1327.58 1091.38 1323.39 1091.38 1318.42C1091.38 1313.45 1094.07 1309.31 1097.6 1309.29ZM1092.03 1318.42C1092.03 1320.83 1092.71 1322.97 1093.73 1324.49C1094.75 1326.06 1096.13 1326.95 1097.6 1326.95C1099.06 1326.95 1100.48 1326.06 1101.5 1324.49C1102.54 1322.97 1103.2 1320.83 1103.2 1318.42C1103.2 1316.01 1102.54 1313.92 1101.5 1312.4C1100.48 1310.83 1099.09 1309.94 1097.6 1309.94C1096.1 1309.94 1094.75 1310.83 1093.73 1312.4C1092.71 1313.92 1092.03 1316.07 1092.03 1318.42Z" fill="#5EB0EF"/>
</g>
<g opacity="0.53">
<path d="M1099.03 1291.81L1100.75 1290.8C1100.82 1290.74 1100.92 1290.76 1100.96 1290.85C1101 1290.92 1100.98 1291.01 1100.89 1291.06L1099.18 1292.09C1099.18 1292.09 1099.13 1292.1 1099.1 1292.1C1099.06 1292.1 1098.99 1292.09 1098.97 1292.03C1098.93 1291.97 1098.94 1291.87 1099.03 1291.82L1099.03 1291.81ZM1100.67 1290.92C1100.67 1290.84 1100.75 1290.76 1100.82 1290.76C1100.92 1290.76 1100.98 1290.84 1100.98 1290.92V1299.17C1100.98 1299.25 1100.92 1299.32 1100.82 1299.32C1100.75 1299.32 1100.67 1299.25 1100.67 1299.17V1290.92Z" fill="#5EB0EF"/>
<path d="M1104.76 1299.11L1108.13 1291.07H1103.6C1103.52 1291.07 1103.44 1291 1103.44 1290.92C1103.44 1290.84 1103.52 1290.76 1103.6 1290.76H1108.38C1108.46 1290.76 1108.54 1290.84 1108.54 1290.92C1108.54 1290.95 1108.52 1290.99 1108.49 1291.03L1105.03 1299.23C1105.01 1299.28 1104.95 1299.33 1104.89 1299.33C1104.88 1299.33 1104.86 1299.32 1104.83 1299.32C1104.75 1299.28 1104.72 1299.2 1104.75 1299.11H1104.76Z" fill="#5EB0EF"/>
</g>
<g opacity="0.5">
<path d="M1069 1253.73C1068.94 1253.67 1068.94 1253.57 1069 1253.51C1069.06 1253.45 1069.16 1253.45 1069.22 1253.51C1069.72 1253.99 1070.35 1254.24 1070.98 1254.24C1071.6 1254.24 1072.27 1253.99 1072.75 1253.51C1073.25 1253.01 1073.49 1252.37 1073.49 1251.73C1073.49 1251.09 1073.25 1250.45 1072.75 1249.97C1072.27 1249.49 1071.64 1249.25 1070.99 1249.25C1070.35 1249.25 1069.73 1249.49 1069.22 1249.97C1069.18 1250.03 1069.08 1250.03 1069.02 1249.99C1069 1249.99 1068.99 1249.97 1068.99 1249.97C1068.98 1249.96 1068.98 1249.95 1068.98 1249.95C1068.96 1249.91 1068.94 1249.87 1068.96 1249.83L1069.38 1246.27C1069.39 1246.2 1069.44 1246.15 1069.48 1246.14C1069.51 1246.12 1069.53 1246.11 1069.57 1246.11H1073.65C1073.73 1246.11 1073.8 1246.2 1073.8 1246.27C1073.8 1246.37 1073.73 1246.43 1073.65 1246.43H1069.68L1069.32 1249.5C1069.81 1249.12 1070.4 1248.94 1070.99 1248.94C1071.72 1248.94 1072.44 1249.21 1072.99 1249.76C1073.53 1250.3 1073.8 1251.02 1073.8 1251.74C1073.8 1252.46 1073.52 1253.19 1072.96 1253.73C1072.42 1254.29 1071.69 1254.57 1070.97 1254.57C1070.25 1254.57 1069.55 1254.29 1068.99 1253.73L1069 1253.73Z" fill="#E71D36"/>
<path d="M1075.97 1247.16L1077.66 1246.16C1077.74 1246.11 1077.83 1246.12 1077.88 1246.2C1077.92 1246.28 1077.89 1246.36 1077.8 1246.41L1076.12 1247.42C1076.12 1247.42 1076.07 1247.44 1076.04 1247.44C1076 1247.44 1075.93 1247.42 1075.91 1247.36C1075.87 1247.3 1075.89 1247.21 1075.97 1247.16ZM1077.58 1246.28C1077.58 1246.2 1077.66 1246.12 1077.73 1246.12C1077.83 1246.12 1077.89 1246.2 1077.89 1246.28V1254.4C1077.89 1254.49 1077.83 1254.56 1077.73 1254.56C1077.65 1254.56 1077.58 1254.49 1077.58 1254.4V1246.28Z" fill="#E71D36"/>
</g>
<g opacity="0.74">
<path d="M1105.93 1230.8C1106.58 1230.8 1107.11 1231.34 1107.11 1231.99C1107.11 1232.46 1106.83 1232.87 1106.44 1233.06C1107.24 1233.28 1107.84 1234.01 1107.84 1234.89C1107.84 1235.94 1106.98 1236.79 1105.93 1236.79C1104.88 1236.79 1104.02 1235.94 1104.02 1234.89C1104.02 1234.01 1104.62 1233.28 1105.43 1233.06C1105.02 1232.87 1104.74 1232.46 1104.74 1231.99C1104.74 1231.33 1105.28 1230.8 1105.93 1230.8ZM1105.93 1236.58C1106.87 1236.58 1107.62 1235.83 1107.62 1234.89C1107.62 1233.96 1106.87 1233.2 1105.93 1233.2C1104.99 1233.2 1104.24 1233.96 1104.24 1234.89C1104.24 1235.83 1105 1236.58 1105.93 1236.58ZM1105.93 1232.96C1106.47 1232.96 1106.9 1232.52 1106.9 1231.99C1106.9 1231.46 1106.47 1231.02 1105.93 1231.01C1105.39 1231.02 1104.96 1231.45 1104.96 1231.99C1104.96 1232.53 1105.39 1232.96 1105.93 1232.96Z" fill="#5EB0EF"/>
<path d="M1110.32 1236.64L1112.67 1231.04H1109.52C1109.46 1231.04 1109.4 1230.98 1109.4 1230.92C1109.4 1230.87 1109.46 1230.81 1109.52 1230.81H1112.85C1112.91 1230.81 1112.97 1230.87 1112.97 1230.92C1112.97 1230.95 1112.95 1230.98 1112.94 1231L1110.52 1236.72C1110.52 1236.72 1110.47 1236.79 1110.43 1236.79C1110.42 1236.79 1110.4 1236.78 1110.38 1236.78C1110.32 1236.76 1110.31 1236.7 1110.32 1236.64Z" fill="#5EB0EF"/>
</g>
<g opacity="0.87">
<path d="M1068.78 1329.96C1069.93 1331.12 1070.53 1332.67 1070.53 1334.2C1070.53 1335.72 1069.93 1337.3 1068.78 1338.48C1067.59 1339.64 1066.04 1340.23 1064.52 1340.23H1064.5C1062.98 1340.23 1061.43 1339.67 1060.28 1338.48C1060.15 1338.35 1060.15 1338.14 1060.28 1338.01C1060.4 1337.88 1060.62 1337.88 1060.74 1338.01C1061.8 1339.04 1063.14 1339.56 1064.51 1339.56C1065.88 1339.56 1067.27 1339.04 1068.3 1337.99C1069.36 1336.96 1069.87 1335.59 1069.87 1334.2C1069.87 1332.81 1069.35 1331.49 1068.32 1330.43C1067.26 1329.4 1065.92 1328.88 1064.55 1328.88C1064.43 1328.88 1064.32 1328.83 1064.24 1328.72C1064.24 1328.7 1064.22 1328.7 1064.22 1328.7C1064.09 1328.57 1064.09 1328.34 1064.22 1328.21L1069.43 1322.84H1061.46C1061.28 1322.84 1061.13 1322.68 1061.13 1322.51C1061.13 1322.34 1061.28 1322.17 1061.46 1322.17H1070.2C1070.38 1322.17 1070.54 1322.33 1070.54 1322.51C1070.54 1322.56 1070.54 1322.62 1070.52 1322.66C1070.5 1322.71 1070.47 1322.77 1070.45 1322.79L1065.13 1328.23C1066.47 1328.36 1067.78 1328.93 1068.79 1329.96H1068.78Z" fill="#E71D36"/>
<path d="M1075.3 1339.67L1084.02 1331.8C1085.13 1330.77 1085.72 1329.37 1085.72 1327.98C1085.72 1326.77 1085.26 1325.53 1084.39 1324.55C1083.38 1323.44 1081.97 1322.84 1080.6 1322.84C1079.36 1322.84 1078.13 1323.31 1077.14 1324.18L1076.24 1325.01C1076.09 1325.14 1075.9 1325.14 1075.77 1325.01C1075.65 1324.88 1075.67 1324.65 1075.8 1324.54L1076.7 1323.69C1077.83 1322.68 1079.2 1322.19 1080.59 1322.19C1082.16 1322.19 1083.74 1322.84 1084.87 1324.08C1085.84 1325.19 1086.36 1326.6 1086.36 1327.97C1086.36 1329.54 1085.72 1331.12 1084.43 1332.26L1076.34 1339.61H1086.05C1086.21 1339.61 1086.36 1339.74 1086.36 1339.92C1086.36 1340.1 1086.2 1340.26 1086.05 1340.26H1075.53C1075.43 1340.26 1075.32 1340.24 1075.28 1340.13C1075.17 1340 1075.17 1339.79 1075.3 1339.67Z" fill="#E71D36"/>
</g>
<g opacity="0.59">
<path d="M997.006 1350.35H995.909C995.909 1350.35 995.894 1350.35 995.886 1350.35C995.871 1350.33 995.871 1350.32 995.886 1350.3L997.021 1348.89C997.021 1348.89 997.036 1348.88 997.044 1348.88C997.059 1348.88 997.066 1348.89 997.074 1348.89V1348.91V1350.29H997.239C997.239 1350.29 997.277 1350.3 997.277 1350.32C997.277 1350.34 997.262 1350.35 997.239 1350.35H997.074V1350.72C997.074 1350.72 997.059 1350.76 997.036 1350.76C997.021 1350.76 996.999 1350.74 996.999 1350.72V1350.35H997.006ZM997.006 1350.29V1349.01L995.976 1350.29H997.006Z" fill="#E71D36"/>
<path d="M997.57 1349.1L997.945 1348.88C997.945 1348.88 997.983 1348.87 997.991 1348.89C997.998 1348.91 997.991 1348.93 997.976 1348.94L997.6 1349.16H997.585C997.577 1349.16 997.562 1349.16 997.555 1349.15C997.547 1349.13 997.555 1349.11 997.57 1349.1ZM997.93 1348.91C997.93 1348.91 997.945 1348.87 997.961 1348.87C997.983 1348.87 997.998 1348.89 997.998 1348.91V1350.71C997.998 1350.71 997.983 1350.75 997.961 1350.75C997.945 1350.75 997.93 1350.74 997.93 1350.71V1348.91Z" fill="#E71D36"/>
</g>
<g opacity="0.73">
<path d="M1032.58 1394.41C1032.73 1394.56 1032.81 1394.76 1032.81 1394.96C1032.81 1395.15 1032.73 1395.36 1032.58 1395.52C1032.43 1395.67 1032.23 1395.74 1032.03 1395.74C1031.84 1395.74 1031.64 1395.67 1031.49 1395.52C1031.47 1395.5 1031.47 1395.47 1031.49 1395.46C1031.5 1395.44 1031.53 1395.44 1031.55 1395.46C1031.68 1395.59 1031.85 1395.66 1032.03 1395.66C1032.21 1395.66 1032.4 1395.59 1032.53 1395.46C1032.67 1395.32 1032.73 1395.15 1032.73 1394.97C1032.73 1394.79 1032.67 1394.61 1032.53 1394.48C1032.4 1394.34 1032.22 1394.27 1032.04 1394.27C1032.03 1394.27 1032.01 1394.27 1032 1394.25C1032 1394.25 1031.98 1394.2 1032 1394.18L1032.67 1393.48H1031.64C1031.64 1393.48 1031.59 1393.46 1031.59 1393.44C1031.59 1393.42 1031.61 1393.39 1031.64 1393.39H1032.77C1032.77 1393.39 1032.82 1393.42 1032.82 1393.44C1032.82 1393.44 1032.82 1393.45 1032.82 1393.46C1032.82 1393.46 1032.82 1393.48 1032.81 1393.48L1032.12 1394.18C1032.29 1394.2 1032.46 1394.27 1032.59 1394.41H1032.58Z" fill="#E71D36"/>
<path d="M1034.28 1393.4C1034.74 1393.4 1035.08 1393.94 1035.08 1394.58C1035.08 1395.21 1034.74 1395.75 1034.28 1395.75C1033.83 1395.75 1033.49 1395.21 1033.49 1394.58C1033.49 1393.94 1033.83 1393.41 1034.28 1393.4ZM1033.57 1394.58C1033.57 1394.88 1033.66 1395.16 1033.79 1395.36C1033.91 1395.56 1034.09 1395.67 1034.28 1395.67C1034.47 1395.67 1034.65 1395.56 1034.78 1395.36C1034.91 1395.16 1035 1394.88 1035 1394.58C1035 1394.27 1034.91 1394 1034.78 1393.81C1034.65 1393.61 1034.47 1393.49 1034.28 1393.49C1034.09 1393.49 1033.91 1393.61 1033.79 1393.81C1033.66 1394 1033.57 1394.27 1033.57 1394.58Z" fill="#E71D36"/>
</g>
<g opacity="0.54">
<path d="M1051.96 1358.38L1057.08 1353.76C1057.74 1353.15 1058.08 1352.34 1058.08 1351.52C1058.08 1350.8 1057.81 1350.08 1057.29 1349.5C1056.71 1348.85 1055.87 1348.5 1055.07 1348.5C1054.34 1348.5 1053.61 1348.77 1053.04 1349.29L1052.51 1349.77C1052.42 1349.85 1052.31 1349.85 1052.24 1349.77C1052.16 1349.7 1052.18 1349.56 1052.25 1349.5L1052.78 1349.01C1053.45 1348.41 1054.25 1348.13 1055.07 1348.13C1055.99 1348.13 1056.92 1348.5 1057.58 1349.23C1058.16 1349.88 1058.46 1350.71 1058.46 1351.52C1058.46 1352.44 1058.08 1353.37 1057.32 1354.03L1052.57 1358.35H1058.27C1058.36 1358.35 1058.45 1358.42 1058.45 1358.53C1058.45 1358.63 1058.36 1358.72 1058.27 1358.72H1052.09C1052.03 1358.72 1051.97 1358.71 1051.94 1358.65C1051.88 1358.57 1051.88 1358.45 1051.96 1358.38H1051.96Z" fill="#5EB0EF"/>
<path d="M1060.9 1358.38L1066.02 1353.76C1066.67 1353.15 1067.02 1352.34 1067.02 1351.52C1067.02 1350.8 1066.75 1350.08 1066.23 1349.5C1065.64 1348.85 1064.81 1348.5 1064 1348.5C1063.28 1348.5 1062.55 1348.77 1061.98 1349.29L1061.44 1349.77C1061.35 1349.85 1061.25 1349.85 1061.17 1349.77C1061.1 1349.7 1061.11 1349.56 1061.19 1349.5L1061.71 1349.01C1062.37 1348.41 1063.18 1348.13 1064 1348.13C1064.92 1348.13 1065.85 1348.5 1066.51 1349.23C1067.09 1349.88 1067.39 1350.71 1067.39 1351.52C1067.39 1352.44 1067.01 1353.37 1066.25 1354.03L1061.5 1358.35H1067.21C1067.3 1358.35 1067.39 1358.42 1067.39 1358.53C1067.39 1358.63 1067.3 1358.72 1067.21 1358.72H1061.03C1060.97 1358.72 1060.91 1358.71 1060.88 1358.65C1060.82 1358.57 1060.82 1358.45 1060.89 1358.38H1060.9Z" fill="#5EB0EF"/>
</g>
<g opacity="0.93">
<path d="M879.166 1364.3C879.068 1364.22 879.068 1364.04 879.166 1363.94C879.271 1363.84 879.429 1363.84 879.527 1363.94C880.353 1364.75 881.42 1365.18 882.473 1365.18C883.525 1365.18 884.629 1364.76 885.441 1363.94C886.268 1363.12 886.674 1362.05 886.674 1360.97C886.674 1359.9 886.268 1358.84 885.441 1358.02C884.637 1357.22 883.585 1356.81 882.495 1356.81C881.405 1356.81 880.376 1357.22 879.527 1358.02C879.466 1358.12 879.286 1358.12 879.181 1358.05C879.158 1358.05 879.143 1358.02 879.143 1358.01C879.121 1358.01 879.121 1357.99 879.121 1357.99C879.083 1357.93 879.061 1357.87 879.083 1357.78L879.79 1351.83C879.812 1351.71 879.887 1351.62 879.97 1351.6C880.008 1351.58 880.053 1351.56 880.113 1351.56H886.929C887.072 1351.56 887.192 1351.71 887.192 1351.83C887.192 1351.98 887.072 1352.09 886.929 1352.09H880.293L879.684 1357.22C880.511 1356.6 881.503 1356.29 882.488 1356.29C883.698 1356.29 884.908 1356.73 885.832 1357.66C886.719 1358.57 887.185 1359.75 887.185 1360.96C887.185 1362.18 886.719 1363.39 885.794 1364.3C884.885 1365.23 883.675 1365.69 882.465 1365.69C881.255 1365.69 880.083 1365.22 879.158 1364.3L879.166 1364.3Z" fill="#5EB0EF"/>
</g>
<g opacity="0.83">
<path d="M894.332 1375.44C895.136 1376.25 895.55 1377.32 895.55 1378.38C895.55 1379.43 895.136 1380.53 894.332 1381.35C893.513 1382.15 892.438 1382.56 891.379 1382.56H891.364C890.312 1382.56 889.237 1382.17 888.433 1381.35C888.343 1381.26 888.343 1381.11 888.433 1381.02C888.523 1380.93 888.666 1380.93 888.756 1381.02C889.492 1381.74 890.417 1382.1 891.371 1382.1C892.326 1382.1 893.288 1381.74 894.002 1381.01C894.738 1380.29 895.091 1379.35 895.091 1378.38C895.091 1377.41 894.731 1376.5 894.017 1375.76C893.28 1375.04 892.356 1374.68 891.401 1374.68C891.311 1374.68 891.244 1374.65 891.183 1374.58C891.183 1374.56 891.168 1374.56 891.168 1374.56C891.078 1374.47 891.078 1374.31 891.168 1374.22L894.783 1370.5H889.252C889.124 1370.5 889.019 1370.4 889.019 1370.27C889.019 1370.14 889.124 1370.03 889.252 1370.03H895.317C895.445 1370.03 895.55 1370.14 895.55 1370.27C895.55 1370.31 895.55 1370.34 895.535 1370.37C895.52 1370.41 895.497 1370.45 895.482 1370.46L891.8 1374.24C892.732 1374.33 893.641 1374.72 894.34 1375.44H894.332Z" fill="#5EB0EF"/>
</g>
<g opacity="0.54">
<path d="M942.513 1320.12C943.813 1320.12 944.872 1321.18 944.872 1322.5C944.872 1323.44 944.309 1324.26 943.52 1324.65C945.128 1325.09 946.323 1326.56 946.323 1328.31C946.323 1330.42 944.609 1332.11 942.505 1332.11C940.401 1332.11 938.687 1330.42 938.687 1328.31C938.687 1326.56 939.882 1325.09 941.49 1324.65C940.671 1324.27 940.123 1323.44 940.123 1322.5C940.123 1321.18 941.182 1320.12 942.505 1320.12H942.513ZM942.513 1331.68C944.399 1331.68 945.902 1330.17 945.902 1328.3C945.902 1326.43 944.399 1324.91 942.513 1324.91C940.626 1324.91 939.123 1326.44 939.123 1328.3C939.123 1330.17 940.649 1331.68 942.513 1331.68ZM942.513 1324.44C943.572 1324.44 944.444 1323.56 944.444 1322.5C944.444 1321.44 943.572 1320.57 942.513 1320.55C941.43 1320.56 940.559 1321.42 940.559 1322.5C940.559 1323.59 941.43 1324.44 942.513 1324.44Z" fill="#E71D36"/>
<path d="M953.725 1320.12C956.07 1320.13 957.814 1322.88 957.814 1326.1C957.814 1329.32 956.07 1332.09 953.725 1332.11C951.418 1332.09 949.652 1329.35 949.652 1326.1C949.652 1322.85 951.418 1320.13 953.725 1320.12ZM950.08 1326.1C950.08 1327.67 950.524 1329.08 951.193 1330.07C951.862 1331.1 952.763 1331.68 953.725 1331.68C954.687 1331.68 955.612 1331.1 956.273 1330.07C956.957 1329.08 957.385 1327.67 957.385 1326.1C957.385 1324.53 956.957 1323.15 956.273 1322.16C955.604 1321.13 954.695 1320.55 953.725 1320.55C952.756 1320.55 951.862 1321.13 951.193 1322.16C950.524 1323.15 950.08 1324.56 950.08 1326.1Z" fill="#E71D36"/>
</g>
<g opacity="0.77">
<path d="M910.347 1434.57L912.482 1429.48H909.618C909.566 1429.48 909.521 1429.43 909.521 1429.37C909.521 1429.33 909.566 1429.28 909.618 1429.28H912.647C912.692 1429.28 912.745 1429.33 912.745 1429.37C912.745 1429.4 912.73 1429.42 912.714 1429.44L910.528 1434.64C910.528 1434.64 910.482 1434.7 910.445 1434.7C910.437 1434.7 910.422 1434.7 910.407 1434.7C910.355 1434.68 910.34 1434.62 910.355 1434.56L910.347 1434.57Z" fill="#E71D36"/>
<path d="M914.902 1434.57L917.036 1429.48H914.173C914.12 1429.48 914.075 1429.43 914.075 1429.37C914.075 1429.33 914.12 1429.28 914.173 1429.28H917.201C917.247 1429.28 917.299 1429.33 917.299 1429.37C917.299 1429.4 917.284 1429.42 917.269 1429.44L915.082 1434.64C915.082 1434.64 915.037 1434.7 915 1434.7C914.992 1434.7 914.977 1434.7 914.962 1434.7C914.909 1434.68 914.894 1434.62 914.909 1434.56L914.902 1434.57Z" fill="#E71D36"/>
</g>
<g opacity="0.83">
<path d="M885.359 1364.09C885.359 1361.64 885.878 1358.91 887.126 1356.75C888.373 1354.57 890.447 1352.96 893.438 1352.83C893.619 1352.83 893.777 1352.95 893.777 1353.17C893.777 1353.32 893.649 1353.47 893.491 1353.47C890.71 1353.6 888.922 1355.03 887.697 1357.06C886.765 1358.65 886.269 1360.54 886.088 1362.42C887.05 1360.52 889.027 1359.24 891.312 1359.24C894.558 1359.24 897.211 1361.87 897.234 1365.12C897.211 1368.39 894.558 1371.02 891.312 1371.02C888.268 1371.02 885.78 1368.76 885.465 1365.79C885.442 1365.76 885.442 1365.72 885.442 1365.69C885.389 1365.17 885.359 1364.62 885.359 1364.08V1364.09ZM891.312 1370.37C894.22 1370.37 896.535 1368.03 896.58 1365.12C896.527 1362.24 894.212 1359.9 891.312 1359.9C888.411 1359.9 886.088 1362.24 886.088 1365.12C886.088 1368.01 888.426 1370.37 891.312 1370.37Z" fill="#5EB0EF"/>
</g>
<g opacity="0.71">
<path d="M789.606 1421.74C789.606 1418.66 790.26 1415.23 791.831 1412.5C793.402 1409.75 796.017 1407.72 799.782 1407.56C800.008 1407.56 800.21 1407.73 800.21 1407.99C800.21 1408.19 800.045 1408.38 799.85 1408.38C796.348 1408.55 794.093 1410.35 792.552 1412.9C791.372 1414.9 790.749 1417.29 790.523 1419.65C791.733 1417.26 794.221 1415.66 797.099 1415.66C801.187 1415.66 804.524 1418.97 804.562 1423.06C804.532 1427.18 801.195 1430.49 797.099 1430.49C793.274 1430.49 790.132 1427.64 789.734 1423.91C789.704 1423.87 789.704 1423.81 789.704 1423.78C789.637 1423.12 789.606 1422.43 789.606 1421.75V1421.74ZM797.099 1429.67C800.767 1429.67 803.675 1426.72 803.743 1423.06C803.675 1419.42 800.767 1416.48 797.099 1416.48C793.432 1416.48 790.523 1419.42 790.523 1423.06C790.523 1426.69 793.469 1429.67 797.099 1429.67Z" fill="#E71D36"/>
<path d="M818.435 1407.56C822.914 1407.59 826.258 1412.84 826.258 1419C826.258 1425.15 822.922 1430.46 818.435 1430.49C814.016 1430.46 810.649 1425.21 810.649 1419C810.649 1412.78 814.024 1407.6 818.435 1407.56ZM811.468 1419C811.468 1422.01 812.318 1424.7 813.595 1426.59C814.873 1428.55 816.609 1429.67 818.435 1429.67C820.261 1429.67 822.035 1428.55 823.312 1426.59C824.62 1424.7 825.439 1422.01 825.439 1419C825.439 1415.98 824.62 1413.36 823.312 1411.47C822.035 1409.5 820.299 1408.39 818.435 1408.39C816.571 1408.39 814.865 1409.5 813.595 1411.47C812.318 1413.36 811.468 1416.05 811.468 1419Z" fill="#E71D36"/>
</g>
<g opacity="0.61">
<path d="M789.539 1413.35L792.613 1410.58C793.004 1410.22 793.214 1409.72 793.214 1409.23C793.214 1408.8 793.049 1408.37 792.741 1408.02C792.387 1407.63 791.884 1407.42 791.403 1407.42C790.967 1407.42 790.531 1407.59 790.185 1407.89L789.87 1408.19C789.817 1408.23 789.75 1408.23 789.705 1408.19C789.66 1408.14 789.667 1408.06 789.712 1408.02L790.028 1407.72C790.426 1407.37 790.907 1407.19 791.403 1407.19C791.959 1407.19 792.515 1407.42 792.914 1407.86C793.259 1408.25 793.44 1408.75 793.44 1409.23C793.44 1409.79 793.214 1410.35 792.756 1410.74L789.9 1413.34H793.327C793.38 1413.34 793.432 1413.38 793.432 1413.44C793.432 1413.5 793.38 1413.57 793.327 1413.57H789.614C789.614 1413.57 789.539 1413.56 789.524 1413.52C789.487 1413.47 789.487 1413.4 789.532 1413.35H789.539Z" fill="#5EB0EF"/>
<path d="M798.46 1412.21H794.74C794.74 1412.21 794.695 1412.2 794.665 1412.19C794.62 1412.14 794.612 1412.08 794.65 1412.03L798.49 1407.26L798.505 1407.24C798.505 1407.24 798.55 1407.21 798.58 1407.21C798.618 1407.21 798.655 1407.24 798.67 1407.26C798.678 1407.27 798.678 1407.28 798.685 1407.29C798.685 1407.29 798.693 1407.31 798.693 1407.32V1411.99H799.249C799.324 1411.99 799.369 1412.04 799.369 1412.1C799.369 1412.17 799.324 1412.22 799.249 1412.22H798.693V1413.46C798.693 1413.52 798.648 1413.58 798.573 1413.58C798.52 1413.58 798.452 1413.53 798.452 1413.46V1412.22L798.46 1412.21ZM798.46 1411.99V1407.67L794.98 1411.99H798.46Z" fill="#5EB0EF"/>
</g>
<g opacity="0.98">
<path d="M747.521 1261.78L750.474 1259.11C750.85 1258.76 751.053 1258.29 751.053 1257.82C751.053 1257.4 750.895 1256.99 750.602 1256.65C750.264 1256.28 749.783 1256.07 749.317 1256.07C748.896 1256.07 748.475 1256.23 748.145 1256.52L747.837 1256.8C747.784 1256.85 747.724 1256.85 747.679 1256.8C747.634 1256.76 747.641 1256.68 747.686 1256.64L747.994 1256.36C748.378 1256.02 748.844 1255.85 749.317 1255.85C749.851 1255.85 750.384 1256.07 750.767 1256.49C751.098 1256.87 751.271 1257.35 751.271 1257.82C751.271 1258.35 751.053 1258.88 750.617 1259.27L747.874 1261.76H751.166C751.218 1261.76 751.271 1261.8 751.271 1261.86C751.271 1261.92 751.218 1261.98 751.166 1261.98H747.596C747.596 1261.98 747.528 1261.97 747.506 1261.93C747.468 1261.89 747.468 1261.82 747.513 1261.77L747.521 1261.78Z" fill="#E71D36"/>
</g>
<g opacity="0.82">
<path d="M822.216 1337.43L823.599 1336.18C823.772 1336.02 823.869 1335.8 823.869 1335.57C823.869 1335.38 823.794 1335.19 823.659 1335.03C823.501 1334.85 823.276 1334.76 823.058 1334.76C822.862 1334.76 822.667 1334.84 822.509 1334.97L822.366 1335.1C822.366 1335.1 822.314 1335.12 822.291 1335.1C822.269 1335.08 822.276 1335.04 822.291 1335.03L822.434 1334.89C822.614 1334.73 822.832 1334.65 823.05 1334.65C823.298 1334.65 823.546 1334.75 823.727 1334.95C823.884 1335.12 823.967 1335.35 823.967 1335.57C823.967 1335.82 823.862 1336.06 823.659 1336.24L822.374 1337.41H823.914C823.914 1337.41 823.967 1337.43 823.967 1337.45C823.967 1337.48 823.944 1337.51 823.914 1337.51H822.246C822.246 1337.51 822.216 1337.51 822.209 1337.48C822.194 1337.46 822.194 1337.43 822.209 1337.41L822.216 1337.43Z" fill="#E71D36"/>
</g>
<g opacity="0.93">
<path d="M797.265 1390.98C797.198 1390.93 797.198 1390.81 797.265 1390.74C797.333 1390.67 797.438 1390.67 797.506 1390.74C798.047 1391.27 798.753 1391.55 799.445 1391.55C800.136 1391.55 800.865 1391.27 801.391 1390.74C801.932 1390.2 802.203 1389.49 802.203 1388.79C802.203 1388.09 801.94 1387.39 801.391 1386.85C800.865 1386.32 800.174 1386.05 799.452 1386.05C798.731 1386.05 798.062 1386.32 797.506 1386.85C797.468 1386.92 797.348 1386.92 797.28 1386.87C797.265 1386.87 797.258 1386.85 797.258 1386.84C797.243 1386.84 797.243 1386.83 797.243 1386.83C797.213 1386.79 797.205 1386.75 797.213 1386.69L797.679 1382.78C797.694 1382.7 797.746 1382.65 797.799 1382.64C797.821 1382.62 797.851 1382.61 797.889 1382.61H802.376C802.466 1382.61 802.549 1382.7 802.549 1382.78C802.549 1382.89 802.466 1382.96 802.376 1382.96H798.009L797.611 1386.32C798.152 1385.91 798.806 1385.72 799.452 1385.72C800.249 1385.72 801.045 1386.01 801.654 1386.62C802.24 1387.21 802.541 1388 802.541 1388.79C802.541 1389.58 802.233 1390.39 801.624 1390.98C801.03 1391.59 800.234 1391.9 799.437 1391.9C798.641 1391.9 797.874 1391.59 797.265 1390.98Z" fill="#E71D36"/>
<path d="M809.741 1386.6C810.342 1387.19 810.643 1387.99 810.643 1388.77C810.643 1389.55 810.342 1390.36 809.741 1390.97C809.132 1391.57 808.336 1391.88 807.554 1391.88H807.539C806.757 1391.88 805.961 1391.58 805.367 1390.97C805.299 1390.91 805.299 1390.8 805.367 1390.73C805.435 1390.66 805.54 1390.66 805.608 1390.73C806.149 1391.27 806.84 1391.53 807.547 1391.53C808.253 1391.53 808.967 1391.27 809.493 1390.72C810.034 1390.19 810.305 1389.49 810.305 1388.77C810.305 1388.05 810.042 1387.38 809.508 1386.83C808.967 1386.29 808.275 1386.03 807.569 1386.03C807.501 1386.03 807.449 1386 807.411 1385.95C807.411 1385.93 807.396 1385.93 807.396 1385.93C807.329 1385.87 807.329 1385.75 807.396 1385.69L810.072 1382.93H805.976C805.886 1382.93 805.803 1382.84 805.803 1382.75C805.803 1382.66 805.886 1382.58 805.976 1382.58H810.47C810.56 1382.58 810.643 1382.66 810.643 1382.75C810.643 1382.78 810.643 1382.81 810.628 1382.84C810.613 1382.86 810.605 1382.89 810.59 1382.9L807.862 1385.7C808.554 1385.77 809.23 1386.06 809.749 1386.59L809.741 1386.6Z" fill="#E71D36"/>
</g>
<g opacity="0.59">
<path d="M655.008 1288.79C655.008 1288.79 654.978 1288.7 655.008 1288.67C655.046 1288.64 655.098 1288.64 655.128 1288.67C655.406 1288.94 655.767 1289.08 656.12 1289.08C656.474 1289.08 656.849 1288.94 657.12 1288.67C657.398 1288.39 657.533 1288.03 657.533 1287.67C657.533 1287.31 657.398 1286.94 657.12 1286.67C656.849 1286.4 656.496 1286.27 656.12 1286.27C655.745 1286.27 655.406 1286.4 655.121 1286.67C655.098 1286.7 655.038 1286.7 655.008 1286.67L654.993 1286.66C654.993 1286.66 654.971 1286.61 654.978 1286.58L655.219 1284.57C655.219 1284.53 655.249 1284.5 655.279 1284.49C655.294 1284.49 655.309 1284.48 655.324 1284.48H657.631C657.676 1284.48 657.721 1284.52 657.721 1284.57C657.721 1284.62 657.684 1284.66 657.631 1284.66H655.391L655.189 1286.39C655.467 1286.18 655.805 1286.07 656.135 1286.07C656.541 1286.07 656.955 1286.22 657.27 1286.54C657.571 1286.85 657.729 1287.25 657.729 1287.66C657.729 1288.07 657.571 1288.48 657.255 1288.79C656.947 1289.1 656.541 1289.26 656.128 1289.26C655.715 1289.26 655.324 1289.1 655.008 1288.79Z" fill="#E71D36"/>
<path d="M660.659 1284.49C661.463 1284.49 662.125 1285.11 662.192 1285.91C662.215 1286.03 662.222 1286.17 662.222 1286.31C662.222 1286.95 662.087 1287.67 661.756 1288.24C661.418 1288.81 660.885 1289.23 660.103 1289.27C660.05 1289.27 660.013 1289.23 660.013 1289.18C660.013 1289.14 660.043 1289.1 660.088 1289.1C660.817 1289.07 661.29 1288.68 661.599 1288.16C661.832 1287.75 661.974 1287.24 662.019 1286.76C661.764 1287.25 661.253 1287.58 660.659 1287.58C659.81 1287.58 659.104 1286.89 659.104 1286.04C659.104 1285.19 659.81 1284.49 660.659 1284.49V1284.49ZM660.659 1287.4C661.418 1287.4 662.027 1286.79 662.027 1286.03C662.027 1286 662.027 1285.97 662.027 1285.93V1285.9C661.959 1285.19 661.373 1284.65 660.667 1284.65C659.9 1284.65 659.284 1285.27 659.284 1286.03C659.284 1286.79 659.908 1287.4 660.667 1287.4L660.659 1287.4Z" fill="#E71D36"/>
</g>
<g opacity="0.98">
<path d="M822.591 1310.21C823.358 1310.21 823.989 1310.84 823.989 1311.62C823.989 1312.18 823.651 1312.67 823.185 1312.89C824.139 1313.15 824.846 1314.03 824.846 1315.07C824.846 1316.31 823.831 1317.31 822.583 1317.31C821.336 1317.31 820.321 1316.31 820.321 1315.07C820.321 1314.03 821.035 1313.16 821.982 1312.89C821.494 1312.67 821.171 1312.18 821.171 1311.62C821.171 1310.84 821.802 1310.21 822.583 1310.21H822.591ZM822.591 1317.05C823.703 1317.05 824.598 1316.16 824.598 1315.05C824.598 1313.94 823.703 1313.04 822.591 1313.04C821.479 1313.04 820.584 1313.94 820.584 1315.05C820.584 1316.16 821.486 1317.05 822.591 1317.05ZM822.591 1312.76C823.222 1312.76 823.733 1312.24 823.733 1311.62C823.733 1311 823.215 1310.48 822.591 1310.46C821.952 1310.47 821.434 1310.98 821.434 1311.62C821.434 1312.26 821.952 1312.76 822.591 1312.76Z" fill="#5EB0EF"/>
<path d="M826.815 1316.61C826.762 1316.57 826.762 1316.48 826.815 1316.43C826.867 1316.37 826.942 1316.37 826.995 1316.43C827.408 1316.83 827.949 1317.04 828.475 1317.04C829.001 1317.04 829.558 1316.83 829.963 1316.43C830.377 1316.01 830.58 1315.47 830.58 1314.94C830.58 1314.4 830.377 1313.86 829.963 1313.46C829.558 1313.05 829.032 1312.85 828.483 1312.85C827.934 1312.85 827.416 1313.05 826.995 1313.46C826.965 1313.51 826.875 1313.51 826.822 1313.46C826.814 1313.46 826.8 1313.46 826.8 1313.44C826.792 1313.44 826.792 1313.43 826.792 1313.43C826.769 1313.4 826.762 1313.37 826.769 1313.33L827.123 1310.33C827.13 1310.27 827.175 1310.24 827.213 1310.22C827.235 1310.21 827.25 1310.2 827.288 1310.2H830.715C830.79 1310.2 830.843 1310.27 830.843 1310.33C830.843 1310.41 830.783 1310.46 830.715 1310.46H827.378L827.078 1313.04C827.491 1312.73 827.987 1312.58 828.483 1312.58C829.092 1312.58 829.7 1312.8 830.166 1313.27C830.61 1313.73 830.843 1314.32 830.843 1314.93C830.843 1315.54 830.61 1316.15 830.144 1316.6C829.685 1317.07 829.077 1317.3 828.468 1317.3C827.859 1317.3 827.273 1317.07 826.807 1316.6L826.815 1316.61Z" fill="#5EB0EF"/>
</g>
<g opacity="0.71">
<path d="M789.096 1341.22H785.331C785.331 1341.22 785.286 1341.22 785.256 1341.19C785.211 1341.15 785.203 1341.08 785.241 1341.03L789.126 1336.19L789.141 1336.18C789.141 1336.18 789.186 1336.15 789.216 1336.15C789.254 1336.15 789.292 1336.18 789.307 1336.2C789.314 1336.21 789.314 1336.21 789.322 1336.23C789.322 1336.23 789.329 1336.25 789.329 1336.26V1340.99H789.893C789.968 1340.99 790.013 1341.03 790.013 1341.1C790.013 1341.18 789.968 1341.22 789.893 1341.22H789.329V1342.48C789.329 1342.54 789.284 1342.6 789.209 1342.6C789.156 1342.6 789.089 1342.55 789.089 1342.48V1341.22H789.096ZM789.096 1340.99V1336.62L785.564 1340.99H789.096Z" fill="#E71D36"/>
<path d="M793.673 1336.14C794.936 1336.15 795.875 1337.63 795.875 1339.36C795.875 1341.09 794.936 1342.58 793.673 1342.59C792.425 1342.58 791.479 1341.11 791.479 1339.36C791.479 1337.61 792.425 1336.15 793.673 1336.14ZM791.712 1339.36C791.712 1340.21 791.952 1340.96 792.313 1341.49C792.673 1342.04 793.162 1342.36 793.673 1342.36C794.184 1342.36 794.688 1342.04 795.048 1341.49C795.417 1340.96 795.649 1340.2 795.649 1339.36C795.649 1338.52 795.417 1337.77 795.048 1337.24C794.688 1336.68 794.199 1336.37 793.673 1336.37C793.147 1336.37 792.666 1336.69 792.313 1337.24C791.952 1337.77 791.712 1338.53 791.712 1339.36Z" fill="#E71D36"/>
</g>
<g opacity="0.76">
<path d="M703.549 1374.18L704.481 1373.63C704.519 1373.6 704.571 1373.61 704.601 1373.65C704.624 1373.69 704.609 1373.74 704.564 1373.77L703.632 1374.32C703.632 1374.32 703.602 1374.32 703.594 1374.32C703.564 1374.32 703.534 1374.32 703.519 1374.28C703.497 1374.25 703.504 1374.19 703.549 1374.17V1374.18ZM704.444 1373.7C704.444 1373.66 704.481 1373.61 704.526 1373.61C704.579 1373.61 704.616 1373.65 704.616 1373.7V1378.17C704.616 1378.22 704.579 1378.26 704.526 1378.26C704.489 1378.26 704.444 1378.22 704.444 1378.17V1373.7Z" fill="#E71D36"/>
<path d="M706.661 1378.13L708.487 1373.78H706.037C705.992 1373.78 705.954 1373.74 705.954 1373.69C705.954 1373.65 705.992 1373.6 706.037 1373.6H708.63C708.667 1373.6 708.72 1373.64 708.72 1373.69C708.72 1373.71 708.705 1373.73 708.69 1373.75L706.818 1378.19C706.818 1378.19 706.781 1378.25 706.743 1378.25C706.743 1378.25 706.721 1378.25 706.706 1378.25C706.661 1378.22 706.646 1378.18 706.661 1378.13Z" fill="#E71D36"/>
</g>
<g opacity="0.96">
<path d="M762.109 1398.61C762.95 1398.61 763.642 1399.3 763.642 1400.16C763.642 1400.76 763.273 1401.3 762.77 1401.54C763.807 1401.83 764.589 1402.78 764.589 1403.91C764.589 1405.27 763.484 1406.37 762.116 1406.37C760.748 1406.37 759.643 1405.27 759.643 1403.91C759.643 1402.78 760.418 1401.83 761.462 1401.54C760.929 1401.3 760.575 1400.76 760.575 1400.16C760.575 1399.31 761.259 1398.61 762.116 1398.61H762.109ZM762.109 1406.1C763.326 1406.1 764.303 1405.12 764.303 1403.91C764.303 1402.7 763.326 1401.71 762.109 1401.71C760.891 1401.71 759.914 1402.7 759.914 1403.91C759.914 1405.12 760.899 1406.1 762.109 1406.1ZM762.109 1401.4C762.792 1401.4 763.364 1400.84 763.364 1400.15C763.364 1399.46 762.8 1398.9 762.109 1398.88C761.41 1398.9 760.846 1399.45 760.846 1400.15C760.846 1400.85 761.41 1401.4 762.109 1401.4Z" fill="#5EB0EF"/>
<path d="M769.21 1398.61C770.518 1398.61 771.593 1399.62 771.705 1400.92C771.743 1401.13 771.751 1401.36 771.751 1401.58C771.751 1402.62 771.525 1403.77 770.999 1404.71C770.443 1405.64 769.579 1406.32 768.309 1406.38C768.218 1406.38 768.166 1406.33 768.166 1406.24C768.151 1406.17 768.218 1406.1 768.286 1406.1C769.473 1406.05 770.232 1405.43 770.744 1404.57C771.119 1403.91 771.352 1403.09 771.427 1402.3C771.014 1403.1 770.172 1403.64 769.21 1403.64C767.828 1403.64 766.685 1402.52 766.685 1401.13C766.685 1399.75 767.828 1398.61 769.21 1398.61ZM769.21 1403.36C770.443 1403.36 771.435 1402.36 771.435 1401.13C771.435 1401.08 771.435 1401.02 771.427 1400.97V1400.92C771.315 1399.77 770.36 1398.89 769.21 1398.89C767.97 1398.89 766.963 1399.89 766.963 1401.13C766.963 1402.37 767.97 1403.36 769.21 1403.36Z" fill="#5EB0EF"/>
</g>
<g opacity="0.89">
<path d="M586.477 1297.5L589.723 1295.57C589.859 1295.48 590.046 1295.5 590.137 1295.66C590.204 1295.8 590.159 1295.96 590.001 1296.05L586.755 1298C586.71 1298.02 586.665 1298.02 586.619 1298.02C586.529 1298.02 586.409 1298 586.364 1297.89C586.296 1297.77 586.319 1297.59 586.477 1297.5ZM589.58 1295.8C589.58 1295.67 589.716 1295.5 589.859 1295.5C590.046 1295.5 590.159 1295.67 590.159 1295.8V1311.42C590.159 1311.58 590.046 1311.72 589.859 1311.72C589.723 1311.72 589.58 1311.58 589.58 1311.42V1295.8Z" fill="#E71D36"/>
<path d="M594.871 1310.12C594.758 1310.03 594.758 1309.82 594.871 1309.7C594.991 1309.59 595.172 1309.59 595.292 1309.7C596.239 1310.63 597.471 1311.12 598.674 1311.12C599.876 1311.12 601.154 1310.63 602.078 1309.7C603.025 1308.75 603.491 1307.52 603.491 1306.3C603.491 1305.07 603.025 1303.84 602.078 1302.91C601.154 1301.99 599.944 1301.52 598.696 1301.52C597.449 1301.52 596.262 1301.99 595.292 1302.91C595.224 1303.02 595.014 1303.02 594.894 1302.93C594.871 1302.93 594.849 1302.91 594.849 1302.89C594.826 1302.89 594.826 1302.87 594.826 1302.87C594.781 1302.8 594.758 1302.72 594.781 1302.63L595.593 1295.79C595.615 1295.65 595.705 1295.56 595.803 1295.53C595.848 1295.51 595.893 1295.49 595.968 1295.49H603.807C603.965 1295.49 604.107 1295.65 604.107 1295.79C604.107 1295.98 603.965 1296.09 603.807 1296.09H596.179L595.48 1301.99C596.427 1301.26 597.569 1300.92 598.704 1300.92C600.094 1300.92 601.485 1301.43 602.552 1302.5C603.574 1303.54 604.107 1304.91 604.107 1306.3C604.107 1307.69 603.574 1309.09 602.507 1310.13C601.462 1311.2 600.072 1311.73 598.681 1311.73C597.291 1311.73 595.946 1311.2 594.879 1310.13L594.871 1310.12Z" fill="#E71D36"/>
</g>
<g opacity="0.83">
<path d="M590.843 1358.47C590.843 1356.97 591.158 1355.3 591.925 1353.99C592.684 1352.65 593.962 1351.66 595.788 1351.58C595.901 1351.58 595.991 1351.66 595.991 1351.78C595.991 1351.88 595.916 1351.97 595.818 1351.97C594.119 1352.05 593.022 1352.92 592.271 1354.17C591.7 1355.14 591.399 1356.29 591.286 1357.44C591.872 1356.28 593.082 1355.5 594.48 1355.5C596.464 1355.5 598.087 1357.11 598.103 1359.1C598.087 1361.1 596.464 1362.71 594.48 1362.71C592.624 1362.71 591.098 1361.33 590.903 1359.51C590.888 1359.5 590.888 1359.47 590.888 1359.45C590.858 1359.14 590.843 1358.8 590.843 1358.47ZM594.48 1362.32C596.261 1362.32 597.674 1360.89 597.704 1359.11C597.674 1357.34 596.254 1355.91 594.48 1355.91C592.707 1355.91 591.286 1357.34 591.286 1359.11C591.286 1360.87 592.714 1362.32 594.48 1362.32Z" fill="#E71D36"/>
<path d="M604.452 1351.58C605.662 1351.58 606.647 1352.56 606.647 1353.79C606.647 1354.66 606.121 1355.43 605.392 1355.78C606.887 1356.19 608 1357.56 608 1359.18C608 1361.14 606.406 1362.71 604.452 1362.71C602.498 1362.71 600.905 1361.14 600.905 1359.18C600.905 1357.56 602.018 1356.19 603.513 1355.78C602.754 1355.42 602.243 1354.66 602.243 1353.79C602.243 1352.56 603.227 1351.58 604.452 1351.58ZM604.452 1362.32C606.204 1362.32 607.601 1360.92 607.601 1359.18C607.601 1357.44 606.204 1356.03 604.452 1356.03C602.701 1356.03 601.304 1357.44 601.304 1359.18C601.304 1360.92 602.716 1362.32 604.452 1362.32ZM604.452 1355.59C605.437 1355.59 606.249 1354.78 606.249 1353.79C606.249 1352.8 605.437 1351.99 604.452 1351.98C603.453 1351.99 602.641 1352.79 602.641 1353.79C602.641 1354.79 603.453 1355.59 604.452 1355.59Z" fill="#E71D36"/>
</g>
<g opacity="0.84">
<path d="M569.417 1305.8C569.417 1304.52 569.688 1303.1 570.341 1301.97C570.995 1300.83 572.077 1299.99 573.633 1299.93C573.731 1299.93 573.806 1299.99 573.806 1300.1C573.806 1300.18 573.738 1300.26 573.656 1300.26C572.205 1300.33 571.273 1301.08 570.634 1302.14C570.146 1302.96 569.89 1303.96 569.793 1304.93C570.296 1303.94 571.326 1303.28 572.521 1303.28C574.212 1303.28 575.595 1304.65 575.61 1306.35C575.595 1308.06 574.212 1309.42 572.521 1309.42C570.935 1309.42 569.635 1308.24 569.47 1306.69C569.455 1306.68 569.455 1306.66 569.455 1306.64C569.424 1306.37 569.417 1306.09 569.417 1305.8ZM572.521 1309.08C574.039 1309.08 575.241 1307.86 575.271 1306.34C575.241 1304.84 574.039 1303.61 572.521 1303.61C571.003 1303.61 569.793 1304.83 569.793 1306.34C569.793 1307.85 571.01 1309.08 572.521 1309.08Z" fill="#5EB0EF"/>
<path d="M578.119 1308.48C578.052 1308.42 578.052 1308.3 578.119 1308.24C578.187 1308.17 578.292 1308.17 578.36 1308.24C578.916 1308.78 579.63 1309.06 580.336 1309.06C581.043 1309.06 581.787 1308.78 582.328 1308.24C582.884 1307.68 583.154 1306.96 583.154 1306.24C583.154 1305.53 582.884 1304.81 582.328 1304.27C581.787 1303.72 581.08 1303.45 580.351 1303.45C579.622 1303.45 578.931 1303.72 578.36 1304.27C578.322 1304.33 578.194 1304.33 578.127 1304.28C578.112 1304.28 578.097 1304.27 578.097 1304.25C578.082 1304.25 578.082 1304.24 578.082 1304.24C578.052 1304.2 578.044 1304.15 578.052 1304.1L578.525 1300.1C578.54 1300.02 578.593 1299.96 578.645 1299.95C578.675 1299.93 578.698 1299.92 578.743 1299.92H583.32C583.417 1299.92 583.5 1300.01 583.5 1300.09C583.5 1300.2 583.417 1300.27 583.32 1300.27H578.863L578.457 1303.72C579.014 1303.3 579.675 1303.09 580.344 1303.09C581.155 1303.09 581.967 1303.39 582.591 1304.02C583.192 1304.63 583.5 1305.43 583.5 1306.24C583.5 1307.06 583.192 1307.87 582.561 1308.48C581.952 1309.11 581.14 1309.42 580.329 1309.42C579.517 1309.42 578.728 1309.11 578.104 1308.48L578.119 1308.48Z" fill="#5EB0EF"/>
</g>
<g opacity="0.9">
<path d="M667.251 1308.72L673.458 1293.92H665.124C664.966 1293.92 664.831 1293.78 664.831 1293.62C664.831 1293.49 664.966 1293.33 665.124 1293.33H673.932C674.067 1293.33 674.225 1293.49 674.225 1293.62C674.225 1293.69 674.18 1293.76 674.135 1293.83L667.769 1308.94C667.747 1309.03 667.634 1309.12 667.521 1309.12C667.499 1309.12 667.454 1309.1 667.409 1309.1C667.251 1309.03 667.206 1308.88 667.251 1308.72Z" fill="#5EB0EF"/>
<path d="M677.727 1295.28L680.884 1293.41C681.019 1293.32 681.199 1293.34 681.29 1293.5C681.357 1293.63 681.312 1293.79 681.154 1293.88L677.99 1295.77C677.945 1295.8 677.9 1295.8 677.855 1295.8C677.765 1295.8 677.652 1295.77 677.607 1295.66C677.539 1295.55 677.562 1295.37 677.72 1295.28H677.727ZM680.748 1293.63C680.748 1293.5 680.884 1293.34 681.019 1293.34C681.199 1293.34 681.312 1293.5 681.312 1293.63V1308.84C681.312 1309 681.199 1309.13 681.019 1309.13C680.884 1309.13 680.748 1309 680.748 1308.84V1293.63Z" fill="#5EB0EF"/>
</g>
<g opacity="0.61">
<path d="M660.885 1363.48L663.125 1361.46C663.41 1361.2 663.561 1360.84 663.561 1360.48C663.561 1360.18 663.441 1359.85 663.215 1359.6C662.96 1359.31 662.591 1359.16 662.238 1359.16C661.922 1359.16 661.599 1359.28 661.351 1359.51L661.118 1359.72C661.081 1359.75 661.036 1359.75 660.998 1359.72C660.968 1359.69 660.975 1359.63 660.998 1359.6L661.231 1359.38C661.524 1359.12 661.877 1358.99 662.231 1358.99C662.636 1358.99 663.042 1359.16 663.335 1359.48C663.591 1359.76 663.719 1360.12 663.719 1360.48C663.719 1360.88 663.553 1361.29 663.223 1361.57L661.141 1363.46H663.643C663.681 1363.46 663.726 1363.49 663.726 1363.54C663.726 1363.6 663.689 1363.63 663.643 1363.63H660.938C660.938 1363.63 660.885 1363.63 660.87 1363.6C660.848 1363.56 660.848 1363.51 660.87 1363.48L660.885 1363.48Z" fill="#E71D36"/>
<path d="M664.906 1363.18C664.906 1363.18 664.876 1363.09 664.906 1363.06C664.944 1363.02 664.996 1363.02 665.026 1363.06C665.297 1363.32 665.65 1363.46 665.996 1363.46C666.341 1363.46 666.702 1363.33 666.973 1363.06C667.243 1362.78 667.379 1362.43 667.379 1362.08C667.379 1361.72 667.243 1361.37 666.973 1361.11C666.71 1360.84 666.364 1360.71 666.003 1360.71C665.643 1360.71 665.304 1360.84 665.026 1361.11C665.004 1361.14 664.944 1361.14 664.914 1361.11C664.914 1361.11 664.898 1361.11 664.898 1361.09C664.883 1361.07 664.876 1361.05 664.883 1361.02L665.116 1359.07C665.116 1359.03 665.146 1359 665.177 1358.99C665.192 1358.99 665.199 1358.98 665.222 1358.98H667.461C667.506 1358.98 667.551 1359.02 667.551 1359.06C667.551 1359.11 667.514 1359.15 667.461 1359.15H665.282L665.086 1360.84C665.357 1360.63 665.68 1360.53 666.011 1360.53C666.409 1360.53 666.807 1360.67 667.108 1360.98C667.401 1361.28 667.551 1361.67 667.551 1362.07C667.551 1362.47 667.401 1362.87 667.093 1363.17C666.792 1363.48 666.394 1363.63 666.003 1363.63C665.612 1363.63 665.222 1363.48 664.914 1363.17L664.906 1363.18Z" fill="#E71D36"/>
</g>
<g opacity="0.54">
<path d="M600.079 1304.08H594.556C594.518 1304.08 594.488 1304.07 594.45 1304.05C594.383 1303.98 594.368 1303.88 594.42 1303.81L600.117 1296.72L600.147 1296.69C600.185 1296.66 600.215 1296.65 600.252 1296.65C600.305 1296.65 600.357 1296.69 600.387 1296.74C600.402 1296.75 600.402 1296.77 600.417 1296.78C600.417 1296.8 600.433 1296.8 600.433 1296.82V1303.75H601.259C601.364 1303.75 601.432 1303.82 601.432 1303.92C601.432 1304.02 601.364 1304.09 601.259 1304.09H600.433V1305.93C600.433 1306.03 600.365 1306.1 600.26 1306.1C600.177 1306.1 600.087 1306.02 600.087 1305.93V1304.09L600.079 1304.08ZM600.079 1303.75V1297.34L594.909 1303.75H600.079Z" fill="#E71D36"/>
<path d="M602.95 1297.81L604.836 1296.69C604.919 1296.64 605.024 1296.65 605.077 1296.74C605.115 1296.83 605.092 1296.92 604.994 1296.98L603.1 1298.11C603.1 1298.11 603.048 1298.13 603.018 1298.13C602.965 1298.13 602.898 1298.11 602.867 1298.05C602.83 1297.98 602.837 1297.87 602.935 1297.81H602.95ZM604.761 1296.83C604.761 1296.74 604.844 1296.65 604.927 1296.65C605.032 1296.65 605.099 1296.74 605.099 1296.83V1305.92C605.099 1306.02 605.032 1306.09 604.927 1306.09C604.844 1306.09 604.761 1306.01 604.761 1305.92V1296.83Z" fill="#E71D36"/>
</g>
<g opacity="0.69">
<path d="M514.999 1276.07C514.954 1276.03 514.954 1275.96 514.999 1275.92C515.044 1275.87 515.104 1275.87 515.149 1275.92C515.487 1276.25 515.931 1276.42 516.359 1276.42C516.787 1276.42 517.246 1276.25 517.584 1275.92C517.922 1275.58 518.095 1275.14 518.095 1274.69C518.095 1274.25 517.93 1273.81 517.584 1273.48C517.253 1273.14 516.817 1272.98 516.374 1272.98C515.931 1272.98 515.502 1273.14 515.149 1273.48C515.126 1273.52 515.051 1273.52 515.006 1273.48C514.999 1273.48 514.991 1273.48 514.991 1273.48V1273.46C514.991 1273.46 514.961 1273.41 514.969 1273.38L515.262 1270.93C515.269 1270.87 515.307 1270.84 515.337 1270.84C515.352 1270.83 515.367 1270.82 515.397 1270.82H518.208C518.268 1270.82 518.313 1270.88 518.313 1270.93C518.313 1270.99 518.26 1271.03 518.208 1271.03H515.472L515.224 1273.14C515.562 1272.89 515.976 1272.76 516.381 1272.76C516.877 1272.76 517.381 1272.94 517.764 1273.32C518.133 1273.7 518.32 1274.19 518.32 1274.69C518.32 1275.18 518.133 1275.69 517.749 1276.06C517.373 1276.45 516.877 1276.63 516.381 1276.63C515.885 1276.63 515.404 1276.44 515.021 1276.06L514.999 1276.07Z" fill="#E71D36"/>
</g>
<g opacity="0.6">
<path d="M540.032 1316.08H536.891C536.891 1316.08 536.853 1316.07 536.83 1316.06C536.793 1316.02 536.785 1315.97 536.815 1315.93L540.055 1311.9L540.07 1311.88C540.07 1311.88 540.107 1311.86 540.13 1311.86C540.16 1311.86 540.19 1311.88 540.205 1311.91C540.212 1311.91 540.212 1311.92 540.22 1311.93V1315.89H540.693C540.753 1315.89 540.791 1315.93 540.791 1315.98C540.791 1316.04 540.753 1316.08 540.693 1316.08H540.227V1317.13C540.227 1317.18 540.19 1317.22 540.13 1317.22C540.085 1317.22 540.032 1317.18 540.032 1317.13V1316.08ZM540.032 1315.89V1312.25L537.093 1315.89H540.032Z" fill="#E71D36"/>
<path d="M541.903 1317.05L544.496 1314.7C544.826 1314.4 544.999 1313.98 544.999 1313.57C544.999 1313.21 544.864 1312.84 544.601 1312.55C544.3 1312.21 543.879 1312.03 543.473 1312.03C543.105 1312.03 542.737 1312.17 542.444 1312.43L542.173 1312.68C542.128 1312.72 542.076 1312.72 542.038 1312.68C542.001 1312.64 542.008 1312.58 542.046 1312.55L542.316 1312.29C542.654 1311.99 543.06 1311.85 543.473 1311.85C543.939 1311.85 544.413 1312.03 544.751 1312.4C545.044 1312.73 545.195 1313.15 545.195 1313.56C545.195 1314.03 545.007 1314.5 544.616 1314.84L542.203 1317.03H545.097C545.142 1317.03 545.187 1317.07 545.187 1317.12C545.187 1317.17 545.142 1317.22 545.097 1317.22H541.963C541.963 1317.22 541.903 1317.21 541.888 1317.18C541.858 1317.14 541.858 1317.08 541.888 1317.04L541.903 1317.05Z" fill="#E71D36"/>
</g>
<g opacity="0.58">
<path d="M500.397 1304.43H493.032C492.98 1304.43 492.942 1304.42 492.889 1304.38C492.799 1304.29 492.777 1304.16 492.852 1304.07L500.457 1294.61L500.495 1294.57C500.548 1294.53 500.585 1294.52 500.638 1294.52C500.713 1294.52 500.781 1294.57 500.818 1294.63C500.833 1294.65 500.833 1294.67 500.856 1294.68C500.856 1294.7 500.871 1294.72 500.871 1294.74V1303.99H501.968C502.111 1303.99 502.201 1304.08 502.201 1304.21C502.201 1304.35 502.111 1304.44 501.968 1304.44H500.871V1306.89C500.871 1307.02 500.78 1307.12 500.638 1307.12C500.532 1307.12 500.405 1307.02 500.405 1306.89V1304.44L500.397 1304.43ZM500.397 1303.98V1295.44L493.498 1303.98H500.397Z" fill="#5EB0EF"/>
<path d="M508.889 1294.5C510.257 1294.5 511.377 1295.61 511.377 1297C511.377 1297.99 510.783 1298.86 509.956 1299.26C511.647 1299.72 512.91 1301.28 512.91 1303.11C512.91 1305.33 511.106 1307.12 508.889 1307.12C506.672 1307.12 504.876 1305.33 504.876 1303.11C504.876 1301.27 506.139 1299.72 507.829 1299.26C506.965 1298.86 506.387 1297.99 506.387 1297C506.387 1295.61 507.506 1294.5 508.889 1294.5ZM508.889 1306.66C510.873 1306.66 512.459 1305.08 512.459 1303.11C512.459 1301.15 510.873 1299.54 508.889 1299.54C506.905 1299.54 505.319 1301.14 505.319 1303.11C505.319 1305.09 506.92 1306.66 508.889 1306.66ZM508.889 1299.04C510.009 1299.04 510.926 1298.12 510.926 1297C510.926 1295.88 510.009 1294.96 508.889 1294.95C507.754 1294.97 506.837 1295.86 506.837 1297C506.837 1298.14 507.754 1299.04 508.889 1299.04Z" fill="#5EB0EF"/>
</g>
<g opacity="0.76">
<path d="M407.05 1327.98L408.26 1327.26C408.313 1327.23 408.38 1327.24 408.418 1327.3C408.44 1327.35 408.425 1327.41 408.365 1327.45L407.155 1328.18C407.155 1328.18 407.125 1328.19 407.103 1328.19C407.073 1328.19 407.027 1328.18 407.005 1328.14C406.982 1328.09 406.99 1328.02 407.05 1327.99V1327.98ZM408.207 1327.35C408.207 1327.29 408.26 1327.23 408.313 1327.23C408.38 1327.23 408.425 1327.29 408.425 1327.35V1333.16C408.425 1333.22 408.38 1333.27 408.313 1333.27C408.26 1333.27 408.207 1333.22 408.207 1333.16V1327.35Z" fill="#E71D36"/>
<path d="M410.169 1330.96C410.169 1330.15 410.342 1329.25 410.755 1328.53C411.168 1327.81 411.86 1327.26 412.852 1327.23C412.912 1327.23 412.964 1327.27 412.964 1327.34C412.964 1327.39 412.919 1327.44 412.867 1327.44C411.942 1327.49 411.349 1327.96 410.943 1328.63C410.635 1329.16 410.469 1329.79 410.409 1330.42C410.732 1329.78 411.386 1329.36 412.145 1329.36C413.227 1329.36 414.107 1330.23 414.114 1331.32C414.107 1332.41 413.227 1333.28 412.145 1333.28C411.138 1333.28 410.304 1332.53 410.206 1331.54C410.199 1331.54 410.199 1331.52 410.199 1331.51C410.184 1331.33 410.176 1331.15 410.176 1330.97L410.169 1330.96ZM412.145 1333.06C413.115 1333.06 413.881 1332.28 413.896 1331.31C413.881 1330.35 413.107 1329.57 412.145 1329.57C411.183 1329.57 410.409 1330.35 410.409 1331.31C410.409 1332.27 411.183 1333.06 412.145 1333.06Z" fill="#E71D36"/>
</g>
<g opacity="0.78">
<path d="M521.553 1272.02L525.761 1262H520.117C520.012 1262 519.922 1261.91 519.922 1261.8C519.922 1261.71 520.012 1261.61 520.117 1261.61H526.084C526.175 1261.61 526.28 1261.71 526.28 1261.8C526.28 1261.85 526.25 1261.89 526.22 1261.94L521.906 1272.17C521.891 1272.23 521.816 1272.29 521.741 1272.29C521.726 1272.29 521.696 1272.28 521.665 1272.28C521.56 1272.23 521.53 1272.13 521.56 1272.02L521.553 1272.02Z" fill="#E71D36"/>
<path d="M533.803 1270.02H527.55C527.505 1270.02 527.475 1270 527.43 1269.97C527.354 1269.9 527.339 1269.79 527.4 1269.71L533.855 1261.67L533.885 1261.64C533.93 1261.61 533.96 1261.6 534.006 1261.6C534.066 1261.6 534.126 1261.64 534.156 1261.69C534.171 1261.71 534.171 1261.72 534.186 1261.74C534.186 1261.75 534.201 1261.77 534.201 1261.78V1269.63H535.133C535.253 1269.63 535.336 1269.71 535.336 1269.81C535.336 1269.93 535.261 1270.01 535.133 1270.01H534.201V1272.09C534.201 1272.2 534.126 1272.29 534.006 1272.29C533.915 1272.29 533.81 1272.2 533.81 1272.09V1270.01L533.803 1270.02ZM533.803 1269.63V1262.38L527.948 1269.63H533.803Z" fill="#E71D36"/>
</g>
<g opacity="0.56">
<path d="M451.367 1282.21C451.179 1282.06 451.179 1281.73 451.367 1281.54C451.555 1281.35 451.856 1281.35 452.044 1281.54C453.592 1283.05 455.591 1283.84 457.552 1283.84C459.514 1283.84 461.588 1283.05 463.099 1281.54C464.647 1279.99 465.398 1277.99 465.398 1275.99C465.398 1273.99 464.647 1271.99 463.099 1270.47C461.588 1268.96 459.626 1268.21 457.59 1268.21C455.553 1268.21 453.629 1268.96 452.044 1270.47C451.931 1270.66 451.593 1270.66 451.405 1270.51C451.367 1270.51 451.33 1270.47 451.33 1270.44C451.292 1270.44 451.292 1270.4 451.292 1270.4C451.217 1270.29 451.179 1270.17 451.217 1270.02L452.54 1258.89C452.577 1258.66 452.727 1258.51 452.878 1258.47C452.953 1258.43 453.028 1258.4 453.141 1258.4H465.894C466.157 1258.4 466.383 1258.66 466.383 1258.89C466.383 1259.19 466.157 1259.38 465.894 1259.38H453.479L452.352 1268.97C453.9 1267.8 455.749 1267.23 457.597 1267.23C459.859 1267.23 462.121 1268.06 463.858 1269.8C465.518 1271.5 466.383 1273.72 466.383 1275.99C466.383 1278.25 465.511 1280.52 463.782 1282.21C462.084 1283.95 459.822 1284.82 457.56 1284.82C455.298 1284.82 453.111 1283.95 451.375 1282.21H451.367Z" fill="#E71D36"/>
<path d="M474.191 1282.21C474.003 1282.06 474.003 1281.73 474.191 1281.54C474.379 1281.35 474.68 1281.35 474.868 1281.54C476.416 1283.05 478.415 1283.84 480.376 1283.84C482.338 1283.84 484.412 1283.05 485.923 1281.54C487.471 1279.99 488.222 1277.99 488.222 1275.99C488.222 1273.99 487.471 1271.99 485.923 1270.47C484.412 1268.96 482.451 1268.21 480.414 1268.21C478.377 1268.21 476.453 1268.96 474.868 1270.47C474.755 1270.66 474.417 1270.66 474.229 1270.51C474.191 1270.51 474.154 1270.47 474.154 1270.44C474.116 1270.44 474.116 1270.4 474.116 1270.4C474.041 1270.29 474.004 1270.17 474.041 1270.02L475.364 1258.88C475.401 1258.66 475.552 1258.51 475.702 1258.47C475.777 1258.43 475.852 1258.4 475.965 1258.4H488.718C488.981 1258.4 489.207 1258.66 489.207 1258.88C489.207 1259.19 488.981 1259.38 488.718 1259.38H476.311L475.176 1268.97C476.724 1267.8 478.573 1267.23 480.422 1267.23C482.684 1267.23 484.946 1268.06 486.682 1269.8C488.343 1271.5 489.207 1273.72 489.207 1275.99C489.207 1278.25 488.343 1280.51 486.607 1282.21C484.908 1283.95 482.646 1284.82 480.384 1284.82C478.122 1284.82 475.935 1283.95 474.199 1282.21H474.191Z" fill="#E71D36"/>
</g>
<g opacity="0.78">
<path d="M475.168 1322.86L476.964 1321.8C477.039 1321.74 477.144 1321.76 477.197 1321.85C477.234 1321.92 477.212 1322.01 477.121 1322.07L475.325 1323.15C475.325 1323.15 475.273 1323.17 475.25 1323.17C475.198 1323.17 475.137 1323.15 475.107 1323.09C475.07 1323.02 475.085 1322.92 475.168 1322.87V1322.86ZM476.888 1321.92C476.888 1321.85 476.964 1321.76 477.039 1321.76C477.144 1321.76 477.204 1321.85 477.204 1321.92V1330.58C477.204 1330.67 477.137 1330.75 477.039 1330.75C476.964 1330.75 476.888 1330.67 476.888 1330.58V1321.92Z" fill="#E71D36"/>
<path d="M481.18 1330.51L484.712 1322.09H479.97C479.88 1322.09 479.804 1322.01 479.804 1321.92C479.804 1321.85 479.88 1321.76 479.97 1321.76H484.982C485.058 1321.76 485.148 1321.85 485.148 1321.92C485.148 1321.96 485.125 1322 485.095 1322.04L481.473 1330.64C481.458 1330.69 481.398 1330.75 481.33 1330.75C481.315 1330.75 481.292 1330.73 481.262 1330.73C481.172 1330.69 481.15 1330.6 481.172 1330.51H481.18Z" fill="#E71D36"/>
</g>
<g opacity="0.83">
<path d="M500.329 1188.2L501.652 1187.41C501.712 1187.37 501.787 1187.38 501.825 1187.44C501.855 1187.5 501.832 1187.57 501.764 1187.6L500.442 1188.4C500.442 1188.4 500.404 1188.41 500.382 1188.41C500.344 1188.41 500.299 1188.4 500.276 1188.35C500.246 1188.31 500.254 1188.23 500.322 1188.2H500.329ZM501.599 1187.5C501.599 1187.45 501.652 1187.38 501.712 1187.38C501.787 1187.38 501.832 1187.45 501.832 1187.5V1193.88C501.832 1193.95 501.787 1194 501.712 1194C501.652 1194 501.599 1193.94 501.599 1193.88V1187.5Z" fill="#E71D36"/>
<path d="M504.763 1193.84L507.371 1187.63H503.876C503.808 1187.63 503.756 1187.57 503.756 1187.5C503.756 1187.45 503.816 1187.38 503.876 1187.38H507.574C507.626 1187.38 507.694 1187.45 507.694 1187.5C507.694 1187.53 507.679 1187.57 507.656 1187.59L504.988 1193.93C504.981 1193.97 504.936 1194 504.883 1194C504.876 1194 504.853 1194 504.838 1194C504.77 1193.97 504.755 1193.9 504.77 1193.83L504.763 1193.84Z" fill="#E71D36"/>
</g>
<g opacity="0.99">
<path d="M465.864 1253.07C465.864 1253.07 465.842 1253.01 465.864 1252.98C465.887 1252.96 465.924 1252.96 465.954 1252.98C466.157 1253.18 466.413 1253.28 466.668 1253.28C466.924 1253.28 467.194 1253.18 467.39 1252.98C467.593 1252.78 467.69 1252.52 467.69 1252.26C467.69 1252 467.593 1251.74 467.39 1251.54C467.194 1251.35 466.939 1251.25 466.676 1251.25C466.413 1251.25 466.165 1251.35 465.954 1251.54C465.939 1251.57 465.894 1251.57 465.872 1251.54H465.864V1251.53C465.864 1251.53 465.842 1251.5 465.849 1251.48L466.022 1250.03C466.022 1250.03 466.044 1249.98 466.067 1249.98C466.074 1249.98 466.09 1249.97 466.097 1249.97H467.758C467.758 1249.97 467.818 1250 467.818 1250.03C467.818 1250.07 467.788 1250.09 467.758 1250.09H466.142L465.992 1251.34C466.195 1251.19 466.435 1251.12 466.676 1251.12C466.969 1251.12 467.262 1251.22 467.487 1251.45C467.705 1251.66 467.818 1251.96 467.818 1252.25C467.818 1252.54 467.705 1252.84 467.48 1253.06C467.262 1253.29 466.969 1253.4 466.668 1253.4C466.368 1253.4 466.09 1253.29 465.864 1253.06V1253.07Z" fill="#5EB0EF"/>
<path d="M468.825 1252.1C468.825 1251.64 468.922 1251.12 469.155 1250.72C469.388 1250.3 469.779 1250 470.343 1249.98C470.38 1249.98 470.403 1250 470.403 1250.04C470.403 1250.07 470.38 1250.1 470.35 1250.1C469.824 1250.12 469.486 1250.39 469.253 1250.78C469.073 1251.08 468.983 1251.43 468.952 1251.79C469.133 1251.42 469.509 1251.18 469.937 1251.18C470.546 1251.18 471.049 1251.68 471.057 1252.29C471.057 1252.91 470.553 1253.4 469.937 1253.4C469.366 1253.4 468.892 1252.97 468.832 1252.42C468.832 1252.42 468.832 1252.4 468.832 1252.39C468.825 1252.3 468.817 1252.19 468.817 1252.09L468.825 1252.1ZM469.952 1253.29C470.501 1253.29 470.937 1252.85 470.944 1252.3C470.937 1251.76 470.501 1251.31 469.952 1251.31C469.403 1251.31 468.968 1251.76 468.968 1252.3C468.968 1252.84 469.411 1253.29 469.952 1253.29Z" fill="#5EB0EF"/>
</g>
<g opacity="0.76">
<path d="M354.646 1235.84L362.559 1216.97H351.94C351.737 1216.97 351.564 1216.8 351.564 1216.6C351.564 1216.42 351.737 1216.22 351.94 1216.22H363.168C363.341 1216.22 363.544 1216.42 363.544 1216.6C363.544 1216.68 363.484 1216.77 363.431 1216.86L355.314 1236.13C355.284 1236.24 355.142 1236.36 354.999 1236.36C354.969 1236.36 354.916 1236.33 354.856 1236.33C354.653 1236.24 354.6 1236.04 354.653 1235.84H354.646Z" fill="#5EB0EF"/>
<path d="M377.702 1232.07H365.926C365.836 1232.07 365.783 1232.04 365.693 1231.99C365.55 1231.85 365.52 1231.64 365.633 1231.5L377.778 1216.38L377.838 1216.32C377.92 1216.26 377.981 1216.23 378.071 1216.23C378.183 1216.23 378.304 1216.32 378.356 1216.4C378.386 1216.43 378.386 1216.46 378.416 1216.48C378.416 1216.51 378.446 1216.54 378.446 1216.57V1231.35H380.205C380.438 1231.35 380.581 1231.49 380.581 1231.7C380.581 1231.93 380.438 1232.07 380.205 1232.07H378.446V1235.99C378.446 1236.19 378.304 1236.37 378.071 1236.37C377.898 1236.37 377.695 1236.19 377.695 1235.99V1232.07H377.702ZM377.702 1231.35V1217.69L366.678 1231.35H377.702Z" fill="#5EB0EF"/>
</g>
<g opacity="0.89">
<path d="M285.948 1273.66C285.948 1271.62 286.384 1269.35 287.422 1267.55C288.459 1265.73 290.187 1264.39 292.675 1264.28C292.825 1264.28 292.953 1264.38 292.953 1264.56C292.953 1264.68 292.84 1264.82 292.712 1264.82C290.398 1264.93 288.902 1266.12 287.887 1267.8C287.106 1269.13 286.7 1270.71 286.542 1272.26C287.346 1270.68 288.985 1269.62 290.894 1269.62C293.599 1269.62 295.801 1271.81 295.824 1274.52C295.801 1277.25 293.592 1279.43 290.894 1279.43C288.361 1279.43 286.287 1277.55 286.024 1275.08C286.001 1275.05 286.001 1275.01 286.001 1274.99C285.956 1274.55 285.933 1274.1 285.933 1273.65L285.948 1273.66ZM290.901 1278.89C293.321 1278.89 295.252 1276.94 295.29 1274.52C295.245 1272.11 293.321 1270.17 290.901 1270.17C288.481 1270.17 286.55 1272.11 286.55 1274.52C286.55 1276.93 288.496 1278.89 290.901 1278.89Z" fill="#E71D36"/>
<path d="M299.852 1277.94C299.747 1277.86 299.747 1277.66 299.852 1277.55C299.957 1277.44 300.13 1277.44 300.243 1277.55C301.129 1278.42 302.279 1278.88 303.399 1278.88C304.519 1278.88 305.714 1278.42 306.578 1277.55C307.465 1276.66 307.901 1275.51 307.901 1274.37C307.901 1273.23 307.465 1272.08 306.578 1271.21C305.714 1270.35 304.586 1269.91 303.421 1269.91C302.257 1269.91 301.152 1270.35 300.243 1271.21C300.175 1271.32 299.979 1271.32 299.874 1271.23C299.852 1271.23 299.829 1271.21 299.829 1271.19C299.807 1271.19 299.807 1271.17 299.807 1271.17C299.762 1271.1 299.739 1271.04 299.762 1270.95L300.521 1264.56C300.543 1264.44 300.626 1264.34 300.716 1264.32C300.761 1264.3 300.799 1264.28 300.866 1264.28H308.179C308.329 1264.28 308.464 1264.43 308.464 1264.56C308.464 1264.73 308.336 1264.83 308.179 1264.83H301.062L300.415 1270.33C301.302 1269.66 302.362 1269.33 303.421 1269.33C304.722 1269.33 306.014 1269.81 307.014 1270.81C307.968 1271.78 308.464 1273.05 308.464 1274.36C308.464 1275.66 307.968 1276.95 306.969 1277.93C305.999 1278.93 304.699 1279.42 303.399 1279.42C302.099 1279.42 300.844 1278.93 299.852 1277.93V1277.94Z" fill="#E71D36"/>
</g>
<g opacity="0.54">
<path d="M733.761 1428.37L737.662 1424.85C738.158 1424.39 738.421 1423.76 738.421 1423.14C738.421 1422.6 738.21 1422.04 737.82 1421.6C737.369 1421.11 736.737 1420.85 736.121 1420.85C735.565 1420.85 735.016 1421.06 734.573 1421.45L734.167 1421.82C734.1 1421.88 734.017 1421.88 733.957 1421.82C733.897 1421.76 733.912 1421.65 733.972 1421.61L734.378 1421.23C734.881 1420.78 735.497 1420.56 736.121 1420.56C736.828 1420.56 737.526 1420.85 738.038 1421.4C738.473 1421.9 738.706 1422.53 738.706 1423.15C738.706 1423.85 738.421 1424.55 737.842 1425.06L734.22 1428.36H738.571C738.639 1428.36 738.706 1428.42 738.706 1428.49C738.706 1428.57 738.639 1428.64 738.571 1428.64H733.867C733.821 1428.64 733.776 1428.63 733.754 1428.58C733.709 1428.52 733.709 1428.43 733.769 1428.37L733.761 1428.37Z" fill="#E71D36"/>
<path d="M745.087 1426.91H740.367C740.367 1426.91 740.307 1426.89 740.277 1426.88C740.217 1426.82 740.209 1426.74 740.255 1426.68L745.124 1420.62L745.147 1420.6C745.147 1420.6 745.207 1420.56 745.237 1420.56C745.282 1420.56 745.327 1420.6 745.35 1420.63C745.357 1420.64 745.357 1420.65 745.372 1420.66C745.372 1420.68 745.387 1420.69 745.387 1420.7V1426.63H746.094C746.184 1426.63 746.244 1426.69 746.244 1426.76C746.244 1426.85 746.184 1426.91 746.094 1426.91H745.387V1428.49C745.387 1428.57 745.327 1428.64 745.237 1428.64C745.17 1428.64 745.087 1428.57 745.087 1428.49V1426.91V1426.91ZM745.087 1426.62V1421.15L740.668 1426.62H745.087Z" fill="#E71D36"/>
</g>
<g opacity="0.6">
<path d="M662.644 1318.17C663.02 1318.17 663.328 1318.48 663.328 1318.86C663.328 1319.13 663.162 1319.37 662.937 1319.47C663.403 1319.6 663.749 1320.02 663.749 1320.53C663.749 1321.14 663.253 1321.63 662.651 1321.63C662.05 1321.63 661.547 1321.14 661.547 1320.53C661.547 1320.03 661.892 1319.61 662.358 1319.47C662.118 1319.36 661.96 1319.13 661.96 1318.86C661.96 1318.47 662.268 1318.17 662.644 1318.17ZM662.644 1321.5C663.185 1321.5 663.621 1321.07 663.621 1320.53C663.621 1320 663.185 1319.56 662.644 1319.56C662.103 1319.56 661.667 1319.99 661.667 1320.53C661.667 1321.07 662.103 1321.5 662.644 1321.5ZM662.644 1319.41C662.952 1319.41 663.2 1319.16 663.2 1318.86C663.2 1318.55 662.952 1318.3 662.644 1318.29C662.336 1318.29 662.08 1318.55 662.08 1318.86C662.08 1319.16 662.336 1319.41 662.644 1319.41Z" fill="#E71D36"/>
<path d="M664.696 1320.31C664.696 1319.84 664.793 1319.32 665.034 1318.92C665.267 1318.5 665.665 1318.19 666.229 1318.17C666.266 1318.17 666.296 1318.19 666.296 1318.23C666.296 1318.26 666.274 1318.29 666.244 1318.29C665.718 1318.31 665.372 1318.59 665.139 1318.98C664.966 1319.28 664.868 1319.64 664.831 1319.99C665.011 1319.63 665.387 1319.39 665.823 1319.39C666.439 1319.39 666.943 1319.89 666.95 1320.5C666.95 1321.13 666.439 1321.62 665.823 1321.62C665.244 1321.62 664.771 1321.19 664.711 1320.63V1320.61C664.696 1320.51 664.688 1320.41 664.688 1320.3L664.696 1320.31ZM665.83 1321.5C666.379 1321.5 666.822 1321.06 666.83 1320.5C666.822 1319.95 666.379 1319.51 665.83 1319.51C665.282 1319.51 664.838 1319.95 664.838 1320.5C664.838 1321.05 665.282 1321.5 665.83 1321.5Z" fill="#E71D36"/>
</g>
<g opacity="0.75">
<path d="M718.219 1294.19C719.384 1295.36 719.985 1296.92 719.985 1298.45C719.985 1299.99 719.392 1301.57 718.219 1302.76C717.024 1303.93 715.469 1304.53 713.936 1304.53H713.913C712.38 1304.53 710.824 1303.96 709.659 1302.76C709.532 1302.63 709.532 1302.42 709.659 1302.29C709.787 1302.17 709.998 1302.17 710.125 1302.29C711.185 1303.33 712.538 1303.85 713.913 1303.85C715.288 1303.85 716.686 1303.33 717.723 1302.26C718.79 1301.23 719.309 1299.85 719.309 1298.45C719.309 1297.05 718.79 1295.72 717.753 1294.66C716.686 1293.62 715.341 1293.1 713.966 1293.1C713.838 1293.1 713.733 1293.05 713.657 1292.95C713.657 1292.92 713.635 1292.92 713.635 1292.92C713.507 1292.8 713.507 1292.56 713.635 1292.43L718.873 1287.03H710.854C710.674 1287.03 710.516 1286.87 710.516 1286.69C710.516 1286.51 710.674 1286.35 710.854 1286.35H719.647C719.828 1286.35 719.985 1286.51 719.985 1286.69C719.985 1286.74 719.985 1286.79 719.963 1286.85C719.94 1286.9 719.91 1286.95 719.888 1286.98L714.544 1292.45C715.897 1292.58 717.22 1293.15 718.227 1294.19L718.219 1294.19Z" fill="#5EB0EF"/>
<path d="M727.305 1304.08L734.437 1287.08H724.863C724.682 1287.08 724.525 1286.92 724.525 1286.74C724.525 1286.58 724.682 1286.4 724.863 1286.4H734.978C735.136 1286.4 735.317 1286.58 735.317 1286.74C735.317 1286.82 735.264 1286.9 735.211 1286.98L727.899 1304.34C727.876 1304.45 727.741 1304.55 727.613 1304.55C727.591 1304.55 727.538 1304.53 727.486 1304.53C727.305 1304.45 727.253 1304.27 727.305 1304.08Z" fill="#5EB0EF"/>
</g>
<g opacity="0.96">
<path d="M562.743 1490.56C563.014 1490.83 563.156 1491.2 563.156 1491.56C563.156 1491.92 563.014 1492.29 562.743 1492.58C562.465 1492.85 562.097 1492.99 561.736 1492.99C561.375 1492.99 561.014 1492.86 560.736 1492.58C560.706 1492.55 560.706 1492.5 560.736 1492.47C560.766 1492.44 560.819 1492.44 560.849 1492.47C561.097 1492.71 561.42 1492.83 561.736 1492.83C562.052 1492.83 562.39 1492.71 562.63 1492.46C562.878 1492.22 562.999 1491.89 562.999 1491.56C562.999 1491.23 562.878 1490.92 562.63 1490.67C562.382 1490.43 562.067 1490.3 561.743 1490.3C561.713 1490.3 561.691 1490.28 561.668 1490.26C561.668 1490.26 561.638 1490.17 561.668 1490.14L562.901 1488.87H561.014C560.969 1488.87 560.932 1488.83 560.932 1488.79C560.932 1488.74 560.969 1488.71 561.014 1488.71H563.081C563.126 1488.71 563.156 1488.74 563.156 1488.79C563.156 1488.8 563.156 1488.81 563.156 1488.83C563.156 1488.84 563.141 1488.85 563.141 1488.86L561.886 1490.14C562.202 1490.17 562.518 1490.31 562.75 1490.55L562.743 1490.56Z" fill="#E71D36"/>
<path d="M564.216 1489.26L565.073 1488.76C565.11 1488.74 565.155 1488.74 565.185 1488.78C565.2 1488.82 565.185 1488.86 565.148 1488.89L564.291 1489.4C564.291 1489.4 564.269 1489.4 564.254 1489.4C564.231 1489.4 564.201 1489.4 564.186 1489.36C564.171 1489.33 564.171 1489.28 564.216 1489.25V1489.26ZM565.035 1488.82C565.035 1488.82 565.073 1488.74 565.11 1488.74C565.155 1488.74 565.193 1488.78 565.193 1488.82V1492.92C565.193 1492.97 565.163 1493.01 565.11 1493.01C565.073 1493.01 565.035 1492.97 565.035 1492.92V1488.82Z" fill="#E71D36"/>
</g>
<g opacity="0.77">
<path d="M741.411 1341.07H732.085C732.017 1341.07 731.972 1341.05 731.904 1341C731.792 1340.89 731.769 1340.73 731.859 1340.61L741.486 1328.63L741.531 1328.59C741.599 1328.54 741.644 1328.52 741.712 1328.52C741.802 1328.52 741.892 1328.59 741.937 1328.66C741.96 1328.68 741.96 1328.7 741.982 1328.72C741.982 1328.75 742.005 1328.77 742.005 1328.79V1340.5H743.395C743.576 1340.5 743.688 1340.61 743.688 1340.77C743.688 1340.95 743.576 1341.06 743.395 1341.06H742.005V1344.17C742.005 1344.33 741.892 1344.46 741.712 1344.46C741.577 1344.46 741.419 1344.33 741.419 1344.17V1341.06L741.411 1341.07ZM741.411 1340.5V1329.68L732.678 1340.5H741.411Z" fill="#E71D36"/>
<path d="M747.296 1338.38C747.296 1336.24 747.754 1333.84 748.844 1331.94C749.941 1330.02 751.767 1328.61 754.39 1328.5C754.548 1328.5 754.683 1328.61 754.683 1328.79C754.683 1328.93 754.57 1329.06 754.435 1329.06C751.993 1329.17 750.422 1330.43 749.347 1332.21C748.528 1333.6 748.092 1335.27 747.934 1336.91C748.776 1335.24 750.512 1334.13 752.519 1334.13C755.367 1334.13 757.697 1336.43 757.719 1339.29C757.697 1342.16 755.367 1344.47 752.519 1344.47C749.851 1344.47 747.664 1342.49 747.386 1339.88C747.363 1339.86 747.363 1339.82 747.363 1339.79C747.318 1339.33 747.296 1338.86 747.296 1338.38ZM752.519 1343.9C755.074 1343.9 757.103 1341.85 757.148 1339.29C757.103 1336.76 755.074 1334.7 752.519 1334.7C749.964 1334.7 747.934 1336.76 747.934 1339.29C747.934 1341.82 749.986 1343.9 752.519 1343.9Z" fill="#E71D36"/>
</g>
<g opacity="0.91">
<path d="M678.539 1468.08C679.448 1468.99 679.914 1470.21 679.914 1471.4C679.914 1472.59 679.448 1473.83 678.539 1474.76C677.607 1475.67 676.397 1476.14 675.202 1476.14H675.179C673.984 1476.14 672.774 1475.69 671.865 1474.76C671.767 1474.66 671.767 1474.5 671.865 1474.4C671.963 1474.3 672.128 1474.3 672.226 1474.4C673.053 1475.21 674.105 1475.61 675.179 1475.61C676.254 1475.61 677.344 1475.21 678.155 1474.38C678.982 1473.57 679.388 1472.5 679.388 1471.4C679.388 1470.3 678.982 1469.27 678.17 1468.44C677.344 1467.63 676.292 1467.23 675.217 1467.23C675.119 1467.23 675.037 1467.2 674.976 1467.11C674.976 1467.09 674.954 1467.09 674.954 1467.09C674.849 1466.99 674.849 1466.8 674.954 1466.71L679.042 1462.5H672.79C672.647 1462.5 672.526 1462.38 672.526 1462.23C672.526 1462.09 672.647 1461.97 672.79 1461.97H679.643C679.786 1461.97 679.906 1462.09 679.906 1462.23C679.906 1462.27 679.906 1462.31 679.884 1462.35C679.861 1462.39 679.846 1462.44 679.824 1462.45L675.66 1466.72C676.712 1466.82 677.742 1467.27 678.531 1468.08L678.539 1468.08Z" fill="#E71D36"/>
<path d="M688.797 1461.99C691.57 1462.01 693.629 1465.25 693.629 1469.05C693.629 1472.86 691.57 1476.14 688.797 1476.16C686.069 1476.14 683.987 1472.9 683.987 1469.05C683.987 1465.2 686.069 1462.01 688.797 1461.99ZM684.491 1469.05C684.491 1470.92 685.017 1472.57 685.806 1473.75C686.595 1474.97 687.662 1475.65 688.797 1475.65C689.932 1475.65 691.022 1474.97 691.811 1473.75C692.615 1472.57 693.126 1470.91 693.126 1469.05C693.126 1467.2 692.622 1465.57 691.811 1464.4C691.022 1463.18 689.947 1462.5 688.797 1462.5C687.647 1462.5 686.595 1463.18 685.806 1464.4C685.017 1465.57 684.491 1467.23 684.491 1469.05Z" fill="#E71D36"/>
</g>
<g opacity="0.68">
<path d="M578.443 1307.68C578.405 1307.65 578.405 1307.58 578.443 1307.54C578.48 1307.51 578.541 1307.51 578.578 1307.54C578.894 1307.85 579.3 1308.02 579.705 1308.02C580.111 1308.02 580.532 1307.85 580.84 1307.54C581.156 1307.23 581.314 1306.81 581.314 1306.41C581.314 1306 581.156 1305.59 580.84 1305.28C580.532 1304.97 580.126 1304.81 579.713 1304.81C579.3 1304.81 578.901 1304.96 578.578 1305.28C578.556 1305.32 578.488 1305.32 578.443 1305.29L578.428 1305.28C578.42 1305.27 578.42 1305.27 578.42 1305.27C578.405 1305.24 578.398 1305.22 578.405 1305.19L578.676 1302.91C578.683 1302.87 578.713 1302.84 578.743 1302.83C578.759 1302.82 578.773 1302.81 578.796 1302.81H581.404C581.456 1302.81 581.502 1302.87 581.502 1302.91C581.502 1302.97 581.456 1303.01 581.404 1303.01H578.864L578.631 1304.97C578.946 1304.73 579.322 1304.62 579.705 1304.62C580.171 1304.62 580.63 1304.79 580.991 1305.15C581.329 1305.49 581.509 1305.95 581.509 1306.41C581.509 1306.87 581.329 1307.33 580.975 1307.69C580.63 1308.04 580.164 1308.22 579.705 1308.22C579.247 1308.22 578.796 1308.05 578.435 1307.69L578.443 1307.68Z" fill="#E71D36"/>
<path d="M583.11 1306.15C583.11 1305.42 583.26 1304.62 583.636 1303.97C584.004 1303.33 584.62 1302.84 585.507 1302.81C585.56 1302.81 585.605 1302.84 585.605 1302.9C585.605 1302.95 585.567 1302.99 585.522 1302.99C584.696 1303.03 584.162 1303.46 583.801 1304.06C583.523 1304.54 583.373 1305.1 583.32 1305.66C583.606 1305.09 584.192 1304.72 584.868 1304.72C585.83 1304.72 586.619 1305.5 586.627 1306.46C586.619 1307.43 585.83 1308.21 584.868 1308.21C583.967 1308.21 583.223 1307.54 583.132 1306.66C583.125 1306.65 583.125 1306.63 583.125 1306.63C583.11 1306.48 583.102 1306.31 583.102 1306.15L583.11 1306.15ZM584.876 1308.03C585.74 1308.03 586.432 1307.33 586.447 1306.46C586.432 1305.6 585.74 1304.91 584.876 1304.91C584.012 1304.91 583.328 1305.6 583.328 1306.46C583.328 1307.32 584.019 1308.03 584.876 1308.03Z" fill="#E71D36"/>
</g>
<g opacity="0.68">
<path d="M567.824 1331.46L570.281 1330C570.386 1329.93 570.529 1329.95 570.597 1330.07C570.649 1330.17 570.612 1330.29 570.492 1330.37L568.034 1331.84C568.034 1331.84 567.967 1331.86 567.929 1331.86C567.861 1331.86 567.771 1331.84 567.734 1331.75C567.681 1331.66 567.696 1331.53 567.824 1331.45V1331.46ZM570.176 1330.18C570.176 1330.08 570.281 1329.96 570.386 1329.96C570.529 1329.96 570.612 1330.08 570.612 1330.18V1342C570.612 1342.12 570.522 1342.22 570.386 1342.22C570.281 1342.22 570.176 1342.12 570.176 1342V1330.18Z" fill="#5EB0EF"/>
<path d="M580.156 1335.22C580.945 1336.01 581.351 1337.06 581.351 1338.1C581.351 1339.14 580.945 1340.21 580.156 1341.01C579.352 1341.8 578.3 1342.21 577.263 1342.21H577.248C576.211 1342.21 575.159 1341.82 574.37 1341.01C574.279 1340.92 574.279 1340.79 574.37 1340.7C574.46 1340.61 574.595 1340.61 574.685 1340.7C575.407 1341.4 576.316 1341.75 577.248 1341.75C578.18 1341.75 579.127 1341.4 579.826 1340.68C580.547 1339.98 580.893 1339.05 580.893 1338.1C580.893 1337.15 580.54 1336.26 579.841 1335.54C579.119 1334.84 578.21 1334.48 577.278 1334.48C577.188 1334.48 577.12 1334.45 577.068 1334.38C577.068 1334.36 577.053 1334.36 577.053 1334.36C576.962 1334.27 576.962 1334.12 577.053 1334.03L580.592 1330.39H575.174C575.053 1330.39 574.948 1330.28 574.948 1330.16C574.948 1330.04 575.053 1329.93 575.174 1329.93H581.118C581.238 1329.93 581.344 1330.04 581.344 1330.16C581.344 1330.2 581.344 1330.23 581.329 1330.26C581.314 1330.3 581.299 1330.33 581.276 1330.36L577.661 1334.06C578.571 1334.15 579.465 1334.54 580.149 1335.24L580.156 1335.22Z" fill="#5EB0EF"/>
</g>
<g opacity="0.5">
<path d="M561.721 1342.93C564.111 1342.93 566.065 1344.89 566.065 1347.31C566.065 1349.04 565.028 1350.56 563.578 1351.25C566.539 1352.07 568.741 1354.78 568.741 1357.99C568.741 1361.86 565.592 1364.98 561.721 1364.98C557.851 1364.98 554.702 1361.86 554.702 1357.99C554.702 1354.78 556.904 1352.06 559.865 1351.25C558.354 1350.56 557.347 1349.04 557.347 1347.31C557.347 1344.89 559.301 1342.93 561.721 1342.93ZM561.721 1364.19C565.186 1364.19 567.951 1361.42 567.951 1357.99C567.951 1354.56 565.178 1351.75 561.721 1351.75C558.264 1351.75 555.491 1354.56 555.491 1357.99C555.491 1361.42 558.294 1364.19 561.721 1364.19ZM561.721 1350.86C563.675 1350.86 565.276 1349.25 565.276 1347.3C565.276 1345.34 563.668 1343.74 561.721 1343.71C559.737 1343.74 558.129 1345.31 558.129 1347.3C558.129 1349.28 559.737 1350.86 561.721 1350.86Z" fill="#E71D36"/>
<path d="M582.343 1342.93C586.657 1342.96 589.866 1348 589.866 1353.93C589.866 1359.85 586.657 1364.95 582.343 1364.98C578.09 1364.95 574.851 1359.91 574.851 1353.93C574.851 1347.94 578.09 1342.97 582.343 1342.93ZM575.64 1353.93C575.64 1356.83 576.459 1359.41 577.684 1361.24C578.909 1363.12 580.577 1364.2 582.343 1364.2C584.109 1364.2 585.808 1363.13 587.033 1361.24C588.295 1359.41 589.077 1356.82 589.077 1353.93C589.077 1351.03 588.288 1348.51 587.033 1346.68C585.808 1344.8 584.139 1343.72 582.343 1343.72C580.547 1343.72 578.909 1344.79 577.684 1346.68C576.459 1348.51 575.64 1351.09 575.64 1353.93Z" fill="#E71D36"/>
</g>
<g opacity="0.86">
<path d="M479.602 1393.47L484.217 1389.3C484.803 1388.75 485.118 1388.02 485.118 1387.28C485.118 1386.64 484.87 1385.99 484.412 1385.46C483.878 1384.87 483.127 1384.56 482.405 1384.56C481.752 1384.56 481.098 1384.81 480.579 1385.26L480.098 1385.7C480.016 1385.77 479.918 1385.77 479.85 1385.7C479.783 1385.63 479.798 1385.51 479.865 1385.45L480.346 1385C480.947 1384.47 481.669 1384.21 482.405 1384.21C483.24 1384.21 484.074 1384.55 484.675 1385.21C485.194 1385.8 485.464 1386.55 485.464 1387.27C485.464 1388.11 485.126 1388.94 484.442 1389.54L480.158 1393.44H485.306C485.389 1393.44 485.472 1393.51 485.472 1393.61C485.472 1393.7 485.389 1393.79 485.306 1393.79H479.737C479.685 1393.79 479.625 1393.77 479.602 1393.72C479.55 1393.65 479.55 1393.54 479.617 1393.47H479.602Z" fill="#5EB0EF"/>
<path d="M487.876 1392.84C487.809 1392.79 487.809 1392.66 487.876 1392.59C487.944 1392.52 488.057 1392.52 488.124 1392.59C488.68 1393.14 489.409 1393.42 490.116 1393.42C490.822 1393.42 491.574 1393.14 492.122 1392.59C492.671 1392.04 492.957 1391.3 492.957 1390.58C492.957 1389.86 492.686 1389.14 492.122 1388.59C491.574 1388.04 490.867 1387.77 490.131 1387.77C489.394 1387.77 488.695 1388.04 488.124 1388.59C488.087 1388.66 487.959 1388.66 487.891 1388.6C487.876 1388.6 487.861 1388.59 487.861 1388.57C487.846 1388.57 487.846 1388.56 487.846 1388.56C487.816 1388.52 487.809 1388.48 487.816 1388.42L488.297 1384.39C488.312 1384.31 488.365 1384.26 488.417 1384.24C488.44 1384.23 488.47 1384.21 488.515 1384.21H493.129C493.227 1384.21 493.31 1384.31 493.31 1384.39C493.31 1384.5 493.227 1384.57 493.129 1384.57H488.643L488.23 1388.03C488.786 1387.61 489.455 1387.4 490.123 1387.4C490.943 1387.4 491.762 1387.7 492.393 1388.33C492.994 1388.95 493.31 1389.75 493.31 1390.57C493.31 1391.39 492.994 1392.21 492.37 1392.83C491.754 1393.45 490.935 1393.77 490.116 1393.77C489.297 1393.77 488.508 1393.45 487.876 1392.83V1392.84Z" fill="#5EB0EF"/>
</g>
<g opacity="0.66">
<path d="M538.574 1345.28C538.447 1345.18 538.447 1344.95 538.574 1344.82C538.702 1344.69 538.905 1344.69 539.04 1344.82C540.092 1345.85 541.453 1346.38 542.79 1346.38C544.128 1346.38 545.534 1345.84 546.563 1344.82C547.615 1343.76 548.126 1342.4 548.126 1341.04C548.126 1339.68 547.615 1338.32 546.563 1337.29C545.534 1336.26 544.203 1335.75 542.813 1335.75C541.423 1335.75 540.115 1336.26 539.04 1337.29C538.965 1337.42 538.732 1337.42 538.604 1337.31C538.582 1337.31 538.552 1337.29 538.552 1337.26C538.529 1337.26 538.529 1337.24 538.529 1337.24C538.477 1337.16 538.454 1337.09 538.477 1336.98L539.379 1329.41C539.401 1329.26 539.506 1329.15 539.611 1329.12C539.664 1329.1 539.717 1329.07 539.792 1329.07H548.464C548.645 1329.07 548.795 1329.25 548.795 1329.4C548.795 1329.6 548.645 1329.74 548.464 1329.74H540.025L539.258 1336.26C540.31 1335.46 541.565 1335.08 542.828 1335.08C544.369 1335.08 545.909 1335.64 547.089 1336.82C548.216 1337.98 548.81 1339.49 548.81 1341.03C548.81 1342.58 548.224 1344.12 547.037 1345.28C545.879 1346.46 544.339 1347.05 542.798 1347.05C541.257 1347.05 539.769 1346.46 538.589 1345.28L538.574 1345.28Z" fill="#E71D36"/>
<path d="M559.564 1329.08C561.518 1329.08 563.104 1330.67 563.104 1332.65C563.104 1334.06 562.255 1335.3 561.075 1335.86C563.487 1336.53 565.284 1338.73 565.284 1341.36C565.284 1344.52 562.721 1347.06 559.557 1347.06C556.393 1347.06 553.83 1344.52 553.83 1341.36C553.83 1338.74 555.626 1336.53 558.039 1335.86C556.806 1335.3 555.987 1334.06 555.987 1332.65C555.987 1330.67 557.58 1329.08 559.557 1329.08H559.564ZM559.564 1346.41C562.39 1346.41 564.645 1344.15 564.645 1341.35C564.645 1338.55 562.383 1336.27 559.564 1336.27C556.746 1336.27 554.484 1338.55 554.484 1341.35C554.484 1344.15 556.769 1346.41 559.564 1346.41ZM559.564 1335.54C561.158 1335.54 562.465 1334.24 562.465 1332.64C562.465 1331.05 561.158 1329.74 559.564 1329.72C557.949 1329.74 556.641 1331.02 556.641 1332.64C556.641 1334.26 557.949 1335.54 559.564 1335.54Z" fill="#E71D36"/>
</g>
<g opacity="0.94">
<path d="M484.344 1317.88C484.72 1317.88 485.028 1318.17 485.058 1318.54C485.066 1318.6 485.073 1318.67 485.073 1318.73C485.073 1319.03 485.013 1319.36 484.855 1319.63C484.697 1319.9 484.449 1320.1 484.081 1320.11C484.059 1320.11 484.036 1320.1 484.036 1320.07C484.036 1320.05 484.051 1320.03 484.074 1320.03C484.412 1320.01 484.637 1319.83 484.78 1319.59C484.893 1319.4 484.96 1319.16 484.975 1318.93C484.855 1319.16 484.615 1319.31 484.337 1319.31C483.938 1319.31 483.608 1318.99 483.608 1318.59C483.608 1318.19 483.938 1317.87 484.337 1317.87L484.344 1317.88ZM484.344 1319.24C484.697 1319.24 484.983 1318.95 484.983 1318.6C484.983 1318.58 484.983 1318.57 484.983 1318.55V1318.54C484.953 1318.21 484.675 1317.95 484.344 1317.95C483.983 1317.95 483.698 1318.24 483.698 1318.6C483.698 1318.96 483.991 1319.24 484.344 1319.24Z" fill="#E71D36"/>
</g>
<g opacity="0.67">
<path d="M455.531 1358.45L463.827 1350.95C464.88 1349.97 465.451 1348.65 465.451 1347.31C465.451 1346.16 465.007 1344.98 464.173 1344.05C463.219 1342.99 461.866 1342.43 460.566 1342.43C459.386 1342.43 458.206 1342.87 457.274 1343.7L456.417 1344.49C456.267 1344.61 456.094 1344.61 455.974 1344.49C455.854 1344.37 455.876 1344.15 455.997 1344.05L456.853 1343.24C457.935 1342.28 459.236 1341.82 460.558 1341.82C462.054 1341.82 463.549 1342.43 464.632 1343.61C465.563 1344.67 466.052 1346.01 466.052 1347.31C466.052 1348.81 465.436 1350.32 464.211 1351.39L456.5 1358.39H465.751C465.902 1358.39 466.044 1358.51 466.044 1358.69C466.044 1358.86 465.902 1359 465.751 1359H455.733C455.636 1359 455.538 1358.98 455.486 1358.88C455.388 1358.76 455.388 1358.56 455.508 1358.44L455.531 1358.45Z" fill="#5EB0EF"/>
<path d="M476.243 1341.81C479.61 1341.83 482.112 1345.77 482.112 1350.38C482.112 1355 479.61 1358.98 476.243 1359.01C472.928 1358.99 470.403 1355.05 470.403 1350.38C470.403 1345.71 472.928 1341.83 476.243 1341.81ZM471.012 1350.38C471.012 1352.65 471.651 1354.65 472.605 1356.08C473.56 1357.56 474.867 1358.39 476.235 1358.39C477.603 1358.39 478.933 1357.56 479.895 1356.08C480.88 1354.65 481.488 1352.65 481.488 1350.38C481.488 1348.12 480.872 1346.16 479.895 1344.73C478.941 1343.26 477.633 1342.43 476.235 1342.43C474.837 1342.43 473.56 1343.26 472.605 1344.73C471.651 1346.16 471.012 1348.17 471.012 1350.38Z" fill="#5EB0EF"/>
</g>
<g opacity="0.89">
<path d="M521.822 1298.94C521.822 1297.38 522.153 1295.64 522.95 1294.25C523.746 1292.86 525.076 1291.83 526.978 1291.75C527.098 1291.75 527.196 1291.83 527.196 1291.97C527.196 1292.07 527.113 1292.16 527.015 1292.16C525.242 1292.25 524.092 1293.16 523.318 1294.46C522.717 1295.47 522.401 1296.68 522.288 1297.88C522.904 1296.67 524.16 1295.86 525.625 1295.86C527.699 1295.86 529.39 1297.53 529.405 1299.61C529.39 1301.7 527.699 1303.38 525.625 1303.38C523.686 1303.38 522.093 1301.93 521.89 1300.04C521.875 1300.02 521.875 1299.99 521.875 1299.97C521.845 1299.64 521.822 1299.29 521.822 1298.94ZM525.625 1302.96C527.481 1302.96 528.962 1301.46 528.992 1299.6C528.962 1297.76 527.481 1296.26 525.625 1296.26C523.769 1296.26 522.288 1297.76 522.288 1299.6C522.288 1301.44 523.784 1302.96 525.625 1302.96Z" fill="#5EB0EF"/>
<path d="M532.471 1298.94C532.471 1297.38 532.802 1295.64 533.599 1294.26C534.395 1292.86 535.726 1291.83 537.634 1291.75C537.747 1291.75 537.852 1291.83 537.852 1291.97C537.852 1292.07 537.77 1292.16 537.672 1292.16C535.898 1292.25 534.749 1293.16 533.974 1294.46C533.381 1295.47 533.065 1296.68 532.945 1297.88C533.561 1296.67 534.824 1295.86 536.282 1295.86C538.356 1295.86 540.047 1297.53 540.062 1299.61C540.047 1301.7 538.356 1303.38 536.282 1303.38C534.343 1303.38 532.749 1301.93 532.547 1300.04C532.532 1300.02 532.532 1299.99 532.532 1299.97C532.501 1299.64 532.479 1299.29 532.479 1298.94H532.471ZM536.274 1302.96C538.13 1302.96 539.611 1301.46 539.641 1299.6C539.611 1297.76 538.13 1296.26 536.274 1296.26C534.418 1296.26 532.937 1297.76 532.937 1299.6C532.937 1301.44 534.433 1302.96 536.274 1302.96Z" fill="#5EB0EF"/>
</g>
<g opacity="0.5">
<path d="M388.607 1234.77C389.644 1234.77 390.493 1235.56 390.583 1236.59C390.606 1236.76 390.621 1236.94 390.621 1237.11C390.621 1237.94 390.44 1238.86 390.027 1239.59C389.584 1240.33 388.9 1240.88 387.893 1240.93C387.818 1240.93 387.78 1240.88 387.78 1240.81C387.773 1240.76 387.825 1240.71 387.878 1240.71C388.817 1240.66 389.426 1240.17 389.832 1239.5C390.132 1238.97 390.313 1238.32 390.38 1237.69C390.057 1238.32 389.388 1238.76 388.622 1238.76C387.525 1238.76 386.615 1237.87 386.615 1236.77C386.615 1235.68 387.517 1234.78 388.622 1234.78L388.607 1234.77ZM388.607 1238.53C389.584 1238.53 390.373 1237.74 390.373 1236.76C390.373 1236.71 390.373 1236.67 390.365 1236.62V1236.59C390.275 1235.67 389.524 1234.98 388.607 1234.98C387.622 1234.98 386.826 1235.77 386.826 1236.75C386.826 1237.74 387.622 1238.52 388.607 1238.52V1238.53Z" fill="#E71D36"/>
<path d="M394.333 1234.77C395.536 1234.77 396.43 1236.18 396.43 1237.83C396.43 1239.49 395.536 1240.91 394.333 1240.92C393.146 1240.91 392.244 1239.5 392.244 1237.83C392.244 1236.16 393.146 1234.77 394.333 1234.77ZM392.462 1237.83C392.462 1238.65 392.688 1239.36 393.033 1239.87C393.379 1240.4 393.845 1240.7 394.333 1240.7C394.822 1240.7 395.303 1240.4 395.641 1239.87C395.994 1239.36 396.212 1238.64 396.212 1237.83C396.212 1237.03 395.994 1236.32 395.641 1235.81C395.295 1235.28 394.829 1234.98 394.333 1234.98C393.837 1234.98 393.379 1235.28 393.033 1235.81C392.688 1236.32 392.462 1237.04 392.462 1237.83Z" fill="#E71D36"/>
</g>
<g opacity="0.87">
<path d="M565.374 1294.05H563.134C563.134 1294.05 563.104 1294.05 563.089 1294.04C563.059 1294.01 563.059 1293.97 563.082 1293.95L565.389 1291.07H565.396C565.396 1291.07 565.426 1291.05 565.441 1291.05C565.464 1291.05 565.487 1291.07 565.494 1291.08C565.494 1291.08 565.494 1291.09 565.509 1291.1V1293.92H565.847C565.892 1293.92 565.922 1293.95 565.922 1293.99C565.922 1294.04 565.892 1294.06 565.847 1294.06H565.517V1294.8C565.517 1294.84 565.487 1294.88 565.441 1294.88C565.411 1294.88 565.374 1294.85 565.374 1294.8V1294.06V1294.05ZM565.374 1293.92V1291.32L563.277 1293.92H565.374Z" fill="#E71D36"/>
<path d="M566.696 1294.74L568.545 1293.07C568.778 1292.86 568.906 1292.56 568.906 1292.26C568.906 1292.01 568.808 1291.74 568.62 1291.53C568.41 1291.3 568.109 1291.17 567.816 1291.17C567.553 1291.17 567.29 1291.27 567.08 1291.46L566.892 1291.63C566.892 1291.63 566.817 1291.66 566.794 1291.63C566.764 1291.6 566.772 1291.56 566.794 1291.53L566.99 1291.35C567.23 1291.14 567.516 1291.04 567.816 1291.04C568.147 1291.04 568.485 1291.17 568.726 1291.43C568.936 1291.67 569.041 1291.97 569.041 1292.26C569.041 1292.59 568.906 1292.93 568.628 1293.17L566.914 1294.73H568.974C568.974 1294.73 569.041 1294.76 569.041 1294.8C569.041 1294.83 569.011 1294.87 568.974 1294.87H566.742C566.742 1294.87 566.696 1294.87 566.689 1294.84C566.666 1294.81 566.666 1294.77 566.689 1294.74H566.696Z" fill="#E71D36"/>
</g>
<g opacity="0.57">
<path d="M530.758 1211.72C530.931 1211.89 531.021 1212.13 531.021 1212.36C531.021 1212.59 530.931 1212.83 530.758 1213.01C530.578 1213.18 530.345 1213.27 530.12 1213.27C529.894 1213.27 529.654 1213.19 529.481 1213.01C529.458 1212.99 529.458 1212.96 529.481 1212.94C529.503 1212.93 529.533 1212.93 529.548 1212.94C529.706 1213.1 529.909 1213.17 530.12 1213.17C530.33 1213.17 530.533 1213.1 530.691 1212.93C530.849 1212.77 530.931 1212.57 530.931 1212.36C530.931 1212.15 530.856 1211.96 530.698 1211.79C530.54 1211.63 530.338 1211.56 530.127 1211.56C530.105 1211.56 530.09 1211.55 530.082 1211.53C530.082 1211.53 530.059 1211.47 530.082 1211.46L530.871 1210.65H529.669C529.669 1210.65 529.616 1210.62 529.616 1210.59C529.616 1210.56 529.639 1210.54 529.669 1210.54H530.984C530.984 1210.54 531.036 1210.56 531.036 1210.59C531.036 1210.6 531.036 1210.61 531.036 1210.62V1210.63L530.225 1211.46C530.428 1211.48 530.623 1211.56 530.773 1211.72H530.758Z" fill="#E71D36"/>
<path d="M531.742 1213.18L533.058 1211.99C533.223 1211.83 533.313 1211.62 533.313 1211.41C533.313 1211.23 533.245 1211.05 533.11 1210.89C532.96 1210.73 532.742 1210.64 532.539 1210.64C532.351 1210.64 532.163 1210.71 532.02 1210.84L531.885 1210.96C531.885 1210.96 531.833 1210.98 531.818 1210.96C531.795 1210.95 531.803 1210.91 531.818 1210.89L531.953 1210.77C532.126 1210.62 532.329 1210.54 532.539 1210.54C532.78 1210.54 533.012 1210.64 533.185 1210.83C533.336 1210.99 533.411 1211.21 533.411 1211.41C533.411 1211.65 533.313 1211.89 533.118 1212.06L531.893 1213.17H533.358C533.358 1213.17 533.403 1213.19 533.403 1213.21C533.403 1213.23 533.381 1213.26 533.358 1213.26H531.773C531.773 1213.26 531.742 1213.26 531.735 1213.24C531.72 1213.22 531.72 1213.19 531.735 1213.17L531.742 1213.18Z" fill="#E71D36"/>
</g>
<g opacity="0.96">
<path d="M509.798 1325.18H502.869C502.816 1325.18 502.786 1325.17 502.734 1325.13C502.651 1325.05 502.628 1324.93 502.696 1324.84L509.843 1315.95L509.873 1315.91C509.926 1315.88 509.956 1315.86 510.008 1315.86C510.076 1315.86 510.144 1315.91 510.174 1315.95C510.189 1315.97 510.189 1315.99 510.211 1316.01C510.211 1316.02 510.226 1316.04 510.226 1316.06V1324.75H511.256C511.391 1324.75 511.474 1324.83 511.474 1324.96C511.474 1325.09 511.391 1325.17 511.256 1325.17H510.226V1327.47C510.226 1327.59 510.144 1327.69 510.008 1327.69C509.903 1327.69 509.79 1327.59 509.79 1327.47V1325.17L509.798 1325.18ZM509.798 1324.76V1316.73L503.312 1324.76H509.798Z" fill="#E71D36"/>
<path d="M514.186 1326.53C514.104 1326.47 514.104 1326.32 514.186 1326.23C514.269 1326.14 514.404 1326.14 514.487 1326.23C515.178 1326.9 516.08 1327.26 516.96 1327.26C517.839 1327.26 518.771 1326.9 519.447 1326.23C520.138 1325.53 520.484 1324.63 520.484 1323.74C520.484 1322.84 520.146 1321.94 519.447 1321.26C518.771 1320.59 517.891 1320.25 516.975 1320.25C516.058 1320.25 515.193 1320.59 514.487 1321.26C514.434 1321.35 514.284 1321.35 514.201 1321.28C514.186 1321.28 514.164 1321.26 514.164 1321.25C514.149 1321.25 514.149 1321.23 514.149 1321.23C514.111 1321.18 514.096 1321.13 514.111 1321.06L514.705 1316.06C514.72 1315.95 514.788 1315.89 514.855 1315.87C514.885 1315.86 514.923 1315.83 514.975 1315.83H520.702C520.822 1315.83 520.92 1315.95 520.92 1316.05C520.92 1316.19 520.815 1316.27 520.702 1316.27H515.133L514.622 1320.58C515.321 1320.05 516.148 1319.8 516.975 1319.8C517.989 1319.8 519.004 1320.17 519.785 1320.95C520.529 1321.71 520.92 1322.71 520.92 1323.72C520.92 1324.74 520.529 1325.75 519.748 1326.52C518.989 1327.3 517.967 1327.69 516.952 1327.69C515.937 1327.69 514.953 1327.29 514.179 1326.52L514.186 1326.53Z" fill="#E71D36"/>
</g>
<g opacity="0.82">
<path d="M322.104 1237.74L325.336 1230.03H321C320.917 1230.03 320.849 1229.96 320.849 1229.88C320.849 1229.8 320.917 1229.73 321 1229.73H325.584C325.652 1229.73 325.734 1229.81 325.734 1229.88C325.734 1229.91 325.712 1229.94 325.689 1229.98L322.375 1237.86C322.36 1237.9 322.307 1237.95 322.247 1237.95C322.232 1237.95 322.21 1237.94 322.187 1237.94C322.104 1237.9 322.082 1237.82 322.104 1237.74Z" fill="#E71D36"/>
<path d="M327.395 1237.68L331.371 1234.09C331.874 1233.61 332.145 1232.98 332.145 1232.34C332.145 1231.79 331.934 1231.22 331.536 1230.78C331.078 1230.28 330.431 1230 329.808 1230C329.244 1230 328.68 1230.22 328.229 1230.61L327.816 1230.99C327.741 1231.05 327.666 1231.05 327.606 1230.99C327.546 1230.93 327.561 1230.82 327.621 1230.78L328.034 1230.39C328.553 1229.93 329.176 1229.7 329.808 1229.7C330.529 1229.7 331.243 1230 331.762 1230.56C332.205 1231.07 332.445 1231.71 332.445 1232.34C332.445 1233.06 332.152 1233.77 331.566 1234.29L327.876 1237.65H332.31C332.378 1237.65 332.453 1237.71 332.453 1237.79C332.453 1237.87 332.385 1237.94 332.31 1237.94H327.515C327.47 1237.94 327.425 1237.92 327.395 1237.88C327.35 1237.82 327.35 1237.73 327.41 1237.67L327.395 1237.68Z" fill="#E71D36"/>
</g>
<g opacity="0.96">
<path d="M363.641 1318.17L367.429 1309.14H362.348C362.251 1309.14 362.168 1309.06 362.168 1308.96C362.168 1308.88 362.251 1308.78 362.348 1308.78H367.722C367.804 1308.78 367.902 1308.88 367.902 1308.96C367.902 1309 367.872 1309.04 367.85 1309.08L363.964 1318.3C363.949 1318.35 363.881 1318.41 363.814 1318.41C363.799 1318.41 363.776 1318.4 363.746 1318.4C363.648 1318.36 363.618 1318.26 363.648 1318.16L363.641 1318.17Z" fill="#5EB0EF"/>
<path d="M374.696 1312.92C375.312 1313.54 375.635 1314.37 375.635 1315.19C375.635 1316.01 375.32 1316.84 374.696 1317.47C374.065 1318.09 373.238 1318.41 372.426 1318.41H372.411C371.6 1318.41 370.773 1318.11 370.149 1317.47C370.082 1317.4 370.082 1317.29 370.149 1317.22C370.217 1317.16 370.33 1317.16 370.397 1317.22C370.961 1317.77 371.675 1318.05 372.411 1318.05C373.148 1318.05 373.884 1317.77 374.433 1317.21C374.997 1316.66 375.275 1315.93 375.275 1315.19C375.275 1314.44 374.997 1313.73 374.448 1313.17C373.884 1312.62 373.17 1312.34 372.434 1312.34C372.366 1312.34 372.306 1312.31 372.268 1312.26C372.268 1312.24 372.253 1312.24 372.253 1312.24C372.186 1312.18 372.186 1312.05 372.253 1311.98L375.034 1309.12H370.78C370.683 1309.12 370.6 1309.03 370.6 1308.94C370.6 1308.84 370.683 1308.76 370.78 1308.76H375.447C375.545 1308.76 375.628 1308.84 375.628 1308.94C375.628 1308.97 375.628 1308.99 375.613 1309.02C375.598 1309.05 375.583 1309.07 375.575 1309.09L372.734 1312C373.448 1312.06 374.155 1312.37 374.688 1312.92H374.696Z" fill="#5EB0EF"/>
</g>
<g opacity="0.84">
<path d="M330.108 1193.48H320.571C320.504 1193.48 320.451 1193.46 320.383 1193.42C320.271 1193.3 320.241 1193.14 320.338 1193.02L330.176 1180.77L330.221 1180.72C330.289 1180.68 330.341 1180.65 330.409 1180.65C330.499 1180.65 330.597 1180.72 330.642 1180.79C330.664 1180.81 330.664 1180.83 330.687 1180.86C330.687 1180.88 330.709 1180.9 330.709 1180.92V1192.9H332.13C332.318 1192.9 332.43 1193.02 332.43 1193.18C332.43 1193.36 332.31 1193.48 332.13 1193.48H330.709V1196.65C330.709 1196.82 330.597 1196.95 330.409 1196.95C330.266 1196.95 330.108 1196.81 330.108 1196.65V1193.48V1193.48ZM330.108 1192.9V1181.83L321.18 1192.9H330.108Z" fill="#E71D36"/>
</g>
<g opacity="0.85">
<path d="M346.439 1268.34C346.875 1268.77 347.093 1269.35 347.093 1269.93C347.093 1270.5 346.867 1271.08 346.439 1271.53C345.996 1271.97 345.417 1272.2 344.846 1272.2H344.838C344.267 1272.2 343.688 1271.99 343.252 1271.53C343.2 1271.49 343.2 1271.41 343.252 1271.36C343.298 1271.32 343.38 1271.32 343.425 1271.36C343.824 1271.75 344.327 1271.94 344.838 1271.94C345.349 1271.94 345.875 1271.75 346.259 1271.35C346.657 1270.96 346.845 1270.44 346.845 1269.93C346.845 1269.41 346.649 1268.91 346.266 1268.51C345.868 1268.12 345.364 1267.93 344.853 1267.93C344.808 1267.93 344.771 1267.91 344.74 1267.87H344.733C344.688 1267.81 344.688 1267.73 344.733 1267.68L346.687 1265.66H343.696C343.628 1265.66 343.568 1265.6 343.568 1265.53C343.568 1265.47 343.628 1265.41 343.696 1265.41H346.973C347.04 1265.41 347.1 1265.47 347.1 1265.53C347.1 1265.55 347.1 1265.57 347.093 1265.59C347.085 1265.62 347.07 1265.63 347.063 1265.64L345.071 1267.68C345.575 1267.74 346.071 1267.95 346.446 1268.33L346.439 1268.34Z" fill="#E71D36"/>
<path d="M349.031 1269.62C349.031 1268.71 349.227 1267.69 349.685 1266.89C350.151 1266.07 350.925 1265.47 352.037 1265.43C352.105 1265.43 352.165 1265.47 352.165 1265.56C352.165 1265.62 352.12 1265.67 352.06 1265.67C351.023 1265.71 350.362 1266.25 349.903 1267.01C349.557 1267.59 349.37 1268.3 349.302 1269C349.663 1268.29 350.399 1267.82 351.248 1267.82C352.458 1267.82 353.443 1268.8 353.45 1270.01C353.443 1271.23 352.451 1272.2 351.248 1272.2C350.114 1272.2 349.189 1271.36 349.076 1270.26C349.076 1270.26 349.069 1270.23 349.069 1270.22C349.046 1270.02 349.039 1269.82 349.039 1269.62H349.031ZM351.248 1271.96C352.331 1271.96 353.195 1271.08 353.21 1270C353.195 1268.93 352.331 1268.05 351.248 1268.05C350.166 1268.05 349.302 1268.93 349.302 1270C349.302 1271.08 350.174 1271.96 351.248 1271.96Z" fill="#E71D36"/>
</g>
<g opacity="0.94">
<path d="M431.429 1210.22L434.518 1207.43C434.909 1207.06 435.119 1206.57 435.119 1206.07C435.119 1205.65 434.954 1205.2 434.646 1204.86C434.285 1204.46 433.789 1204.25 433.3 1204.25C432.865 1204.25 432.421 1204.42 432.075 1204.73L431.752 1205.02C431.7 1205.07 431.632 1205.07 431.587 1205.02C431.542 1204.98 431.549 1204.89 431.594 1204.86L431.918 1204.55C432.323 1204.2 432.804 1204.02 433.3 1204.02C433.857 1204.02 434.42 1204.25 434.819 1204.69C435.164 1205.08 435.352 1205.59 435.352 1206.07C435.352 1206.63 435.127 1207.19 434.668 1207.59L431.797 1210.2H435.247C435.3 1210.2 435.36 1210.25 435.36 1210.32C435.36 1210.38 435.307 1210.44 435.247 1210.44H431.512C431.474 1210.44 431.437 1210.43 431.422 1210.39C431.384 1210.35 431.384 1210.27 431.429 1210.23V1210.22Z" fill="#5EB0EF"/>
<path d="M436.825 1210.22L439.914 1207.43C440.305 1207.06 440.515 1206.57 440.515 1206.07C440.515 1205.65 440.35 1205.2 440.042 1204.86C439.681 1204.46 439.185 1204.25 438.697 1204.25C438.261 1204.25 437.817 1204.42 437.472 1204.73L437.148 1205.02C437.096 1205.07 437.028 1205.07 436.983 1205.02C436.938 1204.98 436.946 1204.89 436.991 1204.86L437.314 1204.55C437.72 1204.2 438.201 1204.02 438.697 1204.02C439.253 1204.02 439.809 1204.25 440.215 1204.69C440.56 1205.08 440.741 1205.59 440.741 1206.07C440.741 1206.63 440.515 1207.19 440.057 1207.59L437.186 1210.2H440.636C440.688 1210.2 440.741 1210.25 440.741 1210.32C440.741 1210.38 440.688 1210.44 440.636 1210.44H436.9C436.9 1210.44 436.825 1210.43 436.81 1210.39C436.773 1210.35 436.773 1210.27 436.818 1210.23L436.825 1210.22Z" fill="#5EB0EF"/>
</g>
<g opacity="0.55">
<path d="M318.159 1275.57C319.985 1275.57 321.481 1277.07 321.481 1278.92C321.481 1280.24 320.684 1281.4 319.579 1281.93C321.841 1282.55 323.525 1284.63 323.525 1287.08C323.525 1290.04 321.12 1292.43 318.159 1292.43C315.198 1292.43 312.793 1290.04 312.793 1287.08C312.793 1284.62 314.476 1282.55 316.739 1281.93C315.581 1281.4 314.815 1280.24 314.815 1278.92C314.815 1277.06 316.31 1275.57 318.159 1275.57ZM318.159 1291.83C320.804 1291.83 322.924 1289.71 322.924 1287.09C322.924 1284.46 320.804 1282.32 318.159 1282.32C315.514 1282.32 313.394 1284.46 313.394 1287.09C313.394 1289.71 315.536 1291.83 318.159 1291.83ZM318.159 1281.64C319.654 1281.64 320.879 1280.42 320.879 1278.92C320.879 1277.42 319.654 1276.2 318.159 1276.18C316.641 1276.2 315.416 1277.4 315.416 1278.92C315.416 1280.44 316.641 1281.64 318.159 1281.64Z" fill="#E71D36"/>
<path d="M330.507 1292.01L337.127 1276.23H328.244C328.079 1276.23 327.929 1276.08 327.929 1275.91C327.929 1275.77 328.072 1275.6 328.244 1275.6H337.631C337.774 1275.6 337.947 1275.77 337.947 1275.91C337.947 1275.99 337.902 1276.05 337.849 1276.13L331.063 1292.25C331.04 1292.34 330.92 1292.44 330.8 1292.44C330.777 1292.44 330.724 1292.42 330.679 1292.42C330.514 1292.35 330.461 1292.18 330.514 1292.01L330.507 1292.01Z" fill="#E71D36"/>
</g>
<g opacity="0.73">
<path d="M353.751 1180.8C353.751 1179.89 353.946 1178.89 354.405 1178.1C354.863 1177.29 355.63 1176.7 356.727 1176.65C356.795 1176.65 356.855 1176.7 356.855 1176.78C356.855 1176.84 356.81 1176.89 356.75 1176.89C355.728 1176.94 355.066 1177.46 354.615 1178.21C354.27 1178.8 354.089 1179.5 354.022 1180.18C354.375 1179.48 355.104 1179.01 355.945 1179.01C357.14 1179.01 358.117 1179.98 358.125 1181.18C358.117 1182.38 357.14 1183.35 355.945 1183.35C354.826 1183.35 353.909 1182.52 353.796 1181.43C353.789 1181.42 353.789 1181.4 353.789 1181.39C353.766 1181.2 353.758 1181 353.758 1180.8H353.751ZM355.945 1183.11C357.013 1183.11 357.869 1182.25 357.884 1181.18C357.862 1180.12 357.013 1179.25 355.945 1179.25C354.878 1179.25 354.022 1180.12 354.022 1181.18C354.022 1182.24 354.886 1183.11 355.945 1183.11Z" fill="#5EB0EF"/>
<path d="M359.455 1177.48L360.793 1176.68C360.853 1176.64 360.928 1176.65 360.966 1176.72C360.996 1176.78 360.973 1176.85 360.906 1176.89L359.568 1177.69C359.568 1177.69 359.53 1177.7 359.508 1177.7C359.47 1177.7 359.425 1177.69 359.403 1177.64C359.373 1177.59 359.388 1177.52 359.448 1177.47L359.455 1177.48ZM360.74 1176.78C360.74 1176.72 360.801 1176.65 360.853 1176.65C360.928 1176.65 360.981 1176.72 360.981 1176.78V1183.22C360.981 1183.29 360.936 1183.35 360.853 1183.35C360.793 1183.35 360.74 1183.29 360.74 1183.22V1176.78Z" fill="#5EB0EF"/>
</g>
<g opacity="0.78">
<path d="M321.706 1161.73H319.174C319.174 1161.73 319.143 1161.73 319.121 1161.71C319.091 1161.68 319.083 1161.63 319.106 1161.6L321.721 1158.35L321.736 1158.33C321.736 1158.33 321.766 1158.32 321.789 1158.32C321.811 1158.32 321.841 1158.33 321.849 1158.35V1158.37C321.849 1158.38 321.864 1158.38 321.864 1158.39V1161.57H322.24C322.292 1161.57 322.322 1161.6 322.322 1161.65C322.322 1161.7 322.292 1161.73 322.24 1161.73H321.864V1162.57C321.864 1162.62 321.834 1162.66 321.781 1162.66C321.744 1162.66 321.699 1162.62 321.699 1162.57V1161.73H321.706ZM321.706 1161.57V1158.63L319.331 1161.57H321.706Z" fill="#5EB0EF"/>
<path d="M325.825 1161.73H323.285C323.285 1161.73 323.255 1161.73 323.232 1161.71C323.202 1161.68 323.195 1161.63 323.217 1161.6L325.833 1158.35L325.848 1158.33C325.848 1158.33 325.878 1158.32 325.9 1158.32C325.923 1158.32 325.953 1158.33 325.96 1158.35V1158.37C325.96 1158.38 325.975 1158.38 325.975 1158.39V1161.57H326.351C326.404 1161.57 326.434 1161.6 326.434 1161.65C326.434 1161.7 326.404 1161.73 326.351 1161.73H325.975V1162.57C325.975 1162.62 325.945 1162.66 325.893 1162.66C325.855 1162.66 325.81 1162.62 325.81 1162.57V1161.73H325.825ZM325.825 1161.57V1158.63L323.45 1161.57H325.825Z" fill="#5EB0EF"/>
</g>
<g opacity="0.68">
<path d="M341.163 1067.37L345.725 1056.5H339.608C339.495 1056.5 339.39 1056.4 339.39 1056.28C339.39 1056.18 339.487 1056.06 339.608 1056.06H346.078C346.176 1056.06 346.296 1056.18 346.296 1056.28C346.296 1056.33 346.259 1056.38 346.228 1056.43L341.546 1067.53C341.531 1067.6 341.449 1067.67 341.366 1067.67C341.351 1067.67 341.313 1067.65 341.283 1067.65C341.171 1067.6 341.133 1067.49 341.171 1067.38L341.163 1067.37Z" fill="#E71D36"/>
<path d="M352.691 1056.05C354.645 1056.05 356.261 1057.56 356.426 1059.5C356.479 1059.82 356.494 1060.15 356.494 1060.48C356.494 1062.04 356.163 1063.77 355.367 1065.16C354.54 1066.55 353.247 1067.58 351.339 1067.66C351.203 1067.66 351.121 1067.58 351.121 1067.44C351.106 1067.34 351.203 1067.25 351.301 1067.25C353.075 1067.16 354.217 1066.23 354.983 1064.96C355.547 1063.96 355.893 1062.73 356.013 1061.56C355.397 1062.76 354.134 1063.57 352.691 1063.57C350.617 1063.57 348.911 1061.89 348.911 1059.82C348.911 1057.74 350.617 1056.05 352.691 1056.05ZM352.691 1063.16C354.533 1063.16 356.028 1061.66 356.028 1059.82C356.028 1059.73 356.028 1059.65 356.013 1059.57V1059.49C355.848 1057.78 354.42 1056.46 352.691 1056.46C350.835 1056.46 349.324 1057.96 349.324 1059.82C349.324 1061.67 350.835 1063.16 352.691 1063.16Z" fill="#E71D36"/>
</g>
<g opacity="0.76">
<path d="M271.587 1108.48C271.91 1108.81 272.068 1109.23 272.068 1109.65C272.068 1110.07 271.903 1110.5 271.587 1110.83C271.256 1111.15 270.836 1111.31 270.415 1111.31C269.994 1111.31 269.573 1111.15 269.25 1110.83C269.212 1110.79 269.212 1110.74 269.25 1110.7C269.287 1110.66 269.34 1110.66 269.378 1110.7C269.671 1110.99 270.039 1111.13 270.415 1111.13C270.791 1111.13 271.174 1110.99 271.459 1110.7C271.752 1110.41 271.895 1110.03 271.895 1109.65C271.895 1109.27 271.752 1108.91 271.467 1108.61C271.174 1108.33 270.806 1108.18 270.43 1108.18C270.392 1108.18 270.37 1108.17 270.347 1108.14C270.347 1108.13 270.34 1108.13 270.34 1108.13C270.302 1108.09 270.302 1108.03 270.34 1108L271.775 1106.51H269.581C269.528 1106.51 269.49 1106.47 269.49 1106.42C269.49 1106.38 269.535 1106.33 269.581 1106.33H271.985C272.038 1106.33 272.076 1106.38 272.076 1106.42C272.076 1106.44 272.076 1106.45 272.076 1106.47C272.068 1106.48 272.061 1106.5 272.061 1106.5L270.588 1108C270.956 1108.04 271.317 1108.2 271.595 1108.48L271.587 1108.48Z" fill="#5EB0EF"/>
<path d="M273.458 1110.83C273.458 1110.83 273.421 1110.74 273.458 1110.7C273.496 1110.67 273.548 1110.67 273.586 1110.7C273.879 1110.99 274.255 1111.14 274.623 1111.14C274.991 1111.14 275.382 1110.99 275.668 1110.7C275.961 1110.41 276.104 1110.03 276.104 1109.66C276.104 1109.28 275.961 1108.91 275.668 1108.62C275.382 1108.33 275.014 1108.19 274.63 1108.19C274.247 1108.19 273.886 1108.33 273.586 1108.62C273.563 1108.66 273.503 1108.66 273.466 1108.63C273.458 1108.63 273.451 1108.62 273.451 1108.61V1108.6C273.436 1108.58 273.428 1108.56 273.436 1108.54L273.684 1106.44C273.684 1106.39 273.721 1106.37 273.744 1106.36C273.759 1106.36 273.774 1106.35 273.796 1106.35H276.194C276.246 1106.35 276.284 1106.4 276.284 1106.44C276.284 1106.5 276.239 1106.53 276.194 1106.53H273.856L273.646 1108.33C273.939 1108.12 274.285 1108 274.63 1108C275.059 1108 275.48 1108.16 275.81 1108.48C276.119 1108.8 276.284 1109.22 276.284 1109.65C276.284 1110.08 276.119 1110.5 275.795 1110.82C275.472 1111.15 275.051 1111.31 274.623 1111.31C274.195 1111.31 273.789 1111.15 273.458 1110.82V1110.83Z" fill="#5EB0EF"/>
</g>
<g opacity="0.66">
<path d="M341.809 1111.06L346.334 1106.98C346.912 1106.45 347.22 1105.72 347.22 1104.99C347.22 1104.36 346.98 1103.72 346.522 1103.21C346.003 1102.63 345.266 1102.32 344.553 1102.32C343.906 1102.32 343.267 1102.56 342.756 1103.02L342.29 1103.45C342.208 1103.52 342.118 1103.52 342.05 1103.45C341.982 1103.38 341.997 1103.26 342.065 1103.21L342.531 1102.77C343.117 1102.25 343.831 1101.99 344.553 1101.99C345.372 1101.99 346.183 1102.32 346.777 1102.97C347.288 1103.55 347.551 1104.29 347.551 1104.99C347.551 1105.81 347.213 1106.63 346.544 1107.22L342.343 1111.04H347.393C347.476 1111.04 347.551 1111.11 347.551 1111.2C347.551 1111.29 347.468 1111.37 347.393 1111.37H341.93C341.877 1111.37 341.824 1111.36 341.794 1111.3C341.742 1111.24 341.742 1111.13 341.809 1111.06Z" fill="#E71D36"/>
</g>
<g opacity="0.75">
<path d="M206.993 1095.56L217.266 1086.27C218.574 1085.06 219.273 1083.42 219.273 1081.77C219.273 1080.34 218.724 1078.88 217.695 1077.72C216.507 1076.42 214.839 1075.72 213.223 1075.72C211.765 1075.72 210.307 1076.26 209.15 1077.3L208.083 1078.27C207.902 1078.42 207.684 1078.42 207.534 1078.27C207.384 1078.11 207.414 1077.84 207.564 1077.72L208.631 1076.72C209.969 1075.53 211.577 1074.95 213.223 1074.95C215.08 1074.95 216.936 1075.71 218.266 1077.17C219.423 1078.48 220.032 1080.15 220.032 1081.76C220.032 1083.62 219.273 1085.47 217.755 1086.81L208.211 1095.48H219.671C219.852 1095.48 220.04 1095.63 220.04 1095.85C220.04 1096.07 219.859 1096.25 219.671 1096.25H207.271C207.151 1096.25 207.031 1096.22 206.971 1096.1C206.85 1095.95 206.85 1095.7 207.001 1095.55L206.993 1095.56Z" fill="#5EB0EF"/>
<path d="M232.65 1074.96C236.814 1074.99 239.917 1079.86 239.917 1085.58C239.917 1091.31 236.821 1096.23 232.65 1096.26C228.547 1096.23 225.413 1091.37 225.413 1085.58C225.413 1079.8 228.547 1074.99 232.65 1074.96ZM226.172 1085.58C226.172 1088.38 226.961 1090.88 228.149 1092.64C229.336 1094.47 230.944 1095.5 232.65 1095.5C234.356 1095.5 235.995 1094.47 237.182 1092.64C238.399 1090.88 239.158 1088.39 239.158 1085.58C239.158 1082.78 238.399 1080.35 237.182 1078.59C235.995 1076.76 234.386 1075.73 232.65 1075.73C230.914 1075.73 229.336 1076.76 228.149 1078.59C226.961 1080.36 226.172 1082.85 226.172 1085.58Z" fill="#5EB0EF"/>
</g>
<g opacity="0.83">
<path d="M227.179 1067.5L230.741 1065.38C230.892 1065.28 231.102 1065.31 231.2 1065.49C231.275 1065.64 231.222 1065.82 231.049 1065.92L227.48 1068.07C227.427 1068.09 227.374 1068.09 227.329 1068.09C227.232 1068.09 227.096 1068.07 227.051 1067.94C226.976 1067.81 226.999 1067.61 227.179 1067.5ZM230.591 1065.64C230.591 1065.49 230.741 1065.31 230.899 1065.31C231.102 1065.31 231.23 1065.49 231.23 1065.64V1082.79C231.23 1082.97 231.102 1083.12 230.899 1083.12C230.749 1083.12 230.591 1082.97 230.591 1082.79V1065.64Z" fill="#5EB0EF"/>
<path d="M242.465 1065.28C245.952 1065.31 248.553 1069.39 248.553 1074.18C248.553 1078.97 245.952 1083.1 242.465 1083.13C239.023 1083.11 236.4 1079.02 236.4 1074.18C236.4 1069.34 239.023 1065.31 242.465 1065.28ZM237.039 1074.18C237.039 1076.53 237.7 1078.62 238.692 1080.09C239.684 1081.62 241.037 1082.48 242.465 1082.48C243.893 1082.48 245.268 1081.62 246.26 1080.09C247.275 1078.61 247.914 1076.52 247.914 1074.18C247.914 1071.84 247.275 1069.8 246.26 1068.32C245.268 1066.79 243.916 1065.92 242.465 1065.92C241.015 1065.92 239.692 1066.78 238.692 1068.32C237.7 1069.8 237.039 1071.89 237.039 1074.18Z" fill="#5EB0EF"/>
</g>
<g opacity="0.6">
<path d="M134.832 996.552L135.876 995.928C135.922 995.898 135.982 995.905 136.012 995.958C136.034 996.003 136.019 996.055 135.967 996.086L134.922 996.717C134.922 996.717 134.892 996.725 134.877 996.725C134.847 996.725 134.809 996.717 134.794 996.68C134.772 996.642 134.779 996.582 134.832 996.552ZM135.839 996.003C135.839 995.958 135.884 995.905 135.929 995.905C135.989 995.905 136.027 995.958 136.027 996.003V1001.04C136.027 1001.09 135.989 1001.14 135.929 1001.14C135.884 1001.14 135.839 1001.09 135.839 1001.04V996.003Z" fill="#E71D36"/>
<path d="M140.573 1000.03H137.515C137.515 1000.03 137.477 1000.03 137.455 1000C137.417 999.966 137.41 999.914 137.44 999.876L140.596 995.943L140.611 995.928C140.611 995.928 140.649 995.905 140.671 995.905C140.701 995.905 140.731 995.928 140.746 995.95C140.746 995.958 140.746 995.965 140.761 995.973C140.761 995.973 140.769 995.988 140.769 995.995V999.838H141.227C141.287 999.838 141.325 999.876 141.325 999.929C141.325 999.989 141.287 1000.03 141.227 1000.03H140.769V1001.04C140.769 1001.09 140.731 1001.14 140.671 1001.14C140.626 1001.14 140.573 1001.09 140.573 1001.04V1000.03ZM140.573 999.838V996.288L137.71 999.838H140.573Z" fill="#E71D36"/>
</g>
<g opacity="0.79">
<path d="M178.33 1144.75L179.63 1143.58C179.796 1143.42 179.886 1143.21 179.886 1143C179.886 1142.82 179.818 1142.64 179.683 1142.49C179.533 1142.33 179.322 1142.24 179.119 1142.24C178.932 1142.24 178.751 1142.3 178.601 1142.44L178.466 1142.56C178.466 1142.56 178.413 1142.58 178.398 1142.56C178.383 1142.54 178.383 1142.51 178.398 1142.49L178.533 1142.36C178.699 1142.21 178.909 1142.14 179.112 1142.14C179.345 1142.14 179.578 1142.24 179.751 1142.42C179.901 1142.58 179.976 1142.79 179.976 1143C179.976 1143.23 179.878 1143.47 179.691 1143.64L178.481 1144.73H179.931C179.931 1144.73 179.976 1144.76 179.976 1144.78C179.976 1144.81 179.954 1144.83 179.931 1144.83H178.36C178.36 1144.83 178.33 1144.83 178.323 1144.81C178.308 1144.79 178.308 1144.76 178.323 1144.74L178.33 1144.75Z" fill="#E71D36"/>
<path d="M181.487 1142.14C181.78 1142.14 182.02 1142.38 182.02 1142.67C182.02 1142.88 181.893 1143.07 181.72 1143.15C182.08 1143.25 182.351 1143.59 182.351 1143.98C182.351 1144.46 181.968 1144.84 181.494 1144.84C181.021 1144.84 180.638 1144.46 180.638 1143.98C180.638 1143.59 180.908 1143.26 181.269 1143.15C181.081 1143.07 180.961 1142.88 180.961 1142.67C180.961 1142.37 181.201 1142.14 181.494 1142.14H181.487ZM181.487 1144.74C181.908 1144.74 182.246 1144.4 182.246 1143.98C182.246 1143.56 181.908 1143.22 181.487 1143.22C181.066 1143.22 180.728 1143.57 180.728 1143.98C180.728 1144.4 181.073 1144.74 181.487 1144.74ZM181.487 1143.11C181.727 1143.11 181.923 1142.91 181.923 1142.67C181.923 1142.43 181.727 1142.24 181.487 1142.24C181.246 1142.24 181.051 1142.43 181.051 1142.67C181.051 1142.91 181.246 1143.11 181.487 1143.11Z" fill="#E71D36"/>
</g>
<g opacity="0.96">
<path d="M122.462 1015.62C123.942 1015.62 125.152 1016.84 125.152 1018.34C125.152 1019.41 124.506 1020.36 123.612 1020.78C125.445 1021.29 126.813 1022.97 126.813 1024.97C126.813 1027.36 124.859 1029.3 122.462 1029.3C120.064 1029.3 118.11 1027.36 118.11 1024.97C118.11 1022.97 119.478 1021.3 121.312 1020.78C120.372 1020.36 119.749 1019.41 119.749 1018.34C119.749 1016.84 120.959 1015.62 122.462 1015.62ZM122.462 1028.81C124.611 1028.81 126.325 1027.09 126.325 1024.96C126.325 1022.83 124.611 1021.09 122.462 1021.09C120.312 1021.09 118.599 1022.83 118.599 1024.96C118.599 1027.09 120.335 1028.81 122.462 1028.81ZM122.462 1020.54C123.672 1020.54 124.664 1019.55 124.664 1018.34C124.664 1017.13 123.664 1016.14 122.462 1016.11C121.229 1016.14 120.237 1017.11 120.237 1018.34C120.237 1019.57 121.229 1020.54 122.462 1020.54Z" fill="#5EB0EF"/>
<path d="M134.975 1015.62C137.274 1015.62 139.168 1017.4 139.364 1019.69C139.424 1020.05 139.439 1020.45 139.439 1020.84C139.439 1022.67 139.048 1024.7 138.108 1026.34C137.132 1027.98 135.613 1029.19 133.366 1029.29C133.209 1029.29 133.111 1029.19 133.111 1029.03C133.088 1028.91 133.209 1028.8 133.321 1028.8C135.411 1028.7 136.756 1027.61 137.65 1026.11C138.311 1024.94 138.725 1023.49 138.86 1022.11C138.139 1023.51 136.658 1024.47 134.96 1024.47C132.517 1024.47 130.511 1022.5 130.511 1020.05C130.511 1017.61 132.525 1015.62 134.96 1015.62L134.975 1015.62ZM134.975 1023.99C137.139 1023.99 138.898 1022.23 138.898 1020.06C138.898 1019.96 138.898 1019.87 138.875 1019.77V1019.67C138.68 1017.66 137.004 1016.11 134.975 1016.11C132.788 1016.11 131.014 1017.87 131.014 1020.06C131.014 1022.25 132.788 1023.99 134.975 1023.99Z" fill="#5EB0EF"/>
</g>
<g opacity="0.56">
<path d="M58.5369 937.062C58.7774 937.303 58.8976 937.626 58.8976 937.942C58.8976 938.258 58.7774 938.589 58.5369 938.83C58.2889 939.07 57.9657 939.191 57.6501 939.191C57.3345 939.191 57.0113 939.07 56.7708 938.83C56.7483 938.799 56.7483 938.762 56.7708 938.732C56.8009 938.709 56.8385 938.709 56.8685 938.732C57.0865 938.95 57.3645 939.055 57.6501 939.055C57.9357 939.055 58.2213 938.95 58.4392 938.732C58.6571 938.514 58.7699 938.235 58.7699 937.942C58.7699 937.649 58.6647 937.378 58.4467 937.16C58.2288 936.949 57.9507 936.837 57.6651 936.837C57.6351 936.837 57.62 936.821 57.5975 936.806C57.5674 936.776 57.5674 936.731 57.5975 936.709L58.6797 935.596H57.0263C56.9888 935.596 56.9587 935.566 56.9587 935.528C56.9587 935.49 56.9888 935.46 57.0263 935.46H58.8375C58.8751 935.46 58.9051 935.49 58.9051 935.528C58.9051 935.535 58.9051 935.55 58.9051 935.558C58.9051 935.573 58.8901 935.581 58.8901 935.581L57.7854 936.716C58.0634 936.739 58.334 936.859 58.5444 937.077L58.5369 937.062Z" fill="#E71D36"/>
<path d="M61.1147 935.453C61.5205 935.453 61.8512 935.784 61.8512 936.197C61.8512 936.491 61.6708 936.746 61.4303 936.867C61.9338 937.009 62.3096 937.468 62.3096 938.01C62.3096 938.672 61.776 939.198 61.1147 939.198C60.4533 939.198 59.9197 938.664 59.9197 938.01C59.9197 937.461 60.2955 937.002 60.799 936.867C60.5435 936.746 60.3707 936.491 60.3707 936.197C60.3707 935.784 60.7013 935.453 61.1147 935.453ZM61.1147 939.07C61.7009 939.07 62.1743 938.596 62.1743 938.017C62.1743 937.438 61.7009 936.957 61.1147 936.957C60.5285 936.957 60.055 937.431 60.055 938.017C60.055 938.604 60.5285 939.07 61.1147 939.07ZM61.1147 936.806C61.4453 936.806 61.7234 936.536 61.7234 936.197C61.7234 935.859 61.4529 935.596 61.1147 935.588C60.7765 935.588 60.5059 935.859 60.5059 936.197C60.5059 936.536 60.7765 936.806 61.1147 936.806Z" fill="#E71D36"/>
</g>
<g opacity="0.85">
<path d="M194.541 949.554C196.172 949.554 197.494 950.885 197.494 952.533C197.494 953.713 196.788 954.736 195.803 955.21C197.817 955.766 199.313 957.609 199.313 959.798C199.313 962.43 197.171 964.551 194.541 964.551C191.91 964.551 189.769 962.43 189.769 959.798C189.769 957.616 191.264 955.766 193.278 955.21C192.249 954.736 191.565 953.713 191.565 952.533C191.565 950.885 192.895 949.554 194.541 949.554ZM194.541 964.017C196.893 964.017 198.779 962.129 198.779 959.798C198.779 957.466 196.893 955.556 194.541 955.556C192.188 955.556 190.302 957.459 190.302 959.798C190.302 962.137 192.211 964.017 194.541 964.017ZM194.541 954.954C195.871 954.954 196.961 953.864 196.961 952.533C196.961 951.201 195.871 950.111 194.541 950.088C193.196 950.111 192.098 951.179 192.098 952.533C192.098 953.886 193.188 954.954 194.541 954.954Z" fill="#5EB0EF"/>
<path d="M203.446 958.835C203.446 956.819 203.875 954.571 204.904 952.796C205.934 950.998 207.647 949.667 210.105 949.562C210.255 949.562 210.383 949.667 210.383 949.84C210.383 949.968 210.278 950.096 210.15 950.096C207.858 950.201 206.385 951.382 205.378 953.051C204.611 954.36 204.198 955.924 204.048 957.466C204.837 955.902 206.467 954.849 208.354 954.849C211.029 954.849 213.216 957.015 213.239 959.692C213.216 962.392 211.037 964.558 208.354 964.558C205.851 964.558 203.792 962.693 203.537 960.256C203.514 960.234 203.514 960.189 203.514 960.166C203.469 959.738 203.454 959.286 203.454 958.835H203.446ZM208.346 964.017C210.744 964.017 212.653 962.092 212.69 959.692C212.645 957.316 210.744 955.39 208.346 955.39C205.949 955.39 204.04 957.316 204.04 959.692C204.04 962.069 205.964 964.017 208.346 964.017Z" fill="#5EB0EF"/>
</g>
<g opacity="0.55">
<path d="M100.006 985.549C100.006 984.315 100.269 982.947 100.892 981.856C101.516 980.758 102.568 979.946 104.071 979.878C104.162 979.878 104.244 979.946 104.244 980.051C104.244 980.134 104.177 980.209 104.101 980.209C102.704 980.277 101.802 980.991 101.186 982.014C100.712 982.811 100.464 983.766 100.374 984.714C100.855 983.759 101.854 983.12 103.004 983.12C104.643 983.12 105.973 984.443 105.988 986.075C105.973 987.722 104.643 989.046 103.004 989.046C101.471 989.046 100.216 987.91 100.058 986.414C100.043 986.399 100.043 986.376 100.043 986.361C100.021 986.098 100.006 985.827 100.006 985.549ZM102.997 988.715C104.462 988.715 105.627 987.534 105.65 986.068C105.627 984.616 104.462 983.435 102.997 983.435C101.531 983.435 100.366 984.616 100.366 986.068C100.366 987.519 101.546 988.715 102.997 988.715Z" fill="#5EB0EF"/>
<path d="M113.714 987.098H108.363C108.325 987.098 108.295 987.083 108.257 987.06C108.19 986.993 108.175 986.903 108.235 986.835L113.751 979.961L113.774 979.938C113.811 979.908 113.841 979.901 113.879 979.901C113.932 979.901 113.984 979.938 114.007 979.976C114.022 979.991 114.022 979.998 114.029 980.013C114.029 980.028 114.044 980.036 114.044 980.051V986.767H114.841C114.946 986.767 115.014 986.835 115.014 986.925C115.014 987.03 114.946 987.098 114.841 987.098H114.044V988.88C114.044 988.971 113.977 989.053 113.879 989.053C113.796 989.053 113.706 988.978 113.706 988.88V987.098H113.714ZM113.714 986.767V980.562L108.701 986.767H113.714Z" fill="#5EB0EF"/>
</g>
<g opacity="0.52">
<path d="M160.804 1006.43C160.706 1006.35 160.706 1006.18 160.804 1006.09C160.894 1005.99 161.052 1005.99 161.142 1006.09C161.916 1006.84 162.916 1007.24 163.893 1007.24C164.87 1007.24 165.914 1006.84 166.666 1006.09C167.44 1005.31 167.816 1004.31 167.816 1003.31C167.816 1002.31 167.44 1001.31 166.666 1000.55C165.914 999.801 164.93 999.425 163.915 999.425C162.901 999.425 161.931 999.801 161.142 1000.55C161.089 1000.65 160.917 1000.65 160.819 1000.58C160.804 1000.58 160.781 1000.55 160.781 1000.54C160.766 1000.54 160.766 1000.52 160.766 1000.52C160.729 1000.46 160.706 1000.41 160.729 1000.33L161.39 994.769C161.413 994.656 161.48 994.581 161.563 994.566C161.6 994.551 161.638 994.529 161.691 994.529H168.064C168.199 994.529 168.312 994.656 168.312 994.769C168.312 994.92 168.199 995.017 168.064 995.017H161.856L161.292 999.808C162.066 999.222 162.991 998.943 163.915 998.943C165.042 998.943 166.177 999.357 167.049 1000.23C167.876 1001.08 168.312 1002.19 168.312 1003.32C168.312 1004.45 167.876 1005.58 167.011 1006.43C166.162 1007.3 165.035 1007.74 163.9 1007.74C162.765 1007.74 161.676 1007.3 160.811 1006.43L160.804 1006.43Z" fill="#5EB0EF"/>
<path d="M179.855 1004.91H172.137C172.077 1004.91 172.047 1004.9 171.987 1004.86C171.889 1004.76 171.874 1004.64 171.949 1004.54L179.908 994.626L179.945 994.589C180.005 994.551 180.043 994.536 180.096 994.536C180.171 994.536 180.246 994.589 180.283 994.649C180.306 994.672 180.306 994.687 180.321 994.709C180.321 994.732 180.344 994.747 180.344 994.762V1004.45H181.493C181.644 1004.45 181.741 1004.54 181.741 1004.67C181.741 1004.82 181.644 1004.92 181.493 1004.92H180.344V1007.49C180.344 1007.62 180.246 1007.74 180.096 1007.74C179.983 1007.74 179.855 1007.62 179.855 1007.49V1004.92V1004.91ZM179.855 1004.44V995.491L172.633 1004.44H179.855Z" fill="#5EB0EF"/>
</g>
<g opacity="0.95">
<path d="M134.463 886.966C134.463 885.199 134.839 883.228 135.74 881.664C136.642 880.085 138.145 878.919 140.302 878.829C140.43 878.829 140.543 878.919 140.543 879.069C140.543 879.182 140.453 879.295 140.34 879.295C138.326 879.385 137.033 880.423 136.154 881.89C135.477 883.033 135.124 884.409 134.989 885.763C135.68 884.394 137.108 883.469 138.762 883.469C141.106 883.469 143.023 885.364 143.045 887.718C143.03 890.087 141.114 891.982 138.762 891.982C136.567 891.982 134.764 890.35 134.538 888.207C134.523 888.184 134.523 888.147 134.523 888.132C134.485 887.756 134.47 887.365 134.47 886.966H134.463ZM138.762 891.516C140.866 891.516 142.534 889.824 142.572 887.718C142.534 885.627 140.866 883.943 138.762 883.943C136.657 883.943 134.989 885.635 134.989 887.718C134.989 889.801 136.68 891.516 138.762 891.516Z" fill="#E71D36"/>
<path d="M146.54 890.689C146.45 890.614 146.45 890.441 146.54 890.35C146.63 890.26 146.78 890.26 146.878 890.35C147.645 891.102 148.644 891.494 149.621 891.494C150.598 891.494 151.628 891.102 152.379 890.35C153.153 889.583 153.529 888.583 153.529 887.59C153.529 886.598 153.153 885.597 152.379 884.845C151.628 884.093 150.651 883.717 149.636 883.717C148.622 883.717 147.667 884.093 146.878 884.845C146.825 884.935 146.653 884.935 146.562 884.86C146.54 884.86 146.525 884.845 146.525 884.823C146.502 884.823 146.502 884.8 146.502 884.8C146.465 884.747 146.45 884.687 146.465 884.612L147.119 879.069C147.141 878.956 147.216 878.881 147.284 878.866C147.321 878.844 147.359 878.829 147.419 878.829H153.77C153.897 878.829 154.01 878.956 154.01 879.069C154.01 879.22 153.897 879.31 153.77 879.31H147.592L147.028 884.086C147.795 883.499 148.719 883.221 149.636 883.221C150.763 883.221 151.891 883.634 152.755 884.499C153.582 885.342 154.01 886.455 154.01 887.583C154.01 888.711 153.574 889.839 152.717 890.681C151.868 891.546 150.741 891.975 149.621 891.975C148.501 891.975 147.404 891.546 146.54 890.681V890.689Z" fill="#E71D36"/>
</g>
<g opacity="0.98">
<path d="M105.086 992.423H102.32C102.32 992.423 102.29 992.415 102.268 992.4C102.23 992.37 102.23 992.317 102.253 992.287L105.109 988.73L105.124 988.715C105.124 988.715 105.154 988.692 105.176 988.692C105.206 988.692 105.229 988.715 105.244 988.73C105.244 988.73 105.244 988.745 105.259 988.753C105.259 988.753 105.259 988.768 105.259 988.775V992.25H105.672C105.725 992.25 105.762 992.287 105.762 992.332C105.762 992.385 105.725 992.423 105.672 992.423H105.259V993.34C105.259 993.385 105.229 993.431 105.169 993.431C105.131 993.431 105.079 993.393 105.079 993.34V992.423H105.086ZM105.086 992.25V989.038L102.493 992.25H105.086Z" fill="#E71D36"/>
<path d="M109.144 990.731C109.452 991.031 109.603 991.445 109.603 991.844C109.603 992.242 109.445 992.656 109.144 992.964C108.836 993.273 108.43 993.423 108.032 993.423H108.024C107.626 993.423 107.22 993.273 106.912 992.964C106.882 992.927 106.882 992.874 106.912 992.844C106.95 992.806 107.002 992.806 107.032 992.844C107.311 993.115 107.664 993.25 108.017 993.25C108.37 993.25 108.738 993.115 109.009 992.836C109.287 992.566 109.422 992.205 109.422 991.844C109.422 991.483 109.287 991.129 109.016 990.858C108.738 990.588 108.385 990.452 108.032 990.452C107.994 990.452 107.972 990.437 107.949 990.415H107.942C107.904 990.385 107.904 990.317 107.942 990.287L109.31 988.88H107.22C107.175 988.88 107.13 988.835 107.13 988.79C107.13 988.745 107.175 988.7 107.22 988.7H109.512C109.558 988.7 109.603 988.738 109.603 988.79C109.603 988.805 109.603 988.82 109.603 988.828C109.603 988.843 109.588 988.858 109.58 988.865L108.19 990.294C108.543 990.332 108.889 990.475 109.152 990.746L109.144 990.731Z" fill="#E71D36"/>
</g>
<g opacity="0.98">
<path d="M290.036 1209.27C289.924 1209.18 289.924 1208.98 290.036 1208.88C290.149 1208.77 290.322 1208.77 290.427 1208.88C291.322 1209.75 292.479 1210.21 293.614 1210.21C294.749 1210.21 295.951 1209.75 296.823 1208.88C297.717 1207.98 298.153 1206.83 298.153 1205.67C298.153 1204.51 297.717 1203.35 296.823 1202.48C295.951 1201.61 294.816 1201.17 293.636 1201.17C292.456 1201.17 291.344 1201.61 290.427 1202.48C290.36 1202.59 290.164 1202.59 290.059 1202.5C290.036 1202.5 290.014 1202.48 290.014 1202.46C289.991 1202.46 289.991 1202.43 289.991 1202.43C289.946 1202.37 289.924 1202.31 289.946 1202.22L290.713 1195.77C290.735 1195.64 290.826 1195.55 290.908 1195.53C290.953 1195.51 290.998 1195.48 291.059 1195.48H298.439C298.589 1195.48 298.724 1195.63 298.724 1195.77C298.724 1195.94 298.596 1196.06 298.439 1196.06H291.254L290.6 1201.61C291.494 1200.93 292.569 1200.6 293.636 1200.6C294.944 1200.6 296.259 1201.08 297.259 1202.09C298.221 1203.07 298.724 1204.36 298.724 1205.68C298.724 1206.99 298.221 1208.3 297.221 1209.29C296.237 1210.29 294.929 1210.79 293.614 1210.79C292.299 1210.79 291.036 1210.29 290.029 1209.29L290.036 1209.27Z" fill="#5EB0EF"/>
<path d="M303.233 1204.95C303.233 1202.89 303.669 1200.6 304.721 1198.78C305.773 1196.94 307.517 1195.59 310.027 1195.48C310.177 1195.48 310.313 1195.59 310.313 1195.76C310.313 1195.89 310.2 1196.03 310.072 1196.03C307.735 1196.14 306.224 1197.33 305.202 1199.04C304.413 1200.37 304 1201.97 303.85 1203.55C304.661 1201.95 306.315 1200.88 308.238 1200.88C310.967 1200.88 313.199 1203.08 313.221 1205.82C313.199 1208.57 310.974 1210.78 308.238 1210.78C305.683 1210.78 303.587 1208.88 303.323 1206.39C303.301 1206.37 303.301 1206.32 303.301 1206.3C303.256 1205.86 303.233 1205.4 303.233 1204.95ZM308.238 1210.23C310.688 1210.23 312.627 1208.26 312.672 1205.82C312.627 1203.4 310.688 1201.43 308.238 1201.43C305.789 1201.43 303.85 1203.4 303.85 1205.82C303.85 1208.24 305.811 1210.23 308.238 1210.23Z" fill="#5EB0EF"/>
</g>
<g opacity="0.7">
<path d="M225.534 1340.63H220.28C220.243 1340.63 220.22 1340.61 220.175 1340.59C220.108 1340.52 220.1 1340.43 220.153 1340.37L225.564 1333.63L225.586 1333.6C225.624 1333.58 225.646 1333.57 225.691 1333.57C225.744 1333.57 225.797 1333.6 225.819 1333.64C225.834 1333.66 225.834 1333.66 225.842 1333.68C225.842 1333.69 225.857 1333.7 225.857 1333.72V1340.31H226.638C226.744 1340.31 226.804 1340.37 226.804 1340.46C226.804 1340.57 226.744 1340.63 226.638 1340.63H225.857V1342.37C225.857 1342.46 225.797 1342.54 225.691 1342.54C225.616 1342.54 225.526 1342.46 225.526 1342.37V1340.63H225.534ZM225.534 1340.31V1334.22L220.619 1340.31H225.534Z" fill="#E71D36"/>
<path d="M228.254 1334.67L230.05 1333.6C230.125 1333.55 230.231 1333.57 230.283 1333.66C230.321 1333.73 230.298 1333.82 230.208 1333.87L228.412 1334.95C228.412 1334.95 228.359 1334.97 228.337 1334.97C228.284 1334.97 228.224 1334.95 228.194 1334.89C228.156 1334.82 228.171 1334.72 228.262 1334.67H228.254ZM229.975 1333.73C229.975 1333.66 230.05 1333.57 230.125 1333.57C230.231 1333.57 230.291 1333.66 230.291 1333.73V1342.37C230.291 1342.46 230.223 1342.54 230.125 1342.54C230.05 1342.54 229.975 1342.46 229.975 1342.37V1333.73Z" fill="#E71D36"/>
</g>
<g opacity="0.88">
<path d="M290.308 1136.35L294.261 1132.78C294.764 1132.31 295.035 1131.68 295.035 1131.05C295.035 1130.5 294.824 1129.93 294.426 1129.49C293.968 1128.99 293.329 1128.71 292.705 1128.71C292.141 1128.71 291.585 1128.93 291.142 1129.32L290.736 1129.7C290.668 1129.76 290.586 1129.76 290.526 1129.7C290.466 1129.64 290.481 1129.53 290.533 1129.49L290.947 1129.11C291.458 1128.65 292.081 1128.43 292.713 1128.43C293.427 1128.43 294.141 1128.72 294.652 1129.29C295.095 1129.79 295.328 1130.44 295.328 1131.05C295.328 1131.77 295.035 1132.48 294.449 1132.99L290.774 1136.33H295.185C295.253 1136.33 295.328 1136.39 295.328 1136.47C295.328 1136.55 295.26 1136.62 295.185 1136.62H290.413C290.368 1136.62 290.323 1136.61 290.293 1136.56C290.248 1136.5 290.248 1136.41 290.3 1136.35H290.308Z" fill="#E71D36"/>
<path d="M298.461 1136.4L301.678 1128.74H297.364C297.281 1128.74 297.214 1128.67 297.214 1128.59C297.214 1128.52 297.281 1128.44 297.364 1128.44H301.926C301.994 1128.44 302.076 1128.52 302.076 1128.59C302.076 1128.62 302.054 1128.65 302.031 1128.69L298.732 1136.52C298.717 1136.57 298.664 1136.61 298.604 1136.61C298.589 1136.61 298.567 1136.6 298.544 1136.6C298.461 1136.57 298.439 1136.48 298.461 1136.41V1136.4Z" fill="#E71D36"/>
</g>
<g opacity="0.71">
<path d="M294.29 1279.99H287.181C287.128 1279.99 287.091 1279.97 287.038 1279.94C286.956 1279.85 286.933 1279.73 287.001 1279.64L294.343 1270.5L294.381 1270.47C294.433 1270.43 294.471 1270.41 294.516 1270.41C294.584 1270.41 294.659 1270.47 294.689 1270.52C294.704 1270.53 294.704 1270.56 294.726 1270.57C294.726 1270.59 294.741 1270.61 294.741 1270.62V1279.55H295.801C295.936 1279.55 296.026 1279.64 296.026 1279.76C296.026 1279.9 295.936 1279.99 295.801 1279.99H294.741V1282.36C294.741 1282.48 294.651 1282.58 294.516 1282.58C294.411 1282.58 294.29 1282.48 294.29 1282.36V1279.99ZM294.29 1279.55V1271.3L287.632 1279.55H294.29Z" fill="#E71D36"/>
<path d="M302.497 1270.39C303.82 1270.39 304.902 1271.47 304.902 1272.81C304.902 1273.77 304.331 1274.6 303.527 1274.99C305.165 1275.44 306.383 1276.93 306.383 1278.71C306.383 1280.85 304.647 1282.58 302.505 1282.58C300.363 1282.58 298.627 1280.85 298.627 1278.71C298.627 1276.93 299.844 1275.44 301.483 1274.99C300.648 1274.6 300.092 1273.77 300.092 1272.81C300.092 1271.47 301.174 1270.39 302.512 1270.39H302.497ZM302.497 1282.15C304.414 1282.15 305.939 1280.61 305.939 1278.72C305.939 1276.82 304.406 1275.27 302.497 1275.27C300.588 1275.27 299.055 1276.82 299.055 1278.72C299.055 1280.61 300.603 1282.15 302.497 1282.15ZM302.497 1274.78C303.579 1274.78 304.466 1273.9 304.466 1272.81C304.466 1271.73 303.579 1270.85 302.497 1270.83C301.4 1270.84 300.513 1271.72 300.513 1272.81C300.513 1273.91 301.4 1274.78 302.497 1274.78Z" fill="#E71D36"/>
</g>
<g opacity="0.69">
<path d="M299.168 1204.34L305.082 1190.25H297.146C296.996 1190.25 296.868 1190.12 296.868 1189.97C296.868 1189.84 296.996 1189.69 297.146 1189.69H305.533C305.661 1189.69 305.811 1189.84 305.811 1189.97C305.811 1190.04 305.766 1190.1 305.729 1190.17L299.664 1204.57C299.641 1204.65 299.536 1204.74 299.423 1204.74C299.401 1204.74 299.363 1204.72 299.318 1204.72C299.168 1204.65 299.123 1204.5 299.168 1204.35V1204.34Z" fill="#E71D36"/>
<path d="M313.875 1189.66C315.513 1189.66 316.843 1190.99 316.843 1192.66C316.843 1193.84 316.129 1194.88 315.145 1195.35C317.167 1195.91 318.67 1197.76 318.67 1199.95C318.67 1202.6 316.52 1204.73 313.875 1204.73C311.229 1204.73 309.08 1202.6 309.08 1199.95C309.08 1197.76 310.583 1195.91 312.605 1195.35C311.575 1194.88 310.884 1193.85 310.884 1192.66C310.884 1191 312.214 1189.66 313.875 1189.66ZM313.875 1204.19C316.242 1204.19 318.136 1202.3 318.136 1199.95C318.136 1197.61 316.242 1195.7 313.875 1195.7C311.508 1195.7 309.621 1197.61 309.621 1199.95C309.621 1202.29 311.538 1204.19 313.875 1204.19ZM313.875 1195.09C315.205 1195.09 316.302 1193.99 316.302 1192.65C316.302 1191.31 315.205 1190.22 313.875 1190.2C312.522 1190.22 311.425 1191.3 311.425 1192.65C311.425 1194 312.522 1195.09 313.875 1195.09Z" fill="#E71D36"/>
</g>
<g opacity="0.71">
<path d="M221.303 1179.74C222.212 1179.74 222.956 1180.48 222.956 1181.4C222.956 1182.06 222.558 1182.63 222.009 1182.89C223.136 1183.2 223.971 1184.24 223.971 1185.46C223.971 1186.93 222.776 1188.12 221.303 1188.12C219.83 1188.12 218.635 1186.93 218.635 1185.46C218.635 1184.24 219.476 1183.21 220.596 1182.89C220.025 1182.63 219.642 1182.06 219.642 1181.4C219.642 1180.47 220.386 1179.74 221.303 1179.74ZM221.303 1187.82C222.618 1187.82 223.678 1186.77 223.678 1185.46C223.678 1184.15 222.625 1183.09 221.303 1183.09C219.98 1183.09 218.935 1184.16 218.935 1185.46C218.935 1186.76 220.003 1187.82 221.303 1187.82ZM221.303 1182.75C222.047 1182.75 222.655 1182.14 222.655 1181.4C222.655 1180.65 222.047 1180.04 221.303 1180.04C220.551 1180.05 219.935 1180.65 219.935 1181.4C219.935 1182.15 220.544 1182.75 221.303 1182.75Z" fill="#E71D36"/>
<path d="M229.149 1179.74C230.787 1179.75 232.012 1181.67 232.012 1183.92C232.012 1186.17 230.795 1188.11 229.149 1188.12C227.533 1188.11 226.301 1186.19 226.301 1183.92C226.301 1181.65 227.533 1179.75 229.149 1179.74ZM226.601 1183.92C226.601 1185.02 226.91 1186 227.383 1186.7C227.849 1187.42 228.488 1187.83 229.157 1187.83C229.825 1187.83 230.472 1187.42 230.938 1186.7C231.419 1186.01 231.719 1185.02 231.719 1183.92C231.719 1182.81 231.419 1181.86 230.938 1181.16C230.472 1180.44 229.833 1180.03 229.157 1180.03C228.48 1180.03 227.849 1180.44 227.383 1181.16C226.917 1181.86 226.601 1182.83 226.601 1183.92Z" fill="#E71D36"/>
</g>
<g opacity="0.62">
<path d="M279.756 1170.58C279.696 1170.52 279.696 1170.42 279.756 1170.36C279.816 1170.3 279.914 1170.3 279.974 1170.36C280.47 1170.84 281.109 1171.09 281.732 1171.09C282.356 1171.09 283.018 1170.84 283.506 1170.36C284.002 1169.86 284.243 1169.23 284.243 1168.58C284.243 1167.94 284.002 1167.3 283.506 1166.82C283.025 1166.34 282.401 1166.1 281.747 1166.1C281.094 1166.1 280.485 1166.34 279.981 1166.82C279.944 1166.88 279.839 1166.88 279.778 1166.84C279.763 1166.84 279.756 1166.82 279.756 1166.82C279.741 1166.82 279.741 1166.8 279.741 1166.8C279.718 1166.76 279.703 1166.72 279.718 1166.68L280.139 1163.13C280.154 1163.05 280.199 1163.01 280.252 1162.99C280.274 1162.98 280.297 1162.97 280.335 1162.97H284.4C284.483 1162.97 284.558 1163.05 284.558 1163.13C284.558 1163.23 284.483 1163.29 284.4 1163.29H280.44L280.079 1166.35C280.575 1165.97 281.161 1165.79 281.755 1165.79C282.476 1165.79 283.198 1166.06 283.754 1166.61C284.288 1167.15 284.558 1167.87 284.558 1168.59C284.558 1169.31 284.28 1170.03 283.731 1170.58C283.19 1171.13 282.469 1171.4 281.747 1171.4C281.026 1171.4 280.327 1171.12 279.771 1170.58H279.756Z" fill="#E71D36"/>
</g>
<g opacity="0.94">
<path d="M280.425 1033.1C281.462 1033.1 282.318 1033.91 282.409 1034.94C282.431 1035.1 282.446 1035.28 282.446 1035.46C282.446 1036.28 282.273 1037.2 281.845 1037.95C281.402 1038.68 280.718 1039.23 279.703 1039.28C279.635 1039.28 279.59 1039.23 279.59 1039.16C279.583 1039.11 279.635 1039.06 279.688 1039.06C280.627 1039.01 281.236 1038.52 281.642 1037.84C281.943 1037.31 282.131 1036.66 282.191 1036.04C281.867 1036.67 281.199 1037.1 280.425 1037.1C279.327 1037.1 278.418 1036.22 278.418 1035.11C278.418 1034.01 279.327 1033.11 280.425 1033.11V1033.1ZM280.425 1036.88C281.402 1036.88 282.198 1036.09 282.198 1035.11C282.198 1035.07 282.198 1035.02 282.191 1034.98V1034.95C282.1 1034.03 281.341 1033.34 280.425 1033.34C279.44 1033.34 278.636 1034.13 278.636 1035.12C278.636 1036.1 279.44 1036.89 280.425 1036.89V1036.88Z" fill="#E71D36"/>
</g>
<g opacity="0.86">
<path d="M285.79 1105.3C286.775 1105.3 287.579 1106.11 287.579 1107.11C287.579 1107.82 287.151 1108.45 286.557 1108.73C287.774 1109.07 288.684 1110.18 288.684 1111.51C288.684 1113.1 287.391 1114.39 285.798 1114.39C284.205 1114.39 282.912 1113.1 282.912 1111.51C282.912 1110.18 283.821 1109.07 285.039 1108.73C284.415 1108.45 284.002 1107.82 284.002 1107.11C284.002 1106.11 284.806 1105.3 285.805 1105.3H285.79ZM285.79 1114.06C287.218 1114.06 288.353 1112.91 288.353 1111.5C288.353 1110.09 287.211 1108.94 285.79 1108.94C284.37 1108.94 283.228 1110.09 283.228 1111.5C283.228 1112.91 284.378 1114.06 285.79 1114.06ZM285.79 1108.57C286.595 1108.57 287.256 1107.91 287.256 1107.11C287.256 1106.3 286.595 1105.64 285.79 1105.63C284.971 1105.65 284.317 1106.3 284.317 1107.11C284.317 1107.92 284.979 1108.57 285.79 1108.57Z" fill="#5EB0EF"/>
<path d="M291.194 1113.49C291.126 1113.43 291.126 1113.32 291.194 1113.25C291.262 1113.18 291.359 1113.18 291.427 1113.25C291.96 1113.77 292.644 1114.04 293.321 1114.04C293.997 1114.04 294.704 1113.77 295.222 1113.25C295.756 1112.72 296.011 1112.03 296.011 1111.34C296.011 1110.65 295.748 1109.97 295.222 1109.45C294.704 1108.93 294.027 1108.66 293.328 1108.66C292.629 1108.66 291.968 1108.93 291.427 1109.45C291.389 1109.51 291.269 1109.51 291.209 1109.46C291.194 1109.46 291.186 1109.45 291.186 1109.44C291.171 1109.44 291.171 1109.42 291.171 1109.42C291.149 1109.39 291.134 1109.35 291.149 1109.3L291.6 1105.48C291.615 1105.4 291.667 1105.35 291.72 1105.33C291.743 1105.32 291.773 1105.31 291.81 1105.31H296.192C296.282 1105.31 296.357 1105.4 296.357 1105.48C296.357 1105.58 296.282 1105.64 296.192 1105.64H291.93L291.54 1108.94C292.073 1108.54 292.704 1108.34 293.343 1108.34C294.117 1108.34 294.899 1108.63 295.493 1109.22C296.064 1109.8 296.357 1110.57 296.357 1111.35C296.357 1112.13 296.056 1112.91 295.463 1113.49C294.884 1114.08 294.102 1114.38 293.328 1114.38C292.554 1114.38 291.803 1114.08 291.201 1113.49H291.194Z" fill="#5EB0EF"/>
</g>
<g opacity="0.59">
<path d="M187.303 1120.16L188.333 1119.55C188.378 1119.52 188.438 1119.53 188.468 1119.58C188.491 1119.63 188.468 1119.68 188.423 1119.71L187.394 1120.33C187.394 1120.33 187.364 1120.34 187.349 1120.34C187.318 1120.34 187.281 1120.33 187.266 1120.29C187.243 1120.25 187.251 1120.19 187.303 1120.16ZM188.288 1119.63C188.288 1119.58 188.333 1119.53 188.378 1119.53C188.438 1119.53 188.476 1119.58 188.476 1119.63V1124.57C188.476 1124.62 188.438 1124.67 188.378 1124.67C188.333 1124.67 188.288 1124.62 188.288 1124.57V1119.63Z" fill="#5EB0EF"/>
<path d="M189.843 1124.5L192.323 1122.25C192.639 1121.96 192.804 1121.56 192.804 1121.17C192.804 1120.83 192.677 1120.47 192.421 1120.19C192.135 1119.88 191.73 1119.71 191.339 1119.71C190.986 1119.71 190.632 1119.84 190.354 1120.1L190.099 1120.33C190.054 1120.37 190.001 1120.37 189.963 1120.33C189.926 1120.29 189.933 1120.22 189.971 1120.19L190.227 1119.95C190.55 1119.67 190.94 1119.52 191.331 1119.52C191.782 1119.52 192.226 1119.7 192.549 1120.06C192.827 1120.37 192.977 1120.78 192.977 1121.16C192.977 1121.62 192.789 1122.06 192.428 1122.38L190.129 1124.47H192.894C192.94 1124.47 192.985 1124.51 192.985 1124.56C192.985 1124.62 192.94 1124.66 192.894 1124.66H189.903C189.903 1124.66 189.843 1124.65 189.828 1124.62C189.798 1124.59 189.798 1124.53 189.828 1124.49L189.843 1124.5Z" fill="#5EB0EF"/>
</g>
<g opacity="0.66">
<path d="M233.65 1008.49L235.611 1007.33C235.694 1007.28 235.807 1007.28 235.867 1007.38C235.912 1007.46 235.882 1007.56 235.784 1007.62L233.823 1008.8C233.823 1008.8 233.762 1008.82 233.74 1008.82C233.687 1008.82 233.612 1008.8 233.582 1008.74C233.537 1008.67 233.552 1008.55 233.65 1008.49ZM235.529 1007.47C235.529 1007.39 235.611 1007.29 235.694 1007.29C235.807 1007.29 235.874 1007.39 235.874 1007.47V1016.91C235.874 1017.01 235.807 1017.09 235.694 1017.09C235.611 1017.09 235.529 1017.01 235.529 1016.91V1007.47Z" fill="#E71D36"/>
</g>
<g opacity="0.63">
<path d="M86.6363 1045.88C88.1619 1045.88 89.4095 1047.13 89.4095 1048.68C89.4095 1049.78 88.7481 1050.75 87.8237 1051.19C89.7101 1051.71 91.1154 1053.44 91.1154 1055.49C91.1154 1057.97 89.1089 1059.95 86.6363 1059.95C84.1638 1059.95 82.1572 1057.96 82.1572 1055.49C82.1572 1053.44 83.5626 1051.72 85.4489 1051.19C84.487 1050.75 83.8406 1049.78 83.8406 1048.68C83.8406 1047.13 85.0882 1045.88 86.6288 1045.88H86.6363ZM86.6363 1059.45C88.8458 1059.45 90.6119 1057.68 90.6119 1055.48C90.6119 1053.29 88.8458 1051.51 86.6363 1051.51C84.4268 1051.51 82.6607 1053.3 82.6607 1055.48C82.6607 1057.67 84.4494 1059.45 86.6363 1059.45ZM86.6363 1050.94C87.8839 1050.94 88.9059 1049.92 88.9059 1048.67C88.9059 1047.42 87.8839 1046.4 86.6363 1046.38C85.3738 1046.4 84.3442 1047.4 84.3442 1048.67C84.3442 1049.94 85.3662 1050.94 86.6363 1050.94Z" fill="#E71D36"/>
<path d="M99.5104 1045.88C101.878 1045.88 103.832 1047.71 104.027 1050.06C104.087 1050.45 104.11 1050.84 104.11 1051.25C104.11 1053.14 103.704 1055.23 102.742 1056.92C101.735 1058.61 100.172 1059.85 97.8646 1059.96C97.7067 1059.96 97.6015 1059.86 97.6015 1059.7C97.579 1059.58 97.6992 1059.46 97.8195 1059.46C99.9688 1059.36 101.352 1058.23 102.276 1056.68C102.96 1055.48 103.381 1053.99 103.524 1052.56C102.78 1054.01 101.254 1054.99 99.5104 1054.99C97.0003 1054.99 94.9336 1052.96 94.9336 1050.45C94.9336 1047.93 97.0003 1045.88 99.5104 1045.88ZM99.5104 1054.48C101.742 1054.48 103.546 1052.67 103.546 1050.45C103.546 1050.35 103.546 1050.24 103.524 1050.15V1050.05C103.321 1047.98 101.6 1046.39 99.5104 1046.39C97.2633 1046.39 95.4371 1048.2 95.4371 1050.45C95.4371 1052.7 97.2633 1054.49 99.5104 1054.49V1054.48Z" fill="#E71D36"/>
</g>
<g opacity="0.6">
<path d="M229.524 1034.5L231.666 1029.4H228.787C228.735 1029.4 228.69 1029.35 228.69 1029.3C228.69 1029.25 228.735 1029.2 228.787 1029.2H231.824C231.869 1029.2 231.921 1029.25 231.921 1029.3C231.921 1029.32 231.906 1029.34 231.891 1029.36L229.697 1034.58C229.697 1034.58 229.652 1034.64 229.614 1034.64C229.607 1034.64 229.591 1034.64 229.576 1034.64C229.524 1034.61 229.509 1034.56 229.524 1034.5Z" fill="#E71D36"/>
<path d="M233.109 1032.56C233.109 1031.83 233.266 1031.01 233.642 1030.37C234.018 1029.71 234.642 1029.23 235.536 1029.18C235.589 1029.18 235.634 1029.22 235.634 1029.29C235.634 1029.33 235.596 1029.38 235.551 1029.38C234.717 1029.42 234.183 1029.85 233.815 1030.46C233.537 1030.93 233.387 1031.5 233.334 1032.06C233.62 1031.49 234.213 1031.12 234.897 1031.12C235.874 1031.12 236.663 1031.91 236.671 1032.88C236.663 1033.86 235.867 1034.64 234.897 1034.64C233.988 1034.64 233.236 1033.97 233.146 1033.08C233.139 1033.07 233.139 1033.06 233.139 1033.06C233.124 1032.89 233.116 1032.73 233.116 1032.57L233.109 1032.56ZM234.89 1034.45C235.762 1034.45 236.453 1033.75 236.475 1032.88C236.46 1032.01 235.769 1031.31 234.89 1031.31C234.01 1031.31 233.327 1032.01 233.327 1032.88C233.327 1033.74 234.026 1034.45 234.89 1034.45Z" fill="#E71D36"/>
</g>
<g opacity="0.61">
<path d="M137.725 1053.9C138.243 1053.9 138.671 1054.33 138.671 1054.85C138.671 1055.23 138.446 1055.56 138.13 1055.71C138.777 1055.89 139.258 1056.48 139.258 1057.18C139.258 1058.02 138.574 1058.7 137.725 1058.7C136.875 1058.7 136.191 1058.02 136.191 1057.18C136.191 1056.48 136.672 1055.88 137.319 1055.71C136.988 1055.56 136.77 1055.23 136.77 1054.85C136.77 1054.32 137.198 1053.9 137.725 1053.9ZM137.725 1058.54C138.476 1058.54 139.085 1057.94 139.085 1057.18C139.085 1056.43 138.476 1055.82 137.725 1055.82C136.973 1055.82 136.364 1056.43 136.364 1057.18C136.364 1057.94 136.973 1058.54 137.725 1058.54ZM137.725 1055.63C138.153 1055.63 138.499 1055.28 138.499 1054.85C138.499 1054.42 138.153 1054.08 137.725 1054.07C137.289 1054.07 136.943 1054.42 136.943 1054.85C136.943 1055.28 137.296 1055.63 137.725 1055.63Z" fill="#5EB0EF"/>
<path d="M142.128 1053.9C142.94 1053.9 143.601 1054.52 143.669 1055.33C143.692 1055.45 143.699 1055.6 143.699 1055.73C143.699 1056.38 143.564 1057.09 143.233 1057.67C142.888 1058.25 142.354 1058.67 141.565 1058.71C141.512 1058.71 141.475 1058.68 141.475 1058.62C141.475 1058.58 141.512 1058.54 141.55 1058.54C142.286 1058.51 142.76 1058.12 143.075 1057.59C143.308 1057.18 143.451 1056.67 143.504 1056.18C143.248 1056.68 142.73 1057.02 142.128 1057.02C141.272 1057.02 140.565 1056.33 140.565 1055.47C140.565 1054.61 141.272 1053.91 142.128 1053.91V1053.9ZM142.128 1056.84C142.888 1056.84 143.511 1056.22 143.511 1055.45C143.511 1055.42 143.511 1055.39 143.511 1055.35V1055.32C143.444 1054.6 142.85 1054.06 142.136 1054.06C141.369 1054.06 140.738 1054.68 140.738 1055.45C140.738 1056.23 141.362 1056.84 142.136 1056.84H142.128Z" fill="#5EB0EF"/>
</g>
<g opacity="0.71">
<path d="M138.995 1009.37L139.867 1008.85C139.867 1008.85 139.957 1008.83 139.979 1008.87C139.994 1008.91 139.979 1008.95 139.942 1008.98L139.07 1009.49C139.07 1009.49 139.048 1009.49 139.032 1009.49C139.01 1009.49 138.98 1009.49 138.965 1009.46C138.95 1009.43 138.95 1009.37 138.995 1009.35V1009.37ZM139.829 1008.92C139.829 1008.88 139.867 1008.83 139.904 1008.83C139.957 1008.83 139.987 1008.88 139.987 1008.92V1013.1C139.987 1013.14 139.957 1013.18 139.904 1013.18C139.867 1013.18 139.829 1013.14 139.829 1013.1V1008.92Z" fill="#5EB0EF"/>
<path d="M141.242 1012.75C141.242 1012.75 141.212 1012.67 141.242 1012.64C141.272 1012.61 141.325 1012.61 141.355 1012.64C141.61 1012.89 141.941 1013.01 142.264 1013.01C142.587 1013.01 142.925 1012.89 143.173 1012.64C143.429 1012.38 143.557 1012.05 143.557 1011.73C143.557 1011.41 143.429 1011.07 143.173 1010.82C142.925 1010.57 142.602 1010.44 142.264 1010.44C141.926 1010.44 141.61 1010.57 141.347 1010.82C141.332 1010.85 141.272 1010.85 141.242 1010.82C141.242 1010.82 141.227 1010.82 141.227 1010.8C141.212 1010.78 141.212 1010.77 141.212 1010.74L141.43 1008.91C141.43 1008.87 141.46 1008.85 141.482 1008.84C141.497 1008.84 141.505 1008.83 141.528 1008.83H143.624C143.669 1008.83 143.707 1008.87 143.707 1008.91C143.707 1008.96 143.669 1008.99 143.624 1008.99H141.58L141.392 1010.57C141.648 1010.37 141.948 1010.28 142.257 1010.28C142.632 1010.28 143.001 1010.42 143.286 1010.71C143.557 1010.98 143.699 1011.35 143.699 1011.73C143.699 1012.1 143.557 1012.47 143.271 1012.75C142.993 1013.04 142.617 1013.18 142.249 1013.18C141.881 1013.18 141.52 1013.04 141.234 1012.75H141.242Z" fill="#5EB0EF"/>
</g>
<g opacity="0.93">
<path d="M209.571 1039.95C209.962 1039.95 210.285 1040.27 210.285 1040.67C210.285 1040.95 210.112 1041.2 209.879 1041.32C210.367 1041.45 210.728 1041.89 210.728 1042.42C210.728 1043.06 210.21 1043.57 209.578 1043.57C208.947 1043.57 208.429 1043.06 208.429 1042.42C208.429 1041.89 208.789 1041.45 209.278 1041.32C209.03 1041.2 208.864 1040.95 208.864 1040.67C208.864 1040.27 209.188 1039.95 209.586 1039.95H209.571ZM209.571 1043.44C210.142 1043.44 210.593 1042.99 210.593 1042.42C210.593 1041.86 210.142 1041.4 209.571 1041.4C209 1041.4 208.549 1041.86 208.549 1042.42C208.549 1042.99 209.007 1043.44 209.571 1043.44ZM209.571 1041.25C209.894 1041.25 210.157 1040.98 210.157 1040.66C210.157 1040.34 209.894 1040.07 209.571 1040.07C209.248 1040.07 208.985 1040.34 208.985 1040.66C208.985 1040.98 209.248 1041.25 209.571 1041.25Z" fill="#5EB0EF"/>
<path d="M213.825 1042.8H211.713C211.713 1042.8 211.683 1042.8 211.668 1042.78C211.638 1042.76 211.638 1042.72 211.66 1042.69L213.84 1039.98H213.847C213.847 1039.98 213.87 1039.95 213.892 1039.95C213.915 1039.95 213.93 1039.97 213.945 1039.98V1040L213.96 1040.01V1042.67H214.276C214.321 1042.67 214.343 1042.69 214.343 1042.73C214.343 1042.77 214.321 1042.8 214.276 1042.8H213.96V1043.5C213.96 1043.5 213.938 1043.57 213.892 1043.57C213.862 1043.57 213.825 1043.54 213.825 1043.5V1042.8ZM213.825 1042.67V1040.22L211.848 1042.67H213.825Z" fill="#5EB0EF"/>
</g>
<g opacity="0.54">
<path d="M254.31 960.339L255.572 959.196C255.73 959.045 255.82 958.842 255.82 958.639C255.82 958.466 255.752 958.286 255.625 958.143C255.482 957.985 255.271 957.895 255.076 957.895C254.896 957.895 254.715 957.962 254.573 958.09L254.445 958.211C254.445 958.211 254.392 958.233 254.377 958.211C254.362 958.195 254.362 958.158 254.377 958.143L254.505 958.022C254.67 957.88 254.866 957.804 255.069 957.804C255.294 957.804 255.527 957.895 255.692 958.075C255.835 958.233 255.91 958.444 255.91 958.639C255.91 958.865 255.82 959.098 255.625 959.263L254.445 960.331H255.858C255.858 960.331 255.903 960.354 255.903 960.376C255.903 960.399 255.88 960.422 255.858 960.422H254.332C254.332 960.422 254.302 960.422 254.294 960.407C254.279 960.392 254.279 960.362 254.294 960.339H254.31Z" fill="#E71D36"/>
<path d="M256.481 958.128L257.007 957.82C257.007 957.82 257.06 957.804 257.075 957.834C257.083 957.857 257.075 957.887 257.053 957.895L256.527 958.211H256.504C256.504 958.211 256.474 958.211 256.459 958.196C256.451 958.173 256.459 958.143 256.481 958.135V958.128ZM256.985 957.857C256.985 957.857 257.007 957.812 257.03 957.812C257.06 957.812 257.075 957.835 257.075 957.857V960.384C257.075 960.384 257.06 960.429 257.03 960.429C257.007 960.429 256.985 960.407 256.985 960.384V957.857Z" fill="#E71D36"/>
</g>
<g opacity="0.55">
<path d="M186.709 970.176C186.634 970.116 186.634 969.988 186.709 969.913C186.785 969.838 186.897 969.838 186.972 969.913C187.574 970.5 188.355 970.808 189.114 970.808C189.873 970.808 190.685 970.5 191.271 969.913C191.872 969.311 192.165 968.529 192.165 967.747C192.165 966.965 191.872 966.19 191.271 965.604C190.685 965.017 189.918 964.724 189.129 964.724C188.34 964.724 187.589 965.017 186.965 965.604C186.92 965.679 186.792 965.679 186.717 965.619C186.702 965.619 186.687 965.604 186.687 965.589C186.672 965.589 186.672 965.573 186.672 965.573C186.642 965.528 186.627 965.483 186.642 965.431L187.153 961.091C187.168 961.001 187.228 960.941 187.281 960.926C187.311 960.911 187.341 960.896 187.386 960.896H192.353C192.459 960.896 192.541 961.001 192.541 961.091C192.541 961.211 192.451 961.279 192.353 961.279H187.521L187.078 965.017C187.679 964.558 188.4 964.34 189.122 964.34C190.001 964.34 190.888 964.664 191.564 965.34C192.211 966.002 192.549 966.867 192.549 967.755C192.549 968.642 192.211 969.522 191.534 970.184C190.873 970.861 189.994 971.199 189.107 971.199C188.22 971.199 187.371 970.861 186.694 970.184L186.709 970.176Z" fill="#E71D36"/>
</g>
<g opacity="0.95">
<path d="M200.139 948.027C200.139 948.027 200.117 947.975 200.139 947.96C200.162 947.937 200.192 947.937 200.207 947.96C200.365 948.11 200.568 948.193 200.763 948.193C200.958 948.193 201.176 948.11 201.327 947.96C201.477 947.809 201.56 947.599 201.56 947.396C201.56 947.193 201.484 946.99 201.327 946.839C201.176 946.689 200.973 946.606 200.77 946.606C200.568 946.606 200.365 946.681 200.207 946.839C200.192 946.854 200.162 946.854 200.139 946.839H200.132C200.132 946.839 200.117 946.809 200.124 946.794L200.259 945.658C200.259 945.658 200.282 945.621 200.297 945.613C200.304 945.613 200.312 945.606 200.327 945.606H201.62C201.62 945.606 201.672 945.636 201.672 945.658C201.672 945.688 201.65 945.711 201.62 945.711H200.357L200.244 946.689C200.402 946.568 200.59 946.516 200.778 946.516C201.011 946.516 201.236 946.599 201.417 946.779C201.582 946.952 201.672 947.178 201.672 947.411C201.672 947.644 201.582 947.87 201.409 948.043C201.236 948.216 201.003 948.306 200.778 948.306C200.552 948.306 200.327 948.216 200.147 948.043L200.139 948.027Z" fill="#5EB0EF"/>
<path d="M203.273 945.606C203.566 945.606 203.799 945.846 203.799 946.14C203.799 946.35 203.671 946.538 203.498 946.621C203.859 946.719 204.129 947.05 204.129 947.441C204.129 947.915 203.746 948.291 203.273 948.291C202.799 948.291 202.416 947.907 202.416 947.441C202.416 947.05 202.687 946.719 203.047 946.621C202.859 946.538 202.739 946.35 202.739 946.14C202.739 945.846 202.98 945.606 203.273 945.606ZM203.273 948.193C203.694 948.193 204.032 947.854 204.032 947.433C204.032 947.012 203.694 946.674 203.273 946.674C202.852 946.674 202.514 947.012 202.514 947.433C202.514 947.854 202.852 948.193 203.273 948.193ZM203.273 946.568C203.513 946.568 203.709 946.373 203.709 946.132C203.709 945.892 203.513 945.696 203.273 945.696C203.032 945.696 202.837 945.892 202.837 946.132C202.837 946.373 203.032 946.568 203.273 946.568Z" fill="#5EB0EF"/>
</g>
<g opacity="0.61">
<path d="M200.079 979.871H196.291C196.291 979.871 196.246 979.863 196.216 979.84C196.171 979.795 196.163 979.728 196.201 979.683L200.109 974.817L200.124 974.794C200.124 974.794 200.169 974.764 200.199 974.764C200.237 974.764 200.274 974.794 200.289 974.817C200.297 974.824 200.297 974.832 200.312 974.847C200.312 974.854 200.319 974.869 200.319 974.877V979.637H200.883C200.958 979.637 201.003 979.683 201.003 979.75C201.003 979.825 200.958 979.871 200.883 979.871H200.319V981.134C200.319 981.202 200.274 981.254 200.199 981.254C200.146 981.254 200.079 981.202 200.079 981.134V979.871ZM200.079 979.637V975.238L196.531 979.637H200.079Z" fill="#5EB0EF"/>
</g>
<g opacity="0.51">
<path d="M190.008 933.543C189.903 933.452 189.903 933.264 190.008 933.151C190.113 933.039 190.286 933.039 190.399 933.151C191.286 934.016 192.428 934.475 193.555 934.475C194.683 934.475 195.87 934.024 196.734 933.151C197.621 932.264 198.057 931.113 198.057 929.97C198.057 928.827 197.621 927.676 196.734 926.811C195.87 925.946 194.743 925.51 193.578 925.51C192.413 925.51 191.308 925.946 190.399 926.811C190.331 926.917 190.136 926.917 190.031 926.834C190.008 926.834 189.986 926.811 189.986 926.789C189.963 926.789 189.963 926.766 189.963 926.766C189.918 926.706 189.895 926.638 189.918 926.548L190.677 920.163C190.7 920.035 190.79 919.945 190.872 919.922C190.918 919.9 190.963 919.877 191.023 919.877H198.335C198.485 919.877 198.613 920.028 198.613 920.155C198.613 920.328 198.485 920.434 198.335 920.434H191.218L190.572 925.931C191.459 925.262 192.518 924.931 193.578 924.931C194.878 924.931 196.171 925.405 197.17 926.405C198.125 927.375 198.621 928.654 198.621 929.955C198.621 931.256 198.125 932.55 197.125 933.527C196.148 934.52 194.856 935.024 193.555 935.024C192.255 935.024 191 934.528 190.008 933.527V933.543Z" fill="#E71D36"/>
<path d="M203.077 929.256C203.077 927.217 203.513 924.946 204.55 923.149C205.587 921.329 207.315 919.99 209.811 919.877C209.961 919.877 210.089 919.982 210.089 920.155C210.089 920.283 209.983 920.411 209.848 920.411C207.533 920.516 206.038 921.712 205.023 923.397C204.242 924.721 203.836 926.3 203.678 927.857C204.475 926.277 206.121 925.217 208.029 925.217C210.735 925.217 212.937 927.406 212.959 930.105C212.937 932.836 210.735 935.017 208.029 935.017C205.497 935.017 203.423 933.136 203.16 930.662C203.137 930.639 203.137 930.594 203.137 930.572C203.092 930.136 203.069 929.684 203.069 929.233L203.077 929.256ZM208.029 934.498C210.449 934.498 212.381 932.55 212.418 930.121C212.373 927.714 210.449 925.766 208.029 925.766C205.61 925.766 203.678 927.714 203.678 930.121C203.678 932.527 205.625 934.498 208.029 934.498Z" fill="#E71D36"/>
</g>
<g opacity="0.96">
<path d="M179.78 889.305C180.321 889.305 180.757 889.741 180.757 890.29C180.757 890.681 180.524 891.02 180.201 891.178C180.862 891.366 181.359 891.967 181.359 892.689C181.359 893.562 180.652 894.261 179.78 894.261C178.909 894.261 178.202 893.562 178.202 892.689C178.202 891.967 178.698 891.358 179.359 891.178C179.021 891.02 178.796 890.681 178.796 890.29C178.796 889.749 179.232 889.305 179.78 889.305ZM179.78 894.081C180.562 894.081 181.178 893.456 181.178 892.689C181.178 891.922 180.554 891.29 179.78 891.29C179.006 891.29 178.382 891.922 178.382 892.689C178.382 893.456 179.014 894.081 179.78 894.081ZM179.78 891.087C180.216 891.087 180.577 890.726 180.577 890.29C180.577 889.854 180.216 889.493 179.78 889.485C179.337 889.493 178.976 889.846 178.976 890.29C178.976 890.734 179.337 891.087 179.78 891.087Z" fill="#E71D36"/>
<path d="M182.719 892.374C182.719 891.712 182.862 890.967 183.2 890.381C183.538 889.786 184.102 889.35 184.921 889.313C184.973 889.313 185.011 889.35 185.011 889.403C185.011 889.448 184.973 889.485 184.936 889.485C184.177 889.523 183.688 889.914 183.358 890.463C183.102 890.892 182.967 891.411 182.922 891.922C183.185 891.403 183.718 891.057 184.342 891.057C185.229 891.057 185.95 891.772 185.95 892.659C185.95 893.554 185.221 894.269 184.342 894.269C183.515 894.269 182.839 893.652 182.749 892.847V892.825C182.734 892.674 182.726 892.531 182.726 892.381L182.719 892.374ZM184.342 894.088C185.131 894.088 185.762 893.449 185.777 892.659C185.762 891.87 185.131 891.238 184.342 891.238C183.553 891.238 182.922 891.877 182.922 892.659C182.922 893.441 183.56 894.088 184.342 894.088Z" fill="#E71D36"/>
</g>
<g opacity="0.88">
<path d="M177.571 902.286C180.292 902.286 182.509 904.504 182.509 907.265C182.509 909.235 181.329 910.95 179.683 911.739C183.042 912.672 185.552 915.748 185.552 919.403C185.552 923.803 181.975 927.353 177.571 927.353C173.167 927.353 169.59 923.81 169.59 919.403C169.59 915.748 172.092 912.672 175.459 911.739C173.746 910.95 172.596 909.235 172.596 907.265C172.596 904.504 174.813 902.286 177.571 902.286ZM177.571 926.458C181.509 926.458 184.658 923.306 184.658 919.403C184.658 915.5 181.509 912.311 177.571 912.311C173.633 912.311 170.484 915.5 170.484 919.403C170.484 923.306 173.671 926.458 177.571 926.458ZM177.571 911.311C179.788 911.311 181.614 909.483 181.614 907.265C181.614 905.046 179.788 903.218 177.571 903.181C175.316 903.218 173.49 905.008 173.49 907.265C173.49 909.521 175.316 911.311 177.571 911.311Z" fill="#E71D36"/>
<path d="M204.694 913.063C206.302 914.673 207.129 916.824 207.129 918.937C207.129 921.05 206.302 923.231 204.694 924.878C203.048 926.488 200.898 927.315 198.794 927.315H198.757C196.645 927.315 194.503 926.525 192.887 924.878C192.707 924.698 192.707 924.412 192.887 924.232C193.068 924.051 193.353 924.051 193.533 924.232C194.999 925.66 196.863 926.382 198.757 926.382C200.65 926.382 202.582 925.668 204.017 924.201C205.483 922.772 206.204 920.87 206.204 918.937C206.204 917.004 205.49 915.176 204.055 913.71C202.589 912.273 200.726 911.559 198.832 911.559C198.651 911.559 198.509 911.491 198.403 911.341C198.403 911.303 198.366 911.303 198.366 911.303C198.185 911.123 198.185 910.799 198.366 910.619L205.595 903.173H194.541C194.293 903.173 194.075 902.955 194.075 902.707C194.075 902.459 194.285 902.241 194.541 902.241H206.67C206.918 902.241 207.136 902.451 207.136 902.707C207.136 902.782 207.136 902.85 207.099 902.925C207.061 903 207.023 903.068 206.993 903.106L199.621 910.656C201.485 910.837 203.303 911.627 204.701 913.056L204.694 913.063Z" fill="#E71D36"/>
</g>
<g opacity="0.51">
<path d="M157.024 844.158C156.911 844.067 156.911 843.864 157.024 843.759C157.137 843.646 157.317 843.646 157.422 843.759C158.332 844.647 159.512 845.113 160.669 845.113C161.826 845.113 163.044 844.647 163.938 843.759C164.847 842.849 165.291 841.668 165.291 840.488C165.291 839.307 164.847 838.133 163.938 837.239C163.051 836.351 161.894 835.907 160.692 835.907C159.489 835.907 158.354 836.351 157.422 837.239C157.355 837.351 157.159 837.351 157.047 837.261C157.024 837.261 157.002 837.238 157.002 837.216C156.979 837.216 156.979 837.193 156.979 837.193C156.934 837.126 156.911 837.058 156.934 836.968L157.715 830.41C157.738 830.274 157.828 830.184 157.918 830.161C157.963 830.139 158.009 830.116 158.076 830.116H165.591C165.749 830.116 165.877 830.274 165.877 830.402C165.877 830.583 165.742 830.688 165.591 830.688H158.279L157.61 836.336C158.52 835.644 159.609 835.313 160.699 835.313C162.029 835.313 163.367 835.802 164.389 836.825C165.366 837.825 165.877 839.141 165.877 840.472C165.877 841.804 165.366 843.142 164.344 844.143C163.344 845.165 162.007 845.677 160.676 845.677C159.346 845.677 158.054 845.165 157.032 844.143L157.024 844.158Z" fill="#E71D36"/>
<path d="M169.883 845.18L177.39 838.397C178.345 837.509 178.856 836.306 178.856 835.103C178.856 834.057 178.458 832.989 177.699 832.147C176.834 831.192 175.609 830.68 174.429 830.68C173.362 830.68 172.295 831.079 171.453 831.839L170.672 832.553C170.537 832.666 170.386 832.666 170.274 832.553C170.161 832.44 170.183 832.245 170.296 832.154L171.07 831.425C172.047 830.56 173.227 830.139 174.429 830.139C175.782 830.139 177.142 830.695 178.119 831.763C178.961 832.718 179.405 833.944 179.405 835.125C179.405 836.479 178.848 837.84 177.736 838.818L170.762 845.158H179.142C179.277 845.158 179.405 845.271 179.405 845.429C179.405 845.587 179.269 845.714 179.142 845.714H170.078C169.988 845.714 169.898 845.692 169.853 845.602C169.762 845.489 169.762 845.308 169.875 845.203L169.883 845.18Z" fill="#E71D36"/>
</g>
<g opacity="0.7">
<path d="M111.587 877.482C112.008 877.904 112.226 878.468 112.226 879.024C112.226 879.581 112.008 880.152 111.587 880.581C111.151 881.002 110.595 881.22 110.039 881.22H110.031C109.475 881.22 108.919 881.017 108.491 880.581C108.446 880.536 108.446 880.461 108.491 880.416C108.536 880.37 108.611 880.37 108.656 880.416C109.039 880.792 109.528 880.98 110.024 880.98C110.52 880.98 111.031 880.792 111.399 880.408C111.783 880.032 111.97 879.536 111.97 879.032C111.97 878.528 111.783 878.047 111.407 877.663C111.023 877.287 110.535 877.099 110.039 877.099C109.994 877.099 109.956 877.076 109.926 877.046H109.919C109.874 876.986 109.874 876.903 109.919 876.858L111.813 874.91H108.912C108.844 874.91 108.791 874.85 108.791 874.79C108.791 874.73 108.852 874.67 108.912 874.67H112.091C112.158 874.67 112.211 874.722 112.211 874.79C112.211 874.805 112.211 874.828 112.203 874.843C112.196 874.865 112.188 874.88 112.173 874.888L110.242 876.866C110.73 876.911 111.211 877.121 111.572 877.498L111.587 877.482Z" fill="#E71D36"/>
<path d="M114.872 881.055L117.449 874.91H113.992C113.925 874.91 113.872 874.858 113.872 874.79C113.872 874.737 113.932 874.67 113.992 874.67H117.652C117.705 874.67 117.772 874.737 117.772 874.79C117.772 874.82 117.757 874.85 117.735 874.873L115.09 881.153C115.082 881.19 115.029 881.228 114.984 881.228C114.984 881.228 114.954 881.22 114.939 881.22C114.872 881.19 114.857 881.13 114.872 881.062V881.055Z" fill="#E71D36"/>
</g>
<g opacity="0.87">
<path d="M100.84 854.251L105.094 850.407C105.635 849.904 105.921 849.227 105.921 848.542C105.921 847.948 105.695 847.346 105.267 846.865C104.778 846.324 104.087 846.038 103.418 846.038C102.817 846.038 102.208 846.263 101.735 846.692L101.291 847.098C101.216 847.158 101.126 847.158 101.066 847.098C101.006 847.038 101.013 846.925 101.081 846.873L101.524 846.459C102.08 845.97 102.742 845.729 103.425 845.729C104.192 845.729 104.959 846.045 105.515 846.647C105.996 847.189 106.244 847.88 106.244 848.55C106.244 849.317 105.928 850.084 105.304 850.641L101.351 854.228H106.093C106.169 854.228 106.244 854.288 106.244 854.378C106.244 854.469 106.169 854.544 106.093 854.544H100.96C100.908 854.544 100.863 854.529 100.833 854.484C100.78 854.424 100.78 854.318 100.848 854.258L100.84 854.251Z" fill="#E71D36"/>
<path d="M109.611 854.311L113.068 846.068H108.423C108.333 846.068 108.258 845.993 108.258 845.902C108.258 845.827 108.333 845.737 108.423 845.737H113.331C113.406 845.737 113.496 845.827 113.496 845.902C113.496 845.94 113.473 845.978 113.443 846.015L109.896 854.439C109.881 854.491 109.821 854.536 109.753 854.536C109.738 854.536 109.716 854.521 109.693 854.521C109.603 854.484 109.58 854.393 109.603 854.303L109.611 854.311Z" fill="#E71D36"/>
</g>
<g opacity="0.93">
<path d="M204.513 735.098H203.709C203.709 735.098 203.702 735.098 203.694 735.098C203.687 735.091 203.679 735.076 203.694 735.061L204.521 734.03H204.543C204.543 734.03 204.558 734.03 204.566 734.03V734.046V735.053H204.686C204.686 735.053 204.709 735.061 204.709 735.076C204.709 735.091 204.701 735.098 204.686 735.098H204.566V735.369C204.566 735.369 204.558 735.392 204.543 735.392C204.528 735.392 204.521 735.377 204.521 735.369V735.098H204.513ZM204.513 735.053V734.121L203.762 735.053H204.513Z" fill="#E71D36"/>
<path d="M204.934 734.188L205.212 734.023C205.212 734.023 205.242 734.023 205.25 734.03C205.25 734.046 205.25 734.053 205.235 734.068L204.957 734.234H204.919C204.919 734.234 204.919 734.196 204.926 734.188H204.934ZM205.197 734.046C205.197 734.046 205.212 734.023 205.22 734.023C205.235 734.023 205.242 734.038 205.242 734.046V735.369C205.242 735.369 205.235 735.392 205.22 735.392C205.205 735.392 205.197 735.377 205.197 735.369V734.046Z" fill="#E71D36"/>
</g>
<g opacity="0.76">
<path d="M81.6011 751.93C82.0069 752.336 82.2173 752.878 82.2173 753.419C82.2173 753.961 82.0069 754.502 81.6011 754.923C81.1877 755.33 80.6391 755.54 80.1055 755.54H80.098C79.5644 755.54 79.0233 755.345 78.61 754.923C78.5649 754.878 78.5649 754.803 78.61 754.758C78.6551 754.713 78.7302 754.713 78.7753 754.758C79.1436 755.119 79.617 755.299 80.098 755.299C80.579 755.299 81.0675 755.119 81.4282 754.743C81.7965 754.382 81.9843 753.901 81.9843 753.412C81.9843 752.923 81.804 752.457 81.4433 752.088C81.075 751.727 80.6015 751.547 80.1206 751.547C80.0755 751.547 80.0379 751.532 80.0078 751.494C80.0078 751.486 80.0003 751.486 80.0003 751.486C79.9552 751.441 79.9552 751.359 80.0003 751.313L81.8265 749.426H79.0308C78.9707 749.426 78.9106 749.373 78.9106 749.305C78.9106 749.238 78.9632 749.185 79.0308 749.185H82.1046C82.1647 749.185 82.2248 749.238 82.2248 749.305C82.2248 749.32 82.2248 749.343 82.2173 749.358C82.2098 749.373 82.2023 749.396 82.1873 749.403L80.3235 751.313C80.7969 751.359 81.2554 751.562 81.6086 751.923L81.6011 751.93Z" fill="#E71D36"/>
<path d="M84.0285 753.126C84.0285 752.276 84.2089 751.321 84.6448 750.569C85.0806 749.809 85.8021 749.245 86.8467 749.2C86.9069 749.2 86.967 749.245 86.967 749.32C86.967 749.373 86.9219 749.433 86.8693 749.433C85.8998 749.478 85.276 749.975 84.8477 750.682C84.5245 751.238 84.3517 751.9 84.284 752.547C84.6222 751.885 85.3061 751.441 86.1027 751.441C87.2375 751.441 88.1619 752.359 88.1694 753.487C88.1619 754.63 87.2375 755.548 86.1027 755.548C85.0431 755.548 84.1713 754.758 84.0661 753.728C84.0586 753.72 84.0586 753.705 84.0586 753.705C84.0435 753.517 84.0285 753.321 84.0285 753.133V753.126ZM86.1027 755.322C87.1173 755.322 87.9214 754.502 87.944 753.487C87.9289 752.479 87.1173 751.667 86.1027 751.667C85.0882 751.667 84.284 752.479 84.284 753.487C84.284 754.495 85.1032 755.322 86.1027 755.322Z" fill="#E71D36"/>
</g>
<g opacity="0.54">
<path d="M28.28 712.04C28.7309 712.491 28.9564 713.085 28.9564 713.672C28.9564 714.258 28.7309 714.868 28.28 715.326C27.8215 715.77 27.2278 716.003 26.6417 716.003H26.6341C26.0479 716.003 25.4467 715.785 25.0033 715.326C24.9507 715.274 24.9507 715.198 25.0033 715.146C25.0559 715.093 25.1311 715.093 25.1837 715.146C25.5895 715.544 26.1081 715.74 26.6341 715.74C27.1602 715.74 27.6938 715.537 28.0996 715.131C28.5054 714.732 28.7084 714.206 28.7084 713.664C28.7084 713.123 28.513 712.619 28.1146 712.213C27.7088 711.814 27.1903 711.611 26.6642 711.611C26.6116 711.611 26.574 711.588 26.544 711.551H26.5364C26.4838 711.491 26.4838 711.4 26.5364 711.355L28.5505 709.287H25.4768C25.4091 709.287 25.349 709.227 25.349 709.159C25.349 709.092 25.4091 709.031 25.4768 709.031H28.8511C28.9188 709.031 28.9789 709.092 28.9789 709.159C28.9789 709.182 28.9789 709.197 28.9714 709.219C28.9639 709.242 28.9488 709.257 28.9413 709.272L26.8897 711.37C27.4082 711.423 27.9192 711.641 28.3025 712.04H28.28Z" fill="#5EB0EF"/>
<path d="M30.7976 715.785L34.1644 712.747C34.5928 712.348 34.8182 711.814 34.8182 711.273C34.8182 710.806 34.6379 710.325 34.2997 709.949C33.9089 709.52 33.3678 709.295 32.8342 709.295C32.3532 709.295 31.8798 709.475 31.504 709.813L31.1583 710.129C31.0982 710.182 31.0306 710.182 30.9779 710.129C30.9253 710.077 30.9404 709.994 30.9855 709.949L31.3312 709.618C31.767 709.227 32.2931 709.039 32.8342 709.039C33.443 709.039 34.0442 709.287 34.4876 709.768C34.8633 710.197 35.0663 710.746 35.0663 711.273C35.0663 711.882 34.8182 712.491 34.3222 712.927L31.1959 715.762H34.946C35.0061 715.762 35.0663 715.815 35.0663 715.883C35.0663 715.95 35.0061 716.011 34.946 716.011H30.8878C30.8502 716.011 30.8051 716.003 30.7901 715.958C30.7525 715.905 30.7525 715.83 30.7976 715.777V715.785Z" fill="#5EB0EF"/>
</g>
<g opacity="0.71">
<path d="M138.912 682.363L142.069 674.842H137.838C137.755 674.842 137.687 674.774 137.687 674.691C137.687 674.624 137.755 674.541 137.838 674.541H142.309C142.377 674.541 142.459 674.624 142.459 674.691C142.459 674.729 142.437 674.759 142.414 674.797L139.183 682.475C139.168 682.521 139.115 682.566 139.055 682.566C139.04 682.566 139.025 682.551 138.995 682.551C138.912 682.513 138.89 682.438 138.912 682.355V682.363Z" fill="#E71D36"/>
<path d="M144.068 682.302L147.946 678.798C148.442 678.339 148.705 677.722 148.705 677.098C148.705 676.557 148.502 676.008 148.111 675.571C147.668 675.083 147.037 674.812 146.428 674.812C145.879 674.812 145.323 675.015 144.887 675.406L144.489 675.774C144.421 675.835 144.339 675.835 144.286 675.774C144.226 675.714 144.241 675.617 144.301 675.571L144.699 675.195C145.203 674.744 145.812 674.534 146.435 674.534C147.134 674.534 147.833 674.819 148.337 675.368C148.773 675.865 148.998 676.496 148.998 677.106C148.998 677.805 148.712 678.504 148.141 679.016L144.541 682.287H148.863C148.93 682.287 148.998 682.348 148.998 682.423C148.998 682.498 148.93 682.573 148.863 682.573H144.181C144.136 682.573 144.091 682.558 144.068 682.513C144.023 682.453 144.023 682.363 144.083 682.31L144.068 682.302Z" fill="#E71D36"/>
</g>
<g opacity="0.63">
<path d="M104.785 728.916C105.319 729.45 105.59 730.157 105.59 730.857C105.59 731.556 105.319 732.278 104.785 732.82C104.244 733.354 103.53 733.624 102.839 733.624H102.824C102.125 733.624 101.419 733.361 100.885 732.82C100.825 732.759 100.825 732.669 100.885 732.609C100.945 732.549 101.035 732.549 101.095 732.609C101.576 733.083 102.193 733.316 102.816 733.316C103.44 733.316 104.079 733.083 104.552 732.594C105.033 732.12 105.274 731.496 105.274 730.857C105.274 730.217 105.041 729.616 104.567 729.134C104.086 728.661 103.47 728.427 102.846 728.427C102.786 728.427 102.741 728.405 102.704 728.352C102.704 728.337 102.689 728.337 102.689 728.337C102.629 728.277 102.629 728.172 102.689 728.112L105.071 725.652H101.419C101.336 725.652 101.268 725.585 101.268 725.502C101.268 725.419 101.343 725.352 101.419 725.352H105.424C105.507 725.352 105.582 725.419 105.582 725.502C105.582 725.524 105.582 725.547 105.567 725.57C105.552 725.592 105.544 725.615 105.529 725.63L103.094 728.127C103.711 728.187 104.312 728.443 104.77 728.916H104.785Z" fill="#5EB0EF"/>
<path d="M107.776 733.361L111.767 729.759C112.278 729.285 112.548 728.645 112.548 728.014C112.548 727.457 112.338 726.893 111.932 726.442C111.474 725.93 110.82 725.66 110.196 725.66C109.632 725.66 109.061 725.87 108.618 726.276L108.204 726.652C108.137 726.713 108.054 726.713 107.994 726.652C107.934 726.592 107.949 726.487 108.009 726.442L108.422 726.051C108.941 725.592 109.565 725.366 110.203 725.366C110.925 725.366 111.646 725.66 112.165 726.231C112.616 726.743 112.849 727.39 112.849 728.014C112.849 728.736 112.556 729.458 111.962 729.977L108.257 733.346H112.706C112.781 733.346 112.849 733.406 112.849 733.489C112.849 733.572 112.781 733.639 112.706 733.639H107.889C107.844 733.639 107.791 733.624 107.769 733.579C107.723 733.519 107.723 733.429 107.784 733.369L107.776 733.361Z" fill="#5EB0EF"/>
</g>
<g opacity="0.77">
<path d="M39.6356 771.507C39.6356 771.093 39.7257 770.642 39.9287 770.273C40.1391 769.905 40.4848 769.634 40.9883 769.611C41.0184 769.611 41.0409 769.634 41.0409 769.672C41.0409 769.694 41.0184 769.724 40.9958 769.724C40.5299 769.747 40.2293 769.987 40.0264 770.326C39.8685 770.589 39.7859 770.913 39.7558 771.221C39.9136 770.905 40.2443 770.687 40.6276 770.687C41.1687 770.687 41.6196 771.123 41.6196 771.672C41.6196 772.221 41.1687 772.657 40.6276 772.657C40.1165 772.657 39.7032 772.281 39.6506 771.785V771.77C39.6431 771.68 39.6356 771.589 39.6356 771.499V771.507ZM40.6351 772.56C41.1236 772.56 41.5069 772.169 41.5219 771.68C41.5144 771.198 41.1236 770.807 40.6351 770.807C40.1466 770.807 39.7633 771.198 39.7633 771.68C39.7633 772.161 40.1541 772.56 40.6351 772.56Z" fill="#E71D36"/>
<path d="M42.2284 769.995L42.8371 769.634C42.8371 769.634 42.8972 769.619 42.9122 769.649C42.9273 769.679 42.9122 769.709 42.8897 769.724L42.281 770.093C42.281 770.093 42.2659 770.093 42.2584 770.093C42.2434 770.093 42.2208 770.093 42.2133 770.063C42.1983 770.04 42.2058 770.002 42.2359 769.987L42.2284 769.995ZM42.8145 769.679C42.8145 769.679 42.8371 769.619 42.8672 769.619C42.9047 769.619 42.9273 769.649 42.9273 769.679V772.612C42.9273 772.612 42.9047 772.672 42.8672 772.672C42.8446 772.672 42.8145 772.65 42.8145 772.612V769.679Z" fill="#E71D36"/>
</g>
<g opacity="0.92">
<path d="M77.9785 720.794L82.0592 717.109C82.5778 716.627 82.8559 715.973 82.8559 715.319C82.8559 714.747 82.6379 714.168 82.2246 713.709C81.7511 713.19 81.0898 712.912 80.451 712.912C79.8723 712.912 79.2861 713.13 78.8277 713.544L78.4068 713.927C78.3317 713.988 78.249 713.988 78.1889 713.927C78.1288 713.867 78.1363 713.754 78.2039 713.709L78.6248 713.311C79.1584 712.837 79.7972 712.611 80.451 712.611C81.1875 712.611 81.924 712.912 82.4576 713.491C82.916 714.01 83.1565 714.672 83.1565 715.319C83.1565 716.056 82.8559 716.793 82.2471 717.327L78.4519 720.771H83.0062C83.0813 720.771 83.149 720.832 83.149 720.914C83.149 720.997 83.0738 721.072 83.0062 721.072H78.0762C78.0311 721.072 77.9785 721.057 77.9559 721.012C77.9108 720.952 77.9108 720.854 77.9709 720.794H77.9785Z" fill="#E71D36"/>
<path d="M85.2983 720.237C85.2382 720.192 85.2382 720.079 85.2983 720.019C85.3585 719.959 85.4562 719.959 85.5163 720.019C86.0123 720.501 86.6511 720.756 87.2824 720.756C87.9136 720.756 88.575 720.501 89.056 720.019C89.552 719.523 89.7925 718.884 89.7925 718.244C89.7925 717.605 89.552 716.966 89.056 716.477C88.575 715.996 87.9437 715.747 87.2899 715.747C86.6361 715.747 86.0198 715.988 85.5163 716.477C85.4787 716.537 85.3735 716.537 85.3134 716.492C85.2983 716.492 85.2908 716.477 85.2908 716.469C85.2758 716.469 85.2758 716.454 85.2758 716.454C85.2532 716.417 85.2382 716.379 85.2532 716.334L85.6741 712.769C85.6891 712.701 85.7342 712.649 85.7793 712.634C85.8019 712.619 85.8244 712.611 85.862 712.611H89.9428C90.0254 712.611 90.1006 712.694 90.1006 712.769C90.1006 712.867 90.0254 712.927 89.9428 712.927H85.9672L85.6065 716.003C86.1025 715.627 86.6962 715.447 87.2824 715.447C88.0113 715.447 88.7328 715.71 89.2889 716.266C89.8225 716.808 90.1006 717.522 90.1006 718.252C90.1006 718.981 89.8225 719.703 89.2664 720.245C88.7253 720.801 87.9963 721.08 87.2748 721.08C86.5534 721.08 85.8469 720.801 85.2908 720.245L85.2983 720.237Z" fill="#E71D36"/>
</g>
<g opacity="0.68">
<path d="M147.825 678.264C148.817 679.256 149.328 680.58 149.328 681.881C149.328 683.182 148.817 684.529 147.825 685.544C146.811 686.537 145.488 687.04 144.188 687.04H144.165C142.865 687.04 141.543 686.559 140.551 685.544C140.438 685.431 140.438 685.258 140.551 685.145C140.663 685.032 140.836 685.032 140.949 685.145C141.851 686.025 143.001 686.469 144.173 686.469C145.345 686.469 146.533 686.025 147.412 685.123C148.314 684.235 148.757 683.07 148.757 681.874C148.757 680.678 148.314 679.557 147.435 678.655C146.533 677.767 145.383 677.331 144.218 677.331C144.105 677.331 144.023 677.286 143.955 677.196C143.955 677.173 143.932 677.173 143.932 677.173C143.82 677.06 143.82 676.865 143.932 676.752L148.389 672.157H141.573C141.415 672.157 141.287 672.029 141.287 671.871C141.287 671.713 141.422 671.585 141.573 671.585H149.05C149.208 671.585 149.336 671.721 149.336 671.871C149.336 671.916 149.336 671.961 149.313 672.006C149.291 672.052 149.268 672.097 149.246 672.119L144.699 676.775C145.849 676.887 146.969 677.369 147.833 678.256L147.825 678.264Z" fill="#E71D36"/>
<path d="M162.69 683.784H153.672C153.604 683.784 153.559 683.761 153.492 683.716C153.379 683.604 153.356 683.453 153.447 683.34L162.758 671.751L162.803 671.706C162.871 671.661 162.916 671.638 162.983 671.638C163.074 671.638 163.164 671.706 163.201 671.773C163.224 671.796 163.224 671.818 163.246 671.841C163.246 671.864 163.269 671.886 163.269 671.909V683.235H164.614C164.787 683.235 164.9 683.348 164.9 683.498C164.9 683.679 164.787 683.784 164.614 683.784H163.269V686.785C163.269 686.935 163.156 687.071 162.983 687.071C162.848 687.071 162.698 686.935 162.698 686.785V683.784H162.69ZM162.69 683.235V672.774L154.243 683.235H162.69Z" fill="#E71D36"/>
</g>
<g opacity="0.95">
<path d="M98.3298 626.897L99.0738 626.46C99.0738 626.46 99.149 626.445 99.1715 626.483C99.1866 626.513 99.1715 626.551 99.1415 626.573L98.3974 627.017C98.3974 627.017 98.3749 627.017 98.3674 627.017C98.3448 627.017 98.3223 627.017 98.3073 626.987C98.2922 626.957 98.2997 626.919 98.3298 626.897ZM99.0362 626.513C99.0362 626.513 99.0663 626.445 99.0964 626.445C99.1415 626.445 99.164 626.483 99.164 626.513V630.078C99.164 630.116 99.1415 630.146 99.0964 630.146C99.0663 630.146 99.0362 630.116 99.0362 630.078V626.513Z" fill="#E71D36"/>
<path d="M102.396 629.356H100.231C100.231 629.356 100.209 629.356 100.186 629.341C100.156 629.311 100.156 629.281 100.171 629.251L102.403 626.468H102.411C102.411 626.468 102.433 626.445 102.456 626.445C102.478 626.445 102.501 626.46 102.508 626.475C102.508 626.475 102.508 626.483 102.523 626.491V626.506V629.221H102.846C102.892 629.221 102.914 629.243 102.914 629.281C102.914 629.326 102.892 629.348 102.846 629.348H102.523V630.07C102.523 630.108 102.493 630.138 102.456 630.138C102.426 630.138 102.388 630.108 102.388 630.07V629.348L102.396 629.356ZM102.396 629.221V626.709L100.366 629.221H102.396Z" fill="#E71D36"/>
</g>
<g opacity="0.84">
<path d="M2.94628 603.966C3.95333 603.966 4.77249 604.786 4.77249 605.808C4.77249 606.538 4.33661 607.17 3.72787 607.463C4.96789 607.809 5.89978 608.945 5.89978 610.298C5.89978 611.923 4.5771 613.239 2.9538 613.239C1.3305 613.239 0.0078125 611.93 0.0078125 610.298C0.0078125 608.952 0.932191 607.809 2.17221 607.463C1.54093 607.17 1.11256 606.538 1.11256 605.808C1.11256 604.786 1.93172 603.966 2.9538 603.966H2.94628ZM2.94628 612.901C4.39673 612.901 5.5616 611.735 5.5616 610.291C5.5616 608.847 4.39673 607.674 2.94628 607.674C1.49584 607.674 0.330969 608.854 0.330969 610.291C0.330969 611.727 1.51087 612.901 2.94628 612.901ZM2.94628 607.305C3.76545 607.305 4.44182 606.628 4.44182 605.808C4.44182 604.989 3.76545 604.312 2.94628 604.297C2.11209 604.312 1.43571 604.974 1.43571 605.808C1.43571 606.643 2.11209 607.305 2.94628 607.305Z" fill="#5EB0EF"/>
<path d="M11.2803 603.966C12.2873 603.966 13.1065 604.786 13.1065 605.808C13.1065 606.538 12.6706 607.17 12.0619 607.463C13.3019 607.809 14.2338 608.945 14.2338 610.298C14.2338 611.923 12.9111 613.239 11.2878 613.239C9.66448 613.239 8.3418 611.93 8.3418 610.298C8.3418 608.952 9.26617 607.809 10.5062 607.463C9.87491 607.17 9.44654 606.538 9.44654 605.808C9.44654 604.786 10.2657 603.966 11.2878 603.966H11.2803ZM11.2803 612.901C12.7307 612.901 13.8956 611.735 13.8956 610.291C13.8956 608.847 12.7307 607.674 11.2803 607.674C9.82982 607.674 8.66495 608.854 8.66495 610.291C8.66495 611.727 9.84485 612.901 11.2803 612.901ZM11.2803 607.305C12.0994 607.305 12.7758 606.628 12.7758 605.808C12.7758 604.989 12.0994 604.312 11.2803 604.297C10.4461 604.312 9.7697 604.974 9.7697 605.808C9.7697 606.643 10.4461 607.305 11.2803 607.305Z" fill="#5EB0EF"/>
</g>
<g opacity="0.71">
<path d="M102.929 629.942C102.929 628.799 103.169 627.528 103.755 626.521C104.342 625.505 105.311 624.746 106.701 624.685C106.784 624.685 106.859 624.746 106.859 624.843C106.859 624.919 106.799 624.986 106.724 624.986C105.424 625.046 104.59 625.716 104.018 626.663C103.583 627.4 103.35 628.288 103.267 629.168C103.718 628.28 104.642 627.686 105.709 627.686C107.227 627.686 108.46 628.912 108.475 630.431C108.46 631.958 107.227 633.191 105.709 633.191C104.289 633.191 103.124 632.131 102.981 630.747C102.966 630.732 102.966 630.71 102.966 630.695C102.944 630.454 102.929 630.198 102.929 629.942ZM105.709 632.883C107.07 632.883 108.144 631.793 108.174 630.431C108.152 629.085 107.07 627.987 105.709 627.987C104.349 627.987 103.267 629.078 103.267 630.431C103.267 631.785 104.357 632.883 105.709 632.883Z" fill="#E71D36"/>
<path d="M114.75 628.341C115.299 628.89 115.577 629.619 115.577 630.334C115.577 631.048 115.299 631.793 114.75 632.349C114.194 632.898 113.465 633.176 112.751 633.176H112.736C112.022 633.176 111.293 632.913 110.744 632.349C110.684 632.289 110.684 632.191 110.744 632.131C110.804 632.071 110.902 632.071 110.962 632.131C111.458 632.62 112.09 632.861 112.736 632.861C113.382 632.861 114.036 632.62 114.517 632.116C115.013 631.635 115.253 630.988 115.253 630.334C115.253 629.679 115.013 629.055 114.524 628.559C114.028 628.07 113.397 627.829 112.751 627.829C112.691 627.829 112.638 627.807 112.601 627.761C112.601 627.746 112.586 627.746 112.586 627.746C112.525 627.686 112.525 627.573 112.586 627.513L115.036 624.986H111.285C111.203 624.986 111.128 624.911 111.128 624.828C111.128 624.746 111.203 624.67 111.285 624.67H115.396C115.479 624.67 115.554 624.746 115.554 624.828C115.554 624.851 115.554 624.881 115.539 624.904C115.524 624.926 115.517 624.949 115.501 624.964L112.999 627.528C113.63 627.588 114.246 627.859 114.72 628.341H114.75Z" fill="#E71D36"/>
</g>
<g opacity="0.89">
<path d="M214.584 602.86H209.067C209.03 602.86 209 602.845 208.962 602.823C208.894 602.755 208.879 602.657 208.932 602.589L214.629 595.497L214.659 595.475C214.696 595.445 214.726 595.437 214.764 595.437C214.816 595.437 214.869 595.475 214.899 595.52C214.914 595.535 214.914 595.55 214.929 595.557C214.929 595.572 214.944 595.588 214.944 595.595V602.522H215.763C215.869 602.522 215.936 602.589 215.936 602.687C215.936 602.792 215.869 602.86 215.763 602.86H214.944V604.695C214.944 604.793 214.877 604.868 214.771 604.868C214.689 604.868 214.599 604.785 214.599 604.695V602.86H214.584ZM214.584 602.522V596.122L209.413 602.522H214.584Z" fill="#E71D36"/>
<path d="M217.86 604.56L222.422 600.446C223.001 599.904 223.309 599.175 223.309 598.445C223.309 597.814 223.068 597.159 222.61 596.648C222.084 596.069 221.34 595.753 220.626 595.753C219.979 595.753 219.333 595.994 218.815 596.452L218.341 596.881C218.258 596.949 218.168 596.949 218.101 596.881C218.033 596.813 218.048 596.693 218.116 596.64L218.589 596.197C219.183 595.67 219.897 595.415 220.626 595.415C221.452 595.415 222.272 595.753 222.865 596.4C223.376 596.979 223.647 597.723 223.647 598.438C223.647 599.265 223.309 600.085 222.632 600.679L218.394 604.53H223.482C223.564 604.53 223.639 604.597 223.639 604.695C223.639 604.793 223.557 604.868 223.482 604.868H217.973C217.92 604.868 217.868 604.853 217.838 604.8C217.785 604.733 217.785 604.628 217.853 604.56H217.86Z" fill="#E71D36"/>
</g>
<g opacity="0.73">
<path d="M103.049 590.082L105.319 584.675H102.275C102.215 584.675 102.17 584.622 102.17 584.57C102.17 584.517 102.223 584.464 102.275 584.464H105.492C105.544 584.464 105.597 584.524 105.597 584.57C105.597 584.592 105.582 584.622 105.559 584.645L103.237 590.165C103.237 590.165 103.185 590.233 103.147 590.233C103.14 590.233 103.125 590.225 103.102 590.225C103.042 590.203 103.027 590.142 103.042 590.082H103.049Z" fill="#E71D36"/>
<path d="M109.663 586.939C110.031 587.307 110.226 587.803 110.226 588.292C110.226 588.781 110.039 589.278 109.663 589.661C109.287 590.03 108.791 590.225 108.303 590.225H108.295C107.807 590.225 107.318 590.045 106.942 589.661C106.897 589.624 106.897 589.556 106.942 589.511C106.987 589.466 107.048 589.466 107.093 589.511C107.431 589.842 107.859 590.007 108.295 590.007C108.731 590.007 109.174 589.842 109.505 589.503C109.843 589.172 110.009 588.736 110.009 588.292C110.009 587.849 109.843 587.427 109.513 587.089C109.174 586.758 108.746 586.593 108.31 586.593C108.265 586.593 108.235 586.578 108.212 586.54H108.205C108.167 586.487 108.167 586.42 108.205 586.375L109.873 584.66H107.326C107.265 584.66 107.22 584.607 107.22 584.555C107.22 584.502 107.273 584.449 107.326 584.449H110.121C110.181 584.449 110.226 584.502 110.226 584.555C110.226 584.57 110.227 584.585 110.219 584.607L110.204 584.637L108.498 586.382C108.926 586.427 109.347 586.608 109.67 586.939H109.663Z" fill="#E71D36"/>
</g>
<g opacity="0.92">
<path d="M70.2975 462.718L74.8743 460.003C75.0697 459.875 75.3328 459.905 75.4605 460.13C75.5582 460.326 75.4906 460.552 75.2651 460.687L70.6808 463.44C70.6132 463.47 70.5531 463.47 70.4854 463.47C70.3577 463.47 70.1923 463.44 70.1247 463.274C70.027 463.109 70.057 462.845 70.29 462.718H70.2975ZM74.6789 460.326C74.6789 460.13 74.8743 459.897 75.0697 459.897C75.3328 459.897 75.4981 460.123 75.4981 460.326V482.362C75.4981 482.595 75.3328 482.791 75.0697 482.791C74.8743 482.791 74.6789 482.595 74.6789 482.362V460.326Z" fill="#5EB0EF"/>
<path d="M81.2548 462.717L85.8316 460.002C86.027 459.875 86.29 459.905 86.4178 460.13C86.5155 460.326 86.4479 460.551 86.2224 460.687L81.6456 463.44C81.578 463.47 81.5178 463.47 81.4502 463.47C81.3224 463.47 81.1571 463.439 81.0895 463.274C80.9918 463.109 81.0218 462.845 81.2548 462.717ZM85.6362 460.326C85.6362 460.13 85.8316 459.897 86.027 459.897C86.29 459.897 86.4554 460.123 86.4554 460.326V482.362C86.4554 482.595 86.29 482.791 86.027 482.791C85.8316 482.791 85.6362 482.595 85.6362 482.362V460.326Z" fill="#5EB0EF"/>
</g>
<g opacity="0.88">
<path d="M35.3139 892.08C35.855 892.08 36.2908 892.516 36.2908 893.065C36.2908 893.457 36.0579 893.795 35.7347 893.953C36.4036 894.141 36.8996 894.75 36.8996 895.472C36.8996 896.345 36.1931 897.044 35.3214 897.044C34.4496 897.044 33.7432 896.345 33.7432 895.472C33.7432 894.75 34.2392 894.141 34.908 893.953C34.5698 893.795 34.3444 893.457 34.3444 893.065C34.3444 892.516 34.7803 892.08 35.3289 892.08H35.3139ZM35.3139 896.864C36.0954 896.864 36.7117 896.239 36.7117 895.465C36.7117 894.69 36.0879 894.058 35.3139 894.058C34.5398 894.058 33.9085 894.69 33.9085 895.465C33.9085 896.239 34.5398 896.864 35.3139 896.864ZM35.3139 893.863C35.7497 893.863 36.1105 893.502 36.1105 893.065C36.1105 892.629 35.7497 892.268 35.3139 892.261C34.8705 892.261 34.5097 892.622 34.5097 893.065C34.5097 893.509 34.8705 893.863 35.3139 893.863Z" fill="#E71D36"/>
</g>
<g opacity="0.63">
<path d="M77.5723 937.363C77.5723 936.972 77.6549 936.536 77.8578 936.182C78.0608 935.829 78.3914 935.573 78.8724 935.55C78.9025 935.55 78.925 935.573 78.925 935.603C78.925 935.626 78.9025 935.656 78.8799 935.656C78.4365 935.678 78.1434 935.904 77.948 936.235C77.7977 936.49 77.7226 936.791 77.6925 937.092C77.8503 936.784 78.166 936.581 78.5342 936.581C79.0528 936.581 79.4811 937.002 79.4811 937.528C79.4811 938.055 79.0528 938.476 78.5342 938.476C78.0457 938.476 77.6474 938.115 77.5948 937.634V937.619C77.5873 937.536 77.5798 937.446 77.5798 937.363H77.5723ZM78.5267 938.371C78.9926 938.371 79.3684 937.995 79.3759 937.528C79.3684 937.062 78.9926 936.686 78.5267 936.686C78.0608 936.686 77.685 937.062 77.685 937.528C77.685 937.995 78.0608 938.371 78.5267 938.371Z" fill="#E71D36"/>
<path d="M81.6308 936.806C81.8187 936.994 81.9164 937.243 81.9164 937.491C81.9164 937.739 81.8187 937.995 81.6308 938.183C81.4429 938.371 81.1874 938.468 80.9469 938.468C80.6989 938.468 80.4509 938.378 80.263 938.183C80.2405 938.16 80.2405 938.13 80.263 938.107C80.2855 938.085 80.3156 938.085 80.3382 938.107C80.511 938.273 80.7289 938.356 80.9469 938.356C81.1648 938.356 81.3903 938.273 81.5631 938.1C81.736 937.934 81.8187 937.709 81.8187 937.483C81.8187 937.258 81.736 937.047 81.5707 936.874C81.3978 936.709 81.1799 936.626 80.9619 936.626C80.9394 936.626 80.9243 936.618 80.9093 936.603C80.8868 936.581 80.8868 936.543 80.9093 936.521L81.751 935.656H80.4659C80.4659 935.656 80.4133 935.633 80.4133 935.603C80.4133 935.573 80.4359 935.55 80.4659 935.55H81.8788C81.8788 935.55 81.9314 935.573 81.9314 935.603V935.618C81.9314 935.618 81.9164 935.641 81.9164 935.648L81.0596 936.528C81.2776 936.551 81.488 936.641 81.6533 936.806H81.6308Z" fill="#E71D36"/>
</g>
<g opacity="0.76">
<path d="M25.5146 949.321C25.5146 948.659 25.6574 947.93 25.9881 947.343C26.3263 946.757 26.8824 946.32 27.6941 946.283C27.7467 946.283 27.7843 946.32 27.7843 946.373C27.7843 946.418 27.7467 946.456 27.7091 946.456C26.9576 946.493 26.4766 946.877 26.1459 947.426C25.8904 947.855 25.7627 948.366 25.71 948.87C25.9731 948.359 26.4991 948.013 27.1229 948.013C27.9947 948.013 28.7162 948.719 28.7162 949.599C28.7162 950.479 27.9947 951.194 27.1229 951.194C26.3038 951.194 25.6274 950.585 25.5447 949.787C25.5447 949.787 25.5372 949.765 25.5372 949.757C25.5222 949.622 25.5146 949.472 25.5146 949.321ZM27.1229 951.021C27.9045 951.021 28.5283 950.389 28.5433 949.607C28.5283 948.825 27.9045 948.193 27.1229 948.193C26.3413 948.193 25.71 948.825 25.71 949.607C25.71 950.389 26.3413 951.021 27.1229 951.021Z" fill="#E71D36"/>
<path d="M31.6918 946.283C32.6538 946.29 33.3677 947.411 33.3677 948.727C33.3677 950.043 32.6538 951.179 31.6918 951.186C30.7449 951.186 30.0234 950.058 30.0234 948.727C30.0234 947.396 30.7449 946.29 31.6918 946.283ZM30.1963 948.727C30.1963 949.374 30.3767 949.945 30.6472 950.351C30.9178 950.773 31.2935 951.013 31.6843 951.013C32.0751 951.013 32.4509 950.773 32.7289 950.351C33.007 949.945 33.1874 949.374 33.1874 948.727C33.1874 948.08 33.0145 947.524 32.7289 947.118C32.4584 946.696 32.0826 946.456 31.6843 946.456C31.286 946.456 30.9178 946.696 30.6472 947.118C30.3767 947.524 30.1963 948.103 30.1963 948.727Z" fill="#E71D36"/>
</g>
<g opacity="0.82">
<path d="M85.8394 809.539C86.7337 810.434 87.1921 811.638 87.1921 812.811C87.1921 813.984 86.7337 815.203 85.8394 816.12C84.9225 817.015 83.7276 817.474 82.5477 817.474H82.5252C81.3453 817.474 80.1503 817.038 79.256 816.12C79.1583 816.022 79.1583 815.857 79.256 815.759C79.3537 815.661 79.5191 815.661 79.6167 815.759C80.4359 816.556 81.473 816.955 82.5252 816.955C83.5773 816.955 84.6595 816.556 85.4561 815.737C86.2753 814.939 86.6736 813.879 86.6736 812.803C86.6736 811.728 86.2753 810.705 85.4787 809.893C84.6595 809.096 83.6224 808.697 82.5703 808.697C82.4726 808.697 82.3899 808.659 82.3298 808.577C82.3298 808.554 82.3072 808.554 82.3072 808.554C82.2095 808.456 82.2095 808.276 82.3072 808.178L86.3354 804.027H80.1804C80.0451 804.027 79.9174 803.906 79.9174 803.763C79.9174 803.62 80.0376 803.508 80.1804 803.508H86.9366C87.0719 803.508 87.1921 803.628 87.1921 803.763C87.1921 803.801 87.1921 803.846 87.1696 803.884C87.147 803.921 87.132 803.966 87.1095 803.982L83.0061 808.193C84.0432 808.291 85.0578 808.735 85.8394 809.532V809.539Z" fill="#E71D36"/>
<path d="M95.9475 803.538C98.6755 803.56 100.712 806.749 100.712 810.502C100.712 814.255 98.6755 817.481 95.9475 817.504C93.257 817.481 91.2053 814.293 91.2053 810.502C91.2053 806.712 93.257 803.56 95.9475 803.538ZM91.7013 810.502C91.7013 812.337 92.2199 813.977 92.994 815.127C93.768 816.323 94.8277 817 95.9475 817C97.0672 817 98.1419 816.323 98.916 815.127C99.7126 813.969 100.216 812.337 100.216 810.502C100.216 808.667 99.7201 807.073 98.916 805.914C98.1419 804.719 97.0823 804.042 95.9475 804.042C94.8127 804.042 93.7755 804.719 92.994 805.914C92.2199 807.073 91.7013 808.705 91.7013 810.502Z" fill="#E71D36"/>
</g>
<g opacity="0.72">
<path d="M144.94 799.296C144.872 799.243 144.872 799.131 144.94 799.063C145.007 798.995 145.105 798.995 145.173 799.063C145.699 799.574 146.383 799.853 147.052 799.853C147.72 799.853 148.427 799.582 148.945 799.063C149.472 798.536 149.735 797.852 149.735 797.168C149.735 796.483 149.479 795.806 148.945 795.287C148.434 794.768 147.758 794.513 147.067 794.513C146.375 794.513 145.714 794.768 145.173 795.287C145.135 795.348 145.015 795.348 144.955 795.302C144.94 795.302 144.932 795.287 144.932 795.272C144.917 795.272 144.917 795.257 144.917 795.257C144.895 795.22 144.88 795.182 144.895 795.129L145.346 791.331C145.361 791.256 145.413 791.204 145.458 791.189C145.481 791.174 145.511 791.166 145.549 791.166H149.9C149.99 791.166 150.065 791.256 150.065 791.331C150.065 791.437 149.99 791.497 149.9 791.497H145.661L145.278 794.769C145.804 794.37 146.435 794.174 147.067 794.174C147.841 794.174 148.615 794.46 149.201 795.047C149.765 795.626 150.065 796.385 150.065 797.16C150.065 797.935 149.765 798.709 149.178 799.289C148.6 799.883 147.826 800.176 147.052 800.176C146.278 800.176 145.534 799.883 144.94 799.289V799.296Z" fill="#5EB0EF"/>
<path d="M157.941 798.266H152.673C152.635 798.266 152.612 798.251 152.567 798.228C152.507 798.168 152.492 798.078 152.545 798.01L157.978 791.249L158.001 791.226C158.039 791.204 158.069 791.189 158.106 791.189C158.159 791.189 158.211 791.226 158.234 791.264C158.249 791.279 158.249 791.294 158.256 791.302C158.256 791.317 158.272 791.324 158.272 791.339V797.95H159.053C159.158 797.95 159.218 798.018 159.218 798.108C159.218 798.213 159.151 798.273 159.053 798.273H158.272V800.026C158.272 800.116 158.204 800.191 158.106 800.191C158.031 800.191 157.941 800.116 157.941 800.026V798.273V798.266ZM157.941 797.942V791.835L153.011 797.942H157.941Z" fill="#5EB0EF"/>
</g>
<g opacity="0.97">
<path d="M42.9949 827.431L46.2866 819.587H41.8676C41.7849 819.587 41.7098 819.52 41.7098 819.429C41.7098 819.354 41.7774 819.271 41.8676 819.271H46.5346C46.6097 819.271 46.6924 819.354 46.6924 819.429C46.6924 819.467 46.6699 819.497 46.6473 819.534L43.273 827.552C43.2579 827.597 43.1978 827.649 43.1377 827.649C43.1226 827.649 43.1001 827.634 43.0776 827.634C42.9949 827.597 42.9723 827.514 42.9949 827.431Z" fill="#E71D36"/>
<path d="M48.3833 827.371L52.4341 823.716C52.9451 823.235 53.2232 822.588 53.2232 821.941C53.2232 821.377 53.0052 820.806 52.5994 820.347C52.1335 819.828 51.4721 819.557 50.8408 819.557C50.2697 819.557 49.691 819.775 49.2401 820.181L48.8192 820.565C48.7441 820.625 48.6614 820.625 48.6013 820.565C48.5412 820.505 48.5562 820.399 48.6163 820.347L49.0372 819.948C49.5632 819.482 50.202 819.256 50.8408 819.256C51.5698 819.256 52.2988 819.557 52.8324 820.129C53.2833 820.648 53.5238 821.302 53.5238 821.941C53.5238 822.671 53.2232 823.4 52.6295 823.927L48.8718 827.341H53.3885C53.4561 827.341 53.5313 827.401 53.5313 827.484C53.5313 827.567 53.4561 827.642 53.3885 827.642H48.5036C48.4585 827.642 48.4059 827.627 48.3833 827.582C48.3382 827.522 48.3382 827.424 48.3984 827.364L48.3833 827.371Z" fill="#E71D36"/>
</g>
<g opacity="0.89">
<path d="M104.673 864.396H98.5331C98.488 864.396 98.458 864.381 98.4129 864.351C98.3377 864.276 98.3227 864.171 98.3828 864.095L104.718 856.206L104.748 856.176C104.793 856.146 104.823 856.131 104.868 856.131C104.929 856.131 104.989 856.176 105.019 856.221C105.034 856.236 105.034 856.251 105.049 856.266C105.049 856.281 105.064 856.296 105.064 856.311V864.02H105.981C106.101 864.02 106.176 864.095 106.176 864.201C106.176 864.321 106.101 864.396 105.981 864.396H105.064V866.442C105.064 866.547 104.989 866.637 104.868 866.637C104.778 866.637 104.673 866.547 104.673 866.442V864.396ZM104.673 864.02V856.898L98.9239 864.02H104.673Z" fill="#5EB0EF"/>
<path d="M108.558 865.599C108.483 865.539 108.483 865.404 108.558 865.329C108.633 865.253 108.754 865.253 108.829 865.329C109.445 865.93 110.242 866.246 111.023 866.246C111.805 866.246 112.631 865.93 113.233 865.329C113.849 864.712 114.15 863.915 114.15 863.118C114.15 862.32 113.849 861.523 113.233 860.922C112.631 860.32 111.85 860.019 111.038 860.019C110.227 860.019 109.46 860.32 108.829 860.922C108.784 860.997 108.648 860.997 108.573 860.937C108.558 860.937 108.543 860.922 108.543 860.906C108.528 860.906 108.528 860.892 108.528 860.892C108.498 860.846 108.483 860.801 108.498 860.741L109.024 856.311C109.039 856.221 109.099 856.161 109.159 856.146C109.189 856.131 109.22 856.116 109.265 856.116H114.337C114.443 856.116 114.533 856.221 114.533 856.311C114.533 856.432 114.443 856.507 114.337 856.507H109.4L108.949 860.327C109.565 859.861 110.302 859.635 111.038 859.635C111.94 859.635 112.842 859.966 113.533 860.658C114.195 861.335 114.54 862.223 114.54 863.125C114.54 864.028 114.195 864.93 113.503 865.607C112.827 866.299 111.925 866.645 111.023 866.645C110.121 866.645 109.25 866.299 108.558 865.607V865.599Z" fill="#5EB0EF"/>
</g>
<g opacity="0.88">
<path d="M127.301 834.576C127.256 834.538 127.256 834.456 127.301 834.411C127.346 834.366 127.421 834.366 127.466 834.411C127.85 834.787 128.346 834.982 128.834 834.982C129.323 834.982 129.841 834.787 130.217 834.411C130.6 834.027 130.788 833.531 130.788 833.027C130.788 832.523 130.6 832.034 130.217 831.658C129.841 831.282 129.353 831.094 128.849 831.094C128.346 831.094 127.865 831.282 127.466 831.658C127.436 831.703 127.354 831.703 127.309 831.666C127.301 831.666 127.294 831.658 127.294 831.643C127.286 831.643 127.286 831.636 127.286 831.636C127.264 831.605 127.256 831.583 127.264 831.545L127.594 828.778C127.602 828.725 127.639 828.687 127.677 828.672C127.692 828.665 127.714 828.657 127.745 828.657H130.916C130.984 828.657 131.036 828.725 131.036 828.778C131.036 828.853 130.984 828.898 130.916 828.898H127.827L127.549 831.282C127.932 830.989 128.391 830.853 128.849 830.853C129.413 830.853 129.977 831.056 130.405 831.493C130.818 831.914 131.036 832.47 131.036 833.034C131.036 833.598 130.818 834.162 130.39 834.584C129.969 835.012 129.405 835.23 128.842 835.23C128.278 835.23 127.737 835.012 127.301 834.584V834.576Z" fill="#E71D36"/>
<path d="M136.132 831.478C136.553 831.899 136.771 832.463 136.771 833.019C136.771 833.576 136.553 834.147 136.132 834.576C135.704 834.997 135.14 835.216 134.584 835.216H134.576C134.02 835.216 133.457 835.012 133.036 834.576C132.991 834.531 132.991 834.456 133.036 834.411C133.081 834.366 133.156 834.366 133.201 834.411C133.584 834.787 134.073 834.975 134.569 834.975C135.065 834.975 135.576 834.787 135.952 834.403C136.335 834.027 136.523 833.531 136.523 833.019C136.523 832.508 136.335 832.034 135.959 831.651C135.576 831.275 135.087 831.087 134.591 831.087C134.546 831.087 134.509 831.064 134.479 831.026H134.471C134.426 830.974 134.426 830.883 134.471 830.838L136.365 828.883H133.464C133.396 828.883 133.344 828.823 133.344 828.763C133.344 828.702 133.404 828.642 133.464 828.642H136.643C136.711 828.642 136.763 828.695 136.763 828.763C136.763 828.778 136.763 828.8 136.756 828.815C136.748 828.838 136.741 828.853 136.741 828.853L134.794 830.846C135.283 830.891 135.764 831.102 136.125 831.478H136.132Z" fill="#E71D36"/>
</g>
<g opacity="0.77">
<path d="M85.2307 777.772C85.2307 777.027 85.3885 776.2 85.7643 775.545C86.1401 774.883 86.7713 774.395 87.6807 774.357C87.7333 774.357 87.7859 774.395 87.7859 774.462C87.7859 774.507 87.7483 774.56 87.6957 774.56C86.854 774.598 86.3054 775.034 85.9371 775.651C85.6516 776.132 85.5013 776.711 85.4487 777.275C85.7417 776.696 86.3355 776.312 87.0344 776.312C88.0189 776.312 88.823 777.11 88.8305 778.095C88.823 779.088 88.0189 779.885 87.0344 779.885C86.11 779.885 85.3585 779.2 85.2608 778.298C85.2608 778.298 85.2608 778.275 85.2608 778.268C85.2457 778.11 85.2382 777.945 85.2382 777.779L85.2307 777.772ZM87.0344 779.682C87.9137 779.682 88.6201 778.967 88.6351 778.087C88.6201 777.215 87.9212 776.5 87.0344 776.5C86.1476 776.5 85.4487 777.207 85.4487 778.087C85.4487 778.967 86.1551 779.682 87.0344 779.682Z" fill="#5EB0EF"/>
<path d="M90.296 779.336C90.2584 779.306 90.2584 779.231 90.296 779.193C90.3336 779.155 90.3937 779.155 90.4388 779.193C90.762 779.509 91.1753 779.674 91.5886 779.674C92.002 779.674 92.4303 779.509 92.746 779.193C93.0691 778.87 93.227 778.448 93.227 778.035C93.227 777.621 93.0691 777.2 92.746 776.884C92.4303 776.568 92.0245 776.41 91.5961 776.41C91.1678 776.41 90.7695 776.568 90.4388 776.884C90.4163 776.922 90.3411 776.922 90.3035 776.892C90.296 776.892 90.2885 776.884 90.2885 776.876C90.281 776.876 90.281 776.869 90.281 776.869C90.2659 776.846 90.2584 776.824 90.2659 776.786L90.544 774.462C90.5515 774.417 90.5816 774.38 90.6117 774.372C90.6267 774.365 90.6417 774.357 90.6643 774.357H93.3247C93.3773 774.357 93.4299 774.41 93.4299 774.462C93.4299 774.523 93.3848 774.568 93.3247 774.568H90.7319L90.4989 776.568C90.8221 776.32 91.2129 776.207 91.5961 776.207C92.0696 776.207 92.5431 776.38 92.9038 776.741C93.2495 777.095 93.4299 777.561 93.4299 778.035C93.4299 778.509 93.2495 778.982 92.8888 779.336C92.5356 779.697 92.0621 779.877 91.5886 779.877C91.1152 779.877 90.6567 779.697 90.296 779.336Z" fill="#5EB0EF"/>
</g>
<g opacity="0.54">
<path d="M11.4236 758.796L14.3095 757.082C14.4372 756.999 14.6026 757.022 14.6777 757.164C14.7378 757.285 14.7003 757.435 14.5575 757.518L11.6716 759.248C11.6265 759.27 11.5889 759.27 11.5514 759.27C11.4687 759.27 11.3635 759.248 11.3259 759.142C11.2658 759.037 11.2808 758.872 11.4311 758.796H11.4236ZM14.1892 757.292C14.1892 757.172 14.3095 757.022 14.4372 757.022C14.6026 757.022 14.7078 757.164 14.7078 757.292V771.183C14.7078 771.326 14.6026 771.454 14.4372 771.454C14.3095 771.454 14.1892 771.334 14.1892 771.183V757.292Z" fill="#5EB0EF"/>
<path d="M25.9209 763.211C26.8528 764.136 27.3263 765.377 27.3263 766.595C27.3263 767.814 26.8528 769.07 25.9209 770.025C24.974 770.95 23.734 771.431 22.5165 771.431H22.494C21.2765 771.431 20.044 770.98 19.1121 770.025C19.0069 769.92 19.0069 769.754 19.1121 769.656C19.2173 769.551 19.3827 769.551 19.4804 769.656C20.3221 770.484 21.3968 770.897 22.494 770.897C23.5912 770.897 24.7035 770.484 25.5226 769.641C26.3719 768.814 26.7777 767.724 26.7777 766.611C26.7777 765.497 26.3643 764.445 25.5377 763.595C24.6884 762.767 23.6213 762.354 22.524 762.354C22.4188 762.354 22.3362 762.309 22.276 762.233C22.276 762.211 22.2535 762.211 22.2535 762.211C22.1483 762.106 22.1483 761.925 22.2535 761.82L26.417 757.525H20.044C19.9012 757.525 19.7735 757.405 19.7735 757.255C19.7735 757.104 19.8937 756.984 20.044 756.984H27.0332C27.176 756.984 27.3038 757.112 27.3038 757.255C27.3038 757.292 27.3038 757.337 27.2812 757.375C27.2587 757.42 27.2436 757.458 27.2211 757.48L22.975 761.835C24.0496 761.94 25.1018 762.391 25.9059 763.219L25.9209 763.211Z" fill="#5EB0EF"/>
</g>
<g opacity="0.7">
<path d="M38.1625 738.814C38.0949 738.761 38.0949 738.633 38.1625 738.566C38.2302 738.498 38.3429 738.498 38.4105 738.566C38.9742 739.115 39.7031 739.408 40.4171 739.408C41.131 739.408 41.8901 739.122 42.4387 738.566C43.0023 738.001 43.2729 737.272 43.2729 736.542C43.2729 735.813 43.0023 735.083 42.4387 734.534C41.8901 733.985 41.1761 733.707 40.4321 733.707C39.6881 733.707 38.9892 733.985 38.4105 734.534C38.3654 734.602 38.2452 734.602 38.1775 734.549C38.1625 734.549 38.1475 734.534 38.1475 734.519C38.1325 734.519 38.1325 734.504 38.1325 734.504C38.1024 734.459 38.0874 734.422 38.1024 734.369L38.5834 730.315C38.5984 730.232 38.651 730.18 38.7036 730.165C38.7337 730.15 38.7562 730.135 38.8013 730.135H43.4458C43.5434 730.135 43.6261 730.232 43.6261 730.315C43.6261 730.428 43.5434 730.496 43.4458 730.496H38.9216L38.5082 733.993C39.0719 733.564 39.7482 733.361 40.4171 733.361C41.2438 733.361 42.0629 733.662 42.7017 734.294C43.303 734.91 43.6186 735.723 43.6186 736.55C43.6186 737.377 43.303 738.197 42.6717 738.821C42.0554 739.453 41.2287 739.769 40.4021 739.769C39.5754 739.769 38.7788 739.453 38.1475 738.821L38.1625 738.814Z" fill="#E71D36"/>
<path d="M46.4594 736.091C46.4594 734.798 46.7374 733.354 47.3913 732.21C48.0526 731.052 49.1498 730.202 50.728 730.135C50.8257 730.135 50.9084 730.202 50.9084 730.315C50.9084 730.398 50.8408 730.481 50.7581 730.481C49.2851 730.548 48.3382 731.308 47.6919 732.376C47.1959 733.211 46.9328 734.219 46.8426 735.211C47.3537 734.203 48.3983 733.534 49.6007 733.534C51.3217 733.534 52.7196 734.925 52.7346 736.64C52.7196 738.37 51.3217 739.761 49.6007 739.761C47.9925 739.761 46.6698 738.566 46.512 736.994C46.4969 736.979 46.4969 736.949 46.4969 736.941C46.4669 736.663 46.4518 736.377 46.4518 736.091H46.4594ZM49.6083 739.423C51.1489 739.423 52.3739 738.182 52.3964 736.648C52.3664 735.121 51.1489 733.88 49.6083 733.88C48.0676 733.88 46.8502 735.121 46.8502 736.648C46.8502 738.174 48.0902 739.423 49.6083 739.423Z" fill="#E71D36"/>
</g>
<g opacity="0.5">
<path d="M67.9233 612.547L69.8246 611.419C69.9073 611.366 70.0125 611.374 70.0651 611.471C70.1027 611.554 70.0801 611.644 69.9824 611.705L68.0811 612.848C68.0811 612.848 68.0285 612.863 67.9984 612.863C67.9458 612.863 67.8782 612.848 67.8481 612.78C67.8105 612.712 67.818 612.6 67.9157 612.547H67.9233ZM69.742 611.554C69.742 611.471 69.8246 611.374 69.9073 611.374C70.02 611.374 70.0801 611.471 70.0801 611.554V620.699C70.0801 620.797 70.0125 620.88 69.9073 620.88C69.8246 620.88 69.742 620.797 69.742 620.699V611.554Z" fill="#5EB0EF"/>
<path d="M72.8382 619.932C72.7706 619.88 72.7706 619.752 72.8382 619.692C72.9059 619.624 73.0111 619.624 73.0787 619.692C73.6348 620.233 74.3563 620.519 75.0627 620.519C75.7692 620.519 76.5132 620.233 77.0543 619.692C77.6104 619.135 77.881 618.413 77.881 617.691C77.881 616.969 77.6104 616.255 77.0543 615.706C76.5132 615.164 75.8068 614.893 75.0778 614.893C74.3488 614.893 73.6499 615.164 73.0787 615.706C73.0411 615.773 72.9134 615.773 72.8457 615.721C72.8307 615.721 72.8232 615.706 72.8232 615.691C72.8082 615.691 72.8082 615.676 72.8082 615.676C72.7781 615.63 72.7706 615.593 72.7781 615.54L73.2516 611.532C73.2666 611.449 73.3192 611.396 73.3718 611.381C73.4019 611.366 73.4244 611.351 73.4695 611.351H78.0613C78.159 611.351 78.2417 611.449 78.2417 611.532C78.2417 611.644 78.159 611.705 78.0613 611.705H73.5973L73.1914 615.157C73.7476 614.735 74.4089 614.532 75.0778 614.532C75.8894 614.532 76.7086 614.833 77.3324 615.457C77.9261 616.067 78.2417 616.871 78.2417 617.684C78.2417 618.496 77.9261 619.316 77.3023 619.925C76.6936 620.549 75.8744 620.865 75.0627 620.865C74.2511 620.865 73.462 620.549 72.8382 619.925V619.932Z" fill="#5EB0EF"/>
</g>
<g opacity="0.61">
<path d="M65.2855 655.295L71.2752 641.013H63.2338C63.0835 641.013 62.9482 640.885 62.9482 640.727C62.9482 640.599 63.076 640.442 63.2338 640.442H71.7336C71.8613 640.442 72.0192 640.592 72.0192 640.727C72.0192 640.795 71.9741 640.855 71.929 640.923L65.7815 655.506C65.759 655.596 65.6537 655.679 65.541 655.679C65.5185 655.679 65.4734 655.656 65.4283 655.656C65.278 655.588 65.2329 655.438 65.278 655.288L65.2855 655.295Z" fill="#E71D36"/>
<path d="M80.4213 640.426C82.9915 640.426 85.1033 642.412 85.3212 644.962C85.3889 645.375 85.4114 645.811 85.4114 646.248C85.4114 648.301 84.9755 650.565 83.9309 652.4C82.8412 654.235 81.1427 655.581 78.6401 655.694C78.4673 655.694 78.3546 655.588 78.3546 655.408C78.332 655.28 78.4673 655.145 78.595 655.145C80.9248 655.032 82.4278 653.814 83.4349 652.136C84.1789 650.828 84.6298 649.218 84.7876 647.669C83.9835 649.241 82.3226 650.309 80.4288 650.309C77.7082 650.309 75.4612 648.105 75.4612 645.383C75.4612 642.66 77.7082 640.434 80.4288 640.434L80.4213 640.426ZM80.4213 649.76C82.8412 649.76 84.8027 647.797 84.8027 645.375C84.8027 645.262 84.8027 645.157 84.7801 645.052V644.939C84.5622 642.69 82.6909 640.968 80.4213 640.968C77.9788 640.968 76.0023 642.931 76.0023 645.368C76.0023 647.804 77.9863 649.752 80.4213 649.752V649.76Z" fill="#E71D36"/>
</g>
<g opacity="0.83">
<path d="M122.093 612.126C122.093 611.329 122.266 610.434 122.671 609.734C123.077 609.02 123.754 608.493 124.731 608.448C124.791 608.448 124.843 608.493 124.843 608.561C124.843 608.614 124.798 608.659 124.753 608.659C123.844 608.704 123.258 609.17 122.859 609.832C122.551 610.351 122.393 610.968 122.333 611.584C122.649 610.968 123.295 610.547 124.039 610.547C125.099 610.547 125.963 611.404 125.978 612.464C125.971 613.532 125.106 614.39 124.039 614.39C123.047 614.39 122.228 613.653 122.13 612.682V612.645C122.108 612.479 122.093 612.299 122.093 612.118V612.126ZM124.039 614.179C124.994 614.179 125.745 613.412 125.76 612.464C125.745 611.524 124.986 610.757 124.039 610.757C123.092 610.757 122.333 611.524 122.333 612.464C122.333 613.404 123.1 614.179 124.039 614.179Z" fill="#5EB0EF"/>
<path d="M127.541 612.126C127.541 611.329 127.714 610.434 128.12 609.734C128.526 609.02 129.202 608.493 130.179 608.448C130.239 608.448 130.292 608.493 130.292 608.561C130.292 608.614 130.247 608.659 130.201 608.659C129.292 608.704 128.706 609.17 128.308 609.832C127.999 610.351 127.842 610.968 127.781 611.584C128.097 610.968 128.743 610.547 129.487 610.547C130.547 610.547 131.411 611.404 131.419 612.464C131.411 613.532 130.547 614.39 129.487 614.39C128.495 614.39 127.676 613.653 127.579 612.682C127.571 612.675 127.571 612.66 127.571 612.66C127.556 612.479 127.549 612.299 127.549 612.126H127.541ZM129.487 614.179C130.434 614.179 131.193 613.412 131.208 612.464C131.193 611.524 130.434 610.757 129.487 610.757C128.541 610.757 127.781 611.524 127.781 612.464C127.781 613.404 128.548 614.179 129.487 614.179Z" fill="#5EB0EF"/>
</g>
<g opacity="0.79">
<path d="M128.894 714.928L129.744 712.905H128.609C128.609 712.905 128.571 712.89 128.571 712.867C128.571 712.852 128.594 712.829 128.609 712.829H129.811C129.811 712.829 129.849 712.852 129.849 712.867C129.849 712.875 129.849 712.89 129.834 712.897L128.962 714.958C128.962 714.958 128.947 714.98 128.932 714.98H128.917C128.917 714.98 128.887 714.943 128.894 714.928Z" fill="#5EB0EF"/>
<path d="M131.36 714.529H130.097C130.097 714.529 130.082 714.529 130.075 714.522C130.06 714.507 130.052 714.484 130.075 714.469L131.375 712.852C131.375 712.852 131.39 712.837 131.397 712.837C131.412 712.837 131.42 712.845 131.427 712.852V712.867V714.446H131.615C131.615 714.446 131.653 714.461 131.653 714.484C131.653 714.507 131.638 714.522 131.615 714.522H131.427V714.943C131.427 714.943 131.412 714.98 131.39 714.98C131.375 714.98 131.352 714.965 131.352 714.943V714.522L131.36 714.529ZM131.36 714.454V712.995L130.18 714.454H131.36Z" fill="#5EB0EF"/>
</g>
<g opacity="0.93">
<path d="M158.61 630.837L163.442 626.475C164.059 625.904 164.389 625.129 164.389 624.355C164.389 623.685 164.134 622.993 163.645 622.452C163.089 621.835 162.3 621.512 161.541 621.512C160.857 621.512 160.166 621.767 159.625 622.256L159.121 622.715C159.039 622.79 158.933 622.79 158.866 622.715C158.791 622.64 158.813 622.512 158.881 622.459L159.384 621.986C160.016 621.429 160.775 621.158 161.541 621.158C162.413 621.158 163.285 621.519 163.916 622.204C164.457 622.82 164.743 623.602 164.743 624.362C164.743 625.234 164.382 626.107 163.668 626.739L159.181 630.815H164.57C164.652 630.815 164.743 630.89 164.743 630.988C164.743 631.086 164.66 631.176 164.57 631.176H158.738C158.678 631.176 158.625 631.161 158.595 631.101C158.535 631.026 158.535 630.913 158.61 630.845V630.837Z" fill="#E71D36"/>
</g>
<g opacity="0.6">
<path d="M122.334 558.871H115.555C115.502 558.871 115.472 558.856 115.42 558.818C115.337 558.736 115.322 558.623 115.389 558.54L122.386 549.831L122.424 549.801C122.476 549.771 122.506 549.748 122.559 549.748C122.627 549.748 122.694 549.801 122.724 549.846C122.739 549.861 122.739 549.876 122.762 549.899C122.762 549.914 122.777 549.929 122.777 549.951V558.465H123.784C123.919 558.465 124.002 558.548 124.002 558.66C124.002 558.796 123.919 558.878 123.784 558.878H122.777V561.135C122.777 561.248 122.694 561.353 122.559 561.353C122.461 561.353 122.341 561.255 122.341 561.135V558.878L122.334 558.871ZM122.334 558.457V550.591L115.983 558.457H122.334Z" fill="#5EB0EF"/>
<path d="M132.276 554.719C133.02 555.464 133.404 556.464 133.404 557.442C133.404 558.42 133.02 559.435 132.276 560.195C131.51 560.939 130.518 561.323 129.541 561.323H129.526C128.549 561.323 127.557 560.954 126.805 560.195C126.722 560.112 126.722 559.977 126.805 559.894C126.888 559.811 127.023 559.811 127.106 559.894C127.782 560.556 128.646 560.887 129.526 560.887C130.405 560.887 131.299 560.556 131.961 559.871C132.637 559.209 132.975 558.329 132.975 557.434C132.975 556.54 132.645 555.69 131.983 555.013C131.299 554.351 130.443 554.02 129.563 554.02C129.481 554.02 129.413 553.99 129.368 553.922C129.368 553.907 129.353 553.907 129.353 553.907C129.27 553.825 129.27 553.674 129.353 553.591L132.705 550.139H127.579C127.467 550.139 127.361 550.042 127.361 549.921C127.361 549.801 127.459 549.703 127.579 549.703H133.201C133.321 549.703 133.419 549.801 133.419 549.921C133.419 549.959 133.419 549.989 133.404 550.019C133.389 550.049 133.374 550.087 133.351 550.102L129.932 553.599C130.796 553.682 131.637 554.05 132.284 554.712L132.276 554.719Z" fill="#5EB0EF"/>
</g>
<g opacity="0.65">
<path d="M76.0467 607.35L78.6094 601.243H75.1749C75.1073 601.243 75.0547 601.191 75.0547 601.123C75.0547 601.07 75.1148 601.003 75.1749 601.003H78.8048C78.8649 601.003 78.925 601.07 78.925 601.123C78.925 601.153 78.9025 601.176 78.8875 601.206L76.2646 607.44C76.2571 607.478 76.212 607.516 76.1594 607.516C76.1519 607.516 76.1294 607.508 76.1143 607.508C76.0467 607.478 76.0317 607.418 76.0467 607.35Z" fill="#E71D36"/>
<path d="M82.4122 600.995C83.1186 600.995 83.6973 601.574 83.6973 602.289C83.6973 602.8 83.3891 603.251 82.9608 603.454C83.8326 603.695 84.4864 604.5 84.4864 605.447C84.4864 606.591 83.5545 607.516 82.4122 607.516C81.2698 607.516 80.338 606.591 80.338 605.447C80.338 604.492 80.9918 603.695 81.8636 603.454C81.4201 603.251 81.1195 602.8 81.1195 602.289C81.1195 601.567 81.6982 600.995 82.4122 600.995ZM82.4122 607.282C83.4342 607.282 84.2534 606.463 84.2534 605.447C84.2534 604.432 83.4342 603.605 82.4122 603.605C81.3901 603.605 80.5709 604.432 80.5709 605.447C80.5709 606.463 81.3976 607.282 82.4122 607.282ZM82.4122 603.342C82.9908 603.342 83.4643 602.868 83.4643 602.289C83.4643 601.71 82.9908 601.236 82.4122 601.228C81.826 601.236 81.3525 601.702 81.3525 602.289C81.3525 602.875 81.826 603.342 82.4122 603.342Z" fill="#E71D36"/>
</g>
<g opacity="0.55">
<path d="M165.269 628.017H163.683C163.683 628.017 163.66 628.017 163.653 628.002C163.63 627.98 163.63 627.957 163.645 627.935L165.284 625.896H165.291C165.291 625.896 165.314 625.881 165.321 625.881C165.336 625.881 165.351 625.889 165.359 625.904V625.911L165.366 625.926V627.912H165.599C165.599 627.912 165.652 627.935 165.652 627.957C165.652 627.987 165.629 628.01 165.599 628.01H165.366V628.536C165.366 628.536 165.344 628.589 165.314 628.589C165.291 628.589 165.261 628.566 165.261 628.536V628.01L165.269 628.017ZM165.269 627.919V626.084L163.788 627.919H165.269Z" fill="#5EB0EF"/>
<path d="M166.095 626.22L166.637 625.896C166.637 625.896 166.689 625.889 166.704 625.911C166.719 625.934 166.704 625.964 166.682 625.979L166.141 626.303H166.126C166.103 626.303 166.08 626.302 166.073 626.28C166.058 626.257 166.065 626.227 166.095 626.212V626.22ZM166.614 625.934C166.614 625.934 166.637 625.881 166.659 625.881C166.689 625.881 166.712 625.904 166.712 625.934V628.544C166.712 628.544 166.689 628.596 166.659 628.596C166.637 628.596 166.614 628.574 166.614 628.544V625.934Z" fill="#5EB0EF"/>
</g>
<g opacity="0.73">
<path d="M52.6896 530.796C53.629 530.796 54.3955 531.563 54.3955 532.518C54.3955 533.202 53.9897 533.797 53.4185 534.067C54.5834 534.391 55.4477 535.451 55.4477 536.715C55.4477 538.241 54.2076 539.467 52.6896 539.467C51.1715 539.467 49.9315 538.241 49.9315 536.715C49.9315 535.451 50.7957 534.383 51.9606 534.067C51.3669 533.797 50.9686 533.202 50.9686 532.518C50.9686 531.563 51.7351 530.796 52.6896 530.796ZM52.6896 539.159C54.0498 539.159 55.1395 538.068 55.1395 536.715C55.1395 535.361 54.0498 534.263 52.6896 534.263C51.3293 534.263 50.2396 535.368 50.2396 536.715C50.2396 538.061 51.3443 539.159 52.6896 539.159ZM52.6896 533.917C53.4561 533.917 54.0874 533.285 54.0874 532.518C54.0874 531.751 53.4561 531.119 52.6896 531.104C51.908 531.119 51.2767 531.736 51.2767 532.518C51.2767 533.3 51.908 533.917 52.6896 533.917Z" fill="#E71D36"/>
<path d="M59.0403 539.249L62.4447 531.134H57.8754C57.7852 531.134 57.7176 531.059 57.7176 530.976C57.7176 530.901 57.7927 530.818 57.8754 530.818H62.7002C62.7754 530.818 62.858 530.901 62.858 530.976C62.858 531.014 62.8355 531.051 62.8054 531.089L59.3108 539.377C59.2958 539.43 59.2357 539.475 59.1755 539.475C59.1605 539.475 59.138 539.46 59.1154 539.46C59.0328 539.422 59.0027 539.339 59.0328 539.249H59.0403Z" fill="#E71D36"/>
</g>
<g opacity="0.69">
<path d="M79.0155 417.202L80.5562 415.81C80.7516 415.63 80.8568 415.382 80.8568 415.133C80.8568 414.915 80.7741 414.697 80.6163 414.524C80.4434 414.329 80.1879 414.223 79.9474 414.223C79.7295 414.223 79.5115 414.306 79.3387 414.457L79.1809 414.599C79.1809 414.599 79.1208 414.622 79.0982 414.599C79.0757 414.577 79.0832 414.539 79.0982 414.517L79.256 414.366C79.4589 414.193 79.6994 414.103 79.9474 414.103C80.2255 414.103 80.5036 414.216 80.7065 414.434C80.8793 414.629 80.9695 414.878 80.9695 415.126C80.9695 415.404 80.8568 415.682 80.6313 415.885L79.2034 417.187H80.9244C80.9244 417.187 80.977 417.209 80.977 417.239C80.977 417.269 80.947 417.299 80.9244 417.299H79.0681C79.0681 417.299 79.0306 417.299 79.0231 417.277C79.008 417.254 79.008 417.217 79.0231 417.194L79.0155 417.202Z" fill="#E71D36"/>
<path d="M83.3368 415.487C83.5397 415.69 83.6449 415.968 83.6449 416.239C83.6449 416.51 83.5397 416.788 83.3368 416.999C83.1264 417.202 82.8558 417.307 82.5853 417.307C82.3147 417.307 82.0442 417.209 81.8413 416.999C81.8187 416.976 81.8187 416.938 81.8413 416.916C81.8638 416.893 81.9014 416.893 81.9239 416.916C82.1118 417.096 82.3448 417.187 82.5853 417.187C82.8257 417.187 83.0738 417.096 83.2541 416.908C83.442 416.728 83.5322 416.48 83.5322 416.239C83.5322 415.998 83.442 415.758 83.2616 415.57C83.0738 415.389 82.8408 415.299 82.6003 415.299C82.5777 415.299 82.5627 415.291 82.5477 415.269C82.5251 415.246 82.5251 415.209 82.5477 415.186L83.4645 414.238H82.0592C82.0592 414.238 81.9991 414.208 81.9991 414.178C81.9991 414.148 82.0291 414.118 82.0592 414.118H83.6073C83.6073 414.118 83.6675 414.148 83.6675 414.178C83.6675 414.186 83.6675 414.193 83.6675 414.208C83.6675 414.216 83.6599 414.223 83.6524 414.231L82.713 415.194C82.9535 415.216 83.1865 415.314 83.3593 415.502L83.3368 415.487Z" fill="#E71D36"/>
</g>
<g opacity="0.8">
<path d="M124.355 477.21C124.355 477.21 124.332 477.15 124.355 477.12C124.377 477.097 124.415 477.097 124.445 477.12C124.648 477.315 124.903 477.421 125.159 477.421C125.414 477.421 125.677 477.315 125.873 477.12C126.076 476.917 126.173 476.661 126.173 476.405C126.173 476.15 126.076 475.886 125.873 475.691C125.677 475.495 125.422 475.398 125.159 475.398C124.896 475.398 124.648 475.495 124.445 475.691C124.43 475.713 124.385 475.713 124.362 475.691C124.362 475.691 124.355 475.691 124.355 475.683C124.355 475.683 124.34 475.653 124.347 475.631L124.52 474.194C124.52 474.194 124.543 474.142 124.565 474.142C124.573 474.142 124.588 474.134 124.603 474.134H126.249C126.249 474.134 126.309 474.164 126.309 474.194C126.309 474.232 126.279 474.254 126.249 474.254H124.648L124.498 475.495C124.7 475.345 124.933 475.27 125.174 475.27C125.467 475.27 125.76 475.375 125.986 475.601C126.196 475.819 126.309 476.104 126.309 476.398C126.309 476.691 126.196 476.984 125.971 477.203C125.753 477.428 125.459 477.541 125.166 477.541C124.873 477.541 124.588 477.428 124.37 477.203L124.355 477.21Z" fill="#5EB0EF"/>
<path d="M129.277 476.827H127.285C127.285 476.827 127.263 476.827 127.248 476.812C127.225 476.789 127.218 476.751 127.24 476.729L129.299 474.172H129.307C129.307 474.172 129.329 474.149 129.344 474.149C129.367 474.149 129.382 474.164 129.397 474.179C129.397 474.179 129.397 474.187 129.405 474.194C129.405 474.194 129.405 474.202 129.405 474.209V476.706H129.705C129.743 476.706 129.765 476.729 129.765 476.766C129.765 476.804 129.743 476.827 129.705 476.827H129.405V477.488C129.405 477.488 129.382 477.549 129.344 477.549C129.314 477.549 129.284 477.518 129.284 477.488V476.827H129.277ZM129.277 476.706V474.397L127.413 476.706H129.277Z" fill="#5EB0EF"/>
</g>
<g opacity="0.93">
<path d="M116.261 455.009L118.064 453.377C118.297 453.166 118.418 452.873 118.418 452.587C118.418 452.339 118.32 452.076 118.14 451.872C117.929 451.639 117.636 451.519 117.358 451.519C117.102 451.519 116.847 451.617 116.644 451.797L116.456 451.97C116.456 451.97 116.389 452 116.358 451.97C116.328 451.94 116.336 451.895 116.358 451.872L116.546 451.699C116.779 451.489 117.065 451.391 117.35 451.391C117.674 451.391 118.004 451.527 118.237 451.782C118.44 452.015 118.545 452.309 118.545 452.587C118.545 452.91 118.41 453.241 118.147 453.474L116.471 454.994H118.485C118.485 454.994 118.545 455.024 118.545 455.054C118.545 455.084 118.515 455.121 118.485 455.121H116.306C116.306 455.121 116.261 455.121 116.253 455.091C116.231 455.069 116.231 455.024 116.253 454.994L116.261 455.009Z" fill="#E71D36"/>
<path d="M119.35 451.857L120.101 451.414C120.101 451.414 120.176 451.399 120.199 451.436C120.214 451.466 120.199 451.504 120.169 451.526L119.425 451.978H119.402C119.372 451.978 119.342 451.978 119.335 451.948C119.319 451.925 119.32 451.88 119.357 451.857H119.35ZM120.064 451.466C120.064 451.466 120.094 451.399 120.124 451.399C120.169 451.399 120.191 451.436 120.191 451.466V455.061C120.191 455.099 120.161 455.129 120.124 455.129C120.094 455.129 120.064 455.099 120.064 455.061V451.466Z" fill="#E71D36"/>
</g>
<g opacity="0.94">
<path d="M215.982 484.896C216.26 485.175 216.395 485.543 216.395 485.904C216.395 486.265 216.252 486.641 215.982 486.919C215.704 487.198 215.335 487.333 214.967 487.333C214.606 487.333 214.238 487.198 213.96 486.919C213.93 486.889 213.93 486.837 213.96 486.807C213.99 486.777 214.043 486.777 214.073 486.807C214.321 487.055 214.644 487.175 214.967 487.175C215.29 487.175 215.621 487.055 215.869 486.799C216.124 486.551 216.245 486.228 216.245 485.897C216.245 485.566 216.124 485.25 215.876 485.002C215.628 484.754 215.305 484.633 214.982 484.633C214.952 484.633 214.93 484.618 214.907 484.596C214.907 484.596 214.877 484.505 214.907 484.475L216.147 483.197H214.253C214.208 483.197 214.17 483.159 214.17 483.114C214.17 483.069 214.208 483.031 214.253 483.031H216.335C216.38 483.031 216.418 483.069 216.418 483.114C216.418 483.129 216.418 483.137 216.418 483.152C216.418 483.167 216.403 483.174 216.403 483.182L215.14 484.475C215.456 484.505 215.771 484.641 216.012 484.889L215.982 484.896Z" fill="#5EB0EF"/>
<path d="M218.935 483.046C219.401 483.046 219.777 483.43 219.777 483.896C219.777 484.235 219.574 484.528 219.296 484.663C219.875 484.821 220.303 485.348 220.303 485.979C220.303 486.731 219.687 487.341 218.935 487.341C218.184 487.341 217.567 486.731 217.567 485.979C217.567 485.355 217.996 484.829 218.574 484.663C218.281 484.528 218.086 484.235 218.086 483.896C218.086 483.422 218.469 483.046 218.935 483.046ZM218.935 487.19C219.612 487.19 220.153 486.649 220.153 485.979C220.153 485.31 219.612 484.769 218.935 484.769C218.259 484.769 217.725 485.318 217.725 485.979C217.725 486.641 218.274 487.19 218.935 487.19ZM218.935 484.596C219.318 484.596 219.627 484.287 219.627 483.904C219.627 483.52 219.318 483.212 218.935 483.204C218.552 483.204 218.236 483.52 218.236 483.904C218.236 484.287 218.552 484.596 218.935 484.596Z" fill="#5EB0EF"/>
</g>
<g opacity="0.81">
<path d="M103.628 496.012H100.509C100.509 496.012 100.471 496.004 100.449 495.989C100.411 495.952 100.404 495.899 100.434 495.862L103.65 491.861L103.665 491.845C103.665 491.845 103.703 491.823 103.725 491.823C103.755 491.823 103.785 491.845 103.8 491.868C103.808 491.876 103.808 491.883 103.815 491.891V495.824H104.289C104.349 495.824 104.387 495.862 104.387 495.914C104.387 495.974 104.349 496.012 104.289 496.012H103.823V497.05C103.823 497.103 103.785 497.148 103.725 497.148C103.68 497.148 103.628 497.103 103.628 497.05V496.012ZM103.628 495.824V492.206L100.712 495.824H103.628Z" fill="#E71D36"/>
<path d="M107.265 491.808C108.167 491.808 108.904 492.5 108.979 493.395C109.001 493.538 109.009 493.696 109.009 493.846C109.009 494.56 108.859 495.358 108.49 495.997C108.107 496.636 107.513 497.11 106.642 497.148C106.581 497.148 106.544 497.11 106.544 497.05C106.544 497.005 106.581 496.96 106.627 496.96C107.446 496.922 107.964 496.493 108.317 495.907C108.573 495.448 108.738 494.884 108.791 494.342C108.505 494.891 107.927 495.267 107.265 495.267C106.311 495.267 105.529 494.5 105.529 493.545C105.529 492.59 106.311 491.815 107.265 491.815V491.808ZM107.265 495.072C108.115 495.072 108.798 494.387 108.798 493.538C108.798 493.5 108.798 493.462 108.791 493.425V493.395C108.716 492.605 108.062 492.003 107.265 492.003C106.409 492.003 105.717 492.688 105.717 493.545C105.717 494.403 106.409 495.079 107.265 495.079V495.072Z" fill="#E71D36"/>
</g>
<g opacity="0.92">
<path d="M274.878 413.231C274.72 413.11 274.72 412.824 274.878 412.674C275.036 412.516 275.284 412.516 275.434 412.674C276.704 413.915 278.35 414.562 279.958 414.562C281.567 414.562 283.273 413.907 284.513 412.674C285.783 411.403 286.407 409.756 286.407 408.116C286.407 406.477 285.783 404.83 284.513 403.589C283.273 402.348 281.664 401.731 279.989 401.731C278.313 401.731 276.734 402.348 275.434 403.589C275.344 403.747 275.066 403.747 274.908 403.619C274.878 403.619 274.848 403.589 274.848 403.559C274.818 403.559 274.818 403.529 274.818 403.529C274.758 403.438 274.728 403.341 274.758 403.22L275.84 394.075C275.87 393.887 275.998 393.767 276.118 393.737C276.178 393.707 276.238 393.677 276.336 393.677H286.812C287.03 393.677 287.218 393.895 287.218 394.083C287.218 394.331 287.03 394.489 286.812 394.489H276.622L275.69 402.363C276.96 401.4 278.478 400.934 279.996 400.934C281.852 400.934 283.716 401.618 285.136 403.04C286.497 404.431 287.211 406.266 287.211 408.124C287.211 409.982 286.497 411.847 285.076 413.238C283.678 414.667 281.822 415.374 279.966 415.374C278.11 415.374 276.314 414.66 274.886 413.238L274.878 413.231Z" fill="#E71D36"/>
</g>
<g opacity="0.79">
<path d="M219.897 422.489C220.821 422.489 221.58 423.203 221.655 424.121C221.678 424.271 221.685 424.429 221.685 424.579C221.685 425.317 221.528 426.129 221.152 426.783C220.761 427.437 220.152 427.926 219.25 427.964C219.19 427.964 219.145 427.926 219.145 427.866C219.138 427.821 219.183 427.776 219.228 427.776C220.062 427.738 220.603 427.302 220.964 426.693C221.227 426.227 221.392 425.647 221.452 425.091C221.159 425.655 220.566 426.039 219.889 426.039C218.912 426.039 218.108 425.249 218.108 424.271C218.108 423.293 218.912 422.496 219.889 422.496L219.897 422.489ZM219.897 425.836C220.769 425.836 221.467 425.129 221.467 424.264C221.467 424.226 221.467 424.188 221.46 424.143V424.113C221.385 423.301 220.708 422.684 219.897 422.684C219.025 422.684 218.311 423.391 218.311 424.264C218.311 425.136 219.025 425.836 219.897 425.836Z" fill="#E71D36"/>
</g>
<g opacity="0.64">
<path d="M257.045 481.489C257.045 480.752 257.203 479.933 257.578 479.286C257.954 478.632 258.578 478.143 259.472 478.105C259.525 478.105 259.577 478.143 259.577 478.21C259.577 478.255 259.54 478.301 259.495 478.301C258.661 478.338 258.119 478.767 257.751 479.376C257.466 479.85 257.323 480.421 257.27 480.986C257.563 480.414 258.157 480.03 258.841 480.03C259.818 480.03 260.615 480.82 260.622 481.798C260.615 482.783 259.818 483.573 258.841 483.573C257.924 483.573 257.18 482.896 257.082 482.001C257.075 481.993 257.075 481.978 257.075 481.978C257.06 481.813 257.052 481.647 257.052 481.489H257.045ZM258.833 483.377C259.705 483.377 260.404 482.678 260.419 481.798C260.404 480.933 259.705 480.226 258.833 480.226C257.962 480.226 257.263 480.925 257.263 481.798C257.263 482.67 257.969 483.377 258.833 483.377Z" fill="#E71D36"/>
<path d="M262.073 483.039C262.035 483.009 262.035 482.933 262.073 482.896C262.11 482.858 262.171 482.858 262.216 482.896C262.539 483.204 262.952 483.37 263.358 483.37C263.764 483.37 264.192 483.204 264.508 482.896C264.831 482.572 264.981 482.159 264.981 481.745C264.981 481.331 264.823 480.918 264.508 480.602C264.192 480.286 263.786 480.136 263.366 480.136C262.945 480.136 262.546 480.294 262.216 480.602C262.193 480.64 262.126 480.64 262.08 480.609L262.065 480.594L262.058 480.587C262.043 480.564 262.035 480.542 262.043 480.512L262.313 478.203C262.321 478.158 262.351 478.12 262.389 478.113C262.404 478.105 262.419 478.098 262.441 478.098H265.079C265.132 478.098 265.177 478.15 265.177 478.203C265.177 478.263 265.132 478.301 265.079 478.301H262.509L262.276 480.286C262.599 480.045 262.982 479.925 263.366 479.925C263.831 479.925 264.305 480.098 264.658 480.459C265.004 480.813 265.184 481.271 265.184 481.745C265.184 482.219 265.004 482.685 264.643 483.039C264.29 483.4 263.824 483.58 263.358 483.58C262.892 483.58 262.434 483.4 262.073 483.039Z" fill="#E71D36"/>
</g>
<g opacity="0.75">
<path d="M257.03 367.504L258.766 366.474C258.841 366.421 258.939 366.436 258.991 366.526C259.029 366.602 259.006 366.684 258.916 366.737L257.18 367.782C257.18 367.782 257.127 367.797 257.105 367.797C257.052 367.797 256.992 367.782 256.97 367.722C256.932 367.662 256.947 367.557 257.03 367.512V367.504ZM258.691 366.594C258.691 366.519 258.766 366.436 258.841 366.436C258.939 366.436 259.006 366.526 259.006 366.594V374.957C259.006 375.047 258.946 375.115 258.841 375.115C258.766 375.115 258.691 375.04 258.691 374.957V366.594Z" fill="#5EB0EF"/>
<path d="M264.169 366.429C265.109 366.429 265.883 367.196 265.883 368.158C265.883 368.843 265.469 369.437 264.906 369.715C266.071 370.039 266.942 371.107 266.942 372.378C266.942 373.904 265.702 375.138 264.177 375.138C262.651 375.138 261.411 373.912 261.411 372.378C261.411 371.114 262.283 370.039 263.448 369.715C262.854 369.444 262.456 368.843 262.456 368.158C262.456 367.203 263.222 366.429 264.184 366.429H264.169ZM264.169 374.814C265.537 374.814 266.627 373.724 266.627 372.37C266.627 371.016 265.537 369.911 264.169 369.911C262.801 369.911 261.712 371.016 261.712 372.37C261.712 373.724 262.817 374.814 264.169 374.814ZM264.169 369.557C264.936 369.557 265.575 368.926 265.575 368.151C265.575 367.376 264.943 366.744 264.169 366.737C263.388 366.752 262.756 367.369 262.756 368.151C262.756 368.933 263.388 369.557 264.169 369.557Z" fill="#5EB0EF"/>
</g>
<g opacity="0.7">
<path d="M184.041 395.429C184.041 395.429 184.011 395.354 184.041 395.331C184.072 395.301 184.117 395.301 184.139 395.331C184.372 395.557 184.665 395.67 184.958 395.67C185.251 395.67 185.56 395.549 185.785 395.331C186.018 395.098 186.123 394.805 186.123 394.504C186.123 394.203 186.01 393.91 185.785 393.684C185.56 393.458 185.266 393.346 184.966 393.346C184.665 393.346 184.38 393.458 184.139 393.684C184.124 393.714 184.072 393.714 184.041 393.684C184.041 393.684 184.026 393.684 184.026 393.669C184.019 393.654 184.011 393.639 184.019 393.616L184.214 391.962C184.214 391.962 184.244 391.909 184.267 391.902C184.274 391.902 184.289 391.887 184.305 391.887H186.198C186.236 391.887 186.274 391.924 186.274 391.962C186.274 392.007 186.236 392.037 186.198 392.037H184.35L184.184 393.466C184.417 393.293 184.688 393.21 184.966 393.21C185.304 393.21 185.642 393.331 185.898 393.594C186.146 393.85 186.274 394.18 186.274 394.519C186.274 394.857 186.146 395.196 185.883 395.444C185.627 395.7 185.297 395.835 184.958 395.835C184.62 395.835 184.297 395.707 184.041 395.444V395.429Z" fill="#E71D36"/>
<path d="M187.288 392.375L188.077 391.909C188.077 391.909 188.152 391.894 188.174 391.932C188.189 391.969 188.174 392.007 188.144 392.03L187.363 392.503C187.363 392.503 187.34 392.503 187.325 392.503C187.303 392.503 187.273 392.503 187.265 392.473C187.25 392.443 187.25 392.398 187.295 392.375H187.288ZM188.039 391.969C188.039 391.969 188.077 391.894 188.107 391.894C188.152 391.894 188.182 391.932 188.182 391.969V395.745C188.182 395.782 188.152 395.82 188.107 395.82C188.077 395.82 188.039 395.782 188.039 395.745V391.969Z" fill="#E71D36"/>
</g>
<g opacity="0.69">
<path d="M172.115 330.389L173.918 326.08H171.491C171.446 326.08 171.408 326.042 171.408 325.997C171.408 325.959 171.446 325.914 171.491 325.914H174.054C174.091 325.914 174.136 325.959 174.136 325.997C174.136 326.019 174.121 326.034 174.114 326.057L172.257 330.457C172.257 330.457 172.22 330.509 172.182 330.509C172.182 330.509 172.16 330.509 172.152 330.509C172.107 330.487 172.092 330.442 172.107 330.396L172.115 330.389Z" fill="#5EB0EF"/>
<path d="M177.383 329.532H174.692C174.692 329.532 174.662 329.532 174.64 329.509C174.609 329.479 174.602 329.426 174.624 329.396L177.398 325.944L177.413 325.929C177.413 325.929 177.443 325.907 177.465 325.907C177.488 325.907 177.518 325.929 177.533 325.944C177.533 325.944 177.54 325.959 177.548 325.967C177.548 325.967 177.548 325.982 177.548 325.989V329.359H177.946C177.999 329.359 178.029 329.396 178.029 329.441C178.029 329.494 177.999 329.524 177.946 329.524H177.548V330.419C177.548 330.464 177.518 330.502 177.465 330.502C177.428 330.502 177.383 330.464 177.383 330.419V329.524V329.532ZM177.383 329.366V326.252L174.865 329.366H177.383Z" fill="#5EB0EF"/>
</g>
<g opacity="0.65">
<path d="M226.984 402.07C226.984 402.07 226.961 402.002 226.984 401.979C227.007 401.957 227.052 401.957 227.074 401.979C227.285 402.182 227.555 402.295 227.826 402.295C228.096 402.295 228.374 402.19 228.577 401.979C228.788 401.769 228.885 401.498 228.885 401.227C228.885 400.957 228.78 400.686 228.577 400.475C228.374 400.272 228.104 400.167 227.826 400.167C227.548 400.167 227.292 400.272 227.074 400.475C227.059 400.498 227.014 400.498 226.984 400.475C226.984 400.475 226.976 400.475 226.976 400.468C226.969 400.453 226.961 400.438 226.969 400.415L227.149 398.903C227.149 398.903 227.172 398.851 227.194 398.843C227.202 398.843 227.217 398.836 227.232 398.836H228.968C228.968 398.836 229.036 398.873 229.036 398.903C229.036 398.941 229.006 398.971 228.968 398.971H227.285L227.127 400.272C227.337 400.114 227.593 400.039 227.841 400.039C228.149 400.039 228.457 400.152 228.69 400.385C228.915 400.618 229.036 400.919 229.036 401.227C229.036 401.536 228.915 401.844 228.682 402.07C228.449 402.303 228.141 402.423 227.833 402.423C227.525 402.423 227.224 402.303 226.991 402.07H226.984Z" fill="#5EB0EF"/>
<path d="M232.162 401.656H230.066C230.066 401.656 230.043 401.656 230.028 401.641C230.005 401.618 229.998 401.581 230.02 401.551L232.185 398.858H232.192C232.192 398.858 232.215 398.836 232.237 398.836C232.26 398.836 232.275 398.851 232.29 398.866V398.873C232.29 398.881 232.298 398.888 232.298 398.888V401.521H232.613C232.658 401.521 232.681 401.543 232.681 401.581C232.681 401.618 232.658 401.648 232.613 401.648H232.298V402.348C232.298 402.348 232.275 402.416 232.23 402.416C232.2 402.416 232.162 402.385 232.162 402.348V401.648V401.656ZM232.162 401.528V399.099L230.201 401.528H232.162Z" fill="#5EB0EF"/>
</g>
<g opacity="0.63">
<path d="M266.454 290.138L269.385 287.498C269.753 287.152 269.956 286.686 269.956 286.212C269.956 285.806 269.798 285.392 269.505 285.061C269.167 284.693 268.693 284.489 268.227 284.489C267.814 284.489 267.393 284.647 267.07 284.941L266.77 285.219C266.717 285.264 266.657 285.264 266.612 285.219C266.567 285.174 266.574 285.099 266.619 285.061L266.92 284.775C267.303 284.437 267.762 284.271 268.227 284.271C268.754 284.271 269.287 284.489 269.663 284.903C269.994 285.279 270.166 285.753 270.166 286.212C270.166 286.738 269.948 287.272 269.52 287.648L266.8 290.115H270.069C270.121 290.115 270.174 290.16 270.174 290.22C270.174 290.28 270.121 290.333 270.069 290.333H266.537C266.537 290.333 266.469 290.326 266.446 290.288C266.416 290.243 266.416 290.175 266.454 290.13V290.138Z" fill="#E71D36"/>
</g>
<g opacity="0.88">
<path d="M265.094 258.069C267.153 258.084 268.686 260.491 268.686 263.318C268.686 266.146 267.153 268.583 265.094 268.598C263.065 268.583 261.517 266.176 261.517 263.318C261.517 260.46 263.065 258.084 265.094 258.069ZM261.892 263.318C261.892 264.702 262.283 265.936 262.869 266.808C263.456 267.711 264.252 268.222 265.094 268.222C265.936 268.222 266.747 267.711 267.333 266.808C267.935 265.936 268.31 264.702 268.31 263.318C268.31 261.935 267.935 260.731 267.333 259.859C266.747 258.956 265.951 258.445 265.094 258.445C264.237 258.445 263.456 258.956 262.869 259.859C262.283 260.731 261.892 261.965 261.892 263.318Z" fill="#E71D36"/>
</g>
<g opacity="0.65">
<path d="M205.085 358.201C205.295 358.411 205.408 358.697 205.408 358.975C205.408 359.254 205.303 359.539 205.085 359.758C204.867 359.968 204.589 360.081 204.311 360.081C204.032 360.081 203.754 359.976 203.536 359.758C203.514 359.735 203.514 359.697 203.536 359.675C203.559 359.652 203.597 359.652 203.619 359.675C203.815 359.863 204.055 359.953 204.303 359.953C204.551 359.953 204.807 359.855 204.994 359.667C205.19 359.479 205.28 359.231 205.28 358.975C205.28 358.72 205.19 358.479 204.994 358.291C204.799 358.103 204.559 358.013 204.311 358.013C204.288 358.013 204.265 358.005 204.25 357.983C204.25 357.983 204.228 357.915 204.25 357.885L205.197 356.907H203.747C203.747 356.907 203.687 356.877 203.687 356.847C203.687 356.817 203.717 356.787 203.747 356.787H205.34C205.34 356.787 205.4 356.817 205.4 356.847C205.4 356.855 205.4 356.87 205.4 356.877C205.4 356.885 205.393 356.892 205.393 356.892L204.423 357.892C204.671 357.915 204.904 358.02 205.092 358.208L205.085 358.201Z" fill="#E71D36"/>
<path d="M206.333 359.75C206.333 359.75 206.31 359.69 206.333 359.667C206.355 359.645 206.393 359.645 206.415 359.667C206.611 359.855 206.859 359.953 207.099 359.953C207.34 359.953 207.603 359.855 207.791 359.667C207.986 359.472 208.076 359.224 208.076 358.975C208.076 358.727 207.986 358.479 207.791 358.291C207.603 358.103 207.362 358.005 207.107 358.005C206.851 358.005 206.618 358.096 206.415 358.291C206.4 358.314 206.355 358.314 206.333 358.291H206.325C206.325 358.291 206.31 358.253 206.318 358.238L206.483 356.855C206.483 356.855 206.505 356.809 206.528 356.802C206.535 356.802 206.551 356.794 206.558 356.794H208.144C208.144 356.794 208.204 356.825 208.204 356.855C208.204 356.892 208.174 356.915 208.144 356.915H206.596L206.453 358.111C206.648 357.968 206.874 357.892 207.107 357.892C207.392 357.892 207.67 357.998 207.888 358.216C208.091 358.426 208.204 358.705 208.204 358.983C208.204 359.261 208.099 359.547 207.881 359.758C207.67 359.976 207.385 360.081 207.107 360.081C206.829 360.081 206.55 359.968 206.333 359.758V359.75Z" fill="#E71D36"/>
</g>
<g opacity="0.95">
<path d="M250.078 269.598L252.994 262.642H249.079C249.003 262.642 248.943 262.581 248.943 262.506C248.943 262.446 249.003 262.371 249.079 262.371H253.219C253.28 262.371 253.355 262.446 253.355 262.506C253.355 262.536 253.332 262.566 253.31 262.604L250.319 269.711C250.311 269.756 250.258 269.794 250.206 269.794C250.206 269.794 250.176 269.786 250.153 269.786C250.078 269.756 250.056 269.681 250.078 269.606V269.598Z" fill="#E71D36"/>
<path d="M257.458 262.356C258.713 262.356 259.743 263.326 259.848 264.567C259.878 264.77 259.893 264.98 259.893 265.191C259.893 266.191 259.683 267.297 259.172 268.184C258.638 269.079 257.811 269.734 256.594 269.786C256.511 269.786 256.459 269.734 256.459 269.651C256.444 269.591 256.511 269.523 256.579 269.523C257.714 269.47 258.45 268.876 258.931 268.057C259.292 267.417 259.517 266.635 259.592 265.875C259.202 266.643 258.39 267.162 257.466 267.162C256.135 267.162 255.046 266.086 255.046 264.762C255.046 263.439 256.143 262.348 257.466 262.348L257.458 262.356ZM257.458 266.906C258.638 266.906 259.592 265.951 259.592 264.77C259.592 264.717 259.592 264.665 259.585 264.612V264.567C259.48 263.461 258.563 262.626 257.458 262.626C256.271 262.626 255.301 263.582 255.301 264.77C255.301 265.958 256.263 266.906 257.458 266.906Z" fill="#E71D36"/>
</g>
<g opacity="0.67">
<path d="M218.394 202.941L220.092 201.934C220.167 201.888 220.265 201.896 220.31 201.986C220.348 202.061 220.325 202.144 220.235 202.189L218.537 203.212C218.537 203.212 218.484 203.227 218.461 203.227C218.416 203.227 218.349 203.212 218.326 203.152C218.289 203.092 218.304 202.994 218.386 202.949L218.394 202.941ZM220.025 202.054C220.025 201.979 220.1 201.896 220.175 201.896C220.273 201.896 220.333 201.979 220.333 202.054V210.237C220.333 210.319 220.273 210.394 220.175 210.394C220.1 210.394 220.025 210.319 220.025 210.237V202.054Z" fill="#5EB0EF"/>
<path d="M225.578 201.888C227.014 201.888 228.193 202.994 228.314 204.415C228.351 204.649 228.366 204.889 228.366 205.13C228.366 206.273 228.126 207.537 227.54 208.559C226.931 209.582 225.984 210.334 224.586 210.394C224.488 210.394 224.428 210.334 224.428 210.237C224.413 210.161 224.488 210.094 224.564 210.094C225.864 210.033 226.705 209.349 227.262 208.417C227.675 207.687 227.93 206.784 228.013 205.92C227.562 206.792 226.638 207.394 225.586 207.394C224.068 207.394 222.812 206.168 222.812 204.641C222.812 203.114 224.06 201.881 225.586 201.881L225.578 201.888ZM225.578 207.093C226.931 207.093 228.021 205.995 228.021 204.649C228.021 204.588 228.021 204.528 228.006 204.468V204.408C227.885 203.152 226.841 202.197 225.578 202.197C224.218 202.197 223.113 203.295 223.113 204.656C223.113 206.017 224.218 207.1 225.578 207.1V207.093Z" fill="#5EB0EF"/>
</g>
<g opacity="0.55">
<path d="M262.726 300.99L263.432 300.569C263.432 300.569 263.508 300.554 263.523 300.592C263.538 300.622 263.523 300.659 263.493 300.674L262.786 301.095H262.764C262.734 301.095 262.711 301.095 262.696 301.065C262.681 301.043 262.688 300.998 262.719 300.983L262.726 300.99ZM263.402 300.622C263.402 300.622 263.433 300.554 263.463 300.554C263.5 300.554 263.53 300.592 263.53 300.622V304.021C263.53 304.021 263.508 304.089 263.463 304.089C263.433 304.089 263.402 304.059 263.402 304.021V300.622Z" fill="#E71D36"/>
<path d="M264.417 300.99L265.123 300.569C265.123 300.569 265.199 300.554 265.214 300.592C265.229 300.622 265.214 300.659 265.184 300.674L264.477 301.095C264.477 301.095 264.455 301.095 264.447 301.095C264.425 301.095 264.402 301.095 264.394 301.065C264.379 301.043 264.387 300.998 264.417 300.983V300.99ZM265.093 300.622C265.093 300.622 265.123 300.554 265.154 300.554C265.199 300.554 265.221 300.592 265.221 300.622V304.021C265.221 304.021 265.199 304.089 265.154 304.089C265.123 304.089 265.093 304.059 265.093 304.021V300.622Z" fill="#E71D36"/>
</g>
<g opacity="0.87">
<path d="M349.144 191.931C350.73 193.518 351.542 195.639 351.542 197.722C351.542 199.805 350.73 201.956 349.144 203.581C347.521 205.168 345.409 205.98 343.328 205.98H343.29C341.208 205.98 339.096 205.205 337.503 203.581C337.323 203.408 337.323 203.122 337.503 202.949C337.676 202.776 337.962 202.776 338.135 202.949C339.585 204.363 341.419 205.062 343.283 205.062C345.146 205.062 347.055 204.355 348.468 202.911C349.918 201.497 350.617 199.625 350.617 197.722C350.617 195.819 349.911 194.014 348.498 192.57C347.055 191.156 345.221 190.449 343.35 190.449C343.177 190.449 343.034 190.374 342.929 190.239C342.929 190.201 342.892 190.201 342.892 190.201C342.719 190.021 342.719 189.705 342.892 189.532L350.016 182.191H339.119C338.871 182.191 338.661 181.981 338.661 181.733C338.661 181.485 338.871 181.274 339.119 181.274H351.076C351.324 181.274 351.534 181.485 351.534 181.733C351.534 181.8 351.534 181.876 351.497 181.943C351.459 182.011 351.429 182.086 351.391 182.124L344.124 189.569C345.958 189.75 347.754 190.525 349.129 191.931H349.144Z" fill="#E71D36"/>
<path d="M358.449 203.573C358.276 203.43 358.276 203.114 358.449 202.941C358.622 202.768 358.907 202.768 359.088 202.941C360.531 204.355 362.402 205.092 364.236 205.092C366.069 205.092 368.008 204.355 369.421 202.941C370.864 201.49 371.57 199.625 371.57 197.752C371.57 195.879 370.864 194.007 369.421 192.6C368.008 191.186 366.175 190.479 364.273 190.479C362.372 190.479 360.568 191.186 359.088 192.6C358.982 192.773 358.667 192.773 358.486 192.638C358.449 192.638 358.419 192.6 358.419 192.57C358.381 192.57 358.381 192.533 358.381 192.533C358.314 192.427 358.276 192.322 358.314 192.179L359.546 181.77C359.584 181.56 359.719 181.417 359.862 181.379C359.929 181.342 360.004 181.312 360.11 181.312H372.029C372.277 181.312 372.487 181.56 372.487 181.77C372.487 182.056 372.277 182.229 372.029 182.229H360.425L359.366 191.194C360.816 190.096 362.537 189.569 364.266 189.569C366.385 189.569 368.497 190.344 370.12 191.969C371.676 193.555 372.48 195.639 372.48 197.76C372.48 199.88 371.668 201.994 370.045 203.581C368.459 205.205 366.34 206.017 364.228 206.017C362.116 206.017 360.065 205.205 358.441 203.581L358.449 203.573Z" fill="#E71D36"/>
</g>
<g opacity="0.54">
<path d="M312.207 201.49L314.063 200.384C314.146 200.332 314.251 200.347 314.304 200.437C314.341 200.52 314.319 200.61 314.221 200.663L312.365 201.776C312.365 201.776 312.312 201.791 312.282 201.791C312.229 201.791 312.162 201.776 312.132 201.708C312.094 201.64 312.109 201.535 312.199 201.482L312.207 201.49ZM313.988 200.52C313.988 200.437 314.071 200.347 314.146 200.347C314.251 200.347 314.319 200.437 314.319 200.52V209.462C314.319 209.552 314.251 209.635 314.146 209.635C314.063 209.635 313.988 209.552 313.988 209.462V200.52Z" fill="#E71D36"/>
<path d="M322.398 207.657H316.964C316.926 207.657 316.896 207.642 316.859 207.619C316.791 207.552 316.776 207.461 316.829 207.394L322.435 200.414L322.465 200.384C322.503 200.362 322.533 200.347 322.57 200.347C322.623 200.347 322.676 200.384 322.706 200.429C322.721 200.445 322.721 200.46 322.736 200.467C322.736 200.482 322.751 200.49 322.751 200.505V207.326H323.562C323.668 207.326 323.735 207.394 323.735 207.484C323.735 207.589 323.668 207.657 323.562 207.657H322.751V209.462C322.751 209.552 322.683 209.635 322.578 209.635C322.495 209.635 322.405 209.552 322.405 209.462V207.657H322.398ZM322.398 207.326V201.024L317.31 207.326H322.398Z" fill="#E71D36"/>
</g>
<g opacity="0.82">
<path d="M58.6943 451.414C58.6943 449.481 59.1076 447.33 60.0921 445.623C61.0766 443.9 62.7225 442.622 65.0823 442.524C65.225 442.524 65.3528 442.629 65.3528 442.787C65.3528 442.908 65.2476 443.036 65.1273 443.036C62.9329 443.141 61.5125 444.269 60.5505 445.871C59.8141 447.127 59.4233 448.623 59.2805 450.105C60.0395 448.608 61.6027 447.601 63.4064 447.601C65.9691 447.601 68.0658 449.676 68.0884 452.241C68.0658 454.828 65.9766 456.904 63.4064 456.904C61.0015 456.904 59.0325 455.114 58.7845 452.775C58.7619 452.752 58.7619 452.715 58.7619 452.692C58.7243 452.279 58.7018 451.85 58.7018 451.421L58.6943 451.414ZM63.3913 456.385C65.691 456.385 67.5172 454.535 67.5548 452.233C67.5172 449.955 65.691 448.105 63.3913 448.105C61.0916 448.105 59.2654 449.955 59.2654 452.233C59.2654 454.512 61.1142 456.385 63.3913 456.385Z" fill="#E71D36"/>
<path d="M76.7685 442.517C79.5792 442.539 81.6759 445.826 81.6759 449.684C81.6759 453.542 79.5792 456.874 76.7685 456.896C73.9953 456.874 71.8835 453.587 71.8835 449.684C71.8835 445.781 73.9953 442.539 76.7685 442.517ZM72.3946 449.684C72.3946 451.572 72.9282 453.256 73.7248 454.452C74.5289 455.686 75.6111 456.385 76.761 456.385C77.9108 456.385 79.0155 455.686 79.8197 454.452C80.6388 453.264 81.1499 451.579 81.1499 449.684C81.1499 447.789 80.6388 446.149 79.8197 444.961C79.0155 443.727 77.9333 443.028 76.761 443.028C75.5886 443.028 74.5214 443.727 73.7248 444.961C72.9282 446.149 72.3946 447.834 72.3946 449.684Z" fill="#E71D36"/>
</g>
<g opacity="0.57">
<path d="M205.873 531.676L209.992 529.231C210.165 529.111 210.405 529.141 210.518 529.352C210.608 529.525 210.548 529.735 210.345 529.848L206.226 532.323C206.166 532.353 206.106 532.353 206.054 532.353C205.933 532.353 205.791 532.323 205.73 532.18C205.64 532.029 205.67 531.796 205.881 531.676H205.873ZM209.811 529.532C209.811 529.359 209.984 529.149 210.164 529.149C210.397 529.149 210.548 529.352 210.548 529.532V549.327C210.548 549.53 210.397 549.711 210.164 549.711C209.992 549.711 209.811 549.538 209.811 549.327V529.532Z" fill="#E71D36"/>
<path d="M216.515 547.68C216.365 547.56 216.365 547.296 216.515 547.154C216.665 547.003 216.898 547.003 217.048 547.154C218.251 548.334 219.814 548.951 221.34 548.951C222.865 548.951 224.489 548.334 225.661 547.154C226.863 545.95 227.457 544.386 227.457 542.829C227.457 541.272 226.871 539.708 225.661 538.535C224.489 537.361 222.955 536.767 221.37 536.767C219.784 536.767 218.281 537.354 217.048 538.535C216.958 538.685 216.695 538.685 216.545 538.565C216.515 538.565 216.485 538.535 216.485 538.505C216.455 538.505 216.455 538.475 216.455 538.475C216.395 538.384 216.365 538.294 216.395 538.181L217.424 529.502C217.454 529.329 217.567 529.209 217.687 529.179C217.747 529.149 217.808 529.119 217.89 529.119H227.825C228.028 529.119 228.209 529.322 228.209 529.502C228.209 529.735 228.036 529.886 227.825 529.886H218.153L217.274 537.354C218.476 536.444 219.919 536 221.362 536C223.128 536 224.887 536.647 226.24 538.001C227.532 539.324 228.209 541.062 228.209 542.822C228.209 544.581 227.532 546.349 226.18 547.673C224.857 549.026 223.091 549.703 221.332 549.703C219.574 549.703 217.86 549.026 216.507 547.673L216.515 547.68Z" fill="#E71D36"/>
</g>
<g opacity="0.95">
<path d="M89.1612 467.08C90.063 467.08 90.792 467.809 90.792 468.727C90.792 469.381 90.4012 469.945 89.8601 470.208C90.9724 470.517 91.799 471.532 91.799 472.743C91.799 474.202 90.6191 475.375 89.1612 475.375C87.7032 475.375 86.5233 474.202 86.5233 472.743C86.5233 471.532 87.35 470.517 88.4623 470.208C87.8911 469.945 87.5153 469.381 87.5153 468.727C87.5153 467.817 88.2443 467.08 89.1612 467.08ZM89.1612 475.074C90.4613 475.074 91.5059 474.029 91.5059 472.743C91.5059 471.457 90.4613 470.396 89.1612 470.396C87.861 470.396 86.8164 471.449 86.8164 472.743C86.8164 474.036 87.8686 475.074 89.1612 475.074ZM89.1612 470.065C89.8977 470.065 90.4989 469.464 90.4989 468.727C90.4989 467.99 89.8977 467.388 89.1612 467.373C88.4172 467.388 87.8084 467.975 87.8084 468.727C87.8084 469.479 88.4096 470.065 89.1612 470.065Z" fill="#E71D36"/>
<path d="M98.8935 473.6H94.0537C94.0537 473.6 93.9935 473.585 93.956 473.562C93.8958 473.502 93.8883 473.42 93.9334 473.359L98.9236 467.14L98.9461 467.117C98.9461 467.117 99.0062 467.08 99.0438 467.08C99.0889 467.08 99.1415 467.117 99.164 467.147C99.1791 467.162 99.1791 467.17 99.1866 467.185C99.1866 467.2 99.2016 467.207 99.2016 467.222V473.299H99.9231C100.021 473.299 100.081 473.359 100.081 473.442C100.081 473.54 100.021 473.593 99.9231 473.593H99.2016V475.202C99.2016 475.285 99.1415 475.352 99.0438 475.352C98.9686 475.352 98.8935 475.277 98.8935 475.202V473.593V473.6ZM98.8935 473.307V467.696L94.3618 473.307H98.8935Z" fill="#E71D36"/>
</g>
<g opacity="0.89">
<path d="M63.2564 507.88L72.2446 499.757C73.3869 498.689 74.0032 497.253 74.0032 495.816C74.0032 494.568 73.5222 493.289 72.6204 492.274C71.5833 491.131 70.1178 490.514 68.7125 490.514C67.4349 490.514 66.1573 490.996 65.1502 491.898L64.2183 492.748C64.0605 492.883 63.8726 492.883 63.7373 492.748C63.6021 492.613 63.6321 492.372 63.7674 492.267L64.6993 491.387C65.8717 490.349 67.277 489.845 68.7125 489.845C70.3358 489.845 71.959 490.507 73.1314 491.785C74.146 492.929 74.6721 494.395 74.6721 495.809C74.6721 497.433 74.0107 499.058 72.6805 500.231L64.331 507.82H74.3564C74.5142 507.82 74.6721 507.955 74.6721 508.136C74.6721 508.316 74.5142 508.481 74.3564 508.481H63.5044C63.3992 508.481 63.2939 508.451 63.2413 508.346C63.1361 508.211 63.1361 508 63.2639 507.865L63.2564 507.88Z" fill="#E71D36"/>
<path d="M81.7965 508.015L89.1089 490.582H79.2939C79.1061 490.582 78.9482 490.424 78.9482 490.236C78.9482 490.078 79.1061 489.89 79.2939 489.89H89.665C89.8228 489.89 90.0107 490.078 90.0107 490.236C90.0107 490.319 89.9581 490.394 89.9055 490.477L82.4053 508.286C82.3827 508.391 82.2474 508.497 82.1122 508.497C82.0896 508.497 82.0295 508.466 81.9769 508.466C81.789 508.384 81.7364 508.203 81.789 508.015H81.7965Z" fill="#E71D36"/>
</g>
<g opacity="0.77">
<path d="M213.095 406.424L217.304 396.399H211.66C211.555 396.399 211.465 396.309 211.465 396.203C211.465 396.113 211.555 396.008 211.66 396.008H217.627C217.717 396.008 217.823 396.113 217.823 396.203C217.823 396.249 217.793 396.294 217.762 396.339L213.449 406.575C213.434 406.635 213.358 406.695 213.283 406.695C213.268 406.695 213.238 406.68 213.208 406.68C213.103 406.635 213.073 406.53 213.103 406.417L213.095 406.424Z" fill="#E71D36"/>
<path d="M223.556 395.985C224.721 395.985 225.668 396.933 225.668 398.114C225.668 398.956 225.165 399.693 224.458 400.024C225.894 400.423 226.968 401.739 226.968 403.296C226.968 405.176 225.443 406.695 223.556 406.695C221.67 406.695 220.144 405.183 220.144 403.296C220.144 401.739 221.212 400.423 222.654 400.024C221.918 399.686 221.429 398.956 221.429 398.114C221.429 396.933 222.376 395.985 223.556 395.985ZM223.556 406.311C225.24 406.311 226.585 404.965 226.585 403.296C226.585 401.626 225.24 400.265 223.556 400.265C221.873 400.265 220.528 401.626 220.528 403.296C220.528 404.965 221.888 406.311 223.556 406.311ZM223.556 399.836C224.503 399.836 225.285 399.054 225.285 398.106C225.285 397.159 224.503 396.376 223.556 396.361C222.594 396.376 221.813 397.144 221.813 398.106C221.813 399.069 222.594 399.836 223.556 399.836Z" fill="#E71D36"/>
</g>
<g opacity="0.72">
<path d="M147.449 364.917L152.823 352.102H145.608C145.473 352.102 145.353 351.981 145.353 351.846C145.353 351.725 145.473 351.59 145.608 351.59H153.229C153.349 351.59 153.484 351.725 153.484 351.846C153.484 351.906 153.446 351.966 153.409 352.026L147.893 365.113C147.87 365.195 147.772 365.27 147.675 365.27C147.652 365.27 147.615 365.248 147.577 365.248C147.442 365.188 147.404 365.052 147.442 364.917H147.449Z" fill="#E71D36"/>
<path d="M156.242 364.819L162.848 358.848C163.69 358.066 164.141 357.005 164.141 355.952C164.141 355.035 163.787 354.095 163.126 353.35C162.367 352.508 161.285 352.056 160.255 352.056C159.316 352.056 158.376 352.41 157.632 353.072L156.949 353.696C156.828 353.794 156.693 353.794 156.595 353.696C156.498 353.598 156.52 353.425 156.618 353.342L157.302 352.696C158.159 351.936 159.196 351.56 160.255 351.56C161.45 351.56 162.638 352.049 163.502 352.989C164.246 353.831 164.637 354.907 164.637 355.945C164.637 357.14 164.148 358.329 163.171 359.194L157.031 364.767H164.404C164.524 364.767 164.637 364.864 164.637 365C164.637 365.135 164.516 365.255 164.404 365.255H156.43C156.355 365.255 156.272 365.233 156.235 365.158C156.159 365.06 156.159 364.902 156.257 364.804L156.242 364.819Z" fill="#E71D36"/>
</g>
<g opacity="0.91">
<path d="M118.005 355.621C118.223 355.847 118.335 356.14 118.335 356.426C118.335 356.712 118.223 357.013 118.005 357.246C117.779 357.464 117.486 357.577 117.193 357.577C116.9 357.577 116.607 357.471 116.389 357.246C116.366 357.223 116.366 357.186 116.389 357.155C116.412 357.133 116.449 357.133 116.479 357.155C116.682 357.351 116.938 357.449 117.201 357.449C117.464 357.449 117.727 357.351 117.922 357.148C118.125 356.952 118.223 356.689 118.223 356.426C118.223 356.163 118.125 355.907 117.93 355.704C117.727 355.508 117.471 355.411 117.208 355.411C117.186 355.411 117.163 355.403 117.148 355.381C117.125 355.358 117.125 355.313 117.148 355.29L118.14 354.267H116.622C116.622 354.267 116.562 354.237 116.562 354.207C116.562 354.177 116.592 354.14 116.622 354.14H118.29C118.29 354.14 118.35 354.17 118.35 354.207C118.35 354.207 118.35 354.23 118.35 354.237C118.35 354.245 118.343 354.26 118.335 354.26L117.321 355.298C117.576 355.32 117.824 355.433 118.02 355.629L118.005 355.621Z" fill="#E71D36"/>
<path d="M119.192 354.568L119.884 354.162C119.884 354.162 119.951 354.147 119.974 354.185C119.989 354.215 119.974 354.245 119.944 354.267L119.252 354.681C119.252 354.681 119.23 354.681 119.222 354.681C119.2 354.681 119.177 354.681 119.17 354.651C119.155 354.628 119.162 354.591 119.192 354.568ZM119.854 354.207C119.854 354.207 119.884 354.147 119.914 354.147C119.951 354.147 119.974 354.185 119.974 354.207V357.516C119.974 357.516 119.951 357.577 119.914 357.577C119.884 357.577 119.854 357.547 119.854 357.516V354.207Z" fill="#E71D36"/>
</g>
<g opacity="0.71">
<path d="M262.418 391.586C262.418 391.586 262.396 391.526 262.418 391.503C262.441 391.48 262.478 391.48 262.501 391.503C262.689 391.683 262.929 391.781 263.17 391.781C263.41 391.781 263.658 391.683 263.839 391.503C264.026 391.315 264.117 391.074 264.117 390.834C264.117 390.593 264.026 390.352 263.839 390.164C263.658 389.984 263.418 389.894 263.17 389.894C262.922 389.894 262.689 389.984 262.501 390.164C262.486 390.187 262.448 390.187 262.426 390.164H262.418V390.149C262.418 390.149 262.403 390.119 262.403 390.104L262.561 388.758C262.561 388.758 262.584 388.713 262.599 388.705C262.599 388.705 262.621 388.698 262.629 388.698H264.169C264.169 388.698 264.229 388.728 264.229 388.758C264.229 388.795 264.199 388.818 264.169 388.818H262.666L262.531 389.976C262.719 389.833 262.944 389.766 263.162 389.766C263.433 389.766 263.711 389.863 263.921 390.074C264.124 390.277 264.229 390.548 264.229 390.826C264.229 391.104 264.124 391.375 263.914 391.578C263.711 391.789 263.433 391.894 263.162 391.894C262.892 391.894 262.621 391.789 262.411 391.578L262.418 391.586Z" fill="#5EB0EF"/>
<path d="M266.702 390.082C266.905 390.285 267.01 390.563 267.01 390.826C267.01 391.089 266.905 391.375 266.702 391.586C266.491 391.789 266.221 391.894 265.95 391.894C265.68 391.894 265.409 391.796 265.206 391.586C265.184 391.563 265.184 391.526 265.206 391.503C265.229 391.48 265.267 391.48 265.289 391.503C265.477 391.683 265.71 391.774 265.958 391.774C266.206 391.774 266.446 391.683 266.627 391.495C266.815 391.315 266.905 391.074 266.905 390.826C266.905 390.578 266.815 390.345 266.634 390.164C266.446 389.984 266.213 389.894 265.973 389.894C265.95 389.894 265.928 389.886 265.92 389.863C265.92 389.863 265.898 389.796 265.92 389.773L266.845 388.826H265.439C265.439 388.826 265.379 388.795 265.379 388.765C265.379 388.735 265.409 388.705 265.439 388.705H266.988C266.988 388.705 267.048 388.735 267.048 388.765C267.048 388.773 267.048 388.78 267.048 388.796C267.048 388.803 267.04 388.811 267.033 388.818L266.093 389.781C266.326 389.803 266.559 389.901 266.739 390.089L266.702 390.082Z" fill="#5EB0EF"/>
</g>
<g opacity="0.69">
<path d="M225.301 352.583C225.301 351.77 225.474 350.86 225.887 350.138C226.3 349.409 226.992 348.875 227.991 348.83C228.051 348.83 228.104 348.875 228.104 348.943C228.104 348.995 228.059 349.048 228.006 349.048C227.082 349.093 226.481 349.567 226.075 350.244C225.767 350.77 225.601 351.402 225.534 352.026C225.857 351.394 226.511 350.973 227.277 350.973C228.36 350.973 229.239 351.846 229.254 352.929C229.246 354.019 228.36 354.892 227.277 354.892C226.263 354.892 225.436 354.14 225.331 353.147C225.323 353.139 225.323 353.124 225.323 353.109C225.308 352.936 225.301 352.756 225.301 352.575V352.583ZM227.285 354.681C228.254 354.681 229.028 353.899 229.043 352.929C229.028 351.966 228.254 351.184 227.285 351.184C226.315 351.184 225.541 351.966 225.541 352.929C225.541 353.891 226.323 354.681 227.285 354.681Z" fill="#5EB0EF"/>
</g>
<g opacity="0.56">
<path d="M325.314 296.763C325.998 297.448 326.351 298.358 326.351 299.26C326.351 300.163 326.005 301.088 325.314 301.787C324.615 302.472 323.706 302.825 322.804 302.825H322.789C321.894 302.825 320.978 302.494 320.294 301.787C320.219 301.712 320.219 301.592 320.294 301.516C320.369 301.441 320.489 301.441 320.564 301.516C321.188 302.126 321.977 302.426 322.781 302.426C323.585 302.426 324.405 302.126 325.013 301.501C325.637 300.892 325.938 300.088 325.938 299.268C325.938 298.448 325.637 297.673 325.021 297.049C324.397 296.44 323.608 296.139 322.804 296.139C322.729 296.139 322.668 296.109 322.623 296.049C322.623 296.034 322.608 296.034 322.608 296.034C322.533 295.959 322.533 295.823 322.608 295.748L325.682 292.582H320.985C320.88 292.582 320.79 292.491 320.79 292.386C320.79 292.281 320.88 292.191 320.985 292.191H326.141C326.246 292.191 326.336 292.281 326.336 292.386C326.336 292.416 326.336 292.446 326.321 292.476C326.306 292.507 326.291 292.537 326.276 292.552L323.142 295.763C323.931 295.838 324.705 296.177 325.299 296.778L325.314 296.763Z" fill="#E71D36"/>
<path d="M329.387 298.771C329.387 297.342 329.688 295.748 330.417 294.485C331.146 293.206 332.363 292.266 334.107 292.191C334.212 292.191 334.302 292.266 334.302 292.386C334.302 292.476 334.227 292.567 334.137 292.567C332.514 292.642 331.461 293.477 330.747 294.665C330.199 295.59 329.913 296.703 329.808 297.801C330.372 296.688 331.522 295.944 332.859 295.944C334.761 295.944 336.309 297.478 336.324 299.381C336.309 301.298 334.761 302.833 332.859 302.833C331.078 302.833 329.62 301.509 329.44 299.772C329.425 299.757 329.425 299.727 329.425 299.711C329.395 299.411 329.38 299.087 329.38 298.771H329.387ZM332.867 302.449C334.573 302.449 335.925 301.08 335.956 299.373C335.925 297.688 334.573 296.32 332.867 296.32C331.161 296.32 329.816 297.688 329.816 299.373C329.816 301.058 331.183 302.449 332.867 302.449Z" fill="#E71D36"/>
</g>
<g opacity="0.79">
<path d="M256.241 377.695C256.241 375.19 256.774 372.393 258.052 370.182C259.33 367.94 261.464 366.286 264.523 366.158C264.711 366.158 264.868 366.293 264.868 366.504C264.868 366.662 264.733 366.827 264.575 366.827C261.727 366.963 259.886 368.429 258.638 370.505C257.676 372.129 257.173 374.077 256.985 375.995C257.969 374.047 259.991 372.746 262.336 372.746C265.665 372.746 268.378 375.439 268.408 378.77C268.378 382.132 265.665 384.825 262.336 384.825C259.217 384.825 256.662 382.508 256.346 379.47C256.316 379.44 256.316 379.387 256.316 379.365C256.263 378.831 256.233 378.274 256.233 377.71L256.241 377.695ZM262.343 384.148C265.327 384.148 267.694 381.749 267.747 378.763C267.694 375.807 265.327 373.408 262.343 373.408C259.36 373.408 256.992 375.807 256.992 378.763C256.992 381.719 259.39 384.148 262.343 384.148Z" fill="#5EB0EF"/>
<path d="M273.345 377.695C273.345 375.19 273.879 372.393 275.157 370.182C276.434 367.94 278.569 366.286 281.627 366.158C281.815 366.158 281.973 366.293 281.973 366.504C281.973 366.662 281.838 366.827 281.68 366.827C278.832 366.963 276.99 368.429 275.743 370.505C274.781 372.129 274.277 374.077 274.089 375.995C275.074 374.047 277.096 372.746 279.44 372.746C282.77 372.746 285.49 375.439 285.513 378.77C285.49 382.132 282.77 384.825 279.44 384.825C276.321 384.825 273.766 382.508 273.451 379.47C273.421 379.44 273.421 379.387 273.421 379.365C273.368 378.831 273.338 378.274 273.338 377.71L273.345 377.695ZM279.448 384.148C282.431 384.148 284.799 381.749 284.851 378.763C284.799 375.807 282.431 373.408 279.448 373.408C276.464 373.408 274.097 375.807 274.097 378.763C274.097 381.719 276.494 384.148 279.448 384.148Z" fill="#5EB0EF"/>
</g>
<g opacity="0.61">
<path d="M336.203 334.27C336.572 334.638 336.76 335.127 336.76 335.616C336.76 336.105 336.572 336.601 336.203 336.977C335.828 337.346 335.332 337.534 334.851 337.534H334.843C334.362 337.534 333.866 337.353 333.498 336.977C333.46 336.932 333.46 336.872 333.498 336.827C333.535 336.789 333.603 336.789 333.641 336.827C333.979 337.158 334.4 337.323 334.836 337.323C335.272 337.323 335.715 337.158 336.038 336.819C336.376 336.488 336.542 336.06 336.542 335.616C336.542 335.172 336.376 334.759 336.046 334.42C335.707 334.089 335.287 333.931 334.851 333.931C334.806 333.931 334.775 333.916 334.753 333.879C334.753 333.871 334.745 333.871 334.745 333.871C334.7 333.834 334.7 333.758 334.745 333.713L336.399 332.006H333.866C333.806 332.006 333.761 331.953 333.761 331.901C333.761 331.848 333.814 331.795 333.866 331.795H336.639C336.699 331.795 336.744 331.848 336.744 331.901C336.744 331.916 336.745 331.931 336.737 331.953C336.729 331.968 336.722 331.983 336.714 331.991L335.031 333.721C335.459 333.758 335.873 333.939 336.196 334.27H336.203Z" fill="#E71D36"/>
<path d="M340.239 331.803C341.209 331.803 341.998 332.548 342.081 333.51C342.103 333.668 342.111 333.834 342.111 333.992C342.111 334.759 341.945 335.616 341.555 336.3C341.149 336.985 340.51 337.496 339.563 337.534C339.495 337.534 339.458 337.496 339.458 337.429C339.45 337.376 339.495 337.331 339.548 337.331C340.427 337.293 340.991 336.834 341.367 336.203C341.645 335.714 341.818 335.105 341.878 334.526C341.577 335.112 340.953 335.518 340.239 335.518C339.217 335.518 338.368 334.691 338.368 333.668C338.368 332.645 339.21 331.811 340.239 331.811V331.803ZM340.239 335.308C341.149 335.308 341.885 334.571 341.885 333.661C341.885 333.615 341.885 333.578 341.878 333.54V333.51C341.795 332.66 341.089 332.014 340.239 332.014C339.323 332.014 338.579 332.751 338.579 333.668C338.579 334.586 339.323 335.315 340.239 335.315V335.308Z" fill="#E71D36"/>
</g>
<g opacity="0.99">
<path d="M244.292 317.453C244.667 317.829 244.863 318.333 244.863 318.829C244.863 319.326 244.667 319.837 244.292 320.221C243.908 320.597 243.405 320.792 242.909 320.792H242.901C242.405 320.792 241.902 320.604 241.526 320.221C241.481 320.176 241.481 320.108 241.526 320.07C241.564 320.025 241.631 320.025 241.676 320.07C242.022 320.409 242.458 320.574 242.901 320.574C243.345 320.574 243.796 320.409 244.134 320.063C244.48 319.732 244.645 319.281 244.645 318.829C244.645 318.378 244.479 317.95 244.141 317.604C243.796 317.273 243.36 317.1 242.916 317.1C242.871 317.1 242.841 317.085 242.819 317.047C242.819 317.04 242.811 317.04 242.811 317.04C242.766 316.994 242.766 316.919 242.811 316.882L244.502 315.137H241.917C241.857 315.137 241.804 315.084 241.804 315.024C241.804 314.964 241.857 314.911 241.917 314.911H244.758C244.818 314.911 244.863 314.964 244.863 315.024C244.863 315.039 244.863 315.054 244.855 315.077C244.848 315.092 244.84 315.114 244.833 315.122L243.112 316.889C243.548 316.934 243.976 317.115 244.299 317.453H244.292Z" fill="#E71D36"/>
<path d="M247.215 320.642L249.515 315.152H246.426C246.366 315.152 246.313 315.099 246.313 315.039C246.313 314.986 246.366 314.934 246.426 314.934H249.695C249.748 314.934 249.808 314.994 249.808 315.039C249.808 315.062 249.793 315.092 249.77 315.114L247.41 320.717C247.41 320.717 247.358 320.785 247.32 320.785C247.313 320.785 247.298 320.777 247.275 320.777C247.215 320.755 247.2 320.695 247.215 320.634V320.642Z" fill="#E71D36"/>
</g>
<g opacity="0.73">
<path d="M340.344 341.452C340.893 341.452 341.336 341.896 341.336 342.452C341.336 342.851 341.096 343.197 340.765 343.347C341.441 343.535 341.945 344.152 341.945 344.882C341.945 345.769 341.223 346.476 340.344 346.476C339.465 346.476 338.743 345.761 338.743 344.882C338.743 344.152 339.247 343.528 339.923 343.347C339.577 343.189 339.344 342.843 339.344 342.452C339.344 341.903 339.788 341.452 340.344 341.452ZM340.344 346.303C341.133 346.303 341.764 345.671 341.764 344.889C341.764 344.107 341.133 343.468 340.344 343.468C339.555 343.468 338.924 344.107 338.924 344.889C338.924 345.671 339.562 346.303 340.344 346.303ZM340.344 343.265C340.787 343.265 341.156 342.896 341.156 342.452C341.156 342.009 340.787 341.64 340.344 341.633C339.893 341.633 339.525 342.001 339.525 342.452C339.525 342.904 339.893 343.265 340.344 343.265Z" fill="#5EB0EF"/>
<path d="M345.785 343.61C346.108 343.934 346.274 344.363 346.274 344.791C346.274 345.22 346.108 345.656 345.785 345.987C345.454 346.31 345.026 346.476 344.598 346.476C344.177 346.476 343.741 346.318 343.418 345.987C343.38 345.949 343.38 345.897 343.418 345.859C343.455 345.822 343.508 345.822 343.546 345.859C343.839 346.145 344.214 346.288 344.59 346.288C344.966 346.288 345.357 346.145 345.642 345.852C345.935 345.566 346.078 345.182 346.078 344.791C346.078 344.4 345.935 344.039 345.65 343.746C345.357 343.46 344.981 343.317 344.605 343.317C344.568 343.317 344.538 343.302 344.523 343.272H344.515C344.477 343.227 344.477 343.167 344.515 343.129L345.965 341.633H343.748C343.696 341.633 343.658 341.587 343.658 341.542C343.658 341.497 343.703 341.452 343.748 341.452H346.183C346.236 341.452 346.274 341.497 346.274 341.542C346.274 341.557 346.274 341.572 346.274 341.587C346.274 341.602 346.259 341.617 346.251 341.625L344.771 343.144C345.146 343.182 345.507 343.34 345.785 343.626V343.61Z" fill="#5EB0EF"/>
</g>
<g opacity="0.97">
<path d="M378.605 368.61C379.243 368.61 379.762 369.129 379.762 369.775C379.762 370.242 379.484 370.64 379.101 370.828C379.89 371.046 380.476 371.768 380.476 372.626C380.476 373.656 379.634 374.491 378.605 374.491C377.575 374.491 376.733 373.656 376.733 372.626C376.733 371.768 377.32 371.046 378.109 370.828C377.703 370.64 377.432 370.242 377.432 369.775C377.432 369.129 377.951 368.61 378.597 368.61H378.605ZM378.605 374.28C379.529 374.28 380.266 373.543 380.266 372.626C380.266 371.708 379.529 370.964 378.605 370.964C377.68 370.964 376.944 371.708 376.944 372.626C376.944 373.543 377.688 374.28 378.605 374.28ZM378.605 370.723C379.123 370.723 379.552 370.294 379.552 369.775C379.552 369.256 379.123 368.828 378.605 368.82C378.079 368.828 377.65 369.249 377.65 369.775C377.65 370.302 378.079 370.723 378.605 370.723Z" fill="#E71D36"/>
<path d="M382.911 374.341L385.218 368.835H382.122C382.061 368.835 382.009 368.783 382.009 368.73C382.009 368.677 382.061 368.617 382.122 368.617H385.398C385.451 368.617 385.511 368.677 385.511 368.73C385.511 368.753 385.496 368.783 385.473 368.805L383.106 374.423C383.106 374.423 383.053 374.491 383.016 374.491C383.008 374.491 382.993 374.483 382.971 374.483C382.911 374.461 382.896 374.401 382.911 374.341Z" fill="#E71D36"/>
</g>
<g opacity="0.9">
<path d="M370.465 257.918C371.502 258.956 372.028 260.34 372.028 261.694C372.028 263.048 371.502 264.454 370.465 265.514C369.406 266.552 368.023 267.079 366.67 267.079H366.647C365.287 267.079 363.912 266.575 362.875 265.514C362.762 265.402 362.762 265.214 362.875 265.101C362.988 264.988 363.175 264.988 363.288 265.101C364.228 266.026 365.43 266.485 366.647 266.485C367.865 266.485 369.112 266.026 370.029 265.078C370.976 264.153 371.435 262.935 371.435 261.694C371.435 260.453 370.976 259.272 370.052 258.332C369.105 257.415 367.91 256.948 366.693 256.948C366.58 256.948 366.482 256.903 366.414 256.813C366.414 256.79 366.392 256.79 366.392 256.79C366.279 256.678 366.279 256.467 366.392 256.354L371.044 251.563H363.934C363.777 251.563 363.634 251.428 363.634 251.263C363.634 251.097 363.769 250.962 363.934 250.962H371.735C371.893 250.962 372.036 251.097 372.036 251.263C372.036 251.308 372.036 251.353 372.013 251.398C371.991 251.443 371.968 251.488 371.946 251.511L367.204 256.369C368.399 256.482 369.571 256.993 370.473 257.911L370.465 257.918Z" fill="#E71D36"/>
<path d="M381.806 250.984C384.519 250.984 386.751 253.083 386.984 255.775C387.051 256.211 387.074 256.67 387.074 257.136C387.074 259.302 386.616 261.701 385.511 263.634C384.361 265.567 382.565 266.996 379.919 267.109C379.739 267.109 379.619 266.996 379.619 266.808C379.596 266.673 379.732 266.53 379.874 266.53C382.339 266.417 383.925 265.123 384.985 263.348C385.766 261.965 386.255 260.265 386.413 258.625C385.563 260.288 383.812 261.416 381.813 261.416C378.935 261.416 376.568 259.092 376.568 256.211C376.568 253.331 378.935 250.984 381.813 250.984H381.806ZM381.806 260.844C384.361 260.844 386.435 258.768 386.435 256.211C386.435 256.098 386.435 255.978 386.413 255.865V255.752C386.18 253.383 384.203 251.563 381.813 251.563C379.236 251.563 377.139 253.632 377.139 256.211C377.139 258.791 379.236 260.844 381.813 260.844H381.806Z" fill="#E71D36"/>
</g>
<g opacity="0.98">
<path d="M333.941 192.044C333.941 191.066 334.152 189.968 334.648 189.103C335.144 188.231 335.978 187.584 337.18 187.531C337.256 187.531 337.316 187.584 337.316 187.667C337.316 187.727 337.263 187.794 337.203 187.794C336.091 187.847 335.369 188.419 334.881 189.231C334.505 189.863 334.31 190.63 334.234 191.374C334.618 190.615 335.414 190.103 336.324 190.103C337.624 190.103 338.691 191.156 338.699 192.457C338.691 193.774 337.624 194.826 336.324 194.826C335.106 194.826 334.107 193.916 333.979 192.728C333.971 192.721 333.971 192.698 333.971 192.683C333.949 192.472 333.941 192.254 333.941 192.036V192.044ZM336.324 194.563C337.489 194.563 338.42 193.623 338.436 192.457C338.413 191.299 337.489 190.359 336.324 190.359C335.159 190.359 334.234 191.299 334.234 192.457C334.234 193.616 335.174 194.563 336.324 194.563Z" fill="#E71D36"/>
<path d="M342.907 187.531C344.14 187.531 345.147 188.479 345.252 189.697C345.282 189.893 345.297 190.103 345.297 190.314C345.297 191.292 345.087 192.375 344.591 193.255C344.072 194.127 343.26 194.774 342.058 194.826C341.975 194.826 341.923 194.774 341.923 194.691C341.915 194.631 341.975 194.563 342.035 194.563C343.148 194.511 343.869 193.931 344.35 193.127C344.703 192.503 344.921 191.728 344.996 190.991C344.613 191.743 343.816 192.254 342.915 192.254C341.615 192.254 340.54 191.201 340.54 189.9C340.54 188.599 341.615 187.531 342.915 187.531H342.907ZM342.907 191.991C344.064 191.991 345.004 191.051 345.004 189.9C345.004 189.848 345.004 189.795 344.996 189.742V189.697C344.891 188.614 343.997 187.787 342.915 187.787C341.75 187.787 340.803 188.727 340.803 189.893C340.803 191.059 341.75 191.984 342.915 191.984L342.907 191.991Z" fill="#E71D36"/>
</g>
<g opacity="0.94">
<path d="M448.369 217.592L449.721 216.374C449.894 216.216 449.984 215.998 449.984 215.779C449.984 215.591 449.909 215.396 449.774 215.245C449.616 215.072 449.398 214.982 449.188 214.982C448.992 214.982 448.804 215.057 448.647 215.193L448.511 215.321C448.511 215.321 448.459 215.343 448.436 215.321C448.414 215.298 448.421 215.268 448.436 215.245L448.579 215.11C448.759 214.952 448.97 214.877 449.188 214.877C449.436 214.877 449.676 214.975 449.849 215.17C449.999 215.343 450.082 215.561 450.082 215.779C450.082 216.02 449.984 216.268 449.781 216.441L448.526 217.584H450.037C450.037 217.584 450.082 217.607 450.082 217.63C450.082 217.652 450.059 217.682 450.037 217.682H448.406C448.406 217.682 448.376 217.682 448.369 217.66C448.353 217.637 448.353 217.607 448.369 217.584V217.592Z" fill="#5EB0EF"/>
<path d="M450.788 216.614C450.788 216.238 450.871 215.817 451.059 215.486C451.254 215.148 451.57 214.899 452.028 214.884C452.059 214.884 452.081 214.907 452.081 214.937C452.081 214.96 452.059 214.982 452.036 214.982C451.608 215.005 451.33 215.223 451.142 215.539C450.999 215.779 450.924 216.073 450.894 216.366C451.044 216.073 451.345 215.877 451.698 215.877C452.201 215.877 452.607 216.283 452.607 216.78C452.607 217.284 452.194 217.69 451.698 217.69C451.232 217.69 450.849 217.344 450.796 216.885V216.87C450.781 216.787 450.781 216.704 450.781 216.622L450.788 216.614ZM451.705 217.584C452.156 217.584 452.509 217.223 452.517 216.772C452.509 216.328 452.149 215.967 451.705 215.967C451.262 215.967 450.901 216.328 450.901 216.772C450.901 217.216 451.262 217.584 451.705 217.584Z" fill="#5EB0EF"/>
</g>
<g opacity="0.52">
<path d="M259.232 269.109H245.764C245.667 269.109 245.599 269.079 245.501 269.012C245.336 268.846 245.306 268.613 245.434 268.455L259.322 251.157L259.39 251.09C259.487 251.022 259.555 250.992 259.653 250.992C259.78 250.992 259.916 251.09 259.983 251.187C260.021 251.217 260.021 251.255 260.051 251.285C260.051 251.315 260.088 251.353 260.088 251.383V268.282H262.095C262.358 268.282 262.523 268.448 262.523 268.681C262.523 268.944 262.358 269.109 262.095 269.109H260.088V273.592C260.088 273.825 259.923 274.021 259.66 274.021C259.465 274.021 259.232 273.825 259.232 273.592V269.109ZM259.232 268.282V252.669L246.621 268.282H259.232Z" fill="#E71D36"/>
<path d="M267.228 273.261L278.351 263.213C279.763 261.897 280.522 260.115 280.522 258.34C280.522 256.79 279.929 255.211 278.809 253.955C277.524 252.541 275.713 251.781 273.969 251.781C272.391 251.781 270.813 252.376 269.558 253.496L268.408 254.549C268.212 254.715 267.979 254.715 267.814 254.549C267.649 254.384 267.686 254.09 267.852 253.955L269.002 252.864C270.452 251.578 272.196 250.954 273.969 250.954C275.976 250.954 277.982 251.781 279.433 253.361C280.68 254.775 281.342 256.587 281.342 258.34C281.342 260.348 280.515 262.356 278.869 263.807L268.536 273.193H280.943C281.139 273.193 281.342 273.359 281.342 273.592C281.342 273.825 281.146 274.021 280.943 274.021H267.514C267.386 274.021 267.251 273.99 267.183 273.855C267.048 273.69 267.048 273.426 267.213 273.261H267.228Z" fill="#E71D36"/>
</g>
<g opacity="0.7">
<path d="M481.451 213.538C481.406 213.508 481.406 213.425 481.451 213.38C481.496 213.335 481.564 213.335 481.609 213.38C481.962 213.726 482.42 213.907 482.871 213.907C483.322 213.907 483.796 213.726 484.141 213.38C484.494 213.027 484.667 212.568 484.667 212.109C484.667 211.65 484.494 211.192 484.141 210.846C483.796 210.5 483.345 210.327 482.879 210.327C482.413 210.327 481.969 210.5 481.609 210.846C481.586 210.891 481.503 210.891 481.458 210.853C481.451 210.853 481.443 210.846 481.443 210.838C481.436 210.838 481.436 210.831 481.436 210.831C481.421 210.808 481.413 210.778 481.421 210.748L481.721 208.198C481.729 208.146 481.766 208.108 481.796 208.101C481.812 208.093 481.827 208.086 481.857 208.086H484.773C484.833 208.086 484.885 208.146 484.885 208.198C484.885 208.266 484.833 208.311 484.773 208.311H481.932L481.669 210.507C482.022 210.237 482.45 210.109 482.871 210.109C483.39 210.109 483.908 210.297 484.307 210.695C484.69 211.086 484.885 211.598 484.885 212.117C484.885 212.636 484.69 213.155 484.292 213.546C483.901 213.944 483.382 214.14 482.864 214.14C482.345 214.14 481.842 213.944 481.451 213.546V213.538Z" fill="#5EB0EF"/>
<path d="M486.441 208.838L487.65 208.123C487.703 208.086 487.771 208.101 487.808 208.161C487.831 208.214 487.816 208.274 487.756 208.311L486.546 209.041C486.546 209.041 486.516 209.048 486.493 209.048C486.456 209.048 486.418 209.041 486.395 208.996C486.373 208.951 486.38 208.883 486.441 208.853V208.838ZM487.598 208.206C487.598 208.153 487.65 208.093 487.703 208.093C487.771 208.093 487.816 208.153 487.816 208.206V214.027C487.816 214.087 487.771 214.14 487.703 214.14C487.65 214.14 487.598 214.087 487.598 214.027V208.206Z" fill="#5EB0EF"/>
</g>
<g opacity="0.9">
<path d="M278.749 307.639C279.147 308.037 279.35 308.564 279.35 309.09C279.35 309.617 279.147 310.151 278.749 310.557C278.343 310.955 277.809 311.158 277.291 311.158H277.283C276.765 311.158 276.231 310.963 275.833 310.557C275.788 310.512 275.788 310.444 275.833 310.399C275.878 310.354 275.945 310.354 275.99 310.399C276.351 310.752 276.81 310.933 277.283 310.933C277.757 310.933 278.23 310.76 278.583 310.391C278.944 310.038 279.124 309.571 279.124 309.09C279.124 308.609 278.944 308.165 278.591 307.797C278.23 307.443 277.772 307.263 277.298 307.263C277.253 307.263 277.215 307.248 277.193 307.21C277.193 307.202 277.185 307.202 277.185 307.202C277.14 307.157 277.14 307.075 277.185 307.037L278.967 305.194H276.238C276.178 305.194 276.126 305.142 276.126 305.082C276.126 305.021 276.178 304.969 276.238 304.969H279.237C279.297 304.969 279.35 305.021 279.35 305.082C279.35 305.097 279.35 305.119 279.342 305.134C279.335 305.149 279.327 305.172 279.312 305.179L277.494 307.044C277.952 307.09 278.403 307.285 278.749 307.639Z" fill="#E71D36"/>
</g>
<g opacity="0.58">
<path d="M322.12 138.608H315.859C315.814 138.608 315.784 138.593 315.739 138.563C315.664 138.488 315.649 138.375 315.709 138.3L322.172 130.253L322.202 130.223C322.247 130.193 322.277 130.178 322.322 130.178C322.383 130.178 322.443 130.223 322.473 130.268C322.488 130.283 322.488 130.298 322.503 130.313C322.503 130.328 322.518 130.343 322.518 130.358V138.217H323.45C323.57 138.217 323.645 138.293 323.645 138.405C323.645 138.526 323.57 138.601 323.45 138.601H322.518V140.684C322.518 140.789 322.443 140.88 322.322 140.88C322.232 140.88 322.12 140.789 322.12 140.684V138.601V138.608ZM322.12 138.225V130.96L316.258 138.225H322.12Z" fill="#5EB0EF"/>
<path d="M329.439 130.163C331.251 130.163 332.731 131.554 332.889 133.351C332.934 133.645 332.949 133.953 332.949 134.254C332.949 135.698 332.641 137.292 331.904 138.578C331.138 139.864 329.943 140.812 328.184 140.895C328.064 140.895 327.981 140.82 327.981 140.699C327.966 140.609 328.057 140.511 328.147 140.511C329.785 140.436 330.845 139.579 331.544 138.398C332.062 137.48 332.385 136.345 332.491 135.254C331.927 136.36 330.762 137.112 329.424 137.112C327.508 137.112 325.93 135.563 325.93 133.645C325.93 131.727 327.508 130.17 329.424 130.17L329.439 130.163ZM329.439 136.721C331.138 136.721 332.521 135.337 332.521 133.637C332.521 133.562 332.521 133.487 332.506 133.404V133.329C332.355 131.749 331.033 130.539 329.439 130.539C327.726 130.539 326.328 131.915 326.328 133.63C326.328 135.344 327.718 136.713 329.439 136.713V136.721Z" fill="#5EB0EF"/>
</g>
<g opacity="0.82">
<path d="M355.382 124.86H353.541C353.541 124.86 353.519 124.86 353.504 124.845C353.481 124.823 353.474 124.793 353.496 124.77L355.397 122.408H355.405C355.405 122.408 355.427 122.386 355.443 122.386C355.458 122.386 355.48 122.401 355.488 122.416C355.488 122.416 355.488 122.424 355.495 122.431C355.495 122.431 355.495 122.439 355.495 122.446V124.755H355.773C355.773 124.755 355.833 124.778 355.833 124.808C355.833 124.845 355.811 124.868 355.773 124.868H355.495V125.477C355.495 125.477 355.473 125.537 355.435 125.537C355.405 125.537 355.375 125.507 355.375 125.477V124.868L355.382 124.86ZM355.382 124.747V122.612L353.661 124.747H355.382Z" fill="#5EB0EF"/>
<path d="M356.84 125.447L358.08 122.499H356.42C356.42 122.499 356.359 122.469 356.359 122.439C356.359 122.409 356.389 122.378 356.42 122.378H358.171C358.171 122.378 358.231 122.409 358.231 122.439C358.231 122.454 358.223 122.469 358.216 122.476L356.946 125.485C356.946 125.485 356.916 125.522 356.893 125.522C356.893 125.522 356.878 125.522 356.87 125.522C356.84 125.507 356.833 125.477 356.84 125.447Z" fill="#5EB0EF"/>
</g>
<g opacity="0.98">
<path d="M455.817 246.081C455.817 246.081 455.779 245.99 455.817 245.953C455.854 245.915 455.907 245.915 455.944 245.953C456.23 246.231 456.606 246.382 456.974 246.382C457.342 246.382 457.725 246.231 458.011 245.953C458.297 245.675 458.439 245.291 458.439 244.915C458.439 244.539 458.297 244.163 458.011 243.885C457.733 243.606 457.365 243.463 456.981 243.463C456.598 243.463 456.237 243.606 455.944 243.885C455.922 243.922 455.862 243.922 455.824 243.892L455.809 243.885V243.87C455.794 243.847 455.786 243.825 455.794 243.802L456.042 241.719C456.049 241.674 456.08 241.651 456.11 241.643C456.125 241.643 456.14 241.628 456.162 241.628H458.545C458.597 241.628 458.635 241.681 458.635 241.719C458.635 241.779 458.59 241.809 458.545 241.809H456.222L456.012 243.599C456.305 243.381 456.651 243.275 456.989 243.275C457.41 243.275 457.838 243.433 458.161 243.757C458.469 244.073 458.635 244.494 458.635 244.915C458.635 245.336 458.469 245.765 458.146 246.081C457.831 246.404 457.41 246.57 456.981 246.57C456.553 246.57 456.147 246.404 455.824 246.081H455.817Z" fill="#5EB0EF"/>
<path d="M462.941 245.517H460.055C460.055 245.517 460.018 245.517 460.003 245.494C459.965 245.457 459.957 245.411 459.988 245.374L462.964 241.666L462.979 241.651C462.979 241.651 463.016 241.628 463.031 241.628C463.061 241.628 463.091 241.651 463.099 241.674C463.106 241.681 463.106 241.689 463.114 241.696C463.114 241.696 463.121 241.711 463.121 241.719V245.344H463.55C463.61 245.344 463.64 245.381 463.64 245.426C463.64 245.487 463.602 245.517 463.55 245.517H463.121V246.479C463.121 246.532 463.084 246.57 463.031 246.57C462.986 246.57 462.941 246.524 462.941 246.479V245.517ZM462.941 245.336V241.989L460.243 245.336H462.941Z" fill="#5EB0EF"/>
</g>
<g opacity="0.96">
<path d="M386.187 173.67C386.142 173.64 386.142 173.565 386.187 173.52C386.232 173.475 386.3 173.475 386.338 173.52C386.676 173.851 387.119 174.031 387.555 174.031C387.991 174.031 388.442 173.858 388.78 173.52C389.118 173.182 389.291 172.738 389.291 172.294C389.291 171.85 389.126 171.414 388.78 171.076C388.449 170.745 388.013 170.579 387.563 170.579C387.112 170.579 386.691 170.745 386.338 171.076C386.315 171.121 386.24 171.121 386.195 171.083C386.195 171.083 386.18 171.076 386.18 171.068C386.172 171.068 386.172 171.061 386.172 171.061C386.157 171.038 386.15 171.008 386.157 170.978L386.45 168.526C386.458 168.473 386.495 168.443 386.525 168.436C386.54 168.428 386.555 168.421 386.586 168.421H389.396C389.456 168.421 389.501 168.481 389.501 168.534C389.501 168.601 389.449 168.639 389.396 168.639H386.661L386.413 170.752C386.751 170.497 387.164 170.369 387.57 170.369C388.074 170.369 388.57 170.549 388.953 170.933C389.321 171.309 389.509 171.798 389.509 172.294C389.509 172.79 389.314 173.294 388.938 173.67C388.562 174.054 388.066 174.242 387.563 174.242C387.059 174.242 386.578 174.054 386.195 173.67H386.187Z" fill="#5EB0EF"/>
<path d="M392.988 168.413C393.619 168.413 394.138 168.932 394.138 169.572C394.138 170.03 393.86 170.429 393.484 170.609C394.266 170.828 394.844 171.542 394.844 172.392C394.844 173.415 394.01 174.242 392.988 174.242C391.966 174.242 391.132 173.415 391.132 172.392C391.132 171.542 391.718 170.828 392.5 170.609C392.101 170.429 391.831 170.03 391.831 169.572C391.831 168.932 392.349 168.413 392.988 168.413ZM392.988 174.031C393.905 174.031 394.634 173.302 394.634 172.392C394.634 171.482 393.905 170.745 392.988 170.745C392.071 170.745 391.342 171.489 391.342 172.392C391.342 173.294 392.086 174.031 392.988 174.031ZM392.988 170.512C393.507 170.512 393.928 170.09 393.928 169.572C393.928 169.053 393.499 168.631 392.988 168.624C392.462 168.631 392.041 169.053 392.041 169.572C392.041 170.09 392.47 170.512 392.988 170.512Z" fill="#5EB0EF"/>
</g>
<g opacity="0.5">
<path d="M523.469 200.805C523.469 200.505 523.536 200.166 523.687 199.903C523.844 199.632 524.1 199.437 524.468 199.422C524.491 199.422 524.506 199.437 524.506 199.467C524.506 199.489 524.491 199.504 524.468 199.504C524.122 199.519 523.904 199.7 523.754 199.948C523.634 200.144 523.574 200.377 523.551 200.61C523.672 200.377 523.912 200.219 524.198 200.219C524.596 200.219 524.927 200.542 524.927 200.948C524.927 201.354 524.596 201.678 524.198 201.678C523.822 201.678 523.514 201.4 523.476 201.031C523.476 200.956 523.469 200.888 523.469 200.82V200.805ZM524.205 201.58C524.566 201.58 524.851 201.287 524.859 200.933C524.859 200.572 524.566 200.287 524.205 200.287C523.844 200.287 523.559 200.572 523.559 200.933C523.559 201.294 523.844 201.58 524.205 201.58Z" fill="#E71D36"/>
<path d="M526.828 201.181H525.513C525.513 201.181 525.498 201.181 525.491 201.174C525.476 201.159 525.468 201.136 525.491 201.121L526.843 199.437C526.843 199.437 526.858 199.422 526.866 199.422C526.881 199.422 526.888 199.429 526.896 199.444V199.467V201.114H527.091C527.091 201.114 527.136 201.129 527.136 201.151C527.136 201.174 527.121 201.197 527.091 201.197H526.896V201.633C526.896 201.633 526.881 201.678 526.851 201.678C526.828 201.678 526.813 201.655 526.813 201.633V201.197L526.828 201.181ZM526.828 201.099V199.572L525.596 201.099H526.828Z" fill="#E71D36"/>
</g>
<g opacity="0.55">
<path d="M566.757 158.418L570.725 154.838C571.228 154.365 571.499 153.733 571.499 153.101C571.499 152.552 571.288 151.988 570.89 151.537C570.432 151.033 569.785 150.762 569.169 150.762C568.605 150.762 568.042 150.973 567.598 151.371L567.185 151.747C567.117 151.807 567.035 151.807 566.975 151.747C566.914 151.687 566.929 151.582 566.982 151.537L567.395 151.146C567.914 150.687 568.53 150.461 569.169 150.461C569.883 150.461 570.597 150.755 571.115 151.319C571.559 151.823 571.799 152.469 571.799 153.094C571.799 153.808 571.506 154.522 570.92 155.041L567.238 158.388H571.664C571.732 158.388 571.807 158.448 571.807 158.531C571.807 158.614 571.739 158.681 571.664 158.681H566.877C566.832 158.681 566.787 158.666 566.757 158.621C566.712 158.561 566.712 158.471 566.772 158.411L566.757 158.418Z" fill="#5EB0EF"/>
<path d="M573.558 151.492L575.196 150.514C575.264 150.469 575.362 150.476 575.407 150.559C575.444 150.627 575.422 150.709 575.339 150.755L573.693 151.74C573.693 151.74 573.648 151.755 573.626 151.755C573.58 151.755 573.52 151.74 573.498 151.68C573.46 151.619 573.475 151.529 573.558 151.484V151.492ZM575.129 150.634C575.129 150.566 575.196 150.484 575.271 150.484C575.369 150.484 575.422 150.566 575.422 150.634V158.539C575.422 158.621 575.362 158.689 575.271 158.689C575.204 158.689 575.129 158.621 575.129 158.539V150.634Z" fill="#5EB0EF"/>
</g>
<g opacity="0.76">
<path d="M419.232 238.078L420.442 237.356C420.494 237.319 420.562 237.334 420.599 237.394C420.622 237.447 420.607 237.507 420.547 237.544L419.337 238.274C419.337 238.274 419.299 238.281 419.284 238.281C419.247 238.281 419.202 238.274 419.186 238.229C419.164 238.184 419.171 238.116 419.232 238.078ZM420.389 237.447C420.389 237.394 420.442 237.334 420.494 237.334C420.562 237.334 420.607 237.394 420.607 237.447V243.275C420.607 243.335 420.562 243.388 420.494 243.388C420.442 243.388 420.389 243.335 420.389 243.275V237.447Z" fill="#E71D36"/>
<path d="M422.358 241.072C422.358 240.259 422.531 239.349 422.945 238.627C423.358 237.898 424.057 237.364 425.049 237.319C425.109 237.319 425.162 237.364 425.162 237.432C425.162 237.484 425.116 237.537 425.064 237.537C424.139 237.582 423.538 238.056 423.132 238.733C422.824 239.259 422.659 239.891 422.599 240.515C422.922 239.883 423.576 239.455 424.342 239.455C425.425 239.455 426.304 240.327 426.319 241.41C426.311 242.501 425.425 243.373 424.342 243.373C423.328 243.373 422.501 242.621 422.396 241.628C422.388 241.621 422.388 241.606 422.388 241.591C422.373 241.418 422.366 241.237 422.366 241.057L422.358 241.072ZM424.342 243.17C425.312 243.17 426.078 242.388 426.101 241.418C426.086 240.455 425.312 239.673 424.342 239.673C423.373 239.673 422.599 240.455 422.599 241.418C422.599 242.38 423.38 243.17 424.342 243.17Z" fill="#E71D36"/>
</g>
<g opacity="0.5">
<path d="M555.732 162.652C555.732 161.261 556.025 159.712 556.739 158.486C557.446 157.245 558.625 156.335 560.324 156.26C560.429 156.26 560.512 156.335 560.512 156.455C560.512 156.546 560.437 156.636 560.346 156.636C558.768 156.711 557.754 157.523 557.055 158.674C556.521 159.576 556.243 160.652 556.138 161.712C556.687 160.637 557.806 159.907 559.106 159.907C560.948 159.907 562.458 161.396 562.473 163.247C562.458 165.104 560.955 166.601 559.106 166.601C557.378 166.601 555.965 165.315 555.785 163.63C555.77 163.615 555.77 163.585 555.77 163.57C555.74 163.277 555.725 162.961 555.725 162.652H555.732ZM559.106 166.225C560.76 166.225 562.075 164.894 562.105 163.247C562.075 161.607 560.76 160.276 559.106 160.276C557.453 160.276 556.138 161.607 556.138 163.247C556.138 164.886 557.468 166.225 559.106 166.225Z" fill="#E71D36"/>
<path d="M566.411 166.33L570.47 156.658H565.029C564.923 156.658 564.833 156.568 564.833 156.463C564.833 156.373 564.923 156.267 565.029 156.267H570.785C570.875 156.267 570.973 156.373 570.973 156.463C570.973 156.508 570.943 156.553 570.913 156.598L566.749 166.473C566.734 166.533 566.659 166.593 566.584 166.593C566.569 166.593 566.539 166.578 566.509 166.578C566.404 166.533 566.374 166.428 566.404 166.33H566.411Z" fill="#E71D36"/>
</g>
<g opacity="0.64">
<path d="M567.486 77.3665H566.193C566.193 77.3665 566.178 77.3665 566.17 77.359C566.155 77.3439 566.155 77.3214 566.17 77.3063L567.501 75.6518C567.501 75.6518 567.516 75.6367 567.523 75.6367C567.538 75.6367 567.546 75.6442 567.553 75.6518V75.6668V77.2913H567.749C567.749 77.2913 567.786 77.3063 567.786 77.3289C567.786 77.3515 567.771 77.374 567.749 77.374H567.553V77.8027C567.553 77.8027 567.538 77.8403 567.516 77.8403C567.493 77.8403 567.471 77.8253 567.471 77.8027V77.374L567.486 77.3665ZM567.486 77.2838V75.7871L566.276 77.2838H567.486Z" fill="#5EB0EF"/>
<path d="M568.974 75.6292C569.214 75.6292 569.41 75.8248 569.41 76.0654C569.41 76.2384 569.305 76.3888 569.162 76.4565C569.455 76.5392 569.68 76.81 569.68 77.1334C569.68 77.5245 569.365 77.8328 568.974 77.8328C568.583 77.8328 568.268 77.5245 568.268 77.1334C568.268 76.81 568.485 76.5392 568.786 76.4565C568.636 76.3888 568.538 76.2384 568.538 76.0654C568.538 75.8248 568.733 75.6292 568.974 75.6292ZM568.974 77.7576C569.32 77.7576 569.598 77.4793 569.598 77.1334C569.598 76.7874 569.32 76.5091 568.974 76.5091C568.628 76.5091 568.35 76.7874 568.35 77.1334C568.35 77.4793 568.628 77.7576 568.974 77.7576ZM568.974 76.4189C569.169 76.4189 569.327 76.261 569.327 76.0654C569.327 75.8699 569.169 75.7044 568.974 75.7044C568.779 75.7044 568.613 75.8624 568.613 76.0654C568.613 76.2685 568.771 76.4189 568.974 76.4189Z" fill="#5EB0EF"/>
</g>
<g opacity="0.53">
<path d="M496.188 146.513C499.712 146.513 502.591 149.393 502.591 152.966C502.591 155.515 501.058 157.749 498.923 158.764C503.282 159.975 506.529 163.961 506.529 168.699C506.529 174.407 501.892 179.003 496.188 179.003C490.484 179.003 485.847 174.407 485.847 168.699C485.847 163.961 489.093 159.975 493.452 158.764C491.228 157.741 489.74 155.515 489.74 152.966C489.74 149.393 492.618 146.513 496.188 146.513ZM496.188 177.844C501.291 177.844 505.371 173.761 505.371 168.699C505.371 163.638 501.291 159.509 496.188 159.509C491.085 159.509 487.004 163.638 487.004 168.699C487.004 173.761 491.13 177.844 496.188 177.844ZM496.188 158.208C499.066 158.208 501.426 155.838 501.426 152.958C501.426 150.078 499.059 147.716 496.188 147.663C493.264 147.709 490.897 150.032 490.897 152.958C490.897 155.884 493.264 158.208 496.188 158.208Z" fill="#E71D36"/>
<path d="M514.788 177.942L530.464 163.781C532.456 161.923 533.531 159.418 533.531 156.914C533.531 154.733 532.696 152.507 531.118 150.739C529.307 148.746 526.759 147.678 524.302 147.678C522.077 147.678 519.845 148.513 518.087 150.093L516.463 151.582C516.185 151.815 515.862 151.815 515.629 151.582C515.396 151.349 515.441 150.935 515.674 150.747L517.298 149.213C519.342 147.4 521.799 146.52 524.302 146.52C527.135 146.52 529.961 147.678 532.005 149.912C533.771 151.905 534.695 154.462 534.695 156.922C534.695 159.757 533.538 162.585 531.216 164.63L516.651 177.859H534.139C534.417 177.859 534.695 178.093 534.695 178.416C534.695 178.739 534.417 179.018 534.139 179.018H515.216C515.028 179.018 514.848 178.973 514.75 178.785C514.562 178.551 514.562 178.183 514.795 177.95L514.788 177.942Z" fill="#E71D36"/>
</g>
<g opacity="0.57">
<path d="M606.625 104.629L607.82 101.779H606.212C606.212 101.779 606.151 101.749 606.151 101.719C606.151 101.696 606.174 101.666 606.212 101.666H607.91C607.91 101.666 607.97 101.696 607.97 101.719C607.97 101.734 607.963 101.741 607.955 101.756L606.73 104.675C606.73 104.675 606.708 104.705 606.685 104.705C606.685 104.705 606.67 104.705 606.662 104.705C606.632 104.69 606.625 104.659 606.632 104.629H606.625Z" fill="#E71D36"/>
<path d="M609.601 101.659C609.932 101.659 610.203 101.929 610.203 102.268C610.203 102.509 610.06 102.719 609.857 102.809C610.263 102.922 610.571 103.298 610.571 103.742C610.571 104.276 610.135 104.712 609.601 104.712C609.068 104.712 608.632 104.283 608.632 103.742C608.632 103.298 608.932 102.922 609.346 102.809C609.135 102.712 609 102.501 609 102.268C609 101.929 609.271 101.659 609.609 101.659H609.601ZM609.601 104.599C610.082 104.599 610.466 104.216 610.466 103.742C610.466 103.268 610.082 102.877 609.601 102.877C609.12 102.877 608.737 103.261 608.737 103.742C608.737 104.223 609.12 104.599 609.601 104.599ZM609.601 102.757C609.872 102.757 610.09 102.531 610.09 102.268C610.09 102.005 609.864 101.772 609.601 101.772C609.331 101.772 609.105 101.997 609.105 102.268C609.105 102.539 609.331 102.757 609.601 102.757Z" fill="#E71D36"/>
</g>
<g opacity="0.92">
<path d="M597.817 13.7104L601.515 11.5144C601.673 11.4091 601.883 11.4316 601.988 11.6196C602.071 11.7776 602.018 11.9656 601.83 12.0709L598.133 14.2895C598.08 14.3196 598.028 14.3196 597.975 14.3196C597.87 14.3196 597.735 14.2895 597.682 14.1617C597.599 14.0263 597.629 13.8157 597.817 13.7104ZM601.357 11.7851C601.357 11.6272 601.515 11.4391 601.673 11.4391C601.883 11.4391 602.018 11.6272 602.018 11.7851V29.5643C602.018 29.7523 601.883 29.9102 601.673 29.9102C601.515 29.9102 601.357 29.7523 601.357 29.5643V11.7851Z" fill="#5EB0EF"/>
<path d="M613.419 11.4166C616.538 11.4166 619.093 13.8232 619.356 16.9143C619.439 17.4182 619.461 17.9446 619.461 18.4711C619.461 20.9529 618.935 23.7056 617.665 25.9242C616.342 28.1428 614.283 29.7824 611.247 29.9177C611.037 29.9177 610.901 29.7824 610.901 29.5718C610.871 29.4138 611.037 29.2559 611.194 29.2559C614.02 29.1205 615.839 27.6465 617.056 25.6083C617.951 24.0214 618.507 22.066 618.695 20.1933C617.718 22.0961 615.711 23.3897 613.411 23.3897C610.112 23.3897 607.392 20.7198 607.392 17.4182C607.392 14.1165 610.112 11.4166 613.411 11.4166H613.419ZM613.419 22.7279C616.35 22.7279 618.725 20.3513 618.725 17.4182C618.725 17.2828 618.725 17.1549 618.695 17.0196V16.8917C618.432 14.1692 616.162 12.0859 613.411 12.0859C610.458 12.0859 608.053 14.4625 608.053 17.4257C608.053 20.3889 610.458 22.7354 613.411 22.7354L613.419 22.7279Z" fill="#5EB0EF"/>
</g>
<g opacity="0.85">
<path d="M652.588 32.6554L659.487 26.4282C660.367 25.6084 660.832 24.5104 660.832 23.4048C660.832 22.4422 660.464 21.4644 659.773 20.6898C658.976 19.8099 657.856 19.3436 656.774 19.3436C655.797 19.3436 654.813 19.7121 654.039 20.404L653.325 21.0583C653.204 21.1561 653.062 21.1561 652.956 21.0583C652.851 20.953 652.874 20.7725 652.979 20.6898L653.693 20.0129C654.595 19.2157 655.669 18.8246 656.774 18.8246C658.022 18.8246 659.262 19.3361 660.164 20.3138C660.938 21.1937 661.351 22.3143 661.351 23.3973C661.351 24.6457 660.84 25.8867 659.818 26.7892L653.407 32.6103H661.103C661.223 32.6103 661.343 32.7156 661.343 32.8585C661.343 33.0014 661.223 33.1217 661.103 33.1217H652.776C652.693 33.1217 652.611 33.0991 652.573 33.0164C652.49 32.9186 652.49 32.7532 652.596 32.6479L652.588 32.6554Z" fill="#E71D36"/>
<path d="M669.632 18.8247C672.045 18.8247 674.021 20.6823 674.224 23.0739C674.284 23.465 674.307 23.8711 674.307 24.2772C674.307 26.195 673.901 28.3234 672.917 30.0382C671.895 31.7529 670.301 33.0164 667.956 33.1217C667.791 33.1217 667.693 33.0164 667.693 32.8585C667.671 32.7381 667.791 32.6103 667.919 32.6103C670.106 32.505 671.511 31.3618 672.451 29.79C673.142 28.5641 673.57 27.0524 673.713 25.6009C672.954 27.075 671.406 28.0752 669.632 28.0752C667.077 28.0752 664.98 26.0145 664.98 23.4575C664.98 20.9004 667.085 18.8171 669.632 18.8171V18.8247ZM669.632 27.5714C671.902 27.5714 673.736 25.7288 673.736 23.465C673.736 23.3672 673.736 23.2619 673.713 23.1566V23.0514C673.51 20.9455 671.752 19.3361 669.632 19.3361C667.348 19.3361 665.492 21.1787 665.492 23.465C665.492 25.7513 667.348 27.5714 669.632 27.5714Z" fill="#E71D36"/>
</g>
<g opacity="0.91">
<path d="M638.633 15.1394L639.527 13.011H638.332C638.332 13.011 638.287 12.9884 638.287 12.9658C638.287 12.9433 638.309 12.9207 638.332 12.9207H639.602C639.602 12.9207 639.647 12.9433 639.647 12.9658C639.647 12.9658 639.647 12.9884 639.632 12.9959L638.715 15.1694C638.715 15.1694 638.693 15.192 638.678 15.192H638.663C638.663 15.192 638.633 15.1544 638.64 15.1318L638.633 15.1394Z" fill="#E71D36"/>
</g>
<g opacity="0.5">
<path d="M620.295 53.8866H613.78C613.735 53.8866 613.704 53.8715 613.652 53.8414C613.569 53.7587 613.554 53.6534 613.622 53.5707L620.34 45.2L620.37 45.1699C620.416 45.1399 620.453 45.1248 620.498 45.1248C620.558 45.1248 620.626 45.1699 620.656 45.2226C620.671 45.2376 620.671 45.2527 620.686 45.2677C620.686 45.2828 620.701 45.2978 620.701 45.3128V53.488H621.671C621.798 53.488 621.874 53.5707 621.874 53.6835C621.874 53.8113 621.791 53.8866 621.671 53.8866H620.701V56.0525C620.701 56.1654 620.619 56.2556 620.491 56.2556C620.393 56.2556 620.288 56.1578 620.288 56.0525V53.8866H620.295ZM620.295 53.488V45.9295L614.193 53.488H620.295Z" fill="#5EB0EF"/>
<path d="M625.48 55.9698L629.862 45.531H623.985C623.872 45.531 623.774 45.4332 623.774 45.3204C623.774 45.2226 623.872 45.1173 623.985 45.1173H630.2C630.298 45.1173 630.403 45.2301 630.403 45.3204C630.403 45.3655 630.373 45.4181 630.343 45.4633L625.849 56.1278C625.834 56.1879 625.751 56.2556 625.676 56.2556C625.661 56.2556 625.631 56.2406 625.593 56.2406C625.48 56.1955 625.45 56.0826 625.48 55.9698Z" fill="#5EB0EF"/>
</g>
<g opacity="0.86">
<path d="M620.317 132.276L623.917 123.687H619.085C618.995 123.687 618.912 123.612 618.912 123.514C618.912 123.439 618.987 123.341 619.085 123.341H624.195C624.278 123.341 624.368 123.431 624.368 123.514C624.368 123.552 624.346 123.597 624.316 123.634L620.626 132.404C620.611 132.456 620.55 132.509 620.483 132.509C620.468 132.509 620.445 132.494 620.415 132.494C620.325 132.456 620.295 132.366 620.325 132.268L620.317 132.276Z" fill="#E71D36"/>
<path d="M630.824 127.282C631.41 127.869 631.711 128.658 631.711 129.433C631.711 130.208 631.41 131.005 630.824 131.607C630.223 132.193 629.434 132.494 628.659 132.494H628.644C627.87 132.494 627.089 132.208 626.495 131.607C626.427 131.539 626.427 131.434 626.495 131.373C626.563 131.306 626.668 131.306 626.728 131.373C627.262 131.9 627.945 132.163 628.637 132.163C629.328 132.163 630.042 131.9 630.561 131.366C631.102 130.839 631.357 130.147 631.357 129.441C631.357 128.734 631.094 128.064 630.568 127.53C630.035 127.004 629.351 126.741 628.652 126.741C628.584 126.741 628.532 126.71 628.494 126.658C628.494 126.643 628.479 126.643 628.479 126.643C628.411 126.575 628.411 126.455 628.479 126.395L631.124 123.665H627.074C626.984 123.665 626.901 123.589 626.901 123.492C626.901 123.394 626.976 123.319 627.074 123.319H631.515C631.605 123.319 631.681 123.401 631.681 123.492C631.681 123.514 631.681 123.544 631.666 123.574C631.651 123.597 631.643 123.627 631.628 123.642L628.93 126.41C629.614 126.477 630.283 126.763 630.786 127.29L630.824 127.282Z" fill="#E71D36"/>
</g>
<g opacity="0.63">
<path d="M631.606 66.6269C631.606 66.6269 631.569 66.5366 631.606 66.499C631.644 66.4614 631.696 66.4614 631.734 66.499C632.027 66.7848 632.403 66.9352 632.771 66.9352C633.139 66.9352 633.53 66.7848 633.816 66.499C634.109 66.2057 634.252 65.8297 634.252 65.4536C634.252 65.0776 634.109 64.7015 633.816 64.4158C633.53 64.13 633.162 63.9871 632.779 63.9871C632.395 63.9871 632.035 64.13 631.734 64.4158C631.711 64.4534 631.651 64.4534 631.614 64.4158C631.606 64.4158 631.599 64.4158 631.599 64.4007C631.591 64.4007 631.591 64.4007 631.591 64.4007C631.576 64.3781 631.569 64.3556 631.576 64.333L631.824 62.2347C631.824 62.1971 631.862 62.167 631.884 62.1595C631.899 62.1595 631.914 62.1445 631.937 62.1445H634.334C634.387 62.1445 634.424 62.1971 634.424 62.2347C634.424 62.2949 634.379 62.325 634.334 62.325H631.997L631.787 64.13C632.08 63.9119 632.425 63.8066 632.771 63.8066C633.199 63.8066 633.62 63.9645 633.951 64.2879C634.259 64.6113 634.424 65.0249 634.424 65.4536C634.424 65.8823 634.259 66.3035 633.936 66.6269C633.613 66.9503 633.192 67.1157 632.764 67.1157C632.335 67.1157 631.929 66.9503 631.599 66.6269H631.606Z" fill="#E71D36"/>
<path d="M637.491 62.137C638.325 62.137 639.016 62.7838 639.091 63.611C639.114 63.7464 639.121 63.8893 639.121 64.0322C639.121 64.7016 638.979 65.4386 638.64 66.0327C638.287 66.6269 637.731 67.0706 636.912 67.1082C636.852 67.1082 636.822 67.0706 636.822 67.018C636.814 66.9728 636.859 66.9352 636.897 66.9352C637.656 66.8976 638.145 66.499 638.475 65.9575C638.716 65.5288 638.866 65.0024 638.911 64.4985C638.648 65.0099 638.107 65.3559 637.491 65.3559C636.604 65.3559 635.875 64.6339 635.875 63.7464C635.875 62.859 636.604 62.137 637.491 62.137ZM637.491 65.1754C638.28 65.1754 638.919 64.5361 638.919 63.7464C638.919 63.7088 638.919 63.6787 638.911 63.6411V63.611C638.843 62.874 638.227 62.3099 637.491 62.3099C636.694 62.3099 636.048 62.9492 636.048 63.7464C636.048 64.5436 636.694 65.1754 637.491 65.1754Z" fill="#E71D36"/>
</g>
<g opacity="0.53">
<path d="M730.958 120.694C731.484 120.694 731.905 121.123 731.905 121.649C731.905 122.025 731.679 122.356 731.364 122.506C732.01 122.687 732.491 123.281 732.491 123.98C732.491 124.83 731.799 125.507 730.958 125.507C730.116 125.507 729.425 124.823 729.425 123.98C729.425 123.281 729.906 122.687 730.552 122.506C730.221 122.356 730.003 122.025 730.003 121.649C730.003 121.123 730.432 120.694 730.958 120.694ZM730.958 125.342C731.717 125.342 732.318 124.733 732.318 123.988C732.318 123.243 731.709 122.627 730.958 122.627C730.206 122.627 729.597 123.243 729.597 123.988C729.597 124.733 730.206 125.342 730.958 125.342ZM730.958 122.431C731.386 122.431 731.739 122.078 731.739 121.657C731.739 121.235 731.386 120.882 730.958 120.874C730.522 120.874 730.176 121.228 730.176 121.657C730.176 122.085 730.529 122.431 730.958 122.431Z" fill="#E71D36"/>
<path d="M733.634 121.296L734.596 120.724C734.641 120.694 734.693 120.701 734.723 120.754C734.746 120.799 734.723 120.844 734.678 120.874L733.716 121.453C733.716 121.453 733.686 121.453 733.679 121.453C733.649 121.453 733.619 121.453 733.604 121.416C733.581 121.378 733.589 121.326 733.641 121.296H733.634ZM734.558 120.792C734.558 120.747 734.596 120.701 734.641 120.701C734.693 120.701 734.731 120.747 734.731 120.792V125.424C734.731 125.47 734.693 125.515 734.641 125.515C734.596 125.515 734.558 125.47 734.558 125.424V120.792Z" fill="#E71D36"/>
</g>
<g opacity="0.72">
<path d="M691.232 6.2498C691.743 6.76121 691.998 7.43809 691.998 8.09992C691.998 8.76175 691.735 9.45366 691.232 9.98012C690.713 10.4915 690.037 10.7472 689.368 10.7472H689.361C688.692 10.7472 688.015 10.4991 687.504 9.98012C687.452 9.91995 687.452 9.8297 687.504 9.77706C687.564 9.72441 687.647 9.72441 687.707 9.77706C688.173 10.2283 688.759 10.4539 689.353 10.4539C689.947 10.4539 690.563 10.2283 691.014 9.76201C691.48 9.31077 691.705 8.7091 691.705 8.09992C691.705 7.49073 691.48 6.91163 691.029 6.45286C690.563 6.00161 689.977 5.77599 689.383 5.77599C689.323 5.77599 689.278 5.75343 689.248 5.7083V5.69326C689.18 5.64061 689.18 5.53532 689.24 5.47516L691.518 3.12114H688.03C687.948 3.12114 687.88 3.05346 687.88 2.97073C687.88 2.888 687.948 2.82031 688.03 2.82031H691.856C691.938 2.82031 692.006 2.888 692.006 2.97073C692.006 2.99329 692.006 3.01585 691.991 3.03842C691.983 3.06098 691.968 3.08354 691.953 3.09858L689.624 5.48268C690.21 5.53532 690.789 5.78351 691.224 6.24228L691.232 6.2498Z" fill="#E71D36"/>
<path d="M698.837 9.07012H694.216C694.216 9.07012 694.155 9.0626 694.125 9.03252C694.073 8.97235 694.058 8.89714 694.103 8.83698L698.868 2.90306L698.89 2.8805C698.89 2.8805 698.943 2.8429 698.98 2.8429C699.025 2.8429 699.07 2.8805 699.093 2.91058C699.108 2.92562 699.108 2.93315 699.116 2.94067C699.116 2.95571 699.123 2.96323 699.123 2.97075V8.76929H699.814C699.905 8.76929 699.965 8.82194 699.965 8.90466C699.965 8.99491 699.912 9.05508 699.814 9.05508H699.123V10.5893C699.123 10.6645 699.07 10.7397 698.98 10.7397C698.913 10.7397 698.83 10.6721 698.83 10.5893V9.05508L698.837 9.07012ZM698.837 8.79185V3.42952L694.516 8.78433H698.837V8.79185Z" fill="#E71D36"/>
</g>
<g opacity="0.97">
<path d="M363.243 228.798C364.047 229.61 364.46 230.686 364.46 231.746C364.46 232.806 364.047 233.904 363.243 234.732C362.416 235.544 361.341 235.958 360.282 235.958H360.267C359.207 235.958 358.132 235.559 357.321 234.732C357.23 234.641 357.23 234.499 357.321 234.408C357.411 234.318 357.554 234.318 357.644 234.408C358.38 235.13 359.312 235.491 360.267 235.491C361.221 235.491 362.19 235.13 362.904 234.393C363.641 233.671 364.002 232.724 364.002 231.753C364.002 230.783 363.641 229.866 362.927 229.129C362.19 228.407 361.259 228.053 360.304 228.053C360.214 228.053 360.139 228.016 360.086 227.94C360.086 227.925 360.071 227.925 360.071 227.925C359.981 227.835 359.981 227.67 360.071 227.587L363.701 223.849H358.147C358.019 223.849 357.914 223.744 357.914 223.616C357.914 223.488 358.019 223.383 358.147 223.383H364.235C364.362 223.383 364.468 223.488 364.468 223.616C364.468 223.654 364.468 223.691 364.453 223.721C364.438 223.759 364.415 223.789 364.4 223.812L360.702 227.602C361.634 227.692 362.551 228.083 363.25 228.805L363.243 228.798Z" fill="#5EB0EF"/>
<path d="M368.053 231.174C368.053 229.482 368.413 227.595 369.278 226.105C370.142 224.594 371.577 223.481 373.644 223.39C373.772 223.39 373.877 223.481 373.877 223.624C373.877 223.729 373.787 223.842 373.682 223.842C371.758 223.932 370.518 224.917 369.676 226.323C369.03 227.422 368.692 228.73 368.564 230.024C369.225 228.708 370.593 227.828 372.171 227.828C374.418 227.828 376.244 229.64 376.267 231.889C376.252 234.153 374.418 235.973 372.171 235.973C370.067 235.973 368.346 234.408 368.128 232.363C368.113 232.348 368.113 232.31 368.113 232.287C368.075 231.926 368.06 231.55 368.06 231.174H368.053ZM372.164 235.521C374.178 235.521 375.771 233.904 375.808 231.889C375.771 229.896 374.178 228.279 372.164 228.279C370.149 228.279 368.556 229.896 368.556 231.889C368.556 233.882 370.172 235.521 372.164 235.521Z" fill="#5EB0EF"/>
</g>
<g opacity="0.71">
<path d="M354.172 122.424C354.473 122.724 354.631 123.131 354.631 123.529C354.631 123.928 354.473 124.341 354.172 124.65C353.864 124.951 353.458 125.109 353.06 125.109C352.662 125.109 352.256 124.958 351.955 124.65C351.925 124.62 351.925 124.56 351.955 124.529C351.985 124.499 352.045 124.499 352.075 124.529C352.353 124.8 352.707 124.936 353.06 124.936C353.413 124.936 353.781 124.8 354.052 124.522C354.33 124.251 354.465 123.89 354.465 123.529C354.465 123.168 354.33 122.822 354.059 122.544C353.781 122.273 353.428 122.138 353.075 122.138C353.045 122.138 353.015 122.123 352.992 122.1C352.955 122.055 352.955 121.995 352.992 121.965L354.36 120.558H352.271C352.226 120.558 352.181 120.521 352.181 120.468C352.181 120.416 352.218 120.378 352.271 120.378H354.563C354.608 120.378 354.653 120.416 354.653 120.468C354.653 120.483 354.653 120.498 354.653 120.513C354.653 120.528 354.638 120.536 354.631 120.543L353.24 121.972C353.593 122.002 353.939 122.153 354.202 122.424H354.172Z" fill="#E71D36"/>
<path d="M356.539 125.003L358.396 120.574H355.901C355.856 120.574 355.81 120.536 355.81 120.483C355.81 120.446 355.856 120.393 355.901 120.393H358.531C358.569 120.393 358.621 120.438 358.621 120.483C358.621 120.506 358.606 120.521 358.591 120.543L356.682 125.063C356.682 125.063 356.645 125.116 356.607 125.116C356.607 125.116 356.585 125.116 356.577 125.116C356.532 125.094 356.517 125.048 356.532 125.003H356.539Z" fill="#E71D36"/>
</g>
<g opacity="0.74">
<path d="M428.513 185.959C428.378 185.847 428.378 185.598 428.513 185.463C428.648 185.328 428.874 185.328 429.009 185.463C430.144 186.569 431.609 187.155 433.045 187.155C434.48 187.155 436.006 186.576 437.111 185.463C438.245 184.327 438.802 182.861 438.802 181.394C438.802 179.928 438.245 178.461 437.111 177.348C436.006 176.243 434.563 175.686 433.067 175.686C431.572 175.686 430.167 176.243 429.002 177.348C428.919 177.483 428.671 177.483 428.528 177.378C428.498 177.378 428.476 177.348 428.476 177.326C428.446 177.326 428.446 177.295 428.446 177.295C428.393 177.213 428.363 177.13 428.393 177.017L429.362 168.85C429.392 168.684 429.498 168.571 429.61 168.549C429.663 168.519 429.723 168.496 429.806 168.496H439.162C439.358 168.496 439.523 168.692 439.523 168.857C439.523 169.083 439.358 169.218 439.162 169.218H430.061L429.235 176.25C430.369 175.393 431.722 174.972 433.082 174.972C434.743 174.972 436.404 175.581 437.674 176.859C438.892 178.108 439.531 179.74 439.531 181.402C439.531 183.064 438.892 184.726 437.622 185.967C436.374 187.238 434.713 187.877 433.052 187.877C431.392 187.877 429.791 187.238 428.513 185.967V185.959Z" fill="#5EB0EF"/>
</g>
<path d="M352.97 132.945L353.737 132.486C353.737 132.486 353.812 132.471 353.834 132.509C353.849 132.539 353.834 132.584 353.804 132.599L353.038 133.058C353.038 133.058 353.015 133.058 353.008 133.058C352.985 133.058 352.955 133.058 352.947 133.028C352.932 132.998 352.932 132.953 352.978 132.938L352.97 132.945ZM353.706 132.547C353.706 132.547 353.737 132.479 353.774 132.479C353.819 132.479 353.849 132.516 353.849 132.547V136.247C353.849 136.284 353.819 136.314 353.774 136.314C353.744 136.314 353.706 136.284 353.706 136.247V132.547Z" fill="#E71D36"/>
<path d="M354.962 135.938C354.962 135.938 354.931 135.863 354.962 135.841C354.992 135.811 355.029 135.811 355.059 135.841C355.285 136.059 355.578 136.179 355.863 136.179C356.149 136.179 356.45 136.066 356.675 135.841C356.9 135.615 357.013 135.322 357.013 135.036C357.013 134.75 356.9 134.449 356.675 134.231C356.457 134.013 356.171 133.9 355.871 133.9C355.57 133.9 355.292 134.013 355.067 134.231C355.052 134.261 354.999 134.261 354.977 134.231C354.977 134.231 354.969 134.231 354.969 134.224C354.969 134.224 354.954 134.186 354.954 134.164L355.149 132.539C355.149 132.539 355.179 132.486 355.202 132.479C355.21 132.479 355.225 132.464 355.24 132.464H357.096C357.133 132.464 357.171 132.501 357.171 132.539C357.171 132.584 357.141 132.607 357.096 132.607H355.285L355.119 134.006C355.345 133.833 355.615 133.75 355.886 133.75C356.217 133.75 356.547 133.87 356.803 134.126C357.043 134.374 357.171 134.698 357.171 135.028C357.171 135.359 357.043 135.69 356.795 135.938C356.547 136.194 356.217 136.315 355.886 136.315C355.555 136.315 355.24 136.187 354.984 135.938H354.962Z" fill="#E71D36"/>
<g opacity="0.54">
<path d="M469.531 77.6974C470.531 77.6974 471.343 78.5097 471.343 79.525C471.343 80.247 470.907 80.8787 470.306 81.1645C471.538 81.503 472.462 82.6386 472.462 83.9773C472.462 85.5943 471.147 86.8954 469.531 86.8954C467.916 86.8954 466.601 85.5943 466.601 83.9773C466.601 82.6386 467.517 81.503 468.757 81.1645C468.126 80.8787 467.705 80.247 467.705 79.525C467.705 78.5097 468.517 77.6974 469.531 77.6974ZM469.531 86.572C470.974 86.572 472.132 85.4138 472.132 83.9848C472.132 82.5559 470.974 81.3826 469.531 81.3826C468.089 81.3826 466.931 82.5559 466.931 83.9848C466.931 85.4138 468.104 86.572 469.531 86.572ZM469.531 81.0141C470.343 81.0141 471.02 80.3448 471.02 79.5325C471.02 78.7203 470.351 78.0434 469.531 78.0359C468.705 78.0509 468.036 78.7052 468.036 79.5325C468.036 80.3598 468.705 81.0141 469.531 81.0141Z" fill="#E71D36"/>
<path d="M475.01 85.9854C474.942 85.9327 474.942 85.8124 475.01 85.7522C475.077 85.6845 475.183 85.6845 475.243 85.7522C475.784 86.2787 476.475 86.557 477.159 86.557C477.843 86.557 478.564 86.2787 479.091 85.7522C479.632 85.2107 479.895 84.5188 479.895 83.8194C479.895 83.1199 479.632 82.428 479.091 81.9016C478.564 81.3751 477.881 81.1119 477.174 81.1119C476.468 81.1119 475.791 81.3751 475.243 81.9016C475.205 81.9693 475.085 81.9693 475.017 81.9166C475.002 81.9166 474.995 81.9016 474.995 81.8941C474.98 81.8941 474.98 81.879 474.98 81.879C474.957 81.8414 474.942 81.7963 474.957 81.7512L475.416 77.8704C475.431 77.7877 475.483 77.7426 475.536 77.7275C475.558 77.7125 475.588 77.6974 475.626 77.6974H480.068C480.158 77.6974 480.24 77.7877 480.24 77.8704C480.24 77.9757 480.158 78.0434 480.068 78.0434H475.746L475.355 81.3826C475.897 80.9765 476.535 80.781 477.182 80.781C477.971 80.781 478.76 81.0668 479.361 81.676C479.94 82.2701 480.24 83.0447 480.24 83.8344C480.24 84.6241 479.94 85.4138 479.331 86.0004C478.737 86.6021 477.948 86.9104 477.167 86.9104C476.385 86.9104 475.619 86.6096 475.01 86.0004V85.9854Z" fill="#E71D36"/>
</g>
<g opacity="0.6">
<path d="M581.937 83.7516C582.801 83.7516 583.5 84.4585 583.5 85.331C583.5 85.9552 583.124 86.5042 582.606 86.7524C583.673 87.0457 584.47 88.0234 584.47 89.1816C584.47 90.5805 583.335 91.7011 581.937 91.7011C580.539 91.7011 579.404 90.573 579.404 89.1816C579.404 88.0234 580.201 87.0457 581.268 86.7524C580.72 86.5042 580.359 85.9552 580.359 85.331C580.359 84.4585 581.065 83.7516 581.937 83.7516ZM581.937 91.4228C583.185 91.4228 584.184 90.4225 584.184 89.1816C584.184 87.9407 583.185 86.9329 581.937 86.9329C580.689 86.9329 579.69 87.9407 579.69 89.1816C579.69 90.4225 580.697 91.4228 581.937 91.4228ZM581.937 86.617C582.643 86.617 583.222 86.0379 583.222 85.331C583.222 84.624 582.643 84.0449 581.937 84.0374C581.223 84.0449 580.644 84.6165 580.644 85.331C580.644 86.0454 581.223 86.617 581.937 86.617Z" fill="#E71D36"/>
<path d="M586.671 90.9189C586.611 90.8738 586.611 90.7685 586.671 90.7159C586.724 90.6557 586.814 90.6557 586.874 90.7159C587.34 91.1671 587.941 91.4078 588.528 91.4078C589.114 91.4078 589.745 91.1671 590.196 90.7159C590.662 90.2496 590.887 89.6479 590.887 89.0463C590.887 88.4446 590.662 87.8429 590.196 87.3842C589.745 86.9329 589.151 86.6998 588.535 86.6998C587.919 86.6998 587.34 86.9254 586.867 87.3842C586.829 87.4368 586.731 87.4368 586.671 87.3917C586.664 87.3917 586.649 87.3842 586.649 87.3691C586.634 87.3691 586.634 87.3541 586.634 87.3541C586.611 87.324 586.604 87.2864 586.611 87.2413L587.01 83.887C587.017 83.8193 587.062 83.7742 587.107 83.7591C587.13 83.7441 587.152 83.7366 587.19 83.7366H591.03C591.113 83.7366 591.181 83.8193 591.181 83.887C591.181 83.9772 591.113 84.0374 591.03 84.0374H587.295L586.957 86.9254C587.423 86.5719 587.979 86.4065 588.535 86.4065C589.219 86.4065 589.895 86.6546 590.421 87.1811C590.925 87.6925 591.181 88.3619 591.181 89.0463C591.181 89.7307 590.917 90.4075 590.399 90.9189C589.888 91.4379 589.204 91.7011 588.528 91.7011C587.851 91.7011 587.19 91.4379 586.664 90.9189H586.671Z" fill="#E71D36"/>
</g>
<g opacity="0.86">
<path d="M436.224 84.1128C436.142 84.0451 436.142 83.9022 436.224 83.8195C436.307 83.7367 436.435 83.7367 436.517 83.8195C437.179 84.4662 438.043 84.8122 438.885 84.8122C439.726 84.8122 440.621 84.4738 441.267 83.8195C441.928 83.1576 442.251 82.2927 442.251 81.4354C442.251 80.578 441.928 79.7206 441.267 79.0663C440.621 78.4195 439.779 78.0961 438.9 78.0961C438.02 78.0961 437.201 78.4195 436.517 79.0663C436.465 79.149 436.322 79.149 436.239 79.0813C436.224 79.0813 436.209 79.0663 436.209 79.0513C436.194 79.0513 436.194 79.0362 436.194 79.0362C436.164 78.9911 436.149 78.9385 436.164 78.8708L436.735 74.0875C436.75 73.9898 436.818 73.9296 436.878 73.907C436.908 73.892 436.946 73.877 436.991 73.877H442.469C442.582 73.877 442.68 73.9898 442.68 74.0875C442.68 74.2154 442.582 74.2981 442.469 74.2981H437.141L436.653 78.4195C437.314 77.9156 438.111 77.675 438.907 77.675C439.877 77.675 440.854 78.0284 441.598 78.7805C442.312 79.51 442.68 80.4652 442.68 81.4354C442.68 82.4055 442.304 83.3832 441.56 84.1128C440.831 84.8573 439.862 85.2334 438.885 85.2334C437.908 85.2334 436.976 84.8573 436.232 84.1128H436.224Z" fill="#E71D36"/>
</g>
<g opacity="0.59">
<path d="M557.648 48.0504H553.289C553.289 48.0504 553.237 48.0429 553.207 48.0203C553.154 47.9677 553.139 47.8925 553.184 47.8398L557.686 42.2368L557.708 42.2143C557.708 42.2143 557.761 42.1842 557.791 42.1842C557.836 42.1842 557.874 42.2143 557.896 42.2444C557.904 42.2594 557.904 42.2669 557.919 42.2745C557.919 42.282 557.926 42.297 557.926 42.3045V47.7797H558.58C558.663 47.7797 558.715 47.8323 558.715 47.9075C558.715 47.9903 558.663 48.0429 558.58 48.0429H557.926V49.4944C557.926 49.5696 557.874 49.6298 557.791 49.6298C557.723 49.6298 557.648 49.5696 557.648 49.4944V48.0429V48.0504ZM557.648 47.7797V42.7182L553.567 47.7797H557.648Z" fill="#E71D36"/>
<path d="M562.954 42.1691C564.42 42.1766 565.502 43.8839 565.502 45.8919C565.502 47.9 564.412 49.6298 562.954 49.6373C561.519 49.6298 560.414 47.9225 560.414 45.8919C560.414 43.8613 561.511 42.1766 562.954 42.1691ZM560.685 45.8919C560.685 46.8772 560.963 47.7496 561.376 48.3663C561.789 49.0055 562.353 49.3665 562.954 49.3665C563.556 49.3665 564.127 49.0055 564.548 48.3663C564.976 47.7496 565.239 46.8696 565.239 45.8919C565.239 44.9142 564.976 44.0568 564.548 43.4401C564.134 42.8009 563.563 42.4399 562.954 42.4399C562.346 42.4399 561.789 42.8009 561.376 43.4401C560.963 44.0568 560.685 44.9368 560.685 45.8919Z" fill="#E71D36"/>
</g>
<g opacity="0.58">
<path d="M598.802 37.3257C599.643 37.3257 600.327 38.0101 600.327 38.8675C600.327 39.4767 599.959 40.0107 599.456 40.2513C600.493 40.5371 601.267 41.4923 601.267 42.6204C601.267 43.9817 600.162 45.0797 598.802 45.0797C597.441 45.0797 596.337 43.9817 596.337 42.6204C596.337 41.4923 597.111 40.5371 598.148 40.2513C597.614 40.0107 597.261 39.4767 597.261 38.8675C597.261 38.0177 597.945 37.3257 598.802 37.3257ZM598.802 44.8014C600.019 44.8014 600.996 43.8237 600.996 42.6204C600.996 41.4171 600.019 40.4243 598.802 40.4243C597.584 40.4243 596.615 41.4095 596.615 42.6204C596.615 43.8312 597.599 44.8014 598.802 44.8014ZM598.802 40.116C599.486 40.116 600.049 39.5519 600.049 38.8675C600.049 38.1831 599.486 37.6191 598.802 37.604C598.103 37.6115 597.539 38.1681 597.539 38.8675C597.539 39.5669 598.103 40.116 598.802 40.116Z" fill="#5EB0EF"/>
<path d="M607.903 43.4251H603.378C603.378 43.4251 603.326 43.4176 603.288 43.395C603.236 43.3424 603.221 43.2596 603.266 43.207L607.933 37.3934L607.955 37.3708C607.955 37.3708 608.008 37.3408 608.045 37.3408C608.091 37.3408 608.136 37.3708 608.158 37.4084C608.173 37.4235 608.173 37.431 608.181 37.4385C608.181 37.4385 608.196 37.4611 608.196 37.4686V43.1468H608.872C608.962 43.1468 609.015 43.1995 609.015 43.2822C609.015 43.3724 608.962 43.4251 608.872 43.4251H608.196V44.9293C608.196 45.0045 608.143 45.0721 608.053 45.0721C607.985 45.0721 607.91 45.0045 607.91 44.9293V43.4251H607.903ZM607.903 43.1468V37.8973L603.664 43.1468H607.903Z" fill="#5EB0EF"/>
</g>
<g opacity="0.66">
<path d="M540.272 132.577C540.272 131.389 540.528 130.057 541.137 129.004C541.745 127.937 542.752 127.154 544.21 127.094C544.301 127.094 544.376 127.154 544.376 127.26C544.376 127.335 544.316 127.41 544.24 127.41C542.888 127.47 542.016 128.17 541.415 129.155C540.956 129.93 540.716 130.855 540.633 131.765C541.099 130.84 542.061 130.223 543.181 130.223C544.759 130.223 546.052 131.501 546.067 133.088C546.052 134.683 544.767 135.961 543.181 135.961C541.7 135.961 540.483 134.856 540.333 133.412C540.317 133.397 540.318 133.374 540.318 133.359C540.295 133.103 540.28 132.84 540.28 132.577H540.272ZM543.173 135.638C544.594 135.638 545.713 134.495 545.744 133.081C545.721 131.674 544.594 130.531 543.173 130.531C541.753 130.531 540.626 131.674 540.626 133.081C540.626 134.487 541.768 135.638 543.173 135.638Z" fill="#5EB0EF"/>
<path d="M551.425 127.087C553.161 127.102 554.446 129.125 554.446 131.509C554.446 133.893 553.153 135.939 551.425 135.954C549.719 135.939 548.411 133.916 548.411 131.509C548.411 129.102 549.711 127.102 551.425 127.087ZM548.727 131.509C548.727 132.675 549.057 133.713 549.546 134.45C550.042 135.209 550.711 135.638 551.417 135.638C552.124 135.638 552.808 135.209 553.304 134.45C553.807 133.713 554.13 132.675 554.13 131.509C554.13 130.343 553.815 129.328 553.304 128.598C552.808 127.839 552.139 127.41 551.417 127.41C550.696 127.41 550.034 127.839 549.546 128.598C549.05 129.335 548.727 130.373 548.727 131.509Z" fill="#5EB0EF"/>
</g>
<g opacity="0.97">
<path d="M514.217 24.9691C515.352 24.9691 516.269 25.8941 516.269 27.0373C516.269 27.857 515.781 28.5715 515.097 28.8949C516.494 29.286 517.539 30.5645 517.539 32.0837C517.539 33.9113 516.051 35.3854 514.217 35.3854C512.384 35.3854 510.903 33.9113 510.903 32.0837C510.903 30.5645 511.948 29.286 513.346 28.8949C512.632 28.564 512.158 27.8495 512.158 27.0373C512.158 25.8941 513.083 24.9691 514.225 24.9691H514.217ZM514.217 35.0169C515.856 35.0169 517.163 33.7082 517.163 32.0837C517.163 30.4592 515.856 29.1356 514.217 29.1356C512.579 29.1356 511.271 30.4592 511.271 32.0837C511.271 33.7082 512.594 35.0169 514.217 35.0169ZM514.217 28.7219C515.142 28.7219 515.901 27.9623 515.901 27.0373C515.901 26.1122 515.142 25.3526 514.217 25.3376C513.278 25.3526 512.519 26.0972 512.519 27.0373C512.519 27.9774 513.278 28.7219 514.217 28.7219Z" fill="#5EB0EF"/>
<path d="M521.86 35.1222L525.956 25.3677H520.47C520.364 25.3677 520.274 25.2775 520.274 25.1722C520.274 25.0819 520.364 24.9766 520.47 24.9766H526.271C526.362 24.9766 526.467 25.0819 526.467 25.1722C526.467 25.2173 526.437 25.2624 526.407 25.3075L522.213 35.2651C522.198 35.3253 522.123 35.3854 522.048 35.3854C522.033 35.3854 522.003 35.3704 521.973 35.3704C521.867 35.3253 521.837 35.22 521.867 35.1147L521.86 35.1222Z" fill="#5EB0EF"/>
</g>
<g opacity="0.88">
<path d="M604.822 56.2708L607.204 50.6001H604.01C603.95 50.6001 603.897 50.5474 603.897 50.4873C603.897 50.4346 603.95 50.3745 604.01 50.3745H607.385C607.437 50.3745 607.497 50.4346 607.497 50.4873C607.497 50.5098 607.482 50.5399 607.46 50.5625L605.017 56.3535C605.017 56.3535 604.965 56.4212 604.92 56.4212C604.912 56.4212 604.897 56.4137 604.874 56.4137C604.814 56.3911 604.799 56.3309 604.814 56.2632L604.822 56.2708Z" fill="#E71D36"/>
<path d="M610.744 50.3669C611.405 50.3669 611.939 50.9009 611.939 51.5703C611.939 52.0441 611.653 52.4652 611.255 52.6533C612.067 52.8789 612.675 53.6235 612.675 54.5034C612.675 55.5713 611.811 56.4287 610.744 56.4287C609.677 56.4287 608.812 55.5713 608.812 54.5034C608.812 53.6235 609.421 52.8789 610.233 52.6533C609.819 52.4652 609.541 52.0441 609.541 51.5703C609.541 50.9084 610.075 50.3669 610.744 50.3669ZM610.744 56.2106C611.698 56.2106 612.457 55.451 612.457 54.5034C612.457 53.5558 611.698 52.7886 610.744 52.7886C609.789 52.7886 609.03 53.5558 609.03 54.5034C609.03 55.451 609.797 56.2106 610.744 56.2106ZM610.744 52.548C611.277 52.548 611.721 52.1042 611.721 51.5703C611.721 51.0363 611.277 50.5926 610.744 50.585C610.203 50.5926 609.759 51.0288 609.759 51.5703C609.759 52.1118 610.203 52.548 610.744 52.548Z" fill="#E71D36"/>
</g>
<g opacity="0.84">
<path d="M625.12 68.8304C625.842 68.8304 626.375 69.6802 626.375 70.6655C626.375 71.6507 625.842 72.5081 625.12 72.5156C624.406 72.5156 623.865 71.6657 623.865 70.6655C623.865 69.6652 624.406 68.8304 625.12 68.8304ZM624 70.6655C624 71.1468 624.136 71.583 624.339 71.8838C624.541 72.1997 624.82 72.3802 625.12 72.3802C625.421 72.3802 625.699 72.1997 625.902 71.8838C626.112 71.5755 626.24 71.1468 626.24 70.6655C626.24 70.1841 626.105 69.763 625.902 69.4546C625.699 69.1387 625.421 68.9582 625.12 68.9582C624.82 68.9582 624.549 69.1387 624.339 69.4546C624.136 69.763 624 70.1917 624 70.6655Z" fill="#5EB0EF"/>
</g>
<g opacity="0.58">
<path d="M704.18 101.117H692.937C692.855 101.117 692.802 101.087 692.72 101.035C692.584 100.899 692.554 100.704 692.667 100.568L704.263 86.1283L704.316 86.0756C704.398 86.023 704.451 85.9929 704.534 85.9929C704.646 85.9929 704.751 86.0756 704.812 86.1584C704.842 86.1885 704.842 86.211 704.864 86.2411C704.864 86.2712 704.894 86.2938 704.894 86.3238V100.433H706.57C706.788 100.433 706.931 100.568 706.931 100.764C706.931 100.982 706.796 101.125 706.57 101.125H704.894V104.863C704.894 105.058 704.759 105.224 704.534 105.224C704.368 105.224 704.173 105.058 704.173 104.863V101.125L704.18 101.117ZM704.18 100.433V87.3993L693.659 100.433H704.18Z" fill="#5EB0EF"/>
</g>
<g opacity="0.88">
<path d="M584.274 135.6L592.105 128.531C593.105 127.606 593.631 126.35 593.631 125.101C593.631 124.011 593.218 122.898 592.428 122.018C591.527 121.017 590.249 120.483 589.024 120.483C587.912 120.483 586.8 120.905 585.92 121.687L585.109 122.431C584.973 122.544 584.808 122.544 584.695 122.431C584.583 122.318 584.605 122.108 584.718 122.018L585.529 121.25C586.552 120.348 587.777 119.904 589.032 119.904C590.444 119.904 591.857 120.483 592.879 121.596C593.759 122.597 594.225 123.868 594.225 125.101C594.225 126.515 593.646 127.929 592.489 128.952L585.214 135.563H593.947C594.082 135.563 594.225 135.675 594.225 135.841C594.225 136.006 594.089 136.142 593.947 136.142H584.492C584.402 136.142 584.304 136.119 584.259 136.029C584.169 135.909 584.169 135.728 584.282 135.615L584.274 135.6Z" fill="#5EB0EF"/>
<path d="M603.258 119.897C605.016 119.897 606.459 121.333 606.459 123.116C606.459 124.394 605.693 125.507 604.625 126.011C606.805 126.613 608.428 128.606 608.428 130.975C608.428 133.825 606.114 136.127 603.258 136.127C600.402 136.127 598.095 133.833 598.095 130.975C598.095 128.606 599.718 126.613 601.897 126.011C600.785 125.5 600.041 124.387 600.041 123.116C600.041 121.333 601.477 119.897 603.258 119.897ZM603.258 135.548C605.805 135.548 607.85 133.509 607.85 130.982C607.85 128.455 605.813 126.395 603.258 126.395C600.703 126.395 598.673 128.455 598.673 130.982C598.673 133.509 600.733 135.548 603.258 135.548ZM603.258 125.74C604.693 125.74 605.881 124.56 605.881 123.123C605.881 121.687 604.701 120.506 603.258 120.476C601.8 120.498 600.62 121.657 600.62 123.123C600.62 124.59 601.8 125.74 603.258 125.74Z" fill="#5EB0EF"/>
</g>
<g opacity="0.79">
<path d="M616.906 258.881L621.723 254.527C622.339 253.955 622.663 253.188 622.663 252.413C622.663 251.744 622.407 251.06 621.919 250.518C621.362 249.901 620.581 249.578 619.822 249.578C619.138 249.578 618.454 249.834 617.913 250.322L617.417 250.781C617.334 250.849 617.229 250.849 617.161 250.781C617.086 250.706 617.101 250.578 617.176 250.526L617.673 250.052C618.296 249.495 619.055 249.224 619.822 249.224C620.694 249.224 621.558 249.585 622.189 250.27C622.73 250.879 623.016 251.669 623.016 252.421C623.016 253.293 622.663 254.158 621.949 254.79L617.47 258.859H622.843C622.926 258.859 623.016 258.926 623.016 259.032C623.016 259.137 622.933 259.22 622.843 259.22H617.026C616.966 259.22 616.913 259.205 616.883 259.144C616.823 259.069 616.823 258.956 616.898 258.889L616.906 258.881Z" fill="#E71D36"/>
<path d="M630.892 257.084H625.06C625.015 257.084 624.985 257.069 624.947 257.039C624.872 256.963 624.864 256.866 624.917 256.798L630.929 249.307L630.959 249.277C631.004 249.247 631.027 249.232 631.072 249.232C631.132 249.232 631.185 249.277 631.215 249.315C631.23 249.33 631.23 249.345 631.245 249.36C631.245 249.375 631.26 249.39 631.26 249.405V256.723H632.132C632.244 256.723 632.319 256.79 632.319 256.896C632.319 257.008 632.252 257.084 632.132 257.084H631.26V259.024C631.26 259.122 631.192 259.212 631.072 259.212C630.989 259.212 630.884 259.129 630.884 259.024V257.084H630.892ZM630.892 256.73V249.969L625.435 256.73H630.892Z" fill="#E71D36"/>
</g>
<g opacity="0.54">
<path d="M668.318 25.6459H666.191C666.191 25.6459 666.169 25.6459 666.146 25.6309C666.123 25.6083 666.116 25.5707 666.139 25.5406L668.333 22.8106H668.34C668.34 22.8106 668.363 22.788 668.386 22.788C668.408 22.788 668.431 22.8031 668.438 22.8181C668.438 22.8181 668.438 22.8256 668.446 22.8332V22.8407V25.5106H668.761C668.806 25.5106 668.829 25.5331 668.829 25.5707C668.829 25.6158 668.806 25.6384 668.761 25.6384H668.446V26.3454C668.446 26.3454 668.423 26.4131 668.378 26.4131C668.348 26.4131 668.31 26.383 668.31 26.3454V25.6384L668.318 25.6459ZM668.318 25.5181V23.0513L666.326 25.5181H668.318Z" fill="#E71D36"/>
<path d="M669.581 26.3003L671.339 24.7134C671.565 24.5028 671.685 24.2245 671.685 23.9462C671.685 23.7056 671.595 23.4499 671.414 23.2543C671.211 23.0287 670.926 22.9084 670.648 22.9084C670.4 22.9084 670.152 22.9986 669.949 23.1791L669.768 23.3446C669.768 23.3446 669.701 23.3671 669.671 23.3446C669.648 23.322 669.648 23.2694 669.671 23.2543L669.851 23.0813C670.077 22.8783 670.355 22.7805 670.633 22.7805C670.948 22.7805 671.264 22.9084 671.497 23.1566C671.692 23.3822 671.797 23.668 671.797 23.9462C671.797 24.2621 671.67 24.578 671.407 24.8111L669.776 26.2927H671.737C671.737 26.2927 671.797 26.3153 671.797 26.3529C671.797 26.3905 671.767 26.4206 671.737 26.4206H669.618C669.618 26.4206 669.58 26.4206 669.565 26.398C669.543 26.3755 669.543 26.3303 669.565 26.3078L669.581 26.3003Z" fill="#E71D36"/>
</g>
<g opacity="0.58">
<path d="M707.254 127.124L709.155 125.996C709.238 125.943 709.343 125.951 709.403 126.049C709.449 126.131 709.418 126.222 709.321 126.282L707.419 127.425C707.419 127.425 707.367 127.44 707.337 127.44C707.284 127.44 707.217 127.425 707.186 127.357C707.141 127.29 707.156 127.184 707.254 127.124ZM709.073 126.131C709.073 126.049 709.155 125.951 709.238 125.951C709.343 125.951 709.418 126.049 709.418 126.131V135.277C709.418 135.374 709.351 135.45 709.238 135.45C709.155 135.45 709.073 135.367 709.073 135.277V126.131Z" fill="#E71D36"/>
<path d="M712.161 131.825C712.161 130.546 712.432 129.117 713.085 127.989C713.739 126.846 714.822 126.003 716.385 125.936C716.482 125.936 716.565 126.003 716.565 126.116C716.565 126.199 716.497 126.282 716.415 126.282C714.964 126.349 714.025 127.102 713.386 128.162C712.898 128.989 712.642 129.982 712.544 130.96C713.048 129.967 714.078 129.298 715.272 129.298C716.971 129.298 718.354 130.666 718.369 132.366C718.354 134.081 716.971 135.45 715.272 135.45C713.687 135.45 712.379 134.269 712.221 132.72C712.206 132.705 712.206 132.682 712.206 132.667C712.176 132.396 712.169 132.11 712.169 131.825H712.161ZM715.272 135.111C716.798 135.111 718 133.885 718.031 132.366C718 130.862 716.798 129.636 715.272 129.636C713.747 129.636 712.544 130.862 712.544 132.366C712.544 133.87 713.769 135.111 715.272 135.111Z" fill="#E71D36"/>
</g>
<g opacity="0.9">
<path d="M702.79 116.64C702.7 116.573 702.7 116.415 702.79 116.332C702.873 116.249 703.015 116.249 703.098 116.332C703.804 117.024 704.714 117.385 705.616 117.385C706.517 117.385 707.457 117.024 708.148 116.332C708.855 115.625 709.2 114.715 709.2 113.797C709.2 112.88 708.855 111.97 708.148 111.278C707.457 110.586 706.563 110.24 705.631 110.24C704.699 110.24 703.819 110.586 703.098 111.278C703.045 111.368 702.895 111.368 702.805 111.293C702.79 111.293 702.767 111.278 702.767 111.255C702.752 111.255 702.752 111.24 702.752 111.24C702.715 111.188 702.7 111.135 702.715 111.067L703.316 105.983C703.331 105.878 703.399 105.81 703.474 105.795C703.511 105.78 703.541 105.765 703.594 105.765H709.418C709.539 105.765 709.644 105.885 709.644 105.991C709.644 106.134 709.539 106.216 709.418 106.216H703.752L703.233 110.593C703.94 110.06 704.781 109.796 705.631 109.796C706.668 109.796 707.697 110.172 708.494 110.97C709.253 111.744 709.651 112.759 709.651 113.797C709.651 114.835 709.253 115.866 708.464 116.64C707.69 117.437 706.653 117.829 705.623 117.829C704.594 117.829 703.594 117.43 702.797 116.64H702.79Z" fill="#E71D36"/>
<path d="M716.881 105.765C718.189 105.765 719.256 106.833 719.256 108.164C719.256 109.112 718.685 109.939 717.896 110.323C719.519 110.774 720.721 112.256 720.721 114.015C720.721 116.136 719 117.844 716.881 117.844C714.762 117.844 713.041 116.136 713.041 114.015C713.041 112.256 714.251 110.774 715.867 110.323C715.04 109.947 714.491 109.119 714.491 108.164C714.491 106.841 715.558 105.765 716.889 105.765H716.881ZM716.881 117.407C718.775 117.407 720.293 115.888 720.293 114.008C720.293 112.128 718.775 110.593 716.881 110.593C714.987 110.593 713.469 112.128 713.469 114.008C713.469 115.888 715.002 117.407 716.881 117.407ZM716.881 110.112C717.948 110.112 718.828 109.232 718.828 108.164C718.828 107.096 717.948 106.216 716.881 106.201C715.799 106.216 714.92 107.081 714.92 108.164C714.92 109.247 715.799 110.112 716.881 110.112Z" fill="#E71D36"/>
</g>
<g opacity="0.71">
<path d="M705.631 55.6313C706.165 56.1653 706.435 56.8798 706.435 57.5792C706.435 58.2786 706.165 59.0082 705.631 59.5497C705.083 60.0836 704.376 60.3544 703.67 60.3544H703.655C702.956 60.3544 702.242 60.0912 701.708 59.5497C701.648 59.4895 701.648 59.3992 701.708 59.3391C701.768 59.2789 701.866 59.2789 701.919 59.3391C702.407 59.8129 703.023 60.0536 703.655 60.0536C704.286 60.0536 704.925 59.8129 705.398 59.3316C705.887 58.8577 706.12 58.226 706.12 57.5867C706.12 56.9475 705.879 56.3383 705.406 55.8494C704.917 55.3756 704.301 55.1349 703.67 55.1349C703.61 55.1349 703.564 55.1124 703.527 55.0673C703.527 55.0522 703.512 55.0522 703.512 55.0522C703.452 54.992 703.452 54.8868 703.512 54.8266L705.909 52.3522H702.242C702.159 52.3522 702.084 52.2846 702.084 52.1943C702.084 52.1041 702.152 52.0364 702.242 52.0364H706.262C706.345 52.0364 706.42 52.1041 706.42 52.1943C706.42 52.2169 706.42 52.2394 706.405 52.2695C706.39 52.2921 706.383 52.3146 706.368 52.3297L703.925 54.8341C704.541 54.8943 705.15 55.1575 705.609 55.6313H705.631Z" fill="#5EB0EF"/>
<path d="M712.875 55.6313C713.409 56.1653 713.679 56.8798 713.679 57.5792C713.679 58.2786 713.409 59.0082 712.875 59.5497C712.327 60.0836 711.62 60.3544 710.914 60.3544H710.899C710.2 60.3544 709.486 60.0912 708.952 59.5497C708.892 59.4895 708.892 59.3992 708.952 59.3391C709.012 59.2789 709.11 59.2789 709.163 59.3391C709.651 59.8129 710.268 60.0536 710.899 60.0536C711.53 60.0536 712.169 59.8129 712.642 59.3316C713.131 58.8577 713.364 58.226 713.364 57.5867C713.364 56.9475 713.123 56.3383 712.65 55.8494C712.161 55.3756 711.545 55.1349 710.914 55.1349C710.854 55.1349 710.809 55.1124 710.771 55.0673C710.771 55.0522 710.756 55.0522 710.756 55.0522C710.696 54.992 710.696 54.8868 710.756 54.8266L713.153 52.3522H709.486C709.403 52.3522 709.328 52.2846 709.328 52.1943C709.328 52.1041 709.396 52.0364 709.486 52.0364H713.507C713.589 52.0364 713.664 52.1041 713.664 52.1943C713.664 52.2169 713.664 52.2394 713.649 52.2695C713.634 52.2921 713.627 52.3146 713.612 52.3297L711.169 54.8341C711.786 54.8943 712.394 55.1575 712.853 55.6313H712.875Z" fill="#5EB0EF"/>
</g>
<g opacity="0.85">
<path d="M735.963 79.7957C736.684 80.5177 737.053 81.4729 737.053 82.4205C737.053 83.3681 736.684 84.3383 735.963 85.0753C735.226 85.7973 734.272 86.1658 733.325 86.1658H733.31C732.363 86.1658 731.409 85.8124 730.687 85.0753C730.605 84.9926 730.605 84.8647 730.687 84.7895C730.77 84.7068 730.898 84.7068 730.973 84.7895C731.627 85.4288 732.461 85.7522 733.31 85.7522C734.159 85.7522 735.023 85.4288 735.662 84.7745C736.316 84.1352 736.639 83.2854 736.639 82.4205C736.639 81.5556 736.316 80.7434 735.677 80.0815C735.023 79.4423 734.189 79.1189 733.34 79.1189C733.257 79.1189 733.197 79.0888 733.152 79.0211C733.152 79.006 733.137 79.006 733.137 79.006C733.055 78.9233 733.055 78.7804 733.137 78.7052L736.369 75.3735H731.424C731.311 75.3735 731.213 75.2757 731.213 75.1629C731.213 75.0501 731.311 74.9523 731.424 74.9523H736.842C736.955 74.9523 737.053 75.0501 737.053 75.1629C737.053 75.193 737.053 75.2231 737.038 75.2607C737.023 75.2908 737.007 75.3208 736.992 75.3434L733.701 78.7203C734.535 78.803 735.347 79.1565 735.97 79.7957H735.963Z" fill="#E71D36"/>
<path d="M741.554 85.8951L745.951 75.4111H740.051C739.938 75.4111 739.841 75.3133 739.841 75.2005C739.841 75.1027 739.938 74.9899 740.051 74.9899H746.289C746.387 74.9899 746.499 75.1027 746.499 75.2005C746.499 75.2456 746.469 75.2983 746.432 75.3434L741.922 86.053C741.907 86.1132 741.825 86.1809 741.742 86.1809C741.727 86.1809 741.697 86.1658 741.659 86.1658C741.547 86.1207 741.517 86.0079 741.547 85.8951H741.554Z" fill="#E71D36"/>
</g>
<g opacity="0.64">
<path d="M826.259 84.2029L827.484 81.2773H825.838C825.838 81.2773 825.778 81.2472 825.778 81.2171C825.778 81.1946 825.808 81.157 825.838 81.157H827.582C827.582 81.157 827.642 81.1871 827.642 81.2171C827.642 81.2322 827.635 81.2472 827.627 81.2548L826.364 84.2405C826.364 84.2405 826.342 84.2781 826.319 84.2781C826.319 84.2781 826.304 84.2781 826.297 84.2781C826.267 84.2631 826.259 84.233 826.267 84.2029H826.259Z" fill="#E71D36"/>
<path d="M829.31 81.157C829.649 81.157 829.927 81.4352 829.927 81.7812C829.927 82.0294 829.776 82.24 829.573 82.3377C829.994 82.4506 830.302 82.8416 830.302 83.2929C830.302 83.8419 829.859 84.2856 829.31 84.2856C828.762 84.2856 828.318 83.8419 828.318 83.2929C828.318 82.8416 828.634 82.4506 829.047 82.3377C828.829 82.24 828.687 82.0294 828.687 81.7812C828.687 81.4352 828.965 81.157 829.31 81.157ZM829.31 84.1728C829.799 84.1728 830.197 83.7817 830.197 83.2929C830.197 82.804 829.806 82.4054 829.31 82.4054C828.814 82.4054 828.424 82.804 828.424 83.2929C828.424 83.7817 828.822 84.1728 829.31 84.1728ZM829.31 82.2851C829.588 82.2851 829.814 82.0595 829.814 81.7812C829.814 81.5029 829.588 81.2773 829.31 81.2698C829.032 81.2698 828.799 81.4954 828.799 81.7812C828.799 82.067 829.025 82.2851 829.31 82.2851Z" fill="#E71D36"/>
</g>
<g opacity="0.69">
<path d="M785.609 159.261L788.051 157.809C788.156 157.741 788.299 157.756 788.367 157.877C788.419 157.982 788.382 158.102 788.262 158.178L785.819 159.644C785.819 159.644 785.752 159.659 785.714 159.659C785.646 159.659 785.556 159.644 785.519 159.554C785.466 159.464 785.481 159.328 785.609 159.253V159.261ZM787.946 157.982C787.946 157.877 788.051 157.756 788.156 157.756C788.299 157.756 788.382 157.877 788.382 157.982V169.737C788.382 169.857 788.292 169.963 788.156 169.963C788.051 169.963 787.946 169.857 787.946 169.737V157.982Z" fill="#5EB0EF"/>
</g>
<g opacity="0.68">
<path d="M829.205 122.965C830.31 122.965 831.204 123.868 831.204 124.981C831.204 125.778 830.723 126.477 830.062 126.793C831.422 127.169 832.437 128.418 832.437 129.899C832.437 131.682 830.986 133.118 829.205 133.118C827.424 133.118 825.974 131.682 825.974 129.899C825.974 128.418 826.988 127.169 828.348 126.793C827.649 126.47 827.191 125.778 827.191 124.981C827.191 123.86 828.093 122.965 829.205 122.965ZM829.205 132.757C830.798 132.757 832.076 131.479 832.076 129.899C832.076 128.32 830.798 127.026 829.205 127.026C827.612 127.026 826.334 128.32 826.334 129.899C826.334 131.479 827.627 132.757 829.205 132.757ZM829.205 126.62C830.107 126.62 830.844 125.883 830.844 124.981C830.844 124.078 830.107 123.341 829.205 123.326C828.288 123.341 827.552 124.063 827.552 124.981C827.552 125.898 828.288 126.62 829.205 126.62Z" fill="#E71D36"/>
<path d="M835.24 129.245C835.24 127.884 835.533 126.357 836.225 125.154C836.916 123.935 838.081 123.033 839.749 122.965C839.847 122.965 839.937 123.04 839.937 123.153C839.937 123.236 839.862 123.326 839.779 123.326C838.231 123.394 837.232 124.198 836.548 125.327C836.029 126.214 835.751 127.267 835.646 128.312C836.18 127.252 837.284 126.545 838.562 126.545C840.373 126.545 841.854 128.012 841.869 129.824C841.854 131.652 840.373 133.118 838.562 133.118C836.864 133.118 835.473 131.855 835.3 130.2C835.285 130.185 835.285 130.155 835.285 130.14C835.255 129.847 835.24 129.546 835.24 129.237V129.245ZM838.562 132.757C840.185 132.757 841.478 131.456 841.5 129.824C841.47 128.215 840.185 126.906 838.562 126.906C836.939 126.906 835.646 128.215 835.646 129.824C835.646 131.434 836.954 132.757 838.562 132.757Z" fill="#E71D36"/>
</g>
<g opacity="0.66">
<path d="M855.253 196.285L857.914 193.886C858.252 193.57 858.432 193.149 858.432 192.721C858.432 192.352 858.289 191.976 858.026 191.675C857.718 191.337 857.282 191.156 856.869 191.156C856.493 191.156 856.117 191.299 855.817 191.562L855.539 191.81C855.494 191.848 855.441 191.848 855.396 191.81C855.358 191.773 855.366 191.698 855.403 191.668L855.681 191.412C856.027 191.104 856.441 190.953 856.869 190.953C857.35 190.953 857.831 191.149 858.177 191.525C858.477 191.863 858.635 192.299 858.635 192.713C858.635 193.194 858.44 193.676 858.041 194.022L855.576 196.263H858.537C858.582 196.263 858.635 196.3 858.635 196.361C858.635 196.421 858.59 196.458 858.537 196.458H855.328C855.328 196.458 855.268 196.451 855.253 196.421C855.223 196.383 855.223 196.315 855.261 196.278L855.253 196.285Z" fill="#5EB0EF"/>
<path d="M861.881 190.961C862.956 190.968 863.76 192.224 863.76 193.706C863.76 195.187 862.956 196.458 861.881 196.466C860.821 196.458 860.01 195.195 860.01 193.706C860.01 192.217 860.821 190.968 861.881 190.961ZM860.205 193.706C860.205 194.428 860.408 195.075 860.716 195.533C861.024 196.007 861.438 196.27 861.881 196.27C862.324 196.27 862.745 196 863.053 195.533C863.369 195.075 863.564 194.428 863.564 193.706C863.564 192.984 863.369 192.352 863.053 191.893C862.745 191.419 862.332 191.156 861.881 191.156C861.43 191.156 861.024 191.427 860.716 191.893C860.408 192.352 860.205 192.991 860.205 193.706Z" fill="#5EB0EF"/>
</g>
<g opacity="0.99">
<path d="M841.703 105.299C841.62 105.239 841.62 105.096 841.703 105.013C841.778 104.938 841.906 104.938 841.988 105.013C842.635 105.645 843.469 105.976 844.288 105.976C845.107 105.976 845.971 105.645 846.603 105.013C847.249 104.366 847.565 103.531 847.565 102.697C847.565 101.862 847.249 101.027 846.603 100.395C845.971 99.7635 845.152 99.4476 844.303 99.4476C843.454 99.4476 842.65 99.7635 841.988 100.395C841.943 100.478 841.8 100.478 841.718 100.41C841.703 100.41 841.688 100.395 841.688 100.38C841.673 100.38 841.673 100.365 841.673 100.365C841.643 100.32 841.628 100.267 841.643 100.207L842.191 95.5593C842.206 95.4616 842.274 95.4014 842.334 95.3863C842.364 95.3713 842.394 95.3563 842.447 95.3563H847.768C847.88 95.3563 847.97 95.4691 847.97 95.5593C847.97 95.6872 847.873 95.7624 847.768 95.7624H842.59L842.116 99.7634C842.762 99.2746 843.536 99.0415 844.303 99.0415C845.25 99.0415 846.189 99.3874 846.918 100.109C847.61 100.816 847.97 101.749 847.97 102.697C847.97 103.644 847.61 104.584 846.888 105.299C846.182 106.021 845.235 106.382 844.288 106.382C843.341 106.382 842.432 106.021 841.71 105.299H841.703Z" fill="#E71D36"/>
<path d="M850.819 106.021L856.14 101.215C856.816 100.583 857.177 99.7334 857.177 98.8835C857.177 98.1465 856.891 97.3869 856.357 96.7852C855.741 96.1083 854.877 95.7473 854.043 95.7473C853.284 95.7473 852.532 96.0331 851.931 96.5671L851.382 97.071C851.285 97.1537 851.179 97.1537 851.097 97.071C851.014 96.9883 851.037 96.8529 851.112 96.7852L851.66 96.2663C852.352 95.6496 853.186 95.3563 854.035 95.3563C854.997 95.3563 855.952 95.7473 856.651 96.507C857.252 97.1838 857.56 98.0487 857.56 98.8835C857.56 99.8462 857.169 100.809 856.38 101.501L851.435 105.991H857.372C857.47 105.991 857.56 106.066 857.56 106.179C857.56 106.292 857.47 106.382 857.372 106.382H850.947C850.886 106.382 850.819 106.367 850.789 106.299C850.729 106.224 850.729 106.096 850.804 106.013L850.819 106.021Z" fill="#E71D36"/>
</g>
<g opacity="0.51">
<path d="M819.803 192.826C820.953 192.833 821.809 194.18 821.809 195.759C821.809 197.338 820.953 198.7 819.803 198.707C818.668 198.7 817.804 197.353 817.804 195.759C817.804 194.165 818.668 192.833 819.803 192.826ZM818.014 195.759C818.014 196.534 818.232 197.218 818.563 197.707C818.893 198.211 819.337 198.497 819.803 198.497C820.269 198.497 820.727 198.211 821.05 197.707C821.389 197.218 821.599 196.534 821.599 195.759C821.599 194.984 821.389 194.315 821.05 193.826C820.72 193.322 820.276 193.037 819.803 193.037C819.329 193.037 818.886 193.322 818.563 193.826C818.232 194.315 818.014 194.999 818.014 195.759Z" fill="#E71D36"/>
</g>
<g opacity="0.81">
<path d="M929.331 222.766C930.15 223.578 930.563 224.669 930.563 225.744C930.563 226.82 930.142 227.918 929.331 228.76C928.497 229.58 927.407 229.994 926.34 229.994H926.325C925.25 229.994 924.168 229.595 923.349 228.76C923.259 228.67 923.259 228.527 923.349 228.429C923.439 228.339 923.582 228.339 923.672 228.429C924.416 229.159 925.355 229.52 926.317 229.52C927.279 229.52 928.256 229.159 928.985 228.414C929.729 227.692 930.09 226.73 930.09 225.744C930.09 224.759 929.729 223.842 929 223.097C928.256 222.375 927.317 222.007 926.355 222.007C926.265 222.007 926.189 221.969 926.137 221.901C926.137 221.886 926.122 221.886 926.122 221.886C926.032 221.796 926.032 221.631 926.122 221.54L929.789 217.765H924.183C924.055 217.765 923.95 217.652 923.95 217.532C923.95 217.411 924.055 217.299 924.183 217.299H930.33C930.458 217.299 930.563 217.411 930.563 217.532C930.563 217.569 930.563 217.607 930.548 217.637C930.533 217.675 930.511 217.712 930.496 217.727L926.761 221.555C927.7 221.646 928.632 222.044 929.338 222.774L929.331 222.766Z" fill="#E71D36"/>
<path d="M938.274 217.306C940.416 217.306 942.175 218.961 942.355 221.082C942.408 221.428 942.43 221.789 942.43 222.15C942.43 223.857 942.07 225.744 941.198 227.271C940.289 228.798 938.876 229.919 936.794 230.009C936.651 230.009 936.561 229.919 936.561 229.776C936.546 229.67 936.651 229.558 936.764 229.558C938.703 229.467 939.958 228.452 940.792 227.053C941.408 225.963 941.792 224.624 941.912 223.33C941.243 224.639 939.86 225.526 938.289 225.526C936.02 225.526 934.156 223.691 934.156 221.428C934.156 219.164 936.027 217.306 938.289 217.306H938.274ZM938.274 225.075C940.289 225.075 941.919 223.443 941.919 221.428C941.919 221.337 941.919 221.247 941.897 221.157V221.067C941.716 219.201 940.153 217.765 938.274 217.765C936.245 217.765 934.592 219.397 934.592 221.428C934.592 223.458 936.238 225.075 938.274 225.075Z" fill="#E71D36"/>
</g>
<g opacity="0.87">
<path d="M955.07 182.522C955.927 182.522 956.618 183.214 956.618 184.079C956.618 184.696 956.25 185.237 955.732 185.478C956.784 185.771 957.573 186.734 957.573 187.877C957.573 189.261 956.453 190.367 955.07 190.367C953.687 190.367 952.575 189.254 952.575 187.877C952.575 186.734 953.357 185.771 954.416 185.478C953.875 185.23 953.522 184.696 953.522 184.079C953.522 183.214 954.221 182.522 955.078 182.522H955.07ZM955.07 190.096C956.303 190.096 957.295 189.111 957.295 187.885C957.295 186.659 956.31 185.666 955.07 185.666C953.83 185.666 952.853 186.666 952.853 187.885C952.853 189.103 953.853 190.096 955.07 190.096ZM955.07 185.35C955.769 185.35 956.34 184.779 956.34 184.087C956.34 183.395 955.769 182.816 955.07 182.808C954.364 182.823 953.793 183.38 953.793 184.087C953.793 184.794 954.364 185.35 955.07 185.35Z" fill="#E71D36"/>
</g>
<g opacity="0.68">
<path d="M869.382 76.0578C871.088 76.0578 872.493 77.374 872.636 79.0661C872.681 79.3444 872.696 79.6302 872.696 79.9235C872.696 81.2848 872.411 82.7889 871.712 84.0073C870.99 85.2257 869.863 86.1206 868.194 86.1883C868.082 86.1883 868.007 86.1131 868.007 86.0003C867.992 85.9101 868.082 85.8273 868.164 85.8273C869.713 85.7521 870.712 84.9399 871.373 83.8268C871.862 82.9619 872.17 81.8864 872.268 80.8561C871.734 81.9015 870.629 82.6084 869.374 82.6084C867.563 82.6084 866.075 81.1494 866.075 79.3369C866.075 77.5244 867.563 76.0503 869.374 76.0503L869.382 76.0578ZM869.382 82.255C870.99 82.255 872.29 80.9539 872.29 79.3444C872.29 79.2692 872.29 79.2015 872.275 79.1263V79.0511C872.133 77.562 870.885 76.4188 869.382 76.4188C867.759 76.4188 866.443 77.7199 866.443 79.3444C866.443 80.9689 867.759 82.255 869.382 82.255Z" fill="#E71D36"/>
</g>
<g opacity="0.88">
<path d="M913.143 192.36L914.714 188.607H912.602C912.564 188.607 912.527 188.577 912.527 188.531C912.527 188.494 912.557 188.456 912.602 188.456H914.834C914.834 188.456 914.909 188.494 914.909 188.531C914.909 188.547 914.894 188.562 914.886 188.584L913.271 192.42C913.271 192.42 913.233 192.465 913.21 192.465C913.21 192.465 913.195 192.465 913.18 192.465C913.143 192.45 913.128 192.412 913.143 192.367V192.36Z" fill="#5EB0EF"/>
<path d="M915.728 192.337L917.66 190.592C917.908 190.367 918.035 190.051 918.035 189.742C918.035 189.472 917.93 189.201 917.735 188.983C917.509 188.735 917.194 188.607 916.893 188.607C916.615 188.607 916.344 188.712 916.126 188.908L915.924 189.088C915.924 189.088 915.848 189.118 915.818 189.088C915.788 189.058 915.796 189.005 915.818 188.983L916.021 188.795C916.269 188.569 916.577 188.464 916.886 188.464C917.231 188.464 917.584 188.607 917.84 188.885C918.058 189.133 918.171 189.449 918.171 189.75C918.171 190.096 918.028 190.449 917.742 190.705L915.946 192.337H918.103C918.103 192.337 918.171 192.367 918.171 192.405C918.171 192.442 918.133 192.48 918.103 192.48H915.766C915.766 192.48 915.721 192.48 915.706 192.45C915.683 192.42 915.683 192.375 915.706 192.345L915.728 192.337Z" fill="#5EB0EF"/>
</g>
<g opacity="0.83">
<path d="M898.871 110.541H895.061C895.061 110.541 895.016 110.533 894.986 110.511C894.941 110.466 894.933 110.398 894.963 110.353L898.894 105.464L898.909 105.442C898.909 105.442 898.954 105.412 898.984 105.412C899.022 105.412 899.059 105.442 899.074 105.464C899.082 105.472 899.082 105.479 899.089 105.494C899.089 105.502 899.097 105.517 899.097 105.524V110.308H899.661C899.736 110.308 899.781 110.353 899.781 110.42C899.781 110.496 899.736 110.541 899.661 110.541H899.097V111.804C899.097 111.872 899.052 111.925 898.977 111.925C898.924 111.925 898.856 111.872 898.856 111.804V110.541H898.871ZM898.871 110.308V105.893L895.309 110.308H898.871Z" fill="#E71D36"/>
<path d="M903.313 105.412C904.41 105.412 905.312 106.261 905.41 107.352C905.44 107.525 905.447 107.713 905.447 107.901C905.447 108.773 905.259 109.744 904.816 110.526C904.35 111.308 903.629 111.887 902.554 111.932C902.479 111.932 902.434 111.887 902.434 111.812C902.426 111.759 902.479 111.699 902.539 111.699C903.538 111.654 904.177 111.127 904.606 110.413C904.921 109.856 905.117 109.164 905.184 108.503C904.838 109.172 904.132 109.631 903.32 109.631C902.156 109.631 901.201 108.691 901.201 107.525C901.201 106.359 902.163 105.412 903.32 105.412H903.313ZM903.313 109.398C904.35 109.398 905.184 108.563 905.184 107.525C905.184 107.48 905.184 107.435 905.177 107.382V107.344C905.086 106.374 904.282 105.637 903.313 105.637C902.268 105.637 901.427 106.472 901.427 107.517C901.427 108.563 902.276 109.39 903.313 109.39V109.398Z" fill="#E71D36"/>
</g>
<g opacity="0.76">
<path d="M1095.86 205.13L1106.67 195.375C1108.04 194.097 1108.78 192.367 1108.78 190.637C1108.78 189.133 1108.2 187.599 1107.12 186.381C1105.87 185.004 1104.11 184.267 1102.42 184.267C1100.89 184.267 1099.36 184.846 1098.14 185.929L1097.02 186.952C1096.82 187.11 1096.61 187.11 1096.44 186.952C1096.28 186.794 1096.31 186.508 1096.47 186.373L1097.59 185.32C1099 184.072 1100.69 183.463 1102.42 183.463C1104.37 183.463 1106.32 184.26 1107.73 185.801C1108.95 187.178 1109.58 188.938 1109.58 190.637C1109.58 192.585 1108.79 194.541 1107.19 195.947L1097.15 205.062H1109.2C1109.39 205.062 1109.58 205.22 1109.58 205.446C1109.58 205.671 1109.39 205.859 1109.2 205.859H1096.15C1096.03 205.859 1095.9 205.829 1095.84 205.702C1095.71 205.544 1095.71 205.288 1095.87 205.122L1095.86 205.13Z" fill="#E71D36"/>
<path d="M1122.56 183.47C1126.33 183.47 1129.43 186.381 1129.75 190.126C1129.85 190.735 1129.88 191.374 1129.88 192.014C1129.88 195.022 1129.24 198.346 1127.7 201.039C1126.1 203.724 1123.61 205.709 1119.93 205.867C1119.68 205.867 1119.52 205.709 1119.52 205.453C1119.49 205.265 1119.68 205.07 1119.87 205.07C1123.29 204.912 1125.5 203.114 1126.97 200.655C1128.06 198.737 1128.73 196.368 1128.95 194.097C1127.77 196.398 1125.34 197.97 1122.56 197.97C1118.56 197.97 1115.27 194.736 1115.27 190.735C1115.27 186.734 1118.56 183.47 1122.56 183.47ZM1122.56 197.165C1126.1 197.165 1128.98 194.285 1128.98 190.735C1128.98 190.577 1128.98 190.412 1128.95 190.254V190.096C1128.64 186.802 1125.89 184.275 1122.56 184.275C1118.98 184.275 1116.06 187.155 1116.06 190.735C1116.06 194.315 1118.97 197.165 1122.56 197.165Z" fill="#E71D36"/>
</g>
<g opacity="0.61">
<path d="M1058.25 147.709L1060.29 146.498C1060.38 146.438 1060.49 146.453 1060.55 146.558C1060.6 146.648 1060.57 146.746 1060.46 146.806L1058.42 148.032C1058.42 148.032 1058.36 148.047 1058.33 148.047C1058.27 148.047 1058.2 148.032 1058.17 147.957C1058.13 147.882 1058.14 147.769 1058.25 147.709ZM1060.2 146.641C1060.2 146.55 1060.29 146.453 1060.37 146.453C1060.49 146.453 1060.56 146.558 1060.56 146.641V156.463C1060.56 156.561 1060.49 156.651 1060.37 156.651C1060.28 156.651 1060.2 156.561 1060.2 156.463V146.641Z" fill="#5EB0EF"/>
<path d="M1066.87 146.438C1068.59 146.438 1070 147.769 1070.15 149.476C1070.2 149.754 1070.21 150.048 1070.21 150.333C1070.21 151.71 1069.92 153.221 1069.22 154.447C1068.49 155.673 1067.36 156.576 1065.68 156.651C1065.56 156.651 1065.49 156.576 1065.49 156.463C1065.48 156.373 1065.57 156.29 1065.65 156.29C1067.21 156.215 1068.21 155.402 1068.89 154.274C1069.38 153.402 1069.69 152.319 1069.79 151.281C1069.25 152.334 1068.14 153.048 1066.87 153.048C1065.06 153.048 1063.55 151.574 1063.55 149.754C1063.55 147.934 1065.06 146.445 1066.87 146.445L1066.87 146.438ZM1066.87 152.687C1068.48 152.687 1069.8 151.371 1069.8 149.754C1069.8 149.679 1069.8 149.611 1069.78 149.536V149.461C1069.63 147.957 1068.39 146.806 1066.87 146.806C1065.24 146.806 1063.91 148.122 1063.91 149.754C1063.91 151.386 1065.24 152.687 1066.87 152.687Z" fill="#5EB0EF"/>
</g>
<g opacity="0.68">
<path d="M1027.47 116.106C1027.34 116.008 1027.34 115.775 1027.47 115.647C1027.59 115.52 1027.8 115.52 1027.92 115.647C1028.97 116.67 1030.32 117.204 1031.64 117.204C1032.97 117.204 1034.37 116.67 1035.39 115.647C1036.43 114.602 1036.94 113.248 1036.94 111.895C1036.94 110.541 1036.43 109.195 1035.39 108.172C1034.37 107.149 1033.04 106.645 1031.67 106.645C1030.29 106.645 1028.99 107.156 1027.92 108.172C1027.84 108.3 1027.61 108.3 1027.48 108.194C1027.46 108.194 1027.43 108.172 1027.43 108.142C1027.41 108.142 1027.41 108.119 1027.41 108.119C1027.35 108.044 1027.33 107.969 1027.35 107.863L1028.25 100.343C1028.27 100.192 1028.37 100.087 1028.48 100.064C1028.53 100.042 1028.59 100.012 1028.66 100.012H1037.27C1037.45 100.012 1037.6 100.192 1037.6 100.343C1037.6 100.546 1037.45 100.673 1037.27 100.673H1028.89L1028.12 107.149C1029.16 106.359 1030.41 105.976 1031.66 105.976C1033.18 105.976 1034.72 106.54 1035.89 107.713C1037.01 108.864 1037.6 110.368 1037.6 111.895C1037.6 113.421 1037.01 114.956 1035.84 116.106C1034.69 117.279 1033.16 117.866 1031.63 117.866C1030.1 117.866 1028.62 117.279 1027.45 116.106H1027.47Z" fill="#5EB0EF"/>
<path d="M1042.23 117.279L1050.84 109.503C1051.93 108.488 1052.52 107.104 1052.52 105.727C1052.52 104.532 1052.06 103.306 1051.2 102.336C1050.21 101.238 1048.8 100.651 1047.45 100.651C1046.22 100.651 1045 101.11 1044.04 101.975L1043.14 102.794C1042.99 102.922 1042.81 102.922 1042.68 102.794C1042.56 102.666 1042.58 102.441 1042.71 102.336L1043.6 101.493C1044.72 100.501 1046.07 100.012 1047.45 100.012C1049 100.012 1050.56 100.651 1051.68 101.869C1052.65 102.967 1053.16 104.366 1053.16 105.72C1053.16 107.277 1052.52 108.834 1051.25 109.954L1043.25 117.227H1052.86C1053.01 117.227 1053.17 117.355 1053.17 117.535C1053.17 117.716 1053.02 117.866 1052.86 117.866H1042.46C1042.36 117.866 1042.26 117.843 1042.2 117.738C1042.1 117.61 1042.1 117.407 1042.23 117.279Z" fill="#5EB0EF"/>
</g>
<g opacity="0.74">
<path d="M1062.91 164.57L1063.19 164.405C1063.19 164.405 1063.22 164.405 1063.23 164.412C1063.23 164.427 1063.23 164.435 1063.22 164.45L1062.94 164.615H1062.92C1062.92 164.615 1062.91 164.615 1062.9 164.608C1062.9 164.6 1062.9 164.585 1062.91 164.578L1062.91 164.57ZM1063.18 164.427C1063.18 164.427 1063.19 164.405 1063.2 164.405C1063.22 164.405 1063.22 164.42 1063.22 164.427V165.759C1063.22 165.759 1063.22 165.781 1063.2 165.781C1063.19 165.781 1063.18 165.766 1063.18 165.759V164.427Z" fill="#E71D36"/>
</g>
<g opacity="0.52">
<path d="M1075.99 158.448L1078.1 157.2C1078.19 157.14 1078.31 157.155 1078.37 157.26C1078.41 157.35 1078.38 157.456 1078.28 157.516L1076.17 158.779C1076.17 158.779 1076.11 158.794 1076.08 158.794C1076.02 158.794 1075.95 158.779 1075.92 158.704C1075.87 158.629 1075.89 158.508 1075.99 158.448ZM1078.01 157.35C1078.01 157.26 1078.1 157.155 1078.19 157.155C1078.31 157.155 1078.38 157.26 1078.38 157.35V167.466C1078.38 167.571 1078.31 167.661 1078.19 167.661C1078.1 167.661 1078.01 167.571 1078.01 167.466V157.35Z" fill="#E71D36"/>
<path d="M1086.56 161.667C1087.23 162.344 1087.58 163.247 1087.58 164.134C1087.58 165.021 1087.23 165.939 1086.56 166.631C1085.87 167.308 1084.96 167.654 1084.08 167.654H1084.06C1083.18 167.654 1082.27 167.323 1081.6 166.631C1081.52 166.556 1081.52 166.435 1081.6 166.36C1081.67 166.285 1081.79 166.285 1081.87 166.36C1082.48 166.962 1083.27 167.263 1084.06 167.263C1084.86 167.263 1085.67 166.962 1086.27 166.345C1086.89 165.743 1087.19 164.946 1087.19 164.134C1087.19 163.322 1086.89 162.555 1086.29 161.938C1085.67 161.336 1084.89 161.035 1084.09 161.035C1084.02 161.035 1083.96 161.005 1083.91 160.945C1083.91 160.93 1083.9 160.93 1083.9 160.93C1083.82 160.855 1083.82 160.72 1083.9 160.644L1086.93 157.516H1082.29C1082.18 157.516 1082.09 157.425 1082.09 157.32C1082.09 157.215 1082.18 157.125 1082.29 157.125H1087.38C1087.49 157.125 1087.58 157.215 1087.58 157.32C1087.58 157.35 1087.58 157.38 1087.57 157.41C1087.55 157.441 1087.54 157.471 1087.52 157.486L1084.42 160.659C1085.21 160.735 1085.97 161.066 1086.56 161.667Z" fill="#E71D36"/>
</g>
<g opacity="0.65">
<path d="M1090.14 144.294L1093.71 142.181C1093.86 142.083 1094.06 142.106 1094.16 142.279C1094.24 142.429 1094.19 142.609 1094.01 142.715L1090.45 144.851C1090.4 144.873 1090.35 144.873 1090.3 144.873C1090.2 144.873 1090.07 144.851 1090.02 144.723C1089.95 144.595 1089.97 144.392 1090.15 144.287L1090.14 144.294ZM1093.55 142.436C1093.55 142.286 1093.71 142.106 1093.86 142.106C1094.07 142.106 1094.19 142.286 1094.19 142.436V159.576C1094.19 159.757 1094.07 159.907 1093.86 159.907C1093.71 159.907 1093.55 159.757 1093.55 159.576V142.436Z" fill="#5EB0EF"/>
<path d="M1099.36 158.147C1099.23 158.042 1099.23 157.816 1099.36 157.689C1099.48 157.561 1099.69 157.561 1099.82 157.689C1100.86 158.711 1102.21 159.245 1103.53 159.245C1104.85 159.245 1106.25 158.711 1107.27 157.689C1108.32 156.643 1108.83 155.297 1108.83 153.943C1108.83 152.59 1108.32 151.243 1107.27 150.228C1106.26 149.213 1104.93 148.701 1103.56 148.701C1102.19 148.701 1100.88 149.213 1099.82 150.228C1099.74 150.356 1099.51 150.356 1099.38 150.251C1099.36 150.251 1099.33 150.228 1099.33 150.198C1099.3 150.198 1099.3 150.175 1099.3 150.175C1099.25 150.1 1099.23 150.025 1099.25 149.92L1100.15 142.406C1100.17 142.256 1100.27 142.151 1100.37 142.128C1100.42 142.106 1100.48 142.075 1100.55 142.075H1109.15C1109.33 142.075 1109.48 142.256 1109.48 142.406C1109.48 142.609 1109.33 142.737 1109.15 142.737H1100.78L1100.01 149.205C1101.06 148.415 1102.3 148.032 1103.55 148.032C1105.08 148.032 1106.6 148.596 1107.77 149.762C1108.89 150.905 1109.48 152.409 1109.48 153.936C1109.48 155.462 1108.89 156.989 1107.72 158.14C1106.58 159.313 1105.05 159.892 1103.52 159.892C1101.99 159.892 1100.51 159.306 1099.35 158.14L1099.36 158.147Z" fill="#5EB0EF"/>
</g>
<g opacity="0.64">
<path d="M1030.12 209.206C1034.64 209.206 1038.33 212.899 1038.33 217.487C1038.33 220.766 1036.37 223.624 1033.63 224.932C1039.23 226.482 1043.39 231.603 1043.39 237.68C1043.39 245.005 1037.44 250.909 1030.12 250.909C1022.8 250.909 1016.85 245.013 1016.85 237.68C1016.85 231.603 1021.02 226.482 1026.61 224.932C1023.75 223.624 1021.84 220.758 1021.84 217.487C1021.84 212.899 1025.53 209.206 1030.12 209.206ZM1030.12 249.42C1036.66 249.42 1041.9 244.178 1041.9 237.688C1041.9 231.197 1036.66 225.895 1030.12 225.895C1023.57 225.895 1018.33 231.197 1018.33 237.688C1018.33 244.178 1023.63 249.42 1030.12 249.42ZM1030.12 224.218C1033.81 224.218 1036.84 221.179 1036.84 217.487C1036.84 213.794 1033.81 210.756 1030.12 210.695C1026.37 210.756 1023.33 213.734 1023.33 217.487C1023.33 221.24 1026.37 224.218 1030.12 224.218Z" fill="#E71D36"/>
<path d="M1060.65 249.834L1077.03 210.808H1055.06C1054.65 210.808 1054.29 210.447 1054.29 210.034C1054.29 209.673 1054.64 209.259 1055.06 209.259H1078.28C1078.64 209.259 1079.05 209.673 1079.05 210.034C1079.05 210.214 1078.93 210.395 1078.81 210.568L1062.02 250.42C1061.96 250.661 1061.66 250.894 1061.37 250.894C1061.31 250.894 1061.19 250.834 1061.07 250.834C1060.65 250.654 1060.54 250.24 1060.65 249.819V249.834Z" fill="#E71D36"/>
</g>
<g opacity="0.94">
<path d="M1177.06 129.734L1180.64 126.5C1181.1 126.071 1181.34 125.5 1181.34 124.928C1181.34 124.432 1181.15 123.92 1180.79 123.514C1180.38 123.055 1179.79 122.815 1179.24 122.815C1178.72 122.815 1178.22 123.003 1177.82 123.364L1177.45 123.702C1177.39 123.755 1177.31 123.755 1177.26 123.702C1177.21 123.649 1177.21 123.552 1177.27 123.514L1177.63 123.161C1178.1 122.747 1178.66 122.544 1179.24 122.544C1179.88 122.544 1180.53 122.807 1180.99 123.319C1181.4 123.777 1181.61 124.356 1181.61 124.921C1181.61 125.567 1181.35 126.214 1180.81 126.68L1177.48 129.704H1181.48C1181.54 129.704 1181.61 129.756 1181.61 129.832C1181.61 129.907 1181.54 129.967 1181.48 129.967H1177.15C1177.11 129.967 1177.07 129.959 1177.05 129.914C1177 129.862 1177 129.779 1177.06 129.726V129.734Z" fill="#E71D36"/>
<path d="M1183.32 129.734L1186.9 126.5C1187.36 126.071 1187.6 125.5 1187.6 124.928C1187.6 124.432 1187.41 123.92 1187.05 123.514C1186.64 123.055 1186.05 122.815 1185.5 122.815C1184.99 122.815 1184.48 123.003 1184.08 123.364L1183.71 123.702C1183.65 123.755 1183.57 123.755 1183.52 123.702C1183.47 123.649 1183.47 123.552 1183.53 123.514L1183.9 123.161C1184.36 122.747 1184.93 122.544 1185.5 122.544C1186.14 122.544 1186.79 122.807 1187.25 123.319C1187.66 123.777 1187.87 124.356 1187.87 124.921C1187.87 125.567 1187.61 126.214 1187.07 126.68L1183.75 129.704H1187.74C1187.8 129.704 1187.87 129.756 1187.87 129.832C1187.87 129.907 1187.8 129.967 1187.74 129.967H1183.41C1183.37 129.967 1183.33 129.959 1183.31 129.914C1183.26 129.862 1183.26 129.779 1183.32 129.726V129.734Z" fill="#E71D36"/>
</g>
</svg>

  )
}

export default Numbers