import React from "react";
import Chart from "react-apexcharts";
import { useState, useEffect } from "react";
import { getPlansInfo } from "../../utils/requests/dashboardRequest";
import Loading from "../../components/shared/Loading";

const donutChartOption = {
  // winwin, golden, standard
  colors: ["#5d35b0", "#cf364a", "#13807a"],
  chart: {
    parentHeightOffset: 0,
    type: "donut",
    foreColor: "var(--primary-text)",
  },
  dataLabels: {
    enabled: false,
    style: {
      fontSize: "15px ",
    },
  },
  stroke: {
    width: 0,
  },
  plotOptions: {
    pie: {
      startAngle: 0,
      endAngle: 360,
      expandOnClick: false,
      offsetX: 0,
      offsetY: 0,
      dataLabels: {
        offset: 0,
        minAngleToShowLabel: 10,
      },
      donut: {
        size: "80%",
        // background: "#fff",
        labels: {
          show: true,
          name: {
            show: true,
            fontSize: "22px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 500,
            offsetY: -10,
            formatter: function (val) {
              return val;
            },
          },
          value: {
            show: true,
            fontSize: "30px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            color: "var(--primary-text)",
            offsetY: 16,
            formatter: function (val) {
              return val;
            },
          },
          total: {
            show: true,
            showAlways: true,
            label: "Total",
            fontSize: "30px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            color: "var(--primary-text)",
            formatter: function (w) {
              return w.globals.seriesTotals.reduce((a, b) => {
                return a + b;
              }, 0);
            },
          },
        },
      },
    },
  },

  labels: ["WIN-WIN", "GOLDEN", "STANDARD"],
  legend: {
    position: "bottom",
    fontSize: "14px",
    offsetY: 0,
    horizontalAlign: "center",

    markers: {
      offsetX: -3,
      radius: 3,
    },
  },
  responsive: [
    {
      breakpoint: 1400, // Below this width
      options: {
        plotOptions: {
          pie: {
            customScale: 0.8,
          },
        },
      },
    },
  ],
};

export default function DonutChart() {
  const [loading, setLoading] = useState(false);
  const [donutChartSeries, setDonutChartSeries] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading((x) => true);
      const info = await getPlansInfo(); // Replace with your API endpoint
      setLoading((x) => false);
      if (info) {
        setDonutChartSeries([info.win_win, info.golden, info.standard]);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="max-[400px]:hidden min-[1100px]:max-[1400px]:col-[1/-1] w-full p-8 min-h-[400px] bg-[--box-items-background] rounded-xl">
      <div className="text-[--primary-text] text-3xl font-bold">
        Plans Summary
      </div>
      <div className="xl:mt-10">
        {loading ? (
          <div className="w-full mt-20 text-center opacity-30">
            <Loading />
          </div>
        ) : (
          <div className="max-w-[500px] mx-auto grow ld:max-w-xl [&_.apexcharts-legend]:!absolute [&_.apexcharts-legend]:!grid [&_.apexcharts-legend]:!grid-cols-3 [&_.apexcharts-legend]:!justify-items-center">
            <Chart
              options={donutChartOption}
              series={donutChartSeries}
              type="donut"
            />
          </div>
        )}
      </div>
    </div>
  );
}
