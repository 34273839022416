import React from "react";
import { TableHeaders } from "./TableHeaders";
import { InactiveTableRow } from "./InactiveTableRow";
import ErrorMessage from "../../components/shared/ErrorMessage";
import Table from "../../components/Table";
import { LuSettings2 } from "react-icons/lu";
export const inactiveRows = [
  {
    status: "payment pending",
    balance: 0,
    challengePeriod: 30,
    phase: 1,
    startTime: 0,
    endTime: "-",
    more: "analyze",
  },
  {
    status: "payment pending",
    balance: 0,
    challengePeriod: 30,
    phase: 1,
    startTime: 0,
    endTime: "-",
    more: "analyze",
  },
];
const inactiveHeader = [
  "state",
  "balance",
  "challenge",
  "phase",
  "start time",
  "end time",
  <LuSettings2 size={25} className="w-full text-center" />,
];
export function InactivePlans({ data }) {
  return (
    <>
      <div className="w-full rounded-full bg-[var(--indigo-purple-hover)] text-white text-center text-xl font-semibold py-2 mt-20 capitalize">
        inactive plans
      </div>
      {!data || data.length === 0 ? (
        <ErrorMessage
          text="You don't have an inactive plan! "
          optcClass="justify-center mt-2"
        />
      ) : (
        <Table headers={inactiveHeader}>
          {data.map((row) => (
            <InactiveTableRow data={row} />
          ))}
        </Table>
      )}
    </>
  );
}
