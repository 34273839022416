import React, { useState } from "react";
import { SubmitButton } from "../../components/shared/SubmitButton";

export function DepositAmount({ handleSetDepositData, depositData }) {
  const [amount, setAmount] = useState("");
  const [error, setError] = useState(false);
  console.log(depositData);
  function handleSubmit(e) {
    e.preventDefault();
    if (depositData.paymentMethod === "Crypto" && amount < 10) {
      setError(true);
      return;
    } else if (
      depositData.paymentMethod === "Fiat" &&
      (amount < 10 || amount > 550)
    ) {
      setError(true);
      return;
    }
    handleSetDepositData({
      depositAmount: amount,
      creditAmount: amount,
      step: 3,
    });
  }

  return (
    <div className="w-full flex flex-col sm:flex-row items-center ">
      <div className="w-24 h-24 rounded-xl p-3 bg-[#011B19] sm:bg-transparent">
        <img
          src={`../../${depositData.paymentMethod}.png`}
          className="w-full h-full"
          alt=""
        />
      </div>
      <form onSubmit={handleSubmit} className="w-full max-w-xs">
        <DepositAmountInput
          func={setAmount}
          value={amount}
          text={"Deposit Amount"}
          amountError={setError}
        />
        {depositData.paymentMethod === "Fiat" ? (
          <DepositAmountInput
            value={amount && (amount * 0.95).toFixed(2)}
            text={"receive"}
            disabled
            optClass={"text-red-600 !font-bold"}
          />
        ) : null}
        {error && depositData.paymentMethod === "Crypto" && (
          <p className="text-red-600 text-sm">
            your deposit amount must be higher than 10$
          </p>
        )}
        {error && depositData.paymentMethod === "Fiat" && (
          <p className="text-red-600 text-sm">
            your deposit amount must be between 10$ and 550$
          </p>
        )}

        <SubmitButton bgColor="bg-indigo-800" optClass="pt-2 pb-0.5 mt-3" />
      </form>
    </div>
  );
}

function DepositAmountInput({
  func,
  amountError,
  value,
  text,
  disabled = false,
  optClass,
}) {
  const [error, setError] = useState(false);
  function handleError(e) {
    e.preventDefault();
    setError(true);
  }
  function handleChange(e) {
    setError(false);
    amountError(false);
    func(e.target.value);
  }

  return (
    <>
      <div className="w-full bg-white rounded-lg border-x-[3px] border-primaryPurple mt-5 mb-1 relative capitalize ">
        <span className="text-xs text-white bg-[var(--indigo-purple)] rounded-lg px-1.5 pt-0.5 absolute -top-2 leading-none">
          {text}
        </span>
        <span className="text-[#11092E] text-xl font-extrabold px-2 pt-2 bg-neutral-200 rounded-lg absolute right-0 top-0 bottom-0">
          USD
        </span>
        <input
          disabled={disabled}
          onInvalid={handleError}
          required={true}
          value={value}
          onChange={handleChange}
          type="number"
          className={`bg-transparent text-primaryPurple font-semibold p-2 outline-none disabled:cursor-not-allowed ${optClass}`}
        />
      </div>
      <span
        className={`text-sm text-rose-600 font-semibold ${
          error ? "block" : "hidden"
        } `}
      >
        Please Enter amount
      </span>
    </>
  );
}
