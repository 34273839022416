import { createSlice } from '@reduxjs/toolkit';
// utils

// ----------------------------------------------------------------------

const initialState = {
    loading: false,
    success: false,
    error: false,
    msg: null,
  };
  
  const notifyReducer = createSlice({
    name: 'notify',
    initialState,
    reducers: {
      loading(state) {
          state.loading = true;
          state.success = false;
          state.error = false;
          state.msg = null;
      },
      Success(state, action) {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.msg = action.payload.msg;
      },
      error(state, action) {
        state.loading = false;
        state.success = false;
        state.error = true;
        state.msg = action.payload.msg;
      },
      hide(state) {
        state.loading = false;
        state.success = false;
        state.error = false;
        state.msg = null;
      },
    },
  });
  
  export const notifyActions = notifyReducer.actions;
  export default notifyReducer;

