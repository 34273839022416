import React from "react";
import { Link } from "react-router-dom";
import useAddChallenge from "../../../../hooks/Admin/User/useAddChallenge";
import TableBackground from "../../../../components/AdminShared/TableBackground";
import RequestDetailsForm from "../../../../components/AdminShared/RequestDetailsForm";
import RequestDetailsInput from "../../../../components/AdminShared/RequestsDetailsInput";
import RequestDetailsButton, {
  RequestDetailsButtonLayout,
} from "../../../../components/AdminShared/RequestsDetailsButton";
import Loading from "../../../../components/shared/Loading";
import FilterSelect from "../../../../components/AdminShared/FilterSelect";
const AddChallenge = () => {
  const {
    loading,
    formData,
    handleChange,
    balance,
    setBalance,
    challenge,
    setChallenge,
    challenges,
    phases,
    balances,
    mt4Brokers,
    mt5Brokers,
    handleSubmit,
  } = useAddChallenge();
  return (
    <>
      <TableBackground>
        {loading ? (
          <div className="w-full text-center">
            <Loading />
          </div>
        ) : (
          <>
            <div className=" flex items-center justify-between text-center text-white mb-5">
              <h3 className="text-2xl text-center w-full ">
                User Add Challenge
              </h3>
              <Link
                to="/admin/users"
                className="btn btn-secondary btn-outline ml-auto"
              >
                Back
              </Link>
            </div>

            <RequestDetailsForm>
              <label className="form-control">
                <div className="label">
                  <span className={`label-text text-xl text-slate-100`}>
                    Challenge:
                  </span>
                </div>
                <select
                  name="challenge"
                  value={challenge}
                  onChange={(e) => setChallenge(e.target.value)}
                  className="select select-md text-lg text-black select-bordered bg-slate-300"
                >
                  <option disabled selected>
                    Pick challenge
                  </option>
                  {challenges.map((challengeItem) => (
                    <option value={challengeItem.id}>
                      {challengeItem.name}
                    </option>
                  ))}
                </select>
              </label>

              <label className="form-control">
                <div className="label">
                  <span className={`label-text text-xl text-slate-100`}>
                    Balance:
                  </span>
                </div>
                <select
                  name="balance"
                  value={balance}
                  onChange={(e) => setBalance(e.target.value)}
                  className="select select-md text-lg text-black select-bordered bg-slate-300"
                >
                  <option disabled selected value="">
                    Pick Balance
                  </option>
                  {balances &&
                    balances.map((balanceItem) => (
                      <option value={balanceItem.id}>
                        {balanceItem.amount}
                      </option>
                    ))}
                </select>
              </label>

              <FilterSelect
                title="phase"
                name="balance_phase"
                value={formData.balance_phase}
                handleChange={handleChange}
                defaultOption="Pick Phase"
                defaultDisable
                titleClass="text-xl"
                optClass="!select-md !text-lg"
              >
                {phases?.map((phaseItem) => {
                  return <option value={phaseItem.id}>{phaseItem.name}</option>;
                })}
              </FilterSelect>
              <FilterSelect
                title="platform"
                name="platform"
                value={formData.platform}
                handleChange={handleChange}
                defaultOption="Pick Platform"
                defaultDisable
                titleClass="text-xl"
                optClass="!select-md !text-lg text-black"
              >
                <option value="MT4">MT4</option>
                <option value="MT5">MT5</option>
              </FilterSelect>
              <FilterSelect
                title="broker"
                name="broker"
                value={formData.broker}
                handleChange={handleChange}
                defaultOption="Pick Broker"
                defaultDisable
                titleClass="text-xl"
                optClass="!select-md !text-lg"
              >
                {formData.platform && formData.platform === "MT4"
                  ? mt4Brokers.map((broker, index) => (
                      <option key={index} value={broker}>
                        {broker}
                      </option>
                    ))
                  : mt5Brokers.map((broker, index) => (
                      <option key={index} value={broker}>
                        {broker}
                      </option>
                    ))}
              </FilterSelect>

              <RequestDetailsInput
                title="Scale Up"
                name="scale_up"
                titleClass="text-xl"
                optClass="!input-md !text-xl text-black"
                value={formData.scale_up}
                onChange={handleChange}
              />
              <RequestDetailsInput
                title="Real Count"
                name="real_count"
                titleClass="text-xl"
                optClass="!input-md !text-xl text-black"
                value={formData.real_count}
                onChange={handleChange}
              />
            </RequestDetailsForm>
            <RequestDetailsButtonLayout>
              <RequestDetailsButton
                title="submit"
                optClass="btn-success"
                onclick={handleSubmit}
              />
            </RequestDetailsButtonLayout>
          </>
        )}
      </TableBackground>
    </>
  );
};

export default AddChallenge;
