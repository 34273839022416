import React, { useEffect, useState } from "react";
import { useFetch } from "../../../hooks/API/useFetch";
import { useNavigate } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";

const headers = ["Challenge", "State", "Remaining Days"];
const plansImages = {
  Standard: "./standardPlan.svg",
  "Win-win": "./winwinPlan.svg",
  Golden: "./goldenPlan.svg",
  Elite: "./elitePlan.svg",
};

export default function PlanTable() {
  const [plans, setPlans] = useState(null);
  const { loading, fetchData: handleGetData } = useFetch();
  const navigate = useNavigate();
  const fetchData = async () => {
    const res = await handleGetData("dashboard-plans");
    if (!res) return;
    setPlans(res.dashboard_plans);
  };

  const isLinkActive = (data) => {
    if (data.link_active) {
      navigate(`my-challenges/analyze/${data.id}`);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="min-[1100px]:col-span-2">
      <div
        className={`w-full h-full max-h-[400px] overflow-auto scrollWidth cursor-pointer rounded-xl bg-[var(--box-items-background)] relative z-20`}
      >
        <table className="w-full rounded-xl overflow-auto">
          <thead
            className={`text-left text-gray-500 [&_th]:px-4 [&_th]:py-5 capitalize`}
          >
            <tr className="sticky bg-[--box-items-background] top-0 z-30 shadow-[0_1px_0_0px] shadow-gray-500/40 ">
              {/* <th>ID</th> */}
              {headers.map((th) => (
                <th>{th}</th>
              ))}
            </tr>
          </thead>
          <tbody
            className={`[&_td]:p-4 text-white [&_tr:not(:last-child)]:border-b [&_tr]:border-[--chart-grid] [&_tr:nth-child(even)]:- light:[&_tr:nth-child(even)]:- `}
          >
            {loading
              ? Array.from({ length: 5 }).map((item) => (
                  <tr className="w-full">
                    <td>
                      <div className="flex items-center gap-2">
                        <div className="rounded-lg w-12 h-12 bg-[#1c2431] light:bg-gray-300 animate-pulse" />
                        <div className="rounded-lg w-20 h-5 bg-[#1c2431] light:bg-gray-300 animate-pulse" />
                      </div>
                    </td>
                    <td>
                      <div className="rounded-lg w-[50%] h-5 bg-[#1c2431] light:bg-gray-300 animate-pulse" />
                    </td>
                    <td>
                      <div className="rounded-lg w-[80%] h-5 bg-[#1c2431] light:bg-gray-300 animate-pulse" />
                    </td>
                  </tr>
                ))
              : plans.length > 0 &&
                plans.map((row) => (
                  <tr
                    onClick={() => isLinkActive(row)}
                    className={`relative z-0 ${
                      row.link_active &&
                      "hover:bg-[#1b232e] light:hover:bg-gray-300"
                    }`}
                  >
                    {/* <td>{row.id}</td> */}
                    <td>
                      <div className="flex items-center gap-3">
                        <div className="">
                          <div className="h-12 w-12">
                            <img
                              src={plansImages[row.challenge_type]}
                              className="light:invert"
                              alt={`Golden Fund ${row.challenge_type} plan logo`}
                            />
                          </div>
                        </div>
                        <div>
                          <div>{row.challenge_type}</div>
                          <div className="text-sm text-gray-500">
                            {row.balance}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>{row.account_type}</td>
                    <td>
                      <div className="text-gray-500">
                        <span className="text-lg text-white">
                          {row.days_remaining}
                        </span>{" "}
                        {row.days_remaining !== "-" && "days"}
                      </div>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
        {!loading && !plans.length > 0 && (
          <div className="w-full h-[335px] ">
            <Player
              src="https://lottie.host/0a086584-47fd-4247-ad9e-c3e39d6ec20d/yB2qgX25mt.json"
              className="player h-[300px]"
              autoplay
              loop
            />
            <p className="-mt-8 text-second text-center font-semibold text-lg">
              You don't have a plan!
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
