import React, { useState } from "react";
import { useSelector } from "react-redux";

// const countries = [
//   { name: "Afghanistan", code: "AF" },
//   { name: "Åland Islands", code: "AX" },
//   { name: "Albania", code: "AL" },
//   { name: "Algeria", code: "DZ" },
//   { name: "American Samoa", code: "AS" },
//   { name: "Andorra", code: "AD" },
//   { name: "Angola", code: "AO" },
//   { name: "Anguilla", code: "AI" },
//   { name: "Antarctica", code: "AQ" },
//   { name: "Antigua & Barbuda", code: "AG" },
//   { name: "Argentina", code: "AR" },
//   { name: "Armenia", code: "AM" },
//   { name: "Aruba", code: "AW" },
//   { name: "Australia", code: "AU" },
//   { name: "Austria", code: "AT" },
//   { name: "Azerbaijan", code: "AZ" },
//   { name: "Bahamas", code: "BS" },
//   { name: "Bahrain", code: "BH" },
//   { name: "Bangladesh", code: "BD" },
//   { name: "Barbados", code: "BB" },
// ];

export function IdentityCountry({ handleSetData }) {
  const [error, setError] = useState(false);

  const countries = useSelector((state) => state.country.country);

  function handleChange(e) {
    handleSetData("country", e.target.value);
    setError(false);
  }

  function handleErr(e) {
    e.preventDefault();
    setError(true);
  }
  return (
    <>
      <p>Select the country that issued your document</p>
      <select
        onChange={handleChange}
        onInvalid={handleErr}
        required={true}
        className="w-full max-w-xs bg-transparent text-white border-b outline-0 border-white [&>*]:bg-gray-700 capitalize"
      >
        <option disabled selected value="">
          your country
        </option>
        {countries.map(({ name }, index) => (
          <option key={index} value={name}>
            {" "}
            {name}{" "}
          </option>
        ))}
      </select>
      <span className={`text-sm text-error ${error ? "block" : "hidden"} `}>
        Please select a country
      </span>
    </>
  );
}
