import { useState } from "react";
import { postData } from "../../../utils/fetchData";
import { toast } from "react-toastify";
import TableBackground from "../../../components/AdminShared/TableBackground";
import RequestDetailsForm from "../../../components/AdminShared/RequestDetailsForm";
import RequestDetailsButton, {
  RequestDetailsButtonLayout,
} from "../../../components/AdminShared/RequestsDetailsButton";
import RequestDetailsInput from "../../../components/AdminShared/RequestsDetailsInput";
export default function ChallengeRequestDetail({
  userSelected,
  setUserSelected,
  getRequestWithdraws,
}) {
  const [decline, setDecline] = useState("TRX Address is invalid");

  const handleSubmit = async (verify) => {
    const formData = {
      transition_id: userSelected.id,
      verify: verify,
      decline: decline,
    };

    try {
      const res = await postData("finance/withdraws/verify", formData);
      if (!res?.data) return;

      toast.success(res?.data.message);
      setUserSelected(null);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setUserSelected((prev) => null);
      getRequestWithdraws();
    }
  };

  function handleCancel() {
    setUserSelected(null);
  }

  return (
    <TableBackground optClass="max-w-3xl">
      <RequestDetailsForm>
        <RequestDetailsInput
          title="Name"
          value={userSelected.first_name + " " + userSelected.last_name}
          disabled
        />
        <RequestDetailsInput
          title="Email"
          value={userSelected.email}
          disabled
        />
        <RequestDetailsInput
          title="TRX address"
          value={userSelected.address}
          disabled
        />
        <RequestDetailsInput
          title="Amount"
          value={userSelected.amount}
          disabled
        />
        <RequestDetailsInput
          title="Decline reason"
          value={decline}
          onChange={(e) => setDecline(e.target.value)}
        />
      </RequestDetailsForm>
      <RequestDetailsButtonLayout>
        <RequestDetailsButton
          title="Verify"
          onclick={() => handleSubmit(1)}
          optClass="btn-success"
        />
        <RequestDetailsButton
          title="Decline"
          onclick={() => handleSubmit(0)}
          optClass="btn-error"
        />
        <RequestDetailsButton
          title="Cancel"
          onclick={handleCancel}
          optClass="btn-warning"
        />
      </RequestDetailsButtonLayout>
    </TableBackground>
  );
}
