export default function AuthInput({
  label,
  dataTip,
  borderColor,
  children,
  ...props
}) {
  return (
    <>
      <label className="form-control w-full">
        <div className="label py-0">
          {label && (
            <label
              htmlFor={label}
              className="label  text-white font-semibold capitalize"
            >
              {label}:
            </label>
          )}
          {dataTip && (
            <span className="label-text-alt">
              <div
                className={`tooltip before:max-w-[100px] lg:before:max-w-[110px] ${
                  borderColor === "investor"
                    ? "before:bg-[var(--invest-gold)] before:text-black font-semibold"
                    : "before:bg-[var(--indigo-purple)] before:text-indigo-100"
                }   capitalize`}
                data-tip={dataTip}
              >
                <span
                  className={`aspect-square w-5  border-transparent flex items-center justify-center pt-1 rounded-full bg-white ${
                    borderColor === "investor"
                      ? "text-[var(--invest-gold)]"
                      : "text-[var(--indigo-purple)]"
                  } font-extrabold`}
                >
                  &#63;
                </span>
              </div>
            </span>
          )}
        </div>
        <div
          className={`flex items-center gap-x-2 w-full  bg-white rounded-2xl  border-y-4 border-x-0 pr-4  ${
            borderColor === "investor"
              ? "border-[var(--invest-gold)] focus:border-[var(--invest-gold)]"
              : "border-[var(--indigo-purple)] focus:border-[var(--indigo-purple)]"
          }`}
        >
          <input
            className="input py-6 grow !border-0 !outline-0 bg-transparent"
            id={label}
            {...props}
          />
          {children}
        </div>
      </label>
    </>
  );
}
