import { useNavigate, useParams } from "react-router-dom";
import { useAnalyzePanelN } from "../../hooks/useAnalyzePanelN";
import ChallengeStatistics from "./ChallengeStatistics";
import EquityBalanceChart from "./EquityBalanceCharts";
import GeneralInfo from "./generalInfo";
import RadialChart from "./RadialCharts";
import Stats from "./Stats";
import TradesTable from "./TradesTable";
import ErrorMessage from "../../components/shared/ErrorMessage";
export default function AnalyzePanel() {
  const { id } = useParams();
  const {
    loading,
    tradesLoading,
    data,
    tradesData,
    setQuery,
    error,
    tradesError,
  } = useAnalyzePanelN(id);
  const navigate = useNavigate();
  return (
    <div className="grid grid-cols-1 gap-x-5 gap-y-5 lg:gap-y-7 mt-10">
      {error || tradesError ? (
        <ErrorMessage>
          <div
            className="cursor-pointer underline hover:no-underline -mb-2"
            onClick={() => navigate(-1)}
          >
            Back
          </div>
        </ErrorMessage>
      ) : (
        <>
          <GeneralInfo data={data?.challenge_details} loading={loading} />
          <Stats data={data?.balance_data} loading={loading} />
          <RadialChart data={data?.draw_down_data} loading={loading} />
          <EquityBalanceChart data={data?.charts} loading={loading} />

          <ChallengeStatistics
            data={tradesData?.statistics}
            loading={tradesLoading}
          />
          <TradesTable
            data={tradesData?.trades}
            loading={tradesLoading}
            setQuery={setQuery}
          />
        </>
      )}
    </div>
  );
}
