import { GoTriangleDown, GoTriangleUp } from "react-icons/go";
import { PiTarget } from "react-icons/pi";
import { formatComma } from "../../utils/formatNumber";
import { PiCurrencyCircleDollar } from "react-icons/pi";

export default function Stats({ data, loading }) {
  return (
    <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-[inherit]">
      <Box
        title="Balance"
        value={`$${formatComma(data?.balance?.amount)}`}
        change={data?.balance?.change}
        loading={loading}
      />
      <Box
        title="Equity"
        value={`$${formatComma(data?.equity?.amount)}`}
        change={data?.equity?.change}
        loading={loading}
      />
      <Box
        title="Today Profit"
        value={`$${formatComma(data?.today_profit?.toFixed(2))}`}
        loading={loading}
        icon={<PiCurrencyCircleDollar size={25} className="fill-gray-500" />}
      />
      <Box
        title="Target"
        value={data?.target}
        loading={loading}
        icon={<PiTarget size={25} className="fill-[--indigo-purple]" />}
      />
    </div>
  );
}

function Box({ title = "", value, change, icon, loading }) {
  return (
    <div
      className={`bg-[--box-items-background] p-5 rounded-xl flex flex-col items-start gap-2 `}
    >
      <div className="w-full flex items-center justify-between">
        <div className={`text-second font-semibold`}>{title}</div>

        {change === undefined && icon}
        {change !== undefined &&
          (change > 0 ? (
            <div className="flex items-center bg-green-600/20 text-success text-sm rounded-lg py-0.5 px-2">
              <GoTriangleUp className="fill-success size-3 xl:size-4" />
              <div className="pt-1 text-xs">%{change.toFixed(2)}</div>
            </div>
          ) : (
            <div className="flex items-center bg-red-600/20 text-error text-sm rounded-lg py-0.5 px-2">
              <GoTriangleDown className="fill-error size-3 xl:size-4" />
              <div className="pt-1 text-xs">%{change.toFixed(2)}</div>
            </div>
          ))}
      </div>
      {loading ? (
        <div className="w-24 h-7 mb-2 skeleton bg-gray-700/30 rounded-md " />
      ) : (
        <div
          className={`w-full text-white text-2xl xl:text-3xl font-semibold break-words`}
        >
          {value}
        </div>
      )}
    </div>
  );
}
