import { useFetch } from "../../hooks/API/useFetch";
import { useEffect, useState } from "react";
import { formatComma } from "../../utils/formatNumber";
import { useNavigate } from "react-router-dom";
export default function UserBalance() {
  const [balance, setBalance] = useState("");
  const { loading, fetchData } = useFetch();
  const navigate = useNavigate();

  const getUserBalance = async () => {
    const res = await fetchData("finance/wallets/amount-role");
    if (!res) return;

    setBalance(res.wallet);
  };

  useEffect(() => {
    getUserBalance();
  }, []);

  return (
    <div className="w-full h-full flex flex-col items-start justify-between gap-[30px] bg-[--box-items-background] rounded-xl p-5 text-white">
      <div className="w-full p-[1px] bg-gradient-to-tl from-transparent  to-gray-500 light:to-gray-400 rounded-xl">
        <div className="w-full rounded-xl bg-gradient-to-tl from-gray-800 to-gray-700 light:from-gray-200 light:to-gray-300 p-5">
          <div className="w-full flex items-center justify-between mb-5">
            <div>
              <div className="text-gray-500 light:text-gray-500 font-semibold text-lg">
                Balance
              </div>
              {loading ? (
                <div className="w-40 h-10 bg-gray-700 light:bg-gray-300 rounded-xl skeleton mt-2"></div>
              ) : (
                <div className="text-4xl font-semibold mt-2">
                  ${formatComma(balance)}
                </div>
              )}
            </div>
            <svg
              className="fill-gray-600 light:fill-gray-400/60 w-16"
              viewBox="0 0 96 96"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M48 0L0 48L48 96L96 48L48 0ZM47.952 8.93694C49.2012 13.8378 53.045 17.6817 57.946 18.9309C53.045 20.1802 49.2012 24.024 47.952 28.925C46.7508 24.024 42.907 20.1802 38.006 18.9309C42.907 17.6817 46.7508 13.8859 47.952 8.93694ZM48 86.3904L19.2192 57.6096L19.0751 57.4654C13.7417 51.988 13.7898 43.2432 19.2192 37.8618L33.3454 23.7358L43.2432 33.6336L28.8288 48.048L57.6096 76.8288L48 86.3904ZM62.3664 71.976L52.7568 62.3664L67.1712 47.952L62.3664 43.1472L47.952 57.5616L38.3424 47.952L62.3184 23.976L71.4474 33.1052L71.928 33.5856L76.7328 38.3904C82.018 43.6756 82.018 52.2762 76.7328 57.6096L62.3664 71.976Z" />
            </svg>
          </div>
          <svg
            className="opacity-10 light:invert w-12"
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="50"
            viewBox="0 0 60 50"
          >
            <image
              x="2"
              y="2"
              width="57"
              height="46"
              href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADkAAAAuCAYAAACSy7GmAAAER0lEQVRoge1aS0hWQRT+EvsDM7Dc6CY3utCCQCqKQJAEqUgQF1pQG2mTEEVQLaJFBdUmI6IiWpRtbCGh9CAKwqCH9lJ6QRlUixJ6SGiBtjjx2bkwXe7NuXfmgj0+GO71OvP958yZmfudmTtDRJABFhmUg1n8QBLkherWAegF8A7AaKh8jCjB860AcsqxEcBZAAMAbgP4allGAHQbtpQD6AEwHmFLXPkE4AyAJb94xUiKSKmIdIob1ihXiwNLt3KwnHLgGRWRnSKSmxypIlIsIu2ODhJ3RKRQRMpE5EBKjjp1cIUHe4imwMlGT4TEDjWyRkR6E7bdJSJVIlIiIgOe7HkvIvM5J1d7nOP7AJQCuAmgI0G7Dzr/hgHUhxYuF5QAqKSTCz06ycWnXe+fAThp2W6L1i/RhcMnqvKMVdEXmgGUAbgD4KUl55Be6z3bQhRm4SRRkbD+d736Gqa/gE5OZMA7KwPO1MgqktMKWUUyLTLp8KwiOZqy3bBnOyaR7zGS5DkP4BqAp/rs+xRtwuhSnqUAaj3ZNenkOX2BB4bmDMdzxvNCAMUxPBcAtAF4H3o+M6E9t7QQTQBOAZgXU3fYsDcKfP6ciULSVGtDhJI5DWCT3tdp9lAAoEgNrbLg7TBSso8qDO5r2z7tYBN8D7+1ttpQ/VMVCvnNIW04qoI8yD6S6tU4nDbE+raIOksS2A1GslEjEMAMvzlfi1XNmDgCYJv2+GXtYV84DGC7joy+0LBlpO9N8Ts5HdJdnJO7AVSnNOyhXhd7dpBYqVdKvlchJ6sspwEx4voKGdNrgQNHHIqM505vgOkmBjLBf1k3jeA8XP+ESDrZGN6S/Cvxf+H5W/DPDNfPDu2DrOSDJ3tMvNN7ivO5DjwTlHU7Q9uSuZh5Slm1OSTfagB0asbA3bnlDsaE0aN/V0ZIuC4jJfsdqMj6k2QhLM0RGUG1/q9Os4cJxwzkte6mL1feg6H/j+tOu7Xd+Ql6NhcjwvcCaAFwXUuPatnZmk+usuDeo5kF8Q3AFb2v1ZEWtmN2RI4ZhzF6esix54nHRkTNssuy/eKItm0e7GK+uy4vlEumBef0AwAv9DwkWJBs38HBXtBaHQncCDvmwS5Ge77v92SF5qcLUravV0dth6INclm9J+ekbBe3aeWE6SbrMrElK1mXljOzHfQsei/tDvqYRZ3EyGJOstMe6b1tZII9IhsVkxj5jto1Csf1U5OaBDKP55JfANzQbcQSj/YMM5JPPBK+MRQKtykbLNud0CMF7oqv92gPMUQnL3kk3K/DlQKjNWHbFo0ozy+uerJncJJPv73Z4UFCXVQ5Vi4iR1NyNCjHIg/2ELXBdzzBOcdu1XppsUy5Wh04bisHv6Ta68DTT80afJEVZCFcKDjUeJ7BkyvKsyTn/jzZuqv3/E6O+pMZS5LzSdZlXkpwyPOki/KOZ5U2YBsWrtDMN39mNQB+AJlbZJJCvhAMAAAAAElFTkSuQmCC"
            />
          </svg>

          <div className="w-full flex items-center justify-between">
            <div className="text-xl font-mono text-gray-600 light:text-gray-400 tracking-widest">
              xxx xxx xxx xxx
            </div>
            <div className="text-lg font-mono text-gray-600 light:text-gray-400 tracking-wider">
              xx/xx
            </div>
          </div>
        </div>
      </div>

      <div className="w-full grid grid-cols-1 gap-2 ">
        <div
          onClick={() => navigate("deposit")}
          className="cursor-pointer text-[#fff] text-center bg-gradient-to-r from-indigo-500 to-indigo-600 border light:from-[--indigo-purple] light:to-[--indigo-purple-light] light:border-[--indigo-purple] light:hover:border-light-border border-indigo-500 hover:border-indigo-300 text-lg transition-colors rounded-xl p-3"
        >
          Deposit
        </div>
        <div
          onClick={() => navigate("withdraw")}
          className="cursor-pointer text-center bg-gradient-to-r from-gray-600 to-gray-700 light:from-transparent light:to-transparent light:border-gray-300 border border-gray-700 hover:border-gray-500 light:hover:border-gray-400 light:!bg-gray-100 rounded-xl text-lg p-3 transition-colors"
        >
          Withdraw
        </div>
      </div>
    </div>
  );
}
