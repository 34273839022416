import React, { createContext, useState } from "react";

const StateProvider = createContext();

function StatesContextProvider({ children }) {
  const [activeTab, setActiveTab] = useState("unassigned");
  const [search, setSearch] = useState("");
  const [filterOption, setFilterOption] = useState("");

  return (
    <StateProvider.Provider
      value={{
        activeTab,
        setActiveTab,
        search,
        setSearch,
        filterOption,
        setFilterOption,
      }}
    >
      {children}
    </StateProvider.Provider>
  );
}

export { StatesContextProvider, StateProvider };
