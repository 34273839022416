import React from "react";
const leastOneNumber = new RegExp(".*[0-9].*");
const leastOneUpperCase = new RegExp(".*[A-Z].*");

function InputRequirment({ data }) {
  return (
    <>
      <div className="flex items-center gap-2 mt-3">
        <span
          className={`w-3 h-3 rounded-full ${
            checkLength(data) ? "bg-green-500" : "bg-red-600"
          }  `}
        ></span>
        <span
          className={`text-sm ${
            checkLength(data) ? "text-green-500" : "text-red-600"
          }  `}
        >
          At least 8 character
        </span>
      </div>
      <div className="flex items-center gap-2 pt-2">
        <span
          className={`w-3 h-3 rounded-full ${
            checkOneUpperCase(data) ? "bg-green-500" : "bg-red-600"
          }  `}
        ></span>
        <span
          className={`text-sm ${
            checkOneUpperCase(data) ? "text-green-500" : "text-red-600"
          }  `}
        >
          One Uppercase
        </span>
      </div>
      <div className="flex items-center gap-2 pt-2">
        <span
          className={`w-3 h-3 rounded-full ${
            checkOneNumber(data) ? "bg-green-500" : "bg-red-600"
          }  `}
        ></span>
        <span
          className={`text-sm ${
            checkOneNumber(data) ? "text-green-500" : "text-red-600"
          }  `}
        >
          At least one number
        </span>
      </div>
    </>
  );
}

function checkOneNumber(data) {
  if (leastOneNumber.test(data)) {
    return true;
  } else {
    return false;
  }
}

function checkOneUpperCase(data) {
  if (leastOneUpperCase.test(data)) {
    return true;
  } else {
    return false;
  }
}

function checkLength(data) {
  if (data.length >= 8) {
    return true;
  } else {
    return false;
  }
}

function checkRequirement(data) {
  const length = checkLength(data);
  const upperCase = checkOneUpperCase(data);
  const number = checkOneNumber(data);

  return [length, upperCase, number];
}

export { InputRequirment, checkRequirement };
