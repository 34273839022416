import RequestDetailsForm from "../../../components/AdminShared/RequestDetailsForm";
import RequestDetailsInput from "../../../components/AdminShared/RequestsDetailsInput";
import RequestDetailsButton, {
  RequestDetailsButtonLayout,
} from "../../../components/AdminShared/RequestsDetailsButton";

const inputStyle = "!input-md !text-xl text-black";

const StatUpdate = ({ formData, setFormData, handleUpdate }) => {
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="title text-center">
        <h3 className="text-lg text-white">Stat Update</h3>
      </div>

      <RequestDetailsForm>
        <RequestDetailsInput
          title="Active traders"
          name="active_traders"
          titleClass="text-xl"
          optClass={inputStyle}
          value={formData.active_traders}
          onChange={handleInputChange}
        />
        <RequestDetailsInput
          title="Paid profit"
          name="paid_profit"
          titleClass="text-xl"
          optClass={inputStyle}
          value={formData.paid_profit}
          onChange={handleInputChange}
        />
        <RequestDetailsInput
          title="total capital under managment"
          name="total_capital_under_managment"
          titleClass="text-xl"
          optClass={inputStyle}
          value={formData.total_capital_under_managment}
          onChange={handleInputChange}
        />
        <RequestDetailsInput
          title="average total capital profit"
          name="average_total_capital_profit"
          titleClass="text-xl"
          optClass={inputStyle}
          value={formData.average_total_capital_profit}
          onChange={handleInputChange}
        />
        <RequestDetailsInput
          title="win win capital fund"
          name="win_win_capital_fund"
          titleClass="text-xl"
          optClass={inputStyle}
          value={formData.win_win_capital_fund}
          onChange={handleInputChange}
        />
        <RequestDetailsInput
          title="average capital profit"
          name="average_capital_profit"
          titleClass="text-xl"
          optClass={inputStyle}
          value={formData.average_capital_profit}
          onChange={handleInputChange}
        />
      </RequestDetailsForm>
      <RequestDetailsButtonLayout>
        <RequestDetailsButton
          title="Update"
          onclick={handleUpdate}
          optClass="btn-md btn-primary"
        />
      </RequestDetailsButtonLayout>
    </>
  );
};

export default StatUpdate;
