import { format } from "date-fns";
export default function Row({
  data,
  handleClick,
  handleClickTitle = "",
  newMessageRecieved = false,
}) {
  return (
    <div
      className={`dashboard-box border border-gray-700 relative group  ${
        newMessageRecieved && "!border-gray-500"
      }`}
    >
      {newMessageRecieved && (
        <div className="badge badge-error badge-sm absolute -left-2 -top-2 z-20" />
      )}
      <div
        onClick={() => handleClick(data.id)}
        className="cursor-pointer group-hover:visible group-hover:w-1/2 transition-all invisible rounded-md w-0  top-1 right-1 bottom-1 left-auto absolute flex items-center justify-center bg-gray-600/20 backdrop-blur-sm text-white text-xl overflow-hidden hover:bg-indigo-200/20 light:hover:bg-yellow-300/30 light:bg-gray-400/30"
      >
        {handleClickTitle}
      </div>
      <div className="w-full flex items-center justify-between">
        <div className="text-indigo-200 light:text-gray-800">{data.title}</div>
        <div className="text-second">
          {format(new Date(data.updated_at), "yyyy-MM-dd HH:mm")}
        </div>
      </div>
      <div>
        <p className="max-w-xl mb-2 mt-3 text-[--primary-text]">
          {data.message.substring(0, 100)}
          {data.message.length >= 100 ? "..." : ""}
        </p>
      </div>
      <div className="w-full flex items-center justify-between">
        <div className="text-second">
          <p className="break-words">
            Name:{" "}
            <span className="text-gray-400 light:text-gray-700">
              {data.user.first_name + " " + data.user.last_name}
            </span>
          </p>
          <p className="break-words">
            Email:{" "}
            <span className="select-all text-gray-400 light:text-gray-700 ">
              {data.user.email}
            </span>
          </p>
        </div>
        <div className="flex items-center gap-2">
          {data.categories[0] && (
            <div className="px-2 rounded-md bg-gray-500/50 text-gray-200 pt-0.5">
              {data.categories[0]?.name}
            </div>
          )}
          <div
            className={`px-2 rounded-md pt-0.5 ${
              data.state === "Closed"
                ? "bg-red-500/30 text-red-400"
                : data.state === "Pending"
                ? "bg-yellow-500/30 text-yellow-400 light:text-yellow-900"
                : data.state === "Resolved"
                ? "bg-green-500/30 text-green-400 light:text-green-900"
                : ""
            }`}
          >
            {data.state}
          </div>
        </div>
      </div>
    </div>
  );
}
