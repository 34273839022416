import React from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { getData } from "../../utils/fetchData";
import { toast } from "react-toastify";

export function TicketRow({
  data,
  admin,
  ticketType,
  setTicketType = null,
  getUnAssignedTickets,
}) {
  const { title, date, bodyText, state, user, categories, seen, seen_admin } =
    data;
  const navigate = useNavigate();

  const handleClick = () => {
    if (!admin) {
      navigate("chat/" + data.id);
    }
    if (admin && !ticketType) {
      navigate("admin-chats/" + data.id);
    }
  };
  const handleAssign = async () => {
    // console.log("here");
    const res = await getData("admin/tickets/answer/" + data.id);
    if (res?.data) {
      getUnAssignedTickets();
      toast.success(res.data.message);
      // setTicketType(0);
    } else {
      toast.error("error fetching data");
    }
  };

  return (
    <div
      onClick={handleClick}
      className="w-full p-4 bg-[#4743e040]/30 rounded-xl cursor-pointer shadow-[0_0_2px_0] group relative shadow-neutral-900 hover:bg-[#4743e040] transition-colors"
    >
      <div className="flex items-center justify-between">
        <span className="font-bold text-neutral-100">
          {title}
          {admin && !seen_admin && (
            <span className="badge badge-error badge-xs ml-2"></span>
          )}
          {!admin && !seen && (
            <span className="badge badge-error badge-xs ml-2"></span>
          )}

          {admin && ticketType ? (
            <button
              onClick={handleAssign}
              className="hidden group-hover:block absolute  left-0 top-0 rounded-xl w-full h-full bg-indigo-700/80 backdrop-blur-[1px] text-white font-bold "
            >
              assign to yourself
            </button>
          ) : (
            ""
          )}
        </span>

        <span className="text-neutral-100 font-semibold">
          {format(new Date(date), "yyyy-MM-dd HH:mm")}
        </span>
      </div>

      <p className="max-w-xl mb-2 mt-3 text-neutral-100">
        {bodyText.substring(0, 100)}
        {bodyText.length >= 100 ? "..." : ""}
      </p>
      {state && (
        <div className="flex items-center justify-between gap-x-3">
          <div>
            {admin && categories[0] && (
              <span className="mr-2 font-medium badge badge-info">
                {categories[0]?.name}
              </span>
            )}
            <div
              className={`badge badge-outline ${
                state === "Closed"
                  ? "badge-error"
                  : state === "Pending"
                  ? "badge-warning"
                  : state === "Resolved"
                  ? "badge-success"
                  : ""
              }`}
            >
              {state}
            </div>
          </div>
          {user && (
            <div className="text-white">
              <span className="mr-4">
                Name: {user.first_name + " " + user.last_name}
              </span>
              <span>Email: {user.email}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
