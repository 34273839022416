import BarChart from "../Dashboard/BarChart";
export default function EquityBalanceChart({ data, loading }) {
  const { chart_balance, chart_equity } = data || {
    chart_balance: { data: null, dates: null },
    chart_equity: { data: null },
  };
  return (
    <div className="w-full grid grid-cols-1 min-[1100px]:grid-cols-4 gap-5">
      <BarChart
        key={Math.random()}
        layoutClass="max-[400px]:!block"
        loading={loading}
        outsideTitle="Balance"
        series={[
          {
            name: "Balance",
            type: "line",
            color: "#5d35b0",
            data: chart_balance.data,
          },
        ]}
        options={{ tooltip: { followCursor: false } }}
        xAxis={chart_balance.dates}
        legend={{ horizontalAlign: "right" }}
        group="finance"
        id="balance"
      />
      <BarChart
        key={Math.random()}
        layoutClass="max-[400px]:!block"
        loading={loading}
        outsideTitle="Equity"
        series={[
          {
            name: "Equity",
            type: "line",
            color: "#13807a",
            data: chart_equity.data,
          },
          {
            name: "Daily Drawdown",
            type: "line",
            color: "#D84040",
            data: [
              ...Array.from({ length: chart_equity?.data?.length - 4 }).map(
                (i) => null
              ),

              ...Array.from({ length: 4 }).map((i) => data?.draw_down),
            ],
          },
        ]}
        options={{ tooltip: { followCursor: false } }}
        xAxis={chart_equity.dates}
        group="finance"
        id="equity"
        strokeOptions={{ dashArray: [0, 3], width: [3, 1] }}
        legend={{ horizontalAlign: "right" }}
      />
    </div>
  );
}
