import React from "react";
import Loading from "../../../components/shared/Loading";
import StatUpdate from "./StatUpdate";
import TableBackground from "../../../components/AdminShared/TableBackground";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import { useStats } from "../../../hooks/Admin/useStats";

const StatLayout = () => {
  const { loading, handleUpdate, error, formData, setFormData } = useStats();

  return (
    <>
      <TableBackground>
        <div>
          {loading ? (
            <div className="w-full text-center">
              <Loading />
            </div>
          ) : error ? (
            <ErrorMessage text={error} />
          ) : (
            <StatUpdate
              handleUpdate={handleUpdate}
              formData={formData}
              setFormData={setFormData}
            />
          )}
        </div>
      </TableBackground>
    </>
  );
};

export default StatLayout;
