import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/shared/Loading";
import TopTraderTable from "./TopTraderTable";
import { useEffect, useState } from "react";
import {
  getGoldenPlanTopTrader,
  getMaximumWithdraw,
  getToptraders,
} from "../../../store/slices/topTrader";

export default function TopTraderContainer() {
  const topTrader = useSelector((state) => state.topTrader);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const topTraders = topTrader.topTraders.slice(0, 3);
  const golden = topTrader.goldenPlanTopTrader.slice(0, 3);
  const withdraw = topTrader.maximumWithdraws.slice(0, 3);

  useEffect(() => {
    setLoading((x) => true);
    if (topTraders.length > 0 && withdraw.length > 0 && golden.length > 0) {
      setLoading((x) => false);
    }
  }, [topTraders, withdraw, golden]);

  useEffect(() => {
    if (topTraders.length === 0) {
      dispatch(getToptraders());
    }
  }, [dispatch, topTraders]);

  useEffect(() => {
    if (withdraw.length === 0) {
      dispatch(getMaximumWithdraw());
    }
  }, [dispatch, withdraw]);

  useEffect(() => {
    if (golden.length === 0) {
      dispatch(getGoldenPlanTopTrader());
    }
  }, [dispatch, golden]);

  return (
    <div className="max-[400px]:hidden max-[1400px]:col-[1/-1] bg-[--box-items-background] rounded-xl p-5">
      {loading ? (
        <div className="w-full h-full flex items-center justify-center opacity-30">
          <Loading />
        </div>
      ) : (
        <div className="flex h-full flex-col items-start justify-between gap-5 py-5">
          <TopTraderTable data={withdraw} title="Max Withdraws" />
          <TopTraderTable data={golden} title="Golden Top Traders" />
          <TopTraderTable data={topTraders} title="Top Traders" />
        </div>
      )}
    </div>
  );
}
