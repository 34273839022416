const AttachPermissionSelect = ({
  isOpen,
  searchQuery,
  setSearchQuery,
  submitAttachPermission,
  attachPermissionLoading,
  allPermissions,
}) => {
  const filteredOptions = Object.keys(allPermissions).filter((option) =>
    allPermissions[option].includes(searchQuery.name)
  );

  const optionSelected = filteredOptions.includes(searchQuery.value);

  function handleOnclick(option) {
    setSearchQuery({ name: allPermissions[option], value: option });
  }

  return (
    isOpen && (
      <div className="absolute z-50 right-5 top-14 w-full max-w-[250px] mt-3 bg-gray-700 rounded-xl overflow-hidden  shadow-gray-800 shadow-[0_0_8px_4px] light:shadow-[0_0_6px_1px] light:shadow-gray-700">
        <div>
          <input
            disabled={attachPermissionLoading}
            type="text"
            className={`input input-sm w-full !max-w-[300px] bg-gray-300 rounded-none py-6 text-gray-700 !border-none ${
              optionSelected && "!bg-transparent !text-[#fff]"
            }`}
            value={searchQuery.name}
            onChange={(e) =>
              setSearchQuery((prev) => ({ value: "", name: e.target.value }))
            }
            autoFocus
            placeholder="Search..."
          />
          {optionSelected && (
            <button
              disabled={attachPermissionLoading}
              onClick={submitAttachPermission}
              className="btn btn-info w-full disabled:bg-info"
            >
              {attachPermissionLoading ? (
                <span className="loading loading-dots loading-sm" />
              ) : (
                "Submit"
              )}
            </button>
          )}
        </div>

        <div className="bg-gray-700 flex flex-col max-h-[300px] overflow-auto">
          {filteredOptions.length > 0 ? (
            !optionSelected &&
            filteredOptions.map((option, index) => (
              <div
                tabIndex={0}
                key={index}
                className="text-gray-300 hover:bg-gray-800/50  p-3 transition-colors border-b border-b-gray-600/70"
                onClick={() => handleOnclick(option)}
              >
                {allPermissions[option]}
              </div>
            ))
          ) : (
            <div className="p-3 text-center text-[--primary-text]">
              No results found
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default AttachPermissionSelect;
