import React, { useEffect, useRef, useState } from "react";
import { IoIosAttach } from "react-icons/io";
import { IoIosClose } from "react-icons/io";
const defaultInputs = {
  message: "",
  isPrivate: false,
  files: [],
};

export function ChatForm({ addNewMessage, addNewFile, setNewMessage }) {
  const fileInputRef = useRef(null);
  const [inputs, setInputs] = useState(defaultInputs);
  const [fileUpload, setFileUpload] = useState(null);
  const send = useRef();

  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.ctrlKey && event.key === "Enter") {
        send.current.click();
      }
    });
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileUpload(file.name);
    setInputs((prev) => ({ ...prev, message: "" }));
  };

  const handleButtonClick = () => {
    console.log(fileInputRef);
    fileInputRef.current.click();
  };

  function handleSubmit() {
    setNewMessage((prev) => !prev);
    if (inputs.message) {
      addNewMessage(inputs);
    }
    if (fileInputRef.current.files[0]) {
      addNewFile(fileInputRef.current.files[0]);
    }
    fileInputRef.current.value = "";
    setNewMessage((prev) => !prev);
    setFileUpload((prev) => null);
    setInputs(defaultInputs);
  }
  function handleCancelFile() {
    fileInputRef.current.value = "";
    setFileUpload((prev) => null);
  }
  return (
    <div className="w-full border border-neutral-400 shadow-md px-2 md:px-10 py-5 rounded-lg">
      <form onSubmit={(e) => e.preventDefault()}>
        {fileUpload ? (
          <p className="rounded-md flex items-center gap-2 bg-[var(--indigo-purple)] text-white max-w-fit pl-2 my-2 md:my-0">
            <span className="-mb-1">{fileUpload}</span>
            <button
              className="p-1 hover:bg-[var(--indigo-purple-hover)] rounded-lg"
              onClick={handleCancelFile}
            >
              <IoIosClose size={30} />
            </button>
          </p>
        ) : (
          <textarea
            value={inputs.message}
            onChange={(e) =>
              setInputs((prev) => ({ ...prev, message: e.target.value }))
            }
            onInvalid={(e) => e.preventDefault()}
            required={true}
            className="w-full bg-transparent outline-0 border-0 text-neutral-100"
            placeholder="write your message here"
            rows="2"
          />
        )}
        <div className="flex items-start md:items-center md:flex-row gap-y-2 justify-end">
          <div className="flex items-center gap-2">
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept="*"
              hidden
              onChange={handleImageChange}
            />
            <button className="btn " onClick={handleButtonClick}>
              <IoIosAttach size={20} />
              Attach
            </button>
            <button
              ref={send}
              className="btn bg-[var(--indigo-purple)] hover:bg-[var(--indigo-purple-hover)] text-white border-indigo-600 hover:border-indigo-500"
              onClick={handleSubmit}
            >
              send
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
