import { useEffect, useState } from "react";
import useDidMount from "../../useDidMount";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../API/useFetch";
import { usePut } from "../../API/usePut";
import { useDelete } from "../../API/useDelete";

const usePost = (pagination, postId) => {
  const [post, setPost] = useState(null);
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [postLinks, setPostLinks] = useState(null);
  const didMount = useDidMount(true);
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();
  const { loading, error, fetchData } = useFetch();
  const { loading: putLoading, error: putError, handlePutData } = usePut();

  const {
    loading: deleteLoading,
    error: deleteError,
    handleDeleteData,
  } = useDelete();

  const getPost = async (id) => {
    const res = await fetchData("post/" + id);
    if (!res) return;
    setPost(res.post);
    setCategories(res.categories);
  };

  const getPosts = async () => {
    const { posts } = await fetchData("post?page=" + pagination);

    setPosts(posts.data);
    setPostLinks(posts);
    setRefresh(false);
  };

  const updatePost = async (data, id) => {
    const { _ } = await handlePutData(
      "post/" + id,
      data,
      "Post successfully updated"
    );
    navigate("/dashboard/post");
  };

  const deletePost = async (id) => {
    const { _ } = await handleDeleteData("post/" + id, "deleted Successfully");
    setRefresh(true);
  };

  useEffect(() => {
    if (
      pagination &&
      ((posts.length === 0 && didMount) || (refresh && didMount))
    ) {
      getPosts();
    }
  }, [didMount, posts.length, refresh]);

  useEffect(() => {
    getPosts();
  }, [pagination]);

  useEffect(() => {
    if (postId && post === null) {
      getPost(postId);
    }
  }, [didMount, postId]);

  return {
    error,
    loading,
    posts,
    postLinks,
    updatePost,
    post,
    deletePost,
    setRefresh,
    putLoading,
    putError,
    deleteLoading,
    deleteError,
    categories,
  };
};

export default usePost;
