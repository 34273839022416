import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getData, putData } from "../../utils/fetchData";
import { useFetch } from "../API/useFetch";
const initialFormData = {
  active_traders: null,
  paid_profit: null,
  total_capital_under_managment: null,
  average_total_capital_profit: null,
  win_win_capital_fund: null,
  average_capital_profit: null,
};

export function useStats() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const getStats = async () => {

    setLoading((prev) => true);
    setError((prev) => false);
    const res = await getData("admin-dashboard-stats");
    if (res?.data) {
      setFormData(res.data.dashboard_stats);
    } else {
      setError((prev) => "Error fetching data!");
      toast.error(res?.data?.message || "Unexpected error!");
    }

    setLoading((prev) => false);
  };

  const handleUpdate = async () => {
    setLoading((prev) => true);

    const res = await putData("admin-dashboard-stats/" + 1, formData);
    if (res?.data) {
      toast.success("successfully updated!");
      getStats();
    } else {
      setLoading((prev) => false);
      toast.error(res?.data?.message || "Unexpected error!");
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  return { loading, handleUpdate, error, formData, setFormData };
}
