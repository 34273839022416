import React from "react";
import Loading from "../../components/shared/Loading";
import ChangeUserState from "./ChangeUserState";
import usePermissions from "../../hooks/usePermission";

const labels = ["email", "name", "balance", "phase", "challenge"];
export default function GeneralInfo({ loading, data }) {
  const { can } = usePermissions();
  return (
    <div className="dashboard-box">
      <div className="w-full flex max-sm:flex-col max-sm:gap-3  sm:items-center justify-between">
        <div className="text-second text-2xl ">Challenge Details</div>
        <div className="flex items-baseline gap-3 rounded-md">
          {!loading && (
            <div
              className={`py-1 px-3 rounded-[inherit] capitalize
                ${
                  data?.status === "inactive"
                    ? "bg-red-500/20 text-error "
                    : "bg-green-500/20 text-success"
                }
                `}
            >
              {data?.status}
            </div>
          )}
          <div className="bg-[--indigo-purple] text-white py-1 px-3 rounded-[inherit]">
            All times are in GMT
          </div>
        </div>
      </div>
      <hr className="border-gray-700 light:border-gray-300 my-5" />
      {loading ? (
        <div className="w-full h-[210px] flex items-center justify-center opacity-30">
          <Loading />
        </div>
      ) : (
        <>
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-5  ">
            {labels.map((label) => (
              <Input title={label} value={data[label]} />
            ))}

            {can("update_challenge") && (
              <>
                {data.server && <Input title="Server" value={data.server} />}
                {data.platform && (
                  <Input title="Platform" value={data.platform} />
                )}
              </>
            )}

            {data.state && <Input title="state" value={data.state} />}
          </div>
          {data.reason && (
            <div className="mt-3 sm:mt-5">
              <Input
                title="Fail Reason"
                titleClass="!text-error"
                value={data.reason}
                otpClass="bg-gray-500/20 !select-auto !border !border-error"
              />
            </div>
          )}
          {can("update_challenge") && <ChangeUserState status={data.status} />}
        </>
      )}
    </div>
  );
}
function Input({ title, value, otpClass, titleClass }) {
  return (
    <div className={`form-control w-full`}>
      <div className="label">
        <span
          className={`text-second font-semibold light:text-gray-600 capitalize ${titleClass}`}
        >
          {title}
        </span>
      </div>
      <div
        className={`w-full p-3 rounded-lg border border-transparent outline-0 bg-gray-900/40  text-gray-400 cursor-not-allowed light:border-gray-300 light:bg-gray-300/50 light:text-gray-500 select-all text-wrap break-words ${otpClass} `}
      >
        {value}
      </div>
    </div>
  );
}
