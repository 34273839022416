import React from "react";
import { ToastContainer } from 'react-toastify';

export default function AuthContainer({ children }) {
  return (
    <div className="w-full h-screen overflow-hidden flex bg-[#212121]">
      <ToastContainer />
      {children}
    </div>
  );
}
