import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import TableBackground from "./TableBackground";
import RequestDetailsButton from "./RequestsDetailsButton";

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

export default function DropZoneUpdateComponent({ serverImage, handleUpdateImage, loading}) {
    const [files, setFiles] = useState([]);

    // useEffect(() => {
    //     if (img) {
    //         setFiles([{ preview: img }]);
    //     }
    // }, [img]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div>
    ));

    const handleUpdate = () => {
        handleUpdateImage(files);
    }

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
        <TableBackground>
            <div className=" flex items-center justify-between text-center text-white mb-5">
                <h3 className="text-2xl text-center w-full ">Update Image</h3>
            </div>

            <div>
                <p className={"text-white"}>current image:</p>
                <img src={serverImage} alt="" className="w-[200px]"/>
            </div>

            <div className={`form-control `}>
                <div className="p-5">
                    <div {...getRootProps({className: 'dropzone !bg-[#00000055] !text-slate-300 p-5'})}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                    <aside style={thumbsContainer}>
                        {thumbs}
                    </aside>
                </div>
                <RequestDetailsButton
                    title={loading ? "Updating..." : "Update"}
                    onclick={handleUpdate}
                    disabled={loading}
                    optClass="btn-success disabled:bg-success/30 disabled:text-neutral-900"
                />
            </div>
        </TableBackground>
    );
}