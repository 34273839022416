import Loading from "../../components/shared/Loading";
import { formatComma } from "../../utils/formatNumber";
import Donut from "../Referrals/Donut";

const lowerPart = [
  "succeeded_trade_count",
  "failed_trade_count",
  "average_profit_percent",
  "average_loss_percent",
  "best_trade",
  "worst_trade",
  "highest_daily_balance",
  "traded_volume",
];
export default function ChallengeStatistics({ data, loading }) {
  return (
    <div className="dashboard-box w-full mt-10">
      <div className="text-2xl text-second mb-5 -ml-5 -mt-10 ">
        <span className="bg-gray-800 max-sm:text-base light:bg-gray-200 py-2 px-3 md:px-10 rounded-box">
          Challenge Trade Statistics
        </span>
      </div>
      <div className=" grid grid-cols-1 xl:grid-cols-3 gap-x-5">
        <div className="xl:col-span-2">
          <div>
            <div className="w-full grid grid-cols-2 lg:grid-cols-4 gap-y-3 dividex divide-gray-700 dashboard-box !pb-3 !bg-gray-700/10 light:!bg-gray-400/10 !rounded-b-none border border-[--chart-grid]">
              <TopPartItem
                title="Total Trades"
                value={loading ? null : data?.upper_part.total_trades}
              />
              <TopPartItem
                title="Overall Profit"
                value={
                  loading
                    ? null
                    : `$${formatComma(data?.upper_part.overall_profit)}`
                }
                optValClass={
                  data?.upper_part.overall_profit >= 0
                    ? "!text-success"
                    : "!text-error"
                }
              />
              <TopPartItem
                title="Profit Factor"
                value={loading ? null : data?.upper_part.profit_factor}
              />
              <TopPartItem
                title="Golden Factor"
                value={loading ? null : data?.upper_part.golden_factor}
              />
            </div>
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 min-[900px]:grid-cols-2 gap-x-10 gap-y-5 text-second text-lg mt-7 [&>div:not(:last-child)]:border- [&>div:nth-last-child(2)]:!border-b-0 [&>div:not(:last-child)]:border-b-gray-700/20">
              {lowerPart.map((item) => (
                <BottomList
                  title={item.replaceAll("_", " ")}
                  value={loading ? null : data?.lower_part[item]}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="flex items-start justify-center h-full max-xl:mt-7">
          <div className=" max-w-[250px] h-full">
            {/* green for win 047857 */}
            {!loading ? (
              data?.donut_chart && (
                <Donut
                  series={data?.donut_chart ? data.donut_chart.counts : [0, 0]}
                  labels={
                    data?.donut_chart ? data.donut_chart.names : ["Win", "Loss"]
                  }
                  colors={["var(--indigo-purple)", "#ef4444"]}
                  horizontalAlign="center"
                  text=""
                  className=""
                  optPadding={{ top: 0 }}
                  tooltipFormatter={(value) =>
                    "%" +
                    ((value / data.upper_part.total_trades) * 100).toFixed(2)
                  }
                />
              )
            ) : (
              <div className="w-full h-[300px] xl:h-full  flex items-center ">
                <Loading optClass="opacity-30" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
function BottomList({ title, value }) {
  return (
    <div className="w-full flex items-center justify-between pb-1">
      <div className="capitalize">{title}</div>
      {value === null ? (
        <div className="w-14 h-6 skeleton bg-gray-700/10 light:bg-gray-400/10 rounded-md" />
      ) : (
        <div className="text-white">{value}</div>
      )}
    </div>
  );
}

function TopPartItem({ title, value, optValClass }) {
  return (
    <div className="flex flex-col items-center">
      <div className="text-second text-xl">{title}</div>
      <div className={`text-white font-semibold text-3xl ${optValClass}`}>
        {value !== null ? (
          value
        ) : (
          <div className="w-20 h-8 skeleton bg-transparent rounded-md mb-1" />
        )}
      </div>
    </div>
  );
}
