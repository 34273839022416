import { SubmitButton } from "../../components/shared/SubmitButton";
import { useEffect, useState } from "react";
import { WithdrawInitialInput } from "./WithdrawInitialInput";
import { toast } from "react-toastify";
import { useFetch } from "../../hooks/API/useFetch";
import Loading from "../../components/shared/Loading";
const availableFrom = ["GF Wallet"];

export function InitialInfoForm({ handleSetWithdraw }) {
  const [balance, setBalance] = useState(null);
  const [withdrawFrom, setWithdrawFrom] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [error, setError] = useState(false);
  const { loading, fetchData } = useFetch();

  function handleSubmit(e) {
    e.preventDefault();
    console.log(balance);
    if (withdrawAmount < 12) {
      setError("Minimum withdraw is $12");
      toast.error("Minimum withdraw is $12");
      return;
    }
    if (withdrawAmount > +balance) {
      setError("Insufficient Funds!");
      toast.error("Insufficient Funds!");
      return;
    }
    handleSetWithdraw({
      amount: withdrawAmount,
      withdrawFrom: withdrawFrom,
      step: 2,
    });
  }

  const getBalance = async () => {
    const response = await fetchData("finance/wallets/balance");
    if (!response) return;

    setBalance(response.balance);
  };

  useEffect(() => {
    if (balance === null) {
      getBalance();
    }
  }, [balance]);

  return (
    <form
      onSubmit={handleSubmit}
      className="w-full grid grid-cols-1 gap-y-2 pl-8 relative my-6"
    >
      <WithdrawInitialInput
        func={setWithdrawFrom}
        value={withdrawFrom}
        // icon={<CiCreditCard1 size={50} className="mr-3 absolute left-0"/>}
        text={"Withdraw from"}
      >
        {availableFrom.map((option, index) => (
          <option value={option} key={index}>
            {option}
          </option>
        ))}
      </WithdrawInitialInput>
      <label htmlFor="amount" className="mt-3">
        Amount:
      </label>
      <div className="w-full flex items-center justify-start">
        {/* <CiCalculator1 size={50} className="mr-3 absolute left-0" /> */}
        {loading ? (
          <div className="w-full text-center max-w-xs">
            <Loading />
          </div>
        ) : (
          <input
            id="amount"
            value={withdrawAmount}
            required={true}
            onInvalid={(e) => {
              e.preventDefault();
              setError("Please fill the input");
            }}
            placeholder="Please Enter Withdraw Amount Here..."
            onChange={(e) => {
              setWithdrawAmount(e.target.value);
              setError(false);
            }}
            type="number"
            className="w-full max-w-xs bg-transparent border-2 placeholder:text-sm placeholder:opacity-50 text-white text-lg font-normal border-white rounded-lg p-2  focus:outline-0 [&>*]:bg-gray-700 placeholder:text-white"
          />
        )}
      </div>
      <span
        className={`text-sm text-rose-600 mb-1 ${error ? "block" : "hidden"}`}
      >
        {error}
      </span>

      <SubmitButton optClass="mt-5" />
    </form>
  );
}
