import { createSlice } from "@reduxjs/toolkit";
// import { getData } from '../../utils/fetchData';
import { authActions } from "./auth";
import { getToken } from "../../utils/getToken";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
// utils

// ----------------------------------------------------------------------

const initialState = {
  challenges: [],
};

const challengeReducer = createSlice({
  name: "challenge",
  initialState,
  reducers: {
    challenges(state, action) {
      state.challenges = action.payload;
    },
  },
});

export const challengeActions = challengeReducer.actions;
export default challengeReducer;

export function getChallenges() {
  return async (dispatch) => {
    let response;
    const userToken = getToken();
    try {
      let token = "Bearer " + userToken;
      response = await fetch(`${baseUrl}/api/challenges`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      if (!response.ok) {
        console.log("Failed to fetch data");
        if (response && response.status === 401) {
          await dispatch(authActions.logout());
        }
      }

      const jsonData = await response.json();
      if (jsonData?.data?.challenges) {
        const challengesList = jsonData.data.challenges;

        dispatch(challengeActions.challenges(challengesList));
      }
    } catch (error) {
      if (response && response.status === 401) {
        await dispatch(authActions.logout());
      }

      return null;
    }
  };
}
