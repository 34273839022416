import { useState } from "react";
import { getData } from "../../utils/fetchData";
import { toast } from "react-toastify";

export function useFetch() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchData = async (url, successToast = false) => {
    let data = null;
    setLoading((prev) => true);
    setError((prev) => false);
    const response = await getData(url);
    if (response?.data) {
      data = response.data;
      if (successToast && response.message) toast.success(response.message);
    } else {
      setError(true);
      toast.error(
        response?.message || "Error fetching data please try again later"
      );
    }
    setLoading((prev) => false);
    return data;
  };

  return { loading, error, fetchData };
}
