export default function CompactBox({
  title,
  data,
  icon,
  loading,
  optClass,
  titleClass,
}) {
  return (
    <div
      className={`bg-[--box-items-background] p-5 rounded-xl flex flex-col items-start justify-between gap-2 ${optClass}`}
    >
      <div className={`text-second font-semibold`}>{title}</div>
      <div className="w-full flex items-start justify-between">
        {loading ? (
          <div className="w-20 h-7 mb-2 skeleton bg-gray-700/30 rounded-md " />
        ) : (
          <div className={`text-white text-3xl font-semibold ${titleClass}`}>
            {data}
          </div>
        )}
        {icon}
      </div>
    </div>
  );
}
