import { useEffect, useState } from "react";
import { useFetch } from "../../API/useFetch";
import { usePost } from "../../API/usePost";
export function usePermissions(pagination) {
  const [permissions, setPermissions] = useState(null);
  const [newPermission, setNewPermission] = useState("");
  const { error, loading, fetchData } = useFetch();
  const { loading: addPermissionLoading, handlePostData } = usePost(false);

  async function handleGetData() {
    const res = await fetchData(`permission?page=${pagination}`);

    if (!res) return;

    setPermissions(res);
  }

  async function AddNewPermission() {
    const res = await handlePostData(
      "permission",
      { title: newPermission },
      true
    );

    if (!res) return;

    handleCloseModal();
  }

  function handleOpenModal() {
    document.getElementById("add-permission").showModal();
  }

  function handleCloseModal() {
    document.getElementById("add-permission").close();
    handleGetData();
  }

  useEffect(() => {
    handleGetData();
  }, [pagination]);

  return {
    error,
    loading,
    permissions,
    handleOpenModal,
    AddNewPermission,
    setNewPermission,
    addPermissionLoading,
  };
}
