import React from "react";

export function Input({
  label,
  name,
  value,
  type = "text",
  onChange,
  disabled,
}) {
  return (
    <div
      className={`relative w-full bg-neutral-900 rounded-xl border-y-2  border-y-white ${
        disabled ? "bg-slate-700" : ""
      }`}
    >
      <div className="text-xs text-neutral-400 bg-neutral-700 rounded-xl  leading-none px-3 pt-1 absolute -top-2 left-5 ">
        {label}
      </div>
      <input
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className="text-white bg-transparent border-0 outline-none px-5 py-3"
      />
    </div>
  );
}
