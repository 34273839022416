import React from 'react';
import { HiDownload } from "react-icons/hi";

const DownloadCSV = ({ data }) => {

  const convertToCSV = (array) => {
    const keys = Object.keys(array[0]);
    const csvRows = [keys.join(','), ...array.map(row => keys.map(key => row[key]).join(','))];
    return csvRows.join('\n');
  };

  const downloadCSV = () => {
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'data.csv');
    a.click();
  };

  return (
    <button onClick={downloadCSV} className="btn btn-outline text-[var(--indigo-purple)] hover:bg-[var(--indigo-purple-hover)] hover:text-white !h-auto !min-h-7 w-fit rounded-full my-2 p-1 px-3 ">
    download csv
    <HiDownload />
  </button>
  );
};

export default DownloadCSV;


