export default function RequestDetailsButton({
  title,
  onclick,
  optClass,
  children,
  ...props
}) {
  return (
    <button
      onClick={onclick}
      {...props}
      className={`w-full max-w-[90px] sm:max-w-[110px] btn btn-sm btn-outline pt-1 ${optClass}`}
    >
      {title}
      {children}
    </button>
  );
}

export function RequestDetailsButtonLayout({ children, optClass }) {
  return (
    <div className={`flex flex-wrap items-end gap-3 mt-10 mb-3 ${optClass}`}>
      {children}
    </div>
  );
}
