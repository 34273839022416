import { useEffect, useState } from "react";
import { useFetch } from "./API/useFetch";

export const useDashboardCharts = () => {
  const { error, loading, fetchData } = useFetch();
  const [totalChart, setTotalChart] = useState();
  const [avgProfit, setAvgProfit] = useState();

  const getChartData = async () => {
    const res = await fetchData("dashboard-plans-summary");
    if (!res) return;

    const transformedData = res.dashboard_plans_summary.map((item) => ({
      fund: parseFloat(item.fund),
      total_fund_under_managment: parseFloat(item.total_fund_under_managment),
      avg_total_profit: parseFloat(item.avg_total_profit),
    }));

    const series = {
      total: [
        {
          name: "Win-Win Fund",
          type: "line",
          color: "#7635b3",
          data: transformedData.map((item) => item.fund),
        },

        {
          name: "Total Fund Under Management",
          type: "line",
          color: "#cf364a",
          data: transformedData.map((item) => item.total_fund_under_managment),
        },
      ],
      avgProfit: [
        {
          name: "Avg Total Profit",
          type: "line",
          color: "#13807a",
          data: transformedData.map((item) => item.avg_total_profit),
        },
      ],
    };

    setTotalChart(series.total);
    setAvgProfit(series.avgProfit);
  };

  useEffect(() => {
    getChartData();
  }, []);

  return { error, loading, totalChart, avgProfit };
};
