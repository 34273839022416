import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFetch } from "./API/useFetch";
import { usePost } from "./API/usePost";
export function useEmailVerification() {
  const [result, setResult] = useState("");
  const [timer, setTimer] = useState(120);
  const navigate = useNavigate();
  const { fetchData } = useFetch();
  const { handlePostData } = usePost();

  async function sendCode() {
    const res = await fetchData("users/verification/send-otp", true);
    toast.success(res?.message);
  }

  async function verifyOtp(otpData) {
    const res = await handlePostData("users/verification/verify-otp", otpData);
    if (!res) return;
    navigate("/dashboard");
  }

  useEffect(() => {
    sendCode();
  }, []);

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((time) => time - 0.1);
      }, 100);
      return () => clearInterval(interval);
    }
  }, [timer]);

  useEffect(() => {
    if (result.length === 6) {
      const otpData = {
        otp: result,
      };
      verifyOtp(otpData);
    }
  }, [result]);

  const handleOnChange = (res) => {
    setResult((prev) => res);
  };

  async function handleResendEmail() {
    if (timer <= 0) {
      sendCode();
      setTimer(120);
    }
  }
  return { timer, handleOnChange, handleResendEmail };
}
