export function IndicatorItem({ num, text, currentStep, setStep }) {
  function handleClick() {
    if (currentStep > num) {
      setStep({ step: num });
    }
  }
  return (
    <span
      onClick={handleClick}
      className={`flex items-center gap-2 my-2 cursor-pointer ${
        currentStep >= num ? "opacity-100" : "opacity-50"
      }`}
    >
      <span className="min-w-5 min-h-5 max-w-5 max-h-5 flex items-center justify-center rounded-full  bg-[var(--indigo-purple)] pt-1">
        {num}
      </span>
      <span className="text-2xl pt-1">{text}</span>
    </span>
  );
}
