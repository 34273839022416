import { useEffect, useState } from "react";
import { useFetch } from "../../API/useFetch";
import { useNavigate } from "react-router-dom";
import { usePostImage } from "../../API/usePostImage";

const useCreatePost = () => {
  const [categories, setCategories] = useState([]);
  const { fetchData } = useFetch();
  const {
    loading: postImageLoading,
    error: postImageError,
    handlePostImage,
  } = usePostImage();

  const navigate = useNavigate();

  const createPost = async (data) => {
    const res = await handlePostImage(
      "post",
      data,
      "Post successfully created"
    );
    if (res) {
      navigate("/post");
    }
  };

  const getCategories = async () => {
    const res = await fetchData("blog/categories");
    if (!res) return;
    setCategories(res.categories);
  };

  useEffect(() => {
    getCategories();
  }, []);

  return {
    createPost,
    handlePostImage,
    postImageLoading,
    postImageError,
    categories,
  };
};

export default useCreatePost;
