import ReactPaginate from "react-paginate";

const style =
  "join-item text-gray-500 bg-transparent hover:underline underline-offset-4 border !border-gray-700 py-3 text-center !border-0 !rounded-md";

const DarkTablePagination = ({ handleOnClick, data, optClass }) => {
  const { last_page, current_page } = data;

  return (
    <ReactPaginate
      className={`w-full items-center join [&_*]:text-xs sm:[&_*]:text-sm !rounded-none ${optClass} ${
        last_page > 3 ? "!max-w-[400px]" : "max-w-[300px]"
      }`}
      activeLinkClassName="!text-white !text-lg"
      pageClassName="grow inline-flex !border-y-0"
      previousClassName="grow inline-flex"
      nextClassName="grow inline-flex"
      pageLinkClassName={`${style} w-full`}
      previousLinkClassName={`${style} w-full !bg-transparent hover:!bg-gray-700/50 hover:!no-underline !border !border-gray-700/50 `}
      nextLinkClassName={`${style} w-full !bg-transparent hover:!bg-gray-700/50 hover:!no-underline !border !border-gray-700/50 `}
      breakClassName="grow inline-flex"
      breakLinkClassName={`${style} grow`}
      forcePage={current_page - 1}
      breakLabel="..."
      nextLabel="Next"
      onPageChange={(event) => handleOnClick(event)}
      pageRangeDisplayed={2}
      marginPagesDisplayed={1}
      pageCount={last_page}
      previousLabel="Previous"
      renderOnZeroPageCount={null}
    />
  );
};

export default DarkTablePagination;
