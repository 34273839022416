import { MdErrorOutline } from "react-icons/md";
export default function ErrorMessage({
  text = "Data not found!",
  optcClass,
  children,
}) {
  return (
    <div
      className={`flex items-center gap-1 w-full text-warning my-1 ${optcClass} `}
    >
      <MdErrorOutline />
      <span className="-mb-2">{text}</span>
      {children}
    </div>
  );
}
