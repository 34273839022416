export function goldenStartTime() {
  const currentDate = new Date();
  const dayOfMonth = currentDate.getDate();
  let content = null;
  if (dayOfMonth >= 1 && dayOfMonth <= 7) {
    content = currentDate.toDateString();
  } else {
    const nextMonth = currentDate.getMonth() + 1;
    const nextMonthDate = new Date(currentDate.getFullYear(), nextMonth, 1);
    content = nextMonthDate.toDateString();
  }

  return content;
}
