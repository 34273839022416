import React from "react";
import Pagination from "../../../components/AdminShared/Pagination";
import Loading from "../../../components/shared/Loading";
import { Link } from "react-router-dom";
import useUserChallenge from "../../../hooks/Admin/useUserChallenge";
import TableBackground from "../../../components/AdminShared/TableBackground";
import Table from "../../../components/AdminShared/Table";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import { usePagination } from "../../../hooks/usePagination";
import RequestDetailsInput from "../../../components/AdminShared/RequestsDetailsInput";
import RequestDetailsButton, {
  RequestDetailsButtonLayout,
} from "../../../components/AdminShared/RequestsDetailsButton";
import FilterSelect from "../../../components/AdminShared/FilterSelect";

const initialFilter = {
  state: "",
  phase: "",
  challenge: "",
  fromStartTime: "",
  email: "",
};

const filterOptions = ["state", "phase", "challenge", "fromStartTime", "email"];

export default function UserChallenges() {
  const {
    pagination,
    handleFilterChange,
    query,
    handleSubmitFilter,
    handleChangePagination,
    filter,
  } = usePagination(initialFilter, filterOptions);

  const { error, loading, challengesData } = useUserChallenge(
    query,
    pagination
  );

  return (
    <>
      <TableBackground>
        {loading ? (
          <div className="w-full flex items-center justify-center h-[600px]">
            <Loading />
          </div>
        ) : error ? (
          <ErrorMessage />
        ) : (
          <div className="content mx-5 py-5 text-center text-white ">
            <form onSubmit={handleSubmitFilter} className="mb-10 text-end">
              <div className="w-full grid grid-cols-2 lg:grid-cols-5 gap-x-5">
                <RequestDetailsInput
                  title="Email"
                  value={filter.email}
                  name="email"
                  onChange={handleFilterChange}
                  titleClass="font-semibold"
                  placeholder="Email Address"
                  optClass="text-black"
                />

                <FilterSelect
                  title="state"
                  name="state"
                  value={filter.state}
                  handleChange={handleFilterChange}
                  options={challengesData?.state_type_names}
                />

                <FilterSelect
                  title="phase"
                  name="phase"
                  value={filter.phase}
                  handleChange={handleFilterChange}
                  options={challengesData?.phase_type_names}
                />
                <FilterSelect
                  title="challenge"
                  name="challenge"
                  value={filter.challenge}
                  handleChange={handleFilterChange}
                  options={challengesData?.challenge_type_names}
                />

                <RequestDetailsInput
                  title="Date From"
                  type="date"
                  value={filter.fromStartTime}
                  name="fromStartTime"
                  onChange={handleFilterChange}
                  titleClass="font-semibold"
                  optClass="text-black"
                />
              </div>
              <RequestDetailsButtonLayout optClass="mt-5 justify-end">
                <RequestDetailsButton
                  onclick={() => handleFilterChange("reset")}
                  optClass="btn-error"
                  title="Reset"
                />

                <RequestDetailsButton
                  onclick={handleSubmitFilter}
                  optClass="btn-success "
                  title="Filter"
                  type="submit"
                />
              </RequestDetailsButtonLayout>
            </form>

            <Table
              theads={[
                "Id",
                "Email",
                "State",
                "Phase",
                "Challenges",
                "Balance",
                "Start time",
                "End time",
                "Actions",
              ]}
            >
              {challengesData?.user_challenges.data.map((challenge) => (
                <tr>
                  <td>{challenge.id}</td>
                  <td>
                    <p>{challenge.user.email}</p>
                  </td>
                  <td>
                    <p>{challenge.state_type_name}</p>
                  </td>
                  <td>
                    <p>{challenge.phase_type_name}</p>
                  </td>
                  <td>
                    <p>{challenge.challenge_type_name}</p>
                  </td>
                  <td>
                    <p>{challenge.balance}$</p>
                  </td>
                  <td>
                    <p>{challenge.start_time}</p>
                  </td>
                  <td>
                    <p>{challenge.end_time}</p>
                  </td>
                  <th>
                    <Link
                      to={`analyze/${challenge.id}`}
                      className="btn btn-primary btn-outline  btn-sm mr-2"
                    >
                      Stats
                    </Link>
                    {/* */}
                  </th>
                </tr>
              ))}
            </Table>
          </div>
        )}

        {challengesData?.user_challenges && (
          <Pagination
            handleOnClick={handleChangePagination}
            data={challengesData.user_challenges}
          />
        )}
      </TableBackground>
    </>
  );
}
