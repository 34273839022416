import { useEffect, useState } from "react";
import {useFetch} from "./API/useFetch"
import { usePostImage} from "./API/usePostImage"
export function useNewTicket() {
  const [departments, setDepartments] = useState(null);
  const {loading, fetchData} = useFetch()
  const {loading: submitLoading, handlePostImage} = usePostImage()
  
  async function getDepartments() {
    const res = await fetchData("tickets/categories");
    setDepartments(res?.departments);
  }

  useEffect(() => {
    if (departments == null) {
      getDepartments();
    }
  }, [departments]);

  async function handleUploadTicket(formData, modalClose, newTicketSuccess) {
    const res = await handlePostImage("tickets", formData, "Ticket Sent Successfully!");
    if (!res) return;

    modalClose.current.click();
    newTicketSuccess();
  }

  return { loading, submitLoading, handleUploadTicket, departments };
}
