import React, { useEffect, useState } from "react";
import AuthCode from "react-auth-code-input";
import { formatPhoneNumber } from "react-phone-number-input";

export function PhoneNumberVerify({
  handleSetData,
  phoneNumber,
  sendSms,
  verifySms,
}) {
  const [result, setResult] = useState("");
  const [error, setError] = useState(false);
  const [countdown, setCountdown] = useState(60);

  useEffect(() => {
    if (result.length === 6) {
      handleSetData("phoneVerify", result);
      const verfiyResult = verifySms(result);
      if (!verfiyResult) {
        setError(true);
      }
    }
  }, [result]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countdown > 0) {
        setCountdown((x) => x - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countdown]);

  const handleOnChange = (res) => {
    setResult(res);
  };

  function handleResendCode() {
    sendSms(phoneNumber);
    setCountdown(60);
  }
  return (
    <>
      <p className="max-w-lg text-white text-lg font-light">
        Enter the verification code we sent to
        <span className="font-semibold"> {formatPhoneNumber(phoneNumber)}</span>
      </p>
      <AuthCode
        allowedCharacters="numeric"
        onChange={handleOnChange}
        containerClassName="grid grid-cols-3 sm:grid-cols-6 item-center gap-2 mt-4 mb-2"
        inputClassName="w-full max-w-12 h-14 text-center text-white text-xl font-semibold bg-transparent border-2 border-white rounded-lg outline-0"
        length={6}
      />
      <p
        className={`text-sm text-rose-600  font-semibold ${
          error ? "block" : "hidden"
        } `}
      >
        incorrect code
      </p>
      <button
        onClick={handleResendCode}
        disabled={countdown <= 0 ? false : true}
        className={`text-white font-light capitalize disabled:cursor-not-allowed ${
          countdown <= 0 ? "opacity-100" : "opacity-50"
        }`}
      >
        {countdown <= 0 ? (
          <span>resend code</span>
        ) : (
          <>
            <span>resend code in </span>
            <span className="countdown">
              <span style={{ "--value": countdown }}></span>
            </span>
          </>
        )}
      </button>

      <p className="max-w-lg text-white font-light mt-12">
        Didn’t receive a code?{" "}
        <span
          onClick={() => handleSetData("step", 0)}
          className="font-semibold hover:cursor-pointer"
        >
          try a different phone number
        </span>
      </p>
    </>
  );
}
