import { NewTicketForm } from "./NewTicketForm";

export function NewTicket({ newTicketSuccess }) {
  return (
    <>
      <div className="w-full flex items-center justify-between pb-10">
        <span className="text-neutral-100 text-xl font-bold capitalize">
          tickets
        </span>
        <button
          onClick={() => document.getElementById("newTicket").showModal()}
          className="btn btn-md bg-[var(--indigo-purple)] hover:bg-[var(--indigo-purple-hover)] w-[8rem] border-none text-neutral-100 capitalize"
        >
          New Ticket
        </button>
      </div>

      <NewTicketForm newTicketSuccess={newTicketSuccess} />
    </>
  );
}
