import { useNavigate } from "react-router-dom";
import PageStatus from "../../components/PageStatus/PageStatus";
import { TransactionResult } from "../../components/shared/TransactionResult";
import usePermissions from "../../hooks/usePermission";
export default function DepositTransactionSuccess() {
  const navigate = useNavigate();
  const {can} = usePermissions()
  return (
    <>
      <PageStatus
        category={"account"}
        currentPage={"deposits"}
        logoStarColor="#F8CC15"
      />
      <div className="w-full flex justify-center">
      {can("show_rule") && (
        <TransactionResult
          headerText="Your payment has been successfully completed."
          firstBtnText="GO TO NEW CHALLENGE"
          firstBtnClick={() => navigate("/dashboard/new-challenge")}
          secBtnText="back"
          secBtnClick={() => navigate("/dashboard/deposit")}
          imgUrl="../../transactionSuccess.png"
        />)}
         {can("show_investor_rule") && (
          <TransactionResult
          headerText="Your payment has been successfully completed."
          firstBtnText="GO TO MY WALLET"
          firstBtnClick={() => navigate("/dashboard/wallet")}
          secBtnText="back"
          secBtnClick={() => navigate("/dashboard/deposit")}
          imgUrl="../../transactionSuccess.png"
        />
         )}
      </div>
    </>
  );
}
