import React from "react";

import { VerificationLayout } from "./VerificationLayout";
import { IdentityDocument } from "./IdentityDocument";
import { IdentityDocType } from "./IdentityDocType";
import { IdentityUpload } from "./IdentityUpload";
import { IdentityCountry } from "./IdentityCountry";
import { IdentityUploadResult } from "./IdentityUploadResult";
import { PhoneNumber } from "./PhoneNumber";
import { PhoneNumberVerify } from "./PhoneNumberVerify";
import { PhoneNumberEnter } from "./PhoneNumberEnter";
import { PolicyAccept } from "./PolicyAccept";
import { VerificationSuccess } from "./VerificationSuccess";
import { VerificationUnsuccess } from "./VerificationUnsuccess";
import PageStatus from "../../components/PageStatus/PageStatus";
import Loading from "../../components/shared/Loading";
import { useVerification } from "../../hooks/useVerification";
import StepindicatorContainer from "../../components/indicator/StepIndicatorContainer";
import StepIndicator from "../../components/indicator/StepIndicator";
const stepIndicatorHeader = [
  "Golden Fund Terms and conditions",
  "Enter your phone number",
  "Upload your identification document",
  "Verification Status",
];
export default function Verification() {
  const {
    error,
    loading,
    postLoading,
    data,
    handleSetData,
    currentPage,
    handleCurrentPage,
    sendSms,
    verifySms,
    declineReason,
    uploadAgain,
    identityLoading,
    currentPageNum,
    uploadBtnDisabled,
    handleUploadIdentity,
  } = useVerification();
  return (
    <>
      <PageStatus
        category={"account"}
        currentPage={"verification"}
        logoStarColor="#F8CC15"
      />
      <StepindicatorContainer
        headerText={
          stepIndicatorHeader[currentPageNum - 1] ?? stepIndicatorHeader[0]
        }
        optClass="max-w-xl"
      >
        <StepIndicator
          num={1}
          text={"Terms & Conditions"}
          currentStep={currentPageNum}
        />
        <StepIndicator
          num={2}
          text={"Phone Number"}
          currentStep={currentPageNum}
        />
        <StepIndicator
          num={3}
          text={"Identity document"}
          currentStep={currentPageNum}
        />
        <StepIndicator
          num={4}
          text={"Status"}
          currentStep={currentPageNum}
          last
        />
      </StepindicatorContainer>
      <VerificationLayout currentPage={currentPage}>
        {error ? (
          "error fetching data"
        ) : loading || postLoading ? (
          <Loading optClass="mt-32" />
        ) : (
          <>
            {currentPage === "policy" && (
              <PolicyAccept
                handleSetData={handleSetData}
                handleCurrentPage={handleCurrentPage}
              />
            )}
            {currentPage === "phone" && (
              <PhoneNumber>
                {data.step === 0 && (
                  <PhoneNumberEnter
                    data={data}
                    handleSetData={handleSetData}
                    sendSms={sendSms}
                    postLoading={postLoading}
                  />
                )}
                {data.step === 1 && (
                  <PhoneNumberVerify
                    sendSms={sendSms}
                    phoneNumber={data.phoneNumber}
                    verifySms={verifySms}
                    handleSetData={handleSetData}
                  />
                )}
              </PhoneNumber>
            )}
            {currentPage === "document" && (
              <IdentityDocument>
                <IdentityCountry handleSetData={handleSetData} />
                <IdentityDocType handleSetData={handleSetData} />
                <IdentityUpload
                  data={data}
                  loading={identityLoading}
                  handleSetData={handleSetData}
                  handleUploadIdentity={handleUploadIdentity}
                  uploadBtnDisabled={uploadBtnDisabled}
                />
              </IdentityDocument>
            )}
            {currentPage === "uploaded" && <IdentityUploadResult />}
            {currentPage === "success" && <VerificationSuccess />}
            {currentPage === "declined" && (
              <VerificationUnsuccess
                declineReason={declineReason}
                uploadAgain={uploadAgain}
              />
            )}
          </>
        )}
      </VerificationLayout>
    </>
  );
}
