import store from "../store/store";

class CustomUploadAdapter {
  constructor(loader) {
    this.loader = loader;
    this.url = process.env.REACT_APP_API_BASE_URL + "/api/post-image";
  }

  upload() {
    const token = "Bearer " + store.getState().auth.token;

    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          const data = new FormData();
          data.append("photo", file);

          fetch(this.url, {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: token,
            },
            body: data,
          })
            .then((response) => response.json())
            .then((result) => {
              resolve({
                default: result.data.url, // Adjust the response as per your backend API
              });
            })
            .catch((error) => {
              reject(error);
            });
        })
    );
  }

  abort() {
    // Handle abort
  }
}

export default CustomUploadAdapter;
