import { useState, useEffect } from "react";
import { authActions } from "../store/slices/auth";
import { useDispatch } from "react-redux";
import { useFetch } from "../hooks/API/useFetch";
import { usePost } from "../hooks/API/usePost";

const Pages = ["policy", "phone", "document"];

export function useVerification() {
  const [data, setData] = useState({
    policyLive: null,
    phoneNumber: null,
    phoneVerify: null,
    country: null,
    docType: null,
    fileName: null,
    step: 0,
  });
  const [verifyState, setVerifyState] = useState(null);
  const [currentPage, setCurrentPage] = useState("policy");
  const [currentPageNum, setCurrentPageNum] = useState(0);
  const [declineReason, setDeclineReason] = useState("");
  const [uploadBtnDisabled, setUploadBtnDisabled] = useState(true);
  const dispatch = useDispatch();
  const { loading, error, fetchData } = useFetch();
  const { loading: postLoading, handlePostData } = usePost(false);

  const getVerificationState = async () => {
    const res = await fetchData("users/verification/identity/state");
    if (!res) return;
    setVerifyState(res.verification_type);
    if (res.verification_type === 5) {
      setDeclineReason(res.decline_reason);
    }
  };

  function handleSetData(property, value) {
    setData((prev) => ({ ...prev, [property]: value }));
  }
  function handleCurrentPage(property) {
    setCurrentPage(property);
  }
  function uploadAgain() {
    setCurrentPage("document");
  }

  const sendSms = async (number) => {
    const formData = {
      phone: number,
    };

    const res = await handlePostData(
      "users/verification/sms/send",
      formData,
      "Phone Number Added Successfully."
    );
    if (!res) return;
    handleCurrentPage("document");
  };

  const verifySms = async (result) => {
    const formData = {
      phone: data.phoneNumber,
      code: result,
    };

    const res = await handlePostData(
      "users/verification/sms/verify",
      formData,
      true
    );
    if (!res) return 0;
    dispatch(
      authActions.setVerification({
        type: 2,
      })
    );
    setCurrentPage("document");
    return 1;
  };

  const handleUploadIdentity = async () => {
    const formData = {
      identity_name: data.fileName,
      identity_type: data.docType,
      country: data.country,
    };

    const res = await handlePostData(
      "users/verification/identity/send",
      formData,
      true
    );

    if (!res) return;
    dispatch(
      authActions.setVerification({
        type: 3,
      })
    );
    handleCurrentPage("uploaded");
  };

  useEffect(() => {
    if (verifyState === 2) {
      setCurrentPage("document");
    }
    if (verifyState === 3) {
      setCurrentPage("uploaded");
    }
    if (verifyState === 4) {
      setCurrentPage("success");
    }
    if (verifyState === 5) {
      setCurrentPage("declined");
    }
  }, [verifyState]);

  useEffect(() => {
    const index = Pages.indexOf(currentPage);
    if (index === -1) {
      setCurrentPageNum(4);
    } else {
      setCurrentPageNum(index + 1);
    }
  }, [currentPage]);

  useEffect(() => {
    if (error === false && verifyState === null) {
      getVerificationState();
    }
  }, [error, verifyState]);

  useEffect(() => {
    if (data.fileName && data.docType && data.country) {
      setUploadBtnDisabled(false);
    } else {
      setUploadBtnDisabled(true);
    }
  }, [data.fileName, data.docType, data.country]);

  return {
    error,
    loading,
    postLoading,
    data,
    currentPage,
    sendSms,
    verifySms,
    handleSetData,
    handleCurrentPage,
    declineReason,
    uploadAgain,
    currentPageNum,
    uploadBtnDisabled,
    handleUploadIdentity,
  };
}
