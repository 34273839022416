import React from 'react'

const Lines = () => {
  return (
<svg className='circle-top-lines' width="1435" height="1411" viewBox="0 0 1435 1411" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M566.102 1074.4C568.342 1074.4 570.153 1076.22 570.153 1078.46C570.153 1080.7 568.342 1082.51 566.102 1082.51C563.863 1082.51 562.052 1080.7 562.052 1078.46C562.052 1076.22 563.863 1074.4 566.102 1074.4Z" fill="#2293EA"/>
<path d="M1107.73 608.573C1110.25 608.573 1112.29 610.619 1112.29 613.138C1112.29 615.658 1110.25 617.704 1107.73 617.704C1105.21 617.704 1103.17 615.658 1103.17 613.138C1103.17 610.619 1105.21 608.573 1107.73 608.573Z" fill="#EA3A50"/>
<path d="M1088.77 547.73C1091.56 547.73 1093.8 549.986 1093.8 552.761C1093.8 555.536 1091.55 557.793 1088.77 557.793C1086 557.793 1083.75 555.536 1083.75 552.761C1083.75 549.986 1086 547.73 1088.77 547.73Z" fill="#EB3E54"/>
<path d="M324.42 751.609C324.808 748.46 322.571 745.592 319.423 745.204C316.276 744.817 313.41 747.055 313.023 750.205C312.635 753.355 314.873 756.222 318.02 756.61C321.167 756.998 324.033 754.759 324.42 751.609Z" fill="#E92F46"/>
<path d="M456.688 409.212C458.319 409.212 459.641 410.535 459.641 412.167C459.641 413.799 458.319 415.123 456.688 415.123C455.057 415.123 453.734 413.799 453.734 412.167C453.734 410.535 455.057 409.212 456.688 409.212Z" fill="#4DA8EE"/>
<path d="M435.75 423.885C438.185 423.885 440.154 425.855 440.154 428.292C440.154 430.729 438.185 432.699 435.75 432.699C433.315 432.699 431.346 430.729 431.346 428.292C431.346 425.855 433.315 423.885 435.75 423.885Z" fill="#5BAFEF"/>
<path d="M981.449 1053.25C984.545 1053.25 987.055 1055.76 987.055 1058.86C987.055 1061.96 984.545 1064.47 981.449 1064.47C978.353 1064.47 975.843 1061.96 975.843 1058.86C975.843 1055.76 978.353 1053.25 981.449 1053.25Z" fill="#2594EA"/>
<path d="M1146.81 733.208C1149.57 733.208 1151.8 735.442 1151.8 738.202C1151.8 740.962 1149.57 743.196 1146.81 743.196C1144.05 743.196 1141.82 740.962 1141.82 738.202C1141.82 735.442 1144.05 733.208 1146.81 733.208Z" fill="#E82840"/>
<path d="M1147.87 759.741C1150.8 759.741 1153.17 762.118 1153.17 765.051C1153.17 767.984 1150.8 770.361 1147.87 770.361C1144.94 770.361 1142.56 767.984 1142.56 765.051C1142.56 762.118 1144.94 759.741 1147.87 759.741Z" fill="#E92F46"/>
<path d="M853.592 1127.19C855.613 1127.19 857.252 1128.83 857.252 1130.86C857.252 1132.88 855.613 1134.52 853.592 1134.52C851.57 1134.52 849.932 1132.88 849.932 1130.86C849.932 1128.83 851.57 1127.19 853.592 1127.19Z" fill="#84C3F3"/>
<path d="M693.193 1151.02C696.057 1151.02 698.372 1153.34 698.372 1156.2C698.372 1159.06 696.049 1161.38 693.193 1161.38C690.338 1161.38 688.016 1159.06 688.016 1156.2C688.016 1153.34 690.338 1151.02 693.193 1151.02Z" fill="#57ADEF"/>
<path d="M320.744 882.752C323.54 882.752 325.81 885.023 325.81 887.821C325.81 890.618 323.54 892.89 320.744 892.89C317.949 892.89 315.679 890.618 315.679 887.821C315.679 885.023 317.949 882.752 320.744 882.752Z" fill="#128BE8"/>
<path d="M334.978 923.402C337.165 923.402 338.939 925.177 338.939 927.365C338.939 929.554 337.165 931.329 334.978 931.329C332.791 931.329 331.018 929.554 331.018 927.365C331.018 925.177 332.791 923.402 334.978 923.402Z" fill="#389EEC"/>
<path d="M458.477 366.366C461.137 366.366 463.294 368.524 463.294 371.187C463.294 373.849 461.137 376.007 458.477 376.007C455.816 376.007 453.659 373.849 453.659 371.187C453.659 368.524 455.816 366.366 458.477 366.366Z" fill="#3FA1EC"/>
<path d="M1164.12 635.219C1167.12 635.219 1169.56 637.656 1169.56 640.665C1169.56 643.673 1167.12 646.11 1164.12 646.11C1161.11 646.11 1158.67 643.673 1158.67 640.665C1158.67 637.656 1161.11 635.219 1164.12 635.219Z" fill="#EA354C"/>
<path d="M1171.82 777.964C1173.85 777.964 1175.49 779.611 1175.49 781.634C1175.49 783.658 1173.84 785.305 1171.82 785.305C1169.8 785.305 1168.15 783.658 1168.15 781.634C1168.15 779.611 1169.8 777.964 1171.82 777.964Z" fill="#E9334A"/>
<path d="M1031.98 390.477C1034.45 390.477 1036.44 392.478 1036.44 394.937C1036.44 397.397 1034.44 399.397 1031.98 399.397C1029.52 399.397 1027.53 397.397 1027.53 394.937C1027.53 392.478 1029.52 390.477 1031.98 390.477Z" fill="#1B90E9"/>
<path d="M566.929 308.591C569.25 308.591 571.13 306.709 571.13 304.387C571.13 302.065 569.25 300.183 566.929 300.183C564.609 300.183 562.728 302.065 562.728 304.387C562.728 306.709 564.609 308.591 566.929 308.591Z" fill="#2594EA"/>
<path d="M527.196 317.007C529.142 317.007 530.713 318.586 530.713 320.526C530.713 322.467 529.135 324.046 527.196 324.046C525.257 324.046 523.679 322.467 523.679 320.526C523.679 318.586 525.257 317.007 527.196 317.007Z" fill="#2594EA"/>
<path d="M372.81 442.679C374.516 442.679 375.906 444.063 375.906 445.778C375.906 447.493 374.523 448.877 372.81 448.877C371.097 448.877 369.714 447.493 369.714 445.778C369.714 444.063 371.097 442.679 372.81 442.679Z" fill="#65B4F0"/>
<path d="M1114.92 489.587C1116.66 489.587 1118.07 491 1118.07 492.738C1118.07 494.475 1116.66 495.889 1114.92 495.889C1113.18 495.889 1111.77 494.475 1111.77 492.738C1111.77 491 1113.18 489.587 1114.92 489.587Z" fill="#E93148"/>
<path d="M518.946 325.753C519.194 322.752 516.964 320.117 513.965 319.869C510.967 319.621 508.334 321.852 508.086 324.853C507.838 327.855 510.068 330.489 513.067 330.737C516.066 330.985 518.698 328.754 518.946 325.753Z" fill="#2796EA"/>
<path d="M1095.01 448.914C1097.85 448.914 1100.15 451.215 1100.15 454.051C1100.15 456.886 1097.85 459.188 1095.01 459.188C1092.18 459.188 1089.88 456.886 1089.88 454.051C1089.88 451.215 1092.18 448.914 1095.01 448.914Z" fill="#F15F37"/>
<path d="M1112.25 472.845C1114.76 472.845 1116.81 474.891 1116.81 477.41C1116.81 479.93 1114.76 481.975 1112.25 481.975C1109.73 481.975 1107.68 479.93 1107.68 477.41C1107.68 474.891 1109.73 472.845 1112.25 472.845Z" fill="#4735EE"/>
<path d="M1170.8 606.783C1172.45 606.783 1173.79 608.122 1173.79 609.769C1173.79 611.416 1172.45 612.755 1170.8 612.755C1169.16 612.755 1167.82 611.416 1167.82 609.769C1167.82 608.122 1169.16 606.783 1170.8 606.783Z" fill="#EA354C"/>
<path d="M275.119 647.11C278.065 647.11 280.455 649.502 280.455 652.45C280.455 655.398 278.065 657.789 275.119 657.789C272.173 657.789 269.783 655.398 269.783 652.45C269.783 649.502 272.173 647.11 275.119 647.11Z" fill="#E7213A"/>
<path d="M1029.28 371.781C1031.77 371.781 1033.79 373.804 1033.79 376.301C1033.79 378.798 1031.77 380.821 1029.28 380.821C1026.78 380.821 1024.76 378.798 1024.76 376.301C1024.76 373.804 1026.78 371.781 1029.28 371.781Z" fill="#168DE9"/>
<path d="M1107.2 982.425C1109.46 982.425 1111.28 984.253 1111.28 986.509C1111.28 988.765 1109.46 990.593 1107.2 990.593C1104.95 990.593 1103.12 988.765 1103.12 986.509C1103.12 984.253 1104.95 982.425 1107.2 982.425Z" fill="#EAA23D"/>
<path d="M994.984 1095.21C998.096 1095.21 1000.61 1097.73 1000.61 1100.85C1000.61 1103.96 998.096 1106.48 994.984 1106.48C991.873 1106.48 989.355 1103.96 989.355 1100.85C989.355 1097.73 991.873 1095.21 994.984 1095.21Z" fill="#2997EA"/>
<path d="M878.791 1158.33C881.068 1158.33 882.917 1160.18 882.917 1162.46C882.917 1164.74 881.068 1166.59 878.791 1166.59C876.514 1166.59 874.665 1164.74 874.665 1162.46C874.665 1160.18 876.514 1158.33 878.791 1158.33Z" fill="#81C2F3"/>
<path d="M1083.07 421.959C1085.44 421.959 1087.37 423.885 1087.37 426.261C1087.37 428.638 1085.44 430.563 1083.07 430.563C1080.7 430.563 1078.77 428.638 1078.77 426.261C1078.77 423.885 1080.7 421.959 1083.07 421.959Z" fill="#F05429"/>
<path d="M720.091 1186.3C721.849 1186.3 723.277 1187.73 723.277 1189.49C723.277 1191.25 721.849 1192.68 720.091 1192.68C718.332 1192.68 716.904 1191.25 716.904 1189.49C716.904 1187.73 718.332 1186.3 720.091 1186.3Z" fill="#65B4F0"/>
<path d="M934.118 302.153C937.117 302.153 939.551 304.59 939.551 307.591C939.551 310.591 937.117 313.028 934.118 313.028C931.119 313.028 928.684 310.591 928.684 307.591C928.684 304.59 931.119 302.153 934.118 302.153Z" fill="#67B5F0"/>
<path d="M261.652 721.754C263.38 721.754 264.778 723.153 264.778 724.882C264.778 726.612 263.38 728.011 261.652 728.011C259.923 728.011 258.525 726.612 258.525 724.882C258.525 723.153 259.923 721.754 261.652 721.754Z" fill="#E93148"/>
<path d="M494.497 317.984C496.211 317.984 497.601 319.376 497.601 321.09C497.601 322.805 496.211 324.197 494.497 324.197C492.784 324.197 491.393 322.805 491.393 321.09C491.393 319.376 492.784 317.984 494.497 317.984Z" fill="#2C98EB"/>
<path d="M319.279 946.897C322.18 946.897 324.532 949.251 324.532 952.154C324.532 955.057 322.18 957.411 319.279 957.411C316.378 957.411 314.026 955.057 314.026 952.154C314.026 949.251 316.378 946.897 319.279 946.897Z" fill="#48A6ED"/>
<path d="M467.059 329.935C468.863 329.935 470.321 331.394 470.321 333.199C470.321 335.004 468.863 336.463 467.059 336.463C465.256 336.463 463.798 335.004 463.798 333.199C463.798 331.394 465.256 329.935 467.059 329.935Z" fill="#339BEB"/>
<path d="M330.724 466.265C332.64 466.265 334.196 467.821 334.196 469.739C334.196 471.657 332.64 473.214 330.724 473.214C328.808 473.214 327.252 471.657 327.252 469.739C327.252 467.821 328.808 466.265 330.724 466.265Z" fill="#5EB0EF"/>
<path d="M1184.17 840.011C1186.07 840.011 1187.62 841.56 1187.62 843.47C1187.62 845.381 1186.07 846.93 1184.17 846.93C1182.26 846.93 1180.71 845.381 1180.71 843.47C1180.71 841.56 1182.26 840.011 1184.17 840.011Z" fill="#EA354C"/>
<path d="M572.821 1167.16C575.489 1167.16 577.654 1169.32 577.654 1171.99C577.654 1174.66 575.489 1176.83 572.821 1176.83C570.153 1176.83 567.989 1174.66 567.989 1171.99C567.989 1169.32 570.153 1167.16 572.821 1167.16Z" fill="#2594EA"/>
<path d="M465.376 1123.07C467.572 1123.07 469.352 1121.29 469.352 1119.09C469.352 1116.9 467.572 1115.11 465.376 1115.11C463.18 1115.11 461.4 1116.9 461.4 1119.09C461.4 1121.29 463.18 1123.07 465.376 1123.07Z" fill="#339BEB"/>
<path d="M1197.58 660.843C1199.79 660.843 1201.58 662.633 1201.58 664.844C1201.58 667.055 1199.79 668.845 1197.58 668.845C1195.37 668.845 1193.58 667.055 1193.58 664.844C1193.58 662.633 1195.37 660.843 1197.58 660.843Z" fill="#E9334A"/>
<path d="M394.372 1060.6C395.995 1060.6 397.31 1061.91 397.31 1063.54C397.31 1065.16 395.995 1066.48 394.372 1066.48C392.748 1066.48 391.426 1065.17 391.426 1063.54C391.426 1061.9 392.741 1060.6 394.372 1060.6Z" fill="#5BAFEF"/>
<path d="M353.188 1014.07C356.141 1014.07 358.538 1016.47 358.538 1019.43C358.538 1022.38 356.141 1024.78 353.188 1024.78C350.234 1024.78 347.837 1022.38 347.837 1019.43C347.837 1016.47 350.234 1014.07 353.188 1014.07Z" fill="#65B4F0"/>
<path d="M371.525 1039.7C373.651 1039.7 375.372 1041.43 375.372 1043.55C375.372 1045.68 373.651 1047.4 371.525 1047.4C369.398 1047.4 367.677 1045.68 367.677 1043.55C367.677 1041.43 369.398 1039.7 371.525 1039.7Z" fill="#62B3F0"/>
<path d="M854.959 259.819C856.921 259.819 858.507 261.405 858.507 263.368C858.507 265.331 856.921 266.918 854.959 266.918C852.998 266.918 851.412 265.331 851.412 263.368C851.412 261.405 852.998 259.819 854.959 259.819Z" fill="#86C4F3"/>
<path d="M360.568 1029.21C362.634 1029.21 364.318 1030.89 364.318 1032.96C364.318 1035.04 362.642 1036.72 360.568 1036.72C358.493 1036.72 356.818 1035.04 356.818 1032.96C356.818 1030.89 358.493 1029.21 360.568 1029.21Z" fill="#65B4F0"/>
<path d="M246.779 704.584C249.552 704.584 251.806 706.833 251.806 709.615C251.806 712.398 249.559 714.647 246.779 714.647C243.998 714.647 241.751 712.398 241.751 709.615C241.751 706.833 243.998 704.584 246.779 704.584Z" fill="#E93148"/>
<path d="M401.105 1073.3C403.961 1073.3 406.275 1075.62 406.275 1078.47C406.275 1081.33 403.961 1083.65 401.105 1083.65C398.249 1083.65 395.934 1081.33 395.934 1078.47C395.934 1075.62 398.249 1073.3 401.105 1073.3Z" fill="#57ADEF"/>
<path d="M269.813 579.904C272.814 579.904 275.247 577.47 275.247 574.466C275.247 571.463 272.814 569.029 269.813 569.029C266.812 569.029 264.38 571.463 264.38 574.466C264.38 577.47 266.812 579.904 269.813 579.904Z" fill="#2293EA"/>
<path d="M1162.63 928.771C1164.51 928.771 1166.03 930.298 1166.03 932.178C1166.03 934.059 1164.51 935.585 1162.63 935.585C1160.75 935.585 1159.22 934.059 1159.22 932.178C1159.22 930.298 1160.75 928.771 1162.63 928.771Z" fill="#EB3E54"/>
<path d="M927.812 281.847C930.022 281.847 931.818 283.644 931.818 285.856C931.818 288.067 930.022 289.864 927.812 289.864C925.603 289.864 923.807 288.067 923.807 285.856C923.807 283.644 925.603 281.847 927.812 281.847Z" fill="#75BCF2"/>
<path d="M305.443 484.367C308.502 484.367 310.982 486.849 310.982 489.91C310.982 492.971 308.502 495.453 305.443 495.453C302.384 495.453 299.904 492.971 299.904 489.91C299.904 486.849 302.384 484.367 305.443 484.367Z" fill="#48A6ED"/>
<path d="M377.297 1056.02C378.95 1056.02 380.288 1057.35 380.288 1059.01C380.288 1060.66 378.95 1062 377.297 1062C375.643 1062 374.306 1060.66 374.306 1059.01C374.306 1057.35 375.643 1056.02 377.297 1056.02Z" fill="#5BAFEF"/>
<path d="M1177.55 539.765C1180.2 539.765 1182.34 541.909 1182.34 544.556C1182.34 547.204 1180.2 549.347 1177.55 549.347C1174.91 549.347 1172.77 547.204 1172.77 544.556C1172.77 541.909 1174.91 539.765 1177.55 539.765Z" fill="#EA3C52"/>
<path d="M283.235 525.453C285.926 525.453 288.105 527.634 288.105 530.327C288.105 533.019 285.926 535.2 283.235 535.2C280.545 535.2 278.365 533.019 278.365 530.327C278.365 527.634 280.545 525.453 283.235 525.453Z" fill="#1B90E9"/>
<path d="M511.715 1155.55C514.45 1155.55 516.675 1157.77 516.675 1160.52C516.675 1163.26 514.458 1165.48 511.715 1165.48C508.972 1165.48 506.755 1163.26 506.755 1160.52C506.755 1157.77 508.972 1155.55 511.715 1155.55Z" fill="#2594EA"/>
<path d="M767.237 1215.29C770.319 1214.56 772.228 1211.47 771.5 1208.38C770.773 1205.3 767.685 1203.39 764.602 1204.12C761.52 1204.84 759.611 1207.93 760.339 1211.02C761.066 1214.1 764.154 1216.01 767.237 1215.29Z" fill="#75BCF2"/>
<path d="M444.919 1116.58C447.88 1116.58 450.277 1118.98 450.277 1121.94C450.277 1124.91 447.88 1127.31 444.919 1127.31C441.958 1127.31 439.561 1124.91 439.561 1121.94C439.561 1118.98 441.958 1116.58 444.919 1116.58Z" fill="#359DEB"/>
<path d="M943.761 1165.33C945.794 1165.33 947.443 1163.68 947.443 1161.65C947.443 1159.61 945.794 1157.96 943.761 1157.96C941.727 1157.96 940.078 1159.61 940.078 1161.65C940.078 1163.68 941.727 1165.33 943.761 1165.33Z" fill="#67B5F0"/>
<path d="M1195.88 857.805C1198.48 857.805 1200.59 859.918 1200.59 862.521C1200.59 865.123 1198.48 867.236 1195.88 867.236C1193.27 867.236 1191.16 865.123 1191.16 862.521C1191.16 859.918 1193.27 857.805 1195.88 857.805Z" fill="#EA3A50"/>
<path d="M597.261 1201.76C600.357 1201.76 602.867 1199.25 602.867 1196.15C602.867 1193.05 600.357 1190.54 597.261 1190.54C594.164 1190.54 591.654 1193.05 591.654 1196.15C591.654 1199.25 594.164 1201.76 597.261 1201.76Z" fill="#2E99EB"/>
<path d="M597.209 249.816C599.2 249.816 600.816 251.433 600.816 253.426C600.816 255.419 599.2 257.036 597.209 257.036C595.217 257.036 593.602 255.419 593.602 253.426C593.602 251.433 595.217 249.816 597.209 249.816Z" fill="#2E99EB"/>
<path d="M735.219 1208.81C738.233 1208.81 740.675 1211.25 740.675 1214.27C740.675 1217.28 738.233 1219.73 735.219 1219.73C732.205 1219.73 729.763 1217.28 729.763 1214.27C729.763 1211.25 732.205 1208.81 735.219 1208.81Z" fill="#6AB6F1"/>
<path d="M524.257 282.569C525.885 282.569 527.203 281.249 527.203 279.621C527.203 277.993 525.885 276.673 524.257 276.673C522.63 276.673 521.312 277.993 521.312 279.621C521.312 281.249 522.63 282.569 524.257 282.569Z" fill="#2293EA"/>
<path d="M418.278 1101.15C420.81 1101.15 422.862 1103.2 422.862 1105.74C422.862 1108.27 420.81 1110.32 418.278 1110.32C415.745 1110.32 413.693 1108.27 413.693 1105.74C413.693 1103.2 415.745 1101.15 418.278 1101.15Z" fill="#44A3ED"/>
<path d="M1122.74 1012.83C1124.57 1012.83 1126.05 1014.31 1126.05 1016.14C1126.05 1017.97 1124.57 1019.45 1122.74 1019.45C1120.92 1019.45 1119.44 1017.97 1119.44 1016.14C1119.44 1014.31 1120.92 1012.83 1122.74 1012.83Z" fill="#F15F37"/>
<path d="M274.262 917.994C277.223 917.994 279.628 920.401 279.628 923.364C279.628 926.327 277.223 928.734 274.262 928.734C271.301 928.734 268.896 926.327 268.896 923.364C268.896 920.401 271.301 917.994 274.262 917.994Z" fill="#1B90E9"/>
<path d="M1074.71 1073.01C1077.43 1073.01 1079.64 1075.22 1079.64 1077.95C1079.64 1080.68 1077.43 1082.89 1074.71 1082.89C1071.98 1082.89 1069.77 1080.68 1069.77 1077.95C1069.77 1075.22 1071.98 1073.01 1074.71 1073.01Z" fill="#2293EA"/>
<path d="M1221.75 753.168C1223.49 753.168 1224.9 754.575 1224.9 756.319C1224.9 758.064 1223.49 759.471 1221.75 759.471C1220.01 759.471 1218.6 758.064 1218.6 756.319C1218.6 754.575 1220.01 753.168 1221.75 753.168Z" fill="#E82840"/>
<path d="M656.948 1212.1C658.902 1212.1 660.488 1213.69 660.488 1215.65C660.488 1217.6 658.902 1219.19 656.948 1219.19C654.994 1219.19 653.408 1217.6 653.408 1215.65C653.408 1213.69 654.994 1212.1 656.948 1212.1Z" fill="#46A5ED"/>
<path d="M284.43 491.467C287.106 491.467 289.278 493.64 289.278 496.318C289.278 498.995 287.106 501.169 284.43 501.169C281.755 501.169 279.583 498.995 279.583 496.318C279.583 493.64 281.755 491.467 284.43 491.467Z" fill="#389EEC"/>
<path d="M1146.79 991.457C1148.51 991.457 1149.91 992.856 1149.91 994.578C1149.91 996.301 1148.51 997.7 1146.79 997.7C1145.07 997.7 1143.67 996.301 1143.67 994.578C1143.67 992.856 1145.07 991.457 1146.79 991.457Z" fill="#4735EE"/>
<path d="M234.95 800.497C236.746 800.497 238.196 801.956 238.196 803.746C238.196 805.536 236.739 806.995 234.95 806.995C233.161 806.995 231.703 805.536 231.703 803.746C231.703 801.956 233.161 800.497 234.95 800.497Z" fill="#E7213A"/>
<path d="M845.693 232.029C848.512 232.029 850.804 234.315 850.804 237.143C850.804 239.971 848.519 242.257 845.693 242.257C842.868 242.257 840.583 239.971 840.583 237.143C840.583 234.315 842.868 232.029 845.693 232.029Z" fill="#84C3F3"/>
<path d="M398.971 1100.36C401.128 1100.36 402.871 1102.1 402.871 1104.26C402.871 1106.42 401.128 1108.17 398.971 1108.17C396.814 1108.17 395.07 1106.42 395.07 1104.26C395.07 1102.1 396.814 1100.36 398.971 1100.36Z" fill="#4DA8EE"/>
<path d="M1220.46 816.433C1222.41 816.433 1223.99 818.02 1223.99 819.968C1223.99 821.916 1222.4 823.503 1220.46 823.503C1218.51 823.503 1216.93 821.916 1216.93 819.968C1216.93 818.02 1218.51 816.433 1220.46 816.433Z" fill="#EA354C"/>
<path d="M992.384 292.88C994.969 292.88 997.073 294.978 997.073 297.573C997.073 300.168 994.977 302.266 992.384 302.266C989.791 302.266 987.694 300.168 987.694 297.573C987.694 294.978 989.791 292.88 992.384 292.88Z" fill="#3FA1EC"/>
<path d="M751.384 1223.9C753.383 1223.9 754.999 1225.53 754.999 1227.52C754.999 1229.51 753.376 1231.14 751.384 1231.14C749.393 1231.14 747.769 1229.51 747.769 1227.52C747.769 1225.53 749.393 1223.9 751.384 1223.9Z" fill="#71BAF1"/>
<path d="M1200 549.257C1201.93 549.257 1203.5 550.828 1203.5 552.761C1203.5 554.694 1201.93 556.266 1200 556.266C1198.07 556.266 1196.5 554.694 1196.5 552.761C1196.5 550.828 1198.07 549.257 1200 549.257Z" fill="#EA3A50"/>
<path d="M922.725 262.233C924.867 262.233 926.603 260.495 926.603 258.352C926.603 256.209 924.867 254.471 922.725 254.471C920.584 254.471 918.848 256.209 918.848 258.352C918.848 260.495 920.584 262.233 922.725 262.233Z" fill="#7ABEF2"/>
<path d="M799.497 1221.98C801.917 1221.98 803.886 1223.94 803.886 1226.37C803.886 1228.8 801.925 1230.76 799.497 1230.76C797.07 1230.76 795.108 1228.8 795.108 1226.37C795.108 1223.94 797.07 1221.98 799.497 1221.98Z" fill="#7CBFF2"/>
<path d="M608.076 226.674C611.254 226.674 613.84 229.254 613.84 232.443C613.84 235.632 611.262 238.211 608.076 238.211C604.889 238.211 602.311 235.632 602.311 232.443C602.311 229.254 604.889 226.674 608.076 226.674Z" fill="#339BEB"/>
<path d="M251.431 901.779C253.365 901.779 254.933 900.21 254.933 898.275C254.933 896.339 253.365 894.77 251.431 894.77C249.497 894.77 247.929 896.339 247.929 898.275C247.929 900.21 249.497 901.779 251.431 901.779Z" fill="#1B90E9"/>
<path d="M1177.88 486.827C1181.01 486.827 1183.55 489.369 1183.55 492.505C1183.55 495.641 1181.01 498.183 1177.88 498.183C1174.74 498.183 1172.2 495.641 1172.2 492.505C1172.2 489.369 1174.74 486.827 1177.88 486.827Z" fill="#EA3C52"/>
<path d="M232.838 617.042C235.021 617.042 236.791 615.27 236.791 613.086C236.791 610.901 235.021 609.13 232.838 609.13C230.655 609.13 228.885 610.901 228.885 613.086C228.885 615.27 230.655 617.042 232.838 617.042Z" fill="#E89B2F"/>
<path d="M703.271 1229.15C705.361 1229.15 707.052 1230.84 707.052 1232.93C707.052 1235.02 705.361 1236.71 703.271 1236.71C701.182 1236.71 699.491 1235.02 699.491 1232.93C699.491 1230.84 701.182 1229.15 703.271 1229.15Z" fill="#5BAFEF"/>
<path d="M1022.56 306.711C1024.47 306.711 1026.02 308.26 1026.02 310.178C1026.02 312.096 1024.47 313.645 1022.56 313.645C1020.64 313.645 1019.09 312.096 1019.09 310.178C1019.09 308.26 1020.64 306.711 1022.56 306.711Z" fill="#2997EA"/>
<path d="M264.643 941.452C266.793 941.452 268.529 943.197 268.529 945.34C268.529 947.483 266.785 949.228 264.643 949.228C262.501 949.228 260.758 947.483 260.758 945.34C260.758 943.197 262.501 941.452 264.643 941.452Z" fill="#2C98EB"/>
<path d="M287.571 982.786C290.69 982.786 293.223 985.313 293.223 988.441C293.223 991.57 290.69 994.097 287.571 994.097C284.453 994.097 281.92 991.57 281.92 988.441C281.92 985.313 284.445 982.786 287.571 982.786Z" fill="#57ADEF"/>
<path d="M1099.44 1076.37C1101.35 1076.37 1102.89 1077.92 1102.89 1079.83C1102.89 1081.73 1101.34 1083.28 1099.44 1083.28C1097.54 1083.28 1095.99 1081.73 1095.99 1079.83C1095.99 1077.92 1097.54 1076.37 1099.44 1076.37Z" fill="#2796EA"/>
<path d="M303.113 428.969C305.082 428.969 306.675 430.563 306.675 432.534C306.675 434.504 305.082 436.099 303.113 436.099C301.144 436.099 299.551 434.504 299.551 432.534C299.551 430.563 301.144 428.969 303.113 428.969Z" fill="#65B4F0"/>
<path d="M240.97 543.36C243.359 543.36 245.298 545.301 245.298 547.692C245.298 550.084 243.359 552.024 240.97 552.024C238.58 552.024 236.641 550.084 236.641 547.692C236.641 545.301 238.58 543.36 240.97 543.36Z" fill="#1B90E9"/>
<path d="M218.724 817.96C221.513 817.96 223.767 820.216 223.767 823.006C223.767 825.797 221.505 828.053 218.724 828.053C215.944 828.053 213.682 825.789 213.682 823.006C213.682 820.224 215.936 817.96 218.724 817.96Z" fill="#3B28ED"/>
<path d="M1219.74 557.785C1222.66 557.785 1225.03 560.154 1225.03 563.08C1225.03 566.006 1222.66 568.375 1219.74 568.375C1216.81 568.375 1214.45 566.006 1214.45 563.08C1214.45 560.154 1216.81 557.785 1219.74 557.785Z" fill="#EA3A50"/>
<path d="M573.265 224.38C575.602 224.38 577.503 226.276 577.503 228.622C577.503 230.969 575.61 232.864 573.265 232.864C570.92 232.864 569.026 230.969 569.026 228.622C569.026 226.276 570.92 224.38 573.265 224.38Z" fill="#2997EA"/>
<path d="M785.27 1240.54C787.006 1240.54 788.412 1241.95 788.412 1243.68C788.412 1245.42 787.006 1246.83 785.27 1246.83C783.534 1246.83 782.129 1245.42 782.129 1243.68C782.129 1241.95 783.534 1240.54 785.27 1240.54Z" fill="#78BDF2"/>
<path d="M604.431 1227.72C606.994 1227.72 609.068 1229.8 609.068 1232.36C609.068 1234.93 606.994 1237 604.431 1237C601.868 1237 599.794 1234.93 599.794 1232.36C599.794 1229.8 601.868 1227.72 604.431 1227.72Z" fill="#339BEB"/>
<path d="M242.736 525.762C245.456 525.762 247.658 527.965 247.658 530.688C247.658 533.41 245.456 535.614 242.736 535.614C240.015 535.614 237.813 533.41 237.813 530.688C237.813 527.965 240.015 525.762 242.736 525.762Z" fill="#128BE8"/>
<path d="M1081.76 1104.16C1084.3 1104.16 1086.36 1106.22 1086.36 1108.76C1086.36 1111.3 1084.3 1113.36 1081.76 1113.36C1079.22 1113.36 1077.16 1111.3 1077.16 1108.76C1077.16 1106.22 1079.22 1104.16 1081.76 1104.16Z" fill="#1B90E9"/>
<path d="M556.46 225.05C559.444 225.05 561.856 227.471 561.856 230.45C561.856 233.428 559.436 235.85 556.46 235.85C553.484 235.85 551.064 233.428 551.064 230.45C551.064 227.471 553.484 225.05 556.46 225.05Z" fill="#2594EA"/>
<path d="M1178.14 991.194C1180.19 991.194 1181.85 992.856 1181.85 994.909C1181.85 996.963 1180.19 998.625 1178.14 998.625C1176.09 998.625 1174.43 996.963 1174.43 994.909C1174.43 992.856 1176.09 991.194 1178.14 991.194Z" fill="#E93148"/>
<path d="M835.27 1237.09C837.171 1237.09 838.719 1238.63 838.719 1240.54C838.719 1242.45 837.178 1243.99 835.27 1243.99C833.361 1243.99 831.82 1242.45 831.82 1240.54C831.82 1238.63 833.361 1237.09 835.27 1237.09Z" fill="#84C3F3"/>
<path d="M226.856 885.233C229.163 885.233 231.035 887.106 231.035 889.415C231.035 891.724 229.163 893.597 226.856 893.597C224.549 893.597 222.678 891.724 222.678 889.415C222.678 887.106 224.549 885.233 226.856 885.233Z" fill="#2293EA"/>
<path d="M619.318 1236.94C622.159 1236.94 624.459 1239.24 624.459 1242.08C624.459 1244.92 622.159 1247.23 619.318 1247.23C616.477 1247.23 614.178 1244.92 614.178 1242.08C614.178 1239.24 616.477 1236.94 619.318 1236.94Z" fill="#389EEC"/>
<path d="M702.061 187.807C704 187.807 705.578 189.379 705.578 191.327C705.578 193.275 704.008 194.846 702.061 194.846C700.115 194.846 698.544 193.275 698.544 191.327C698.544 189.379 700.115 187.807 702.061 187.807Z" fill="#5BAFEF"/>
<path d="M869.9 1234.94C872.952 1234.94 875.432 1237.42 875.432 1240.48C875.432 1243.54 872.959 1246.01 869.9 1246.01C866.842 1246.01 864.369 1243.54 864.369 1240.48C864.369 1237.42 866.842 1234.94 869.9 1234.94Z" fill="#86C4F3"/>
<path d="M1103.5 337.456C1106.31 337.456 1108.59 339.735 1108.59 342.547C1108.59 345.36 1106.31 347.639 1103.5 347.639C1100.69 347.639 1098.41 345.36 1098.41 342.547C1098.41 339.735 1100.69 337.456 1103.5 337.456Z" fill="#2293EA"/>
<path d="M1138.49 1066.04C1140.7 1066.04 1142.49 1067.83 1142.49 1070.04C1142.49 1072.25 1140.7 1074.04 1138.49 1074.04C1136.28 1074.04 1134.49 1072.25 1134.49 1070.04C1134.49 1067.83 1136.28 1066.04 1138.49 1066.04Z" fill="#F05429"/>
<path d="M1066.65 303.537C1068.6 303.537 1070.18 305.116 1070.18 307.072C1070.18 309.027 1068.6 310.606 1066.65 310.606C1064.7 310.606 1063.12 309.027 1063.12 307.072C1063.12 305.116 1064.7 303.537 1066.65 303.537Z" fill="#1B90E9"/>
<path d="M951.456 1210.79C954.169 1210.79 956.371 1213 956.371 1215.71C956.371 1218.43 954.169 1220.63 951.456 1220.63C948.743 1220.63 946.541 1218.43 946.541 1215.71C946.541 1213 948.743 1210.79 951.456 1210.79Z" fill="#75BCF2"/>
<path d="M1269.02 720.415C1271.49 720.415 1273.48 722.416 1273.48 724.883C1273.48 727.349 1271.49 729.35 1269.02 729.35C1266.56 729.35 1264.56 727.349 1264.56 724.883C1264.56 722.416 1266.56 720.415 1269.02 720.415Z" fill="#E7213A"/>
<path d="M1032.96 273.941C1033.21 270.94 1030.98 268.306 1027.98 268.057C1024.99 267.809 1022.35 270.04 1022.1 273.042C1021.86 276.043 1024.09 278.677 1027.09 278.925C1030.08 279.174 1032.72 276.942 1032.96 273.941Z" fill="#339BEB"/>
<path d="M1028.1 1172.66C1030.55 1172.66 1032.53 1174.64 1032.53 1177.09C1032.53 1179.55 1030.55 1181.53 1028.1 1181.53C1025.65 1181.53 1023.66 1179.55 1023.66 1177.09C1023.66 1174.64 1025.65 1172.66 1028.1 1172.66Z" fill="#339BEB"/>
<path d="M635.348 195.109C638.216 193.92 639.578 190.63 638.39 187.759C637.202 184.889 633.913 183.526 631.045 184.715C628.177 185.904 626.815 189.194 628.003 192.065C629.191 194.935 632.479 196.298 635.348 195.109Z" fill="#3FA1EC"/>
<path d="M236.506 487.285C238.565 487.285 240.233 488.955 240.233 491.015C240.233 493.076 238.565 494.746 236.506 494.746C234.447 494.746 232.778 493.076 232.778 491.015C232.778 488.955 234.447 487.285 236.506 487.285Z" fill="#2C98EB"/>
<path d="M294.586 1049.34C295.332 1046.18 293.376 1043.02 290.217 1042.27C287.058 1041.52 283.892 1043.48 283.147 1046.64C282.401 1049.8 284.358 1052.97 287.517 1053.72C290.676 1054.46 293.841 1052.51 294.586 1049.34Z" fill="#62B3F0"/>
<path d="M1273.71 719.61C1276.62 719.61 1278.98 721.972 1278.98 724.883C1278.98 727.793 1276.62 730.155 1273.71 730.155C1270.8 730.155 1268.44 727.793 1268.44 724.883C1268.44 721.972 1270.8 719.61 1273.71 719.61Z" fill="#E7213A"/>
<path d="M718.775 172.412C721.533 172.412 723.765 174.645 723.765 177.405C723.765 180.166 721.533 182.399 718.775 182.399C716.017 182.399 713.785 180.166 713.785 177.405C713.785 174.645 716.025 172.412 718.775 172.412Z" fill="#65B4F0"/>
<path d="M256.541 438.889C258.668 438.889 260.389 440.611 260.389 442.74C260.389 444.868 258.668 446.59 256.541 446.59C254.414 446.59 252.693 444.868 252.693 442.74C252.693 440.611 254.414 438.889 256.541 438.889Z" fill="#57ADEF"/>
<path d="M683.958 172.412C686.663 172.412 688.858 174.608 688.858 177.315C688.858 180.023 686.663 182.219 683.958 182.219C681.252 182.219 679.058 180.023 679.058 177.315C679.058 174.608 681.252 172.412 683.958 172.412Z" fill="#57ADEF"/>
<path d="M1265.41 598.729C1267.93 598.729 1269.98 600.774 1269.98 603.294C1269.98 605.813 1267.93 607.859 1265.41 607.859C1262.9 607.859 1260.85 605.813 1260.85 603.294C1260.85 600.774 1262.9 598.729 1265.41 598.729Z" fill="#EA384E"/>
<path d="M1252.21 892.799C1254.73 892.799 1256.76 894.838 1256.76 897.357C1256.76 899.876 1254.73 901.915 1252.21 901.915C1249.69 901.915 1247.66 899.876 1247.66 897.357C1247.66 894.838 1249.69 892.799 1252.21 892.799Z" fill="#EA3A50"/>
<path d="M988.326 1207.75C990.738 1207.75 992.7 1209.7 992.7 1212.12C992.7 1214.53 990.746 1216.49 988.326 1216.49C985.906 1216.49 983.952 1214.54 983.952 1212.12C983.952 1209.7 985.906 1207.75 988.326 1207.75Z" fill="#59AEEF"/>
<path d="M1003.71 243.04C1005.37 243.04 1006.72 244.386 1006.72 246.048C1006.72 247.71 1005.37 249.056 1003.71 249.056C1002.05 249.056 1000.7 247.71 1000.7 246.048C1000.7 244.386 1002.05 243.04 1003.71 243.04Z" fill="#4DA8EE"/>
<path d="M191.805 859.302C194.382 859.302 196.472 861.393 196.472 863.972C196.472 866.552 194.382 868.643 191.805 868.643C189.227 868.643 187.138 866.552 187.138 863.972C187.138 861.393 189.227 859.302 191.805 859.302Z" fill="#F05327"/>
<path d="M1163.73 1072.45C1166.06 1072.45 1167.95 1070.56 1167.95 1068.23C1167.95 1065.9 1166.06 1064.01 1163.73 1064.01C1161.4 1064.01 1159.52 1065.9 1159.52 1068.23C1159.52 1070.56 1161.4 1072.45 1163.73 1072.45Z" fill="#F1582E"/>
<path d="M449.586 1201.67C452.261 1201.67 454.433 1203.84 454.433 1206.52C454.433 1209.2 452.261 1211.37 449.586 1211.37C446.91 1211.37 444.738 1209.2 444.738 1206.52C444.738 1203.84 446.91 1201.67 449.586 1201.67Z" fill="#2C98EB"/>
<path d="M1064.24 278.703C1066.07 278.703 1067.56 280.192 1067.56 282.027C1067.56 283.863 1066.07 285.352 1064.24 285.352C1062.4 285.352 1060.92 283.863 1060.92 282.027C1060.92 280.192 1062.4 278.703 1064.24 278.703Z" fill="#2594EA"/>
<path d="M829.573 176.881C830.108 173.892 828.121 171.035 825.135 170.5C822.148 169.964 819.293 171.953 818.758 174.942C818.223 177.93 820.21 180.787 823.197 181.323C826.183 181.858 829.038 179.87 829.573 176.881Z" fill="#84C3F3"/>
<path d="M464.075 1212.22C466.616 1212.22 468.682 1214.28 468.682 1216.83C468.682 1219.38 466.623 1221.44 464.075 1221.44C461.528 1221.44 459.469 1219.38 459.469 1216.83C459.469 1214.28 461.528 1212.22 464.075 1212.22Z" fill="#2796EA"/>
<path d="M1245.61 926.688C1248.8 926.688 1251.4 929.283 1251.4 932.479C1251.4 935.676 1248.8 938.27 1245.61 938.27C1242.42 938.27 1239.82 935.676 1239.82 932.479C1239.82 929.283 1242.42 926.688 1245.61 926.688Z" fill="#EA3C52"/>
<path d="M1187.76 404.692C1189.45 404.692 1190.82 406.068 1190.82 407.76C1190.82 409.452 1189.45 410.829 1187.76 410.829C1186.07 410.829 1184.69 409.452 1184.69 407.76C1184.69 406.068 1186.07 404.692 1187.76 404.692Z" fill="#EAA23D"/>
<path d="M927.339 1243.83C929.887 1243.83 931.953 1245.9 931.953 1248.45C931.953 1251 929.887 1253.07 927.339 1253.07C924.791 1253.07 922.725 1251 922.725 1248.45C922.725 1245.9 924.791 1243.83 927.339 1243.83Z" fill="#7CBFF2"/>
<path d="M1240.91 494.543C1243.93 494.543 1246.37 496.995 1246.37 500.01C1246.37 503.026 1243.92 505.478 1240.91 505.478C1237.89 505.478 1235.44 503.026 1235.44 500.01C1235.44 496.995 1237.89 494.543 1240.91 494.543Z" fill="#EB3E54"/>
<path d="M927.647 195.666C930.308 195.666 932.472 197.824 932.472 200.494C932.472 203.164 930.315 205.323 927.647 205.323C924.979 205.323 922.822 203.164 922.822 200.494C922.822 197.824 924.979 195.666 927.647 195.666Z" fill="#7CBFF2"/>
<path d="M373.464 285.652C375.223 285.652 376.643 287.074 376.643 288.834C376.643 290.594 375.223 292.015 373.464 292.015C371.706 292.015 370.285 290.594 370.285 288.834C370.285 287.074 371.706 285.652 373.464 285.652Z" fill="#44A3ED"/>
<path d="M859.927 1266.72C862.468 1266.72 864.527 1268.78 864.527 1271.32C864.527 1273.86 862.468 1275.92 859.927 1275.92C857.387 1275.92 855.328 1273.86 855.328 1271.32C855.328 1268.78 857.387 1266.72 859.927 1266.72Z" fill="#84C3F3"/>
<path d="M612.344 170.11C614.681 170.11 616.575 172.006 616.575 174.345C616.575 176.684 614.681 178.579 612.344 178.579C610.007 178.579 608.113 176.684 608.113 174.345C608.113 172.006 610.007 170.11 612.344 170.11Z" fill="#389EEC"/>
<path d="M430.791 1197.53C434.015 1197.53 436.63 1200.14 436.63 1203.37C436.63 1206.6 434.015 1209.21 430.791 1209.21C427.567 1209.21 424.951 1206.6 424.951 1203.37C424.951 1200.14 427.567 1197.53 430.791 1197.53Z" fill="#2C98EB"/>
<path d="M1096.14 1146.41C1098.5 1146.41 1100.42 1148.33 1100.42 1150.69C1100.42 1153.05 1098.5 1154.97 1096.14 1154.97C1093.78 1154.97 1091.86 1153.05 1091.86 1150.69C1091.86 1148.33 1093.78 1146.41 1096.14 1146.41Z" fill="#168DE9"/>
<path d="M945.571 1242.61C947.428 1242.61 948.931 1244.12 948.931 1245.98C948.931 1247.83 947.428 1249.34 945.571 1249.34C943.715 1249.34 942.212 1247.83 942.212 1245.98C942.212 1244.12 943.715 1242.61 945.571 1242.61Z" fill="#7ABEF2"/>
<path d="M1224.81 998.437C1226.67 998.437 1228.18 999.948 1228.18 1001.81C1228.18 1003.68 1226.67 1005.19 1224.81 1005.19C1222.94 1005.19 1221.43 1003.68 1221.43 1001.81C1221.43 999.948 1222.94 998.437 1224.81 998.437Z" fill="#EA354C"/>
<path d="M646.448 156.663C648.868 156.663 650.822 158.626 650.822 161.04C650.822 163.454 648.86 165.417 646.448 165.417C644.036 165.417 642.074 163.454 642.074 161.04C642.074 158.626 644.036 156.663 646.448 156.663Z" fill="#46A5ED"/>
<path d="M772.682 161.025C775.7 161.025 778.146 158.577 778.146 155.558C778.146 152.538 775.7 150.09 772.682 150.09C769.665 150.09 767.219 152.538 767.219 155.558C767.219 158.577 769.665 161.025 772.682 161.025Z" fill="#75BCF2"/>
<path d="M754.699 148.932C756.735 148.932 758.389 150.586 758.389 152.624C758.389 154.662 756.735 156.317 754.699 156.317C752.662 156.317 751.009 154.662 751.009 152.624C751.009 150.586 752.662 148.932 754.699 148.932Z" fill="#71BAF1"/>
<path d="M1256.25 952.304C1258.54 952.304 1260.42 954.169 1260.42 956.478C1260.42 958.787 1258.55 960.652 1256.25 960.652C1253.94 960.652 1252.07 958.787 1252.07 956.478C1252.07 954.169 1253.94 952.304 1256.25 952.304Z" fill="#EB3E54"/>
<path d="M1016.69 1220.94C1019.6 1220.94 1021.96 1223.3 1021.96 1226.21C1021.96 1229.12 1019.6 1231.48 1016.69 1231.48C1013.78 1231.48 1011.42 1229.12 1011.42 1226.21C1011.42 1223.3 1013.78 1220.94 1016.69 1220.94Z" fill="#4DA8EE"/>
<path d="M151.492 776.295C153.492 776.295 155.115 777.919 155.115 779.92C155.115 781.92 153.492 783.545 151.492 783.545C149.493 783.545 147.87 781.92 147.87 779.92C147.87 777.919 149.493 776.295 151.492 776.295Z" fill="#E92F46"/>
<path d="M334.219 295.806C335.888 295.806 337.241 297.159 337.241 298.829C337.241 300.499 335.888 301.852 334.219 301.852C332.551 301.852 331.198 300.499 331.198 298.829C331.198 297.159 332.551 295.806 334.219 295.806Z" fill="#57ADEF"/>
<path d="M736.699 137.763C739.029 137.763 740.923 139.659 740.923 141.99C740.923 144.322 739.029 146.217 736.699 146.217C734.37 146.217 732.476 144.322 732.476 141.99C732.476 139.659 734.37 137.763 736.699 137.763Z" fill="#6AB6F1"/>
<path d="M163.48 574.872C165.449 574.872 167.049 576.474 167.049 578.445C167.049 580.415 165.449 582.017 163.48 582.017C161.511 582.017 159.91 580.415 159.91 578.445C159.91 576.474 161.511 574.872 163.48 574.872Z" fill="#F05327"/>
<path d="M1162.2 331.808C1164.31 331.808 1166.02 333.522 1166.02 335.636C1166.02 337.749 1164.31 339.464 1162.2 339.464C1160.09 339.464 1158.37 337.749 1158.37 335.636C1158.37 333.522 1160.09 331.808 1162.2 331.808Z" fill="#2C98EB"/>
<path d="M165.223 554.13C168.282 554.13 170.762 556.612 170.762 559.673C170.762 562.734 168.282 565.216 165.223 565.216C162.164 565.216 159.684 562.734 159.684 559.673C159.684 556.612 162.164 554.13 165.223 554.13Z" fill="#F04D21"/>
<path d="M159.711 855.809C160.457 852.648 158.501 849.48 155.342 848.734C152.183 847.988 149.017 849.946 148.272 853.107C147.526 856.268 149.483 859.436 152.642 860.182C155.801 860.928 158.966 858.97 159.711 855.809Z" fill="#E89B2F"/>
<path d="M848.151 142.471C851.022 142.471 853.344 144.803 853.344 147.668C853.344 150.534 851.014 152.865 848.151 152.865C845.288 152.865 842.958 150.534 842.958 147.668C842.958 144.803 845.288 142.471 848.151 142.471Z" fill="#84C3F3"/>
<path d="M1251.77 992.142C1253.56 992.142 1255.02 993.593 1255.02 995.391C1255.02 997.188 1253.57 998.64 1251.77 998.64C1249.98 998.64 1248.53 997.188 1248.53 995.391C1248.53 993.593 1249.98 992.142 1251.77 992.142Z" fill="#EA3C52"/>
<path d="M1318.73 662.505C1321.97 662.505 1324.6 665.137 1324.6 668.386C1324.6 671.635 1321.97 674.267 1318.73 674.267C1315.48 674.267 1312.85 671.635 1312.85 668.386C1312.85 665.137 1315.48 662.505 1318.73 662.505Z" fill="#E92F46"/>
<path d="M1286.66 922.762C1289.86 922.762 1292.46 925.365 1292.46 928.568C1292.46 931.772 1289.86 934.375 1286.66 934.375C1283.46 934.375 1280.86 931.772 1280.86 928.568C1280.86 925.365 1283.46 922.762 1286.66 922.762Z" fill="#EA3A50"/>
<path d="M1008.8 195.704C1010.84 195.704 1012.49 197.358 1012.49 199.404C1012.49 201.45 1010.84 203.104 1008.8 203.104C1006.75 203.104 1005.1 201.45 1005.1 199.404C1005.1 197.358 1006.75 195.704 1008.8 195.704Z" fill="#59AEEF"/>
<path d="M831.181 1310.38C832.977 1310.38 834.428 1311.84 834.428 1313.63C834.428 1315.42 832.97 1316.88 831.181 1316.88C829.393 1316.88 827.935 1315.42 827.935 1313.63C827.935 1311.84 829.393 1310.38 831.181 1310.38Z" fill="#84C3F3"/>
<path d="M129.225 739.751C131.51 739.751 133.358 741.601 133.358 743.887C133.358 746.174 131.51 748.024 129.225 748.024C126.94 748.024 125.092 746.174 125.092 743.887C125.092 741.601 126.94 739.751 129.225 739.751Z" fill="#E93148"/>
<path d="M494.512 163.191C497.353 163.191 499.653 165.493 499.653 168.335C499.653 171.178 497.353 173.48 494.512 173.48C491.672 173.48 489.372 171.178 489.372 168.335C489.372 165.493 491.672 163.191 494.512 163.191Z" fill="#2293EA"/>
<path d="M1165.64 302.454C1168.02 302.454 1169.95 304.387 1169.95 306.763C1169.95 309.14 1168.02 311.073 1165.64 311.073C1163.27 311.073 1161.33 309.14 1161.33 306.763C1161.33 304.387 1163.27 302.454 1165.64 302.454Z" fill="#2796EA"/>
<path d="M289.263 303.507C291.059 303.507 292.517 304.966 292.517 306.763C292.517 308.561 291.059 310.02 289.263 310.02C287.467 310.02 286.009 308.561 286.009 306.763C286.009 304.966 287.467 303.507 289.263 303.507Z" fill="#5BAFEF"/>
<path d="M1334.87 681.773C1337.34 681.773 1339.34 683.781 1339.34 686.248C1339.34 688.715 1337.33 690.723 1334.87 690.723C1332.4 690.723 1330.4 688.715 1330.4 686.248C1330.4 683.781 1332.4 681.773 1334.87 681.773Z" fill="#E82840"/>
<path d="M119.545 662.001C122.19 662.001 124.332 664.144 124.332 666.792C124.332 669.439 122.19 671.583 119.545 671.583C116.9 671.583 114.758 669.439 114.758 666.792C114.758 664.144 116.9 662.001 119.545 662.001Z" fill="#E92F46"/>
<path d="M116.599 719.144C119.771 719.144 122.334 721.716 122.334 724.882C122.334 728.049 119.763 730.621 116.599 730.621C113.436 730.621 110.865 728.049 110.865 724.882C110.865 721.716 113.436 719.144 116.599 719.144Z" fill="#E93148"/>
<path d="M372.622 221.613C375.162 221.613 377.221 223.673 377.221 226.216C377.221 228.758 375.162 230.818 372.622 230.818C370.082 230.818 368.022 228.758 368.022 226.216C368.022 223.673 370.082 221.613 372.622 221.613Z" fill="#359DEB"/>
<path d="M927.94 141.185C930.157 141.185 931.946 142.983 931.946 145.194C931.946 147.405 930.15 149.202 927.94 149.202C925.731 149.202 923.935 147.405 923.935 145.194C923.935 142.983 925.731 141.185 927.94 141.185Z" fill="#81C2F3"/>
<path d="M118.486 807.085C120.931 807.085 122.913 805.102 122.913 802.655C122.913 800.209 120.931 798.225 118.486 798.225C116.041 798.225 114.06 800.209 114.06 802.655C114.06 805.102 116.041 807.085 118.486 807.085Z" fill="#E82A42"/>
<path d="M252.287 321.406C254.752 321.406 256.751 323.407 256.751 325.874C256.751 328.34 254.752 330.341 252.287 330.341C249.822 330.341 247.823 328.34 247.823 325.874C247.823 323.407 249.822 321.406 252.287 321.406Z" fill="#65B4F0"/>
<path d="M1193.9 1138.08C1196.34 1138.08 1198.32 1140.06 1198.32 1142.51C1198.32 1144.95 1196.34 1146.93 1193.9 1146.93C1191.46 1146.93 1189.47 1144.95 1189.47 1142.51C1189.47 1140.06 1191.46 1138.08 1193.9 1138.08Z" fill="#2C98EB"/>
<path d="M658.022 1351.61C660.069 1351.61 661.727 1349.95 661.727 1347.91C661.727 1345.86 660.069 1344.2 658.022 1344.2C655.976 1344.2 654.317 1345.86 654.317 1347.91C654.317 1349.95 655.976 1351.61 658.022 1351.61Z" fill="#52AAEE"/>
<path d="M540.325 1321.85C543.241 1321.85 545.609 1324.22 545.609 1327.13C545.609 1330.05 543.241 1332.42 540.325 1332.42C537.409 1332.42 535.042 1330.05 535.042 1327.13C535.042 1324.22 537.409 1321.85 540.325 1321.85Z" fill="#2997EA"/>
<path d="M1357.65 826.163C1358.35 823.216 1356.52 820.263 1353.58 819.568C1350.63 818.872 1347.68 820.697 1346.99 823.644C1346.29 826.591 1348.12 829.544 1351.06 830.24C1354.01 830.935 1356.96 829.11 1357.65 826.163Z" fill="#EA354C"/>
<path d="M217.319 353.949C220.05 353.949 222.264 351.733 222.264 349C222.264 346.267 220.05 344.052 217.319 344.052C214.588 344.052 212.374 346.267 212.374 349C212.374 351.733 214.588 353.949 217.319 353.949Z" fill="#62B3F0"/>
<path d="M1354.07 870.395C1356.21 870.395 1357.95 868.658 1357.95 866.514C1357.95 864.371 1356.21 862.634 1354.07 862.634C1351.93 862.634 1350.19 864.371 1350.19 866.514C1350.19 868.658 1351.93 870.395 1354.07 870.395Z" fill="#EA384E"/>
<path d="M1371.41 698.612C1374.62 698.612 1377.22 701.215 1377.22 704.426C1377.22 707.637 1374.62 710.24 1371.41 710.24C1368.2 710.24 1365.6 707.637 1365.6 704.426C1365.6 701.215 1368.2 698.612 1371.41 698.612Z" fill="#E82840"/>
<path d="M1235.56 320.812C1237.87 320.812 1239.75 322.692 1239.75 325.009C1239.75 327.325 1237.87 329.205 1235.56 329.205C1233.24 329.205 1231.36 327.325 1231.36 325.009C1231.36 322.692 1233.24 320.812 1235.56 320.812Z" fill="#F1582E"/>
<path d="M491.972 1338.47C493.761 1338.47 495.219 1339.93 495.219 1341.72C495.219 1343.52 493.768 1344.97 491.972 1344.97C490.176 1344.97 488.726 1343.52 488.726 1341.72C488.726 1339.93 490.176 1338.47 491.972 1338.47Z" fill="#2594EA"/>
<path d="M822.012 62.5027C824.365 62.5027 826.266 64.4054 826.266 66.7595C826.266 69.1135 824.365 71.0162 822.012 71.0162C819.66 71.0162 817.759 69.1135 817.759 66.7595C817.759 64.4054 819.66 62.5027 822.012 62.5027Z" fill="#7CBFF2"/>
<path d="M468.93 1337.64C471.636 1337.64 473.83 1339.84 473.83 1342.54C473.83 1345.25 471.636 1347.45 468.93 1347.45C466.225 1347.45 464.03 1345.25 464.03 1342.54C464.03 1339.84 466.225 1337.64 468.93 1337.64Z" fill="#2293EA"/>
<path d="M53.4707 682.372C53.7381 680.732 52.6266 679.186 50.9881 678.919C49.3495 678.651 47.8044 679.763 47.537 681.403C47.2695 683.043 48.381 684.589 50.0196 684.857C51.6581 685.124 53.2032 684.012 53.4707 682.372Z" fill="#E92F46"/>
<path d="M168.087 1105.38C170.777 1105.38 172.964 1107.56 172.964 1110.26C172.964 1112.96 170.784 1115.14 168.087 1115.14C165.389 1115.14 163.209 1112.96 163.209 1110.26C163.209 1107.56 165.389 1105.38 168.087 1105.38Z" fill="#65B4F0"/>
<path d="M75.1449 913.083C77.0613 913.083 78.617 914.64 78.617 916.558C78.617 918.475 77.0613 920.032 75.1449 920.032C73.2285 920.032 71.6729 918.475 71.6729 916.558C71.6729 914.64 73.2285 913.083 75.1449 913.083Z" fill="#F04D21"/>
<path d="M367.805 139.892C370.518 139.892 372.72 142.095 372.72 144.81C372.72 147.525 370.518 149.729 367.805 149.729C365.092 149.729 362.89 147.525 362.89 144.81C362.89 142.095 365.092 139.892 367.805 139.892Z" fill="#2C98EB"/>
<path d="M803.795 36.2776C805.764 36.2776 807.358 37.872 807.358 39.8425C807.358 41.8129 805.764 43.4073 803.795 43.4073C801.826 43.4073 800.226 41.8129 800.226 39.8425C800.226 37.872 801.819 36.2776 803.795 36.2776Z" fill="#78BDF2"/>
<path d="M607.722 1410.04C610.814 1410.04 613.321 1407.53 613.321 1404.43C613.321 1401.34 610.814 1398.83 607.722 1398.83C604.63 1398.83 602.123 1401.34 602.123 1404.43C602.123 1407.53 604.63 1410.04 607.722 1410.04Z" fill="#3FA1EC"/>
<path d="M199.072 248.432C200.928 248.432 202.439 249.936 202.439 251.801C202.439 253.666 200.936 255.171 199.072 255.171C197.208 255.171 195.705 253.666 195.705 251.801C195.705 249.936 197.208 248.432 199.072 248.432Z" fill="#62B3F0"/>
<path d="M1428.71 607.137C1431.72 607.137 1434.16 609.581 1434.16 612.597C1434.16 615.613 1431.72 618.057 1428.71 618.057C1425.69 618.057 1423.25 615.613 1423.25 612.597C1423.25 609.581 1425.69 607.137 1428.71 607.137Z" fill="#EA354C"/>
<path d="M20.5839 631.609C22.2372 631.609 23.5749 632.948 23.5749 634.61C23.5749 636.272 22.2372 637.603 20.5839 637.603C18.9305 637.603 17.5928 636.265 17.5928 634.61C17.5928 632.956 18.9305 631.609 20.5839 631.609Z" fill="#E82A42"/>
<path d="M939.175 48.6494C942.031 48.6494 944.346 46.3328 944.346 43.4751C944.346 40.6174 942.031 38.3008 939.175 38.3008C936.32 38.3008 934.005 40.6174 934.005 43.4751C934.005 46.3328 936.32 48.6494 939.175 48.6494Z" fill="#84C3F3"/>
<path d="M1182.07 1280.8C1184.32 1280.8 1186.14 1282.62 1186.14 1284.87C1186.14 1287.12 1184.32 1288.94 1182.07 1288.94C1179.82 1288.94 1178 1287.12 1178 1284.87C1178 1282.62 1179.82 1280.8 1182.07 1280.8Z" fill="#1B90E9"/>
<path d="M647.185 0.847168C649.244 0.847168 650.905 2.51679 650.905 4.57749C650.905 6.63819 649.236 8.30781 647.185 8.30781C645.133 8.30781 643.457 6.63819 643.457 4.57749C643.457 2.51679 645.125 0.847168 647.185 0.847168Z" fill="#52AAEE"/>
<path d="M1360.68 366.546C1363.86 366.546 1366.44 369.126 1366.44 372.315C1366.44 375.503 1363.86 378.083 1360.68 378.083C1357.49 378.083 1354.91 375.503 1354.91 372.315C1354.91 369.126 1357.49 366.546 1360.68 366.546Z" fill="#EA354C"/>
<path d="M4.30628 581.935C6.22267 581.935 7.77081 583.491 7.77081 585.402C7.77081 587.312 6.21515 588.869 4.30628 588.869C2.3974 588.869 0.841736 587.312 0.841736 585.402C0.841736 583.491 2.3974 581.935 4.30628 581.935Z" fill="#3B28ED"/>
<path d="M1123.08 1358.95C1125.31 1358.95 1127.11 1360.75 1127.11 1362.98C1127.11 1365.2 1125.31 1367.01 1123.08 1367.01C1120.86 1367.01 1119.05 1365.2 1119.05 1362.98C1119.05 1360.75 1120.86 1358.95 1123.08 1358.95Z" fill="#3FA1EC"/>
<path d="M73.7098 1141.08C76.0545 1141.08 77.9559 1142.99 77.9559 1145.33C77.9559 1147.68 76.0545 1149.58 73.7098 1149.58C71.365 1149.58 69.4636 1147.68 69.4636 1145.33C69.4636 1142.99 71.365 1141.08 73.7098 1141.08Z" fill="#5EB0EF"/>
<path d="M224.849 1391.58C228.049 1391.58 230.643 1388.98 230.643 1385.78C230.643 1382.58 228.049 1379.98 224.849 1379.98C221.649 1379.98 219.055 1382.58 219.055 1385.78C219.055 1388.98 221.649 1391.58 224.849 1391.58Z" fill="#3FA1EC"/>
<path d="M579.037 1050.11L566.809 1078.77C566.644 1079.17 566.185 1079.35 565.787 1079.19C565.389 1079.02 565.208 1078.56 565.374 1078.16L565.389 1078.13L579.029 1050.11H579.037Z" fill="#2293EA"/>
<path d="M1070.26 624.149L1107.51 612.394C1107.92 612.266 1108.36 612.492 1108.49 612.905C1108.62 613.319 1108.39 613.755 1107.98 613.883H1107.95L1070.26 624.156V624.149Z" fill="#EA3A50"/>
<path d="M1050.05 571.217L1088.44 552.069C1088.83 551.874 1089.29 552.032 1089.49 552.423C1089.68 552.806 1089.53 553.273 1089.14 553.468L1089.11 553.483L1050.05 571.225V571.217Z" fill="#EB3E54"/>
<path d="M370.901 747.558L318.768 751.657C318.339 751.694 317.964 751.371 317.933 750.942C317.903 750.513 318.219 750.137 318.647 750.107H318.67L370.901 747.565V747.558Z" fill="#E92F46"/>
<path d="M493.49 454.675L456.094 412.679C455.809 412.355 455.839 411.867 456.154 411.581C456.478 411.295 456.966 411.325 457.252 411.641L457.267 411.656L493.483 454.675H493.49Z" fill="#4DA8EE"/>
<path d="M476.836 470.062L435.194 428.833C434.886 428.533 434.886 428.036 435.194 427.728C435.495 427.419 435.991 427.419 436.299 427.728L436.314 427.743L476.844 470.062H476.836Z" fill="#5BAFEF"/>
<path d="M943.82 1009.4L982.065 1058.39C982.328 1058.73 982.268 1059.22 981.93 1059.48C981.592 1059.75 981.103 1059.69 980.84 1059.35L980.825 1059.33L943.82 1009.4Z" fill="#2594EA"/>
<path d="M1084.56 736.224L1146.84 737.42C1147.27 737.427 1147.61 737.781 1147.6 738.217C1147.6 738.646 1147.24 738.992 1146.81 738.984H1146.79L1084.56 736.224Z" fill="#E82840"/>
<path d="M1083.12 758.869L1147.94 764.276C1148.37 764.314 1148.69 764.69 1148.66 765.119C1148.62 765.547 1148.24 765.863 1147.81 765.833H1147.8L1083.12 758.869H1083.12Z" fill="#E92F46"/>
<path d="M833.534 1066.3L854.328 1130.62C854.464 1131.03 854.238 1131.47 853.825 1131.61C853.419 1131.74 852.976 1131.52 852.84 1131.1V1131.09L833.526 1066.3H833.534Z" fill="#84C3F3"/>
<path d="M699.146 1081.3L693.976 1156.27C693.946 1156.7 693.577 1157.02 693.141 1156.99C692.713 1156.96 692.39 1156.59 692.42 1156.16V1156.14L699.146 1081.3Z" fill="#57ADEF"/>
<path d="M395.769 857.768L321.03 888.55C320.631 888.716 320.181 888.528 320.015 888.129C319.85 887.73 320.038 887.279 320.436 887.114H320.451L395.769 857.775V857.768Z" fill="#128BE8"/>
<path d="M409.898 888.716L335.331 928.065C334.948 928.268 334.482 928.117 334.279 927.741C334.076 927.358 334.227 926.891 334.602 926.688H334.617L409.898 888.723V888.716Z" fill="#389EEC"/>
<path d="M511.083 440.363L457.86 371.66C457.597 371.322 457.657 370.833 457.995 370.57C458.333 370.307 458.822 370.367 459.085 370.705H459.093L511.076 440.363H511.083Z" fill="#3FA1EC"/>
<path d="M1078.28 657.218L1163.97 639.897C1164.39 639.815 1164.8 640.085 1164.88 640.507C1164.96 640.928 1164.69 641.341 1164.27 641.424H1164.26L1078.28 657.218Z" fill="#EA354C"/>
<path d="M1081.87 770.143L1171.92 780.86C1172.34 780.912 1172.65 781.296 1172.6 781.725C1172.55 782.153 1172.16 782.454 1171.74 782.409H1171.72L1081.87 770.143Z" fill="#E9334A"/>
<path d="M969.876 462.233L1031.41 394.403C1031.7 394.087 1032.19 394.057 1032.51 394.351C1032.82 394.636 1032.85 395.133 1032.56 395.449H1032.55L969.876 462.226V462.233Z" fill="#1B90E9"/>
<path d="M599.951 390.891L566.2 304.665C566.042 304.266 566.238 303.815 566.644 303.657C567.042 303.499 567.493 303.695 567.651 304.101V304.116L599.951 390.899V390.891Z" fill="#2594EA"/>
<path d="M568.801 404.526L526.497 320.872C526.302 320.489 526.46 320.023 526.843 319.827C527.226 319.631 527.692 319.789 527.888 320.173V320.188L568.801 404.534V404.526Z" fill="#2594EA"/>
<path d="M446.617 503.869L372.329 446.395C371.991 446.131 371.923 445.643 372.186 445.304C372.449 444.966 372.938 444.898 373.276 445.161H373.283L446.61 503.869H446.617Z" fill="#65B4F0"/>
<path d="M1034 541.224L1114.52 492.069C1114.89 491.843 1115.37 491.963 1115.59 492.324C1115.81 492.693 1115.69 493.174 1115.33 493.392H1115.32L1034 541.217V541.224Z" fill="#E93148"/>
<path d="M558.723 409.731L512.842 325.678C512.639 325.302 512.774 324.828 513.15 324.618C513.526 324.415 513.999 324.55 514.21 324.926L558.723 409.723V409.731Z" fill="#2796EA"/>
<path d="M1015.16 512.894L1094.55 453.427C1094.9 453.171 1095.39 453.239 1095.64 453.584C1095.9 453.93 1095.83 454.419 1095.49 454.675H1095.48L1015.16 512.894Z" fill="#F15F37"/>
<path d="M1028.01 531.583L1111.82 476.756C1112.18 476.523 1112.67 476.621 1112.9 476.982C1113.13 477.343 1113.03 477.824 1112.67 478.057H1112.67L1028.01 531.575V531.583Z" fill="#4735EE"/>
<path d="M1073.28 635.084L1170.61 609.009C1171.02 608.897 1171.45 609.145 1171.56 609.558C1171.68 609.972 1171.43 610.401 1171.01 610.514H1171L1073.28 635.084Z" fill="#EA354C"/>
<path d="M374.666 668.394L274.991 653.224C274.563 653.157 274.277 652.766 274.337 652.337C274.405 651.908 274.796 651.622 275.224 651.683H275.239L374.666 668.394Z" fill="#E7213A"/>
<path d="M961.421 454.675L1028.68 375.797C1028.96 375.466 1029.46 375.428 1029.78 375.707C1030.1 375.985 1030.15 376.481 1029.87 376.805H1029.86L961.421 454.668V454.675Z" fill="#168DE9"/>
<path d="M1021.73 927.628L1107.64 985.862C1107.99 986.103 1108.09 986.591 1107.85 986.945C1107.61 987.298 1107.12 987.396 1106.77 987.155H1106.76L1021.73 927.628Z" fill="#EAA23D"/>
<path d="M934.689 1016.13L995.623 1100.4C995.879 1100.74 995.796 1101.23 995.45 1101.49C995.105 1101.74 994.616 1101.66 994.361 1101.31L934.697 1016.13H934.689Z" fill="#2997EA"/>
<path d="M844.311 1062.76L879.527 1162.21C879.67 1162.62 879.459 1163.06 879.054 1163.2C878.648 1163.35 878.204 1163.13 878.062 1162.73L844.318 1062.76H844.311Z" fill="#81C2F3"/>
<path d="M1001.15 495.062L1082.57 425.667C1082.9 425.389 1083.39 425.427 1083.66 425.758C1083.94 426.088 1083.91 426.577 1083.57 426.856H1083.57L1001.14 495.062H1001.15Z" fill="#F05429"/>
<path d="M721.789 1082.38L720.865 1189.5C720.865 1189.92 720.512 1190.27 720.076 1190.27C719.647 1190.27 719.302 1189.92 719.302 1189.48L721.782 1082.37L721.789 1082.38Z" fill="#65B4F0"/>
<path d="M886.111 404.526L933.419 307.245C933.607 306.861 934.073 306.696 934.464 306.884C934.847 307.072 935.012 307.538 934.824 307.929L886.126 404.526H886.111Z" fill="#67B5F0"/>
<path d="M370.18 724.883L261.652 725.665C261.223 725.665 260.87 725.319 260.87 724.89C260.87 724.461 261.216 724.108 261.644 724.108H261.652L370.18 724.89V724.883Z" fill="#E93148"/>
<path d="M548.818 415.243L493.828 321.482C493.61 321.113 493.738 320.632 494.106 320.414C494.475 320.196 494.956 320.323 495.174 320.692L548.818 415.243Z" fill="#2C98EB"/>
<path d="M415.249 898.719L319.655 952.838C319.279 953.049 318.806 952.921 318.595 952.545C318.385 952.169 318.513 951.695 318.888 951.484H318.896L415.242 898.726L415.249 898.719Z" fill="#48A6ED"/>
<path d="M529.556 427.209L466.413 333.635C466.172 333.282 466.262 332.793 466.623 332.552C466.976 332.312 467.465 332.402 467.705 332.763L529.556 427.209Z" fill="#339BEB"/>
<path d="M426.898 531.583L330.311 470.386C329.951 470.153 329.838 469.672 330.071 469.311C330.304 468.95 330.785 468.837 331.146 469.07H331.153L426.898 531.583Z" fill="#5EB0EF"/>
<path d="M1073.28 814.681L1184.36 842.718C1184.78 842.824 1185.03 843.245 1184.93 843.666C1184.82 844.08 1184.4 844.335 1183.98 844.23H1183.97L1073.28 814.681Z" fill="#EA354C"/>
<path d="M610.601 1062.76L573.558 1172.26C573.423 1172.66 572.979 1172.88 572.573 1172.75C572.168 1172.61 571.95 1172.17 572.085 1171.76L610.601 1062.77V1062.76Z" fill="#2594EA"/>
<path d="M529.556 1022.56L466.022 1119.53C465.789 1119.89 465.301 1119.99 464.947 1119.75C464.587 1119.52 464.489 1119.03 464.722 1118.68L529.549 1022.56H529.556Z" fill="#339BEB"/>
<path d="M1081.87 679.622L1197.48 664.069C1197.91 664.009 1198.3 664.31 1198.36 664.739C1198.42 665.167 1198.11 665.558 1197.69 665.619H1197.68L1081.87 679.622Z" fill="#E9334A"/>
<path d="M476.836 979.702L394.927 1064.09C394.627 1064.4 394.138 1064.41 393.823 1064.11C393.515 1063.81 393.507 1063.32 393.808 1063L476.829 979.702H476.836Z" fill="#5BAFEF"/>
<path d="M446.618 945.904L353.669 1020.04C353.331 1020.31 352.842 1020.25 352.572 1019.92C352.301 1019.59 352.361 1019.1 352.692 1018.83H352.7L446.61 945.904H446.618Z" fill="#65B4F0"/>
<path d="M461.19 963.277L372.043 1044.13C371.728 1044.42 371.232 1044.4 370.946 1044.08C370.661 1043.76 370.683 1043.27 370.999 1042.98L461.182 963.277H461.19Z" fill="#62B3F0"/>
<path d="M822.659 380.272L854.208 263.158C854.321 262.744 854.749 262.496 855.163 262.609C855.576 262.722 855.824 263.15 855.711 263.564L822.659 380.272Z" fill="#86C4F3"/>
<path d="M453.764 954.703L361.063 1033.56C360.733 1033.84 360.244 1033.8 359.966 1033.47C359.688 1033.14 359.725 1032.65 360.056 1032.37H360.064L453.764 954.703Z" fill="#65B4F0"/>
<path d="M370.36 713.541L246.756 710.397C246.328 710.39 245.99 710.029 245.997 709.6C246.005 709.171 246.366 708.833 246.794 708.841H246.801L370.353 713.549L370.36 713.541Z" fill="#E93148"/>
<path d="M485.043 987.532L401.676 1079C401.383 1079.32 400.895 1079.34 400.579 1079.05C400.263 1078.76 400.241 1078.27 400.526 1077.95L485.036 987.532H485.043Z" fill="#57ADEF"/>
<path d="M388.021 613.319L269.572 575.211C269.167 575.076 268.941 574.639 269.069 574.233C269.204 573.82 269.64 573.601 270.046 573.729H270.053L388.013 613.326L388.021 613.319Z" fill="#2293EA"/>
<path d="M1050.05 878.555L1162.97 931.479C1163.36 931.66 1163.52 932.126 1163.34 932.517C1163.16 932.908 1162.69 933.073 1162.3 932.893H1162.3L1050.05 878.563V878.555Z" fill="#EB3E54"/>
<path d="M875.882 399.653L927.106 285.532C927.279 285.141 927.745 284.961 928.136 285.141C928.526 285.314 928.707 285.78 928.526 286.171L875.889 399.653H875.882Z" fill="#75BCF2"/>
<path d="M415.249 551.047L305.068 490.587C304.692 490.376 304.549 489.903 304.76 489.527C304.97 489.15 305.436 489.008 305.819 489.218H305.827L415.257 551.039L415.249 551.047Z" fill="#48A6ED"/>
<path d="M468.885 971.617L377.837 1059.57C377.529 1059.87 377.033 1059.87 376.733 1059.55C376.432 1059.24 376.44 1058.75 376.755 1058.45L468.877 971.617H468.885Z" fill="#5BAFEF"/>
<path d="M1059.13 591.997L1177.26 543.834C1177.66 543.669 1178.11 543.864 1178.27 544.263C1178.44 544.661 1178.24 545.113 1177.85 545.278H1177.84L1059.13 591.997Z" fill="#EA3C52"/>
<path d="M400.151 581.536L282.92 531.049C282.522 530.876 282.341 530.417 282.514 530.026C282.687 529.627 283.145 529.447 283.536 529.62H283.544L400.151 581.543V581.536Z" fill="#1B90E9"/>
<path d="M568.801 1045.24L512.406 1160.86C512.218 1161.25 511.752 1161.41 511.361 1161.22C510.978 1161.04 510.813 1160.57 511.001 1160.18L568.793 1045.24H568.801Z" fill="#2594EA"/>
<path d="M755.773 1081.3L766.746 1209.59C766.783 1210.02 766.468 1210.4 766.039 1210.43C765.611 1210.47 765.235 1210.15 765.198 1209.73L755.781 1081.3H755.773Z" fill="#75BCF2"/>
<path d="M520.215 1016.13L445.551 1122.39C445.303 1122.75 444.814 1122.83 444.468 1122.58C444.115 1122.33 444.033 1121.85 444.281 1121.5L520.215 1016.13Z" fill="#359DEB"/>
<path d="M886.111 1045.24L944.459 1161.3C944.654 1161.68 944.497 1162.15 944.113 1162.35C943.73 1162.54 943.264 1162.38 943.069 1162L886.118 1045.24H886.111Z" fill="#67B5F0"/>
<path d="M1070.26 825.616L1196.09 861.776C1196.51 861.897 1196.75 862.325 1196.63 862.739C1196.51 863.152 1196.08 863.393 1195.66 863.273H1195.66L1070.27 825.616H1070.26Z" fill="#EA3A50"/>
<path d="M632.252 1069.49L598.013 1196.35C597.9 1196.77 597.472 1197.02 597.058 1196.9C596.645 1196.79 596.397 1196.36 596.51 1195.95L632.252 1069.49Z" fill="#2E99EB"/>
<path d="M632.252 380.272L596.457 253.629C596.337 253.215 596.577 252.787 596.998 252.666C597.411 252.546 597.84 252.787 597.96 253.208L632.252 380.272Z" fill="#2E99EB"/>
<path d="M733.122 1082.38L735.993 1214.25C736.001 1214.68 735.662 1215.04 735.234 1215.05C734.806 1215.06 734.445 1214.72 734.437 1214.29L733.122 1082.38Z" fill="#6AB6F1"/>
<path d="M579.037 399.653L523.551 279.944C523.371 279.553 523.536 279.087 523.927 278.906C524.318 278.726 524.784 278.891 524.964 279.282L579.029 399.645L579.037 399.653Z" fill="#2293EA"/>
<path d="M502.178 1002.39L418.879 1106.22C418.608 1106.56 418.12 1106.62 417.781 1106.35C417.443 1106.07 417.391 1105.59 417.661 1105.25L502.163 1002.39H502.178Z" fill="#44A3ED"/>
<path d="M1015.16 936.871L1123.2 1015.51C1123.55 1015.76 1123.63 1016.25 1123.38 1016.6C1123.12 1016.95 1122.64 1017.03 1122.29 1016.77L1015.17 936.871H1015.16Z" fill="#F15F37"/>
<path d="M400.15 868.229L274.57 924.078C274.179 924.251 273.713 924.078 273.54 923.68C273.368 923.289 273.54 922.822 273.939 922.649H273.946L400.15 868.221V868.229Z" fill="#1B90E9"/>
<path d="M978.082 979.702L1075.26 1077.41C1075.56 1077.71 1075.56 1078.2 1075.26 1078.51C1074.95 1078.81 1074.47 1078.81 1074.16 1078.51L978.082 979.71V979.702Z" fill="#2293EA"/>
<path d="M1084.01 747.558L1221.79 755.537C1222.22 755.56 1222.55 755.928 1222.52 756.357C1222.5 756.786 1222.13 757.117 1221.7 757.087H1221.7L1084.01 747.55V747.558Z" fill="#E82840"/>
<path d="M676.607 1078.78L657.714 1215.75C657.653 1216.18 657.263 1216.47 656.834 1216.41C656.406 1216.35 656.113 1215.96 656.173 1215.53L676.607 1078.77V1078.78Z" fill="#46A5ED"/>
<path d="M409.898 561.049L284.077 497.017C283.694 496.822 283.544 496.355 283.739 495.972C283.934 495.588 284.4 495.438 284.784 495.633H284.791L409.898 561.057V561.049Z" fill="#389EEC"/>
<path d="M1028.01 918.182L1147.22 993.917C1147.58 994.15 1147.69 994.631 1147.46 994.992C1147.23 995.353 1146.75 995.466 1146.39 995.233L1028.02 918.182H1028.01Z" fill="#4735EE"/>
<path d="M374.666 781.371L235.07 804.513C234.649 804.58 234.244 804.295 234.176 803.873C234.108 803.452 234.394 803.046 234.815 802.978H234.822L374.666 781.371Z" fill="#E7213A"/>
<path d="M811.687 377.421L844.934 236.955C845.032 236.534 845.453 236.279 845.873 236.376C846.294 236.474 846.55 236.895 846.452 237.316L811.687 377.429V377.421Z" fill="#84C3F3"/>
<path d="M493.49 995.097L399.557 1104.77C399.279 1105.1 398.783 1105.13 398.46 1104.86C398.136 1104.58 398.099 1104.08 398.377 1103.76L493.49 995.097Z" fill="#4DA8EE"/>
<path d="M1078.28 792.547L1220.6 819.201C1221.02 819.284 1221.3 819.69 1221.22 820.111C1221.14 820.532 1220.74 820.81 1220.31 820.735H1220.31L1078.28 792.547Z" fill="#EA354C"/>
<path d="M915.826 421.072L991.723 297.159C991.948 296.791 992.429 296.678 992.79 296.904C993.158 297.129 993.271 297.611 993.045 297.979L915.826 421.072Z" fill="#3FA1EC"/>
<path d="M744.455 1082.02L752.158 1227.48C752.181 1227.91 751.85 1228.28 751.422 1228.3C750.993 1228.33 750.625 1227.99 750.603 1227.57L744.455 1082.02Z" fill="#71BAF1"/>
<path d="M1063.19 602.594L1199.74 552.024C1200.14 551.874 1200.59 552.084 1200.74 552.483C1200.89 552.889 1200.68 553.333 1200.28 553.483H1200.27L1063.19 602.587V602.594Z" fill="#EA3A50"/>
<path d="M865.488 395.103L922.011 258.051C922.176 257.652 922.627 257.464 923.025 257.63C923.423 257.795 923.611 258.247 923.446 258.645L865.488 395.103Z" fill="#7ABEF2"/>
<path d="M778.304 1078.78L800.271 1226.26C800.331 1226.69 800.038 1227.08 799.617 1227.14C799.189 1227.2 798.798 1226.91 798.73 1226.49L778.304 1078.78Z" fill="#7CBFF2"/>
<path d="M643.225 377.421L607.324 232.623C607.219 232.202 607.475 231.781 607.896 231.676C608.316 231.57 608.737 231.826 608.843 232.247L643.232 377.421H643.225Z" fill="#339BEB"/>
<path d="M391.726 847.171L251.694 899.004C251.288 899.155 250.845 898.944 250.694 898.545C250.544 898.139 250.755 897.695 251.153 897.545H251.16L391.726 847.171Z" fill="#1B90E9"/>
<path d="M1045.02 561.049L1177.51 491.813C1177.9 491.617 1178.36 491.76 1178.57 492.144C1178.76 492.527 1178.62 492.994 1178.24 493.197L1045.03 561.049H1045.02Z" fill="#EA3C52"/>
<path d="M378.957 646.11L232.665 613.845C232.244 613.755 231.981 613.334 232.072 612.92C232.162 612.499 232.583 612.236 232.996 612.326H233.003L378.957 646.117V646.11Z" fill="#E89B2F"/>
<path d="M710.456 1082.02L704.053 1232.96C704.038 1233.39 703.67 1233.72 703.241 1233.7C702.813 1233.69 702.475 1233.32 702.497 1232.89L710.456 1082.01V1082.02Z" fill="#5BAFEF"/>
<path d="M934.689 433.639L1021.92 309.719C1022.17 309.366 1022.66 309.283 1023 309.531C1023.35 309.779 1023.44 310.268 1023.19 310.614L934.689 433.632V433.639Z" fill="#2997EA"/>
<path d="M404.863 878.555L264.973 946.054C264.583 946.242 264.124 946.077 263.936 945.693C263.748 945.302 263.914 944.843 264.297 944.655L404.855 878.563L404.863 878.555Z" fill="#2C98EB"/>
<path d="M420.915 908.541L287.97 989.103C287.602 989.329 287.121 989.209 286.903 988.84C286.677 988.472 286.798 987.99 287.166 987.772L420.915 908.541Z" fill="#57ADEF"/>
<path d="M986.033 971.617L1099.97 1079.27C1100.29 1079.56 1100.3 1080.06 1100 1080.37C1099.71 1080.68 1099.21 1080.69 1098.9 1080.4L986.033 971.61V971.617Z" fill="#2796EA"/>
<path d="M433.173 522.137L302.67 433.173C302.317 432.932 302.219 432.444 302.467 432.09C302.708 431.737 303.196 431.639 303.549 431.887L433.165 522.137H433.173Z" fill="#65B4F0"/>
<path d="M391.726 602.594L240.707 548.414C240.301 548.271 240.09 547.82 240.233 547.414C240.376 547.008 240.827 546.797 241.233 546.94L391.718 602.587L391.726 602.594Z" fill="#1B90E9"/>
<path d="M376.635 792.547L218.867 823.774C218.446 823.856 218.033 823.585 217.95 823.157C217.868 822.736 218.138 822.322 218.567 822.239L376.628 792.547H376.635Z" fill="#3B28ED"/>
<path d="M1066.9 613.319L1219.49 562.343C1219.89 562.207 1220.34 562.426 1220.47 562.832C1220.61 563.238 1220.39 563.682 1219.98 563.817L1066.91 613.311L1066.9 613.319Z" fill="#EA3A50"/>
<path d="M621.37 383.468L572.513 228.855C572.386 228.442 572.611 228.005 573.024 227.878C573.438 227.75 573.874 227.975 574.001 228.389L621.37 383.476V383.468Z" fill="#2997EA"/>
<path d="M767.054 1080.22L786.045 1243.6C786.097 1244.03 785.789 1244.41 785.361 1244.46C784.932 1244.52 784.549 1244.21 784.497 1243.78L767.054 1080.22Z" fill="#78BDF2"/>
<path d="M643.224 1072.34L605.189 1232.54C605.092 1232.97 604.671 1233.22 604.25 1233.12C603.829 1233.03 603.573 1232.6 603.671 1232.18L643.217 1072.34H643.224Z" fill="#339BEB"/>
<path d="M395.769 591.997L242.443 531.41C242.044 531.252 241.849 530.801 242.007 530.402C242.165 530.003 242.615 529.808 243.014 529.966L395.762 592.005L395.769 591.997Z" fill="#128BE8"/>
<path d="M969.876 987.532L1082.33 1108.23C1082.63 1108.55 1082.61 1109.04 1082.3 1109.33C1081.98 1109.62 1081.49 1109.61 1081.2 1109.29L969.883 987.532H969.876Z" fill="#1B90E9"/>
<path d="M610.601 387.01L555.724 230.713C555.581 230.307 555.792 229.863 556.198 229.72C556.603 229.577 557.047 229.788 557.19 230.194L610.593 387.01H610.601Z" fill="#2594EA"/>
<path d="M1034 908.541L1178.54 994.233C1178.91 994.451 1179.04 994.932 1178.82 995.301C1178.6 995.669 1178.12 995.789 1177.75 995.571L1034 908.541H1034Z" fill="#E93148"/>
<path d="M800.632 1074.85L836.036 1240.38C836.126 1240.8 835.856 1241.22 835.435 1241.31C835.014 1241.4 834.601 1241.13 834.511 1240.7L800.632 1074.84V1074.85Z" fill="#84C3F3"/>
<path d="M388.021 836.446L227.104 890.152C226.698 890.287 226.254 890.069 226.119 889.656C225.984 889.25 226.202 888.806 226.615 888.671L388.021 836.438V836.446Z" fill="#2293EA"/>
<path d="M654.287 1074.85L620.085 1242.25C620.003 1242.67 619.589 1242.94 619.169 1242.86C618.748 1242.77 618.477 1242.36 618.56 1241.94L654.287 1074.85Z" fill="#389EEC"/>
<path d="M710.457 367.75L701.28 191.357C701.258 190.928 701.588 190.56 702.017 190.537C702.445 190.514 702.814 190.845 702.836 191.274L710.457 367.742V367.75Z" fill="#5BAFEF"/>
<path d="M822.659 1069.49L870.652 1240.26C870.772 1240.67 870.524 1241.1 870.111 1241.22C869.697 1241.34 869.269 1241.1 869.149 1240.68L822.659 1069.49Z" fill="#86C4F3"/>
<path d="M978.082 470.062L1102.94 341.998C1103.24 341.69 1103.74 341.682 1104.05 341.983C1104.35 342.284 1104.36 342.78 1104.06 343.089L978.09 470.062H978.082Z" fill="#2293EA"/>
<path d="M1001.15 954.703L1138.98 1069.44C1139.31 1069.72 1139.36 1070.21 1139.08 1070.54C1138.8 1070.87 1138.32 1070.91 1137.98 1070.64L1001.15 954.703Z" fill="#F05429"/>
<path d="M952.733 447.38L1066.05 306.583C1066.32 306.244 1066.81 306.192 1067.15 306.463C1067.48 306.733 1067.54 307.222 1067.27 307.561L952.748 447.38H952.733Z" fill="#1B90E9"/>
<path d="M875.883 1050.11L952.163 1215.39C952.343 1215.78 952.17 1216.25 951.779 1216.43C951.388 1216.61 950.922 1216.43 950.742 1216.04L875.875 1050.11H875.883Z" fill="#75BCF2"/>
<path d="M1084.73 724.882L1269.01 724.1C1269.44 724.1 1269.8 724.446 1269.8 724.875C1269.8 725.304 1269.45 725.657 1269.02 725.657L1084.74 724.875L1084.73 724.882Z" fill="#E7213A"/>
<path d="M925.355 427.209L1026.91 273.07C1027.15 272.709 1027.63 272.612 1027.99 272.852C1028.35 273.085 1028.45 273.574 1028.21 273.935L925.363 427.217L925.355 427.209Z" fill="#339BEB"/>
<path d="M925.355 1022.56L1028.74 1176.66C1028.98 1177.03 1028.89 1177.51 1028.53 1177.75C1028.17 1177.99 1027.69 1177.89 1027.45 1177.54L925.355 1022.57V1022.56Z" fill="#339BEB"/>
<path d="M665.417 372.773L632.432 190.04C632.357 189.619 632.635 189.213 633.063 189.138C633.484 189.063 633.89 189.341 633.965 189.77L665.417 372.773Z" fill="#3FA1EC"/>
<path d="M404.863 571.217L236.167 491.722C235.777 491.542 235.611 491.076 235.792 490.685C235.972 490.294 236.438 490.128 236.829 490.309L404.855 571.21L404.863 571.217Z" fill="#2C98EB"/>
<path d="M439.748 936.871L289.33 1048.67C288.984 1048.92 288.496 1048.86 288.24 1048.51C287.985 1048.16 288.053 1047.68 288.398 1047.42L439.741 936.871H439.748Z" fill="#62B3F0"/>
<path d="M1084.73 724.882L1273.7 724.1C1274.13 724.1 1274.48 724.446 1274.48 724.875C1274.48 725.304 1274.14 725.657 1273.71 725.657L1084.74 724.875L1084.73 724.882Z" fill="#E7213A"/>
<path d="M721.789 367.389L718.001 177.421C717.994 176.992 718.332 176.631 718.768 176.623C719.196 176.616 719.557 176.954 719.564 177.391L721.796 367.389H721.789Z" fill="#65B4F0"/>
<path d="M420.915 541.224L256.143 443.409C255.775 443.191 255.654 442.709 255.872 442.341C256.09 441.972 256.571 441.852 256.94 442.07L420.915 541.224Z" fill="#57ADEF"/>
<path d="M699.145 368.464L683.183 177.368C683.145 176.939 683.468 176.563 683.897 176.526C684.325 176.488 684.701 176.811 684.738 177.24L699.145 368.464Z" fill="#57ADEF"/>
<path d="M1075.95 646.117L1265.24 602.534C1265.66 602.436 1266.07 602.7 1266.17 603.121C1266.27 603.542 1266.01 603.955 1265.59 604.053L1075.95 646.117Z" fill="#EA384E"/>
<path d="M1066.9 836.446L1252.46 896.613C1252.86 896.748 1253.09 897.184 1252.96 897.598C1252.83 898.004 1252.39 898.229 1251.98 898.102L1066.91 836.446H1066.9Z" fill="#EA3A50"/>
<path d="M896.188 1040.03L989.017 1211.75C989.22 1212.13 989.077 1212.6 988.701 1212.8C988.326 1213.01 987.852 1212.87 987.649 1212.49L896.196 1040.03L896.188 1040.03Z" fill="#59AEEF"/>
<path d="M906.094 415.243L1003.03 245.657C1003.24 245.281 1003.72 245.153 1004.09 245.364C1004.47 245.574 1004.6 246.055 1004.39 246.424L906.101 415.236L906.094 415.243Z" fill="#4DA8EE"/>
<path d="M381.633 814.681L192.008 864.724C191.595 864.837 191.166 864.589 191.054 864.168C190.941 863.754 191.189 863.325 191.61 863.213L381.633 814.673V814.681Z" fill="#F05327"/>
<path d="M1008.29 945.904L1164.21 1067.62C1164.55 1067.88 1164.61 1068.37 1164.35 1068.71C1164.09 1069.05 1163.6 1069.11 1163.26 1068.85L1008.3 945.904H1008.29Z" fill="#F1582E"/>
<path d="M548.817 1034.52L450.262 1206.91C450.052 1207.28 449.571 1207.41 449.202 1207.19C448.827 1206.98 448.699 1206.5 448.917 1206.13L548.825 1034.52H548.817Z" fill="#2C98EB"/>
<path d="M943.82 440.363L1063.61 281.554C1063.87 281.208 1064.36 281.14 1064.7 281.403C1065.05 281.659 1065.12 282.148 1064.85 282.494L943.82 440.363Z" fill="#2594EA"/>
<path d="M789.502 372.773L823.418 175.781C823.494 175.36 823.892 175.074 824.32 175.142C824.741 175.217 825.027 175.616 824.959 176.044L789.509 372.773H789.502Z" fill="#84C3F3"/>
<path d="M558.723 1040.03L464.759 1217.19C464.556 1217.58 464.083 1217.72 463.707 1217.52C463.324 1217.31 463.181 1216.84 463.384 1216.46L558.723 1040.04V1040.03Z" fill="#2796EA"/>
<path d="M1059.13 857.768L1245.9 931.75C1246.29 931.908 1246.49 932.359 1246.33 932.758C1246.17 933.156 1245.72 933.352 1245.33 933.194L1059.14 857.76L1059.13 857.768Z" fill="#EA3C52"/>
<path d="M1021.73 522.137L1187.32 407.113C1187.67 406.865 1188.16 406.955 1188.4 407.309C1188.65 407.662 1188.56 408.151 1188.2 408.392L1021.74 522.137H1021.73Z" fill="#EAA23D"/>
<path d="M854.96 1058.88L928.068 1248.17C928.226 1248.57 928.023 1249.02 927.625 1249.18C927.227 1249.34 926.776 1249.14 926.618 1248.74L854.967 1058.88H854.96Z" fill="#7CBFF2"/>
<path d="M1054.75 581.536L1240.59 499.296C1240.98 499.123 1241.45 499.296 1241.62 499.695C1241.79 500.093 1241.62 500.552 1241.22 500.725L1054.76 581.543L1054.75 581.536Z" fill="#EB3E54"/>
<path d="M854.96 390.891L926.926 200.216C927.076 199.81 927.527 199.607 927.933 199.765C928.339 199.915 928.542 200.366 928.384 200.773L854.967 390.891H854.96Z" fill="#7CBFF2"/>
<path d="M502.178 447.38L372.862 289.33C372.592 288.999 372.637 288.503 372.975 288.232C373.306 287.961 373.802 288.006 374.072 288.345L502.178 447.387V447.38Z" fill="#44A3ED"/>
<path d="M811.687 1072.34L860.686 1271.14C860.791 1271.56 860.536 1271.98 860.115 1272.08C859.694 1272.19 859.273 1271.93 859.176 1271.51L811.694 1072.34H811.687Z" fill="#84C3F3"/>
<path d="M654.286 374.924L611.585 174.502C611.495 174.081 611.765 173.668 612.186 173.577C612.607 173.487 613.02 173.758 613.11 174.179L654.286 374.924Z" fill="#389EEC"/>
<path d="M539.093 1028.69L431.452 1203.78C431.227 1204.15 430.746 1204.27 430.377 1204.04C430.009 1203.81 429.896 1203.33 430.122 1202.96L539.086 1028.69H539.093Z" fill="#2C98EB"/>
<path d="M961.421 995.097L1096.73 1150.18C1097.02 1150.5 1096.98 1151 1096.66 1151.27C1096.34 1151.56 1095.84 1151.52 1095.56 1151.2L961.429 995.097H961.421Z" fill="#168DE9"/>
<path d="M865.488 1054.66L946.292 1245.68C946.458 1246.07 946.277 1246.53 945.879 1246.7C945.481 1246.86 945.03 1246.68 944.857 1246.28L865.488 1054.66Z" fill="#7ABEF2"/>
<path d="M1039.67 898.718L1225.18 1001.13C1225.56 1001.34 1225.7 1001.81 1225.49 1002.19C1225.28 1002.57 1224.81 1002.7 1224.43 1002.5L1039.68 898.726L1039.67 898.718Z" fill="#EA354C"/>
<path d="M676.608 370.984L645.675 161.153C645.615 160.724 645.908 160.333 646.329 160.266C646.757 160.205 647.148 160.499 647.215 160.92L676.608 370.976V370.984Z" fill="#46A5ED"/>
<path d="M755.773 368.464L771.908 155.49C771.939 155.061 772.314 154.738 772.743 154.775C773.171 154.806 773.494 155.182 773.457 155.61L755.766 368.464H755.773Z" fill="#75BCF2"/>
<path d="M744.455 367.75L753.917 152.587C753.939 152.158 754.3 151.827 754.728 151.842C755.157 151.865 755.488 152.226 755.472 152.655L744.455 367.75Z" fill="#71BAF1"/>
<path d="M1054.75 868.229L1256.55 955.764C1256.95 955.937 1257.13 956.395 1256.96 956.786C1256.79 957.178 1256.33 957.366 1255.94 957.193L1054.76 868.229H1054.75Z" fill="#EB3E54"/>
<path d="M906.094 1034.52L1017.36 1225.83C1017.58 1226.2 1017.45 1226.68 1017.09 1226.9C1016.72 1227.11 1016.24 1226.99 1016.02 1226.62L906.101 1034.53L906.094 1034.52Z" fill="#4DA8EE"/>
<path d="M371.796 758.869L151.56 780.694C151.132 780.739 150.749 780.424 150.711 779.995C150.666 779.566 150.982 779.183 151.41 779.145L371.788 758.869H371.796Z" fill="#E92F46"/>
<path d="M485.044 462.234L333.648 299.355C333.355 299.04 333.37 298.551 333.686 298.257C334.002 297.964 334.49 297.979 334.783 298.295L485.028 462.234H485.044Z" fill="#57ADEF"/>
<path d="M733.122 367.389L735.918 141.975C735.918 141.546 736.279 141.2 736.707 141.208C737.135 141.208 737.481 141.569 737.474 141.998L733.122 367.396V367.389Z" fill="#6AB6F1"/>
<path d="M381.633 635.084L163.285 579.189C162.871 579.084 162.616 578.655 162.721 578.242C162.826 577.828 163.255 577.572 163.668 577.678L381.626 635.077L381.633 635.084Z" fill="#F05327"/>
<path d="M993.722 486.488L1161.68 335.057C1162 334.771 1162.49 334.793 1162.78 335.117C1163.06 335.44 1163.04 335.929 1162.72 336.215L993.722 486.488Z" fill="#2C98EB"/>
<path d="M384.654 624.149L165.005 560.418C164.592 560.297 164.351 559.869 164.471 559.455C164.592 559.041 165.02 558.801 165.433 558.921L384.646 624.149H384.654Z" fill="#F04D21"/>
<path d="M378.957 803.655L154.16 855.263C153.739 855.361 153.326 855.098 153.228 854.676C153.131 854.255 153.394 853.842 153.815 853.744L378.957 803.655Z" fill="#E89B2F"/>
<path d="M800.632 374.924L847.392 147.51C847.482 147.089 847.888 146.818 848.309 146.901C848.729 146.991 849 147.398 848.917 147.819L800.639 374.917L800.632 374.924Z" fill="#84C3F3"/>
<path d="M1045.02 888.716L1252.13 994.691C1252.51 994.887 1252.66 995.361 1252.46 995.737C1252.27 996.12 1251.8 996.271 1251.42 996.075L1045.03 888.716H1045.02Z" fill="#EA3C52"/>
<path d="M1083.12 690.896L1318.65 667.604C1319.08 667.559 1319.46 667.875 1319.5 668.303C1319.55 668.732 1319.23 669.116 1318.8 669.153L1083.12 690.896Z" fill="#E92F46"/>
<path d="M1063.19 847.171L1286.93 927.839C1287.34 927.982 1287.55 928.433 1287.4 928.839C1287.25 929.245 1286.8 929.456 1286.4 929.305L1063.19 847.171Z" fill="#EA3A50"/>
<path d="M896.188 409.731L1008.11 199.035C1008.32 198.652 1008.79 198.509 1009.17 198.712C1009.55 198.915 1009.69 199.389 1009.49 199.765L896.196 409.731H896.188Z" fill="#59AEEF"/>
<path d="M789.502 1076.99L831.948 1313.49C832.023 1313.91 831.745 1314.32 831.317 1314.39C830.896 1314.47 830.49 1314.19 830.415 1313.76L789.502 1076.98V1076.99Z" fill="#84C3F3"/>
<path d="M370.36 736.224L129.255 744.662C128.826 744.677 128.466 744.339 128.451 743.91C128.436 743.481 128.774 743.12 129.202 743.105L370.36 736.224Z" fill="#E93148"/>
<path d="M589.423 395.103L493.791 168.636C493.625 168.238 493.806 167.779 494.204 167.613C494.602 167.448 495.061 167.629 495.226 168.027L589.423 395.095V395.103Z" fill="#2293EA"/>
<path d="M986.033 478.155L1165.11 306.207C1165.42 305.906 1165.91 305.921 1166.21 306.229C1166.51 306.538 1166.5 307.034 1166.19 307.335L986.041 478.155H986.033Z" fill="#2796EA"/>
<path d="M468.885 478.155L288.729 307.327C288.413 307.034 288.406 306.538 288.699 306.229C288.992 305.913 289.488 305.906 289.804 306.199L468.885 478.162V478.155Z" fill="#5BAFEF"/>
<path d="M1084.01 702.207L1334.82 685.473C1335.25 685.443 1335.61 685.767 1335.64 686.195C1335.67 686.624 1335.35 686.993 1334.91 687.023L1084.01 702.2V702.207Z" fill="#E82840"/>
<path d="M371.795 690.896L119.47 667.567C119.042 667.529 118.726 667.145 118.764 666.717C118.801 666.288 119.185 665.972 119.613 666.01L371.788 690.889L371.795 690.896Z" fill="#E92F46"/>
<path d="M370.179 724.883L116.599 725.665C116.171 725.665 115.817 725.319 115.817 724.89C115.817 724.461 116.163 724.108 116.591 724.108L370.172 724.89L370.179 724.883Z" fill="#E93148"/>
<path d="M520.215 433.639L371.983 226.667C371.735 226.313 371.81 225.832 372.164 225.576C372.517 225.328 372.998 225.403 373.253 225.757L520.215 433.639Z" fill="#359DEB"/>
<path d="M844.311 387.01L927.204 144.946C927.347 144.54 927.783 144.322 928.196 144.464C928.602 144.607 928.82 145.043 928.677 145.457L844.311 387.018V387.01Z" fill="#81C2F3"/>
<path d="M373.051 770.143L118.584 803.43C118.155 803.482 117.765 803.181 117.712 802.76C117.659 802.332 117.96 801.941 118.381 801.888L373.051 770.15V770.143Z" fill="#E82A42"/>
<path d="M453.764 495.062L251.784 326.468C251.453 326.19 251.408 325.701 251.686 325.37C251.964 325.039 252.453 324.994 252.784 325.272L453.764 495.062Z" fill="#65B4F0"/>
<path d="M993.722 963.277L1194.42 1141.92C1194.74 1142.2 1194.77 1142.7 1194.48 1143.02C1194.2 1143.34 1193.7 1143.37 1193.39 1143.08L993.729 963.277H993.722Z" fill="#2C98EB"/>
<path d="M687.857 1080.22L658.796 1347.99C658.751 1348.42 658.367 1348.73 657.939 1348.68C657.511 1348.64 657.202 1348.25 657.248 1347.82L687.85 1080.22H687.857Z" fill="#52AAEE"/>
<path d="M621.37 1066.3L541.07 1327.36C540.942 1327.77 540.506 1328 540.093 1327.88C539.679 1327.75 539.454 1327.31 539.574 1326.91L621.363 1066.3L621.37 1066.3Z" fill="#2997EA"/>
<path d="M1080.25 781.371L1352.49 824.18C1352.92 824.247 1353.21 824.646 1353.14 825.067C1353.07 825.496 1352.67 825.781 1352.25 825.714L1080.26 781.371H1080.25Z" fill="#EA354C"/>
<path d="M439.749 512.894L216.861 349.632C216.515 349.376 216.44 348.887 216.696 348.541C216.951 348.195 217.44 348.12 217.785 348.376L439.756 512.901L439.749 512.894Z" fill="#62B3F0"/>
<path d="M1075.95 803.655L1354.24 865.755C1354.66 865.845 1354.93 866.266 1354.84 866.687C1354.75 867.108 1354.33 867.372 1353.91 867.281L1075.96 803.663L1075.95 803.655Z" fill="#EA384E"/>
<path d="M1084.56 713.541L1371.39 703.651C1371.81 703.636 1372.18 703.975 1372.19 704.403C1372.21 704.832 1371.87 705.193 1371.44 705.208L1084.57 713.541H1084.56Z" fill="#E82840"/>
<path d="M1008.29 503.869L1235.07 324.4C1235.41 324.136 1235.9 324.189 1236.17 324.527C1236.44 324.866 1236.38 325.355 1236.04 325.626L1008.3 503.869H1008.29Z" fill="#F1582E"/>
<path d="M599.951 1058.88L492.701 1342C492.551 1342.41 492.1 1342.6 491.694 1342.45C491.296 1342.3 491.093 1341.85 491.243 1341.45L599.951 1058.87V1058.88Z" fill="#2594EA"/>
<path d="M778.304 370.984L821.239 66.6467C821.299 66.218 821.69 65.9247 822.118 65.9849C822.547 66.045 822.84 66.4361 822.78 66.8648L778.297 370.984H778.304Z" fill="#7CBFF2"/>
<path d="M589.422 1054.66L469.644 1342.84C469.479 1343.24 469.02 1343.43 468.629 1343.27C468.231 1343.1 468.043 1342.64 468.208 1342.25L589.422 1054.66Z" fill="#2293EA"/>
<path d="M370.901 702.207L50.4576 682.608C50.0292 682.578 49.6985 682.209 49.7286 681.781C49.7511 681.352 50.1269 681.021 50.5553 681.051L370.901 702.207Z" fill="#E92F46"/>
<path d="M433.173 927.628L168.53 1110.9C168.177 1111.15 167.688 1111.06 167.448 1110.71C167.2 1110.35 167.29 1109.86 167.643 1109.62L433.173 927.628Z" fill="#65B4F0"/>
<path d="M384.654 825.616L75.3702 917.31C74.9569 917.43 74.521 917.197 74.4008 916.783C74.2805 916.37 74.5135 915.934 74.9268 915.813L384.654 825.616Z" fill="#F04D21"/>
<path d="M534.066 498.04L362.116 222.192C361.891 221.823 362.004 221.35 362.364 221.116C362.733 220.891 363.206 221.004 363.439 221.365L534.058 498.04H534.066Z" fill="#2C98EB"/>
<path d="M767.054 369.547L803.014 39.7598C803.059 39.3311 803.442 39.0227 803.871 39.0679C804.299 39.113 804.607 39.4965 804.562 39.9252L767.054 369.54V369.547Z" fill="#78BDF2"/>
<path d="M665.417 1076.99L608.489 1404.57C608.413 1404.99 608.015 1405.27 607.587 1405.2C607.166 1405.12 606.88 1404.73 606.955 1404.3L665.417 1076.99Z" fill="#3FA1EC"/>
<path d="M461.19 486.488L198.553 252.38C198.23 252.095 198.2 251.598 198.493 251.282C198.779 250.959 199.275 250.936 199.591 251.222L461.19 486.488Z" fill="#62B3F0"/>
<path d="M1080.25 668.394L1428.58 611.822C1429.01 611.754 1429.41 612.04 1429.48 612.469C1429.55 612.89 1429.26 613.296 1428.83 613.364L1080.25 668.394Z" fill="#EA354C"/>
<path d="M373.051 679.622L20.487 635.378C20.0587 635.325 19.7581 634.934 19.8107 634.505C19.8633 634.076 20.2541 633.776 20.6824 633.828L373.051 679.615V679.622Z" fill="#E82A42"/>
<path d="M833.533 383.468L938.431 43.242C938.559 42.8283 938.995 42.6027 939.408 42.7306C939.822 42.8584 940.047 43.2946 939.919 43.7083L833.533 383.476V383.468Z" fill="#84C3F3"/>
<path d="M952.733 1002.39L1182.67 1284.38C1182.94 1284.71 1182.9 1285.2 1182.56 1285.48C1182.23 1285.75 1181.73 1285.7 1181.46 1285.36L952.733 1002.39Z" fill="#1B90E9"/>
<path d="M687.858 369.547L646.411 4.6602C646.366 4.23152 646.666 3.84795 647.095 3.79531C647.523 3.75018 647.906 4.05102 647.959 4.4797L687.858 369.547Z" fill="#52AAEE"/>
<path d="M1039.67 551.047L1360.29 371.63C1360.67 371.42 1361.14 371.555 1361.35 371.931C1361.56 372.307 1361.43 372.781 1361.05 372.992L1039.67 551.047Z" fill="#EA354C"/>
<path d="M376.635 657.218L4.16303 586.169C3.74218 586.086 3.46411 585.68 3.54678 585.259C3.62945 584.838 4.03527 584.559 4.45613 584.642L376.635 657.218Z" fill="#3B28ED"/>
<path d="M915.826 1028.69L1123.74 1362.56C1123.96 1362.93 1123.85 1363.41 1123.49 1363.64C1123.12 1363.86 1122.65 1363.75 1122.41 1363.39L915.826 1028.69Z" fill="#3FA1EC"/>
<path d="M426.897 918.182L74.1303 1145.99C73.7696 1146.22 73.2886 1146.12 73.0556 1145.75C72.8226 1145.39 72.9279 1144.91 73.2886 1144.68L426.897 918.19V918.182Z" fill="#5EB0EF"/>
<path d="M511.084 1009.4L225.466 1386.25C225.203 1386.6 224.714 1386.67 224.376 1386.4C224.03 1386.14 223.963 1385.65 224.226 1385.31L511.084 1009.4Z" fill="#3FA1EC"/>
</svg>

  )
}

export default Lines