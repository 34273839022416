import ChallengeRequestRow from "./ChallengeRequestRow";
import { useChallengeRequests } from "../../../hooks/Admin/ChallengeRequests/useChallengeRequests";
import Loading from "../../../components/shared/Loading";
import TableBackground from "../../../components/AdminShared/TableBackground";
import Table from "../../../components/AdminShared/Table";
import ErrorMessage from "../../../components/shared/ErrorMessage";

const tHeadData = ["Email", "Challenges", "Date", "Actions"];
export default function ChallengeRequests() {
  const { challengeRequests, error, loading } = useChallengeRequests();

  return (
    <>
      <TableBackground>
        {loading ? (
          <div className="w-full text-center">
            <Loading />
          </div>
        ) : error ? (
          <ErrorMessage text={error} />
        ) : (
          <Table theads={tHeadData}>
            {challengeRequests?.map((data) => (
              <ChallengeRequestRow key={data.email} data={data} />
            ))}
          </Table>
        )}
      </TableBackground>
    </>
  );
}
