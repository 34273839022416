import TableBackground from "../../../../components/AdminShared/TableBackground";
import CreateTitle from "./CreateTitle";
import { useNavigate } from "react-router-dom";

export default function BannerCreate() {
  const navigate = useNavigate();

  return (
    <>
      <TableBackground>
        <button onClick={() => navigate(-1)} className="btn btn-success">
          Back
        </button>
        <CreateTitle />
      </TableBackground>
      ;
    </>
  );
}
