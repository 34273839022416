import React from "react";
import { format } from "date-fns";
import { FaRegFileLines } from "react-icons/fa6";
const imageTypes = [".png", ".jpg", ".jpeg"];

export function ChatBubble({ data, isAdmin }) {
  function formatTextSpaces(value) {
    const formattedText = String(value).split("<?br>");
    return formattedText;
  }

  function isImage(file) {
    let flag = false;
    imageTypes.forEach((type) => {
      if (file.endsWith(type)) {
        return (flag = true);
      }
    });

    if (flag) return true;

    return false;
  }

  return (
    <div className={`chat ${isAdmin ? "chat-start" : "chat-end"}`}>
      <div
        className={`chat-bubble px-2 break-words shadow-md ${
          isAdmin
            ? "bg-gray-900 light:bg-[--indigo-purple] text-white"
            : "bg-[#F7F5FF]  text-zinc-900"
        }`}
      >
        {data.message.startsWith(process.env.REACT_APP_API_BASE_URL) ? (
          isImage(data.message) ? (
            <a href={data.message} target="_blank" rel="noreferrer" download>
              <img
                loading="lazy"
                src={data.message}
                className="size-56 object-cover rounded-xl"
                alt=""
              />
            </a>
          ) : (
            <a href={data.message} target="_blank" rel="noreferrer" download>
              <div className="flex items-center gap-2 mx-2 group">
                <div
                  className={`px-1.5 pt-1 pb-2 rounded-md border transition-colors group-hover:!border-gray-500 ${
                    isAdmin
                      ? "bg-gray-800 border-gray-700 light:bg-yellow-500 light:!border-light-border light:group-hover:!border-gray-800"
                      : "bg-gray-200  border-gray-300 "
                  }`}
                >
                  <FaRegFileLines size={20} />
                </div>
                <div>
                  <p>{data.message.split("/user/")[1]}</p>
                  <p className="text-xs">{data.created_at.split("T")[0]}</p>
                </div>
              </div>
            </a>
          )
        ) : (
          <div className="mx-2">
            {formatTextSpaces(data.message).map((text) => (
              <p>{text}</p>
            ))}
          </div>
        )}
      </div>
      <div
        className={`chat-footer text-neutral-200 light:text-neutral-800 text-xs font-semibold mt-1 capitalize`}
      >
        {data.user?.first_name}
        <time className="opacity-50 ml-1">
          {format(new Date(data.created_at), "yyyy-MM-dd HH:mm")}
        </time>
      </div>
    </div>
  );
}
