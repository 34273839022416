import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {useFetch} from "../API/useFetch"
import { postData } from "../../utils/fetchData";
export function useSucccessChallenges() {
  const [challenges, setChallenges] = useState([]);
  const [error, setError] = useState(false);
  const {loading, fetchData} = useFetch()

  async function getSuccessChallenges() {
    const res = await fetchData("challenges/user-challenges/success");
    if (!res) return;
    setChallenges(res.user_challenges);
    if (res.user_challenges.length === 0) {
      setError((prev) => true);
    }
  }
  
  const handleSubmit = async (id) => {
    const data = {
      user_challenge_id: id
    }
    const res = await postData("challenges/user-challenges/success", data);
    if (res?.data) {
      toast.success(res.data?.message || "successfully confimed");
      setChallenges([]);
    } else {
      toast.error(res?.message || "error reaching data!");
    }
  };

  useEffect(() => {
    if (!error && challenges.length === 0) {
      getSuccessChallenges();
    }
  }, [challenges]);

  return { loading, error, challenges, handleSubmit };
}
