import { formatComma } from "../../../utils/formatNumber";

const nationImg = {
  UAE: "../flags/UAE.png",
  IR: "../flags/IR.png",
  TR: "../flags/TR.png",
  CH: "../flags/CH.png",
  GE: "../flags/GE.png",
  KR: "../flags/KR.png",
  EN: "../flags/EN.png",
  BE: "../flags/BE.png",
  IT: "../flags/IT.png",
  CA: "../flags/CA.png",
};

export default function TopTraderTable({ data, title = "" }) {
  return (
    <div>
      <div className="text-start  text-lg text-white font-semibold mb-2">
        {title}
      </div>

      <table class="w-full table-fixed text-white">
        <thead className="text-gray-500 [&_th]:text-left">
          <tr>
            <th>Name</th>
            {!("participation" in data[0]) && !("withdraw" in data[0]) && (
              <th></th>
            )}
            <th>GF Factor</th>
            {"participation" in data[0] && <th>Participation</th>}
            {"withdraw" in data[0] && <th>Withdraw</th>}
          </tr>
        </thead>
        <tbody className="[&_tr:not(:last-child)]:border-b [&_tr]:border-[--chart-grid]">
          {data.map((item, i) => (
            <tr key={i} className="[&_td]:py-3 ">
              <td>
                <div className="flex items-center gap-2">
                  <img
                    className="max-w-[20px]"
                    src={nationImg[item.nation ?? "UAE"]}
                    alt=""
                  />

                  <div>
                    {item.name.substring(0, 5)}
                    {item.name.length > 5 && " ..."}
                  </div>
                </div>
              </td>
              {!("participation" in data[0]) && !("withdraw" in data[0]) && (
                <td></td>
              )}
              <td>{item.gf_factor}</td>
              {"participation" in data[0] && <td>{item.participation}</td>}
              {"withdraw" in data[0] && <td>${formatComma(item.withdraw)}</td>}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
