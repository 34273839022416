import { useEffect, useState } from "react";
import { useFetch } from "../API/useFetch";
import { usePost } from "../API/usePost";

export function useInactiveUsers(pagination) {
  const [inactiveUsers, setInactiveUsers] = useState([]);
  const [selectesUserID, setSelectedUserID] = useState(null);

  //   get data
  const [userState, setUserState] = useState({
    state: null,
    reason: null,
  });

  //   post data
  const [activateReason, setActivateReason] = useState();

  const { loading, error, fetchData } = useFetch();
  const { loading: deactivateLoading, fetchData: handleDeactive } =
    useFetch(false);
  const { loading: activateLoading, handlePostData } = usePost(false);

  async function getInactiveUsers() {
    const res = await fetchData(
      `challenges/user-challenges/inactive?page=${pagination}`
    );
    if (!res) return;

    setInactiveUsers(res);
  }

  async function handleActivate() {
    const data = {
      id: selectesUserID,
      reason: activateReason,
    };
    const res = await handlePostData(
      "challenges/user-challenges/inactive/reason",
      data,
      true
    );

    if (!res) return;
    handleClose();
  }

  async function handleDeactivate() {
    const res = await handleDeactive(
      `challenges/user-challenges/inactive/disable/${selectesUserID}`,
      true
    );

    handleClose();
  }

  function handleUserSelected(id, state, reason) {
    setSelectedUserID(id);
    setUserState({ state, reason });
    document.getElementById("inactive-challenges").showModal();
  }

  function handleClose() {
    document.getElementById("inactive-challenges").close();
    setActivateReason("");
    getInactiveUsers();
  }

  useEffect(() => {
    getInactiveUsers();
  }, [pagination]);

  return {
    error,
    loading,
    activateLoading,
    deactivateLoading,
    inactiveUsers,
    activateReason,
    handleActivate,
    handleDeactivate,
    setActivateReason,
    handleUserSelected,
    userState,
  };
}
